<template>
  <span
    class="example-box__lable"
    :style="{
      color: color,
      backgroundColor: backgroundColor,
      borderColor: backgroundColor
    }"
    >{{ value }}</span
  >
</template>

<script>
export default {
  name: "LableExample",

  props: {
    color: {
      type: String,
      default: "#000000"
    },
    backgroundColor: {
      type: String,
      default: "#000000"
    },
    value: {
      type: String,
      default: "Example"
    }
  }
}
</script>

<style lang="scss" scoped>
.example-box__lable {
  position: absolute;

  display: inline-block;

  padding: 4px 6px;

  border-radius: 8px 4px 4px 0;

  line-height: 14px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: default;
  &::before {
    content: "";

    position: absolute;
    bottom: -5.6px;
    left: 0;

    border: 6px solid transparent;
    border-left-color: inherit;
    border-left-style: solid;
    border-left-width: 10px;
  }
}
</style>
