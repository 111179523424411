<template>
  <div>
    <Modal
      :showModal="showVldApproveModal"
      title="Модерация"
      @close="closeModal"
    >
      <div>
        <div>
          <label> Подтвердить изменение статуса на "Approved" </label>
        </div>
      </div>
      <span slot="footer">
        <Button @click="confirmApproval">Подтвердить</Button>
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "ConfirmApprovalModal",
  components: {
    Button,
    Modal
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations("ProductCatalog", {
      HIDE_VLD_APPROVE_MODAL: "HIDE_VLD_APPROVE_MODAL",
      PC_showLoader: "PC_showLoader"
    }),
    async confirmApproval() {
      this.PC_showLoader(true)
      try {
        const message = await this.confirmApprovalRequest()
        if (message === "OK") {
          this.$notification.success({ message: "Approved successfully" })
        }
      } catch (e) {
        this.$notification.error({ message: "Approve failure" })
      } finally {
        this.closeModal()
      }
    },
    closeModal() {
      this.HIDE_VLD_APPROVE_MODAL()
      this.PC_showLoader(false)
    },
    ...mapActions("ProductCatalog", {
      confirmApprovalRequest: "confirmApproval"
    })
  },
  computed: {
    ...mapState("ProductCatalog", {
      showVldApproveModal: (state) => state.valuesPage.ApproveVLD.showVldApproveModal
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
</style>
