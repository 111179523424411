<template>
  <div class="select-filter">
    <a-select
      :placeholder="placeholder || ''"
      :options="values"
      style="width: 100%; max-height: 200px"
      :dropdownWidth="dropdownWidth"
      :loading="fetching"
      @change="applySelect"
    />
  </div>
</template>

<script>
export default {
  name: "SelectFilter",
  props: {
    placeholder: String,
    values: Array,
    queryAction: Function,
    queryParams: Object,
    tableQueryAction: Function,
    tableQueryParams: Object,
    filters: Array,
    updateFilters: Function,
    columnKey: String,
    dropdownWidth: {
      type: [String, Number],
      default: "triggerWidth"
    },
    fetching: Boolean
  },

  methods: {
    applySelect(value) {
      const filters = this.filters.map((filter) => {
        let newFilter = {}

        if (filter.name === this.columnKey) {
          newFilter = {
            name: filter.name,
            value
          }
        } else {
          newFilter = filter
        }
        return newFilter
      })

      this.tableQueryAction({
        ...this.tableQueryParams,
        offset: 0,
        filters
      })

      this.updateFilters(filters)
    }
  }
}
</script>

<style scoped></style>
