<template>
  <a-menu
    mode="horizontal"
    class="menu"
    :defaultSelectedKeys="[$route.meta.code]"
  >
    <template v-for="component in components">
      <template v-if="!hiddenMenuItems.includes(component.code)">
        <a-sub-menu
          v-if="component.components.length > 0"
          :key="component.code"
        >
          <span
            slot="title"
            class="submenu-title-wrapper"
          >
            {{ component.display_name }}
          </span>

          <template v-for="nestedComponent in component.components">
            <a-menu-item
              v-if="nestedComponent.display_name"
              :key="nestedComponent.code"
            >
              <router-link :to="nestedComponent.link">
                {{ nestedComponent.display_name }}
              </router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>

        <a-menu-item
          v-else
          :key="component.id"
        >
          <router-link
            :to="component.link"
            class="menu-link"
          >
            {{ component.display_name }}
            <MessengerNewMessages v-if="component.code === '00MS01'" />
          </router-link>
        </a-menu-item>
      </template>
    </template>
  </a-menu>
</template>

<script>
import { mapState } from "vuex"

import MessengerNewMessages from "@/modules/Messenger/components/MessengerNewMessages.vue"

export default {
  name: "Navigation",
  components: {
    MessengerNewMessages
  },
  data() {
    return {
      hiddenMenuItems: ["00B2b", "FIN", "00SALE", "00MPS", "00SUP", "00SER"]
    }
  },
  computed: {
    ...mapState("Auth", {
      components: (state) => state.permissions.applications
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.menu {
  line-height: "64px";
  border-bottom: none;
}

.menu-link {
  position: relative;
}
</style>
