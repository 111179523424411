import { simpleBuildQuery } from "@/utils/HttpUtils"
import router from "@/router"

export const moderationPrefix = "/proxy/pm/api/v3/admin"

export const transformQuery = (queryParams, changeRouter = false) => {
  const { page = 1, pageSize: limit = 10, ...otherQueryParams } = queryParams

  if (changeRouter) {
    const newRouteQuery = queryParams

    if (page === 1) {
      delete newRouteQuery.page
    } else {
      newRouteQuery.page = `${newRouteQuery.page}`
    }

    if (limit === 10) {
      delete newRouteQuery.pageSize
    } else {
      newRouteQuery.pageSize = `${newRouteQuery.pageSize}`
    }

    if (JSON.stringify(router.currentRoute.query) !== JSON.stringify(newRouteQuery)) {
      router.replace({ query: newRouteQuery })
    }
  }

  return simpleBuildQuery({
    ...otherQueryParams,
    limit,
    offset: (page - 1) * limit
  })
}
