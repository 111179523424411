<template>
  <span
    v-show="show"
    class="loader"
    :class="{ [size]: size }"
  ></span>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: {
      type: String,
      default: "small"
    },
    show: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  border-radius: 100%;
  vertical-align: middle;
  position: relative;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  box-sizing: border-box !important;
  height: 22px;
}

.loader.small {
  width: 18px;
  height: 18px;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  box-sizing: border-box !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
}

.loader:before,
.loader:after {
  border: 2px solid transparent;
  border-top-color: $primary-color;
}

.loader:before {
  z-index: 1000000;
  animation: spin 1s infinite;
}

.loader:after {
  border: 2px solid #b4d7ec;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
