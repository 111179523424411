<template>
  <a-table
    :columns="columns"
    :dataSource="items"
    rowKey="id"
    :pagination="false"
  >
    <template #photoRenderer="images">
      <TableRendererPhotoGallery :images="images" />
    </template>

    <template #codeTitleRenderer>
      <div>CS-code</div>
      <div>P-code</div>
    </template>

    <template #codeRenderer="record">
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <div :style="{ width: '90px' }">{{ record.item_in_order.offer.code }}</div>
        <a-icon
          :style="{ marginRight: '10px', color: '#1890FF' }"
          type="copy"
          @click="handleCopy(record.item_in_order.offer.code)"
        />
        <a
          :style="{ color: '#1890FF' }"
          :href="getCsCodeLink(record.item_in_order.offer)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>

      <div :style="{ display: 'flex', alignItems: 'center' }">
        <div :style="{ width: '90px' }">{{ record.item_in_order.offer.product.p_code }}</div>
        <a-icon
          :style="{ marginRight: '10px', color: '#1890FF' }"
          type="copy"
          @click="handleCopy(record.item_in_order.offer.product.p_code)"
        />
        <a
          :style="{ color: '#1890FF' }"
          :href="getPCodeLink(record.item_in_order.offer)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>
    </template>

    <template #productNameRenderer="record">
      <div>{{ record.item_in_order.offer.product.name }}</div>

      <div>
        {{ $t(`condition_${record.item_in_order.condition.key}`) }},
        <template
          v-if="
            record.item_in_order.condition.key !== 'w_undetermined' &&
            record.item_in_order.warranty > 0
          "
        >
          {{
            $tc("monthFull", +record.item_in_order.warranty, {
              count: record.item_in_order.warranty
            })
          }}
        </template>
      </div>
    </template>
  </a-table>
</template>

<script setup>
import { message } from "ant-design-vue"
import i18n from "@/i18n"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/index.js"

import { MARKETPLACE } from "@/constants/common.js"
import useReturnsPageColumns from "../../useReturnsPageColumns.js"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const { returnedOffersColumns: columns } = useReturnsPageColumns()

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}

const getCsCodeLink = ({ product, code }) => {
  return `${MARKETPLACE}/product/${product?.slug}/${product?.p_code}/${code}`
}

const getPCodeLink = ({ product }) => {
  return `${MARKETPLACE}/product/${product?.slug}/${product?.p_code}`
}
</script>
