import { API } from "@/utils/HttpUtils"
import { transformQuery } from "./servicesUtils"

export const fetchBillingSellers = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/billing/sellers/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const getSellersMeta = () => {
  return API.get("/mp-admin/billing/sellers/meta/")
}

export const fetchBillingSellerById = (uuid) => {
  return API.get(`/mp-admin/billing/sellers/${uuid}`)
}

export const fetchSellerOperations = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/billing/sellers/${requestParams.seller_id}/operations/${transformQuery(
      queryParams
    )}`,
    { signal }
  )
}

export const fetchBillingSellerOrders = (uuid) => {
  return API.get(`/mp-admin/billing/sellers/${uuid}/orders-data/`)
}

export const billingAddNote = (billingId, data) => {
  return API.patch(`/mp-admin/billing/sellers-bills/${billingId}`, data)
}

export const sellerChangeAmountFreeOrders = (sellerSlug, data) => {
  return API.patch(`/mp-admin/sellers/${sellerSlug}/`, data)
}

export const sellerCancelTariff = (sellerUuid) => {
  let data = {
    operation_type: "TARIFF_CANCEL"
  }

  return API.post(`/mp-admin/billing/sellers/${sellerUuid}/operations/`, data)
}

export const sellerSubtract = (sellerUuid, data) => {
  return API.post(`/mp-admin/billing/sellers/${sellerUuid}/operations/`, {
    ...data,
    operation_type: "WRITE_OFFS"
  })
}

export const sellerChangeTariff = (sellerUuid, data) => {
  return API.post(`/mp-admin/billing/sellers/${sellerUuid}/operations/`, {
    ...data,
    operation_type: "TARIFF_CHANGE"
  })
}

export const sellerReplenish = (sellerUuid, data) => {
  return API.post(`/mp-admin/billing/sellers/${sellerUuid}/operations/`, data)
}

export const fetchAllTariffs = () => {
  let tariffs = []

  return new Promise((resolve) => {
    const getChunk = async ({ limit = 25, offset = 0 } = {}) => {
      const { data } = await API.get("/mp-admin/billing/tariffs/", { params: { limit, offset } })

      tariffs = tariffs.concat(data.results)

      if (tariffs.length === data.count) {
        resolve(tariffs)
      } else {
        getChunk({ offset: tariffs.length })
      }
    }

    getChunk()
  })
}
