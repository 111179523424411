import { transformQuery } from "../servicesUtils"
import { API } from "@/utils/HttpUtils"

export const fetchDeliveryMethods = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/delivery-methods/${transformQuery(queryParams)}`, { signal })
}

export const fetchDeliveryMethodsMeta = () => {
  return API.get(`/mp-admin/delivery-methods/meta`)
}

export const createDeliveryMethod = (data) => {
  return API.post("/mp-admin/delivery-methods/", data)
}

export const updateDeliveryMethod = (data, id) => {
  return API.put(`/mp-admin/delivery-methods/${id}`, data)
}
