import axios from "axios"
import _ from "lodash"
import getCurrentLocale from "./getCurrentLocale"

export const API = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "Accept-language": getCurrentLocale()
  }
})

export const API_V2 = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v2`,
  withCredentials: true,
  headers: {
    "Accept-language": getCurrentLocale()
  }
})

export const API_ADS = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/ads/api`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "Accept-language": getCurrentLocale()
  }
})

export const API_MODERATION = axios.create({
  baseURL: `${process.env.VUE_APP_API_MODERATION_URL}/api`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "Accept-language": "ru"
  }
})

const doubleEncodeQueryParams = (array) => {
  return array.map((query) => {
    const key = query.substring(0, query.indexOf("="))
    const value = query.substring(query.indexOf("=") + 1)

    return `${key}=${encodeURIComponent(encodeURIComponent(value))}`
  })
}

const singleEncodeQueryParams = (array) => {
  return array.map((query) => {
    const key = query.substring(0, query.indexOf("="))
    const value = query.substring(query.indexOf("=") + 1)

    return `${key}=${encodeURIComponent(value)}`
  })
}

export const buildDataTableQueryString = (params, singleEncode = false) => {
  const filters = !!params.filters
    ? Object.keys(params.filters)
        .filter((key, index) => {
          // if ( params.filters[key].name === 'gtin') { // in this case column should be filterd by true and false, so we need to send vlue 'false' (page productTable)
          //   if(params.filters[key].name && params.filters[key].value !== null)
          //   return true
          // }
          if (params.filters[key].name === "new") {
            if (params.filters[key].name && params.filters[key].value !== null) return true
          }
          return !!params.filters[key].value
        })
        .map((key) => {
          const name = params.filters[key].name
          const value = params.filters[key].value

          return Array.isArray(value)
            ? [`${name}_min=${value[0]}`, `${name}_max=${value[1]}`]
            : `${name}=${value}`
        })
    : []

  const ordering = !!params.ordering ? [`ordering=${params.ordering.join(",")}`] : []
  let queryArray = [
    ...Object.keys(params)
      .filter((key) => {
        return key !== "filters" && key !== "ordering"
      })
      .map((key) => {
        return `${key}=${params[key]}`
      }),
    ...ordering
  ]

  queryArray = queryArray.concat(...filters)

  return singleEncode
    ? `?${singleEncodeQueryParams(queryArray).join("&")}`
    : `?${doubleEncodeQueryParams(queryArray).join("&")}`
}

export const buildDataTableGetUrl = (url, params, singleEncode = false) => {
  const queryString = buildDataTableQueryString(params, singleEncode)

  return `${url}${queryString}`
}

export const simpleBuildQuery = (params) => {
  const arr = Object.keys(params)
    .filter((key) => {
      const paramIsArray = _.isArray(params[key])

      return (
        (!paramIsArray && params[key] !== null && params[key] !== "") ||
        (paramIsArray && !_.isEmpty(params[key]))
      )
    })
    .map((key) => {
      return `${key}=${params[key]}`
    })

  return `?${arr.join("&")}`
}
