var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-form-model',{ref:"formRef",staticClass:"seo-form",attrs:{"model":_setup.form,"colon":false}},[_vm._l((_setup.languages),function(lang){return _c('a-form-model-item',{key:`seo_h1_${lang}`,attrs:{"prop":`seo_h1.${lang}`},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_setup.tagFieldsOptions.seo_h1.title)+", "+_vm._s(lang.toUpperCase())+" ("),_c('span',{class:_setup.checkIsLengthCorrect(
            _setup.form.seo_h1[lang].length,
            _setup.tagFieldsOptions.seo_h1.min,
            _setup.tagFieldsOptions.seo_h1.max
          )},[_vm._v(_vm._s(_setup.form.seo_h1[lang].length))]),_vm._v("/"+_vm._s(_setup.tagFieldsOptions.seo_h1.max)+") ")]},proxy:true}],null,true)},[_c('a-input',{model:{value:(_setup.form.seo_h1[lang]),callback:function ($$v) {_vm.$set(_setup.form.seo_h1, lang, $$v)},expression:"form.seo_h1[lang]"}})],1)}),_vm._l((_setup.languages),function(lang){return _c('a-form-model-item',{key:`seo_title_${lang}`,attrs:{"prop":`seo_title.${lang}`},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_setup.tagFieldsOptions.seo_title.title)+", "+_vm._s(lang.toUpperCase())+" ("),_c('span',{class:_setup.checkIsLengthCorrect(
            _setup.form.seo_title[lang].length,
            _setup.tagFieldsOptions.seo_title.min,
            _setup.tagFieldsOptions.seo_title.max
          )},[_vm._v(_vm._s(_setup.form.seo_title[lang].length))]),_vm._v("/"+_vm._s(_setup.tagFieldsOptions.seo_title.min)+"-"+_vm._s(_setup.tagFieldsOptions.seo_title.max)+") ")]},proxy:true}],null,true)},[_c('a-input',{model:{value:(_setup.form.seo_title[lang]),callback:function ($$v) {_vm.$set(_setup.form.seo_title, lang, $$v)},expression:"form.seo_title[lang]"}})],1)}),_vm._l((_setup.languages),function(lang){return _c('a-form-model-item',{key:`seo_description_${lang}`,attrs:{"prop":`seo_description.${lang}`},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_setup.tagFieldsOptions.seo_description.title)+", "+_vm._s(lang.toUpperCase())+" ("),_c('span',{class:_setup.checkIsLengthCorrect(
            _setup.form.seo_description[lang].length,
            _setup.tagFieldsOptions.seo_description.min,
            _setup.tagFieldsOptions.seo_description.max
          )},[_vm._v(_vm._s(_setup.form.seo_description[lang].length))]),_vm._v("/"+_vm._s(_setup.tagFieldsOptions.seo_description.min)+"-"+_vm._s(_setup.tagFieldsOptions.seo_description.max)+") ")]},proxy:true}],null,true)},[_c('a-textarea',{attrs:{"autoSize":{ minRows: 4, maxRows: 6 }},model:{value:(_setup.form.seo_description[lang]),callback:function ($$v) {_vm.$set(_setup.form.seo_description, lang, $$v)},expression:"form.seo_description[lang]"}})],1)}),_c('a-collapse',{staticStyle:{"background":"transparent"},attrs:{"bordered":false}},_vm._l((_setup.languages),function(lang){return _c('a-collapse-panel',{key:lang,staticStyle:{"border":"none"},attrs:{"forceRender":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("SEO-text, "+_vm._s(lang.toUpperCase()))]},proxy:true}],null,true)},[_c('a-form-model-item',[_c(_setup.EditorHtml,{attrs:{"id":`seo_text_${lang}`},model:{value:(_setup.form.seo_text[lang]),callback:function ($$v) {_vm.$set(_setup.form.seo_text, lang, $$v)},expression:"form.seo_text[lang]"}})],1)],1)}),1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }