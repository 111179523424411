<template>
  <a-form
    :form="cancelForm"
    style="margin-bottom: 40px"
  >
    <a-form-item :label="`${$t('name')}, RU`">
      <a-input v-decorator="['ru', { rules: reasonRules }]" />
    </a-form-item>

    <a-form-item :label="`${$t('name')}, EN`">
      <a-input v-decorator="['en', { rules: reasonRules }]" />
    </a-form-item>

    <a-form-item :label="`${$t('name')}, UK`">
      <a-input v-decorator="['uk', { rules: reasonRules }]" />
    </a-form-item>

    <a-form-item :label="$t('avaliableFor')">
      <a-select
        style="width: 100%"
        mode="multiple"
        :options="allowedForValues"
        v-decorator="['allowed_for', { rules: [{ required: true, message: $t('emptyError') }] }]"
      />
    </a-form-item>

    <a-form-item :label="$t('note')">
      <a-textarea v-decorator="['description', { rules: descriptionRules }]" />
    </a-form-item>

    <a-form-item>
      <a-checkbox v-decorator="['zero_quantity', { valuePropName: 'checked' }]">
        {{ $t("ordersToNull") }}
      </a-checkbox>
    </a-form-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import {
  updateCancellationReason,
  createCancellationReason
} from "@/modules/MPAdmin/services/generalSettingService/cancellationReasonsService"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateCancellationReasonsModal",

  props: {
    prototype: {
      type: Object,
      default() {
        return {}
      }
    },
    allowedForValues: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      cancelForm: this.$form.createForm(this, {
        name: "cancellationReasonsForm",
        mapPropsToFields: () => {
          return {
            ru: this.$form.createFormField({
              value: this.prototype?.reason?.ru || ""
            }),
            en: this.$form.createFormField({
              value: this.prototype?.reason?.en || ""
            }),
            uk: this.$form.createFormField({
              value: this.prototype?.reason?.uk || ""
            }),

            allowed_for: this.$form.createFormField({
              value: this.prototype?.allowed_for || []
            }),
            zero_quantity: this.$form.createFormField({
              value: this.prototype?.zero_quantity
            }),
            description: this.$form.createFormField({
              value: this.prototype?.description || ""
            })
          }
        }
      }),
      isFetching: false
    }
  },

  methods: {
    async onSave() {
      this.cancelForm.validateFields(async (err, values) => {
        if (err) return

        this.isFetching = true

        const { uk, en, ru, ...actionData } = values

        actionData.reason = { uk, en, ru }

        try {
          if (this.prototype.id) {
            const { data } = await updateCancellationReason(actionData, this.prototype.id)
            this.$emit("updateReason", data)
          } else {
            await createCancellationReason(actionData)
            this.$emit("createReason")
          }

          this.$notification.success({
            message: this.$t(this.prototype.id ? "updated" : "created")
          })
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.isFetching = false
        }
      })
    }
  },

  computed: {
    reasonRules() {
      return [
        { required: true, message: this.$t("emptyError") },
        { max: 200, message: this.$t("maxLengthError", { length: 200 }) }
      ]
    },

    descriptionRules() {
      return [{ max: 1000, message: this.$t("maxLengthError", { length: 1000 }) }]
    }
  }
}
</script>
