<template>
  <div class="units-data-table">
    <SynDataTable
      :data="tableData"
      :queryAction="querySearch"
      :queryParams="queryParams"
      :fetching="fetchingStatus"
      rowKey="_id"
      :columns="dataTableColumns"
      tableId="ProductCatalogUnitsTable"
      class="margin-top"
      defaultOrder="-timestamp"
      :cellClasses="cellClasses"
      :headerButtons="headerButtons"
      :limitValues="limitValues"
      :rowActions="rowActions"
      :selectedRow="PU_selectedUnit || {}"
    ></SynDataTable>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import InputEditName from "./EditComponents/inputEditName"
import InputEditSynonyms from "./EditComponents/inputEditSynonyms"
import InputEditDescription from "./EditComponents/inputEditDescription"
import InputEditCoef from "./EditComponents/inputEditCoef"
import AutocompleteEditBase from "./EditComponents/AutocompleteEditBase"
import Base from "./RendererComponents/Base"
import HighlightCellCyrillic from "@/modules/ProductCatalog/components/common/HighlightCellCyrillic"

export default {
  name: "UnitsTable",
  data() {
    return {
      timeout: null,
      cellClasses(rowObject, columnIndex, rowIndex) {
        if (columnIndex === 4) {
          return ["coef-base-cell"]
        } else if (columnIndex === 5) {
          return ["coef-base-cell", "base-cell"]
        } else {
          return []
        }
      },
      headerButtons: [
        {
          label: "Добавить",
          action: () => {
            this.PU_showCreateUnitModal(true)
          },
          theme: "primary"
        }
      ],
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ],
      rowActions: [
        {
          label: "Поиск атрибутов",
          action: this.buildAttrTable
        }
      ]
    }
  },
  methods: {
    buildAttrTable(row) {
      this.PU_selectedUnitSet(row)
      this.PU_searchAttributes({ queryParams: this.PU_attributesTableQueryParams, selected: row })
    },
    querySearch(queryParams) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PU_searchUnits(queryParams)
      }, 500)
    },
    openAttributesModal(unit) {
      let obj = { bool: true, unit }
      this.PU_openAttributesModal(obj)
    },
    ...mapMutations("ProductCatalog", {
      changeQueryParams: "PU_unitsTableQueryParams",
      PU_showCreateUnitModal: "PU_showCreateUnitModal",
      PU_selectedUnitSet: "PU_selectedUnit"
    }),
    ...mapActions("ProductCatalog", {
      PU_searchUnits: "PU_searchUnits",
      updateCell: "PU_editUnitsCell",
      updateSynonymCell: "PU_editUnitsSynonym",
      PU_openAttributesModal: "PU_openAttributesModal",
      searchValue: "PU_unitTableSearchValue",
      selectValue: "PU_unitTableSelectValue",
      PU_searchAttributes: "PU_searchAttributes"
    })
  },
  computed: {
    dataTableColumns() {
      return [
        {
          key: "description",
          title: "Короткое описание",
          editRenderer: InputEditDescription,
          editRendererProps: {
            onUpdate: this.updateCell
          },
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "synonymsNames",
          title: "Синонимы",
          width: 147,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditSynonyms,
          editRendererProps: {
            onUpdate: this.updateSynonymCell
          },
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: "name",
          title: `Обозначение (${this.PC_selectedLanguage})`,
          width: 147,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditName,
          editRendererProps: {
            onUpdate: this.updateCell
          },
          sorting: true,
          filtering: this.nameFilter
        },
        {
          key: "code",
          title: "Код",
          width: 104,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: "coef",
          title: "Коэф.",
          width: 104,
          editRenderer: InputEditCoef,
          editRendererProps: {
            onUpdate: this.updateCell
          },
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: "base_name",
          title: "Базовая",
          width: 90,
          editRenderer: AutocompleteEditBase,
          editRendererProps: {
            onEvent: this.searchValue,
            onSelect: this.selectValue
          },
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          renderer: Base
        },
        {
          key: "time",
          title: "Дата создания",
          width: 145,
          sorting: true,
          filtering: {
            type: "dateRange",
            placeholder: "Поиск",
            valueTypeTimestamp: true
          }
        },
        {
          key: "status",
          title: "Статус",
          width: 80,
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        }
      ]
    },
    nameFilter() {
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        return {
          type: "search",
          placeholder: "Поиск"
        }
      }
      return false
    },
    ...mapGetters("ProductCatalog", {
      queryParams: "PU_unitsTableQueryParams",
      tableData: "PU_unitsTableData",
      fetchingStatus: "PU_unitsTableFetchingStatus",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage",
      PU_attributesTableQueryParams: "PU_attributesTableQueryParams",
      PU_selectedUnit: "PU_selectedUnit"
    })
  }
}
</script>
<style lang="scss">
.units-data-table [class*="tBody__td--editable"].coef-base-cell {
  background-color: rgba(12, 45, 101, 0.07) !important;
}
.units-data-table [class*="tBody__td--editable"].base-cell {
  padding: 0;
}
</style>
