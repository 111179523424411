<template>
  <div class="drawer-wrapper">
    <figure class="user__avatar">
      <img
        :src="userAvatar"
        alt=""
      />
    </figure>

    <div class="user__info">
      <a-descriptions
        bordered
        :column="1"
        size="small"
      >
        <a-descriptions-item
          v-if="prototype.seller"
          :label="prototype.is_seller_owner ? $t('owner') : $t('sellerManager')"
        >
          <a
            :href="getSellerUrl(prototype.seller.slug)"
            target="_blank"
          >
            {{ prototype.seller.name }}
          </a>
        </a-descriptions-item>

        <a-descriptions-item :label="$t('nickName')">
          {{ prototype?.username || $t("notSpecified") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('userFullName')">
          {{ hasFullName(prototype) ? getFullName(prototype) : $t("notSpecified") }}
        </a-descriptions-item>

        <a-descriptions-item
          :label="$t('gender')"
          :class="{ 'moderation__list-value--not': !prototype.gender }"
        >
          {{ gender }}
        </a-descriptions-item>

        <a-descriptions-item
          :label="$t('birthDate')"
          :class="{ 'moderation__list-value--not': !prototype.birthday }"
        >
          {{ prototype.birthday ? getFormatedDateTime(prototype.birthday) : $t("notSpecified") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('placeOfResidence')">
          {{ prototype?.living_city?.name || $t("notSpecified") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('phoneNumber')">
          <PhoneRenderer
            v-if="prototype.mobile_phone"
            :row="prototype"
          />

          <span
            v-else
            class="moderation__list-value--not"
          >
            {{ $t("notSpecified") }}
          </span>
        </a-descriptions-item>

        <a-descriptions-item label="Email">
          <EmailRenderer
            v-if="prototype.email"
            :row="prototype"
          />

          <span
            v-else
            class="moderation__list-value--not"
          >
            {{ $t("notSpecified") }}
          </span>
        </a-descriptions-item>

        <a-descriptions-item label="Refferal id">
          {{ prototype.referral_id }}
        </a-descriptions-item>
      </a-descriptions>

      <div v-if="prototype.avatar">
        <a-switch
          v-model="avatarAllowed"
          @change="handleChangeModerationPhoto"
        />
        {{ $t("photoAllowed") }}
      </div>

      <div>
        <a-switch
          v-model="userIsActive"
          @change="handleChangeActive"
        />
        {{ $t("userIsActive") }}
      </div>

      <div>
        <a-switch
          v-model="isReferalPartner"
          @change="handleChangeRefActivity"
        />
        {{ $t("referalPartner") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue"
import i18n from "@/i18n"
import { notification, Modal } from "ant-design-vue"
import {
  MARKETPLACE,
  IMAGES_CDN,
  IMAGE_FEMALE,
  IMAGE_MALE,
  AVATAR_MODERATION_STATUS_EXPECTS,
  AVATAR_MODERATION_STATUS_FAILED
} from "@/constants/common"

import { updateBuyer } from "@/modules/MPAdmin/services/buyersService.js"

import PhoneRenderer from "./PhoneRenderer.vue"
import EmailRenderer from "./EmailRenderer.vue"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"
import getImagePath from "@/utils/getImagePath.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["updateUser"])

const userIsActive = ref(!props.prototype?.is_blocked || false)
const isReferalPartner = ref(props.prototype?.is_referrals_available || false)
const avatarAllowed = ref(
  props.prototype?.avatar_moderation_status !== AVATAR_MODERATION_STATUS_FAILED
)

const userAvatar = computed(() => {
  const avatar = props.prototype.avatar
  if (!avatar) {
    return props.isSelectedFemale ? IMAGE_FEMALE : IMAGE_MALE
  }
  return IMAGES_CDN + "/media/assets/images/" + getImagePath(avatar, "full")
})

const gender = computed(() => {
  if (!props.prototype.gender) {
    return i18n.t("notSpecified")
  }
  return props.isSelectedFemale ? i18n.t("female") : i18n.t("male")
})

const getSellerUrl = (slug) => {
  return `${MARKETPLACE}/seller/${slug}/`
}

const hasFullName = ({ last_name, first_name, middle_name }) => {
  return Boolean(last_name || first_name || middle_name)
}

const getFullName = ({ last_name, first_name, middle_name }) => {
  return [last_name, first_name, middle_name].filter(Boolean).join(" ")
}

const handleChangeModerationPhoto = () => {
  const title = avatarAllowed.value ? i18n.t("avatarShowAlert") : i18n.t("avatarHideAlert")
  const payload = {
    avatar_moderation_status: avatarAllowed.value
      ? AVATAR_MODERATION_STATUS_EXPECTS
      : AVATAR_MODERATION_STATUS_FAILED
  }
  changeUserConfirmation(title, payload, avatarAllowed)
}

const handleChangeRefActivity = () => {
  const title = isReferalPartner.value
    ? i18n.t("isReferalPartnerAlert")
    : i18n.t("isNotReferalPartnerAlert")
  const payload = {
    is_referrals_available: isReferalPartner.value
  }
  changeUserConfirmation(title, payload, isReferalPartner)
}

const handleChangeActive = () => {
  const title = userIsActive.value ? `${i18n.t("activateUser")}?` : `${i18n.t("blockUser")}?`
  const payload = {
    is_blocked: !userIsActive.value
  }
  changeUserConfirmation(title, payload, userIsActive)
}

const changeUserConfirmation = (title, payload, valueKey) => {
  Modal.confirm({
    title: title,
    okText: i18n.t("yes"),
    cancelText: i18n.t("no"),
    onOk: () => onUpdateUser(payload),
    onCancel: () => {
      valueKey.value = !valueKey.value
    }
  })
}

const onUpdateUser = async (payload) => {
  try {
    const { data } = await updateBuyer(props.prototype.id, payload)
    notification.success({ message: i18n.t("updated") })
    emit("updateUser", data)
  } catch (error) {
    notifyResponseError({ error })
  }
}
</script>

<style lang="scss" scoped>
.drawer-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.user {
  &__avatar {
    width: 40%;
    aspect-ratio: 1;

    & > img {
      width: 100%;
      aspect-ratio: 1;
    }
  }
  &__info {
    width: 55%;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }
}
</style>
