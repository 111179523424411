import conversion from "./conversion"
import table from "./table"
import search from "./search"
import replacement from "./replacement"
import changingAttrModal from "./changingAttrModal"
import convertToListModal from "./convertToListModal"

const modules = {
  conversion,
  table,
  search,
  replacement,
  changingAttrModal,
  convertToListModal
}

export default {
  namespaced: true,
  modules
}
