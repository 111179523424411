<template>
  <div
    id="app"
    style="height: 100%"
  >
    <a-config-provider :locale="uk_UA">
      <a-layout style="min-height: 100%">
        <Header :fetching="isFetching()" />

        <a-layout-content>
          <div :style="{ background: '#fff', padding: '20px', minHeight: '380px' }">
            <AntLayout v-if="$route.meta.layout === 'ant-layout'">
              <Content :fetching="isFetching()">
                <div v-if="checkPermissions() === 'emptyPermissionsMeta'">
                  Metadata for route permissions is not set or wrong
                </div>
                <template v-else-if="!checkPermissions()">
                  <PageForbidden />
                </template>
                <template v-else>
                  <router-view />
                </template>
              </Content>
              <GlobalGallery ref="globalGalleryRef" />
            </AntLayout>

            <DefaultLayout v-else>
              <GlobalGallery ref="globalGalleryRef" />
              <Content :fetching="isFetching()">
                <div v-if="checkPermissions() === 'emptyPermissionsMeta'">
                  Metadata for route permissions is not set or wrong
                </div>
                <template v-else-if="!checkPermissions()">
                  <PageForbidden />
                </template>
                <template v-else>
                  <router-view />
                </template>
              </Content>

              <portal-target
                name="select"
                multiple
              />
              <portal-target
                name="modal"
                multiple
              />
              <portal-target
                name="autocomplete"
                multiple
              />
            </DefaultLayout>
          </div>
        </a-layout-content>
      </a-layout>
    </a-config-provider>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, provide } from "vue"
import { mapMutations, mapActions, mapState } from "vuex"
import { API, API_ADS, API_V2 } from "./utils/HttpUtils"
import usePermissions from "@/composables/usePermissions.js"
import Cookies from "js-cookie"

import Header from "@/components/Layout/Header"
import Content from "@/components/Layout/Content"
import AntLayout from "@/components/Layout/AntLayout"
import DefaultLayout from "@/components/Layout/DefaultLayout"
import PageForbidden from "@/components/Layout/PageForbidden"
import GlobalGallery from "@/components/GlobalGallery/index.vue"

import uk_UA from "ant-design-vue/lib/locale-provider/uk_UA"
import notifyResponseError from "./utils/notifyResponseError"
import { MESSENGER_URL_XHR } from "./constants/common"

export default {
  metaInfo: {
    title: "Synthetic ERP",
    titleTemplate: "%s - Synthetic ERP"
  },
  name: "App",
  components: {
    Header,
    Content,
    PageForbidden,
    GlobalGallery,
    AntLayout,
    DefaultLayout
  },

  setup() {
    const { checkRouteUrl, checkApplicationPermission, checkComponentPermission } = usePermissions()

    const globalGalleryRef = ref()
    const unreadMessagesCheckInterval = ref()

    provide("galleryRef", globalGalleryRef)

    return {
      checkRouteUrl,
      checkApplicationPermission,
      checkComponentPermission,

      globalGalleryRef,
      unreadMessagesCheckInterval,

      uk_UA
    }
  },

  computed: {
    ...mapState("Auth", {
      profileRequest: (state) => state.profileRequest,
      permissionsRequest: (state) => state.permissionsRequest,
      profile: (state) => state.profile,
      isLogged: (state) => state.isLogged,
      applications: (state) => state.permissions.applications
    }),
    ...mapState("spvMessenger", {
      socket: (state) => state.socket,
      unreadMessages: (state) => state.unreadMessages
    })
  },
  beforeMount() {
    API.interceptors.response.use(this.responseInterseptorSuccess, this.responseInterseptorError)
    API_ADS.interceptors.response.use(
      this.responseInterseptorSuccess,
      this.responseInterseptorError
    )

    API_V2.interceptors.response.use(
      this.responseInterseptorSuccess,
      this.responseInterseptorErrorToken
    )

    this.getProfile()
  },
  mounted() {
    this.responseHandlers()
    this.checkUnreadMessages()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  watch: {
    applications: {
      handler(val) {
        // after permissions loaded (initial page load), connect the messenger
        if (val.length) {
          this.connectMessenger()
        }
      }
    },
    isLogged: {
      handler(val) {
        // after login/logout connect/disconnect messenger
        if (val) {
          this.connectMessenger()
        } else {
          this.disconnectMessenger()
        }
      }
    }
  },
  methods: {
    ...mapMutations("Messenger", ["setGetToken", "setUser", "resetMessengerAccess"]),
    ...mapActions("Auth", ["getProfile", "setLogout", "sendRefreshToken"]),
    ...mapMutations("spvMessenger", ["SET_UNREAD_MESSAGES"]),

    isFetching() {
      return this.profileRequest || this.permissionsRequest
    },

    checkPermissions() {
      if (!!this.$route.meta.withoutChecking) {
        return true
      } else if (!this.$route.meta.permissionType || !this.$route.meta.permissionCode) {
        return "emptyPermissionsMeta"
      } else if (
        !this.checkRouteUrl({
          code: this.$route.meta.permissionCode,
          url: this.$route.path,
          applications: this.applications
        })
      ) {
        return "emptyPermissionsMeta"
      } else if (this.$route.meta) {
        const { permissionType, permissionCode } = this.$route.meta

        if (permissionType === "application") {
          return this.checkApplicationPermission({
            code: permissionCode,
            applications: this.applications
          })
        } else if (permissionType === "component") {
          return this.checkComponentPermission({
            code: permissionCode,
            applications: this.applications
          })
        }
      } else {
        return false
      }
    },

    responseHandlers() {
      this.unsubscribe = this.$store.subscribe(({ type, error }) => {
        if (type === "Auth/logoutFailure") {
          notifyResponseError({ error })

          return
        }

        if (type === "Auth/logoutSuccess") {
          this.$router.push({ path: "/login" })

          return
        }
      })
    },

    async connectMessenger() {
      if (
        this.checkApplicationPermission({ code: "00MS01", applications: this.applications }) ||
        this.checkComponentPermission({ code: "00MPA/MS02", applications: this.applications })
      ) {
        if (!this.socket.isConnected && !this.socket.connecting && this.isLogged) {
          try {
            const { data } = await API.get("/mp-admin/messenger/token")

            const token = data.token
            document.cookie = `moderator_token=${token}`
            const getToken = () => Cookies.get("moderator_token")
            const uuid = data.uuid
            const user = {
              uuid,
              role: "moderator"
            }
            this.setGetToken(getToken)
            this.setUser(user)

            this.$connectMessengerSocket({ getToken, user })
          } catch (error) {
            console.log(error)
          }
        }
      }

      if (!this.unreadMessagesCheckInterval) {
        this.checkUnreadMessages()
      }
    },

    disconnectMessenger() {
      if (this.socket && this.socket.isConnected) {
        this.$messengerSocket.close()
        this.resetMessengerAccess()
        document.cookie = `moderator_token=`
      }
    },

    responseInterseptorSuccess(response) {
      window.allowUseToasts = true
      return response
    },

    responseInterseptorError(error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.config &&
        !error.response.config.__isRetryRequest &&
        this.$route.path !== "/login"
      ) {
        window.allowUseToasts = false

        const query =
          this.$router.history.current.path !== "/login"
            ? {
                next: this.$router.history.current.path
              }
            : {}

        this.$router.push({
          path: "/login",
          query
        })

        this.setLogout()
      } else {
        window.allowUseToasts = true
      }
      return Promise.reject(error)
    },

    async responseInterseptorErrorToken(error) {
      const originalRequest = error?.config
      const path = this.$route.path

      if (
        (originalRequest?.url.endsWith("refresh/") || originalRequest?.url.endsWith("logout/")) &&
        path !== "/login"
      ) {
        this.$router.push({ path: "/login" })

        return Promise.reject(error)
      }

      if (error?.response?.status === 401 && path !== "/login") {
        try {
          const { access_token } = await this.sendRefreshToken()

          API_V2.defaults.headers.common.Authorization = "Bearer " + access_token
          originalRequest._retry = true
          originalRequest.headers.common.Authorization = `Bearer ` + access_token

          return API_V2(originalRequest)
        } catch {
          return Promise.reject(error)
        }
      }

      return Promise.reject(error)
    },

    async checkUnreadMessages(access) {
      if (!API_V2.defaults.headers.common.Authorization) return

      try {
        if (!access) {
          const { data } = await API_V2.get("/proxy/msg/support-auth")
          access = data.token
        }

        const base_url = `${MESSENGER_URL_XHR}/api/v2`

        const res = await fetch(`${base_url}/chats/messages/unread-count/?sender=moderator`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`
          }
        })
        const { count } = await res.json()
        if (!count) return

        if (this.unreadMessages !== count) {
          this.SET_UNREAD_MESSAGES(count)
        }
        if (this.unreadMessagesCheckInterval) return
        this.unreadMessagesCheckInterval = setInterval(() => this.checkUnreadMessages(access), 5000)
      } catch {
        if (this.unreadMessagesCheckInterval) {
          clearInterval(this.unreadMessagesCheckInterval)
          this.unreadMessagesCheckInterval = undefined
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/main";
</style>
@/composables/checkPermissions
