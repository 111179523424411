<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PA_showDeleteListModalGet"
      :title="'Отвязать атрибут от списка?'"
    >
      <div>
        <label
          ><span
            >Выбранный список:
            <strong>{{
              PA_searchedValuesOfAttribute &&
              PA_searchedValuesOfAttribute[0] &&
              PA_searchedValuesOfAttribute[0].description
            }}</strong></span
          ></label
        >
        <label
          ><span
            >Атрибут:
            <strong v-if="PA_selectedAttribute">{{ PA_selectedAttribute.name }}</strong></span
          ></label
        >
      </div>
      <span slot="footer">
        <Button
          color="danger"
          @click="onDelete"
          >Отвязать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "DeleteListModal",
  components: {
    Button,
    Modal
  },
  methods: {
    onDelete() {
      this.PA_deleteListFromAttribute()
      this.closeModal()
    },
    closeModal() {
      this.PA_showDeleteListModalMute(false)
    },
    ...mapMutations("ProductCatalog", {
      PA_showDeleteListModalMute: "PA_showDeleteListModal"
    }),
    ...mapActions("ProductCatalog", {
      PA_deleteListFromAttribute: "PA_deleteListFromAttribute"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PA_searchedValuesOfAttribute: "PA_searchedValuesOfAttribute",
      PA_selectedAttribute: "PA_selectedAttribute",
      PA_showDeleteListModalGet: "PA_showDeleteListModal"
    })
  }
}
</script>
<style lang="scss" scoped></style>
