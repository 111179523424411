<template>
  <div>
    <SynDataTable
      :data="tableData"
      :queryAction="querySearch"
      :queryParams="queryParams"
      :fetching="fetchingStatus"
      rowKey="code"
      :columns="dataTableColumns"
      tableId="ProductCatalogAttributesMainTable"
      class="margin-top custom-table"
      defaultOrder="-timestamp"
      :headerButtons="headerButtons"
      :cellClasses="cellClasses"
      :rowActions="rowActions"
      :selectedRow="PA_selectedAttributeGet || {}"
      :limitValues="limitValues"
      :rowDisabled="rowDisabled"
    />
    <EditDescriptionModal />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import InputEditName from "./EditComponents/inputEditName"
import InputEditSynonyms from "./EditComponents/inputEditSynonyms"
import SelectEditType from "./RendererComponents/SelectEditType"
import AutocompleteEditUnit from "./EditComponents/AutocompleteEditUnit"
import DefaultUnitName from "./RendererComponents/DefaultUnitName"
import Description from "./RendererComponents/Description"
import Time from "@/modules/ProductCatalog/components/common/TimeCellRenderer"
import EditDescriptionModal from "./EditComponents/EditDescriptionModal"
import HighlightCellCyrillic from "@/modules/ProductCatalog/components/common/HighlightCellCyrillic"

export default {
  name: "AttributesTable",
  components: {
    InputEditName,
    InputEditSynonyms,
    SelectEditType,
    AutocompleteEditUnit,
    DefaultUnitName,
    Description,
    EditDescriptionModal
  },
  data() {
    return {
      timeout: null,
      headerButtons: [
        {
          label: "Добавить",
          action: () => {
            this.PA_showCreateAttributeModal(true)
          },
          theme: "primary"
        }
      ],
      rowActions: [
        {
          label: "Просмотр типов",
          action: this.buildAtlsAndValuesTable
        }
      ],
      cellClasses(row, columnIndex, rowIndex) {
        return columnIndex === 4 || columnIndex === 6 ? ["no-padding"] : []
      },
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ]
    }
  },
  methods: {
    buildAtlsAndValuesTable(row) {
      // scroll top
      window.scrollTo(0, 0)
      // assign selected
      this.PA_resetValuesOfAttributeValues()
      this.PA_selectedAttribute(row)
      // load secondary tables
      this.PA_searchAtls(row)
    },
    onEvent(value) {
      this.PA_fetchUnitsToEditAttribute(value)
    },
    onSelect(value) {
      this.PA_processSelectedUnitToEditAttribute(value)
    },
    querySearch(queryParams) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PA_searchAttributes(queryParams)
      }, 500)
    },
    openAttributesModal(attribute) {
      let obj = { bool: true, attribute }
      this.PA_openAttributesModal(obj)
    },
    rowDisabled(row) {
      return row.status === "LOCKED"
    },
    ...mapMutations("ProductCatalog", {
      PA_showCreateAttributeModal: "PA_showCreateAttributeModal",
      PA_selectedAttribute: "PA_selectedAttribute",
      PA_resetValuesOfAttributeValues: "PA_resetValuesOfAttributeValues"
    }),
    ...mapActions("ProductCatalog", {
      PA_searchAttributes: "PA_searchAttributes",
      PA_searchAtls: "PA_searchAtls",
      updateCell: "PA_editAttributesCell",
      updateSynonymCell: "PA_editAttributesSynonym",
      PA_openAttributesModal: "PA_openAttributesModal",
      searchValue: "PA_attributeTableSearchValue",
      PA_fetchUnitsToEditAttribute: "PA_fetchUnitsToEditAttribute",
      PA_processSelectedUnitToEditAttribute: "PA_processSelectedUnitToEditAttribute",
      PA_searchValues: "PA_searchValues"
    })
  },
  computed: {
    dataTableColumns() {
      return [
        {
          key: "code",
          title: "Код",
          width: 90,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: "name",
          title: `Название`,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditName,
          editRendererProps: {
            onUpdate: this.updateCell
          },
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: "public_name",
          title: `Название для публик. (${this.PC_selectedLanguage})`,
          editRenderer: InputEditName,
          editRendererProps: {
            onUpdate: this.updateCell
          },
          sorting: true,
          filtering: this.nameFilter
        },
        {
          key: "synonymsNames",
          title: "Синонимы",
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditSynonyms,
          editRendererProps: {
            onUpdate: this.updateSynonymCell
          },
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          width: 140
        },
        {
          key: "default_unit",
          title: "Е. И.",
          width: 60,
          renderer: DefaultUnitName,
          editRenderer: AutocompleteEditUnit,
          editRendererProps: {
            onEvent: this.onEvent,
            onSelect: this.onSelect
          },
          sorting: true,
          filtering: {
            type: "search",
            placeholder: ""
          }
        },
        {
          key: "value_type",
          title: "Тип значения",
          renderer: SelectEditType,
          sorting: true,
          width: 125,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: "description",
          title: `Info (${this.PC_selectedLanguage})`,
          renderer: Description,
          width: 90,
          sorting: true,
          filtering: this.descriptionFilter
        },
        {
          key: "timestamp",
          title: "Дата",
          width: 88,
          sorting: true,
          filtering: {
            type: "dateRange",
            valueTypeTimestamp: true
          },
          renderer: Time
        },
        {
          key: "status",
          title: "Статус",
          width: 75,
          sorting: true,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        }
      ]
    },
    nameFilter() {
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        return {
          type: "search",
          placeholder: "Поиск"
        }
      }
      return false
    },
    descriptionFilter() {
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        return {
          type: "search",
          placeholder: "Поиск"
        }
      }
      return false
    },
    ...mapGetters("ProductCatalog", {
      queryParams: "PA_attributesTableQueryParams",
      tableData: "PA_attributesTableData",
      fetchingStatus: "PA_attributesTableFetchingStatus",
      PA_selectedAttributeGet: "PA_selectedAttribute",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage"
    })
  }
}
</script>
<style lang="scss">
.custom-table .no-padding {
  padding: 0 !important;
}
</style>
