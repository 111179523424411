import { computed } from "vue"
import i18n from "@/i18n"

import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo } = {}) => {
  const returnsColumns = computed(() => {
    return [
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("creatingDate"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
        fixed: "left",
        width: 150
      },
      {
        key: "code",
        dataIndex: "code",
        title: i18n.t("returnNumber"),
        fixed: "left",
        width: 120
      },
      {
        key: "order_code",
        dataIndex: "order_code",
        title: i18n.t("orderNumber"),
        width: 120
      },
      {
        key: "aim",
        dataIndex: "aim",
        title: i18n.t("aimReasonReturn"),
        scopedSlots: {
          customRender: "aim"
        },
        width: 250
      },
      {
        key: "seller",
        dataIndex: "seller",
        title: i18n.t("seller")
      },
      {
        key: "customer_name",
        dataIndex: "customer_name",
        title: i18n.t("customer")
      },
      {
        key: "goods_quantity",
        dataIndex: "goods_quantity",
        title: i18n.t("goodsQuantity"),
        width: 120,
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "goods_quantity" && sortedInfo?.value?.order
      },
      {
        key: "price_total",
        dataIndex: "price_total",
        title: i18n.t("sum"),
        customRender: (sum) => getFormatedSum(sum, true),
        width: 125,
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "price_total" && sortedInfo?.value?.order
      },
      {
        key: "status",
        dataIndex: "status",
        title: i18n.t("returnStatus"),
        scopedSlots: {
          customRender: "status"
        },
        width: 200
      },
      {
        key: "reason",
        dataIndex: "reason",
        title: i18n.t("returnReasons"),
        width: 300
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        fixed: "right",
        width: 40
      }
    ]
  })

  const returnedOffersColumns = computed(() => {
    return [
      {
        key: "index",
        dataIndex: "index",
        title: "№",
        width: 30,
        customRender: (text, record, index) => index + 1
      },
      {
        key: "product.images",
        dataIndex: "images_of_product",
        title: i18n.t("productImages"),
        width: 80,
        scopedSlots: {
          customRender: "photoRenderer"
        }
      },
      {
        key: "images",
        dataIndex: "images_of_offer",
        title: i18n.t("offerImages"),
        width: 80,
        scopedSlots: {
          customRender: "photoRenderer"
        }
      },
      {
        key: "code",
        width: 160,
        scopedSlots: {
          title: "codeTitleRenderer",
          customRender: "codeRenderer"
        }
      },
      {
        key: "product",
        title: i18n.t("orderProductInfo"),
        scopedSlots: {
          customRender: "productNameRenderer"
        }
      },
      {
        key: "warranty",
        dataIndex: "item_in_order.warranty",
        title: i18n.t("warrantyMonth"),
        width: 140
      },
      {
        key: "quantity",
        dataIndex: "quantity",
        title: i18n.t("count"),
        width: 140
      },
      {
        key: "price",
        dataIndex: "item_in_order.price_per_item",
        title: `${i18n.t("price")}, ${i18n.t("uah")}.`,
        customRender: (sum) => getFormatedSum(sum, true),
        width: 140
      },
      {
        key: "price_total",
        dataIndex: "price_total",
        title: `${i18n.t("sum")}, ${i18n.t("uah")}.`,
        customRender: (sum) => getFormatedSum(sum, true),
        width: 140
      }
    ]
  })

  const returnHistoryColumns = computed(() => {
    return [
      {
        key: "index",
        dataIndex: "index",
        title: "№",
        width: 30,
        customRender: (text, record, index) => index + 1
      },
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("editDate"),
        width: 120,
        scopedSlots: { customRender: "dateRenderer" }
      },
      {
        key: "created_by",
        title: i18n.t("editAuthor"),
        scopedSlots: {
          customRender: "editAuthorRenderer"
        }
      },
      {
        key: "edit_object",
        title: i18n.t("editObject"),
        scopedSlots: {
          customRender: "editObjectRenderer"
        }
      }
    ]
  })

  return {
    returnsColumns,
    returnedOffersColumns,
    returnHistoryColumns
  }
}
