<template>
  <div class="rowActions">
    <div class="rowActions__buttons">
      <a-button
        v-for="(action, index) in actions"
        :key="`action-${index}`"
        @click="action.action(row, rowIndex)"
        :icon="action.icon"
        size="small"
        type="primary"
        :disabled="getDisabledStatus(action)"
      >
        <template
          v-if="action.label"
          slot="default"
        >
          {{ action.label }}
        </template>
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RowActions",
  props: {
    actions: {
      type: [Array, Function],
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getDisabledStatus(action) {
      if (typeof action.disabled === "boolean") {
        return action.disabled
      } else if (typeof action.disabled === "function") {
        return action.disabled(this.row)
      } else {
        return this.disabled
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rowActions {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  display: none;
  &__buttons {
    display: flex;
    align-items: center;
    > * {
      margin-left: 5px;
    }
  }
}
</style>
