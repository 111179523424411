import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"

const productTypeAutocomplete = {
  state: {
    PC_selectedProductType: false,
    PC_productTypesAutocomplete: [],
    PC_productTypeObjects: [],
    PC_productTypePlaceholder: ""
  },

  getters: {
    PC_selectedProductType: (state) => state.PC_selectedProductType,
    PC_productTypesAutocomplete: (state) => state.PC_productTypesAutocomplete,
    PC_productTypeObjects: (state) => state.PC_productTypeObjects,
    PC_productTypePlaceholder: (state) => state.PC_productTypePlaceholder
  },

  mutations: {
    PC_setSelectedProductType: (state, selectedType) => {
      state.PC_selectedProductType = selectedType
    },
    PC_setProductTypesAutocomplete: (state, { typesArray }) => {
      state.PC_productTypesAutocomplete = typesArray
    },
    PC_setProductTypeObjects: (state, { types }) => {
      state.PC_productTypeObjects = types
    },
    PC_setProductTypePlaceholder: (state, value) => {
      state.PC_productTypePlaceholder = value
    },
    PC_resetTypeAutocompleteValues: (state, placeholder) => {
      state.PC_selectedProductType = false
      state.PC_productTypesAutocomplete = []
      state.PC_productTypeObjects = []
      state.PC_productTypePlaceholder = ""
    },
    PC_selectedProductType(state, value) {
      state.PC_selectedProductType = value
    }
  },

  actions: {
    PC_fetchProductTypesAutocomplete({ commit }, value) {
      let searchData = buildMagicSearch({
        resource: "PTD",
        field: "full_name",
        value,
        sort: null,
        limit: 20,
        offset: 0
      })
      API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((types) => {
          commit("PC_setProductTypeObjects", { types })
          let typesArray = types.map((item) => {
            return item.full_name
          })
          commit("PC_setProductTypesAutocomplete", { typesArray })
        })
        .catch((e) => {
          commit("handleError", e)
        })
    },
    async PC_processSelectedProductType({ commit, getters, dispatch }, value) {
      let selectedType = getters.PC_productTypeObjects.find((item) => {
        return item.full_name === value
      })
      commit("PC_setSelectedProductType", selectedType)
      commit("PC_setEditableProductType", selectedType)

      await dispatch("PC_updateAttributesChanges")
      let attrs = await dispatch("PC_restartFetchAttributes")

      commit("PC_resetCheckRequiredAttributes")
      commit("PC_resetCheckMainAttributes")
      if (!getters.PC_EditableProduct.type) return
      let missing = await dispatch("PC_checkRequiredAttributeOfType")
      if (missing) {
        dispatch("PC_missingRequiredAttributes")
      }
      let missingMain = await dispatch("PC_checkMainAttributeOfType")
      if (missingMain) {
        dispatch("PC_missingMainAttributes")
      }
    },
    PC_setProductTypePlaceholder({ commit, getters }, placeholder) {
      if (placeholder === "noType") {
        commit("PC_setProductTypePlaceholder", " ") // reset watcher
        setTimeout(() => {
          commit("PC_setProductTypePlaceholder", "")
        }, 0)
      } else {
        commit("PC_setProductTypePlaceholder", placeholder)
      }
    }
  }
}

export default productTypeAutocomplete
