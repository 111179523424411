<template>
  <div class="wrapper">
    <a-table
      :rowKey="(record, index) => `${record.code}_${index}`"
      :columns="columns"
      :data-source="attributes"
      :loading="fetching"
      :locale="tableLocale"
      :components="components"
      :pagination="false"
    >
      <template
        v-for="(title, key) in tableTitles"
        #[key]
      >
        <TitleTooltip
          :shortText="title.shortText"
          :fullText="title.fullText"
          :key="key"
        />
      </template>

      <template #handleRenderer>
        <div class="handle">
          <a-icon
            type="pause"
            :rotate="90"
          />
        </div>
      </template>

      <template #indexRenderer="_, __, index">
        {{ index + 1 }}
      </template>

      <template #unitNameRenderer="record">
        <EditAttributeUnit
          v-if="record.value_type === 'NUMERIC'"
          :attribute="record"
          @change="handleAttributeChanged"
        />
      </template>

      <template #valueRenderer="record">
        <EditAttributeValue
          :attribute="record"
          @change="handleAttributeChanged"
        />
      </template>

      <template #typeRenderer="value">
        <a-tooltip
          placement="top"
          :title="value"
        >
          <div class="text-status">
            {{ getFirstCharset(value) }}
          </div>
        </a-tooltip>
      </template>

      <template #booleanTooltipRenderer="value">
        <a-tooltip
          v-if="value === true || value === false"
          placement="top"
        >
          <template slot="title">
            {{ value === true ? $t("yes") : value === false ? $t("no") : "" }}
          </template>

          <div
            class="status"
            :class="[value ? 'status--success' : 'status--error']"
          />
        </a-tooltip>
      </template>

      <template #statusRenderer="value">
        <a-tooltip
          placement="top"
          :title="value"
        >
          <div
            class="text-status"
            :class="{
              'text-status--success': value === 'ACTIVE',
              'text-status--disabled': value === 'LOCKED'
            }"
          >
            {{ getFirstCharset(value) }}
          </div>
        </a-tooltip>
      </template>

      <template #actionsRenderer="_, record, index">
        <a-icon
          type="delete"
          @click="() => showDeleteAttributeConfirm(record, index)"
        />
      </template>
    </a-table>
  </div>
</template>

<script>
import TitleTooltip from "@/ant-components/TitleTooltip"
import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper"
import EditAttributeValue from "./EditAttributeValue.vue"
import EditAttributeUnit from "./EditAttributeUnit.vue"

export default {
  name: "AttributesTable",

  components: {
    TitleTooltip,
    EditAttributeValue,
    EditAttributeUnit
  },
  provide() {
    const appData = {}

    // For reactive data inject
    Object.defineProperty(appData, "dataSource", {
      enumerable: true,
      get: () => this.attributes
    })

    return {
      appData,
      onSort: this.onSorted
    }
  },
  props: {
    mpcCode: {
      type: String,
      required: true
    },
    attributes: {
      type: Array,
      default: () => []
    },
    fetching: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      booleanSelectItems: [
        {
          text: this.$t("yes"),
          value: true
        },
        {
          text: this.$t("no"),
          value: false
        }
      ],
      tableLocale: {
        filterConfirm: this.$t("search"),
        filterReset: this.$t("reset"),
        emptyText: this.$t("noData")
      },
      components: {
        body: {
          wrapper: TableGraggableWrapper
        }
      },
      fetchedValues: {},
      fetchedUnits: {}
    }
  },

  computed: {
    columns() {
      return [
        {
          key: "handle",
          dataIndex: "handle",
          width: 45,
          scopedSlots: {
            customRender: "handleRenderer"
          }
        },
        {
          key: "index",
          dataIndex: "index",
          width: 50,
          scopedSlots: {
            customRender: "indexRenderer"
          }
        },
        {
          key: "code",
          dataIndex: "code",
          title: "AND-code",
          width: 120
        },
        {
          key: "name",
          dataIndex: "name",
          title: this.$t("name"),
          width: 455
        },
        {
          key: "unit",
          width: 170,
          slots: {
            title: "unitNameTitleRenderer"
          },
          scopedSlots: {
            customRender: "unitNameRenderer"
          }
        },
        {
          key: "value",
          title: this.$t("value"),
          width: 380,
          scopedSlots: {
            customRender: "valueRenderer"
          }
        },
        {
          key: "type",
          dataIndex: "value_type",
          slots: {
            title: "typeTitleRenderer"
          },
          scopedSlots: {
            customRender: "typeRenderer"
          },
          width: 45
        },
        {
          key: "is_main",
          dataIndex: "is_main",
          slots: {
            title: "mainTitleRenderer"
          },
          scopedSlots: {
            customRender: "booleanTooltipRenderer"
          },
          width: 45
        },
        {
          key: "is_required",
          dataIndex: "is_required",
          slots: { title: "requiredTitleRenderer" },
          scopedSlots: {
            customRender: "booleanTooltipRenderer"
          },
          width: 45
        },
        {
          key: "status",
          dataIndex: "status",
          slots: { title: "statusTitleRenderer" },
          scopedSlots: {
            customRender: "statusRenderer"
          },
          width: 45
        },
        {
          key: "actions",
          width: 46,
          scopedSlots: {
            customRender: "actionsRenderer"
          }
        }
      ]
    },

    tableTitles() {
      return {
        unitNameTitleRenderer: {
          shortText: this.$t("unitShorted"),
          fullText: this.$t("unit")
        },
        typeTitleRenderer: {
          shortText: "T",
          fullText: this.$t("type")
        },
        mainTitleRenderer: {
          shortText: "M",
          fullText: "Main"
        },
        requiredTitleRenderer: {
          shortText: "R",
          fullText: "Required"
        },
        statusTitleRenderer: {
          shortText: "S",
          fullText: "Status"
        }
      }
    }
  },
  methods: {
    onSorted() {
      this.$emit("onSorted")
    },

    async handleAttributeChanged(attribute) {
      this.$emit("updateAttribute", attribute)
    },

    getFirstCharset(text) {
      return text?.charAt(0)
    },

    showDeleteAttributeConfirm(attr, index) {
      this.$confirm({
        title: `${this.$t("deleteAttributeConfirm")} ${attr.code}?`,
        cancelText: this.$t("cancel"),
        okText: this.$t("delete"),
        onOk: () => {
          this.$emit("deleteAttribute", index)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.text-status {
  font-weight: 500;

  &--success {
    color: $green-color;
  }

  &--disabled {
    color: #c6c6c6;
  }

  &--error {
    color: $red-color;
  }
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &--success {
    background-color: #b7eb8f;
  }

  &--error {
    background-color: #ff4d4f;
  }
}
</style>

<style lang="scss">
.editable-cell {
  & .ant-input-number-disabled,
  & .ant-input-disabled {
    background-color: hsl(0, 0%, 98%);
    color: rgba(0, 0, 0, 0.65);
  }

  & .ant-select-disabled {
    & * {
      background: hsl(0, 0%, 98%);

      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
