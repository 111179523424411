import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  PV_searchedListsWithValue: false,
  PV_searchValueInListModal: false,
  PV_valueToSearchInLists: false
})

const getters = {
  PV_searchedListsWithValue: (state) => state.PV_searchedListsWithValue,
  PV_searchValueInListModal: (state) => state.PV_searchValueInListModal,
  PV_valueToSearchInLists: (state) => state.PV_valueToSearchInLists
}

const mutations = {
  PV_searchedListsWithValue(state, value) {
    state.PV_searchedListsWithValue = value
  },
  PV_searchValueInListModal(state, value) {
    state.PV_searchValueInListModal = value
  },
  PV_valueToSearchInLists(state, value) {
    state.PV_valueToSearchInLists = value
  }
}

const actions = {
  async PV_searchValueInLists({ commit, getters, dispatch }, value) {
    try {
      commit("PC_showLoader", true)
      commit("PV_valueToSearchInLists", value)
      await dispatch("PV_searchValueInListsRequest", value)
      commit("PV_searchValueInListModal", true)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PV_searchValueInListsRequest({ commit, getters, dispatch }, value) {
    let sendData = buildMagicSearch({
      resource: "VLD",
      field: "value",
      value,
      sort: "description",
      limit: 50,
      offset: 0
    })

    return API_MODERATION.post(
      // get values lists
      `${apiUrls.searchMagic}`,
      sendData
    )
      .then((r) => r.data.message.data)
      .then((r) => {
        commit("PV_searchedListsWithValue", r)
        return r
      })
      .catch((e) => {
        commit("handleError", e)
        return Promise.reject()
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
