import { ref, onUnmounted } from "vue"

export default (makeRequestFunction) => {
  const abortController = ref()

  const makeRequest = async (args) => {
    if (abortController.value) {
      abortController.value.abort()
      abortController.value = undefined
    }

    abortController.value = new AbortController()

    const params = { ...args, signal: abortController.value.signal }
    return await makeRequestFunction(params)
  }

  const cancelRequest = () => {
    if (abortController.value) {
      abortController.value.abort()
      abortController.value = undefined
    }
  }

  onUnmounted(cancelRequest)

  return { makeRequest, cancelRequest }
}
