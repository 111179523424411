import { escapedRegExp } from "../utils"
import { getElementOffset } from "utils/DOM"

/* mixin for all */
export default {
  props: {
    id: {
      default: null
    },
    name: {
      type: String,
      default: ""
    },
    isError: {
      type: Boolean,
      default: false
    },
    customAttr: {
      type: Function,
      default: () => ""
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    filterPredicate: {
      type: Function,
      default: (text, inputText) => {
        return text.match(escapedRegExp(inputText))
      }
    }
  }
  // methods: {
  //   scroll() {
  //     const select = this.$refs.select

  //     this.offset = getElementOffset(select)
  //     this.elementHeight = select.clientHeight
  //     const width = this.dropdownWidth || select.clientWidth
  //     this.elementWidth = width
  //   }
  // },
  // mounted() {
  //   window.addEventListener('scroll', this.scroll)
  // },
  // destroyed () {
  //   window.removeEventListener('scroll', this.scroll)
  // }
}
