import buildMagicSearchComplex from "@/modules/ProductCatalog/store/utils/buildMagicSearchComplex"
import { API_MODERATION } from "@/utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

const state = () => ({
  PA_attributesTableQueryParams: {
    limit: 10,
    ordering: ["-timestamp"],
    offset: 0
  },
  PA_attributesTableData: { count: 0, items: [] },
  PA_attributesTableFetchingStatus: false,
  PA_selectedAttribute: false
})

const getters = {
  PA_attributesTableQueryParams: (state) => state.PA_attributesTableQueryParams,
  PA_attributesTableData: (state) => state.PA_attributesTableData,
  PA_attributesTableFetchingStatus: (state) => state.PA_attributesTableFetchingStatus,
  PA_selectedAttribute: (state) => state.PA_selectedAttribute
}

const mutations = {
  PA_attributesTableQueryParams(state, value) {
    state.PA_attributesTableQueryParams = value
  },
  PA_attributesTableData(state, value) {
    state.PA_attributesTableData = value
  },
  PA_attributesTableFetchingStatus(state, value) {
    state.PA_attributesTableFetchingStatus = value
  },
  PA_attributesTableSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    state.PA_attributesTableData.items[rowIndex][columnKey] = newValue
  },
  PA_attributesTableSetNewUnitValue: (state, { newValue, rowIndex }) => {
    // if unit is created (in contrary to editing of existing one) - create the unit object first:
    if (!state.PA_attributesTableData.items[rowIndex].default_unit[0]) {
      state.PA_attributesTableData.items[rowIndex].default_unit = [{ name: "" }]
    }
    state.PA_attributesTableData.items[rowIndex].default_unit[0].name = newValue
  },
  PA_attributesTableSetNewArrayValue: (state, { newValue, rowIndex }) => {
    state.PA_attributesTableData.items[rowIndex].synonyms = newValue
  },
  PA_selectedAttribute(state, value) {
    state.PA_selectedAttribute = value
  },
  PA_selectedAttributeType(state, value) {
    state.PA_selectedAttribute.value_type = value
  },
  PA_resetAttributesTableValues(state) {
    state.PA_attributesTableQueryParams = {
      limit: 10,
      ordering: ["-timestamp"],
      offset: 0
    }
    state.PA_attributesTableData = { count: 0, items: [] }
  }
}

const actions = {
  async PA_searchAttributes({ commit, dispatch, getters, rootState }, queryParams) {
    commit("PA_attributesTableData", { count: 0, items: [] })
    let response = await dispatch("PA_searchAttributesInDB", queryParams)
    if (!response) return

    commit("PA_selectedAttribute", response[0])
    let selected = response[0]
    if (response.length) {
      await dispatch("PA_searchAtls", selected)
      await dispatch("PA_searchValues", selected)
    }
    return Promise.resolve
  },
  async PA_searchAttributesForSelected({ commit, dispatch, getters, rootState }, queryParams) {
    let response = await dispatch("PA_searchAttributesInDB", queryParams)
    if (!response) return

    let selected = getters.PA_selectedAttribute
    if (response.length) {
      await dispatch("PA_searchAtls", selected)
      await dispatch("PA_searchValues", selected)
    }
  },
  async PA_searchAttributesInDB({ commit, dispatch, getters }, queryParams) {
    if (!queryParams) queryParams = getters.PA_attributesTableQueryParams

    let fields = [
      "code",
      "name",
      "public_name",
      "synonyms",
      "default_unit.name",
      "value_type",
      "description",
      "timestamp",
      "status"
    ]
    let resource = "AND"

    let sort = buildSortValue(queryParams.ordering[0])
    let sendData = buildMagicSearchComplex(queryParams, fields, resource, sort)

    let attributesCount
    let attributes = []
    // set fetching status
    commit("PA_attributesTableFetchingStatus", true)

    return API_MODERATION.post(
      // search attributes
      `${apiUrls.searchMagic}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        attributesCount = message.count
        attributes = message.data

        let items = buildAttributesTableItems(attributes)

        let tableObj = {
          count: attributesCount,
          items: items
        }

        commit("PA_attributesTableData", tableObj)
        commit("PA_attributesTableFetchingStatus", false)

        // update table query params
        commit("PA_attributesTableQueryParams", queryParams)

        return attributes
      })
      .catch((e) => {
        commit("PA_attributesTableFetchingStatus", false)
        commit("handleError", e)

        return null
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildSortValue(colName) {
  let sort = colName
  switch (colName) {
    case "default_unit":
      sort = "default_unit.name"
      break
    case "-default_unit":
      sort = "-default_unit.name"
      break
    case "synonymsNames":
      sort = "synonyms"
      break
    case "-synonymsNames":
      sort = "-synonyms"
      break
  }
  return sort
}

function buildAttributesTableItems(attributes) {
  return attributes.map((item, index) => {
    // synonyms
    item.synonymsNames = item.synonyms.join(", ")
    // time
    item.timestamp = getFormatedDateTimeByTimestamp(item.timestamp)
    // id
    item._id = "attributesTableRow-" + item.timestamp
    return item
  })
}
