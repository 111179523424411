import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const healthStatuses = computed(() => [
    {
      title: i18n.t("critical"),
      value: 0,
      color: "#FF7A00"
    },
    {
      title: i18n.t("low"),
      value: 1,
      color: "#F5A80F"
    },
    {
      title: i18n.t("good"),
      value: 2,
      color: "#219653"
    }
  ])

  return {
    healthStatuses
  }
}
