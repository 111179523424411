import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PA_showCreateAttributeModal: false,
  PA_nameForNewAttribute: false,
  PA_publicName: false,
  PA_selectedAttributeTypeToCreateNew: "NUMERIC",
  PA_typesForNewAttribute: [],
  PA_selectedProductTypeToCreateNew: false,
  PA_typesForNewAttributeAutocomplete: [],
  PA_unitsForNewAttribute: [],
  PA_selectedUnitForNewAttribute: false,
  PA_unitsForNewAttributeAutocomplete: [],
  PA_listsForNewAttributeAutocomplete: [],
  PA_attributeIsMain: false,
  PA_attributeIsRequired: false,
  PA_valuesForNewAttribute: false,
  PA_selectedListName: false
})

const getters = {
  PA_showCreateAttributeModal: (state) => state.PA_showCreateAttributeModal,
  PA_nameForNewAttribute: (state) => state.PA_nameForNewAttribute,
  PA_publicName: (state) => state.PA_publicName,
  PA_selectedAttributeTypeToCreateNew: (state) => state.PA_selectedAttributeTypeToCreateNew,
  PA_typesForNewAttribute: (state) => state.PA_typesForNewAttribute,
  PA_selectedProductTypeToCreateNew: (state) => state.PA_selectedProductTypeToCreateNew,
  PA_typesForNewAttributeAutocomplete: (state) => state.PA_typesForNewAttributeAutocomplete,
  PA_unitsForNewAttribute: (state) => state.PA_unitsForNewAttribute,
  PA_selectedUnitForNewAttribute: (state) => state.PA_selectedUnitForNewAttribute,
  PA_unitsForNewAttributeAutocomplete: (state) => state.PA_unitsForNewAttributeAutocomplete,
  PA_listsForNewAttributeAutocomplete: (state) => state.PA_listsForNewAttributeAutocomplete,
  PA_attributeIsMain: (state) => state.PA_attributeIsMain,
  PA_attributeIsRequired: (state) => state.PA_attributeIsRequired,
  PA_valuesForNewAttribute: (state) => state.PA_valuesForNewAttribute,
  PA_selectedListName: (state) => state.PA_selectedListName
}

const mutations = {
  PA_showCreateAttributeModal(state, value) {
    state.PA_showCreateAttributeModal = value
  },
  PA_nameForNewAttribute(state, value) {
    state.PA_nameForNewAttribute = value
  },
  PA_setPublicName(state, value) {
    state.PA_publicName = value
  },
  PA_selectedAttributeTypeToCreateNew(state, value) {
    state.PA_selectedAttributeTypeToCreateNew = value
  },
  PA_typesForNewAttribute(state, value) {
    state.PA_typesForNewAttribute = value
  },
  PA_selectedProductTypeToCreateNew(state, value) {
    state.PA_selectedProductTypeToCreateNew = value
  },
  PA_typesForNewAttributeAutocomplete(state, value) {
    state.PA_typesForNewAttributeAutocomplete = value
  },
  PA_unitsForNewAttribute(state, value) {
    state.PA_unitsForNewAttribute = value
  },
  PA_selectedUnitForNewAttribute(state, value) {
    state.PA_selectedUnitForNewAttribute = value
  },
  PA_unitsForNewAttributeAutocomplete(state, value) {
    state.PA_unitsForNewAttributeAutocomplete = value
  },
  PA_listsForNewAttributeAutocomplete(state, value) {
    state.PA_listsForNewAttributeAutocomplete = value
  },
  PA_attributeIsMain(state, value) {
    state.PA_attributeIsMain = value
  },
  PA_attributeIsRequired(state, value) {
    state.PA_attributeIsRequired = value
  },
  PA_valuesForNewAttribute(state, value) {
    state.PA_valuesForNewAttribute = value
  },
  PA_selectedListName(state, value) {
    state.PA_selectedListName = value
  }
}

const actions = {
  async PA_createNewAttribute({ commit, getters, dispatch }) {
    // create attribute
    let response = await dispatch("PA_createNewAttributeInDatabase")
    if (!response) return
    // edit ATL (auto-created along with attribute)
    let msg = await dispatch("PA_editNewAtlInDatabase", response.atlCode)
    if (!msg) return
    // if attribute-type is 'list', submit attribute to all values of the selected list
    if (
      getters.PA_selectedAttributeTypeToCreateNew === "LIST" ||
      getters.PA_selectedAttributeTypeToCreateNew === "MULTILIST"
    ) {
      if (getters.PA_valuesForNewAttribute) {
        msg = await dispatch("PA_addNewAttributeToSelectedList", response.attributeCode)
        if (!msg) return
      }
    }
    // reset values
    dispatch("PA_resetCreateAttributeValues")
    restartTableSearch({ getters, dispatch })
  },
  async PA_createNewAttributeInDatabase({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)
    let attribute = {
      name: getters.PA_nameForNewAttribute,
      public_name: getters.PA_publicName,
      value_type: getters.PA_selectedAttributeTypeToCreateNew,
      product_type: getters.PA_selectedProductTypeToCreateNew.code
    }
    if (
      getters.PA_selectedUnitForNewAttribute &&
      getters.PA_selectedAttributeTypeToCreateNew === "NUMERIC"
    ) {
      attribute.default_unit = getters.PA_selectedUnitForNewAttribute.code
    }
    // start request
    return API_MODERATION.post(`${apiUrls.attribute}`, attribute)
      .then((r) => r.data.message)
      .then((msg) => {
        // hide loader
        commit("PC_showLoader", false)
        return { attributeCode: msg.code, atlCode: msg.atl }
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PA_resetCreateAttributeValues")
        commit("handleError", e)
        return null
      })
  },
  async PA_editNewAtlInDatabase({ commit, getters, dispatch }, atlCode) {
    // show loader
    commit("PC_showLoader", true)
    let atl = {
      code: atlCode,
      link: {
        is_main: getters.PA_attributeIsMain,
        is_required: getters.PA_attributeIsRequired
      }
    }
    // start request
    return API_MODERATION.put(`${apiUrls.link}`, atl)
      .then((r) => r.data.message)
      .then((msg) => {
        // hide loader
        commit("PC_showLoader", false)
        notification.info({ message: "Новый атрибут добавлен" })
        return msg
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PA_resetCreateAttributeValues")
        commit("handleError", e)
        return null
      })
  },
  async PA_addNewAttributeToSelectedList({ commit, getters, dispatch }, attributeCode) {
    // show loader
    commit("PC_showLoader", true)
    let values = getters.PA_valuesForNewAttribute
    let sendData = {
      description: values[0].description,
      attribute: attributeCode,
      action: "ADD"
    }
    return API_MODERATION.patch(`${apiUrls.lists}`, sendData)
      .then((r) => {
        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PA_resetCreateAttributeValues")
        commit("handleError", e)
        return null
      })
  },
  async PA_resetCreateAttributeValues({ commit }) {
    commit("PA_nameForNewAttribute", false)
    commit("PA_setPublicName", false)
    commit("PA_selectedAttributeTypeToCreateNew", "NUMERIC")
    commit("PA_typesForNewAttribute", [])
    commit("PA_selectedProductTypeToCreateNew", false)
    commit("PA_typesForNewAttributeAutocomplete", [])
    commit("PA_unitsForNewAttribute", [])
    commit("PA_selectedUnitForNewAttribute", false)
    commit("PA_unitsForNewAttributeAutocomplete", [])
    commit("PA_attributeIsMain", false)
    commit("PA_attributeIsRequired", false)
    commit("PA_valuesForNewAttribute", false)
    commit("PA_selectedListName", false)
  },
  PA_fetchTypesForNewAttribute({ commit }, value) {
    let searchData = buildMagicSearch({
      resource: "PTD",
      field: "full_name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((types) => {
        commit("PA_typesForNewAttribute", types)
        let typesArray = types.map((item) => {
          return item.full_name
        })
        commit("PA_typesForNewAttributeAutocomplete", typesArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PA_processSelectedTypeForNewAttribute({ commit, getters }, value) {
    let selectedType = getters.PA_typesForNewAttribute.find((item) => {
      return item.full_name === value
    })
    commit("PA_selectedProductTypeToCreateNew", selectedType)
  },
  PA_fetchUnitsForNewAttribute({ commit }, value) {
    let searchData = buildMagicSearch({
      resource: "AUD",
      field: "name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((units) => {
        commit("PA_unitsForNewAttribute", units)
        let unitsArray = units.map((item) => {
          return item.name
        })
        commit("PA_unitsForNewAttributeAutocomplete", unitsArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PA_processSelectedUnitForNewAttribute({ commit, getters }, value) {
    let selectedUnit = getters.PA_unitsForNewAttribute.find((item) => {
      return item.name === value
    })
    commit("PA_selectedUnitForNewAttribute", selectedUnit)
  },
  PA_searchListsForNewAttribute({ commit, getters }, value) {
    let lists = getters.PV_initialValuesDescriptionItems
    if (!lists) return

    let listsNamesArray = lists.map((item) => {
      return item.description
    })

    let filteredLists = listsNamesArray.filter((item) => {
      return item.trim().toLocaleLowerCase().indexOf(value.trim().toLocaleLowerCase()) !== -1
    })

    commit("PA_listsForNewAttributeAutocomplete", filteredLists)
  },
  PA_processSelectedListForNewAttribute({ commit, getters }, value) {
    commit("PA_selectedListName", value)
    // search values of selected list
    let searchData = buildMagicSearch({
      resource: "VLD",
      field: "description",
      value,
      sort: null,
      limit: null,
      offset: null
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((values) => {
        commit("PA_valuesForNewAttribute", values)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function restartTableSearch({ getters, dispatch }) {
  dispatch("PA_searchAttributes", getters.PA_attributesTableQueryParams)
}
