import { computed } from "vue"
import i18n from "@/i18n"

const useDMColumns = () =>
  computed(() => [
    {
      key: "is_seller_filter",
      dataIndex: "is_seller_filter",
      title: i18n.t("sellerFilter"),
      scopedSlots: {
        customRender: "isFiltered"
      },
      align: "center",
      fixed: "left",
      width: 100
    },
    {
      key: "name_uk",
      dataIndex: "name.uk",
      title: "UA",
      fixed: "left",
      width: 250
    },
    {
      key: "name_ru",
      dataIndex: "name.ru",
      title: "RU",
      width: 250
    },
    {
      key: "name_en",
      dataIndex: "name.en",
      title: "EN",
      width: 250
    },
    {
      key: "type_name",
      dataIndex: "type",
      title: i18n.t("type"),
      scopedSlots: {
        customRender: "type"
      },
      width: 100
    },
    {
      key: "ordering",
      dataIndex: "ordering",
      title: i18n.t("ordering"),
      width: 80
    },
    {
      key: "payment_methods",
      dataIndex: "payment_methods",
      title: i18n.t("paymentMethods"),
      scopedSlots: {
        customRender: "paymentMethods"
      },
      width: 500
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 50
    }
  ])

export default useDMColumns
