<template>
  <div
    class="image-loader-wrapper"
    :class="{
      'image-loader-wrapper--empty': !fullImageUrl && !isLoading
    }"
  >
    <div class="image-loader-wrapper__item">
      <img
        v-if="fullImageUrl"
        :src="fullImageUrl"
      />

      <a-spin
        v-if="isLoading"
        class="loader-section"
      />

      <a-empty
        v-if="!fullImageUrl && !isLoading"
        class="empty-text"
        :imageStyle="{ fontSize: '48px', lineHeight: '48px', height: '48px' }"
        @click="handleInputClick()"
      >
        <template #image>
          <a-icon type="file-add" />
        </template>

        <template #description>
          {{ $t("addPhoto") }}
        </template>
      </a-empty>

      <template v-if="!imageFile">
        <a-button
          v-if="fullImageUrl || image.url"
          icon="edit"
          type="primary"
          size="small"
          class="icon_btn"
          @click="handleInputClick()"
        />

        <a-button
          v-if="image.url"
          class="icon_btn remove"
          icon="delete"
          type="danger"
          size="small"
          @click="handleRemove"
        >
        </a-button>
      </template>

      <span
        v-if="uploadPromise && imageFile && !isLoading"
        class="footer-btns"
      >
        <a-button
          type="danger"
          size="small"
          @click="onCancel"
        >
          {{ $t("cancel") }}</a-button
        >

        <a-button
          type="primary"
          size="small"
          icon="cloud-upload"
          @click="onUpload"
        >
          {{ $t("loadOnCDN") }}
        </a-button>
      </span>

      <input
        ref="inputRef"
        type="file"
        :accept="acceptMimeTypes"
        v-show="false"
        @change="onFilesChange"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue"
import { notification } from "ant-design-vue"

import getImagePath from "@/utils/getImagePath.js"
import { IMAGES_CDN } from "@/constants/common.js"
import i18n from "@/i18n"

export default {
  props: {
    image: {
      type: Object,
      required: false
    },

    parseCDN: {
      type: Boolean,
      default: false
    },

    options: {
      type: Object,
      required: false
    },

    allowedFormats: {
      type: Array,
      default: () => ["jpg", "jpeg", "png"]
    },

    uploadPromise: {
      type: Function,
      required: false
    },

    removingPromise: {
      type: Function,
      required: false
    }
  },

  setup(props, ctx) {
    const { emit } = ctx

    const inputRef = ref()

    const imageFile = ref(null)
    const imageUrl = ref("")
    const reading = ref(false)
    const fetching = ref(false)
    const fetchingInterval = ref()

    const fullImageUrl = ref("")

    const isLoading = computed(() => {
      return reading.value || fetching.value
    })

    const acceptMimeTypes = computed(() => {
      return props.allowedFormats.map((format) => `image/${format}`).join(", ")
    })

    const prefetchVideo = async () => {
      try {
        fetching.value = true

        const response = await fetch(
          `${IMAGES_CDN}/media/assets/images/${getImagePath(props.image.url, "full")}`,
          {
            cache: "no-store"
          }
        )

        if (!response.ok) {
          throw new Error([i18n.t("imageLoadingError")])
        }

        const blob = new Blob([new Uint8Array(await response.arrayBuffer())], {
          type: "image/*"
        })

        fullImageUrl.value = URL.createObjectURL(blob)

        fetching.value = false
      } catch {
        fetchingInterval.value = setTimeout(() => {
          prefetchVideo()
        }, 5000)
      }
    }

    const getImageSrc = async () => {
      if (imageUrl.value) {
        fullImageUrl.value = imageUrl.value
        return
      }

      if (!props.image?.url) {
        fullImageUrl.value = ""
        return
      }

      if (props.parseCDN) {
        prefetchVideo()
        return
      }

      fullImageUrl.value = ""
    }

    const clearData = () => {
      fullImageUrl.value = ""
      imageFile.value = null
      imageUrl.value = ""
      reading.value = false
      fetching.value = false
      clearTimeout(fetchingInterval.value)
      fetchingInterval.value = undefined
    }

    const handleInputClick = () => {
      if (reading.value) return

      inputRef.value.click()
    }

    const onUpload = async () => {
      try {
        reading.value = true
        const data = {
          target: imageFile.value
        }

        if (props.options) {
          data.options = props.options
        }

        await props.uploadPromise(data)
        clearData()
      } finally {
        reading.value = false
      }
    }

    const isValidImage = (file) => {
      return new Promise((res, rej) => {
        const image = new Image()

        image.onload = () => res("Image is valid")
        image.onerror = () => rej(i18n.t("imageNotSupported"))

        image.src = URL.createObjectURL(file)
      })
    }

    const validateFormat = (file, allowedFormats) => {
      return new Promise((res, rej) => {
        const isValidFormat = allowedFormats.some((format) => file.name.endsWith(format))

        if (isValidFormat) {
          return res("Image have accepted format")
        }

        return rej(i18n.t("unsupportedFormat"))
      })
    }

    const startRemoving = async () => {
      try {
        reading.value = true
        const data = {
          target: imageFile.value
        }

        if (props.options) {
          data.options = props.options
        }

        await props.removingPromise(data)

        clearData()
      } finally {
        reading.value = false
      }
    }

    const handleRemove = () => {
      if (props.removingPromise) {
        startRemoving()
        return
      }

      const data = props.options ? { options: props.options } : {}

      emit("delete", data)
    }

    const onFilesChange = async (e) => {
      clearData()

      const [file] = e.target.files
      if (!file) return

      try {
        reading.value = true

        await validateFormat(file, props.allowedFormats)
        await isValidImage(file)

        imageFile.value = file

        const fileReader = new FileReader()

        fileReader.addEventListener("load", (event) => {
          fullImageUrl.value = event.target.result
        })

        fileReader.readAsDataURL(file)
      } catch (e) {
        notification.error({ message: i18n.t("error"), description: e })
      } finally {
        reading.value = false
      }
    }

    const onCancel = () => {
      clearData()
      getImageSrc()
    }

    onMounted(getImageSrc)

    watch(() => props.image, getImageSrc, { deep: true })

    onBeforeUnmount(clearData)

    return {
      inputRef,

      imageFile,
      imageUrl,
      reading,
      fullImageUrl,

      isLoading,
      getImageSrc,
      acceptMimeTypes,

      handleInputClick,
      onUpload,
      onCancel,
      handleRemove,
      clearData,
      onFilesChange
    }
  }
}
</script>

<style lang="scss" scoped>
.image-loader-wrapper {
  box-shadow: $light-shadow;
  border: 1px solid $light-border;
  border-radius: 3px;

  position: relative;
  width: 100%;
  height: 100%;

  padding: 8px;

  transition: 0.3s;

  &--empty:hover {
    border-color: $primary-color;
  }

  &__item {
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }
}

.empty-text {
  width: 100%;
  height: 100%;
  margin: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  border-radius: inherit;
  color: $primary-color;

  cursor: pointer;
}

.loader-section {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color-mix(in hsl shorter hue, $background-color, transparent 15%);
}

.footer-btns {
  cursor: default;

  padding: 5px;
  background-color: color-mix(in hsl shorter hue, #000, transparent 15%);

  color: $background-color;
  font-size: 12px;
  line-height: 21px;

  width: 100%;
  margin-top: auto;
  z-index: 1;

  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0 16px;
}

.icon_btn {
  position: absolute;
  top: 5px;
  right: 5px;

  &.remove {
    right: 32px;
  }

  &.return {
    right: 32px;
  }
}
</style>
