import { API_ADS } from "@/utils/HttpUtils.js"
import { transformQuery } from "./servicesUtils.js"

export const fetchRefferers = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `v1/admin/referrers/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const subtractRef = (referralCode) => {
  return API_ADS.post(`/v1/admin/referrers/${referralCode}/commit-ref/`)
}
