<template>
  <a-drawer
    :visible="isOpened"
    :title="$t('invoiceCreating')"
    :width="640"
    :bodyStyle="{ paddingBottom: '80px' }"
    @close="handleCancel"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      layout="vertical"
    >
      <a-divider>
        {{ $t("payerInformation") }}
      </a-divider>

      <!-- Seller select START -->
      <div style="display: flex">
        <div style="margin-right: 8px; width: 32px; height: 32px">
          <div
            style="
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 25px;
            "
          >
            <img
              v-if="selectedSellerLogo"
              :src="selectedSellerLogo"
              style="width: 100%"
            />
            <img
              v-else
              src="@/assets/images/img-placeholder.png"
              style="width: 100%"
            />
          </div>
        </div>
        <a-form-model-item
          :label="$t('seller')"
          style="width: calc(100% - 40px)"
          prop="seller"
        >
          <a-select
            v-model="form.seller"
            autocomplete="off"
            showSearch
            :placeholder="$t('enterSellerName')"
            optionLabelProp
            style="width: 100%"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="sellersFetching ? undefined : null"
            @search="handleSearchSellers"
            @change="handleSellerSelect"
          >
            <a-spin
              v-if="sellersFetching"
              slot="notFoundContent"
              size="small"
            />
            <a-select-option
              v-for="item in sellers"
              :key="item.uuid"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <!-- Seller select END -->

      <div style="display: flex">
        <!-- Seller type select START -->
        <a-form-model-item
          :label="$t('sellerTypePlaceholder')"
          prop="seller_type"
        >
          <a-select
            v-model="form.seller_type"
            autocomplete="off"
            :placeholder="$t('sellerTypePlaceholder')"
            style="width: 160px; margin-right: 8px"
            :default-active-first-option="false"
          >
            <a-select-option key="FN">
              {{ $t("sellerTypePib") }}
            </a-select-option>
            <a-select-option key="IE">
              {{ $t("sellerTypeFop") }}
            </a-select-option>
            <a-select-option key="PLC">
              {{ $t("sellerTypeTov") }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- Seller type select START -->

        <!-- Payer input START -->
        <a-form-model-item
          :label="$t('payer')"
          :help="$t('payerHelpText')"
          style="width: calc(100% - 168px)"
          prop="payer"
        >
          <a-input
            v-model="form.payer"
            autocomplete="off"
          />
        </a-form-model-item>
        <!-- Payer input START -->
      </div>

      <div v-if="form.seller_type === 'IE' || form.seller_type === 'PLC'">
        <!-- VAT, EDRPOU, IPN fields START -->
        <div style="display: flex">
          <!-- Is vat payer input START -->
          <a-form-model-item
            :label="$t('isVatPayer')"
            style="width: 160px; margin-right: 8px"
            prop="is_vat_payer"
          >
            <a-select
              v-model="form.is_vat_payer"
              autocomplete="off"
            >
              <a-select-option key="yes">
                {{ $t("yes") }}
              </a-select-option>
              <a-select-option key="no">
                {{ $t("no") }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- Is vat payer input END -->

          <!-- EDRPOU input START -->
          <a-form-model-item
            :label="$t('edrpou')"
            style="width: 160px; margin-right: 8px"
            prop="edrpou"
          >
            <a-input
              v-model="form.edrpou"
              autocomplete="off"
            />
          </a-form-model-item>
          <!-- EDRPOU input END -->

          <!-- IPN input START -->
          <a-form-model-item
            :label="$t('ipn')"
            style="width: 160px; margin-right: 8px"
            prop="ipn"
          >
            <a-input
              v-model="form.ipn"
              autocomplete="off"
            />
          </a-form-model-item>
          <!-- IPN input END -->
        </div>
        <!-- VAT, EDRPOU, IPN fields END -->

        <!-- Address START -->
        <div>
          <a-divider>
            {{ $t("invoiceAddressDivider") }}
          </a-divider>

          <!-- Post code START -->
          <a-form-model-item
            :label="$t('postCode')"
            style="width: 160px"
            prop="postal_code"
          >
            <a-input
              v-model="form.postal_code"
              autocomplete="off"
              @paste="onPaste"
            />
          </a-form-model-item>
          <!-- Post code END -->

          <a-row :gutter="8">
            <!-- Region START -->
            <a-col :span="14">
              <a-form-model-item
                :label="$t('region')"
                prop="region_name"
              >
                <a-input
                  v-model="form.region_name"
                  autocomplete="off"
                  @paste="onPaste"
                />
              </a-form-model-item>
            </a-col>
            <!-- Region END -->

            <!-- Region START -->
            <a-col :span="10">
              <a-form-model-item
                :label="$t('settlement')"
                prop="city_name"
              >
                <a-input
                  v-model="form.city_name"
                  autocomplete="off"
                  @paste="onPaste"
                />
              </a-form-model-item>
            </a-col>
            <!-- Region END -->
          </a-row>

          <a-row :gutter="8">
            <a-col :span="14">
              <!-- Street START -->
              <a-form-model-item
                :label="$t('streetName')"
                prop="street_name"
              >
                <a-input
                  v-model="form.street_name"
                  autocomplete="off"
                  @paste="onPaste"
                />
              </a-form-model-item>
              <!-- Street END -->
            </a-col>

            <a-col :span="10">
              <a-row :gutter="8">
                <!-- House START -->
                <a-col :span="12">
                  <a-form-model-item
                    :label="$t('houseNumber')"
                    prop="house_number"
                  >
                    <a-input
                      v-model="form.house_number"
                      autocomplete="off"
                      @paste="onPaste"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- House END -->

                <!-- Cabinet START -->
                <a-col :span="12">
                  <a-form-model-item
                    :label="`${$t('cabinet')} (${$t('apartmentShort')})`"
                    prop="cabinet"
                  >
                    <a-input
                      v-model="form.cabinet"
                      @paste="onPaste"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- Cabinet END -->
              </a-row>
            </a-col>
          </a-row>
        </div>
        <!-- Address END -->
      </div>

      <!-- SUM detail START -->
      <div>
        <a-divider>
          {{ $t("invoiceSumDivider") }}
        </a-divider>

        <div style="display: flex">
          <!-- SUM with VAT input START -->
          <a-form-model-item
            :label="$t('sumWithVat')"
            style="width: 160px; margin-right: 8px"
            prop="amount_with_vat"
          >
            <a-input-number
              v-model="form.amount_with_vat"
              autocomplete="off"
              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
              :precision="2"
              decimal-separator=","
              style="width: 100%"
            />
          </a-form-model-item>
          <!-- SUM with VAT input END -->

          <!-- SUM without VAT input START -->
          <a-form-model-item
            :label="$t('sumWithoutVat')"
            style="width: 160px; margin-right: 8px"
          >
            <a-input-number
              :value="amount_without_vat"
              autocomplete="off"
              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
              :precision="2"
              decimal-separator=","
              disabled
              style="width: 100%"
            />
          </a-form-model-item>
          <!-- SUM without VAT input END -->

          <!-- VAT input START -->
          <a-form-model-item
            :label="$t('vat')"
            style="width: 160px"
          >
            <a-input-number
              :value="vat"
              autocomplete="off"
              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
              :precision="2"
              decimal-separator=","
              disabled
              style="width: 100%"
            />
          </a-form-model-item>
          <!-- VAT input END -->
        </div>

        <a-form-model-item
          :label="$t('comment')"
          prop="comment"
        >
          <a-textarea
            v-model="form.comment"
            autocomplete="off"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('dateInPdf')"
          prop="issued_at"
        >
          <a-date-picker
            v-model="form.issued_at"
            autocomplete="off"
            format="DD.MM.YYYY"
          />
        </a-form-model-item>
      </div>
      <!-- SUM detail END -->
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        key="back"
        @click="handleCancel"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="isFetching"
        @click="handleCreateInvoice"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { nextTick, reactive, ref, computed } from "vue"
import { notification } from "ant-design-vue"
import moment from "moment"
import i18n from "@/i18n"

import { IMAGES_CDN } from "@/constants/common.js"

import getImagePath from "@/utils/getImagePath.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

import { createInvoice, fetchInvoices } from "@/modules/MPAdmin/services/invoicesService.js"
import { fetchSellers } from "@/modules/MPAdmin/services/sellersService.js"

import useCancellableRequest from "@/composables/useCancellableRequest.js"

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(["onCreated", "close"])

const { makeRequest } = useCancellableRequest(fetchSellers)

const isFetching = ref(false)
const lastInvoiceFetching = ref(false)
const sellers = ref([])
const sellersFetching = ref(false)
const selectedSellerLogo = ref("")

const formRef = ref()
const form = reactive({
  seller: "",
  seller_type: "",
  payer: "",
  is_vat_payer: "yes",
  edrpou: "",
  ipn: "",
  postal_code: "",
  region_name: "",
  city_name: "",
  street_name: "",
  house_number: "",
  cabinet: "",
  amount_with_vat: "",
  comment: "",
  issued_at: moment(new Date())
})

const requiredIpn = computed(() => {
  return (form.seller_type === "IE" || form.seller_type === "PLC") && form.is_vat_payer === "yes"
})
const requiredAddress = computed(() => {
  return (form.seller_type === "IE" && form.is_vat_payer === "yes") || form.seller_type === "PLC"
})
const formRules = computed(() => ({
  seller: [{ required: true, message: i18n.t("fieldRequire") }],
  seller_type: [{ required: true, message: i18n.t("fieldRequire") }],
  payer: [{ required: true, message: i18n.t("fieldRequire") }],
  is_vat_payer: [{ required: true, message: i18n.t("fieldRequire") }],
  edrpou: [
    { required: true, message: i18n.t("fieldRequire") },
    { min: 8, message: i18n.t("minLengthError", { length: 8 }) },
    { max: 10, message: i18n.t("maxLengthError", { length: 10 }) },
    { pattern: /^\d+$/, message: i18n.t("errorOnlyNumbers") }
  ],
  ipn: [
    { required: requiredIpn.value, message: i18n.t("fieldRequire") },
    { min: 10, message: i18n.t("minLengthError", { length: 10 }) },
    { max: 12, message: i18n.t("maxLengthError", { length: 12 }) },
    { pattern: /^\d+$/, message: i18n.t("errorOnlyNumbers") }
  ],
  postal_code: [
    { required: requiredAddress.value, message: i18n.t("fieldRequire") },
    { len: 5, message: i18n.t("wantedLengthError", { length: 5 }) },
    { pattern: /^\d+$/, message: i18n.t("errorOnlyNumbers") }
  ],
  region_name: [{ required: requiredAddress.value, message: i18n.t("fieldRequire") }],
  city_name: [{ required: requiredAddress.value, message: i18n.t("fieldRequire") }],
  street_name: [{ required: requiredAddress.value, message: i18n.t("fieldRequire") }],
  house_number: [{ required: requiredAddress.value, message: i18n.t("fieldRequire") }],
  amount_with_vat: [{ required: true, message: i18n.t("fieldRequire") }],
  comment: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }],
  issued_at: [{ required: true, message: i18n.t("fieldRequire") }]
}))

const amount_without_vat = computed(() => Math.round((+form.amount_with_vat / 1.2) * 100) / 100)
const vat = computed(() => +form.amount_with_vat - amount_without_vat.value)

const handleSearchSellers = async (value) => {
  try {
    sellers.value = []

    sellersFetching.value = true

    const { data } = await makeRequest({ queryParams: { search: value, pageSize: 20 } })

    sellers.value = data.results
    sellersFetching.value = false
  } catch (error) {
    if (error.message === "canceled") return
    notifyResponseError({ error })

    sellersFetching.value = false
  }
}

const handleSellerSelect = (value) => {
  const seller = sellers.value.find((item) => item.uuid === value)

  if (seller) {
    const logo = seller.small_logo

    searchSellerLastInvoice(seller.name)
    selectedSellerLogo.value = logo
      ? IMAGES_CDN + "/media/assets/images/" + getImagePath(logo, "full")
      : ""
  }
}

const searchSellerLastInvoice = async (sellerName) => {
  try {
    lastInvoiceFetching.value = true
    const { data } = await fetchInvoices({ queryParams: { search: sellerName, pageSize: 1 } })

    const lastInvoice = data.results[0]

    if (!lastInvoice) {
      lastInvoiceFetching.value = false
      return
    }
    form.seller_type = lastInvoice.seller_type
    form.payer = lastInvoice.payer

    if (lastInvoice.seller_type !== "IE" && lastInvoice.seller_type !== "PLC") {
      lastInvoiceFetching.value = false
      return
    }

    nextTick(() => {
      form.is_vat_payer = lastInvoice.is_vat_payer ? "yes" : "no"
      form.edrpou = lastInvoice.edrpou
      form.ipn = lastInvoice.ipn
      form.postal_code = lastInvoice.postal_code
      form.region_name = lastInvoice.region_name
      form.city_name = lastInvoice.city_name
      form.street_name = lastInvoice.street_name
      form.house_number = lastInvoice.house_number
      form.cabinet = lastInvoice.cabinet
    })
  } catch (error) {
    if (error.message === "canceled") return
    notifyResponseError({ error })

    lastInvoiceFetching.value = false
  }
}

const onPaste = (event) => {
  const paste = (event.clipboardData || window.clipboardData).getData("text")
  const pasteArray = paste.split(",")

  nextTick(() => {
    pasteArray.forEach((item) => {
      // postal code
      if (item.match(/^[0-9]{5}/gm)) {
        event.preventDefault() // prevent double insert
        form.postal_code = item.trim()
      }

      // region
      if (item.match(/[Оо]бл[\. ]/gm)) {
        event.preventDefault() // prevent double insert
        form.region_name = item.trim()
      }

      // city
      if (item.match(/(м[\. ])|(с[\. ])|(смт[\. ])/gm)) {
        const cityReg = pasteArray.find((findItem) => findItem.match(/р-н/gm))

        event.preventDefault() // prevent double insert
        form.city_name = `${item.trim()}${cityReg ? `, ${cityReg.trim()}` : ""}`
      }

      // street
      if (
        item.match(
          /([Пп]роспект)|([Пп]росп[\. ])|([Пп][\. ])|(пр-т)|([Вв]ул[\. ])|([Вв]улиця)|([Пп]лоща)|([Пп]л[\. ])/gm
        )
      ) {
        event.preventDefault() // prevent double insert
        form.street_name = item.trim()
      }

      // house
      if (item.match(/[Бб]уд[\. ]/gm)) {
        event.preventDefault() // prevent double insert
        form.house_number = item.trim()
      }

      // cabinet
      if (item.match(/[Кк]в[\. ]/gm)) {
        event.preventDefault() // prevent double insert
        form.cabinet = item.trim()
      }
    })
  })
}

const handleCreateInvoice = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    isFetching.value = true

    let payload = {
      seller: form.seller,
      seller_type: form.seller_type,
      payer: form.payer,
      amount_with_vat: +form.amount_with_vat,
      amount_without_vat: amount_without_vat.value,
      vat: vat.value,
      comment: form.comment,
      issued_at: `${form.issued_at.format("YYYY-MM-DD")} ${moment().format("HH:mm")}` // Set current time
    }

    if (form.seller_type === "IE" || form.seller_type === "PLC") {
      payload = { ...form, ...payload }
      payload.is_vat_payer = form.is_vat_payer === "yes"
    }

    await createInvoice(payload)

    notification.success({
      message: i18n.t("invoiceCreatingSuccess")
    })

    handleCancel()
    emit("onCreated")
  } catch (error) {
    notifyResponseError({ error })
    console.log(error)
  } finally {
    isFetching.value = false
  }
}

const handleCancel = () => {
  selectedSellerLogo.value = ""
  formRef.value.resetFields()
  emit("close")
}
</script>
