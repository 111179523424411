export const typeValidator = (value) => {
  const values = ["primary", "secondary", "success", "danger"]

  return values.indexOf(value) !== -1
}

export const sizeValidator = (value) => {
  const values = ["small", "medium", "large"]

  return values.indexOf(value) !== -1
}

export default {
  type: typeValidator,
  size: sizeValidator
}
