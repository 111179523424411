<template>
  <VueSlider
    v-if="max > 0"
    ref="slider"
    v-model="value"
    v-bind="sliderOptions"
    @drag-end="handleSearch"
    @click.native="handleSearch"
  />
</template>

<script>
import VueSlider from "vue-slider-component"

export default {
  name: "RangeFilter",
  components: {
    VueSlider
  },
  props: {
    min: Number,
    max: Number,
    tableQueryAction: Function,
    tableQueryParams: Object,
    columnKey: String,
    filters: Array,
    updateFilters: Function,
    formatter: {
      type: String,
      default: "{value}"
    }
  },
  data() {
    return {
      value: this.getValue(),
      sliderOptions: {
        height: 4,
        dotSize: 12,
        min: this.min,
        max: this.max,
        formatter: this.formatter,
        tooltip: "hover"
      }
    }
  },
  methods: {
    handleSearch() {
      const filters = this.filters.map((filter) => {
        let newFilter = {}

        if (filter.name === this.columnKey) {
          newFilter = {
            name: filter.name,
            value: this.value
          }
        } else {
          newFilter = filter
        }
        return newFilter
      })

      this.tableQueryAction({
        ...this.tableQueryParams,
        offset: 0,
        filters
      })

      this.updateFilters(filters)
    },

    getValue() {
      const filter = this.filters.find((filter) => filter.name === this.columnKey)

      return !filter.value ? [this.min, this.max] : filter.value
    }
  },
  watch: {
    max() {
      this.sliderOptions.min = this.min
      this.sliderOptions.max = this.max
      this.value = [this.min, this.max]
    }
  }
}
</script>

<style scoped></style>
