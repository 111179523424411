import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const transactionsHistoryColumns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: `${i18n.t("date")}, ${i18n.t("time")}`,
      scopedSlots: {
        customRender: "dateRenderer"
      },
      fixed: "left",
      width: 200
    },
    {
      key: "amount",
      title: i18n.t("sum"),
      scopedSlots: {
        customRender: "sumRender"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "amount" && sortedInfo?.value?.order,
      fixed: "left",
      width: 120
    },
    {
      key: "receiver_data",
      dataIndex: "receiver_data",
      title: i18n.t("recipient"),
      scopedSlots: {
        customRender: "recipientRender"
      },
      fixed: "left",
      width: 400
    },
    {
      key: "transaction_type",
      dataIndex: "transaction_type",
      title: i18n.t("purpose"),
      scopedSlots: {
        customRender: "transactionRender"
      },
      width: 300
    },
    {
      key: "comment_sender",
      dataIndex: "comment_sender",
      title: i18n.t("comment"),
      width: 760
    }
  ])

  const historyRateColumns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: `${i18n.t("date")}, ${i18n.t("time")}`,
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 200
    },
    {
      key: "rate",
      dataIndex: "rate",
      title: i18n.t("course"),
      width: 200
    }
  ])

  return {
    transactionsHistoryColumns,
    historyRateColumns
  }
}
