<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PA_showCreateAttributeModal"
      :title="'Добавление новой характеристики'"
      :size="size"
    >
      <div>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col col-first">
                <label
                  ><span>Название характеристики<span class="required-field">*</span></span></label
                >
                <Input
                  size="medium"
                  :value="fullName"
                  @blur="onNameBlur"
                  @input="onInputName"
                />
                <ValidationErrors
                  :lengthError="!validNameLength"
                  :emptyError="!validNameEmpty"
                  :uniqueError="!PA_checkUniqueAttributeNameGet"
                />
              </div>
              <div class="col">
                <div :class="{ 'col-type-list': typeIsList || typeIsMultiList }">
                  <Select
                    @change="onChangeType"
                    label="Тип значения"
                    :values="selectData"
                    :selected="PA_selectedAttributeTypeToCreateNewGet"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label
                  ><span>Название для публикации<span class="required-field">*</span></span></label
                >
                <Input
                  size="medium"
                  :value="publicName"
                  @input="onInputPublicName"
                />
                <ValidationErrors
                  :lengthError="!validPublicNameLength"
                  :emptyError="!validPublicNameEmpty"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col"
                :class="{ 'col-first': colFirst }"
              >
                <label
                  ><span>Тип товара (полный)<span class="required-field">*</span></span></label
                >
                <Autocomplete
                  :data="PA_typesForNewAttributeAutocompleteGet"
                  event="input"
                  @event="fetchTypes"
                  @select="PA_processSelectedTypeForNewAttribute"
                  :size="'small'"
                  :defaultValue="type"
                />
              </div>
              <div
                class="col"
                v-if="typeIsNumeric"
              >
                <label><span>Единица измерения</span></label>
                <Autocomplete
                  :data="PA_unitsForNewAttributeAutocompleteGet"
                  event="input"
                  @event="fetchUnits"
                  @select="PA_processSelectedUnitForNewAttribute"
                  :size="'small'"
                  :defaultValue="unit"
                />
              </div>
              <div
                class="col"
                v-if="typeIsList || typeIsMultiList"
              >
                <label
                  ><span>Выбрать список<span class="required-field">*</span></span></label
                >
                <Autocomplete
                  :data="PA_listsForNewAttributeAutocompleteGet"
                  event="input"
                  @event="fetchLists"
                  @select="PA_processSelectedListForNewAttribute"
                  :size="'small'"
                  :defaultValue="list"
                />
              </div>
            </div>
            <div class="row margin-top">
              <div class="col">
                <Checkbox
                  @change="checkboxMain"
                  :checked="PA_attributeIsMainGet"
                >
                  Является основной для отображения в описании
                </Checkbox>
              </div>
            </div>
            <div class="row margin-top-10">
              <div class="col">
                <Checkbox
                  @change="checkboxRequired"
                  :checked="PA_attributeIsRequiredGet"
                >
                  Обязательна для заполнения в новых товарах
                </Checkbox>
              </div>
            </div>
          </div>
          <div
            class="col col-last"
            v-if="typeIsList || typeIsMultiList"
          >
            <label
              ><span
                >Значения выбранного списка<template v-if="PA_selectedListName">
                  ({{ PA_selectedListName }})</template
                >:</span
              ></label
            >
            <template v-if="PA_valuesForNewAttributeGet">
              <div class="values-container">
                <div v-for="val in PA_valuesForNewAttributeGet">- {{ val.value }}</div>
              </div>
            </template>
            <template v-else>
              <label class="values-container__empty"><span>Список не выбран</span></label>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewAttribute"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import Autocomplete from "components/Autocomplete"
import Select from "components/Select/Select"
import Checkbox from "components/Checkbox"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import validEmpty from "utils/validations/validEmpty"

export default {
  name: "CreateAttributeModal",
  components: {
    Button,
    Modal,
    Input,
    Autocomplete,
    Select,
    Checkbox,
    ValidationErrors
  },
  created() {},
  data() {
    return {
      size: "small",
      timeoutName: null,
      timeoutTime: null,
      timeoutUnit: null,
      timeoutType: null,
      validNameLength: true,
      validNameEmpty: true,
      validPublicNameLength: true,
      validPublicNameEmpty: true,
      selectData: [
        {
          label: "Число (Numeric)",
          value: "NUMERIC"
        },
        {
          label: "Текст (String)",
          value: "STRING"
        },
        {
          label: "Да/Нет (Boolean)",
          value: "BOOLEAN"
        },
        {
          label: "Список значений (List)",
          value: "LIST"
        },
        {
          label: "Мульти-список значений (Multi-list)",
          value: "MULTILIST"
        },
        {
          label: "Ссылка (URL)",
          value: "URL"
        }
      ],
      duplicatePublicName: true
    }
  },
  watch: {
    PA_showCreateAttributeModal() {
      if (this.typeIsList || this.typeIsMultiList) {
        this.size = "large"
      } else {
        this.size = "small"
      }
    }
  },
  methods: {
    onInputName(value) {
      value = value.trim()
      // validate length
      this.validNameLength = validMaxLength(value, 50)
      this.validNameEmpty = validEmpty(value)

      this.checkUniqueAttributeName(value)

      // set value
      this.PA_nameForNewAttribute(value)
      if (this.duplicatePublicName) {
        this.PA_setPublicName(value)
      }
    },
    onNameBlur() {
      this.duplicatePublicName = false
    },
    onInputPublicName(value) {
      value = value.trim()
      // validate length
      this.validPublicNameLength = validMaxLength(value, 50)
      this.validPublicNameEmpty = validEmpty(value)

      //this.checkUniqueAttributeName(value);

      // set value
      this.PA_setPublicName(value)
    },
    checkUniqueAttributeName(value) {
      clearTimeout(this.timeoutName)
      this.timeoutName = setTimeout(() => {
        this.PA_checkUniqueAttributeName(value)
      }, 500)
    },
    onChangeType(value) {
      // load all lists for type 'LIST'
      if (value === "LIST" || value === "MULTILIST") {
        if (!this.PV_initialValuesDescriptionItems.length) {
          this.PV_searchValuesByDescriptionRequest()
        }
        this.size = "large"
      } else {
        this.size = "small"
      }
      // reset selected unit if any
      this.PA_selectedUnitForNewAttribute(false)
      // set new type
      this.PA_selectedAttributeTypeToCreateNew(value)
    },
    fetchTypes(value) {
      if (value === "") {
        // reset
        this.PA_typesForNewAttribute([])
        this.PA_typesForNewAttributeAutocomplete([])
        this.PA_selectedProductTypeToCreateNew(false)
        return
      }
      clearTimeout(this.timeoutType)
      this.timeoutType = setTimeout(() => {
        this.PA_fetchTypesForNewAttribute(value)
      }, 500)
    },
    fetchUnits(value) {
      if (value === "") {
        // reset
        this.PA_unitsForNewAttribute([])
        this.PA_unitsForNewAttributeAutocomplete([])
        this.PA_selectedUnitForNewAttribute(false)
        return
      }
      clearTimeout(this.timeoutUnit)
      this.timeoutUnit = setTimeout(() => {
        this.PA_fetchUnitsForNewAttribute(value)
      }, 500)
    },
    fetchLists(value) {
      if (value === "") {
        // reset
        this.PA_valuesForNewAttribute(false)
        return
      }
      this.PA_searchListsForNewAttribute(value)
    },
    checkboxMain(val) {
      this.PA_attributeIsMain(val)
    },
    checkboxRequired(val) {
      this.PA_attributeIsRequired(val)
    },
    createNewAttribute() {
      this.PA_createNewAttribute()
      this.closeModal()
    },
    closeModal() {
      this.PA_showCreateAttributeModalSet(false)
      this.resetValues()
    },
    resetValues() {
      this.size = "small"
      this.timeoutName = null
      this.timeoutTime = null
      this.timeoutUnit = null
      this.validNameLength = true
      this.validNameEmpty = true
      this.timeoutType = null
      this.duplicatePublicName = true
    },
    ...mapMutations("ProductCatalog", {
      PA_nameForNewAttribute: "PA_nameForNewAttribute",
      PA_setPublicName: "PA_setPublicName",
      PA_showCreateAttributeModalSet: "PA_showCreateAttributeModal",
      PA_selectedAttributeTypeToCreateNew: "PA_selectedAttributeTypeToCreateNew",
      PA_attributeIsMain: "PA_attributeIsMain",
      PA_attributeIsRequired: "PA_attributeIsRequired",
      PA_unitsForNewAttribute: "PA_unitsForNewAttribute",
      PA_selectedUnitForNewAttribute: "PA_selectedUnitForNewAttribute",
      PA_unitsForNewAttributeAutocomplete: "PA_unitsForNewAttributeAutocomplete",
      PA_valuesForNewAttribute: "PA_valuesForNewAttribute"
    }),
    ...mapActions("ProductCatalog", {
      PA_createNewAttribute: "PA_createNewAttribute",
      PA_checkUniqueAttributeName: "PA_checkUniqueAttributeName",
      PA_fetchTypesForNewAttribute: "PA_fetchTypesForNewAttribute",
      PA_processSelectedTypeForNewAttribute: "PA_processSelectedTypeForNewAttribute",
      PA_fetchUnitsForNewAttribute: "PA_fetchUnitsForNewAttribute",
      PA_processSelectedUnitForNewAttribute: "PA_processSelectedUnitForNewAttribute",
      PV_searchValuesByDescriptionRequest: "PV_searchValuesByDescriptionRequest",
      PA_searchListsForNewAttribute: "PA_searchListsForNewAttribute",
      PA_processSelectedListForNewAttribute: "PA_processSelectedListForNewAttribute"
    })
  },
  computed: {
    fullName() {
      return this.PA_nameForNewAttributeGet === false ? "" : this.PA_nameForNewAttributeGet
    },
    publicName() {
      return this.PA_publicName === false ? "" : this.PA_publicName
    },
    type() {
      return this.PA_selectedProductTypeToCreateNewGet === false
        ? ""
        : this.PA_selectedProductTypeToCreateNewGet.full_name
    },
    unit() {
      return this.PA_selectedUnitForNewAttributeGet === false
        ? ""
        : this.PA_selectedUnitForNewAttributeGet.name
    },
    list() {
      return this.PA_selectedListName === false ? "" : this.PA_selectedListName
    },
    colFirst() {
      return this.typeIsNumeric || this.typeIsList || this.typeIsMultiList
    },
    typeIsNumeric() {
      return this.PA_selectedAttributeTypeToCreateNewGet === "NUMERIC"
    },
    typeIsList() {
      return this.PA_selectedAttributeTypeToCreateNewGet === "LIST"
    },
    typeIsMultiList() {
      return this.PA_selectedAttributeTypeToCreateNewGet === "MULTILIST"
    },
    enableCreateButton() {
      let show = false

      if (
        this.PA_nameForNewAttributeGet &&
        this.PA_publicName &&
        this.PA_checkUniqueAttributeNameGet &&
        this.PA_selectedProductTypeToCreateNewGet &&
        this.validNameLength &&
        this.validNameEmpty &&
        this.validPublicNameLength &&
        this.validPublicNameEmpty
      ) {
        show = true
      }

      if (this.typeIsList || this.typeIsMultiList) {
        if (!this.PA_selectedListName) {
          show = false
        }
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PA_nameForNewAttributeGet: "PA_nameForNewAttribute",
      PA_publicName: "PA_publicName",
      PA_showCreateAttributeModal: "PA_showCreateAttributeModal",
      PA_checkUniqueAttributeNameGet: "PA_checkUniqueAttributeName",
      PA_typesForNewAttributeAutocompleteGet: "PA_typesForNewAttributeAutocomplete",
      PA_selectedProductTypeToCreateNewGet: "PA_selectedProductTypeToCreateNew",
      PA_selectedAttributeTypeToCreateNewGet: "PA_selectedAttributeTypeToCreateNew",
      PA_attributeIsMainGet: "PA_attributeIsMain",
      PA_attributeIsRequiredGet: "PA_attributeIsRequired",
      PA_selectedUnitForNewAttributeGet: "PA_selectedUnitForNewAttribute",
      PA_unitsForNewAttributeAutocompleteGet: "PA_unitsForNewAttributeAutocomplete",
      PA_listsForNewAttributeAutocompleteGet: "PA_listsForNewAttributeAutocomplete",
      PA_valuesForNewAttributeGet: "PA_valuesForNewAttribute",
      PV_initialValuesDescriptionItems: "PV_initialValuesDescriptionItems",
      PA_selectedListName: "PA_selectedListName"
    })
  }
}
</script>
<style lang="scss" scoped>
.example {
  font-size: $medium-font-size;
  line-height: 1.5em;
  margin-bottom: -5px;
  margin-top: 0px;
  position: relative;
  top: -5px;
  font-weight: 300;
}
hr {
  border: none;
  border-top: 1px solid rgba(125, 125, 125, 0.2);
}
.sub-heading {
  margin-bottom: 10px;
  font-weight: 500;
}

.col-first {
  padding-right: 5px;
}
.col-last {
  padding-left: 5px;
}
.values-container {
  max-height: 210px;
  overflow-y: hidden;
  font-weight: 200;
}
.values-container__empty {
  font-weight: 200;
}
.col-type-list {
  max-width: 190px;
}
</style>
