<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="AE_wrongValueTypeModalGet"
      :title="'Внимание!'"
    >
      <div>
        <label><span> Указанная конвертация не поддерживается базой даных </span></label>
      </div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "CreateAttributeModal",
  components: {
    Button,
    Modal
  },
  methods: {
    closeModal() {
      this.AE_wrongValueTypeModal(false)
    },
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_wrongValueTypeModal: "AE_wrongValueTypeModal"
    })
  },
  computed: {
    ...mapState("ProductCatalog/attributesEdit", {
      AE_wrongValueTypeModalGet: (state) => state.conversion.AE_wrongValueTypeModal
    })
  }
}
</script>
<style lang="scss"></style>
