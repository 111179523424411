import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = () => ({
  PA_checkUniqueAttributeName: true,
  PA_checkUniqueAttributeSynonym: true,
  PA_synonymFailedValidation: ""
})

const getters = {
  PA_checkUniqueAttributeName: (state) => state.PA_checkUniqueAttributeName,
  PA_checkUniqueAttributeSynonym: (state) => state.PA_checkUniqueAttributeSynonym,
  PA_synonymFailedValidation: (state) => state.PA_synonymFailedValidation
}

const mutations = {
  PA_checkUniqueAttributeName: (state, value) => {
    state.PA_checkUniqueAttributeName = value
  },
  PA_checkUniqueAttributeSynonym: (state, value) => {
    state.PA_checkUniqueAttributeSynonym = value
  },
  PA_synonymFailedValidation(state, value) {
    state.PA_synonymFailedValidation = value
  }
}

const actions = {
  PA_checkUniqueAttributeName: ({ commit, getters }, value) => {
    if (value === "") {
      commit("PA_checkUniqueAttributeName", true)
      return
    }

    let params = {
      name: value.trim()
    }

    API_MODERATION.get(`${apiUrls.validateAttribute}`, { params })
      .then((r) => r.data.message.unique)
      .then((unique) => {
        if (unique) commit("PA_checkUniqueAttributeName", true)
        if (!unique) commit("PA_checkUniqueAttributeName", false)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PA_checkUniqueAttributeSynonym: ({ commit, getters }, { value, rowIndex }) => {
    if (value === "") {
      commit("PA_checkUniqueAttributeSynonym", true)
      return
    }

    let synonymsArray = value.split(",").map((item) => {
      return item.trim()
    })

    if (rowIndex !== undefined) {
      // this stage is only for editing, not creating new attribute
      // check only those which are not set already
      let oldSynonyms = getters.PA_attributesTableData.items[rowIndex].synonyms

      // remove synonyms, which are already set from synonymsARRAY
      for (let i = 0; i < oldSynonyms.length; i++) {
        synonymsArray = synonymsArray.filter((item) => {
          return item !== oldSynonyms[i]
        })
      }
    }

    let promiseArray = []
    for (let i = 0; i < synonymsArray.length; i++) {
      // skip empty strings
      if (synonymsArray[i] === "") continue
      let params = {
        name: synonymsArray[i]
      }

      promiseArray.push(API_MODERATION.get(`${apiUrls.validateAttribute}`, { params }))
    }

    Promise.all(promiseArray)
      .then((r) => {
        // array of objects
        for (let i = 0; i < r.length; i++) {
          if (!r[i].data.message.unique) {
            commit("PA_checkUniqueAttributeSynonym", false)
            commit("PA_synonymFailedValidation", synonymsArray[i])
            break
          } else {
            commit("PA_checkUniqueAttributeSynonym", true)
            commit("PA_synonymFailedValidation", "")
          }
        }
      })
      .catch((e) => {
        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
