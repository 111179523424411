<template>
  <div>
    <PageHeader :title="$t('productsReviews')">
      <template #filters>
        <a-range-picker
          v-model="filteredInfo.created_at__interval"
          format="DD.MM.YYYY"
          valueFormat="YYYY-MM-DD"
          @change="handleTableFilterChange()"
        />

        <a-input-search
          v-for="field in searchFields"
          :placeholder="$t(field.text)"
          v-model="filteredInfo[field.key]"
          :key="field.key"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>
      </template>

      <template #actions>
        <div class="table-header__actions">
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>
          <a-select
            v-model="filteredInfo.moderation_status"
            :options="[{ value: undefined, label: $t('all') }, ...moderationStatusOptions]"
            :placeholder="$t('moderationStatus')"
            size="large"
            style="width: 200px"
            @change="handleTableFilterChange()"
          />
        </div>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="paginationInfo"
      :loading="isFetching"
      rowKey="id"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #pCode="record">
        <a
          :href="productUrl(record)"
          target="_blank"
        >
          {{ record.p_code }}
        </a>
      </template>

      <template #contacts="author">
        <UserContacts
          :email="author.email"
          :mobilePhone="author.mobile_phone"
        />
      </template>

      <template #ratingRenderer="value">
        {{ value || "0" }}
        <a-rate
          :count="1"
          :value="value"
          disabled
        />
      </template>

      <template #comment="value, { is_bought }">
        <Comment
          :value="value"
          :isBought="is_bought"
        />
      </template>

      <template #clampedText="value">
        <a-popover placement="topLeft">
          <template #content>
            <div style="max-width: 450px">
              {{ value }}
            </div>
          </template>

          <div class="clamped-comment">{{ value }}</div>
        </a-popover>
      </template>

      <template #imageRenderer="images">
        <TableRendererPhotoGallery
          v-if="images?.length"
          :images="images"
        />
      </template>

      <template #statusRender="value">
        <ModerationStatus :status="value" />
      </template>

      <template #parent="value">
        <a-button
          v-if="value"
          type="link"
          style="padding: 0"
          @click="handleShowParentModal(value)"
        >
          {{ $t("show") }}
        </a-button>
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          icon="edit"
          shape="circle"
          @click="openReviewModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :width="700"
      :visible="showParentReviewModal"
      :title="$t('parentReview')"
      destroyOnClose
      @close="showParentReviewModal = false"
    >
      <ParentReviewModal :parent="parentReview" />
    </a-drawer>

    <a-drawer
      :visible="showEditReview"
      :title="$t('edit')"
      :width="800"
      destroyOnClose
      @close="closeReviewModal"
    >
      <EditProductReviewModal
        :review="selectedReview"
        :moderationStatusOptions="moderationStatusOptions"
        @openParent="handleShowParentModal"
        @updateReview="onUpdateReview"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { MARKETPLACE } from "@/constants/common.js"
import i18n from "@/i18n"
import { ref, onMounted } from "vue"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import UserContacts from "../renderers/UserContacts.vue"

import { ParentReviewModal, EditProductReviewModal, Comment } from "./components/index.js"
import ModerationStatus from "../renderers/ModerationStatus.vue"
import PageHeader from "@/ant-components/PageHeader/index.js"

import usePRPageColumns from "./usePRPageColumns.js"

import {
  fetchProductReviews,
  fetchProductReviewsActionsData
} from "@/modules/MPAdmin/services/productReviewsService.js"

import useAntTableQuery from "@/composables/useAntTableQuery"

// metaInfo: {
//   title: "Відгуки про товари"
// },

const columns = usePRPageColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchProductReviews,
  requestParams: { changeRouterQuery: true }
})
const isMetaFetching = ref(false)
const showEditReview = ref(false)
const selectedReview = ref()

const showParentReviewModal = ref(false)
const parentReview = ref(0)

const moderationStatusOptions = ref([])

const searchFields = ref([
  {
    key: "customer_id",
    text: "authorID"
  },
  {
    key: "customer_username",
    text: "user"
  },
  {
    key: "customer_contacts",
    text: "contacts"
  },
  {
    key: "comment",
    text: "comment"
  },
  {
    key: "pros",
    text: "advantages"
  },
  {
    key: "cons",
    text: "flaws"
  },
  {
    key: "p_code",
    text: "p_code"
  }
])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const getMeta = async () => {
  try {
    isMetaFetching.value = true

    const { data } = await fetchProductReviewsActionsData()
    moderationStatusOptions.value = data.moderation_status_choices.map((status) => ({
      value: Object.keys(status)[0],
      label: i18n.t(Object.getOwnPropertyNames(status)[0])
    }))
    isMetaFetching.value = false
  } catch (error) {
    if (error?.response?.status === 401) return

    setTimeout(getMeta, 2500)
  }
}

const handleShowParentModal = (parent = null) => {
  showParentReviewModal.value = !showParentReviewModal.value
  parentReview.value = parent
}

const openReviewModal = (review) => {
  selectedReview.value = review
  showEditReview.value = true
}

const closeReviewModal = () => {
  showEditReview.value = false
  selectedReview.value = undefined
}

const onUpdateReview = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeReviewModal()
}

const productUrl = (record) => {
  return `${MARKETPLACE}/product/${record?.slug}/${record?.p_code}/`
}

onMounted(() => {
  setupTable()
  getMeta()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-header {
  &__actions {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }
}

.clamped-comment {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: help;
}
</style>
