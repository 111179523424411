import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  AE_valueTypes: [
    { label: "NUMERIC", value: "NUMERIC" },
    { label: "STRING", value: "STRING" },
    { label: "BOOLEAN", value: "BOOLEAN" },
    { label: "LIST", value: "LIST" },
    { label: "MULTILIST", value: "MULTILIST" },
    { label: "URL", value: "URL" },
    { label: "TEMP", value: "TEMP" }
  ],
  AE_selectedValueType: "NUMERIC",
  tempValueType: null,

  unitsAutocomplete: {
    values: null,
    data: [],
    default: "",
    selectedObject: null
  },

  AE_wrongValueTypeModal: false,
  AE_conversionBooleanModal: false,
  AE_confirmConversionModal: false,
  AE_changeAttributeModal: false,
  AE_conversionToListsModal: false
})

const mutations = {
  AE_valueTypes(state, value) {
    state.AE_valueTypes = value
  },
  AE_selectedValueType(state, value) {
    state.AE_selectedValueType = value
  },
  unitsAutocompleteValues(state, value) {
    state.unitsAutocomplete.values = value
  },
  unitsAutocompleteData(state, value) {
    state.unitsAutocomplete.data = value
  },
  unitsAutocompleteSelected(state, value) {
    state.unitsAutocomplete.selectedObject = value
  },
  resetUnitsAutocomplete(state) {
    state.unitsAutocomplete = {
      values: null,
      data: [],
      default: "",
      selectedObject: null
    }
  },

  AE_wrongValueTypeModal(state, value) {
    state.AE_wrongValueTypeModal = value
  },
  AE_conversionBooleanModal(state, value) {
    state.AE_conversionBooleanModal = value
  },
  AE_confirmConversionModal(state, value) {
    state.AE_confirmConversionModal = value
  },
  AE_changeAttributeModal(state, value) {
    state.AE_changeAttributeModal = value
  },
  AE_conversionToListsModal(state, value) {
    state.AE_conversionToListsModal = value
  },
  tempValueType(state, value) {
    state.tempValueType = value
  },

  resetConversionValues(state) {
    state.AE_selectedValueType = "NUMERIC"
    state.tempValueType = null

    state.unitsAutocomplete = {
      values: null,
      data: [],
      default: "",
      selectedObject: null
    }
  }
}

const actions = {
  async AE_applyConversion({ commit, state, dispatch }, { selectedAttrCode, selectedUnitCode }) {
    let sendData = {
      attribute: selectedAttrCode,
      new_value_type: state.tempValueType
    }
    if (selectedUnitCode) {
      sendData.new_default_unit = selectedUnitCode
    }
    return API_MODERATION.patch(`${apiUrls.attributeEdit}`, sendData)
      .then((r) => r.data.message.task_id)
      .then((r) => {
        return r
      })
      .catch((e) => {
        e.$_actionName = "AE_applyConversion"
        return Promise.reject(e)
      })
  },
  async AE_fetchUnits({ commit, state, dispatch }, value) {
    try {
      let searchData = buildMagicSearch({
        resource: "AUD",
        field: "name",
        value,
        sort: null,
        limit: 20,
        offset: 0
      })
      return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((units) => {
          commit("unitsAutocompleteValues", units)
          let unitsArray = units.map((item) => {
            return item.name
          })
          commit("unitsAutocompleteData", unitsArray)
          return units
        })
        .catch((e) => {
          e.$_actionName = "AE_fetchUnits"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    }
  },
  async setSelectedUnit({ commit, state, dispatch }, value) {
    let selected = state.unitsAutocomplete.values.find((item) => {
      return item.name === value
    })
    //set
    commit("unitsAutocompleteSelected", selected)
  },
  async searchUnit({ commit, state, dispatch }, code) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      let searchData = buildMagicSearch({
        resource: "AUD",
        field: "code",
        value: code
      })
      return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((units) => {
          return units[0]
        })
        .catch((e) => {
          e.$_actionName = "searchUnit"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    } finally {
      commit("ProductCatalog/PC_showLoader", false, { root: true })
    }
  },
  async unitsByBase({ commit, state, dispatch }, selectedUnit) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      //if base = null then selectedUnit is base
      let base = (selectedUnit.base && selectedUnit.base[0]) || selectedUnit
      let searchData = buildMagicSearch({
        resource: "AUD",
        field: "base.code",
        value: base.code,
        sort: null,
        limit: 100,
        offset: 0
      })
      return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((units) => {
          units.unshift(base)
          commit("possibleUnits", units)
          return units
        })
        .catch((e) => {
          e.$_actionName = "unitsByBase"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    } finally {
      commit("ProductCatalog/PC_showLoader", false, { root: true })
    }
  }
}

export default {
  state,
  mutations,
  actions
}
