<template>
  <div class="attributes-edit__search">
    <div class="search-section">
      <Autocomplete
        :data="AE_attrsAutocompleteGet"
        :defaultValue="AE_attrAutocompleteDefaultGet"
        class="search-section__autocomplete"
        :size="'small'"
        event="input"
        @event="onInput"
        @select="AE_setSelectedAttr"
      />
      <Button
        class="search-section__button"
        color="primary"
        :disabled="!AE_selectedAttrGet || blockSearchGet"
        @click="processSelectedAttr"
      >
        Поиск
      </Button>
    </div>
    <label>
      <span
        >Выбранный тип значений:
        <span
          v-if="AE_selectedAttrGet"
          style="display: inline"
          >{{ AE_selectedAttrGet.value_type }}</span
        >
      </span>
    </label>
    <label>
      <span
        >Кол-во товаров с выбранным атрибутом:
        <span
          v-if="AE_tableDataGet.items.length"
          style="display: inline"
          >{{ AE_tableDataGet.count }}</span
        >
      </span>
    </label>
    <WrongStatusModal />
    <ConversionInProgressModal />
  </div>
</template>
<script>
import Autocomplete from "components/Autocomplete"
import Button from "components/Button"
import { mapState, mapMutations, mapActions } from "vuex"
import WrongStatusModal from "./WrongStatusModal/WrongStatusModal"
import ConversionInProgressModal from "./ConversionInProgressModal/ConversionInProgressModal"
import regeneratorRuntime from "regenerator-runtime"

export default {
  components: {
    ConversionInProgressModal,
    Autocomplete,
    Button,
    WrongStatusModal
  },
  data() {
    return {
      interval: null
    }
  },
  updated() {
    // reload after changing attribute
    if (this.AE_CA_reloadTable) {
      this.processSelectedAttr()
      this.AE_CA_reloadTableSet(false)
    }
  },
  methods: {
    onInput(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.AE_fetchAttributes(value)
      }, 500)
    },
    async processSelectedAttr() {
      try {
        this.AE_CA_resetModalData()
        this.PC_showLoader(true)
        this.blockSearch(true)
        let data = await this.AE_processSelectedAttr()
        if (data == "Attribute not used in products" || data == "Attribute not found") {
          this.$notification.error({ message: "Атрибут не используется в товарах" })
          this.PC_showLoader(false)
          this.AE_selectedAttr(false)
          this.AE_showTable(false)
          return
        } else if (data === 409) {
          await this.userNameById()
          this.AE_conversionInProgressModal(true)
          this.PC_showLoader(false)
          return
        } else {
          //check task
          this.checkWithInterval(data)
        }
      } catch (e) {
        this.handleError(e)
      }
    },
    async checkWithInterval(id) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]

        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.interval)
        }
        if (response.task_state === "SUCCESS") {
          let data = response.task_result.records
          this.AE_CA_uniqueTypes(response.task_result.unique_types)
          this.AE_tableData({
            items: data,
            count: data.length
          })
          this.fetchPossibleUnits(data[0])
          this.AE_showTable(true)
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
        }
      }, this.celeryInterval)
    },
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_selectedAttr: "AE_selectedAttr",
      blockSearch: "blockSearch",
      AE_tableData: "AE_tableData",
      AE_showTable: "AE_showTable",
      AE_conversionInProgressModal: "AE_conversionInProgressModal",
      AE_CA_uniqueTypes: "AE_CA_uniqueTypes",
      AE_CA_reloadTableSet: "AE_CA_reloadTable" // reload after changing attribute
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_fetchAttributes: "AE_fetchAttributes",
      AE_processSelectedAttr: "AE_processSelectedAttr",
      AE_setSelectedAttr: "AE_setSelectedAttr",
      fetchPossibleUnits: "fetchPossibleUnits",
      userNameById: "userNameById",
      AE_CA_resetModalData: "AE_CA_resetModalData"
    })
  },
  data() {
    return {
      timeout: null
    }
  },
  computed: {
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    ...mapState("ProductCatalog/attributesEdit", {
      AE_attrsAutocompleteGet: (state) => state.search.AE_attrsAutocomplete,
      AE_attrAutocompleteDefaultGet: (state) => state.search.AE_attrAutocompleteDefault,
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr,
      AE_tableDataGet: (state) => state.table.AE_tableData,
      blockSearchGet: (state) => state.search.blockSearch,
      // for changing attribute modal
      AE_CA_reloadTable: (state) => state.changingAttrModal.AE_CA_reloadTable
    })
  }
}
</script>
<style lang="scss">
.attributes-edit {
  &__search {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 15px 15px;
    height: 160px;

    .search-section {
      display: flex;
      margin-bottom: 10px;

      &__autocomplete {
        width: 80%;
        margin-right: 10px;
      }

      &__button {
        width: 20%;
      }
    }
  }
}
</style>
