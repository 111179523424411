import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("active"),
      scopedSlots: {
        customRender: "activeRenderer"
      },
      align: "center",
      fixed: "left",
      width: 120
    },
    {
      key: "name_uk",
      dataIndex: "name.uk",
      title: "UA",
      fixed: "left",
      width: 360
    },
    {
      key: "name_en",
      dataIndex: "name.en",
      title: "EN",
      width: 360
    },
    {
      key: "name_ru",
      dataIndex: "name.ru",
      title: "RU",
      width: 360
    },
    {
      key: "ordering",
      dataIndex: "ordering",
      title: i18n.t("outputSortOrder"),
      width: 250
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("description"),
      width: 280
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      align: "center",
      fixed: "right",
      width: 58
    }
  ])
  return columns
}
