import { API } from "@/utils/HttpUtils"
import { transformQuery } from "../servicesUtils"

export const fetchLabels = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/product-labels/${transformQuery(queryParams)}`, { signal })
}

export const removeLabel = (payload) => {
  return API.delete("/mp-admin/product-labels/" + payload.id)
}

export const createLabel = (data) => {
  return API.post("/mp-admin/product-labels/", data)
}
export const updateLabel = (data, id) => {
  return API.put(`/mp-admin/product-labels/${id}`, data)
}
