<template>
  <div
    class="tBody"
    :class="`tBody-${tableId}`"
    :style="{ height: height }"
    ref="tBody"
  >
    <div
      v-show="editableCell.rowIndex !== null"
      class="tBody__fullContent"
      ref="fullContent"
      @dblclick="
        editableCellBuffer.canEdit
          ? editCell(editableCellBuffer.rowIndex, editableCellBuffer.columnKey)
          : () => false
      "
      @mouseleave="hideFullContent"
    ></div>

    <div
      v-if="items.length === 0 && fetching"
      class="tBody__loader"
    >
      <a-spin size="large" />
    </div>

    <template v-else>
      <template v-if="ifExistFixedColumns">
        <draggable
          v-model="sortedItems"
          handle=".row-move"
        >
          <div
            v-for="(rowItem, rowIndex) in items"
            :key="`${tableId}-tr-${rowIndex}`"
            class="tBody__tr"
            :class="[
              {
                'tBody__tr--disabled': rowDisabled(rowItem, rowIndex),
                'tBody__tr--selected': isSelectedRow(rowItem, rowIndex),
                'tBody__tr--withFixedColumns': ifExistFixedColumns
              },
              ...getTrClasses(rowItem, rowIndex)
            ]"
            :style="trStyle"
          >
            <div
              class="tBody__tr-left"
              ref="trLeft"
            >
              <RowActions
                ref="rowActionsFixedColumns"
                :row="rowItem"
                :actions="getRowActions(rowItem, rowIndex)"
                :rowIndex="rowIndex"
                :disabled="rowDisabled(rowItem, rowIndex)"
              />

              <div
                v-if="sortRowsEnabled"
                :key="`${tableId}-td-${rowIndex}.cellSort`"
                class="tBody__td tBody__td--sort"
                :class="[
                  {
                    'tBody__td--disabled': rowDisabled(rowItem, rowIndex)
                  }
                ]"
                :style="{
                  'min-width': getMinTdWidth(32),
                  'max-width': getMaxTdWidth(32)
                }"
              >
                <div class="tBody__td-value">
                  <div class="row-move">
                    <a-icon type="menu" />
                  </div>
                </div>
              </div>

              <div
                v-if="checkRowEnabled"
                :key="`${tableId}-td-${rowIndex}.cellCheckbox`"
                class="tBody__td tBody__td--sort"
                :class="[
                  {
                    'tBody__td--disabled': rowDisabled(rowItem, rowIndex)
                  }
                ]"
                :style="{
                  'min-width': getMinTdWidth(32),
                  'max-width': getMaxTdWidth(32)
                }"
              >
                <div class="tBody__td-value">
                  <CheckRow
                    :disabled="rowDisabled(rowItem, rowIndex)"
                    :onChange="(checked) => onCheckRow(checked, rowItem)"
                    :checked="isChecked(rowItem[rowKey])"
                    :data="rowItem"
                  />
                </div>
              </div>

              <div
                v-if="showRowNumber"
                :key="`${tableId}-td-${rowIndex}.cellNumber`"
                class="tBody__td tBody__td--sort"
                :class="[
                  {
                    'tBody__td--disabled': rowDisabled(rowItem, rowIndex)
                  }
                ]"
                :style="{
                  'min-width': getMinTdWidth(40),
                  'max-width': getMaxTdWidth(40)
                }"
              >
                <div class="tBody__td-value">
                  {{ rowIndex + 1 }}
                </div>
              </div>

              <div
                v-for="(column, columnIndex) in fixedColumns"
                :key="`${tableId}-td-${rowIndex}.${columnIndex}`"
                ref="td"
                class="tBody__td"
                :data-rowIndex="rowIndex"
                :data-colIndex="columnIndex"
                :class="[
                  {
                    'tBody__td--disabled': rowDisabled(rowItem, rowIndex),
                    'tBody__td--editable': !!column.editRenderer,
                    'tBody__td--selectable': !!column.selectable
                  },
                  ...getTdClasses(rowItem, columnIndex, rowIndex)
                ]"
                :style="{
                  'min-width': getMinTdWidth(column.width, column.minWidth),
                  'max-width': getMaxTdWidth(column.width, column.maxWidth)
                }"
                @dblclick="
                  cellCanEdit(column, rowItem) && !rowDisabled(rowItem, rowIndex)
                    ? editCell(rowIndex, column.key)
                    : () => false
                "
              >
                <div class="tBody__td-value">
                  <template
                    v-if="
                      editableCell.columnKey === column.key && editableCell.rowIndex === rowIndex
                    "
                  >
                    <component
                      v-if="!!column.editRenderer"
                      :is="column.editRenderer"
                      :row="rowItem"
                      :rowIndex="rowIndex"
                      :columnIndex="columnIndex"
                      :field="column.key"
                      :value="rowItem[column.key]"
                      :id="`${column.key}_${rowIndex}_cellEdit`"
                      v-bind="column.editRendererProps"
                      :disabled="rowDisabled(rowItem, rowIndex)"
                      :close="cancelEditCell"
                    />
                  </template>
                  <template v-else>
                    <template v-if="!column.renderer">
                      {{ rowItem[column.key] }}
                    </template>
                    <template v-else>
                      <component
                        v-if="!!column.renderer"
                        :is="column.renderer"
                        :row="rowItem"
                        :rowIndex="rowIndex"
                        :columnIndex="columnIndex"
                        :field="column.key"
                        :value="rowItem[column.key]"
                        :id="`${column.key}_${rowIndex}_cellCustom`"
                        :disabled="rowDisabled(rowItem, rowIndex)"
                        v-bind="column.rendererProps"
                      />
                    </template>
                  </template>
                </div>
              </div>
            </div>

            <div
              class="tBody__tr-right"
              ref="trRight"
            >
              <div
                v-for="(column, columnIndex) in notFixedColumns"
                :key="`${tableId}-td-${rowIndex}.${columnIndex}`"
                ref="td"
                class="tBody__td"
                :data-rowIndex="rowIndex"
                :data-colIndex="columnIndex"
                :class="[
                  {
                    'tBody__td--disabled': rowDisabled(rowItem, rowIndex),
                    'tBody__td--editable': !!column.editRenderer,
                    'tBody__td--selectable': !!column.selectable
                  },
                  ...getTdClasses(rowItem, columnIndex, rowIndex)
                ]"
                :style="{
                  'min-width': getMinTdWidth(column.width, column.minWidth),
                  'max-width': getMaxTdWidth(column.width, column.maxWidth)
                }"
                @dblclick="
                  cellCanEdit(column, rowItem) && !rowDisabled(rowItem, rowIndex)
                    ? editCell(rowIndex, column.key)
                    : () => false
                "
              >
                <div class="tBody__td-value">
                  <template
                    v-if="
                      editableCell.columnKey === column.key && editableCell.rowIndex === rowIndex
                    "
                  >
                    <component
                      v-if="!!column.editRenderer"
                      :is="column.editRenderer"
                      :row="rowItem"
                      :rowIndex="rowIndex"
                      :columnIndex="columnIndex"
                      :field="column.key"
                      :value="rowItem[column.key]"
                      :id="`${column.key}_${rowIndex}_cellEdit`"
                      v-bind="column.editRendererProps"
                      :disabled="rowDisabled(rowItem, rowIndex)"
                      :close="cancelEditCell"
                    />
                  </template>
                  <template v-else>
                    <template v-if="!column.renderer">
                      {{ rowItem[column.key] }}
                    </template>
                    <template v-else>
                      <component
                        v-if="!!column.renderer"
                        :is="column.renderer"
                        :row="rowItem"
                        :rowIndex="rowIndex"
                        :columnIndex="columnIndex"
                        :field="column.key"
                        :value="rowItem[column.key]"
                        :id="`${column.key}_${rowIndex}_cellCustom`"
                        :disabled="rowDisabled(rowItem, rowIndex)"
                        v-bind="column.rendererProps"
                      />
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </template>

      <template v-else>
        <draggable
          v-model="sortedItems"
          handle=".row-move"
        >
          <div
            v-for="(rowItem, rowIndex) in items"
            :key="`${tableId}-tr-${rowIndex}`"
            class="tBody__tr"
            :class="[
              {
                'tBody__tr--disabled': rowDisabled(rowItem, rowIndex),
                'tBody__tr--selected': isSelectedRow(rowItem, rowIndex)
              },
              ...getTrClasses(rowItem, rowIndex)
            ]"
            :style="trStyle"
          >
            <RowActions
              ref="rowActions"
              :row="rowItem"
              :actions="getRowActions(rowItem, rowIndex)"
              :rowIndex="rowIndex"
              :disabled="rowDisabled(rowItem, rowIndex)"
            />

            <div
              v-if="sortRowsEnabled"
              :key="`${tableId}-td-${rowIndex}.cellSort`"
              class="tBody__td tBody__td--sort"
              :class="[
                {
                  'tBody__td--disabled': rowDisabled(rowItem, rowIndex)
                }
              ]"
              :style="{
                'min-width': getMinTdWidth(32),
                'max-width': getMaxTdWidth(32)
              }"
            >
              <div class="tBody__td-value">
                <div class="row-move">
                  <a-icon type="menu" />
                </div>
              </div>
            </div>

            <div
              v-if="checkRowEnabled"
              :key="`${tableId}-td-${rowIndex}.cellCheckbox`"
              class="tBody__td tBody__td--sort"
              :class="[
                {
                  'tBody__td--disabled': rowDisabled(rowItem, rowIndex)
                }
              ]"
              :style="{
                'min-width': getMinTdWidth(32),
                'max-width': getMaxTdWidth(32)
              }"
            >
              <div class="tBody__td-value">
                <CheckRow
                  :disabled="rowDisabled(rowItem, rowIndex)"
                  :onChange="(checked) => onCheckRow(checked, rowItem)"
                  :checked="isChecked(rowItem[rowKey])"
                  :data="rowItem"
                />
              </div>
            </div>

            <div
              v-if="showRowNumber"
              :key="`${tableId}-td-${rowIndex}.cellNumber`"
              class="tBody__td tBody__td--sort"
              :class="[
                {
                  'tBody__td--disabled': rowDisabled(rowItem, rowIndex)
                }
              ]"
              :style="{
                'min-width': getMinTdWidth(40),
                'max-width': getMaxTdWidth(40)
              }"
            >
              <div
                v-if="!fetching"
                class="tBody__td-value"
              >
                {{ getPage(rowIndex) }}
              </div>
            </div>

            <div
              v-for="(column, columnIndex) in columns"
              :key="`${tableId}-td-${rowIndex}.${columnIndex}`"
              ref="td"
              class="tBody__td"
              :data-rowIndex="rowIndex"
              :data-colIndex="columnIndex"
              :class="[
                {
                  'tBody__td--disabled': rowDisabled(rowItem, rowIndex),
                  'tBody__td--editable': !!column.editRenderer,
                  'tBody__td--selectable': !!column.selectable
                },
                ...getTdClasses(rowItem, columnIndex, rowIndex)
              ]"
              :style="{
                'min-width': getMinTdWidth(column.width, column.minWidth),
                'max-width': getMaxTdWidth(column.width, column.maxWidth)
              }"
              @dblclick="
                cellCanEdit(column, rowItem) && !rowDisabled(rowItem, rowIndex)
                  ? editCell(rowIndex, column.key)
                  : () => false
              "
              @mouseenter="
                (e) =>
                  showFullContent(
                    e,
                    rowIndex,
                    column.key,
                    cellCanEdit(column, rowItem) && !rowDisabled(rowItem, rowIndex)
                  )
              "
              @mouseleave="leaveFromCell"
            >
              <div class="tBody__td-value">
                <template
                  v-if="editableCell.columnKey === column.key && editableCell.rowIndex === rowIndex"
                >
                  <component
                    v-if="!!column.editRenderer"
                    :is="column.editRenderer"
                    :row="rowItem"
                    :rowIndex="rowIndex"
                    :columnIndex="columnIndex"
                    :field="column.key"
                    :value="rowItem[column.key]"
                    :id="`${column.key}_${rowIndex}_cellEdit`"
                    v-bind="column.editRendererProps"
                    :disabled="rowDisabled(rowItem, rowIndex)"
                    :close="cancelEditCell"
                  />
                </template>
                <template v-else>
                  <template v-if="!column.renderer">
                    {{ rowItem[column.key] }}
                  </template>
                  <template v-else>
                    <component
                      v-if="!!column.renderer"
                      :is="column.renderer"
                      :row="rowItem"
                      :rowIndex="rowIndex"
                      :columnIndex="columnIndex"
                      :field="column.key"
                      :value="rowItem[column.key]"
                      :id="`${column.key}_${rowIndex}_cellCustom`"
                      :disabled="rowDisabled(rowItem, rowIndex)"
                      v-bind="column.rendererProps"
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
        </draggable>
      </template>

      <div
        v-if="items.length !== 0 && fetching"
        class="tBody__infiniteLoader"
      >
        <a-spin />
      </div>
    </template>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar"
// import Sortable from 'sortablejs';
import draggable from "vuedraggable"
import Selection from "@simonwep/selection-js"
import debounce from "../../../helpers/debounce"
import RowActions from "./RowActions.vue"
import CheckRow from "./renderers/CheckRow.vue"
import { getTdOffset } from "../helpers"
export default {
  name: "TBody",
  components: {
    RowActions,
    CheckRow,
    draggable
  },
  props: {
    fetching: {
      type: Boolean,
      require: true
    },
    items: {
      type: Array,
      required: true
    },
    ifExistFixedColumns: {
      type: Boolean,
      default: false
    },
    fixedColumns: {
      type: Array,
      required: true
    },
    notFixedColumns: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    tableId: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    minRowHeight: {
      type: [Number, String],
      required: true
    },
    maxRowHeight: {
      type: [Number, String],
      required: true
    },
    onScrollX: {
      type: Function,
      default: () => false
    },
    onScrollY: {
      type: Function,
      default: () => false
    },
    rowActions: {
      type: [Function, Array],
      required: true
    },
    showRowNumber: {
      type: Boolean,
      required: true
    },
    rowDisabled: {
      type: Function,
      required: true
    },
    theme: {
      type: String,
      required: true
    },
    rowClasses: {
      type: Function,
      required: true
    },
    cellClasses: {
      type: Function,
      required: true
    },
    onCheckRow: {
      type: Function,
      required: true
    },
    checkRowEnabled: {
      type: Boolean,
      required: true
    },
    checkedRows: {
      type: Array,
      required: true
    },
    rowKey: {
      type: String,
      required: true
    },
    sortRowsEnabled: {
      type: Boolean,
      required: true
    },
    onSortRows: {
      type: [Boolean, Function],
      default: false
    },
    selectableMode: {
      type: Boolean,
      required: true
    },
    selectableEnabled: {
      type: Boolean,
      required: true
    },
    onCalculation: {
      type: Function,
      required: true
    },
    infiniteScroll: {
      type: Boolean,
      required: true
    },
    loadMore: {
      type: Function,
      required: true
    },
    showMore: {
      type: Boolean,
      required: true
    },
    selectedRow: {
      type: Object,
      default: () => {}
    },
    selectedComparator: {
      type: Function,
      default: null
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedAreaClass: `tBody-${this.tableId}`,
      selectedElementClass: "tBody__td--selectable",
      perfectScrollbar: null,
      moreBlockScrollbar: null,
      selection: null,
      trStyle: {
        "min-height": this.getMinRowHeight(),
        "max-height": this.getMaxRowHeight()
      },
      editableCell: {
        rowIndex: null,
        columnKey: null
      },
      editableCellBuffer: {
        rowIndex: null,
        columnKey: null,
        canEdit: false
      }
    }
  },
  computed: {
    sortedItems: {
      get() {
        return this.items
      },
      set(value) {
        this.onSortRows(value)
      }
    }
  },
  mounted() {
    this.initSelection()
    this.initScrollbar()
    this.initEventListeners()
    this.checkTextOverflow()
  },
  updated() {
    this.initSelection()
    this.destroyScrollbar()
    this.initScrollbar()
    this.checkTextOverflow()
  },
  beforeDestroy() {
    this.destroyScrollbar()
    this.destroySelection()
  },
  methods: {
    getRowActions(row, rowIndex) {
      return typeof this.rowActions === "function"
        ? this.rowActions(row, rowIndex)
        : this.rowActions
    },
    isSelectedRow(rowObject) {
      return typeof this.selectedComparator === "function"
        ? this.selectedComparator(rowObject, this.selectedRow)
        : !!this.selectedRow && this.selectedRow[this.rowKey] === rowObject[this.rowKey]
    },
    isChecked(key) {
      return this.checkedRows.find((item) => item[this.rowKey] === key) !== undefined
    },
    initScrollbar() {
      this.perfectScrollbar = new PerfectScrollbar(this.$refs.tBody, {
        minScrollbarLength: 40
      })
    },
    destroyScrollbar() {
      if (this.perfectScrollbar !== null) {
        this.perfectScrollbar.destroy()
        this.perfectScrollbar = null
      }
    },
    initEventListeners() {
      this.$refs.tBody.addEventListener("ps-scroll-x", this.onScrollX)
      this.$refs.tBody.addEventListener("ps-scroll-y", this.onScrollY)
      this.$refs.tBody.addEventListener("ps-y-reach-end", this.handleLoadMore)
      if (this.ifExistFixedColumns) {
        this.$refs.tBody.addEventListener("scroll", this.handleTableRightHorizontalScroll)
      } else {
        this.$refs.tBody.addEventListener("scroll", this.handleTableHorizontalScroll)
      }
      window.addEventListener("resize", debounce(this.checkTextOverflow, 500))
    },
    destroyEventListeners() {
      this.$refs.tBody.removeEventListener("ps-scroll-x", this.onScrollX)
      this.$refs.tBody.removeEventListener("ps-scroll-y", this.onScrollY)
      this.$refs.tBody.removeEventListener("ps-y-reach-end", this.handleLoadMore)
      if (this.ifExistFixedColumns) {
        this.$refs.tBody.removeEventListener("scroll", this.handleTableRightHorizontalScroll)
      } else {
        this.$refs.tBody.removeEventListener("scroll", this.handleTableHorizontalScroll)
      }
      window.removeEventListener("resize", debounce(this.checkTextOverflow, 500))
    },
    handleTableHorizontalScroll(e) {
      if (this.$refs.rowActions) {
        this.$refs.rowActions.map((row) => {
          row.$el.style.right = `-${e.target.scrollLeft}px`
        })
      }
    },
    handleTableRightHorizontalScroll(e) {
      if (this.$refs.rowActionsFixedColumns) {
        this.$refs.rowActionsFixedColumns.map((row) => {
          row.$el.style.right = `-${e.target.scrollLeft}px`
        })
      }
    },
    handleLoadMore() {
      if (!this.fetching && this.infiniteScroll) {
        this.loadMore()
      }
    },
    getTrClasses(rowObject, rowIndex) {
      const customRowClasses = this.rowClasses(rowObject, rowIndex)
      return [
        ...customRowClasses,
        {
          "tBody__tr--even": this.theme === "odd" && rowIndex % 2,
          "tBody__tr--odd": this.theme === "odd" && !(rowIndex % 2)
        }
      ]
    },
    getMinRowHeight() {
      let height = "auto"
      if (typeof this.minRowHeight === "number") {
        height = `${this.minRowHeight}px`
      }
      return height
    },
    getMaxRowHeight() {
      let height = "auto"
      if (typeof this.maxRowHeight === "number") {
        height = `${this.maxRowHeight}px`
      }
      return height
    },
    getMinTdWidth(width, minWidth) {
      let tdWidth = "auto"
      if (width) {
        tdWidth = `${width}px`
      } else if (minWidth) {
        tdWidth = `${minWidth}px`
      }
      return tdWidth
    },
    getMaxTdWidth(width, maxWidth) {
      let tdWidth = "none"
      if (width) {
        tdWidth = `${width}px`
      } else if (maxWidth) {
        tdWidth = `${maxWidth}px`
      }
      return tdWidth
    },
    getTdClasses(rowObject, columnIndex, rowIndex) {
      const customCellClasses = this.cellClasses(rowObject, columnIndex, rowIndex)
      return [...customCellClasses]
    },
    checkTextOverflow() {
      if (this.$refs.td) {
        this.$refs.td.forEach((item) => {
          const { offsetWidth, scrollWidth, offsetHeight, scrollHeight } = item
          if (offsetWidth < scrollWidth || offsetHeight < scrollHeight) {
            item.classList.add("tBody__td--hasMoreText")
          } else if (item.classList.contains("tBody__td--hasMoreText")) {
            item.classList.remove("tBody__td--hasMoreText")
          }
        })
      }
    },
    editCell(rowIndex, columnKey) {
      this.editableCell = { rowIndex, columnKey }
    },
    cancelEditCell() {
      this.editableCell = { rowIndex: null, columnKey: null }
    },
    cellCanEdit(column, item) {
      return !!column.canEdit
        ? column.canEdit(item)
        : !!column.editRenderer && this.editableCell.rowIndex === null
    },
    ifMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    initSelection() {
      if (!this.ifMobileDevice() && this.selectableMode && this.selectableEnabled) {
        const $this = this
        this.selection = new Selection({
          startareas: [`.${this.selectedAreaClass}`],
          boundaries: [`.${this.selectedAreaClass}`],
          selectables: [`.${this.selectedElementClass}`],
          disableTouch: true,
          onSelect(evt) {
            const { target, originalEvent, selectedElements } = evt
            const selected = target.classList.contains("selected")
            if (!originalEvent.ctrlKey && !originalEvent.metaKey && !originalEvent.shiftKey) {
              selectedElements.forEach((s) => s.classList.remove("selected"))
              $this.firstSelectedElement = null
              this.clearSelection()
            }
            if (!selected || originalEvent.shiftKey) {
              if ($this.firstSelectedElement === null) {
                $this.firstSelectedElement = target
              } else {
                $this.lastSelectedElement = target
              }
              target.classList.add("selected")
              this.keepSelection()
              if (originalEvent.shiftKey) {
                const rowIndexFirst = $this.firstSelectedElement.getAttribute("data-rowIndex")
                const colIndexFirst = $this.firstSelectedElement.getAttribute("data-colIndex")
                const rowIndexLast = $this.lastSelectedElement.getAttribute("data-rowIndex")
                const colIndexLast = $this.lastSelectedElement.getAttribute("data-colIndex")
                const range = $this.getShiftSelection(
                  rowIndexFirst,
                  colIndexFirst,
                  rowIndexLast,
                  colIndexLast
                )
                this.getSelection().forEach((el) => {
                  el.classList.remove("selected")
                })
                this._selectedStore = []
                for (let i = range.startRow; i <= range.endRow; i += 1) {
                  for (let j = range.startColumn; j <= range.endColumn; j += 1) {
                    const el = document.querySelector(
                      `div[data-rowIndex='${i}'][data-colIndex='${j}']`
                    )
                    this._selectedStore.push(el)
                    el.classList.add("selected")
                  }
                }
              }
              $this.calculation($this.getCellValues(this.getSelection()))
            } else {
              target.classList.remove("selected")
              this.removeFromSelection(target)
              $this.calculation($this.getCellValues(this.getSelection()))
            }
          },
          onStart(evt) {
            const { selectedElements, originalEvent } = evt
            if (!originalEvent.ctrlKey && !originalEvent.metaKey) {
              selectedElements.forEach((s) => s.classList.remove("selected"))
              this.clearSelection()
            }
          },
          onMove(evt) {
            const { selectedElements, changedElements } = evt
            const removedElements = changedElements.removed
            selectedElements.forEach((s) => s.classList.add("selected"))
            removedElements.forEach((s) => s.classList.remove("selected"))
          },
          onStop(evt) {
            $this.calculation($this.getCellValues(evt.selectedElements))
            this.keepSelection()
          }
        })
        window.addEventListener("keydown", this.selectAllCells)
        window.addEventListener("keydown", this.unSelectAllCells)
        window.addEventListener("copy", this.copySelectedTable)
      }
    },
    destroySelection() {
      if (this.selection !== null) {
        this.selection.getSelection().forEach((s) => s.classList.remove("selected"))
        this.selection.clearSelection()
        this.selection.destroy()
        this.selection = null
        window.removeEventListener("keydown", this.selectAllCells)
        window.removeEventListener("keydown", this.unSelectAllCells)
        window.removeEventListener("copy", this.copySelectedTable)
      }
    },
    calculation(values) {
      this.onCalculation(values)
    },
    getShiftSelection(rowIndexFirst, columnIndexFirst, rowIndexLast, columnIndexLast) {
      return {
        startRow: Math.min(rowIndexFirst, rowIndexLast),
        endRow: Math.max(rowIndexFirst, rowIndexLast),
        startColumn: Math.min(columnIndexFirst, columnIndexLast),
        endColumn: Math.max(columnIndexFirst, columnIndexLast)
      }
    },
    getCellValues(elements) {
      return elements
        .map((item) => +item.getElementsByClassName("tBody__td-value")[0].innerHTML)
        .filter((item) => !Number.isNaN(item))
    },
    selectAllCells(e) {
      if (
        !!this.selection &&
        ((!!e.ctrlKey && e.code === "KeyA") || (!!e.metaKey && e.code === "KeyA"))
      ) {
        e.preventDefault()
        const cells = this.$refs.tBody.getElementsByClassName(this.selectedElementClass)
        ;[...cells].map((cell) => cell.classList.add("selected"))
        this.selection._selectedStore = [...cells]
        this.calculation(this.getCellValues([...cells]))
      }
    },
    unSelectAllCells(e) {
      if (!!this.selection && e.code === "Escape") {
        e.preventDefault()
        this.selection.getSelection().forEach((s) => s.classList.remove("selected"))
        this.selection.clearSelection()
      }
    },
    copySelectedTable(e) {
      if (this.selection && this.selection.getSelection().length > 0) {
        e.preventDefault()
        const selectedElements = this.selection.getSelection()
        const ranges = this.getSelectionRange(selectedElements)
        const table = this.getCopiedTable(selectedElements, ranges)
        e.clipboardData.setData("text/html", table)
        e.clipboardData.setData("text/plain", table.replace(/<[^>]*>/g, ""))
      }
    },
    getSelectionRange(selectedElements) {
      const rowIndexes = selectedElements.map((el) =>
        parseInt(el.getAttribute("data-rowIndex"), 10)
      )
      const columnIndexes = selectedElements.map((el) =>
        parseInt(el.getAttribute("data-colIndex"), 10)
      )
      return {
        startRow: Math.min(...rowIndexes),
        endRow: Math.max(...rowIndexes),
        startColumn: Math.min(...columnIndexes),
        endColumn: Math.max(...columnIndexes)
      }
    },
    getCopiedTable(elements, ranges) {
      const { startRow, endRow, startColumn, endColumn } = ranges
      let table = "<table>"
      for (let i = startRow; i <= endRow; i += 1) {
        table += "<tr>"
        for (let j = startColumn; j <= endColumn; j += 1) {
          const el = this.$refs.tBody.querySelector(
            `div[data-rowIndex='${i}'][data-colIndex='${j}']`
          )
          table += "<td>"
          table += el.innerHTML
          table += "</td>"
        }
        table += "</tr>"
      }
      table += "</table>"
      return table
    },
    showFullContent(e, rowIndex, columnKey, canEdit) {
      const { target } = e
      if (target.classList.contains("tBody__td--hasMoreText") && this.showMore) {
        this.editableCellBuffer = { rowIndex, columnKey, canEdit }

        this.fullContentCellTimeout = setTimeout(() => {
          const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = this.$refs.tBody
          const tdOffset = getTdOffset(target, offsetWidth, offsetHeight, scrollTop, scrollLeft)
          this.$refs.fullContent.classList.add("opened")
          this.$refs.fullContent.style.display = "block"
          this.$refs.fullContent.style.minWidth = `${target.clientHeight}px`
          this.$refs.fullContent.style.maxWidth =
            target.clientWidth < offsetWidth / 2
              ? `${offsetWidth / 2}px`
              : `${target.clientWidth}px`
          const {
            leftExist,
            rightExist,
            topExist,
            bottomExist,
            topPos,
            rightPos,
            bottomPos,
            leftPos,
            topOffset,
            rightOffset,
            bottomOffset,
            leftOffset
          } = tdOffset
          if ((leftExist && rightExist && leftOffset > rightOffset) || (leftExist && !rightExist)) {
            this.$refs.fullContent.style.right = `${rightPos}px`
          } else if (
            (leftExist && rightExist && leftOffset < rightOffset) ||
            (!leftExist && rightExist)
          ) {
            this.$refs.fullContent.style.left = `${leftPos}px`
          } else {
            this.$refs.fullContent.style.left = "0px"
            this.$refs.fullContent.style.maxWidth = "none"
          }
          if ((topExist && bottomExist && topOffset > bottomOffset) || (topExist && !bottomExist)) {
            this.$refs.fullContent.style.maxHeight = `${topOffset + target.clientHeight}px`
            this.$refs.fullContent.style.bottom = `${bottomPos}px`
          } else if (
            (topExist && bottomExist && topOffset < bottomOffset) ||
            (!topExist && bottomExist)
          ) {
            this.$refs.fullContent.style.maxHeight = `${bottomOffset + target.clientHeight}px`
            this.$refs.fullContent.style.top = `${topPos}px`
          } else {
            this.$refs.fullContent.style.top = "0px"
          }
          this.$refs.fullContent.innerHTML = target.firstChild.innerHTML
          // this.moreBlockScrollbar = new PerfectScrollbar(this.$refs.fullContent);
        }, 400)
      }
    },
    hideFullContent() {
      this.$refs.fullContent.innerHTML = ""
      this.$refs.fullContent.style.display = "none"
      this.$refs.fullContent.removeAttribute("style")
      this.$refs.fullContent.classList.remove("opened")
      // if (this.moreBlockScrollbar !== null) {
      //   this.moreBlockScrollbar.destroy();
      //   this.moreBlockScrollbar = null;
      // }
      this.editableCellBuffer = {
        rowIndex: null,
        columnKey: null
      }
      clearTimeout(this.fullContentCellTimeout)
    },
    leaveFromCell() {
      if (!this.$refs.fullContent.classList.contains("opened")) {
        clearTimeout(this.fullContentCellTimeout)
      }
    },
    getPage(value) {
      return this.offset + value + 1
    }
  },
  watch: {
    selectableEnabled() {
      if (this.selectableEnabled !== false) {
        this.initSelection()
      } else {
        this.destroySelection()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/config";
.tBody {
  $this: &;
  overflow: hidden;
  position: relative;
  &__fullContent {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    border: solid 1px $border-color;
    box-shadow: $block-shadow-lighter;
    padding: 7px;
    line-height: 1.4;
    display: none;
    overflow: auto;
  }
  // tr styles
  &__tr-left,
  &__tr-right {
    display: flex;
    justify-content: stretch;
    flex-grow: 1;
  }
  &__tr-left {
    .tBody__td:last-child {
      border-right: solid 1px $border-color;
    }
  }
  &__tr {
    position: relative;
    display: flex;
    justify-content: stretch;
    &--withFixedColumns {
      display: flex;
    }
    &--selected {
      #{$this}__td {
        background-color: #e5ffd9 !important;
      }
    }
    &--odd {
      #{$this}__td {
        background-color: #f9f9f9;
      }
    }
    .row-move {
      cursor: move;
    }
    &:hover {
      > .rowActions {
        display: block;
      }
    }
  }
  // td styles
  &__td {
    $td: &;
    flex-grow: 1;
    align-items: stretch;
    text-align: left;
    width: 100%;
    border-top: solid 1px $border-color;
    border-right: solid 1px $border-color;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    line-height: 1.4;
    overflow: hidden;
    &:last-child {
      border-right: none;
    }
    &--disabled {
      #{$td}-value {
        opacity: 0.5;
      }
    }
    &--editable {
      background-color: rgba($primary-color, 0.07) !important;
    }
    &--sort {
      #{$this}__td-value {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .row-move {
          cursor: move;
        }
      }
      &#{$td}--disabled {
        #{$td}-value {
          opacity: 1;
        }
      }
    }
    &--hasMoreText {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 16px;
        background: -moz-linear-gradient(top, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
        background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
      }
    }
    &.selected {
      background-color: rgba($warning-color, 0.1);
    }
    &-value {
      line-height: 21px;
      height: 100%;
    }
  }
  &__loader {
    width: 100%;
    min-height: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__infiniteLoader {
    border-top: solid 1px $border-color;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sortable-ghost {
  background-color: rgba($primary-color, 0.1);
}
</style>
