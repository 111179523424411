const themes =
  process.env.NODE_ENV === "development"
    ? {
        dark: {
          title: `
      background-color: #ffca00;
      color: #111111;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 2px 0 0 2px;
      font-size: 11px;
      line-height: 13px;
    `,
          body: `
      background-color: #555555;
      color: #ffffff;
      padding-left: 4px;
      padding-right: 4px;
      font-size: 11px;
      line-height: 13px;
      border-radius: 0 2px 2px 0;
    `
        },

        red: {
          title: `
      background-color: #F4541D;
      color: #FFFFFF;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 2px 0 0 2px;
      font-size: 11px;
      line-height: 13px;
    `,
          body: `
      background-color: #FF867E;
      color: #FFFFFF;
      padding-left: 4px;
      padding-right: 4px;
      font-size: 11px;
      line-height: 13px;
      border-radius: 0 2px 2px 0;
    `
        },

        request: {
          title: `
      background-color: #36AB80;
      color: #FFFFFF;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 2px;
      font-size: 11px;
      line-height: 13px;
    `
        },

        response: {
          title: `
      background-color: #0284FE;
      color: #FFFFFF;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 2px;
      font-size: 11px;
      line-height: 13px;
    `
        }
      }
    : {}

export default {
  info(message, ...rest) {
    if (process.env.NODE_ENV === "development" && !process.env.MESSENGER_LOGGER === "false") {
      if (rest.length) {
        console.info(message, ...rest)
      } else {
        console.log(`%cMSG` + `%c${message}`, themes.dark.title, themes.dark.body)
      }
    }
  },

  error(message, ...rest) {
    if (process.env.NODE_ENV === "development" && !process.env.MESSENGER_LOGGER === "false") {
      if (rest.length) {
        console.error(message, ...rest)
      } else {
        console.log(`%cMSG ERROR` + `%c${message}`, themes.red.title, themes.red.body)
      }
    }
  },

  request(message) {
    if (process.env.NODE_ENV === "development" && !process.env.MESSENGER_LOGGER === "false") {
      console.log(`%cREQUEST`, themes.request.title, message)
    }
  },

  response(message) {
    if (process.env.NODE_ENV === "development" && !process.env.MESSENGER_LOGGER === "false") {
      console.log(`%cRESPONSE`, themes.response.title, message)
    }
  }
}
