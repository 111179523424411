<template>
  <div>
    <OffersReport :slug="slug" />

    <a-divider>{{ $t("balanceHistory") }}</a-divider>

    <a-table
      :dataSource="dataSource"
      :columns="transactionTableColumns"
      :loading="isFetching"
      :scroll="{ y: 400 }"
      :pagination="paginationInfo"
      :rowKey="(record, index) => record.created_at + index"
      style="margin-bottom: 40px"
      size="middle"
      @change="onTableChange"
    >
      <template #sumCell="sum">
        <span :class="{ 'negative-sum': sum && sum < 0 }">
          {{ sum ? getFormatedSum(sum) : "-" }}
        </span>
      </template>

      <template #amountCell="{ type, amount }">
        <div :class="[type === 'WRITE-OFF' || amount < 0 ? 'negative-sum' : 'positive-sum']">
          {{ getAmountSum(amount, type) }}
        </div>
      </template>

      <template #transactionTypeRenderer="{ type, amount, billing_code }">
        <div :class="getTransactionTypeClass(type, amount)">
          {{ getLocalizedTransactionType(type, amount, billing_code).title }}
        </div>
        {{ getLocalizedTransactionType(type, amount, billing_code).description }}
      </template>

      <template #defaultCell="text">
        {{ text || "-" }}
      </template>

      <template #dateRenderer="value">
        <TableRendererDateTime
          :date="value"
          withSeconds
        />
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { onMounted } from "vue"
import i18n from "@/i18n"

import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"
import OffersReport from "./OffersReport.vue"

import getFormatedSum from "@/utils/getFormatedSum"

import { getBillingTransactions } from "@/modules/ADS/services/sellerServices.js"

import useSellerColumns from "@/modules/ADS/pages/Sellers/useSellerColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"

const props = defineProps({
  uuid: {
    type: String,
    required: true
  },

  slug: {
    type: String,
    required: true
  }
})

const {
  dataSource,
  isFetching,

  paginationInfo,

  fetchTableInfo
} = useAntTableQuery({
  queryFunction: getBillingTransactions,
  requestParams: { seller_id: props.uuid }
})
const { transactionTableColumns } = useSellerColumns()

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const getLocalizedTransactionType = (type, amount, billingCode) => {
  const types = {
    REPLENISHMENT:
      amount > 0
        ? i18n.t("transactionTypes.replenishment")
        : i18n.t("transactionTypes.writeOffBalance"),
    "BONUSES-REPLENISHMENT": i18n.t("transactionTypes.bonusesReplenishment"),
    "WRITE-OFF": i18n.t("transactionTypes.writeOff")
  }

  return {
    title: types[type],
    description: billingCode
      ? `${i18n.t("billing")} ${i18n.t("code").toLowerCase()}: ${billingCode}`
      : ""
  }
}

const getTransactionTypeClass = (type, amount) => {
  if (amount < 0 || type === "WRITE-OFF") return "negative-sum"
  return "positive-sum"
}

const getAmountSum = (amount, type) => {
  if (amount < 0) return getFormatedSum(amount)
  if (type === "WRITE-OFF") return getFormatedSum(-amount)
  return `+${getFormatedSum(amount)}`
}

onMounted(() => {
  paginationInfo.value.size = "default"
  onTableChange()
})

defineExpose({
  onTableChange
})
</script>

<style lang="scss" scoped>
.negative-sum {
  color: $red-color;
}
.positive-sum {
  color: $green-color;
}
</style>
