<template>
  <div>
    <Modal
      @close="PV_showSearchTypesModal(false)"
      :showModal="PV_showSearchTypesModalGet"
      :title="'Поиск типов товаров по атрибуту'"
    >
      <div>
        <label>
          <span
            >Выбранный атрибут: <strong>{{ PV_selectedAttribute.name }}</strong></span
          >
        </label>
        <template v-if="PV_atlsWithAttribute.length">
          <label>
            <span>Найден в типах товаров:</span>
          </label>
          <div class="types">
            <div
              v-for="item in PV_atlsWithAttribute"
              :key="item.code"
            >
              - {{ item.product_type_name }}
            </div>
          </div>
        </template>
        <template v-else>
          <label>
            <span>Ничего не найдено</span>
          </label>
        </template>
      </div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"

import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "SearchTypesModal",
  components: {
    Button,
    Modal
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations("ProductCatalog", {
      PV_showSearchTypesModal: "PV_showSearchTypesModal"
    }),
    ...mapActions("ProductCatalog", {})
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_showSearchTypesModalGet: "PV_showSearchTypesModal",
      PV_selectedAttribute: "PV_selectedAttribute",
      PV_atlsWithAttribute: "PV_atlsWithAttribute"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
.types {
  font-weight: 300;
  overflow-y: scroll;
  max-height: 200px;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
