export default {
  applications: "Застосунки",
  components: "Компоненти",
  views: "Сторінки",
  fields: "Поля",
  modelPermissions: "Дозволи моделей",
  createRole: "Створити роль",
  editRole: "Редагувати роль",
  roles: "Ролі",
  users: "Користувачі",
  isSuper: "Супер",
  createUser: "Створити користувача",
  userCreatedSuccessfully: "Користувача успішно створено",
  userUpdatedSuccessfully: "Користувача успішно оновлено",
  roleCreatedSuccessfully: "Роль успішно створено",
  roleUpdatedSuccessfully: "Роль успішно оновлено"
}
