var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticStyle:{"padding":"0 32px"},attrs:{"form":_vm.topUpForm}},[_c('a-form-item',{attrs:{"label":_vm.$t('date')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['processed_at', { rules: [{ required: true, message: _vm.$t('fillFields') }] }]),expression:"['processed_at', { rules: [{ required: true, message: $t('fillFields') }] }]"}],attrs:{"showTime":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('sum')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'amount',
        {
          rules: [{ required: true, message: _vm.$t('fillFields') }]
        }
      ]),expression:"[\n        'amount',\n        {\n          rules: [{ required: true, message: $t('fillFields') }]\n        }\n      ]"}],attrs:{"min":1}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('applySum')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['amountConfirm', { rules: [{ required: true, message: _vm.$t('fillFields') }] }]),expression:"['amountConfirm', { rules: [{ required: true, message: $t('fillFields') }] }]"}],attrs:{"min":0}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('comment')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'description',
        { rules: [{ max: 1000, message: _vm.$t('maxLengthError', { length: 1000 }) }] }
      ]),expression:"[\n        'description',\n        { rules: [{ max: 1000, message: $t('maxLengthError', { length: 1000 }) }] }\n      ]"}],attrs:{"autoSize":{ minRows: 4, maxRows: 10 }}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","gap":"16px","width":"100%"}},[_c('a-button',{on:{"click":_vm.clearForm}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleReplenish}},[_vm._v(" "+_vm._s(_vm.$t("topUp"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }