<template>
  <div>
    <PageHeader :title="$t('postOfficesSettings')">
      <template #filters>
        <a-select
          v-model="filteredInfo.delivery_provider"
          :placeholder="$t('poshta')"
          @change="handleTableFilterChange()"
        >
          <a-select-option
            v-for="item in deliveryProviderOptions"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>

        <a-select
          v-model="filteredInfo.location"
          showSearch
          style="width: 200px"
          :filterOption="false"
          allowClear
          :defaultActiveFirstOption="false"
          :placeholder="$t('settlement')"
          @search="onLocationSearch"
          @change="onLocationFilterChange"
        >
          <a-select-option
            v-for="item in locationsList"
            :key="item.uuid"
            :value="item.uuid"
          >
            {{ item.name.uk || item.name.ru || item.name.en }}
          </a-select-option>
        </a-select>

        <a-input-search
          v-model="filteredInfo.office_number"
          :placeholder="$t('postOfficeOrBox')"
          allowClear
          @search="handleTableFilterChange()"
        />

        <a-spin :spinning="isMetaFetching">
          <a-select
            :placeholder="$t('type')"
            v-model="filteredInfo.type"
            :options="postTypeOptions"
            allowClear
            style="width: 100%"
            @change="handleTableFilterChange()"
          />
        </a-spin>

        <a-select
          :placeholder="$t('status')"
          v-model="filteredInfo.is_active"
          :options="isActiveOptions"
          allowClear
          @change="handleTableFilterChange()"
        />
      </template>

      <template #actions>
        <a-button
          icon="undo"
          :loading="isFetching"
          @click="onResetFilters()"
        >
          {{ $t("resetFilters") }}
        </a-button>
      </template>
    </PageHeader>

    <a-breadcrumb v-if="filteredInfo.location && filteredInfo.delivery_provider">
      <a-breadcrumb-item
        v-for="item in breadcrumbs"
        :key="item.breadcrumbName"
      >
        {{ item.breadcrumbName }}
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="total-offices">
      {{ $t("totalPostOfficesCount") }}: <b>{{ paginationInfo.total }}</b>
    </div>

    <a-alert
      v-if="isCurrentLocationDisabled"
      :message="$t('currentLocationDisabled')"
      type="warning"
      showIcon
      class="disabled-location-warning"
    />

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      :loading="isFetching"
      rowKey="uuid"
      @change="onTableChange"
    >
      <template #defaultCellRenderer="value, record">
        <span :class="{ 'secondary-text': !record.is_active || isCurrentLocationDisabled }">
          {{ value }}
        </span>
      </template>

      <template #postTypeRenderer="value, record">
        <span :class="{ 'secondary-text': !record.is_active || isCurrentLocationDisabled }">
          {{ postTypes[value] }}
        </span>
      </template>

      <template #scheduleRenderer="weekSchedule, record">
        <a-popover
          v-if="getFirstDaySchedule(weekSchedule)"
          :class="{ 'secondary-text': !record.is_active || isCurrentLocationDisabled }"
          placement="bottom"
        >
          <template #content>
            <div
              v-for="(schedule, index) in getSchedule(weekSchedule)"
              class="popup-schedule"
              :key="index"
            >
              {{ schedule }}
            </div>
          </template>
          {{ getFirstDaySchedule(weekSchedule) }}
          <a-icon type="down" />
        </a-popover>
      </template>

      <template #weightRenderer="weight, record">
        <span :class="{ 'secondary-text': !record.is_active || isCurrentLocationDisabled }">
          {{ weight ? getFormatedSum(weight, true) : $t("withoutLimits") }}
        </span>
      </template>

      <template #maxCostRenderer="cost, record">
        <span :class="{ 'secondary-text': !record.is_active || isCurrentLocationDisabled }">
          {{ cost || $t("withoutLimits") }}
        </span>
      </template>

      <template #sizeLimitRenderer="sizes, record">
        <div
          class="lowercased"
          :class="{ 'secondary-text': !record.is_active || isCurrentLocationDisabled }"
        >
          {{ $t("widthShorted") }}: {{ sizes?.width || "-" }} {{ $t("heightShorted") }}:
          {{ sizes?.height || "-" }} {{ $t("lengthShorted") }}: {{ sizes?.length || "-" }}
        </div>
      </template>

      <template #isActiveRenderer="isActive">
        <span :class="[isActive && !isCurrentLocationDisabled ? 'success-text' : 'secondary-text']">
          {{ isActive && !isCurrentLocationDisabled ? $t("active") : $t("hidden") }}
        </span>
      </template>

      <template #actionsRenderer="record">
        <a-button
          type="dashed"
          shape="circle"
          :icon="record.is_active && !isCurrentLocationDisabled ? 'eye-invisible' : 'eye'"
          :class="[isCurrentLocationDisabled ? 'secondary-text' : 'warning-button']"
          @click="handleChangeActivity(record)"
        />
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import router from "@/router"

import {
  fetchLocationById,
  fetchPostOffices,
  updatePostOffice,
  fetchPostOfficesMeta,
  fetchLocations
} from "@/modules/MPAdmin/services/locationsService.js"
import getCurrentLocale from "@/utils/getCurrentLocale.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import getFormatedSum from "@/utils/getFormatedSum.js"

import { useStatuses } from "@/composables/useStatuses.js"
import usePostOfficesColumns from "./usePostOfficesColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useCancellableRequest from "@/composables/useCancellableRequest"
import { useLocationComposables } from "@/composables/useLocationComposables.js"

import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"

const props = defineProps({
  useAsPage: {
    type: Boolean,
    default: false
  }
})

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange
} = useAntTableQuery({
  queryFunction: fetchPostOffices,
  requestParams: { changeRouterQuery: true }
})

const { makeRequest } = useCancellableRequest(fetchLocations)
const { localityTypes } = useLocationComposables()

const { postOfficesColumns: columns } = usePostOfficesColumns({
  sortedInfo: computed(() => sortedInfo.value)
})
const { isActiveStatuses } = useStatuses()

const isMetaFetching = ref(false)
const currentLocation = ref({})
const currentLocationName = ref("")
const postTypes = ref({})
const locationsList = ref([])

const isCurrentLocationDisabled = computed(() =>
  Boolean(currentLocation.value.uuid && !currentLocation.value.is_active)
)
const isActiveOptions = computed(() => Object.values(isActiveStatuses.value))
const postTypeOptions = computed(() =>
  Object.entries(postTypes.value).map(([value, label]) => ({
    value,
    label
  }))
)

const breadcrumbs = computed(() => {
  return [
    currentLocationName.value,
    i18n.t(filteredInfo.value.delivery_provider.toLowerCase())
  ].map((item) => ({
    breadcrumbName: item
  }))
})
const deliveryProviderOptions = computed(() => [
  { value: "UKR_POSHTA", label: i18n.t("ukr_poshta") },
  { value: "NOVA_POSHTA", label: i18n.t("nova_poshta") }
])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const onResetFilters = () => {
  filteredInfo.value = {
    delivery_provider: "UKR_POSHTA"
  }
  handleTableFilterChange()
}

const getMeta = async () => {
  try {
    isMetaFetching.value = true

    const { data } = await fetchPostOfficesMeta()

    postTypes.value = data.type_fields
    isMetaFetching.value = false
  } catch (error) {
    if (error?.response?.status === 401) return

    setTimeout(getMeta, 2500)
  }
}

const getFirstDaySchedule = (days) => {
  if (getSchedule(days).length) {
    return getSchedule(days)[0]
  }
}

const getSchedule = (days) => {
  const week = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

  return week
    .map((day) => (days[day] ? `${i18n.t(`${day}Shorted`)}: ${days[day]}` : false))
    .filter(Boolean)
}

const getLocalizedCity = (location) => {
  return `${i18n.t(`locationTypes.${location.type}`).toLowerCase()} ${getLocalizedName(location)}`
}

const getLocalizedLocation = (location) => {
  const name = getLocalizedName(location)
  const locality = i18n.t(`locationTypes.${location.type}`).toLowerCase()

  return `${name} ${locality}`
}

const getLocalizedName = ({ name }) => {
  return name[getCurrentLocale()]
}

const getLocationInfo = async (value) => {
  try {
    const { data } = await fetchLocationById(value)

    currentLocation.value = data
    locationsList.value = [data]

    let location = data
    let breadcrumb = []

    while (location.parent && location.parent.type !== "COUNTRY") {
      breadcrumb.unshift(
        location.type === "STATE" || location.type === "DISTRICT"
          ? getLocalizedLocation(location)
          : getLocalizedCity(location)
      )
      location = location.parent
    }

    breadcrumb.unshift(
      getLocalizedName(location).includes("Крим") || location.type === "COUNTRY"
        ? getLocalizedName(location)
        : getLocalizedLocation(location)
    )

    currentLocationName.value = breadcrumb.join(", ")
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onLocationFilterChange = (value) => {
  if (value) {
    getLocationInfo(value)
  } else {
    currentLocation.value = {}
    currentLocationName.value = ""
    locationsList.value = []
  }

  handleTableFilterChange()
}

const handleChangeActivity = async ({ uuid, is_active }) => {
  if (!currentLocation.value?.is_active) {
    notification.error({
      message: `${i18n.t(`locationTypes.${currentLocation.value.type}`)} ${
        currentLocation.value.name.uk
      } ${i18n.t("inactive").toLowerCase()}`
    })
    return
  }
  try {
    await updatePostOffice({ uuid: uuid, payload: { is_active: !is_active } })

    notification.success({ message: i18n.t("updated") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onLocationSearch = async (value) => {
  try {
    const { data } = await makeRequest({
      queryParams: { locality: value, type: localityTypes.value.slice(2).map(({ value }) => value) }
    })
    locationsList.value = data.results
  } catch (e) {
    if (e.message === "canceled") return
  }
}

onMounted(() => {
  if (router.currentRoute.query.location) {
    getLocationInfo(router.currentRoute.query.location)
  }

  getMeta()
  setupTable({
    defaultFilters: {
      delivery_provider: router.currentRoute?.query?.delivery_provider || "UKR_POSHTA"
    }
  })

  onTableChange()
})
</script>

<style lang="scss" scoped>
.success-text {
  color: $green-color;
}

.warning-button {
  color: $dark-yellow-color;
  border-color: $dark-yellow-color;
  accent-color: $dark-yellow-color;

  &:hover,
  &:active {
    opacity: 0.75;
  }
}

.lowercased {
  text-transform: lowercase;
}

.popup-schedule {
  font-size: 14px;
  line-height: 22px;
}

.secondary-text {
  opacity: 0.45;
}

.total-offices {
  margin-bottom: 16px;
}

.disabled-location-warning {
  margin-bottom: 16px;
}
</style>
