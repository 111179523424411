<template>
  <div class="attributes-data-table">
    <template v-if="tableData && tableData.items.length">
      <SynDataTable
        :data="tableData"
        :queryAction="querySearch"
        :queryParams="queryParams"
        :fetching="fetchingStatus"
        rowKey="code"
        :columns="dataTableColumns"
        tableId="PU_attributesTable"
        class="margin-top"
        defaultOrder="-name"
        :limitValues="limitValues"
        theme="odd"
      ></SynDataTable>
    </template>
    <template v-else>
      <label><span>Ничего не найдено</span></label>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

export default {
  data() {
    return {
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ],
      timeout: null
    }
  },
  methods: {
    querySearch(queryParams) {
      if (!this.PU_selectedUnit) return
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PU_searchAttributes({ queryParams, selected: this.PU_selectedUnit })
      }, 500)
    },
    ...mapMutations("ProductCatalog", {
      changeQueryParams: "PU_attributesTableQueryParams"
    }),
    ...mapActions("ProductCatalog", {
      PU_searchAttributes: "PU_searchAttributes"
    })
  },
  computed: {
    dataTableColumns() {
      return [
        {
          key: "code",
          title: "Код",
          width: 104,
          sorting: true
        },
        {
          key: "name",
          title: "Название",
          sorting: true
        }
      ]
    },
    ...mapGetters("ProductCatalog", {
      queryParams: "PU_attributesTableQueryParams",
      tableData: "PU_attributesTableData",
      fetchingStatus: "PU_attributesTableFetchingStatus",
      PU_selectedUnit: "PU_selectedUnit"
    })
  }
}
</script>
