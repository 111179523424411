var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('div',{staticClass:"category-list"},[_c('a-spin',{attrs:{"spinning":_setup.isFetching}},[_c(_setup.Draggable,{attrs:{"animation":200,"draggable":".category-list__item"},on:{"end":_setup.onDragEnd},scopedSlots:_vm._u([(_vm.depth < 4)?{key:"footer",fn:function(){return [_c('a-button',{staticClass:"category-list__add-button",attrs:{"block":"","icon":"plus"},on:{"click":function($event){return _setup.createCategory()}}},[_vm._v(" "+_vm._s(_vm.$t("addCategory"))+" ")])]},proxy:true}:null],null,true),model:{value:(_setup.categoriesList),callback:function ($$v) {_setup.categoriesList=$$v},expression:"categoriesList"}},_vm._l((_setup.categoriesList),function(category){return _c('div',{key:category.id,staticClass:"category-list__item",class:{
            'category-list__item--disabled': !category.is_active,
            'category-list__item--opened': _setup.chosenCategory?.id === category.id
          },on:{"click":function($event){$event.stopPropagation();return _setup.chooseCategory(category)},"dblclick":function($event){$event.stopPropagation();return _setup.goToEditCategory(category.id)}}},[_c('a-icon',{staticClass:"category-list__item__grab-icon",attrs:{"type":"menu"}}),_c('a-icon',{staticClass:"category-list__item__icon",attrs:{"type":"setting","theme":"filled"},on:{"click":function($event){$event.stopPropagation();return _setup.goToEditCategory(category.id)}}}),(!category?.children_count)?_c('a-icon',{staticClass:"category-list__item__icon",attrs:{"type":"sync"},on:{"click":function($event){$event.stopPropagation();return _setup.handleReindex(category.id)}}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(category.name.ru ? category.name.ru : category.name)+" ")]),_c('a-badge',{attrs:{"count":category.children_count,"number-style":{
              backgroundColor: 'grey',
              color: 'white',
              boxShadow: 'none'
            }}})],1)}),0)],1)],1),(_setup.chosenCategory?.id)?[_c('CategoriesList',{attrs:{"parent":_setup.chosenCategory.id,"depth":_vm.depth + 1}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }