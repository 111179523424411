import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => {
    return [
      {
        key: "label",
        dataIndex: "label",
        title: "Title",
        scopedSlots: {
          customRender: "pageTitle"
        },
        fixed: "left",
        width: 515
      },
      {
        key: "slug",
        dataIndex: "slug",
        title: "Slug",
        width: 330
      },
      {
        key: "category",
        dataIndex: "category.name",
        title: i18n.t("pageCategory"),
        width: 180
      },
      {
        key: "location",
        dataIndex: "locations",
        title: i18n.t("location"),
        customRender: (locations) => locations.join(", "),
        width: 280
      },
      {
        key: "ordering",
        dataIndex: "ordering",
        title: i18n.t("ordering"),
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "ordering" && sortedInfo?.value?.order,
        align: "center",
        width: 90
      },
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("created"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
        width: 120
      },
      {
        key: "updated_at",
        dataIndex: "updated_at",
        title: i18n.t("updated"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "updated_at" && sortedInfo?.value?.order,
        fixed: "right",
        width: 120
      },
      {
        key: "is_active",
        dataIndex: "is_active",
        title: i18n.t("state"),
        scopedSlots: {
          customRender: "state"
        },
        fixed: "right",
        width: 120
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        fixed: "right",
        width: 65
      }
    ]
  })

  const pageCategoryColumns = computed(() => {
    return [
      {
        key: "ordering",
        dataIndex: "ordering",
        title: i18n.t("ordering"),
        scopedSlots: {
          customRender: "ordering"
        },
        width: 140
      },
      {
        key: "name_ru",
        dataIndex: "name.ru",
        title: `${i18n.t("categoryName")}(RU)`,
        scopedSlots: {
          customRender: "name-ru"
        },
        width: 300
      },
      {
        key: "name_uk",
        dataIndex: "name.uk",
        title: `${i18n.t("categoryName")}(UA)`,
        scopedSlots: {
          customRender: "name-uk"
        },
        width: 300
      },
      {
        key: "name_en",
        dataIndex: "name.en",
        title: `${i18n.t("categoryName")}(EN)`,
        scopedSlots: {
          customRender: "name-en"
        },
        width: 300
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        width: 65
      }
    ]
  })

  return { columns, pageCategoryColumns }
}
