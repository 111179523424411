<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <div>
      {{ $t("dateOfferUpdate") }}:
      <b style="padding-left: 8px">{{ getFormatedDateTime(order.updated_at) }}</b>
    </div>
    <div v-show="order?.cancel_reason">
      {{ $t("reasonDeclineOffer") }}: <b style="padding-left: 8px">{{ order.cancel_reason }}</b>
    </div>

    <a-form-model-item
      :label="$t('changeCommissionStatus')"
      style="width: 180px; margin: 18px 0 0 0"
      prop="status"
    >
      <a-select
        v-model="form.status"
        :options="comissionStatuses"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('reasonCommissionDecline')"
      prop="cancel_reason"
    >
      <a-textarea
        v-model="form.cancel_reason"
        :autoSize="{ minRows: 2, maxRows: 2 }"
        allow-clear
      />
      <div class="input-counter">
        <span :class="{ 'input-counter--red': form.cancel_reason?.length > 100 }">
          {{ form.cancel_reason?.length || 0 }}
        </span>
        / 100
      </div>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('commentFromManager')"
      prop="comment"
    >
      <a-textarea
        v-model="form.comment"
        allow-clear
        :autoSize="{ minRows: 6, maxRows: 6 }"
      />
      <div class="input-counter">
        <span :class="{ 'input-counter--red': form.comment?.length > 500 }">
          {{ form.comment?.length || 0 }}
        </span>
        / 500
      </div>
    </a-form-model-item>

    <div style="display: flex; gap: 16px">
      <a-form-model-item
        label="Ref"
        prop="enabled"
      >
        <a-switch v-model="form.enabled" />
      </a-form-model-item>

      <a-form-model-item
        label="% Ref"
        prop="percent"
      >
        <a-input-number v-model="form.percent" />
      </a-form-model-item>
    </div>

    <div class="drawer-footer">
      <a-button @click="handleClose">
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        @click="handleConfirm"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { ref, reactive, computed } from "vue"
import i18n from "@/i18n"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

const props = defineProps({
  order: {
    type: Object,
    default: () => ({})
  },
  comissionStatuses: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["submit", "close"])

const formRef = ref()
const form = reactive({
  status: props.order.ref.status || null,
  cancel_reason: props.order.ref.cancel_reason || "",
  comment: props.order.ref.comment || "",
  enabled: props.order.ref.enabled || false,
  percent: props.order.ref.percent || null
})
const formRules = computed(() => ({
  cancel_reason: [{ max: 100, message: i18n.t("maxLengthError", { length: 100 }) }],
  comment: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]
}))

const handleConfirm = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }
  const { percent, enabled, ...payload } = form

  if (percent !== null) {
    payload.enabled = enabled
  }
  payload.percent = percent || 0

  emit("submit", payload)
}

const handleClose = () => {
  emit("close")
}
</script>

<style lang="scss" scoped>
.input-counter {
  position: absolute;
  right: 0;
  top: 18px;
  color: $grey-font;

  &--red {
    color: $red-color;
  }
}
</style>
