<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showCreateValueListModal"
      :title="'Создание нового списка'"
    >
      <div>
        <div>
          <label
            ><span
              >Выберите атрибут для нового списка<span class="required-field">*</span></span
            ></label
          >
          <Autocomplete
            :data="PV_attributesForNewValueAutocomplete"
            event="input"
            @event="fetchAttributes"
            @select="PV_processSelectedAttributeValue"
            :size="'small'"
            :defaultValue="defaultAttr"
          />
        </div>
        <div class="margin-top-10">
          <div v-if="PV_convertStringWithValues">
            <label>
              <span>Список новых значений:</span>
            </label>
            <div class="values">
              <div v-for="item in PV_attributeValues">- {{ item }}</div>
            </div>
          </div>
          <div v-if="!PV_convertStringWithValues">
            <label>
              <span
                >Ввести список значений вручную, указав каждое последущее с новой строки
                <span class="required-field">*</span></span
              >
            </label>
            <textarea
              @input="onValuesInput"
              rows="5"
            ></textarea>
            <ValidationErrors
              :lengthError="valuesLengthError"
              :quantityError="showNumberOfValuesError"
              :repetativeValuesError="showRepetativeValuesError"
              :emptyError="showEmptyError"
            />
          </div>
        </div>
        <div class="margin-top-10">
          <label
            ><span
              >Введите наименование нового списка<span class="required-field">*</span></span
            ></label
          >
          <Input
            size="medium"
            :value="description"
            @input="onDescriptionInput"
          />
          <ValidationErrors
            :lengthError="!validDescriptionLength"
            :uniqueError="!PV_checkUniqueValueDescriptionGet"
          />
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewValue"
          >Создать</Button
        >
      </span>
    </Modal>
    <ConvertConfirmationModal />
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import Autocomplete from "components/Autocomplete"
import ConvertConfirmationModal from "./ConvertConfirmationModal/ConvertConfirmationModal"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { mapActions, mapMutations, mapGetters, mapState } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import validEmpty from "utils/validations/validEmpty"
import validArrayItemsCount from "utils/validations/validArrayItemsCount"
import validArrayItemsMaxLength from "utils/validations/validArrayItemsMaxLength"
import validArrayRepetativeItems from "utils/validations/validArrayRepetativeItems"

export default {
  name: "CreateValueModal",
  components: {
    Button,
    Modal,
    Input,
    Autocomplete,
    ConvertConfirmationModal,
    ValidationErrors
  },
  data() {
    return {
      timeoutDescription: null,
      timeoutValues: null,
      timeoutAttr: null,
      validDescriptionLength: true,
      valuesLengthError: false,
      showNumberOfValuesError: false,
      showRepetativeValuesError: false,
      showEmptyError: false
    }
  },
  methods: {
    onValuesInput(event) {
      let value = event.target.value
      // build array
      let valuesArray = value
        .split("\n")
        .map((item) => {
          return item.trim()
        })
        .filter((item) => item !== "")

      this.showEmptyError = !validEmpty(value)

      this.showNumberOfValuesError = !validArrayItemsCount(valuesArray, 50)
      this.valuesLengthError = !validArrayItemsMaxLength(valuesArray, 50)
      this.showRepetativeValuesError = !validArrayRepetativeItems(valuesArray)

      // set
      if (valuesArray && valuesArray.length) {
        this.PV_valuesForNewValueList(valuesArray)
      }
    },
    onDescriptionInput(value) {
      // validate length
      this.validDescriptionLength = validMaxLength(value, 50)
      this.checkUniqueValueDescription(value)
      // set value
      this.PV_descriptionForNewValue(value)
    },
    checkUniqueValueDescription(value) {
      clearTimeout(this.timeoutDescription)
      this.timeoutDescription = setTimeout(() => {
        this.PV_checkUniqueValueDescription({ value })
      }, 500)
    },
    fetchAttributes(value) {
      clearTimeout(this.timeoutAttr)
      this.timeoutAttr = setTimeout(() => {
        this.PV_fetchAttributesForNewValueAutocomplete(value)
      }, 500)
    },
    createNewValue() {
      this.PV_createNewValueList()
      this.closeModal()
    },
    closeModal() {
      this.PV_showCreateValueListModalSet(false)
      this.resetValues()
    },
    resetValues() {
      this.timeoutDescription = null
      this.timeoutValues = null
      this.timeoutAttr = null
      this.validDescriptionLength = true
      this.valuesLengthError = false
      this.showNumberOfValuesError = false
      this.showRepetativeValuesError = false
      this.showEmptyError = false
    },
    ...mapMutations("ProductCatalog", {
      PV_valuesForNewValueList: "PV_valuesForNewValueList",
      PV_descriptionForNewValue: "PV_descriptionForNewValue",
      PV_showCreateValueListModalSet: "PV_showCreateValueListModal",
      PV_checkUniqueValueDescriptionMute: "PV_checkUniqueValueDescription"
    }),
    ...mapActions("ProductCatalog", {
      PV_createNewValueList: "PV_createNewValueList",
      PV_checkUniqueValueDescription: "PV_checkUniqueValueDescription",
      PV_fetchAttributesForNewValueAutocomplete: "PV_fetchAttributesForNewValueAutocomplete",
      PV_processSelectedAttributeValue: "PV_processSelectedAttributeValue"
    })
  },
  computed: {
    description() {
      return this.PV_descriptionForNewValueGet === false ? "" : this.PV_descriptionForNewValueGet
    },
    defaultAttr() {
      return this.PV_selectedAttributeValue === false ? "" : this.PV_selectedAttributeValue.name
    },
    enableCreateButton() {
      let show = false

      if (
        this.PV_selectedAttributeValue &&
        this.PV_descriptionForNewValueGet &&
        (this.PV_valuesForNewValueListGet || this.PV_attributeValues.length) &&
        this.validDescriptionLength &&
        this.PV_checkUniqueValueDescriptionGet &&
        !this.valuesLengthError &&
        !this.showNumberOfValuesError &&
        !this.showEmptyError &&
        !this.showRepetativeValuesError
      ) {
        show = true
      }
      return show
    },
    ...mapGetters("ProductCatalog", {
      PV_valuesForNewValueListGet: "PV_valuesForNewValueList",
      PV_descriptionForNewValueGet: "PV_descriptionForNewValue",
      PV_showCreateValueListModal: "PV_showCreateValueListModal",
      PV_checkUniqueValueDescriptionGet: "PV_checkUniqueValueDescription",
      PV_attributesForNewValueAutocomplete: "PV_attributesForNewValueAutocomplete",
      PV_selectedAttributeValue: "PV_selectedAttributeValue",
      PV_convertStringWithValues: "PV_convertStringWithValues",
      PV_attributeValues: "PV_attributeValues"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}

.values {
  font-weight: 300;
  overflow-y: scroll;
  max-height: 200px;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
