<template>
  <div>
    <PageHeader :title="$t('sessions')">
      <!-- test input for users file -->

      <template #actions>
        <a-input
          ref="fileInputRef"
          type="file"
          @change="onFileChange"
        />
      </template>
    </PageHeader>

    <div class="sessions-wrapper">
      <a-table
        :dataSource="data.users"
        :columns="columnsWithFilters"
        :pagination="paginationInfo"
        :loading="isFetching"
        :customRow="customRowParams"
        :scroll="{ x: true }"
        rowKey="id"
        size="middle"
        @change="onTableChange"
      >
        <template #userRenderer="record">
          <div>
            <a-popover
              placement="topLeft"
              trigger="click"
            >
              <template #title>
                {{ record.user_id !== "None" ? `User: ${record.user_id}` : "Mids:" }}
              </template>
              <template #content>
                <div
                  v-for="(mid, index) in record.mids"
                  :key="`${mid}_${index}`"
                >
                  {{ mid }}

                  <a-button
                    type="link"
                    icon="copy"
                    @click="onCopyText(mid)"
                  />
                </div>
              </template>

              <a-button
                :icon="record.mids?.length > 1 ? 'user' : 'robot'"
                shape="circle"
              />
            </a-popover>
          </div>
        </template>

        <template #nullSessionsTitle>
          <a-tooltip
            placement="topLeft"
            :title="$t('null_sessions_count')"
          >
            {{ $t("null_sessions_count_shorted") }}
          </a-tooltip>
        </template>

        <template #notRelevantSessionsTitle>
          <a-tooltip
            placement="topLeft"
            :title="$t('non_relevant_sessions_count')"
          >
            {{ $t("non_relevant_sessions_count_shorted") }}
          </a-tooltip>
        </template>
      </a-table>

      <a-collapse
        v-if="currentSessions.length"
        defaultActiveKey="0"
        class="sessions-wrapper__sessions"
        expandIconPosition="right"
      >
        <a-collapse-panel
          v-for="(session, index) in currentSessions"
          :key="index"
        >
          <template #header>
            <div class="session-header">
              <div
                class="session-title"
                :class="{ 'session-title__danger': session.characterization }"
              >
                {{ $t("session") }} {{ index }}
                <span v-if="session.characterization">
                  {{ $t(`sessionCharacterization.${session.characterization}`) }}
                </span>
              </div>

              <div>
                {{ $t("start") }}: {{ getFormatedDate(session.start_time) }}
                {{ getFormatedTime(session.start_time, true) }}
              </div>
            </div>
          </template>

          <SessionGraph :events="session.events_per_minute" />
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue"
import { notification, message } from "ant-design-vue"
import i18n from "@/i18n"

import PageHeader from "@/ant-components/PageHeader/index.js"
import useSessionsColumns from "./useSessionsColumns.js"
import SessionGraph from "./components/SessionGraph.vue"
import { getFormatedDate, getFormatedTime } from "@/utils/getFormatedDateTime"

const { sessionsListColumns: columns } = useSessionsColumns()
const filteredInfo = ref({})

const fileInputRef = ref()
const data = ref({})
const isFetching = ref(false)
const currentSessions = ref([])
const dataSource = ref([])
const paginationInfo = ref({
  current: 1,
  total: 0,
  pageSize: 10,
  total: 0,
  size: "default",
  showSizeChanger: true,
  showQuickJumper: false,
  pageSizeOptions: ["10", "25", "50", "100"]
})

const columnsWithFilters = ref(columns.value)

const onTableChange = async (pagination = paginationInfo.value) => {
  currentSessions.value = [] //
  paginationInfo.value = pagination // remove after test

  // fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const customRowParams = (record) => {
  return {
    on: {
      click: () => onRowClick(record)
    },
    style: { background: currentSessions.value === record.sessions ? "#e6f7ff" : "" }
  }
}

const onRowClick = (record) => {
  if (currentSessions.value === record.sessions) {
    return
  }

  currentSessions.value = []
  nextTick(() => {
    currentSessions.value = record.sessions
  })
}

const onFileChange = (e) => {
  const [file] = e.target.files

  if (file) {
    const reader = new FileReader()

    reader.onload = (e) => {
      try {
        data.value = JSON.parse(e.target.result)

        columnsWithFilters.value = columns.value.map((item) => ({
          ...item,
          filters:
            data.value?.meta?.filters[item.key]?.map((item) => ({
              value: item,
              text: item
            })) || []
        }))
      } catch (error) {
        notification.error({ message: i18n.t("error") })
      }
    }

    reader.readAsText(file)
  }
}

const onCopyText = (value) => {
  navigator.clipboard.writeText(value)

  message.info(i18n.t("copied"))
}

onMounted(() => {
  onTableChange()
})
</script>

<style lang="scss" scoped>
.sessions-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;

  & > :last-child {
    width: calc(40% - 8px);
  }

  & > :first-child {
    width: calc(60% - 8px);
  }

  &__sessions {
    height: min-content;
    background: transparent;
  }
}

.mids-container {
  max-width: 450px;
  max-height: 450px;
  padding-right: 20px;
  overflow: auto;
}

.session-header {
  display: flex;
  justify-content: space-between;
}

.session-title {
  display: flex;
  gap: 8px;
  height: min-content;

  &__danger {
    color: $red-color;
  }
}
</style>
