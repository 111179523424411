<template>
  <div v-if="date">
    <div>{{ timestamp ? getFormatedDateByTimestamp(date) : getFormatedDate(date) }}</div>
    <div :style="{ opacity: '0.45' }">
      {{
        timestamp
          ? getFormatedTimeByTimestamp(date, withSeconds)
          : getFormatedTime(date, withSeconds)
      }}
    </div>
  </div>
</template>

<script>
import {
  getFormatedDate,
  getFormatedTime,
  getFormatedDateByTimestamp,
  getFormatedTimeByTimestamp
} from "@/utils/getFormatedDateTime"

export default {
  name: "TableRendererDateTime",

  props: {
    date: [Date, Number, String],

    timestamp: {
      type: Boolean,
      default: false
    },

    withSeconds: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getFormatedDate,
    getFormatedTime,
    getFormatedDateByTimestamp,
    getFormatedTimeByTimestamp
  }
}
</script>
