import ValuesDescriptionTable from "./valuesDescriptionTable/valuesDescriptionTable"
import ValuesTable from "./valuesTable/valuesTable"
import CreateValueList from "./createValueList/createValueList"
import CreateValue from "./createValue/createValue"
import CheckUniqueValueDescription from "../validations/checkUniqueValueDescription"
import DeleteValue from "./deleteValue/deleteValue"
import AttributesTable from "./attributesTable/attributesTable"
import SearchTypes from "./searchTypes/searchTypes"
import AddAttribute from "./addAttribute/addAttribute"
import DeleteAttribute from "./deleteAttribute/deleteAttribute"
import ShowProducts from "./showProducts/showProducts"
import SearchValueInLists from "./searchValueInLists/searchValueInLists"
import ApproveVLD from "./approveVLD/approveVLD"

const modules = {
  ValuesDescriptionTable,
  CreateValueList,
  CreateValue,
  CheckUniqueValueDescription,
  ValuesTable,
  DeleteValue,
  AttributesTable,
  SearchTypes,
  AddAttribute,
  DeleteAttribute,
  ShowProducts,
  SearchValueInLists,
  ApproveVLD
}

export default {
  modules
}
