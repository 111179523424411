import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PU_showCreateUnitModal: false,
  PU_nameForNewUnit: false,
  PU_descriptionForNewUnit: false,
  PU_synonymsForNewUnit: false,
  PU_baseForNewUnit: false,
  PU_coefForNewUnit: false,
  PU_unitsForBaseSelect: [],
  PU_selectedBaseUnit: false,
  PU_unitsForBaseSelectAutocomplete: []
})

const getters = {
  PU_showCreateUnitModal: (state) => state.PU_showCreateUnitModal,
  PU_nameForNewUnit: (state) => state.PU_nameForNewUnit,
  PU_descriptionForNewUnit: (state) => state.PU_descriptionForNewUnit,
  PU_synonymsForNewUnit: (state) => state.PU_synonymsForNewUnit,
  PU_baseForNewUnit: (state) => state.PU_baseForNewUnit,
  PU_coefForNewUnit: (state) => state.PU_coefForNewUnit,
  PU_unitsForBaseSelect: (state) => state.PU_unitsForBaseSelect,
  PU_selectedBaseUnit: (state) => state.PU_selectedBaseUnit,
  PU_unitsForBaseSelectAutocomplete: (state) => state.PU_unitsForBaseSelectAutocomplete
}

const mutations = {
  PU_showCreateUnitModal(state, value) {
    state.PU_showCreateUnitModal = value
  },
  PU_nameForNewUnit(state, value) {
    state.PU_nameForNewUnit = value
  },
  PU_descriptionForNewUnit(state, value) {
    state.PU_descriptionForNewUnit = value
  },
  PU_synonymsForNewUnit(state, value) {
    state.PU_synonymsForNewUnit = value
  },
  PU_baseForNewUnit(state, value) {
    state.PU_baseForNewUnit = value
  },
  PU_coefForNewUnit(state, value) {
    state.PU_coefForNewUnit = value
  },
  PU_unitsForBaseSelect(state, value) {
    state.PU_unitsForBaseSelect = value
  },
  PU_selectedBaseUnit(state, value) {
    state.PU_selectedBaseUnit = value
  },
  PU_unitsForBaseSelectAutocomplete(state, value) {
    state.PU_unitsForBaseSelectAutocomplete = value
  }
}

const actions = {
  PU_createNewUnit({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let synonyms = []
    if (getters.PU_synonymsForNewUnit !== false) {
      synonyms = getters.PU_synonymsForNewUnit.split(",").map((item) => {
        return item.trim()
      })
    }

    let unit = {
      name: getters.PU_nameForNewUnit,
      synonyms,
      description: getters.PU_descriptionForNewUnit
    }
    // only if both coef and base were assigned:
    if (getters.PU_selectedBaseUnit !== false && getters.PU_coefForNewUnit !== false) {
      unit.base = getters.PU_selectedBaseUnit.code
      unit.coef = getters.PU_coefForNewUnit
    }
    // start request
    API_MODERATION.post(`${apiUrls.unit}`, unit)
      .then((r) => r.data.message.unit)
      .then((product) => {
        restartTableSearch({ getters, dispatch })

        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PU_resetCreateUnitValues")

        notification.info({ message: "Новая еденица измерения добавлена" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PU_resetCreateUnitValues")

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  },
  PU_resetCreateUnitValues({ commit }) {
    commit("PU_nameForNewUnit", false)
    commit("PU_descriptionForNewUnit", false)
    commit("PU_baseForNewUnit", false)
    commit("PU_coefForNewUnit", false)
    commit("PU_synonymsForNewUnit", false)
    commit("PU_unitsForBaseSelect", [])
    commit("PU_selectedBaseUnit", false)
    commit("PU_unitsForBaseSelectAutocomplete", [])
  },
  PU_fetchUnitsForBaseSelect({ commit }, value) {
    let searchData = buildMagicSearch({
      resource: "AUD",
      field: "name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((units) => {
        commit("PU_unitsForBaseSelect", units)
        let unitsArray = units.map((item) => {
          return item.name
        })
        commit("PU_unitsForBaseSelectAutocomplete", unitsArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PU_processSelectedBaseUnit({ commit, getters }, value) {
    let selectedUnit = getters.PU_unitsForBaseSelect.find((item) => {
      return item.name === value
    })
    commit("PU_selectedBaseUnit", selectedUnit)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function restartTableSearch({ getters, dispatch }) {
  dispatch("PU_searchUnits", getters.PU_unitsTableQueryParams)
}
