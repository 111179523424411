<template>
  <a-spin :spinning="fetching">
    <div class="permissions">
      <div class="permissions__block">
        <div class="permissions__block-title">
          {{ $t("applications") }}
        </div>

        <div class="permissions__block-list">
          <div
            v-for="application in permissions"
            class="permissions__item"
            :class="{ 'permissions__item--selected': isApplicationSelected(application.id) }"
            :key="application.id"
            @click.stop="toggleApplication(application.id)"
          >
            <a-checkbox
              @change="(e) => toggleApplicationCheckbox(e.target.checked, application)"
              :checked="isCheckedApplication(application.id)"
            />
            {{ application.display_name }}
          </div>
        </div>
      </div>

      <div class="permissions__block">
        <div class="permissions__block-title">
          {{ $t("components") }}
        </div>

        <div
          v-if="selectedApplication"
          class="permissions__block-list"
        >
          <div
            v-for="component in components"
            class="permissions__item"
            :class="{ 'permissions__item--selected': isComponentSelected(component.id) }"
            :key="component.id"
            @click="toggleComponent(component.id)"
          >
            <a-checkbox
              @change="(e) => toggleComponentCheckbox(e.target.checked, component)"
              :checked="isCheckedComponent(component.id)"
              :disabled="!isCheckedApplication(selectedApplication)"
            />
            {{ component.display_name }}
          </div>
        </div>
      </div>

      <div class="permissions__block">
        <div class="permissions__block-title">
          {{ $t("views") }}
        </div>

        <div
          v-if="selectedApplication"
          class="permissions__block-list"
        >
          <div
            v-for="view in views"
            class="permissions__item"
            :key="view.id"
          >
            <a-checkbox
              @change="(e) => toggleViewCheckbox(e.target.checked, view)"
              :checked="isCheckedView(view.id)"
              :disabled="!isCheckedComponent(selectedComponent)"
            />
            {{ view.display_name }}
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue"

const props = defineProps({
  permissions: {
    type: Array,
    required: true
  },
  fetching: {
    type: Boolean,
    default: false
  },
  defaultPermissions: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["change"])

const selectedApplication = ref(null)
const selectedComponent = ref(null)
const components = ref([])
const views = ref([])
const selectedPermissions = ref([])

const getComponents = computed(() => {
  const currentApplication = props.permissions.find(({ id }) => id === selectedApplication.value)

  if (!currentApplication?.components) return []

  return currentApplication?.components?.filter((item) => item.code !== "NONECMP")
})

const getViews = computed(() => {
  const currentComponent = components.value.find((item) => item.id === selectedComponent.value)
  if (!currentComponent) return []

  return currentComponent.views
})

const toggleApplication = (id) => {
  selectedApplication.value = selectedApplication.value === id ? null : id
}

const toggleComponent = (id) => {
  selectedComponent.value = selectedComponent.value === id ? null : id
}

const toggleApplicationCheckbox = (checked, value) => {
  if (checked) {
    selectedApplication.value = value.id
    selectedPermissions.value = [...selectedPermissions.value, value]
  } else {
    selectedPermissions.value = filterById(selectedPermissions.value, value.id)
  }

  emit("change", selectedPermissions.value)
}

const filterById = (arr, filteredId) => {
  return arr.filter(({ id }) => id !== filteredId)
}

const toggleComponentCheckbox = (checked, value) => {
  if (checked) {
    selectedComponent.value = value.id
  }

  selectedPermissions.value = selectedPermissions.value.map((application) => {
    if (application.id !== selectedApplication.value) return application

    if (checked) {
      return {
        ...application,
        components: [...application.components, value]
      }
    }

    return {
      ...application,
      components: filterById(application.components, value.id)
    }
  })

  emit("change", selectedPermissions.value)
}

const toggleViewCheckbox = (checked, value) => {
  selectedPermissions.value = selectedPermissions.value.map((application) => {
    if (application.id !== selectedApplication.value) return application

    const components = application.components.map((component) => {
      if (component.id !== selectedComponent.value) return component

      return {
        ...component,
        views: checked ? [...component.views, value] : filterById(component.views, value.id)
      }
    })

    return {
      ...application,
      components
    }
  })

  emit("change", selectedPermissions.value)
}

const isApplicationSelected = (id) => selectedApplication.value === id
const isComponentSelected = (id) => selectedComponent.value === id

const isCheckedApplication = (id) => !!selectedPermissions.value.find((item) => item.id === id)

const isCheckedComponent = (id) => {
  const currentApplication = selectedPermissions.value.find(
    (item) => item.id === selectedApplication.value
  )
  if (!currentApplication) return false

  return !!currentApplication.components.find((component) => component.id === id)
}

const isCheckedView = (id) => {
  const currentApplicationIndex = selectedPermissions.value.findIndex(
    (item) => item.id === selectedApplication.value
  )

  if (currentApplicationIndex < 0) return false

  const currentComponent = selectedPermissions.value[currentApplicationIndex].components.find(
    (item) => item.id === selectedComponent.value
  )

  if (!currentComponent) return false

  return !!currentComponent.views.find((view) => view.id === id)
}

const ifIncludeInDefaultPermissions = (id) =>
  !!props.defaultPermissions.find((item) => item.id === id)

const getFilteredComponents = (component) => {
  if (!ifIncludeInDefaultPermissions(component.id)) return false

  return {
    ...component,
    views: component.views
      .map((view) => (ifIncludeInDefaultPermissions(view.id) ? view : false))
      .filter(Boolean)
  }
}

const getFilteredApplication = (application) => {
  if (!ifIncludeInDefaultPermissions(application.id)) return false

  return {
    ...application,
    components: application.components
      .map((component) => getFilteredComponents(component))
      .filter((item) => item)
  }
}

watch(selectedApplication, () => {
  if (selectedApplication.value !== null) {
    components.value = getComponents.value
  } else {
    components.value = []
  }

  selectedComponent.value = null
})

watch(selectedComponent, () => {
  if (selectedComponent.value !== null) {
    views.value = getViews.value
  } else {
    views.value = []
  }
})

onMounted(() => {
  if (!props.defaultPermissions?.length) return

  selectedPermissions.value = props.permissions
    .map((application) => {
      return getFilteredApplication(application)
    })
    .filter((item) => item)
})
</script>

<style lang="scss" scoped>
.permissions {
  display: flex;
  justify-content: space-between;

  &__block {
    width: calc(33.3% - 10px);
  }

  &__block-title {
    margin-bottom: 5px;
  }

  &__item {
    border-top: solid 1px $light-border;
    border-left: solid 1px $light-border;
    border-right: solid 1px $light-border;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 5px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);

    &:last-child {
      border-bottom: solid 1px $light-border;
    }

    &--selected {
      background-color: $default-shadow-color;
    }
  }
}
</style>
