<template>
  <a-form
    :form="statusesForm"
    :style="{ marginTop: '50px' }"
  >
    <a-form-item :label="$t('orderStatus')">
      <a-select
        style="width: 100%"
        v-decorator="['status']"
      >
        <a-select-option
          v-for="status in orderStatuses"
          :key="status.value"
        >
          <div :style="{ color: status.color }">{{ status.title }}</div>
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      v-if="showRejectSelect"
      :label="$t('rejectReason')"
    >
      <a-select
        style="width: 100%"
        v-decorator="['reject_reason']"
      >
        <a-select-option
          v-for="reason in rejectReasons"
          :key="reason.id"
        >
          {{ reason.reason.uk }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item :label="$t('moderationStatus')">
      <a-select
        style="width: 100%"
        v-decorator="['moderation_status']"
      >
        <a-select-option
          v-for="status in orderModerationStatuses"
          :key="status.value"
        >
          <div :style="{ color: status.color }">{{ status.title }}</div>
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import { useStatuses } from "@/composables/useStatuses"

export default {
  name: "OrderStatuses",
  props: {
    rejectReasonsBuyer: {
      type: Array,
      default: () => []
    },
    rejectReasonsModerator: {
      type: Array,
      default: () => []
    },
    rejectReasonsSeller: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const { orderStatuses, paymentStatuses, orderModerationStatuses } = useStatuses()

    return {
      orderStatuses,
      paymentStatuses,
      orderModerationStatuses
    }
  },
  data() {
    return {
      rejectReasons: [],
      showRejectSelect: false,
      statusesForm: this.$form.createForm(this, {
        name: "statusesForm",
        onValuesChange: (props, values) => {
          if (values.status) {
            this.showRejectSelect = this.canceledStatuses.indexOf(values.status) !== -1

            this.setRejectReasons(values.status)
          }
        }
      }),
      canceledStatuses: ["CANCELED_BY_SELLER", "CANCELED_BY_CUSTOMER", "CANCELED_BY_SUPERVISOR"]
    }
  },
  methods: {
    setRejectReasons(status) {
      if (status === "CANCELED_BY_SELLER") {
        this.rejectReasons = this.rejectReasonsSeller
      } else if (status === "CANCELED_BY_SUPERVISOR") {
        this.rejectReasons = this.rejectReasonsModerator
      } else {
        this.rejectReasons = this.rejectReasonsBuyer
      }
    }
  }
}
</script>
