<template>
  <a-drawer
    width="80%"
    :body-style="{ paddingBottom: '80px' }"
    :visible="isOpened"
    :title="`${$t('sellerEditing')}: ${defaultSellerName || '...'} / Slug: ${
      defaultSellerSlug || '...'
    }`"
    @close="handleCloseModal"
  >
    <a-spin
      :spinning="fetching"
      :tip="`${$t('loading')}...`"
    >
      <div style="display: flex; justify-content: space-between">
        <a-tabs v-model="activeTab">
          <a-tab-pane key="general">
            <template #tab>
              <a-icon type="setting" />
              {{ $t("generalInformation") }}
            </template>
          </a-tab-pane>
          <a-tab-pane key="payments">
            <template #tab>
              <a-icon type="dollar" />
              {{ $t("sellerPayments") }}
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>

      <div v-if="isOpened">
        <SellerEditGeneralForm
          v-show="activeTab === 'general'"
          ref="generalForm"
          :default-customers="defaultCustomers"
          @fieldChange="handleChangeIsEdited(true)"
        />

        <SellerPaymentsForm
          v-show="activeTab === 'payments'"
          :seller-slug="defaultSellerSlug"
        />
      </div>
    </a-spin>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      }"
    >
      <a-button
        key="cancel"
        @click="handleCloseModal"
        :style="{ marginRight: '8px' }"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        key="save"
        type="primary"
        :loading="saveProcessing"
        @click="handleSave"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import getImagePath from "@/utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"
import { fetchSellerBySlug, updateSeller } from "@/modules/MPAdmin/services/sellersService"

import SellerEditGeneralForm from "./components/SellerEditGeneralForm.vue"
import SellerPaymentsForm from "./components/SellerPaymentsForm.vue"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  components: {
    SellerEditGeneralForm,
    SellerPaymentsForm
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    defaultSellerSlug: {
      type: String,
      default: ""
    },
    defaultSellerName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fetching: false,
      saveProcessing: false,
      notFound: false,
      isEdited: false,
      activeTab: "general",
      defaultCustomers: [],
      sellerOldData: {}
    }
  },
  watch: {
    isOpened(newValue) {
      if (newValue) {
        this.getSeller()
      }
    }
  },
  methods: {
    handleChangeIsEdited(value) {
      this.isEdited = value
    },
    handleCloseModal() {
      if (this.isEdited) {
        this.$confirm({
          title: this.$t("changesNotSaved"),
          content: this.$t("leavePage"),
          cancelText: this.$t("no"),
          okText: this.$t("yes"),
          onOk: () => {
            this.$emit("close")
          }
        })
      } else {
        this.$emit("close")
      }

      this.isEdited = false
    },

    async getSeller() {
      try {
        this.fetching = true

        const { data } = await fetchSellerBySlug(this.defaultSellerSlug)

        this.sellerOldData = data

        // Get only uniq customers
        this.defaultCustomers = [data.owner, ...data.users].filter((value, index, array) => {
          console.log(array, value)
          return array.findIndex((item) => item.uuid === value.uuid) === index
        })

        // Set default values for general form
        this.$refs.generalForm.form.setFieldsValue({
          name: data.name,
          owner: data.owner.uuid,
          users: data.users.map((item) => item.uuid),
          mobile_phone_public: data.mobile_phone_public,
          notify_emails: data.notify_emails,
          offers_visibility: data.offers_visibility,
          hidden_offers_message: data.hidden_offers_message || "",
          is_backoffice_available: data.is_backoffice_available,
          is_active: data.is_active,
          health: data.health,
          is_bonuses_available: data.is_bonuses_available,
          allow_synthetic_delivery_method: data.allow_synthetic_delivery_method,
          max_bonuses_percent: data.max_bonuses_percent
        })
        // Set default value for html editor
        this.$refs.generalForm.sellerDescription = data.description

        // Set default seller logo url
        this.$refs.generalForm.logoPreviewUrl = data.small_logo
          ? `${IMAGES_CDN}/media/assets/images/${getImagePath(data.small_logo, "full")}`
          : ""
      } catch {
        this.notFound = true
      } finally {
        this.fetching = false
      }
    },

    handleSave() {
      this.$refs.generalForm.form.validateFields(async (err, values) => {
        if (!err) {
          this.saveProcessing = true

          const formData = new FormData()

          formData.append("mobile_phone_public", values.mobile_phone_public)
          formData.append("offers_visibility", values.offers_visibility)
          formData.append("hidden_offers_message", values.hidden_offers_message)
          formData.append("is_backoffice_available", values.is_backoffice_available)
          formData.append("is_active", values.is_active)
          formData.append("health", values.health)
          formData.append("is_bonuses_available", values.is_bonuses_available)
          formData.append("allow_synthetic_delivery_method", values.allow_synthetic_delivery_method)
          formData.append("max_bonuses_percent", values.max_bonuses_percent)
          formData.append("max_bonuses_percent", values.max_bonuses_percent)
          formData.append("description", this.$refs.generalForm.sellerDescription)

          values.users.forEach((user) => {
            formData.append("users", user)
          })
          values.notify_emails.forEach((email) => {
            formData.append("notify_emails", email)
          })

          // If old owner !== new owner
          if (this.sellerOldData.owner.uuid !== values.owner) {
            formData.append("owner", values.owner)
          }

          // If old name !== new name
          if (this.sellerOldData.name !== values.name) {
            formData.append("name", values.name)
          }

          // If logo changed
          if (values.small_logo) {
            formData.append("small_logo", values.small_logo.file)
          }

          try {
            const { data } = await updateSeller({ slug: this.defaultSellerSlug, formData })
            this.sellerOldData = data
            this.handleChangeIsEdited(false)

            this.$notification.success({
              message: this.$t("sellerUpdatingSuccess")
            })
          } catch (error) {
            notifyResponseError({ error })
          } finally {
            this.saveProcessing = false
          }
        }
      })
    }
  }
}
</script>
