<template>
  <div class="email_column">
    <a-icon
      v-if="row.is_confirmed_email"
      type="check-circle"
      class="email_column--confirmed"
    />
    <a-button
      type="link"
      class="email_column-link"
      @click="handleCopyEmail()"
    >
      {{ row.email }}
    </a-button>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import { message } from "ant-design-vue"

const props = defineProps({
  row: {
    type: Object,
    required: true
  }
})

const handleCopyEmail = () => {
  navigator.clipboard.writeText(props.row.email)

  message.info(i18n.t("copied"))
}
</script>

<style lang="scss" scoped>
.email_column {
  display: flex;
  align-items: center;
  height: 100%;
  word-break: break-all;
  gap: 4px;

  &--confirmed {
    color: $green-color;
    display: inline-block;
  }
  &-link {
    padding: 0;
  }
}
</style>
