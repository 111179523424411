<template>
  <a-spin :spinning="fetching">
    <a-descriptions
      bordered
      :column="1"
      size="small"
    >
      <a-descriptions-item :label="$t('authorFullname')">
        <b>{{ review?.author?.username }}</b>
        {{ review?.author?.first_name }}
        {{ review?.author?.middle_name }}
        {{ review?.author?.last_name }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="review?.author?.mobile_phone"
        :label="$t('phoneNumber')"
      >
        <a-button
          type="link"
          style="padding: 0"
          @click="handleCopyText(review.author.mobile_phone)"
        >
          +{{ review.author.mobile_phone }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('date')">
        {{ createdAt }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('p_code')">
        <a-button
          type="link"
          icon="link"
          :href="productUrl"
          target="_blank"
          style="padding: 0"
        >
          {{ review?.p_code }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('grade')">{{ review?.rating }}</a-descriptions-item>
      <a-descriptions-item :label="$t('comment')">{{ review?.comment }}</a-descriptions-item>
      <a-descriptions-item :label="$t('advantages')">{{ review?.pros }}</a-descriptions-item>
      <a-descriptions-item :label="$t('flaws')">{{ review?.cons }}</a-descriptions-item>

      <a-descriptions-item :label="$t('photos')">
        <TableRendererPhotoGallery
          v-if="review?.images?.length"
          :images="review.images"
        />
      </a-descriptions-item>
    </a-descriptions>
  </a-spin>
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import i18n from "@/i18n"
import { message } from "ant-design-vue"
import { MARKETPLACE } from "@/constants/common"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { fetchProductReview } from "@/modules/MPAdmin/services/productReviewsService"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

const props = defineProps({
  parent: {
    type: Number,
    required: true
  }
})

const review = ref({})
const fetching = ref(false)

const createdAt = computed(() => {
  return getFormatedDateTime(review.value.created_at)
})

const productUrl = computed(() => {
  return `${MARKETPLACE}/product/${review.value.slug}/${review.value.p_code}/`
})

const handleCopyText = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}

onMounted(async () => {
  fetching.value = true

  const { data } = await fetchProductReview(props.parent)

  review.value = data
  fetching.value = false
})
</script>
