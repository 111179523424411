<template>
  <div>
    <div class="row">
      <div class="col">
        <Select
          @change="onSelectLang"
          placeholder="Выберите язык"
          :values="PC_languages"
          :width="58"
          :selected="PC_selectedLanguage"
          class="lang-select"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <h2>Справочник единиц измерения</h2>
        <h3>Поиск и сортировка всех существующих единиц измерения</h3>
        <UnitsTable />
        <CreateUnitModal />
      </div>
      <div
        class="col-sm-3"
        v-if="PU_selectedUnit"
      >
        <h2>Атрибуты</h2>
        <!--<h3>Использующие выбранную единицу измерения ("{{PU_selectedUnit.name}}") в качестве основной</h3>-->
        <h3>C единицей "{{ PU_selectedUnit.name }}" в качестве основной</h3>
        <AttributesTable />
      </div>
    </div>
  </div>
</template>
<script>
import CreateUnitModal from "./CreateUnitModal/CreateUnitModal"
import UnitsTable from "./UnitsTable/UnitsTable"
import Button from "components/Button"
import { mapActions, mapMutations, mapGetters } from "vuex"
import Select from "components/Select/Select"
import AttributesTable from "./AttributesTable/AttributesTable"

export default {
  metaInfo: {
    title: "MS: Units"
  },
  name: "ProductCatalogUnits",
  components: {
    AttributesTable,
    CreateUnitModal,
    UnitsTable,
    Button,
    Select
  },
  methods: {
    onSelectLang(value) {
      if (value === this.PC_selectedLanguage) return
      let page = "units"
      this.PC_selectLanguage({ value, page })
    },
    ...mapMutations("ProductCatalog", {
      PU_resetProductUnitsValues: "PU_resetProductUnitsValues"
    }),
    ...mapActions("ProductCatalog", {
      PC_selectLanguage: "PC_selectLanguage"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PC_languages: "PC_languages",
      PC_selectedLanguage: "PC_selectedLanguage",
      PU_selectedUnit: "PU_selectedUnit"
    })
  },
  beforeRouteLeave(to, from, next) {
    this.PU_resetProductUnitsValues()
    next()
  }
}
</script>
<style lang="scss"></style>
