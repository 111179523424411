import { computed } from "vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum.js"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("creatingDate"),
      width: 130,
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" ? sortedInfo?.value?.order : false,
      fixed: "left"
    },
    {
      key: "issued_at",
      dataIndex: "issued_at",
      title: i18n.t("dateInPdf"),
      width: 130,
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "issued_at" && sortedInfo?.value?.order,
      fixed: "left"
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("invoiceNumberWithSymbol"),
      width: 130,
      fixed: "left"
    },

    {
      key: "seller",
      dataIndex: "seller",
      title: i18n.t("seller"),
      width: 150
    },
    {
      key: "payer_factual",
      title: `${i18n.t("payerPDF")} / ${i18n.t("payerFactual")}`,
      width: 240,
      scopedSlots: {
        customRender: "payerRenderer"
      }
    },
    {
      key: "region_name",
      title: i18n.t("invoiceDetails"),
      width: 260,
      scopedSlots: {
        customRender: "invoiceDetails"
      }
    },
    {
      key: "amount_with_vat",
      dataIndex: "amount_with_vat",
      title: i18n.t("sumWithVat"),
      customRender: (sum) => getFormatedSum(sum),
      width: 110
    },
    {
      key: "amount_without_vat",
      dataIndex: "amount_without_vat",
      title: i18n.t("sumWithoutVat"),
      customRender: (sum) => getFormatedSum(sum),
      width: 110
    },
    {
      key: "vat",
      dataIndex: "vat",
      title: i18n.t("vat"),
      customRender: (sum) => getFormatedSum(sum),
      width: 110
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("invoiceStatus"),
      scopedSlots: { customRender: "statusRenderer" },
      width: 120
    },
    {
      key: "expire_at",
      dataIndex: "expire_at",
      title: i18n.t("invoiceDateValidUntil"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 130,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "expire_at" && sortedInfo?.value?.order
    },
    {
      key: "paid_at",
      dataIndex: "paid_at",
      title: i18n.t("paymentDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 130,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "paid_at" && sortedInfo?.value?.order
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: i18n.t("managerComment"),
      scopedSlots: {
        customRender: "commentRender"
      }
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRender"
      },
      fixed: "right"
    }
  ])

  return {
    columns
  }
}
