import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_connectionViewModal: false,
  PC_parsedProductData: false,
  PC_parsedProductModalMode: false,
  PC_selectedConnection: false
})

const getters = {
  PC_connectionViewModal: (state) => state.PC_connectionViewModal,
  PC_parsedProductData: (state) => state.PC_parsedProductData,
  PC_productSourceData: (state) => {
    return state.PC_parsedProductData.data
  },
  PC_parsedProductModalMode: (state) => state.PC_parsedProductModalMode,
  PC_selectedConnection: (state) => state.PC_selectedConnection
}

const mutations = {
  PC_connectionViewModal(state, value) {
    state.PC_connectionViewModal = value
  },
  PC_parsedProductData(state, value) {
    state.PC_parsedProductData = value
  },
  PC_parsedProductModalMode(state, value) {
    state.PC_parsedProductModalMode = value
  },
  PC_selectedConnection(state, value) {
    state.PC_selectedConnection = value
  }
}

const actions = {
  async PC_checkProductInSource({ commit, dispatch }) {
    try {
      commit("PC_showLoader", true)
      await dispatch("PC_resetConnectionViewValues")
      await dispatch("PC_checkProductInSourceRequest")
      commit("PC_parsedProductModalMode", "source")
      commit("PC_connectionViewModal", true)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_checkProductInSourceRequest({ commit, getters }) {
    let params = {
      parser_type: getters.PC_selectedConnectorsSource.parser_type,
      url: getters.PC_urlToCheckInSource
    }

    return API_MODERATION.get(`${apiUrls.connectorsParser}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        commit("PC_parsedProductData", r)
        return r
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          notification.error({ message: "Ничего не найдено" })
        } else {
          commit("handleError", e)
        }
        return Promise.reject()
      })
  },
  async PC_viewConnectionProduct({ commit, getters, dispatch }, connection) {
    try {
      commit("PC_showLoader", true)
      commit("PC_selectedConnection", connection)
      await dispatch("PC_resetConnectionViewValues")
      // fetchData
      await dispatch("PC_getConnectionProductFullData", connection)
      commit("PC_parsedProductModalMode", "saved")
      commit("PC_connectionViewModal", true)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },

  async PC_getConnectionProductFullData({ commit }, connection) {
    let params = {
      parser_type: connection.connector.parser_type,
      parser_id: connection.parser_id
    }
    return API_MODERATION.get(`${apiUrls.connectorsParser}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        commit("PC_parsedProductData", r)
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          notification.error({ message: "Ничего не найдено" })
        } else {
          commit("handleError", e)
        }
        return Promise.reject()
      })
  },
  async PC_approveConnection({ commit, getters, dispatch }) {
    try {
      commit("PC_showLoader", true)
      let newConnection = await dispatch("PC_approveConnectionRequest")
      commit("PC_productConnectionsPush", newConnection)
      commit("PC_connectionViewModal", false)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_approveConnectionRequest({ commit, getters }) {
    commit("PC_showLoader", true)
    let sendData = {
      parser_id: getters.PC_parsedProductData.parser_id,
      parser_type: getters.PC_selectedConnectorsSource.parser_type,
      product_code: getters.PC_EditableProduct.code,
      name: getters.PC_parsedProductData.data.name,
      url: getters.PC_urlToCheckInSource,
      images: getters.PC_parsedProductData.data.images
    }
    return API_MODERATION.post(`${apiUrls.connections}`, sendData)
      .then((r) => r.data.message.connection)
      .then((r) => {
        return r
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  async PC_removeConnection({ commit, getters, dispatch }) {
    try {
      commit("PC_showLoader", true)
      await dispatch("PC_removeConnectionRequest")
      commit("PC_connectionViewModal", false)
      await dispatch("PC_resetConnectionViewValues")
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_removeConnectionRequest({ commit, getters }) {
    let params = {
      parser_id: getters.PC_parsedProductData.parser_id
    }
    return API_MODERATION.delete(`${apiUrls.connectorsParser}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        return r
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  async PC_resetConnectionViewValues({ commit }) {
    commit("PC_parsedProductData", false)
    commit("PC_parsedProductModalMode", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
