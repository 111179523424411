import { computed } from "vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo } = {}) => {
  const billingListColumns = computed(() => [
    {
      key: "seller",
      title: i18n.t("seller"),
      width: 260,
      scopedSlots: {
        customRender: "sellerRenderer"
      },
      fixed: "left"
    },
    {
      key: "orders_count",
      title: i18n.t("ordersCount"),
      width: 120,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "orders_count" && sortedInfo?.value?.order,
      scopedSlots: {
        customRender: "ordersCell"
      }
    },
    {
      key: "amount_free_orders",
      title: i18n.t("amountFreeOrders"),
      width: 150,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "amount_free_orders" && sortedInfo?.value?.order,
      scopedSlots: {
        customRender: "avaliableOrdersCell"
      }
    },
    {
      key: "bill_note",
      title: i18n.t("note"),
      scopedSlots: {
        customRender: "editNoteRender"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "bill_note" && sortedInfo?.value?.order,
      width: 400
    },
    {
      key: "tariff_expire_at",
      title: i18n.t("tariffPlan"),
      scopedSlots: {
        customRender: "tariffExpiresRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "tariff_expire_at" && sortedInfo?.value?.order,
      width: 280
    },
    {
      key: "last_operation_processed_at",
      title: i18n.t("lastOperationInfo"),
      width: 130,
      scopedSlots: {
        customRender: "lastOperation"
      },
      sorter: true,
      sortOrder:
        sortedInfo?.value?.columnKey === "last_operation_processed_at" && sortedInfo?.value?.order
    },
    {
      key: "bill_balance",
      dataIndex: "bill.balance",
      title: i18n.t("currentBalance"),
      width: 150,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "bill_balance" && sortedInfo?.value?.order,
      scopedSlots: {
        customRender: "balanceRenderer"
      }
    },
    {
      key: "is_active",
      title: i18n.t("status"),
      width: 120,
      scopedSlots: {
        customRender: "statusCell"
      }
    },
    {
      key: "offers_visibility",
      dataIndex: "offers_visibility",
      title: i18n.t("offersVisibilityTitle"),
      width: 105
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      fixed: "right",
      width: 105
    }
  ])

  const billingHistoryColumns = computed(() => [
    {
      key: "processed_at",
      dataIndex: "processed_at",
      title: i18n.t("date"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 160,
      fixed: "left"
    },
    {
      key: "type",
      dataIndex: "type",
      title: i18n.t("operationType"),
      scopedSlots: {
        customRender: "operation"
      },
      width: 250
    },
    {
      key: "description",
      dataIndex: "description",
      scopedSlots: {
        customRender: "description"
      },
      width: 220
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: i18n.t("operationSum"),
      scopedSlots: {
        customRender: "amount"
      },
      width: 200
    },
    {
      key: "bill_snapshot",
      dataIndex: "bill_snapshot",
      title: i18n.t("saldo"),
      customRender: (sum) => getFormatedSum(sum),
      width: 200
    }
  ])

  return {
    billingListColumns,
    billingHistoryColumns
  }
}
