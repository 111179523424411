import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  PA_searchedValuesOfAttribute: false,
  PA_searchedValuesTable: { count: 0, items: [] },
  PA_searchedValuesQueryParams: { offset: 0, limit: 50 }
})
const getters = {
  PA_searchedValuesOfAttribute: (state) => state.PA_searchedValuesOfAttribute,
  PA_searchedValuesTable: (state) => state.PA_searchedValuesTable,
  PA_searchedValuesQueryParams: (state) => state.PA_searchedValuesQueryParams
}

const mutations = {
  PA_searchedValuesOfAttribute(state, value) {
    state.PA_searchedValuesOfAttribute = value
  },
  PA_searchedValuesTable(state, value) {
    state.PA_searchedValuesTable = value
  },
  PA_searchedValuesQueryParams(state, value) {
    state.PA_searchedValuesQueryParams = value
  },
  PA_resetValuesOfAttributeValues(state) {
    state.PA_searchedValuesOfAttribute = false
    state.PA_searchedValuesTable = { count: 0, items: [] }
    state.PA_searchedValuesQueryParams = { offset: 0, limit: 50 }
  }
}

const actions = {
  async PA_searchValues({ commit, dispatch }, attribute) {
    try {
      // show loader
      commit("PC_showLoader", true)
      // reset values
      // send request
      await dispatch("PA_searchValuesOfAttribute", attribute.code)
      // build table data
      dispatch("PA_buildSearchedValuesTable")
    } catch (e) {
      console.log(e)
    } finally {
      // hide loader
      commit("PC_showLoader", false)
    }
  },
  async PA_searchValuesOfAttribute({ state, commit }, attributeCode) {
    let sendData = buildMagicSearch({
      resource: "VLD",
      field: "attributes",
      value: attributeCode,
      sort: null,
      ...state.PA_searchedValuesQueryParams
    })

    return API_MODERATION.post(`${apiUrls.searchMagic}`, sendData)
      .then((r) => {
        commit("PA_searchedValuesTable", {
          count: r.data.message.count,
          items: r.data.message.data
        })
        return r.data.message.data
      })
      .then((values) => {
        commit("PA_searchedValuesOfAttribute", values)
        return values
      })
      .catch((e) => {
        commit("handleError", e)
        return Promise.reject()
      })
  },
  PA_buildSearchedValuesTable({ commit, getters }) {
    let values = getters.PA_searchedValuesOfAttribute
    let newValues = []
    for (let i = 0; i < values.length; i++) {
      let code = values[i].code
      let description = values[i].description
      let value = values[i].value
      let attribute = values[i].attribute
      newValues.push({ code, description, value, attribute })
    }
    // if (newValues.length) {
    //   commit('PA_searchedValuesTable', { count: newValues.length, items: newValues });
    // }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
