<template>
  <div class="range-filter">
    <a-page-header
      style="padding: 16px 0; margin: 30px 0 48px 0"
      :title="$t('editingCustomRangeFilter')"
      @back="goToCategory($route.params.id)"
    >
      <a-breadcrumb>
        <a-breadcrumb-item
          class="category-breadcrumb"
          v-for="item in breadcrumbs"
          :key="`crumb-${item.value}`"
        >
          <span @click="onBreadcrumbClick(item.role)">
            {{ item.value }}
          </span>
        </a-breadcrumb-item>
      </a-breadcrumb>

      <template #extra>
        <a-button
          :disabled="disableButtons"
          @click="goToCategory($route.params.id)"
        >
          {{ $t("reject") }}
        </a-button>

        <a-button
          type="primary"
          :disabled="disableButtons && !isIndexing"
          :loading="isIndexing"
          @click="handleReindex($route.params.id)"
        >
          {{ $t("reindex") }}
        </a-button>

        <a-button
          type="primary"
          :disabled="disableButtons && !onSaving"
          :loading="onSaving"
          @click="handleSave"
        >
          {{ $t("save") }}
        </a-button>
      </template>
    </a-page-header>

    <!-- ADD NEW RANGE -->
    <a-form
      :form="addFilterForm"
      layout="vertical"
      class="add-filter"
    >
      <template v-for="lang in languages">
        <a-form-item
          :label="`${$t('name')} ${lang.label}`"
          :required="false"
        >
          <a-input v-decorator="[lang.value, { rules: requiredRule }]" />
        </a-form-item>
      </template>

      <a-form-item
        :label="$t('filterCondition')"
        :required="false"
      >
        <a-select
          v-decorator="[
            'lookup',
            { rules: [{ required: true, message: $t('emptyError') }], initialValue: 'range' }
          ]"
          :options="conditionValues"
          @change="(e) => (lookup = e)"
        />
      </a-form-item>

      <a-form-item
        v-show="lookup === 'range' || lookup.startsWith('gt')"
        :label="$t('from')"
        class="add-filter--thin"
      >
        <a-input-number
          style="width: 100%"
          :min="0"
          v-decorator="['value_from', { initialValue: 0 }]"
        />
      </a-form-item>

      <a-form-item
        v-show="lookup === 'range' || lookup.startsWith('lt')"
        :label="$t('to')"
        class="add-filter--thin"
      >
        <a-input-number
          style="width: 100%"
          :min="0"
          v-decorator="['value_to', { initialValue: 0 }]"
        />
      </a-form-item>

      <a-form-item style="width: 200px">
        <a-button
          type="primary"
          icon="plus"
          :disabled="onSaving || !filterBaseUnit"
          style="margin-top: 29px"
          @click="handleAddNewRange"
        >
          {{ $t("add") }} {{ $t("range").toLowerCase() }}
        </a-button>

        <a-popover v-if="!filterBaseUnit">
          <template #content>
            <div style="max-width: 400px">
              {{ $t("baseUnitError") }}
            </div>
          </template>
          <a-icon
            type="info-circle"
            theme="twoTone"
            twoToneColor="red"
            style="margin-left: 8px"
          />
        </a-popover>
      </a-form-item>
    </a-form>

    <!-- TABLE -->
    <div class="range-filter__table">
      <a-table
        :dataSource="dataSource"
        :components="components"
        :columns="columns"
        rowKey="uuid"
        :pagination="false"
        :scroll="{ x: true }"
        :loading="fetching || disableButtons"
      >
        <template #handleRenderer>
          <div class="handle">
            <a-icon type="menu" />
          </div>
        </template>

        <template
          v-for="columnKey of languages"
          #[columnKey.value]="record, _, index"
        >
          <TableEditableCellInput
            :text="record.title[columnKey?.value]"
            :key="columnKey.value + record.title[columnKey?.value] + index"
            :class="{
              'red-text':
                checkIsTitleDuplicate(columnKey.value, record.title[columnKey.value]) ||
                !record.title[columnKey.value].trim()
            }"
            @change="
              (value, closeEdit) => handleEditTitleCell(columnKey.value, value, index, closeEdit)
            "
          />
        </template>

        <template #conditionRender="record, _, index">
          <a-select
            :options="conditionValues"
            style="width: 100%"
            :value="record.lookup"
            @change="(e) => handleEditCell('lookup', e, index)"
          />
        </template>

        <template #valueTitle>
          <div style="display: flex; align-items: center">
            <div>
              {{ $t("valueInTheBaseUnit") }}
            </div>
            <a-tooltip :overlayStyle="{ maxWidth: 'max-content' }">
              <template #title>
                <a-spin :spinning="unitFetching">
                  <div>
                    {{
                      $t("baseValueInAttribute", {
                        name: breadcrumbs[breadcrumbs.length - 1]?.value || ""
                      })
                    }}
                  </div>
                  <div>
                    {{ baseUnitText }}
                    <a-button
                      v-if="!filterBaseUnit"
                      type="link"
                      size="small"
                      style="padding: 0"
                      @click="getFilterBaseUnit(true)"
                    >
                      {{ $t("getBaseUnit") }}
                    </a-button>
                  </div>
                </a-spin>
              </template>
              <a-icon
                type="info-circle"
                theme="twoTone"
              />
            </a-tooltip>
          </div>
        </template>

        <template #baseUnitRender="record, _, index">
          <div
            class="base-unit-cell"
            style="position: relative"
          >
            <div
              v-show="record.lookup === 'range' || record.lookup.startsWith('gt')"
              class="custom-range-input"
              :class="{
                'red-text': record.value_to && record.value_from > record.value_to
              }"
            >
              <a-input-number
                :value="record.value_from"
                :min="0"
                @change="(e) => handleEditCell('value_from', e, index)"
              />
              <label class="custom-range-label">{{ $t("from") }}</label>
            </div>
            <div
              v-show="record.lookup === 'range' || record.lookup.startsWith('lt')"
              class="custom-range-input"
              :class="{
                'red-text': record.value_to && record.value_from > record.value_to
              }"
            >
              <a-input-number
                :value="record.value_to"
                :min="0"
                @change="(e) => handleEditCell('value_to', e, index, record)"
              />
              <label class="custom-range-label">{{ $t("to") }}</label>
            </div>
          </div>
        </template>

        <template #offersInRangeRender="text">
          {{ text ? text : $t("noDataShorted") }}
        </template>

        <template #actions="record, _, index">
          <a-popconfirm
            :title="$t('deletingConfirmation')"
            @confirm="handleDeleteRange(index)"
          >
            <a-button
              type="dashed"
              shape="circle"
            >
              <a-icon type="delete" />
            </a-button>
          </a-popconfirm>
        </template>
      </a-table>

      <!-- CALCULATE FILTER -->
      <div class="total-missing">
        {{ $t("notInAllFilters") }}:
        <span
          class="total-missing__counter"
          :class="{ 'red-text': totalMissingCount }"
        >
          {{ totalMissingCount !== undefined ? totalMissingCount : $t("noDataShorted") }}
        </span>
        <a-tooltip :title="$t('calculateCount')">
          <a-button
            style="margin-left: 86px"
            type="dashed"
            shape="circle"
            @click="handleCalculateFilterOffers()"
          >
            <a-icon type="undo" />
          </a-button>
        </a-tooltip>

        <a-tooltip :title="$t('downloadFileWithOffers')">
          <a-button
            type="dashed"
            shape="circle"
            :disabled="totalMissingCount === undefined || totalMissingCount === 0"
            @click="downloadAllFilterOffers()"
          >
            <a-icon type="download" />
          </a-button>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper"
import TableEditableCellInput from "@/ant-components/TableEditableCellInput"

import getCurrentLocale from "@/utils/getCurrentLocale.js"

import {
  calculateCustomRangeOffers,
  fetchCategoryById,
  fetchCustomRangeFilterBaseUnit,
  fetchFilterById,
  fetchFilterTaskReport,
  fetchOffersMissingFilter,
  reindexCategory,
  updateFilterById
} from "@/modules/MPAdmin/services/categoriesService.js"

import useCategoriesPageColumns from "../useCategoriesPageColumns.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  name: "EditFilterCustomRanges",

  metaInfo: {
    title: "Редагування діапазону фільтра"
  },

  components: {
    TableEditableCellInput
  },

  provide() {
    const appData = {}
    Object.defineProperty(appData, "dataSource", {
      get: () => this.dataSource
    })

    return {
      appData
    }
  },

  setup() {
    const { customRangesColumns: columns } = useCategoriesPageColumns()

    return {
      columns
    }
  },

  data() {
    return {
      components: {
        body: {
          wrapper: TableGraggableWrapper
        }
      },

      addFilterForm: this.$form.createForm(this, { name: "addFilterForm" }),

      fetching: false,
      onSaving: false,
      isIndexing: false,
      unitFetching: false,

      dataSource: [],
      categoryData: {},
      filterData: {},
      filterBaseUnit: undefined,

      breadcrumbs: [],
      lookup: "range",
      filtersIsChanged: false,
      totalMissingCount: undefined,

      languages: [
        { label: "UA", value: "uk" },
        { label: "EN", value: "en" },
        { label: "RU", value: "ru" }
      ]
    }
  },

  watch: {
    dataSource() {
      this.filtersIsChanged = true
    }
  },

  async mounted() {
    this.fetching = true
    this.breadcrumbs = []
    this.$message.config({ maxCount: 1 })

    const { data: categoryData } = await fetchCategoryById(this.$route.params.id)
    this.categoryData = categoryData

    this.breadcrumbs.unshift({ value: this.categoryData.name[getCurrentLocale()], role: "child" })

    if (this.categoryData.parent) {
      this.breadcrumbs.unshift({
        value: this.categoryData.parent.name[getCurrentLocale()],
        role: "parent"
      })
    }

    await fetchFilterById(this.$route.params.id, this.$route.params.filterId)
      .then(({ data: filterData }) => {
        this.filterData = filterData

        if (filterData?.values) {
          this.dataSource = filterData.values
        }

        this.breadcrumbs.push({
          value: filterData.name[getCurrentLocale()],
          role: "filter"
        })
      })
      .finally(() => {
        this.fetching = false
        this.filtersIsChanged = false
      })

    this.getFilterBaseUnit()

    this.getRangesCounts()
  },

  computed: {
    conditionValues() {
      return [
        {
          label: "\u003c",
          value: "lt"
        },
        {
          label: "\u2264",
          value: "lte"
        },
        {
          label: "\u003e",
          value: "gt"
        },
        {
          label: "\u2265",
          value: "gte"
        },

        {
          label: this.$t("range"),
          value: "range"
        }
      ]
    },

    requiredRule() {
      return [{ required: true, message: this.$t("emptyError") }]
    },

    baseUnitText() {
      return this.filterBaseUnit
        ? `${this.filterBaseUnit.base_name}, ${this.$t("coefficient").toLowerCase()} ${
            this.filterBaseUnit.coef
          }`
        : `${this.$t("noDataShorted")}.`
    },

    disableButtons() {
      return this.isIndexing || this.onSaving
    }
  },

  methods: {
    goToCategory(id, generalPage = false) {
      if (this.filtersIsChanged) {
        this.$confirm({
          title: this.$t("changesNotSaved"),
          content: this.$t("leavePage"),
          cancelText: this.$t("no"),
          okText: this.$t("yes"),
          onOk: () => {
            this.$router.push({
              name: "EditCategory",
              params: { id: id },
              query: { tab: generalPage ? undefined : "filters" }
            })
          }
        })
      } else {
        this.$router.push({
          name: "EditCategory",
          params: { id: id },
          query: { tab: generalPage ? undefined : "filters" }
        })
      }
    },

    async handleReindex(id) {
      if (this.filtersIsChanged) {
        this.$message.error(this.$t("changesNotSaved"))
      } else {
        this.isIndexing = true

        try {
          await reindexCategory(id)

          this.getRangesCounts()
          this.$message.success(this.$t("reindexSuccess"))
        } catch {
          this.$message.error(this.$t("reindexFailure"))
        } finally {
          this.isIndexing = false
        }
      }
    },

    onBreadcrumbClick(role) {
      if (role === "parent") {
        this.goToCategory(this.categoryData.parent.id, true)
      } else if (role === "child") {
        this.goToCategory(this.$route.params.id)
      }
    },

    handleEditTitleCell(key, value, rowIndex, closeEdit) {
      closeEdit()

      this.dataSource[rowIndex].title = { ...this.dataSource[rowIndex].title, [key]: value }
    },

    handleEditCell(key, value, rowIndex) {
      const row = this.dataSource[rowIndex]

      if (row) {
        if (key.startsWith("value")) {
          row[key] = value || 0
        } else if (key === "lookup") {
          row.lookup = value
          row.value_from = 0
          row.value_to = 0
        } else {
          row[key] = value
        }
      }
    },

    async handleCalculateFilterOffers() {
      try {
        const response = await fetchOffersMissingFilter(
          this.$route.params.id,
          this.$route.params.filterId
        )
        this.totalMissingCount = response.data.count

        this.$message.success(this.$t("calculatingIsSuccess"))
      } catch {
        this.$message.error(this.$t("calculatingFailed"))
      }
    },

    downloadAllFilterOffers() {
      this.$message.info(this.$t("downloadCategoryReportWillStart"))
      fetchFilterTaskReport(this.categoryData, this.filterData)
    },

    handleDeleteRange(rowIndex) {
      this.dataSource.splice(rowIndex, 1)
    },

    handleAddNewRange() {
      this.addFilterForm.validateFields((err, values) => {
        if (err) return
        const { lookup, value_from, value_to, ...title } = values
        if (lookup === "range" && value_from > value_to) {
          this.addFilterForm.setFields({
            value_from: { value: value_from, errors: [] },
            value_to: { value: value_to, errors: [] }
          })
          return
        }

        const newFilter = {
          title: title,
          lookup: lookup,
          value_from: lookup === "range" || lookup.startsWith("gt") ? value_from : null,
          value_to: lookup === "range" || lookup.startsWith("lt") ? value_to : null
        }

        this.dataSource = [...this.dataSource, newFilter]

        this.addFilterForm.resetFields()

        this.addFilterForm.setFieldsValue({
          lookup: lookup
        })
      })
    },

    async handleSave() {
      try {
        this.onSaving = true

        if (this.hasIncorrectBaseValues(this.dataSource)) {
          this.$message.error(this.$t("rangeValuesIncorrect"))
          return
        }

        if (this.hasEmptyTitles(this.dataSource)) {
          this.$message.error(this.$t("rangeValuesIncorrect"))
          return
        }

        if (this.hasDuplicateTitles(this.dataSource)) {
          this.$message.error(this.$t("rangeNamesDuplicateError"))
          return
        }

        const { group_code, group_title, ...filter } = this.filterData
        await updateFilterById(
          { ...filter, values: this.dataSource },
          this.$route.params.id,
          this.$route.params.filterId
        )

        this.$notification.success({ message: this.$t("updated") })
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.onSaving = false
        this.filtersIsChanged = false
      }
    },

    hasIncorrectBaseValues(dataSource) {
      return dataSource.some((row) => row.value_to && row.value_from > row.value_to)
    },

    hasEmptyTitles(dataSource) {
      return dataSource.some((row) => Object.values(row.title).some((item) => item.trim() === ""))
    },

    hasDuplicateTitles(dataSource) {
      const ukTitles = new Set()
      const enTitles = new Set()
      const ruTitles = new Set()

      for (const row of dataSource) {
        ukTitles.add(row.title?.uk)
        enTitles.add(row.title?.en)
        ruTitles.add(row.title?.ru)
      }

      return (
        ukTitles.size !== dataSource.length ||
        enTitles.size !== dataSource.length ||
        ruTitles.size !== dataSource.length
      )
    },

    checkIsTitleDuplicate(key, value) {
      const encounteredValues = new Set()

      for (const row of this.dataSource) {
        const currentValue = row.title?.[key]
        if (currentValue === value) {
          if (encounteredValues.has(value)) {
            return true
          }
          encounteredValues.add(value)
        }
      }

      return false
    },

    async getRangesCounts() {
      calculateCustomRangeOffers(this.$route.params.id, this.$route.params.filterId)
        .then(({ data }) => {
          this.dataSource = this.dataSource.map((item) => ({
            ...item,
            count: data.find(({ value_uuid }) => value_uuid === item.uuid)?.count || null
          }))
        })
        .catch((e) => {
          this.filtersIsChanged = false
        })
        .finally(() => {
          this.filtersIsChanged = false
        })
    },

    async getFilterBaseUnit(showMessage = false) {
      this.unitFetching = true

      try {
        const { data } = await fetchCustomRangeFilterBaseUnit(this.filterData.name.ru)

        this.filterBaseUnit = data?.message?.data[0]?.default_unit
          ? data?.message?.data[0]?.default_unit[0]
          : undefined

        if (showMessage && data?.message?.data[0]?.default_unit) {
          this.$message.success(this.$t("baseUnitFetched"))
        } else if (showMessage) {
          this.$message.error(this.$t("baseUnitFetchError"))
        }
      } catch {
        this.$message.error(this.$t("baseUnitFetchError"))

        this.filterBaseUnit = undefined
      } finally {
        this.unitFetching = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$red-color: #ff4d4f;
.category-breadcrumb {
  cursor: pointer;
  &:last-child {
    cursor: default;
  }
}
.range-filter {
  max-width: 1570px;
  margin: 0 auto;
  &__table {
    max-width: 1200px;
    margin: 0 auto;
    & tr:hover .custom-range-label {
      background-color: #e6f7ff;
    }
  }
}
.base-unit-cell {
  display: flex;
  justify-content: space-between;
  line-height: 100%;
  & > .custom-range-input {
    position: relative;
    width: 48%;
    & > div {
      width: 100%;
    }

    & > .custom-range-label {
      position: absolute;
      top: -10px;
      left: 8px;
      border-radius: 2px;
      padding: 2px;
      background: $background-color;
    }
  }
}
.total-missing {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 16px 56px;
  gap: 8px;
  color: $grey-font;
  &__counter {
    min-width: 50px;
    color: $font-default;
    text-align: right;
  }
}
.add-filter {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 16px;
  & > * {
    width: 15%;
    font-weight: 500;
  }

  &--thin {
    width: 7.5%;
  }
}
.red-text {
  color: $red-color;
  border-color: $red-color;
  accent-color: $red-color;
  & * {
    color: $red-color;
    border-color: $red-color;
    accent-color: $red-color;
  }
}

.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
</style>
