<template>
  <a-form
    :form="returnReasonsForm"
    style="margin-bottom: 40px"
  >
    <a-form-item :label="`${$t('name')}, RU`">
      <a-input v-decorator="['name.ru', { rules: nameRules }]" />
    </a-form-item>

    <a-form-item :label="`${$t('name')}, EN`">
      <a-input v-decorator="['name.en', { rules: nameRules }]" />
    </a-form-item>

    <a-form-item :label="`${$t('name')}, UK`">
      <a-input v-decorator="['name.uk', { rules: nameRules }]" />
    </a-form-item>

    <a-form-item :label="$t('outputSortOrder')">
      <a-input-number
        v-decorator="['ordering']"
        :min="0"
        :max="999999"
        :precision="0"
      />
    </a-form-item>
    <a-form-item :label="$t('help')">
      <a-textarea
        v-decorator="['help', { rules: helpRules }]"
        :placeholder="$t('hint')"
      />
    </a-form-item>
    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import {
  createReturnReason,
  updateReturnReason
} from "@/modules/MPAdmin/services/generalSettingService/returnReasonsService"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateReturnReasonModal",

  props: {
    prototype: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      returnReasonsForm: this.$form.createForm(this, {
        name: "returnReasonsForm",
        mapPropsToFields: () => {
          return {
            "name.uk": this.$form.createFormField({
              value: this.prototype?.name?.uk || ""
            }),
            "name.en": this.$form.createFormField({
              value: this.prototype?.name?.en || ""
            }),
            "name.ru": this.$form.createFormField({
              value: this.prototype?.name?.ru || ""
            }),
            ordering: this.$form.createFormField({
              value: this.prototype?.ordering || 0
            }),
            help: this.$form.createFormField({
              value: this.prototype?.help || ""
            })
          }
        }
      }),
      isFetching: false
    }
  },
  methods: {
    async onSubmit() {
      this.returnReasonsForm.validateFields(async (err, values) => {
        if (err) return

        this.isFetching = true

        try {
          if (this.prototype.uuid) {
            const { data } = await updateReturnReason(values, this.prototype.uuid)
            this.$emit("updateReason", data)
          } else {
            await createReturnReason(values)
            this.$emit("createReason")
          }
          this.$notification.success({
            message: this.$t(this.prototype.uuid ? "updated" : "created")
          })
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.isFetching = false
        }
      })
    }
  },
  computed: {
    nameRules() {
      return [
        { required: true, message: this.$t("emptyError") },
        { min: 2, message: this.$t("minLengthError", { length: 2 }) },
        { max: 250, message: this.$t("minLengthError", { length: 250 }) }
      ]
    },

    helpRules() {
      return [{ max: 250, message: this.$t("minLengthError", { length: 250 }) }]
    }
  }
}
</script>
