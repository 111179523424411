<template>
  <div class="bonuses-history-table">
    <a-collapse
      :bordered="false"
      style="background-color: transparent"
    >
      <a-collapse-panel
        :addPadding="false"
        :reloadOnOpen="true"
        style="border: none"
        forceRender
      >
        <template #header>
          <span style="font-size: 20px">{{ $t("adminTransactionHistory") }}</span>
        </template>

        <PageHeader title="">
          <template #filters>
            <a-range-picker
              v-model="filteredInfo.created_at"
              valueFormat="YYYY-MM-DD"
              allowClear
              @change="handleTableFilterChange()"
            />

            <a-select
              v-model="filteredInfo.transaction_type"
              :options="transactionTypes"
              :placeholder="$t('purpose')"
              allowClear
              @select="handleTableFilterChange()"
            />

            <a-input-search
              v-model="filteredInfo.receiver_data"
              :placeholder="$t('recipient')"
              allowClear
              @search="handleTableFilterChange()"
            >
              <template #enterButton>
                <a-button icon="search" />
              </template>
            </a-input-search>

            <a-input-search
              v-model="filteredInfo.comment_sender"
              :placeholder="$t('comment')"
              allowClear
              @search="handleTableFilterChange()"
            >
              <template #enterButton>
                <a-button icon="search" />
              </template>
            </a-input-search>
          </template>

          <template #actions>
            <a-button @click="handleTableFiltersReset()">
              <a-icon type="undo" />
              {{ $t("resetFilters") }}
            </a-button>
          </template>
        </PageHeader>

        <a-table
          :dataSource="dataSource"
          :columns="columns"
          :loading="isFetching"
          :pagination="paginationInfo"
          rowKey="created_at"
          :scroll="{ x: true }"
          @change="onTableChange"
        >
          <template #dateRenderer="value">
            <TableRendererDateTime :date="value" />
          </template>

          <template #transactionRender="text">{{ getTransactionLabel(text) }}</template>

          <template #sumRender="{ transaction_type, amount }">
            <div :class="[isSumNegative(transaction_type) ? 'negative-sum' : 'positive-sum']">
              {{ getFormatedSum(amount, true) }}
            </div>
          </template>

          <template #recipientRender="{ first_name, last_name, middle_name, mobile_phone, uuid }">
            <div class="recipient">
              <div v-if="first_name">
                <label>{{ $t("firstName") }}:</label> {{ first_name }} {{ middle_name }}
                {{ last_name }}
              </div>
              <div v-if="mobile_phone">
                <label>{{ $t("phoneNumber") }}:</label> {{ mobile_phone }}
              </div>
              <div v-if="uuid"><label>uuid:</label> {{ uuid }}</div>
            </div>
          </template>
        </a-table>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/"

import getFormatedSum from "@/utils/getFormatedSum.js"

import { getTransactions } from "@/modules/MPAdmin/services/bonusesService.js"
import useMPAConstants from "@/modules/MPAdmin/constants.js"

import useBonusesPageColumns from "./useBonusesPageColumns.js"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: getTransactions,
  requestParams: { changeRouterQuery: true }
})
const { transactionTypes, negativeOperationTypes } = useMPAConstants()
const { transactionsHistoryColumns: columns } = useBonusesPageColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const getTransactionLabel = (value) => {
  return transactionTypes.value.find((item) => item.value === value).label
}

const isSumNegative = (type) => {
  return negativeOperationTypes.includes(type)
}

onMounted(() => {
  setupTable()
  onTableChange()
})

defineExpose({
  onTableChange
})
</script>

<style lang="scss" scoped>
.bonuses-history-table {
  width: 100%;
  margin-top: 20px;
}
.recipient {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  & label {
    color: $font-default;
  }
}
.negative-sum {
  color: $red-color;
  &::before {
    content: "-";
  }
}
.positive-sum {
  color: $green-color;
}
</style>
