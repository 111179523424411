<template>
  <div>
    <span v-html="highlighted"></span>
    <!--{{$attrs.value}}-->
  </div>
</template>

<script>
import hasCyrillic from "utils/validations/hasCyrillic"

export default {
  computed: {
    highlighted() {
      let value = this.$attrs.value
      if (!value) return ""
      let newValue = ""
      for (let i = 0; i < value.length; i++) {
        let symbol = value[i]
        if (hasCyrillic(symbol.toLowerCase())) {
          newValue += `<span class="cyrillic">${symbol}</span>`
        } else {
          newValue += `${symbol}`
        }
      }
      return newValue
    }
  }
}
</script>
<style lang="scss">
.cyrillic {
  color: #cc515d;
}
</style>
