export default {
  chats: (state) => {
    const result = state.chats.filter((chat) => {
      return chat.title.toLowerCase().includes(state.filterKey.toLowerCase())
    })
    return result
  },

  chat: (state) => state.chats.find((chat) => chat.uuid === state.currentChatId)
}
