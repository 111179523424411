import getImagePath from "@/utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const editProduct = {
  state: {
    // to show/hide modals and elements:
    PC_showAddAttributeModal: false,
    PC_showRemoveAttributeModal: false,
    PC_removalNotAllowedModal: false,
    PC_showCopyFromProductModal: false,
    PC_showEditProductModal: false,

    PC_attributesTableQueryParams: {
      limit: 50,
      sortedColumns: [],
      filteredColumns: [],
      /* currentPage: 1, */
      offset: 0
    },
    PC_EditableProduct: { brand: { name: "" }, type: { name: "" } },
    PC_exportProductErrors: [],
    exportInProcess: false,
    PC_editableProductAttributes: { count: 0, items: [] },
    PC_editableProductAttributesTable: true,

    PC_editedAttributeValues: [],
    PC_editedAttributeDefaultUnits: [],
    PC_rowToBeRemoved: {},
    PC_editedProductBarcode: false,
    PC_setEditedProductModel: false,
    PC_setEditedProductCategory: false,
    PC_setEditedProductNamePublic: false,
    PC_setEditedProductNamePublicCheckbox: null,
    PC_setEditedProductName: false,
    PC_setEditedProductDescription: false,
    PC_EditedProductShortDesc: false,
    PC_setEditedProductImages: false,
    PC_setEditedProductGtin: false,
    PC_setNewProductImages: false,
    PC_attributesOrderChanged: false,
    PC_someAttributesRemoved: false,
    PC_someAttributesAdded: false
  },

  getters: {
    PC_showAddAttributeModal: (state) => state.PC_showAddAttributeModal,
    PC_showRemoveAttributeModal: (state) => state.PC_showRemoveAttributeModal,
    PC_removalNotAllowedModal: (state) => state.PC_removalNotAllowedModal,
    PC_showCopyFromProductModal: (state) => state.PC_showCopyFromProductModal,
    PC_showEditProductModal: (state) => state.PC_showEditProductModal,

    PC_attributesTableQueryParams: (state) => state.PC_attributesTableQueryParams,
    PC_EditableProduct: (state) => state.PC_EditableProduct,
    PC_editableProductAttributes: (state) => state.PC_editableProductAttributes,
    PC_editableProductAttributesTable: (state) => state.PC_editableProductAttributesTable,

    PC_editedAttributeValues: (state) => state.PC_editedAttributeValues,
    PC_editedAttributeDefaultUnits: (state) => state.PC_editedAttributeDefaultUnits,
    PC_rowToBeRemoved: (state) => state.PC_rowToBeRemoved,
    PC_editedProductBarcode: (state) => state.PC_editedProductBarcode,
    PC_setEditedProductModel: (state) => state.PC_setEditedProductModel,
    PC_setEditedProductCategory: (state) => state.PC_setEditedProductCategory,
    PC_setEditedProductNamePublic: (state) => state.PC_setEditedProductNamePublic,
    PC_setEditedProductNamePublicCheckbox: (state) => state.PC_setEditedProductNamePublicCheckbox,
    PC_setEditedProductName: (state) =>
      state.PC_setEditedProductName === null ? true : state.PC_setEditedProductName,
    PC_setEditedProductDescription: (state) => state.PC_setEditedProductDescription,
    PC_EditedProductShortDesc: (state) => state.PC_EditedProductShortDesc,
    PC_setEditedProductImages: (state) => state.PC_setEditedProductImages,
    PC_setEditedProductGtin: (state) => state.PC_setEditedProductGtin,
    PC_setNewProductImages: (state) => state.PC_setNewProductImages,
    PC_attributesOrderChanged: (state) => state.PC_attributesOrderChanged,
    PC_someAttributesRemoved: (state) => state.PC_someAttributesRemoved,
    PC_someAttributesAdded: (state) => state.PC_someAttributesAdded
  },

  mutations: {
    setProductExportErrors(state, { code, errors, task_state }) {
      state.PC_exportProductErrors.push({ code, errors, task_state })
    },
    setExportProcess(state, bool) {
      state.exportInProcess = bool
    },
    // to show/hide modals and elements:
    PC_showAddAttributeModal(state, booleanValue) {
      state.PC_showAddAttributeModal = booleanValue
    },
    PC_showRemoveAttributeModal(state, booleanValue) {
      state.PC_showRemoveAttributeModal = booleanValue
    },
    PC_removalNotAllowedModal(state, value) {
      state.PC_removalNotAllowedModal = value
    },
    PC_showCopyFromProductModal(state, booleanValue) {
      state.PC_showCopyFromProductModal = booleanValue
    },
    PC_showEditProductModal(state, value) {
      state.PC_showEditProductModal = value
    },
    PC_attributesTableSetQueryParams(state, queryParams) {
      state.PC_attributesTableQueryParams = queryParams
    },
    PC_setEditableProduct: (state, product) => {
      let clone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = clone
      state.PC_EditableProduct = product
    },
    PC_setEditableProductImages: (state, imgs) => {
      state.PC_EditableProduct.images = imgs
    },
    PC_setProductImageUrl: (state, { index, value }) => {
      if (state.PC_EditableProduct.images[index].item) {
        delete state.PC_EditableProduct.images[index].item
      }
      state.PC_EditableProduct.images[index].filename = value
      state.PC_EditableProduct.images[index].on_cdn = true
    },
    PC_pushEditableProductImages: (state, img) => {
      state.PC_EditableProduct.images.push(img)
    },
    PC_setEditableProductImageUrls(state, value) {
      state.PC_EditableProduct.imagesUrls = value
      let clone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = clone
    },
    PC_updateEditableProductImageUrls: (state) => {
      state.PC_EditableProduct.imagesUrls = state.PC_EditableProduct.images.map((item) => {
        let url
        if (!item.on_cdn) {
          url = item.filename
        } else {
          url = IMAGES_CDN + "/media/assets/images/" + getImagePath(item.filename, "500x500")
        }
        return {
          on_cdn: item.on_cdn,
          filename: item.filename,
          item: url
        }
      })
    },
    PC_pushEditableProductImageUrls: (state, img) => {
      if (!state.PC_EditableProduct.imagesUrls) state.PC_EditableProduct.imagesUrls = []
      state.PC_EditableProduct.imagesUrls.push(img)
      let clone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = clone
    },
    PC_setEditableProductStatus: (state, value) => {
      state.PC_EditableProduct.status = value
    },
    PC_setEditableProductGtin: (state, value) => {
      // need this clone, because js would not consider creation of a new object.property as a reactive update:
      let clone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = {}
      state.PC_EditableProduct = clone
      // create new or update existing prop
      state.PC_EditableProduct.gtin = value
    },
    PC_setEditableProductAttributes: (state, { attributesData }) => {
      state.PC_editableProductAttributes = attributesData
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
    },
    PC_setEditableProductInnerAttributes(state, value) {
      state.PC_EditableProduct.attributes = value
    },
    PC_pushEditableProductInnerAttributes(state, value) {
      state.PC_EditableProduct.attributes.push(value)
    },
    PC_tweakEditableProductInnerAttribute: (state, { value, index, field }) => {
      state.PC_EditableProduct.attributes[index][field] = value
    },
    PC_emptyEditableProductInnerAttributes(state) {
      let productClone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = productClone
      state.PC_EditableProduct.attributes = []
    },
    PC_editableProductAttributesTable(state, value) {
      state.PC_editableProductAttributesTable = value
    },
    PC_emptyEditableProductAttributes: (state) => {
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
      state.PC_editableProductAttributes = { count: 0, items: [] }
    },
    PC_editableProductAttributesLocalValues(state, attributes) {
      if (!state.PC_editableProductAttributes.items.length) return
      state.PC_editableProductAttributes.items.forEach((item, index) => {
        item.localValue = attributes[index].unitValue
      })
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
    },
    PC_editableProductAttributesLocalValuesUpdate(state, { columnKey, newValue, rowIndex }) {
      if (!state.PC_editableProductAttributes.items.length) return
      state.PC_editableProductAttributes.items[rowIndex][columnKey] = newValue
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
    },
    PC_editableProductAttributesLocalNames(state, value) {
      if (!state.PC_editableProductAttributes.items.length) return
      state.PC_editableProductAttributes.items.forEach((item, index) => {
        item.localName = value[index]
      })
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
    },
    PC_editableProductAttributesLocalUnits(state, value) {
      if (!state.PC_editableProductAttributes.items.length) return
      state.PC_editableProductAttributes.items.forEach((item, index) => {
        item.localUnits = value[index]
      })
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
    },
    PC_setNewAttribute: (state, { attr, value, order }) => {
      // add to coresponding product
      let addObject = {
        code: attr.code,
        value: value
      }
      if (attr.selectedUnit) addObject.unit = attr.selectedUnit.code
      if (!state.PC_EditableProduct.attributes) state.PC_EditableProduct.attributes = []
      state.PC_EditableProduct.attributes.splice(order, 0, addObject)

      let productClone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = productClone
      // add to list of fetched attributes
      state.PC_editableProductAttributes.items.splice(order, 0, attr)
      state.PC_editableProductAttributes.count++
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone // need to update whole object to enable reactive updates

      // add flag that attrs were added
      if (state.PC_someAttributesAdded !== true) state.PC_someAttributesAdded = true
      // update attributes count in product table
      state.PC_EditableProduct.attributesMsg = state.PC_editableProductAttributes.items.length
    },
    PC_attributesTableSetNewValue: (
      state,
      { columnKey, newValue, rowIndex, oldValue, tableItemsCode }
    ) => {
      // set new value in table
      state.PC_editableProductAttributes.items[rowIndex][columnKey] = newValue
      // set in product
      let attributes = state.PC_EditableProduct.attributes
      let changingItem = attributes.find((item) => {
        return item.code == tableItemsCode
      })
      changingItem.value = newValue

      // add to change log, which is going to be executed on submit
      let isSet = false
      if (state.PC_editedAttributeValues.length) {
        isSet = state.PC_editedAttributeValues.find((item) => {
          return item.code === tableItemsCode
        })
      }

      if (isSet) {
        isSet.value = newValue
      } else {
        state.PC_editedAttributeValues.push({
          code: tableItemsCode,
          value: newValue
        })
      }
    },
    PC_editedAttributePushValue(state, { code, value }) {
      state.PC_editedAttributeValues.push({
        code,
        value
      })
    },
    PC_editAttributesTableListValue(state, { selectedCode, attrCode }) {
      // change in editable product.attributes
      let product = state.PC_EditableProduct
      let editable = product.attributes.find((item) => {
        return item.code === attrCode
      })
      editable.value = selectedCode
      // attributes table reload
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
      // add to change log, which is going to be executed on submit
      let isSet = state.PC_editedAttributeValues.find((item) => {
        return item.code === attrCode
      })
      if (isSet) {
        isSet.value = selectedCode
      } else {
        state.PC_editedAttributeValues.push({
          code: attrCode,
          value: selectedCode
        })
      }
    },
    PC_setEditedAttributeDefaultUnits: (state, { attrCode, newUnitCode }) => {
      let isSet = state.PC_editedAttributeDefaultUnits.find((item) => {
        return item.code === attrCode
      })

      if (isSet) {
        isSet.value = newUnitCode
      } else {
        state.PC_editedAttributeDefaultUnits.push({
          code: attrCode,
          value: newUnitCode
        })
      }
    },
    PC_setRowToBeRemoved: (state, row) => {
      state.PC_rowToBeRemoved = row
    },
    PC_deleteAttribute: (state) => {
      // remove from attributes:
      let clone = JSON.parse(JSON.stringify(state.PC_editableProductAttributes))
      state.PC_editableProductAttributes = clone
      let toBeRemovedIndex
      for (let i = 0; i < state.PC_editableProductAttributes.items.length; i++) {
        if (state.PC_editableProductAttributes.items[i].code === state.PC_rowToBeRemoved.code) {
          toBeRemovedIndex = i
          break
        }
      }
      state.PC_editableProductAttributes.items.splice(toBeRemovedIndex, 1)
      state.PC_editableProductAttributes.count--
      // remove from product:
      clone = JSON.parse(JSON.stringify(state.PC_EditableProduct))
      state.PC_EditableProduct = clone
      for (let i = 0; i < state.PC_EditableProduct.attributes.length; i++) {
        if (state.PC_EditableProduct.attributes[i].code === state.PC_rowToBeRemoved.code) {
          toBeRemovedIndex = i
        }
      }
      state.PC_EditableProduct.attributes.splice(toBeRemovedIndex, 1)
      // clear change log for deleted products
      for (let i = 0; i < state.PC_editedAttributeValues.length; i++) {
        if (state.PC_editedAttributeValues[i].code === state.PC_rowToBeRemoved.code) {
          state.PC_editedAttributeValues.splice(i, 1)
          break
        }
      }
      for (let i = 0; i < state.PC_editedAttributeDefaultUnits.length; i++) {
        if (state.PC_editedAttributeDefaultUnits[i].code === state.PC_rowToBeRemoved.code) {
          state.PC_editedAttributeDefaultUnits.splice(i, 1)
          break
        }
      }
      // update attributes count in product table
      state.PC_EditableProduct.attributesMsg =
        "Edit (" + state.PC_editableProductAttributes.items.length + ")"
      // close confirming modal
      state.PC_showRemoveAttributeModal = false
      // set removed flag
      if (state.PC_someAttributesRemoved !== true) {
        state.PC_someAttributesRemoved = true
      }
    },
    PC_setEditableProductType: (state, value) => {
      state.PC_EditableProduct.type = value
      state.PC_EditableProduct.typeName = value.name
    },
    PC_setEditableProductBrand: (state, value) => {
      state.PC_EditableProduct.brand = value
      state.PC_EditableProduct.brandName = value.name
    },
    PC_setEditedProductBarcode: (state, value) => {
      state.PC_editedProductBarcode = value
      state.PC_EditableProduct.barcode = value
    },
    PC_setEditedProductModel: (state, value) => {
      state.PC_setEditedProductModel = value
      state.PC_EditableProduct.model = value
    },
    PC_setEditedProductCategory: (state, value) => {
      const { uuid, name } = value

      state.PC_setEditedProductCategory = value
      // state.PC_EditableProduct.synthetic_meta.category.uuid = uuid;
      // state.PC_EditableProduct.synthetic_meta.category.name = name;
    },
    PC_setEditedProductNamePublic: (state, value) => {
      state.PC_setEditedProductNamePublic = value
      state.PC_EditableProduct.public_title = value
    },
    PC_setEditedProductNamePublicCheckbox: (state, value) => {
      state.PC_setEditedProductNamePublicCheckbox = value
      state.PC_EditableProduct.use_public_title = value
    },
    PC_setEditedProductName: (state, value) => {
      state.PC_setEditedProductName = value
      state.PC_EditableProduct.name = value
      if (!value) {
        state.PC_setEditedProductName = null
      }
    },
    PC_setEditedProductShortDesc: (state, event) => {
      state.PC_EditedProductShortDesc = event.target.value
      state.PC_EditableProduct.short_description = event.target.value
    },
    PC_setEditedProductDescription: (state, event) => {
      state.PC_setEditedProductDescription = event.target.value
      state.PC_EditableProduct.description = event.target.value
    },
    PC_setEditedProductImages: (state, imgsObj) => {
      state.PC_setEditedProductImages = imgsObj.map((item) => {
        return {
          filename: item.filename,
          on_cdn: item.on_cdn
        }
      })
      let clone = JSON.parse(JSON.stringify(state.PC_setEditedProductImages))
      state.PC_setEditedProductImages = clone
    },
    PC_resetEditedProductImages: (state) => {
      state.PC_setEditedProductImages = false
    },
    PC_setNewProductImages: (state, imgs) => {
      state.PC_setNewProductImages = imgs
    },
    PC_attributesOrderChanged(state, value) {
      state.PC_attributesOrderChanged = value
    },
    PC_setEditedProductGtin(state, value) {
      state.PC_setEditedProductGtin = value
    },
    PC_resetProductValues: (state) => {
      state.PC_attributesTableQueryParams = {
        limit: 50,
        sortedColumns: [],
        filteredColumns: [],
        /* currentPage: 1, */
        offset: 0
      }
      state.PC_EditableProduct = { brand: { name: "" }, type: { name: "" } }
      state.PC_editableProductAttributes = { count: 0, items: [] }
      state.PC_SearchedAttributes = []
      state.PC_editedAttributeValues = []
      state.PC_editedAttributeDefaultUnits = []
      state.PC_rowToBeRemoved = {}
      state.PC_editedProductBarcode = false
      state.PC_setEditedProductGtin = false
      state.PC_setEditedProductModel = false
      state.PC_setEditedProductCategory = false
      state.PC_setEditedProductNamePublic = false
      state.PC_setEditedProductNamePublicCheckbox = null
      state.PC_setEditedProductName = false
      state.PC_setEditedProductDescription = false
      state.PC_EditedProductShortDesc = false
      state.PC_attributesOrderChanged = false
      state.PC_someAttributesRemoved = false
      state.PC_someAttributesAdded = false
      state.PC_setEditedProductImages = false
      state.PC_setNewProductImages = false
    },
    PC_resetMainProductValues(state) {
      state.PC_editedProductBarcode = false
      state.PC_setEditedProductGtin = false
      state.PC_setEditedProductModel = false
      state.PC_setEditedProductCategory = false
      state.PC_setEditedProductNamePublic = false
      state.PC_setEditedProductNamePublicCheckbox = null
      state.PC_setEditedProductName = false
      state.PC_setEditedProductDescription = false
      state.PC_EditedProductShortDesc = false
      state.PC_attributesOrderChanged = false
      state.PC_someAttributesRemoved = false
      state.PC_someAttributesAdded = false
      state.PC_editedAttributeValues = []
      state.PC_editedAttributeDefaultUnits = []
    },
    PC_resetProductValuesWithExceptions: (state) => {
      state.PC_attributesTableQueryParams = {
        limit: 50,
        sortedColumns: [],
        filteredColumns: [],
        /* currentPage: 1, */
        offset: 0
      }
      state.PC_SearchedAttributes = []
      state.PC_editedAttributeValues = []
      state.PC_editedAttributeDefaultUnits = []
      state.PC_rowToBeRemoved = {}
      state.PC_editedProductBarcode = false
      state.PC_setEditedProductGtin = false
      state.PC_setEditedProductModel = false
      state.PC_setEditedProductCategory = false
      state.PC_setEditedProductNamePublic = false
      state.PC_setEditedProductNamePublicCheckbox = null
      state.PC_setEditedProductName = false
      state.PC_setEditedProductDescription = false
      state.PC_EditedProductShortDesc = false
      state.PC_attributesOrderChanged = false
      state.PC_someAttributesRemoved = false
      state.PC_someAttributesAdded = false
      state.PC_setEditedProductImages = false
      state.PC_setNewProductImages = false
    },
    PC_editedAttributeDefaultUnits(state, value) {
      state.PC_editedAttributeDefaultUnits = value
    },
    PC_someAttributesAdded: (state, value) => {
      state.PC_someAttributesAdded = value
    }
  },
  actions: {
    PC_attributesTableChangeQueryParams({ commit }, params) {
      commit("PC_attributesTableSetQueryParams", params)
    },
    PC_editAttributesTableInput({ getters, commit }, { columnKey, newValue, rowIndex }) {
      let oldValue = getters.PC_EditableProduct.attributes[rowIndex].value
      if (newValue === oldValue) return
      let tableItemsCode = getters.PC_editableProductAttributes.items[rowIndex].code
      commit("PC_attributesTableSetNewValue", {
        columnKey,
        newValue,
        rowIndex,
        oldValue,
        tableItemsCode
      })
    },
    PC_editAttributesTableListValue({ getters, commit }, { selectedCode, attrCode }) {
      // add to change log
      commit("PC_editAttributesTableListValue", { selectedCode, attrCode })
    },
    PC_checkBeforeDelete({ commit }, row) {
      if (row.is_required === "Да") {
        // show modal
        commit("PC_removalNotAllowedModal", true)
        return
      }
      commit("PC_setRowToBeRemoved", row)
      commit("PC_showRemoveAttributeModal", true)
    },
    PC_showAddAttributeModal({ commit }) {
      commit("PC_resetSearchNewAttributeValues")
      commit("PC_showAddAttributeModal", true)
    },
    PC_exportEditableProduct({ state, commit, dispatch }) {
      commit("setExportProcess", true)
      const code = state.PC_EditableProduct.code
      return API_MODERATION.post(apiUrls.export, {
        codes: [code]
      })
        .then((r) => r.data.message.task_id)
        .then((id) => {
          let checkInterval = setInterval(async () => {
            let response = await dispatch("SH_checkTask", id)
            response = response[0]
            commit("setProductExportErrors", {
              code: code,
              errors: response.task_result.errors,
              task_state: response.task_state
            })

            if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
              commit("setExportProcess", false)
              clearInterval(checkInterval)
            }
            if (response.task_state === "SUCCESS" && response.task_result.errors.length) {
              notification.info({ message: "Запрос на экспорт продукта обработан" })
            } else if (response.task_state === "SUCCESS") {
              notification.success({ message: "Экспорт обработан без ошибок" })
            }
            if (response.task_state === "FAILURE") {
              notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
            }
          }, 3000)
        })
    }
  }
}

export default editProduct
