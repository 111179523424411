<template>
  <div>
    <template v-if="PA_selectedAttribute">
      <!-- {{ tableData }} -->
      <SynDataTable
        :data="tableData"
        :queryAction="getTableData"
        :queryParams="queryParams"
        rowKey="code"
        :columns="dataTableColumns"
        tableId="ProductAttributesValuesTable"
        class="margin-top"
        :limitValues="limitValues"
        :headerButtons="headerButtons"
        theme="odd"
      ></SynDataTable>
    </template>
    <DeleteListModal />
    <!-- :headerButtons="headerButtons" -->
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
import DeleteListModal from "../DeleteListModal/DeleteListModal"

export default {
  name: "ValuesTable",
  components: {
    DeleteListModal
  },

  data() {
    return {
      dataTableColumns: [
        {
          key: "value",
          title: "Доступные значения"
        },
        {
          key: "code",
          title: "Code",
          width: 100
        }
      ],
      headerButtons: [
        {
          label: "Добавить",
          action: () => {
            this.PV_showCreateValueModal(true)
          }
        }
      ],
      //this.PA_showDeleteListModal(true); // Колишня кнопка ОТВЯЗАТЬ
      limitValues: [
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ]
    }
  },
  methods: {
    onSort(oldIndex, newIndex, items) {
      this.PA_updateTypesOrder(items)
    },
    ...mapMutations("ProductCatalog", {
      PA_showDeleteListModal: "PA_showDeleteListModal",
      PV_showCreateValueModal: "PV_showCreateValueModal",
      PA_searchedValuesQueryParams: "PA_searchedValuesQueryParams"
    }),
    ...mapActions("ProductCatalog", {
      PA_updateTypesOrder: "PA_updateTypesOrder",
      PA_searchValues: "PA_searchValues"
    }),
    getTableData(params) {
      this.PA_searchedValuesQueryParams(params)
      this.PA_searchValues(this.PA_selectedAttribute)
    }
  },
  watch: {
    PA_selectedAttribute() {
      this.PA_searchValues(this.PA_selectedAttribute)
    }
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PA_searchedValuesOfAttributeGet: "PA_searchedValuesOfAttribute",
      tableData: "PA_searchedValuesTable",
      queryParams: "PA_searchedValuesQueryParams",
      PA_selectedAttribute: "PA_selectedAttribute"
    })
  }
}
</script>
<style lang="scss" scoped></style>
