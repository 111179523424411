import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      key: "code",
      dataIndex: "code",
      title: "MPC-code",
      width: 130,
      fixed: "left"
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: "P-code",
      width: 130,
      fixed: "left"
    },
    {
      key: "synthetic_meta",
      dataIndex: "synthetic_meta",
      title: `${i18n.t("seller")} (slug)`,
      scopedSlots: {
        customRender: "metaRenderer"
      }
    },
    {
      key: "type_name",
      dataIndex: "type_name",
      title: i18n.t("productType")
    },
    {
      key: "brand_name",
      dataIndex: "brand_name",
      title: i18n.t("brand")
    },
    {
      key: "public_title",
      dataIndex: "public_title",
      title: i18n.t("nameForPublish")
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model")
    },
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      }
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("exportProductStatus")
    }
  ])

  return { columns }
}
