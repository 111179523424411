/**
 * Function that format number to ISO
 *
 * @param {number | string} sum
 * @param {boolean} integer if true return value without fraction digits
 *
 * @return {string}
 */
export const getFormatedSum = (sum, integer = false) => {
  const formatedSum = new Intl.NumberFormat("uk-UA", {
    minimumFractionDigits: integer ? 0 : 2,
    maximumFractionDigits: integer ? 0 : 2
  }).format(+sum)

  return formatedSum
}

export default getFormatedSum
