<template>
  <a-form-model
    ref="formRef"
    :model="form"
    style="padding-bottom: 70px"
  >
    <a-descriptions
      bordered
      :column="1"
      size="small"
    >
      <a-descriptions-item :label="$t('authorFullname')">
        <b>{{ review.author?.username }}</b>
        {{ review.author?.first_name }}
        {{ review.author?.middle_name }}
        {{ review.author?.last_name }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('phoneNumber')">
        <a-button
          type="link"
          style="padding: 0"
          @click="handleCopy(review.author?.mobile_phone)"
        >
          +{{ review.author?.mobile_phone }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('p_code')">
        <a-button
          type="link"
          icon="link"
          style="padding: 0"
          :href="productUrl"
          target="_blank"
        >
          {{ review.p_code }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('grade')">{{ review.rating }}</a-descriptions-item>

      <a-descriptions-item :label="$t('parent')">
        <a-button
          v-if="review.parent"
          type="link"
          style="padding: 0"
          @click="handleShowParentReview()"
        >
          {{ $t("show") }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('comment')">
        {{ review.comment }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('advantages')">
        {{ review.pros }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('flaws')">
        {{ review.cons }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('photo')">
        <TableRendererPhotoGallery
          v-if="review.images?.length"
          :images="review.images"
        />
      </a-descriptions-item>
    </a-descriptions>

    <a-form-model-item
      :label="$t('moderationStatus')"
      prop="moderation_status"
    >
      <a-select
        style="width: 100%"
        v-model="form.moderation_status"
        :options="moderationStatusOptions"
      />
    </a-form-model-item>

    <a-form-model-item
      v-show="form.moderation_status === 'ACTIVE'"
      :label="$t('ordering')"
      prop="ordering"
    >
      <a-input-number
        v-model="form.ordering"
        :min="0"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="fetching"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import { notification, message } from "ant-design-vue"
import i18n from "@/i18n"

import { MARKETPLACE } from "@/constants/common"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { updateProductReview } from "@/modules/MPAdmin/services/productReviewsService.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  review: {
    type: Object,
    default: () => ({})
  },
  moderationStatusOptions: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["openParent", "updateReview"])

const form = reactive({
  moderation_status: props.review.moderation_status || "",
  ordering:
    typeof props.review.ordering === "number" && props.review.moderation_status === "ACTIVE"
      ? props.review.ordering
      : ""
})
const formRef = ref()

const fetching = ref(false)

const productUrl = computed(() => {
  return `${MARKETPLACE}/product/${props.review.slug}/${props.review.p_code}/`
})

const handleShowParentReview = () => {
  emit("openParent", props.review.parent)
}

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    fetching.value = true

    const payload = {
      moderation_status: form.moderation_status
    }

    if (typeof form.ordering === "number" && form.moderation_status === "ACTIVE") {
      payload.ordering = form.ordering
    }

    const { data } = await updateProductReview(payload, props.review.id)

    emit("updateReview", data)
    notification.success({ message: i18n.t("updated") })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    fetching.value = false
  }
}
</script>
