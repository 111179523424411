import { computed } from "vue"
import i18n from "@/i18n"

export default function usePromotionsPageColumns() {
  const columns = computed(() => [
    {
      key: "is_active1",
      title: i18n.t("bannerShorted"),
      scopedSlots: {
        customRender: "images"
      },
      fixed: "left",
      width: 90
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      fixed: "left",
      width: 300
    },
    {
      key: "link",
      dataIndex: "link",
      title: i18n.t("url"),
      scopedSlots: {
        customRender: "urlRenderer"
      },
      width: 500
    },
    {
      key: "promo_text",
      dataIndex: "promo_text",
      title: i18n.t("promoText"),
      scopedSlots: {
        customRender: "promoRenderer"
      },
      width: 400
    },
    {
      key: "rating",
      dataIndex: "rating",
      title: i18n.t("sorting"),
      sorter: true,
      width: 100
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("state"),
      scopedSlots: {
        customRender: "state"
      },
      sorter: true,
      width: 120
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("creatingDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      width: 130
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 100
    }
  ])

  return columns
}
