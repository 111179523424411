<template>
  <div>
    <div class="row product-catalog__attributes">
      <div class="col-sm-9">
        <Select
          @change="onSelectLang"
          placeholder="Выберите язык"
          :values="PC_languages"
          :width="58"
          :selected="PC_selectedLanguage"
          class="lang-select"
        />
        <h2>Справочник характеристик и их связей</h2>
        <h3>Список существующих атрибутов</h3>
        <AttributesTable />
        <CreateAttributeModal />
      </div>
      <div
        class="col-sm-3"
        v-if="PA_selectedAttribute"
      >
        <h2>Типы по атрибуту</h2>
        <h3>"{{ PA_selectedAttribute.name || "" }}"</h3>
        <AtlsTable />

        <div
          v-if="
            PA_selectedAttribute.value_type === 'LIST' ||
            PA_selectedAttribute.value_type === 'MULTILIST'
          "
        >
          <!-- <div v-if="PA_searchedValuesOfAttribute && PA_searchedValuesOfAttribute.length"> -->
          <h2 class="margin-top">Используемый список</h2>
          <h3 v-if="PA_searchedValuesOfAttribute && PA_searchedValuesOfAttribute.length">
            "{{ PA_searchedValuesOfAttribute[0].description || "" }}"
          </h3>
          <ValuesTable />
          <!-- </div> -->
          <!-- <div v-else>
            <h2 class="margin-top">Атрибут не добавлен в список</h2>
          </div> -->
        </div>
        <!-- <div v-if="PA_selectedAttribute.value_type === 'STRING'">
          <h2 class="margin-top">Добавить в список</h2>
          <Button v-if="PA_selectedAttribute && PA_selectedAttribute.value_type === 'STRING'"
                  color="primary"
                  @click="onAdd">Добавить</Button>
          <AddListModal />
        </div> -->
      </div>
    </div>
    <div v-if="PA_searchedValuesOfAttribute[0]">
      <CreateValueModal :description-list="PA_searchedValuesOfAttribute[0].description" />
    </div>
  </div>
</template>
<script>
import Button from "components/Button"
import { mapActions, mapMutations, mapGetters, mapState } from "vuex"
import AttributesTable from "./AttributesTable/AttributesTable"
import CreateAttributeModal from "./CreateAttributeModal/CreateAttributeModal"
import AtlsTable from "./AtlsTable/AtlsTable"
import CreateValueModal from "../ProductCatalogValues/CreateValueModal/CreateValueModal"
import ValuesTable from "./ValuesTable/ValuesTable"
import AddListModal from "./AddListModal/AddListModal"
import Select from "components/Select/Select"

export default {
  metaInfo: {
    title: "MS: Attributes"
  },
  name: "ProductCatalogAttributes",
  components: {
    AtlsTable,
    AttributesTable,
    CreateValueModal,
    CreateAttributeModal,
    Button,
    ValuesTable,
    AddListModal,
    Select
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PA_selectedAttribute: "PA_selectedAttribute",
      PA_searchedValuesOfAttribute: "PA_searchedValuesOfAttribute",
      PV_initialValuesDescriptionItems: "PV_initialValuesDescriptionItems",
      PC_languages: "PC_languages",
      PC_selectedLanguage: "PC_selectedLanguage"
    })
  },
  methods: {
    onAdd() {
      // lists list
      if (!this.PV_initialValuesDescriptionItems.length) {
        this.PV_searchValuesByDescriptionRequest()
      }
      this.PA_showAddListModal(true)
    },
    onSelectLang(value) {
      if (value === this.PC_selectedLanguage) return
      let page = "attributes"
      this.PC_selectLanguage({ value, page })
    },
    ...mapMutations("ProductCatalog", {
      PA_showAddListModal: "PA_showAddListModal",
      PA_resetAttributesTableValues: "PA_resetAttributesTableValues"
    }),
    ...mapActions("ProductCatalog", {
      PV_searchValuesByDescriptionRequest: "PV_searchValuesByDescriptionRequest",
      PC_selectLanguage: "PC_selectLanguage"
    })
  },
  beforeRouteLeave(to, from, next) {
    this.PA_resetAttributesTableValues()
    next()
  }
}
</script>
<style lang="scss">
.product-catalog-wrapper .product-catalog__attributes .lang-select {
  position: absolute;
  right: 15px;
  top: 0px;
}
</style>
