<template>
  <div class="header">
    <div class="header__left">
      <a-button
        v-for="(action, index) in buttons"
        :key="`action-${index}`"
        @click="action.action"
        :icon="action.icon"
        type="primary"
        :disabled="action.disabled"
        className="header__left-button"
      >
        {{ action.label }}
      </a-button>

      <slot name="header" />
    </div>
    <div
      class="header__center"
      v-show="selectableEnabled"
    >
      <div class="header__center-line">
        <span
          class="header__info-item"
          v-show="selectedCalc.countElements"
        >
          Count: {{ selectedCalc.countElements }}
        </span>
        <span
          class="header__info-item"
          v-show="selectedCalc.sum"
        >
          Sum: {{ selectedCalc.sum }}
        </span>
        <span
          class="header__info-item"
          v-show="selectedCalc.min"
        >
          Min: {{ selectedCalc.min }}
        </span>
      </div>
      <div class="header__center-line">
        <span
          class="header__info-item"
          v-show="selectedCalc.average"
        >
          Average: {{ selectedCalc.average }}
        </span>
        <span
          class="header__info-item"
          v-show="selectedCalc.max"
        >
          Max: {{ selectedCalc.max }}
        </span>
      </div>
    </div>

    <div class="header__right">
      <a-checkbox
        v-if="selectableMode"
        @change="(e) => onChangeSelectableEnabled(e.target.checked)"
        :checked="selectableEnabled"
        className="header__selectable-checkbox"
      >
        Selectable
      </a-checkbox>
      <a-select
        :options="limitValues"
        @change="onChangePageLimit"
        :value="itemsLimit"
        className="header__per-page-select"
      />
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs"

export default {
  name: "Header",

  props: {
    selectableMode: {
      type: Boolean,
      required: true
    },
    selectableEnabled: {
      type: Boolean,
      required: true
    },
    onChangeSelectableEnabled: {
      type: Function,
      required: true
    },
    selectedCalc: {
      type: Object,
      required: true
    },
    buttons: {
      type: Array,
      required: true
    },
    itemsLimit: {
      type: Number,
      required: true
    },
    limitValues: {
      type: Array,
      required: true
    },
    onChangePageLimit: {
      type: Function,
      required: true
    },
    onChangeSettings: {
      type: Function,
      required: true
    },
    tableSettings: {
      type: Array,
      required: true
    },
    defaultSettings: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      sortedColumns: [...this.tableSettings],
      cacheSortedColumns: [...this.tableSettings],
      sortable: null
    }
  },

  computed: {
    settingsChanged() {
      const settings = JSON.stringify(this.sortedColumns)
      const defaultSettings = JSON.stringify(this.defaultSettings)

      return settings !== defaultSettings
    }
  },

  methods: {
    changeFixedStatus(columnKey) {
      this.sortedColumns = this.sortedColumns.map((column) => {
        return column.key === columnKey
          ? {
              ...column,
              fixed: !column.fixed
            }
          : column
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/config";

.header {
  &__settings-button {
    &--changed {
      color: $info-color;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../../styles/config";

.header {
  border-bottom: solid 1px $border-color;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 54px;

  &__left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__center {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__left-button {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__selectable-checkbox {
    margin-right: 10px;
  }

  &__center-line {
    display: flex;
    justify-content: space-between;
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-item {
    padding: 0 10px;
  }

  &__settings {
    margin-right: 10px;
  }

  &__settings-button {
    &--changed {
      color: red;
    }
  }
}

.settings {
  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin-right: 10px;
  }
}

.sortableColumns {
  padding: 0;

  &__item {
    border: solid 1px $border-color;
    border-radius: 3px;
    list-style: none;
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 2px 2px 10px;
  }
}
</style>
