import { API_ADS } from "@/utils/HttpUtils"
import { transformQuery } from "./servicesUtils"

export const fetchCPC = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `/v1/admin/campaigns/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const createCampaign = (seller_slug, label, balance) => {
  return API_ADS.post("/v1/admin/campaigns/", {
    seller_slug,
    label,
    balance,
    status: "ACTIVE"
  })
}

export const editCampaign = (uuid, data) => {
  return API_ADS.patch(`/v1/admin/campaigns/${uuid}/`, data)
}

export const fetchCPCAlerts = () => {
  return API_ADS.get("/v1/admin/campaigns/alerts/")
}

export const deleteAlert = (alertUuid) => {
  return API_ADS.delete(`/v1/admin/campaigns/alerts/${alertUuid}/`)
}

export const getCampaignExpences = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `/v1/admin/campaigns/${requestParams.uuid.value}/expenses/${transformQuery(queryParams)}`,
    { signal }
  )
}
