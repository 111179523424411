import { computed } from "vue"
import i18n from "@/i18n"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => {
    return [
      {
        key: "seller",
        dataIndex: "seller",
        title: i18n.t("seller"),
        scopedSlots: {
          customRender: "seller"
        },
        fixed: "left",
        width: 170
      },
      {
        key: "slug",
        dataIndex: "slug",
        title: i18n.t("slug"),
        scopedSlots: {
          customRender: "slug"
        },
        fixed: "left",
        width: 140
      },

      {
        key: "total",
        dataIndex: "total",
        scopedSlots: {
          title: "custom-title-last_sync_total"
        },
        customRender: (total) => getFormatedSum(total, true),
        align: "center",
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "total" && sortedInfo?.value?.order,
        width: 120
      },
      {
        key: "valid",
        dataIndex: "valid",
        scopedSlots: {
          title: "custom-title-last_sync_count"
        },
        customRender: (total) => getFormatedSum(total, true),
        align: "center",
        width: 114
      },
      {
        key: "not_valid",
        dataIndex: "not_valid",
        scopedSlots: {
          title: "custom-title-last_sync_failure_count"
        },
        align: "center",
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "not_valid" && sortedInfo?.value?.order,
        width: 135
      },
      {
        key: "not_matched",
        dataIndex: "not_matched",
        scopedSlots: {
          title: "custom-title-last_sync_new_count"
        },
        customRender: (total) => getFormatedSum(total, true),
        align: "center",
        width: 144
      },
      {
        key: "matched",
        dataIndex: "matched",
        scopedSlots: {
          title: "custom-title-matched"
        },
        customRender: (total) => getFormatedSum(total, true),
        align: "center",
        width: 128
      },
      {
        key: "confirmed",
        dataIndex: "confirmed",
        scopedSlots: {
          title: "custom-title-confirmed"
        },
        customRender: (total) => getFormatedSum(total, true),
        align: "center",
        width: 144
      },

      {
        key: "active",
        dataIndex: "active",
        scopedSlots: {
          title: "custom-title-active"
        },
        customRender: (total) => getFormatedSum(total, true),
        align: "center",
        width: 114
      },
      {
        key: "last_sync_ends_at",
        dataIndex: "last_synchronized",
        title: i18n.t("lastSyncShorted"),
        customRender: (date) => getFormatedDateTime(date),
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "last_sync_ends_at" && sortedInfo?.value?.order,
        width: 166
      },
      {
        key: "is_active",
        dataIndex: "is_active",
        title: i18n.t("state"),
        scopedSlots: {
          customRender: "state"
        },
        fixed: "right",
        width: 98
      },
      {
        key: "status",
        dataIndex: "status",
        title: i18n.t("status"),
        scopedSlots: {
          customRender: "status"
        },
        fixed: "right",
        width: 142
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        fixed: "right",
        width: 104
      }
    ]
  })
  return columns
}
