<template>
  <router-view />
</template>

<script>
export default {
  name: "Metrics",
  metaInfo: {
    title: "Metrics"
  }
}
</script>
