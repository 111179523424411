import { API } from "@/utils/HttpUtils"
import { transformQuery } from "../servicesUtils"

export const fetchOfferLabels = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/offer-labels${transformQuery(queryParams)}`, { signal })
}

export const removeOfferLabel = (payload) => {
  return API.delete("/mp-admin/offer-labels/" + payload.id)
}

export const createOfferLabel = (data) => {
  return API.post("/mp-admin/offer-labels/", data)
}
export const updateOfferLabel = (data, id) => {
  return API.put(`/mp-admin/offer-labels/${id}`, data)
}
