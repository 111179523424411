export default {
  synthetic: "Synthetic Admin",
  authorization: "Authorization",
  signIn: "Sign in",
  login: "Login",
  userName: "Username",
  password: "Password",
  passwordConfirm: "Password confirm",
  wrongLoginOrPassword: "Incorrect login or password",
  forgotPass: "Forgot the password?",
  restorePass: "Restore",
  signOut: "Sign out"
}
