import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_valueToBeDeleted: false,
  PV_showDeleteValuesConfirmation: false,
  PV_productsWithValueToBeDeleted: false,
  PV_showUpdateAndDeleteModal: false,
  PV_newValueInsteadOfDeleted: false,
  PV_valuesSelect: [],
  PV_defaultSelectValue: { label: "Выберете новое", value: 1 },
  PV_filteredValues: false
})

const getters = {
  PV_valueToBeDeleted: (state) => state.PV_valueToBeDeleted,
  PV_showDeleteValuesConfirmation: (state) => state.PV_showDeleteValuesConfirmation,
  PV_productsWithValueToBeDeleted: (state) => state.PV_productsWithValueToBeDeleted,
  PV_showUpdateAndDeleteModal: (state) => state.PV_showUpdateAndDeleteModal,
  PV_newValueInsteadOfDeleted: (state) => state.PV_newValueInsteadOfDeleted,
  PV_valuesSelect: (state) => state.PV_valuesSelect,
  PV_defaultSelectValue: (state) => state.PV_defaultSelectValue,
  PV_filteredValues: (state) => state.PV_filteredValues
}

const mutations = {
  PV_valueToBeDeleted(state, value) {
    state.PV_valueToBeDeleted = value
  },
  PV_showDeleteValuesConfirmation(state, value) {
    state.PV_showDeleteValuesConfirmation = value
  },
  PV_productsWithValueToBeDeleted(state, value) {
    state.PV_productsWithValueToBeDeleted = value
  },
  PV_showUpdateAndDeleteModal(state, value) {
    state.PV_showUpdateAndDeleteModal = value
  },
  PV_newValueInsteadOfDeleted(state, value) {
    state.PV_newValueInsteadOfDeleted = value
  },
  PV_valuesSelect(state, value) {
    state.PV_valuesSelect = value
  },
  PV_defaultSelectValue(state, value) {
    state.PV_defaultSelectValue = value
  },
  PV_filteredValues(state, value) {
    state.PV_filteredValues = value
  }
}

const actions = {
  async PV_deleteValue({ getters, commit, dispatch }) {
    let products = await dispatch("PV_checkProductsWithValue")
    if (products === null) return
    // if there are products using the value
    if (products.length) {
      commit("PV_productsWithValueToBeDeleted", products)
      // close confirmation modal
      commit("PV_showDeleteValuesConfirmation", false)
      // filter values to drop the one which is to be deleted
      await dispatch("PV_filterValues")
      // build select for updateAndDeleteModal
      await dispatch("PV_buildSelectForUpdateAndDelete")
      // show updateAndDeleteModal
      commit("PV_showUpdateAndDeleteModal", true)
    } else {
      // delete
      await dispatch("PV_deleteValueFromDatabase")
      // close confirmation modal
      commit("PV_showDeleteValuesConfirmation", false)
      // reset values
      dispatch("PV_resetDeleteValues")
    }
  },
  async PV_checkProductsWithValue({ getters, commit }) {
    let params = { value: getters.PV_valueToBeDeleted.code }
    // show loader
    commit("PC_showLoader", true)
    return API_MODERATION.get(
      // get vaues lists
      `${apiUrls.deleteValue}`,
      { params }
    )
      .then((r) => r.data.message.products)
      .then((products) => {
        // hide loader
        commit("PC_showLoader", false)
        return products
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        commit("handleError", e)
        return null
      })
  },
  async PV_updateAndDelete({ getters, commit, dispatch }) {
    //commit('PC_showLoader', true);
    let sendData = {
      old_value: getters.PV_valueToBeDeleted.code,
      new_value: getters.PV_newValueInsteadOfDeleted.code
    }
    return API_MODERATION.put(`${apiUrls.deleteValue}`, sendData)
      .then((r) => r.data.message.task_id)
      .then((id) => {
        return id
      })
      .catch((e) => {
        e.$_actionName = "PV_updateAndDelete"
        return Promise.reject(e)
      })
  },
  async PV_buildSelectForUpdateAndDelete({ getters, commit }, values) {
    let select = []
    if (!values) values = getters.PV_filteredValues
    for (let i = 0; i < values.length; i++) {
      select.push({
        label: values[i].value,
        value: values[i].code
      })
    }
    commit("PV_valuesSelect", select)
    return Promise.resolve()
  },
  async PV_filterValues({ getters, commit }) {
    let filtered = getters.PV_valuesData.items.filter((item) => {
      return item.code !== getters.PV_valueToBeDeleted.code
    })
    commit("PV_filteredValues", filtered)
    return Promise.resolve()
  },
  async PV_valuesByDescription({ getters, commit, dispatch }, value) {
    //add loader or blocker
    let searchData = buildMagicSearch({
      resource: "VLD",
      field: ["description", "value"],
      value: [getters.PV_selectedList.description, value],
      sort: null,
      limit: 20,
      offset: 0
    })
    return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((values) => {
        return values
      })
      .catch((e) => {
        e.$_actionName = "PV_valuesByDescription"
        return Promise.reject(e)
      })
  },
  async PV_deleteValueFromDatabase({ getters, commit, dispatch }) {
    let params = {
      code: getters.PV_valueToBeDeleted.code
    }
    return API_MODERATION.delete(`${apiUrls.value}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        // reload values table
        dispatch("PV_resetValuesTalbe")
        dispatch("PV_searchValues", {
          selected: getters.PV_selectedList,
          queryParams: getters.PV_valuesQueryParams
        })
        // if number of values === 0, reload description table
        if (getters.PV_selectedListCount - 1 === 0) {
          commit("PV_selectedList", false)
          dispatch("PV_searchValuesByDescription")
        }
        // update number of values in lists table (if selected still exist)
        if (getters.PV_selectedListCount) {
          commit("PV_selectedListCount", getters.PV_selectedListCount - 1)
        }

        notification.info({ message: "Значение удалено с базы" })
        // hide loader
        commit("PC_showLoader", false)
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        commit("handleError", e)
      })
  },
  async PV_resetDeleteValues({ getters, commit }) {
    commit("PV_valueToBeDeleted", false)
    commit("PV_productsWithValueToBeDeleted", false)
    commit("PV_newValueInsteadOfDeleted", false)
    commit("PV_valuesSelect", [])
    commit("PV_defaultSelectValue", { label: "Выберете новое", value: 1 })
    commit("PV_filteredValues", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
