<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PT_confirmRemoveAttributeModalGet"
      :title="'Подтвердите отвязку атрибута'"
    >
      <div>
        <label
          ><span
            >Вы уверены, что хотите отвязать атрибут:
            <strong>{{ PT_attributeToBeRemovedGet.name }}</strong> ?</span
          ></label
        >
      </div>
      <span slot="footer">
        <Button
          color="danger"
          @click="confirm"
          >Отвязать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"

import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "ConfirmRemoveAttributeModal",
  components: {
    Button,
    Modal
  },
  created() {},
  data() {
    return {}
  },
  methods: {
    confirm() {
      this.PT_removeAttribute()
      this.closeModal()
    },
    closeModal() {
      this.PT_confirmRemoveAttributeModal(false)
      this.PT_attributeToBeRemoved(false)
    },
    ...mapMutations("ProductCatalog", {
      PT_confirmRemoveAttributeModal: "PT_confirmRemoveAttributeModal",
      PT_attributeToBeRemoved: "PT_attributeToBeRemoved"
    }),
    ...mapActions("ProductCatalog", {
      PT_removeAttribute: "PT_removeAttribute"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PT_confirmRemoveAttributeModalGet: "PT_confirmRemoveAttributeModal",
      PT_attributeToBeRemovedGet: "PT_attributeToBeRemoved"
    })
  }
}
</script>
<style lang="scss" scoped></style>
