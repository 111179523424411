<template>
  <div class="new-order-limit">
    {{ $t("newOrderLimit") }}
    <a-input-number
      style="width: 100%"
      :min="0"
      :precision="0"
      v-model="newFreeOrders"
    />

    <div class="new-order-limit__buttons">
      <a-button
        :disabled="fetching"
        @click="clearAmountFreeOrdersDropdown"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="fetching"
        @click="changeAmountFreeOrders"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { sellerChangeAmountFreeOrders } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  props: {
    amountFreeOrders: {
      type: Number | String,
      default: 0
    },
    slug: {
      type: String,
      required: true
    },
    uuid: {
      type: String,
      required: true
    }
  },

  emits: ["clearAmountFreeOrdersDropdown"],

  data() {
    return {
      newFreeOrders: +this.amountFreeOrders || 0,
      fetching: false
    }
  },

  methods: {
    async changeAmountFreeOrders() {
      this.fetching = true

      try {
        await sellerChangeAmountFreeOrders(this.slug, { amount_free_orders: this.newFreeOrders })

        this.$notification.success({ message: this.$t("freeOrdersLimitChanged") })
        this.$emit("handleUpdateBillingInfo")
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.fetching = false
      }
    },

    clearAmountFreeOrdersDropdown() {
      this.newFreeOrders = this.amountFreeOrders
    }
  }
}
</script>

<style scoped lang="scss">
.new-order-limit {
  width: 30%;
  margin-left: 32px;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  &__buttons {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 48%;
    }
  }
}
</style>
