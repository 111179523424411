const state = () => ({
  PC_showEditGtinModal: false,
  PV_stringValueOfGtin: false,
  PV_valuesForNewGtin: false
})

const getters = {
  PC_showEditGtinModal: (state) => state.PC_showEditGtinModal,
  PV_stringValueOfGtin: (state) => state.PV_stringValueOfGtin,
  PV_valuesForNewGtin: (state) => state.PV_valuesForNewGtin
}

const mutations = {
  PC_showEditGtinModal(state, value) {
    state.PC_showEditGtinModal = value
  },
  PV_stringValueOfGtin(state, value) {
    state.PV_stringValueOfGtin = value
  },
  PV_valuesForNewGtin(state, value) {
    state.PV_valuesForNewGtin = value
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
