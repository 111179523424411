import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const state = () => ({
  PT_searchedAttributesOfType: false,
  PT_searchedAtlsOfType: false,
  PT_searchedAttributesTable: { count: 0, items: [] },
  PT_searchedAttributesQueryParams: { offset: 0 },
  PT_typeForAttribute: false,
  PT_typeCodeForAttribute: false,
  PT_searchedAttributesNewOrder: false,
  PT_confirmRemoveAttributeModal: false,
  PT_attributeToBeRemoved: false
})
const getters = {
  PT_searchedAttributesOfType: (state) => state.PT_searchedAttributesOfType,
  PT_searchedAtlsOfType: (state) => state.PT_searchedAtlsOfType,
  PT_searchedAttributesTable: (state) => state.PT_searchedAttributesTable,
  PT_searchedAttributesQueryParams: (state) => state.PT_searchedAttributesQueryParams,
  PT_typeForAttribute: (state) => state.PT_typeForAttribute,
  PT_typeCodeForAttribute: (state) => state.PT_typeCodeForAttribute,
  PT_searchedAttributesNewOrder: (state) => state.PT_searchedAttributesNewOrder,
  PT_confirmRemoveAttributeModal: (state) => state.PT_confirmRemoveAttributeModal,
  PT_attributeToBeRemoved: (state) => state.PT_attributeToBeRemoved
}

const mutations = {
  PT_searchedAttributesOfType(state, value) {
    state.PT_searchedAttributesOfType = value
  },
  PT_searchedAtlsOfType(state, value) {
    state.PT_searchedAtlsOfType = value
  },
  PT_searchedAttributesTable(state, value) {
    state.PT_searchedAttributesTable = value
  },
  PT_searchedAttributesTableItems(state, value) {
    state.PT_searchedAttributesTable.items = value
    let clone = JSON.parse(JSON.stringify(state.PT_searchedAttributesTable))
    state.PT_searchedAttributesTable = clone
  },
  PT_typeForAttribute(state, value) {
    state.PT_typeForAttribute = value
  },
  PT_typeCodeForAttribute(state, value) {
    state.PT_typeCodeForAttribute = value
  },
  PT_searchedAttributesNewOrder(state, value) {
    state.PT_searchedAttributesNewOrder = value
  },
  PT_updateAttributeCheckboxes(state, { field, rowIndex, val }) {
    state.PT_searchedAttributesTable.items[rowIndex][field] = val
  },
  PT_confirmRemoveAttributeModal(state, value) {
    state.PT_confirmRemoveAttributeModal = value
  },
  PT_attributeToBeRemoved(state, value) {
    state.PT_attributeToBeRemoved = value
  },
  PT_resetAttributesOfTypeValue(state) {
    state.PT_searchedAttributesOfType = false
    state.PT_searchedAtlsOfType = false
    state.PT_searchedAttributesTable = { count: 0, items: [] }
    state.PT_searchedAttributesQueryParams = { offset: 0 }
    state.PT_typeForAttribute = false
    state.PT_typeCodeForAttribute = false
    state.PT_searchedAttributesNewOrder = false
  }
}

const actions = {
  async PT_searchAttributes({ commit, dispatch }, type) {
    // reset values
    commit("PT_resetAttributesOfTypeValue")
    // assign type
    commit("PT_typeForAttribute", type.full_name)
    commit("PT_typeCodeForAttribute", type.code)
    // send request
    let response = await dispatch("PT_searchAttributesOfType", type.code)
    if (!response) return
    // build table data
    await dispatch("PT_buildSearchedAttributesTable")
  },
  async PT_searchAttributesOfType({ commit, getters, dispatch }, typeCode) {
    // show loader
    commit("PC_showLoader", true)

    let params = {
      type_code: typeCode
    }

    return API_MODERATION.get(`${apiUrls.searchAttributes}`, { params })
      .then((r) => r.data.message)
      .then((info) => {
        commit("PT_searchedAttributesOfType", info.attributes)
        commit("PT_searchedAtlsOfType", info.atls)

        // hide loader
        commit("PC_showLoader", false)
        return info
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  async PT_buildSearchedAttributesTable({ commit, getters }) {
    let attributes = getters.PT_searchedAttributesOfType
    let atls = getters.PT_searchedAtlsOfType
    let newAttributes = []
    for (let i = 0; i < attributes.length; i++) {
      let name = attributes[i].name || ""
      let attrCode = attributes[i].code
      let code = atls[i].code
      let is_main = atls[i].is_main
      let is_required = atls[i].is_required
      let order = atls[i].order
      newAttributes.push({ name, code, is_main, is_required, order, attrCode })
    }
    if (newAttributes.length) {
      commit("PT_searchedAttributesTable", { count: newAttributes.length, items: newAttributes })
      // abstract items
      commit("PT_searchedAttributesNewOrder", newAttributes)
    }
    return Promise.resolve()
  },
  async PT_updateAttributesOrder({ commit, getters, dispatch }, items) {
    items = items.filter((item) => item)
    // update table items
    commit("PT_searchedAttributesTableItems", items)
    // update on server
    let response = await dispatch("PT_updateAttributesOrderOnServer", items)
    if (!response) return
    // update table abstract items to refer to the real items order later (can't update object which goes into table,
    // because it would break the row-dragging component (historical reasons)
    commit("PT_searchedAttributesNewOrder", items)
  },
  async PT_updateAttributesOrderOnServer({ commit, getters }, items) {
    let atls = items.map((item) => {
      return item.code
    })
    let sendData = {
      type_code: getters.PT_typeCodeForAttribute,
      atls
    }
    // update order on server
    return API_MODERATION.put(`${apiUrls.attributeOrder}`, sendData)
      .then((r) => r.data.message)
      .then((response) => {
        notification.info({ message: "Изменения добавлены" })
        return response
      })
      .catch((e) => {
        commit("handleError", e)
        return null
      })
  },
  async PT_removeAttribute({ commit, getters, dispatch }) {
    let products = await dispatch("PT_removeAttributeFromType")
    if (!products) return
    if (typeof products === "object" && products.length) {
      dispatch("PT_buildProductsTableData", products)
      commit("PT_showProductsModal", true)
    } else {
      dispatch("PT_searchAttributes", getters.PT_selectedType)
    }
  },
  async PT_removeAttributeFromType({ commit, getters }) {
    // show loader
    commit("PC_showLoader", true)

    let params = {
      code: getters.PT_attributeToBeRemoved.code
    }

    return API_MODERATION.delete(`${apiUrls.link}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        if (e.response && e.response.status === 400 && e.response.data.message.length) {
          let products = e.response.data.message
          return products
        } else {
          commit("handleError", e)
          return null
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
