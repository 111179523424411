<template>
  <div
    v-if="row.bill"
    style="display: flex; gap: 10px; justify-content: flex-end; align-items: center"
  >
    <div
      class="note"
      v-if="row.bill.note"
      @dblclick="openModal"
    >
      <span>{{ row.bill.note }}</span>
    </div>
    <a-button
      style="width: 32px"
      type="dashed"
      shape="circle"
      @click="openModal"
    >
      <a-icon type="edit" />
    </a-button>

    <a-drawer
      :visible="showModal"
      :title="`${$t('addNote')} (${row.slug})`"
      :width="600"
      @close="close"
    >
      <a-form :form="form">
        <a-form-item :label="$t('note')">
          <a-textarea
            v-decorator="[
              'note',
              { rules: [{ max: 1000, message: $t('maxLengthError', { length: 1000 }) }] }
            ]"
            :autoSize="{ minRows: 2, maxRows: 10 }"
          />
        </a-form-item>
      </a-form>
      <div class="drawer-footer">
        <a-button
          type="primary"
          :loading="loading"
          @click="handleChange"
        >
          {{ $t("apply") }}
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { billingAddNote } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  data() {
    return {
      loading: false,
      showModal: false,
      form: this.$form.createForm(this, {
        name: "editBillingNote",
        mapPropsToFields: () => {
          return {
            note: this.$form.createFormField({
              value: this.row?.bill?.note.toString() || ""
            })
          }
        }
      })
    }
  },

  props: {
    row: {
      type: Object
    },
    rowIndex: {
      type: Number,
      default: 0
    }
  },

  methods: {
    openModal() {
      this.showModal = true
      this.form.setFieldsValue({ note: this.row?.bill?.note.toString() || "" })
    },

    async handleChange() {
      if (this.loading || !this.showModal) return
      this.loading = true

      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            await billingAddNote(this.row.bill.id, values)

            this.$notification.success({ message: this.$t("updated") })
          } catch (error) {
            notifyResponseError({ error })
          } finally {
            this.loading = false
            this.close()
          }
        }
      })
    },
    close() {
      this.showModal = false
      this.form.resetFields()
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: calc(100% - 42px);
  span {
    white-space: pre-wrap;
  }
}
</style>
