<template>
  <div
    :class="{
      redColor: row.approval_status == 'NOT_VALID'
    }"
  >
    {{ row.approval_status }}
  </div>
</template>

<script>
import Button from "@/components/Button"

export default {
  name: "ApprovalStatusRenderer",
  props: {
    row: Object
  },
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
.redColor {
  color: red;
}
</style>
