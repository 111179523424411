const debounce = (fn, time) => {
  let timeout

  return function () {
    const functionCall = () => fn.apply(this)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export default debounce
