<template>
  <span
    v-if="unreadMessages"
    class="new-messages"
  >
    {{ unreadMessages }}
  </span>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MessengerNewMessages",
  methods: {},
  computed: {
    ...mapState("spvMessenger", {
      unreadMessages: (state) => state.unreadMessages
    })
  }
}
</script>

<style lang="scss">
.new-messages {
  position: absolute;
  top: 7px;
  right: -20px;
  background: #389e0d;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f6ffed;
  font-size: 12px;
  line-height: 18px;
}
</style>
