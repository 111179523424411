<template>
  <div
    v-if="isLogged"
    class="spv-messenger"
  >
    <SpvMessengerModerator />
  </div>
</template>
<script>
import { mapState } from "vuex"

export default {
  name: "SpvMessengerModeratorWrap",
  computed: {
    ...mapState("Auth", {
      isLogged: (state) => state.isLogged
    })
  }
}
</script>
