import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "created_at",
      title: i18n.t("dateCreated"),
      dataIndex: "created_at",
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      width: 165,
      fixed: "left",
      scopedSlots: {
        customRender: "dateRenderer"
      }
    },
    {
      key: "updated_at",
      title: i18n.t("editDate"),
      dataIndex: "updated_at",
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "updated_at" && sortedInfo?.value?.order,
      width: 165,
      fixed: "left",
      scopedSlots: {
        customRender: "dateRenderer"
      }
    },
    {
      key: "title",
      title: i18n.t("tagName"),
      dataIndex: "title",
      width: 270,
      fixed: "left",
      scopedSlots: { customRender: "clampedText" }
    },
    {
      key: "quantity",
      title: i18n.t("offerQuantity"),
      width: 165,
      scopedSlots: { customRender: "quantity" }
    },
    {
      key: "slug",
      title: "Slug",
      dataIndex: "slug",
      width: 165
    },
    {
      key: "seo_h1",
      title: "H1",
      dataIndex: "seo_h1.uk",
      scopedSlots: { customRender: "clampedText" },
      width: 270
    },
    {
      key: "_note",
      title: i18n.t("note"),
      dataIndex: "_note",
      scopedSlots: { customRender: "clampedText" },
      width: 270
    },
    {
      key: "is_active",
      title: i18n.t("status"),
      width: 200,
      dataIndex: "is_active",
      fixed: "right",
      scopedSlots: {
        customRender: "is_active"
      }
    }
  ])

  const tagOffersColumns = computed(() => [
    {
      key: "tag_sort",
      scopedSlots: {
        title: "ratingTitle",
        customRender: "rating"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "tag_sort" && sortedInfo?.value?.order,
      fixed: "left",
      width: 140
    },
    {
      key: "product",
      title: i18n.t("productInformation"),
      scopedSlots: { customRender: "product" },
      width: 150
    },
    {
      key: "category",
      dataIndex: "category_name",
      title: i18n.t("category"),
      scopedSlots: { customRender: "category_name" },
      width: 155
    },
    {
      key: "seller",
      dataIndex: "seller.slug",
      title: `${i18n.t("seller")} (slug)`,
      width: 155
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: i18n.t("count"),
      width: 125
    },
    {
      key: "price",
      dataIndex: "price",
      title: i18n.t("priceNew"),
      scopedSlots: {
        customRender: "sum"
      },
      width: 155
    },
    {
      key: "price_old",
      dataIndex: "price_old",
      title: i18n.t("priceOld"),
      scopedSlots: {
        customRender: "sum"
      },
      width: 155
    },
    {
      key: "is_sells",
      dataIndex: "is_sells",
      title: i18n.t("status"),
      scopedSlots: { customRender: "status" },
      width: 155
    },
    {
      key: "details",
      title: i18n.t("detailsFromSeller"),
      scopedSlots: { customRender: "details" },
      width: 265
    }
  ])

  return { columns, tagOffersColumns }
}
