import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const citiesColumns = computed(() => [
    {
      key: "state",
      title: i18n.t("region"),
      scopedSlots: {
        customRender: "locationRenderer"
      },
      fixed: "left",
      width: 400
    },
    {
      key: "district",
      title: i18n.t("district"),
      scopedSlots: {
        customRender: "locationRenderer"
      },
      width: 400
    },
    {
      key: "settlement",
      title: i18n.t("settlement"),
      scopedSlots: {
        customRender: "cityRenderer"
      },
      width: 420
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      fixed: "right",
      width: 140
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      fixed: "right",
      width: 140
    }
  ])

  return {
    citiesColumns
  }
}
