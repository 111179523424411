<template>
  <div>
    <div class="row product-catalog__types">
      <div class="col-sm-9">
        <Select
          @change="onSelectLang"
          placeholder="Выберите язык"
          :values="PC_languages"
          :width="58"
          :selected="PC_selectedLanguage"
          class="lang-select"
        />
        <h2>Справочник типов</h2>
        <h3>Поиск и сортировка всех существующих типов</h3>
        <TypesTable />
        <CreateTypeModal />
        <CopyTypeModal />
      </div>
      <div class="col-sm-3">
        <h2>Атрибуты по типу</h2>
        <h3 v-if="PT_typeForAttribute">"{{ PT_typeForAttribute }}"</h3>
        <AttributesTable />
        <CreateAttributeModal />
        <ConfirmRemoveAttributeModal />
        <ProductsModal />
      </div>
    </div>
  </div>
</template>
<script>
import CreateTypeModal from "./CreateTypeModal/CreateTypeModal"
import CopyTypeModal from "./CopyTypeModal/CopyTypeModal"
import TypesTable from "./TypesTable/TypesTable"
import { mapActions, mapMutations, mapGetters } from "vuex"
import AttributesTable from "./AttributesTable/AttributesTable"
import CreateAttributeModal from "./CreateAttributeModal/CreateAttributeModal"
import ConfirmRemoveAttributeModal from "./ConfirmRemoveAttributeModal/ConfirmRemoveAttributeModal"
import ProductsModal from "./ProductsModal/ProductsModal"
import Select from "components/Select/Select"

export default {
  metaInfo: {
    title: "MS: Types"
  },
  name: "ProductCatalogTypes",
  components: {
    ProductsModal,
    ConfirmRemoveAttributeModal,
    CreateAttributeModal,
    AttributesTable,
    TypesTable,
    CreateTypeModal,
    CopyTypeModal,
    Select
  },
  methods: {
    onSelectLang(value) {
      if (value === this.PC_selectedLanguage) return
      let page = "types"
      this.PC_selectLanguage({ value, page })
    },
    ...mapMutations("ProductCatalog", {
      PT_resetProductTypeValues: "PT_resetProductTypeValues"
    }),
    ...mapActions("ProductCatalog", {
      PC_searchTypes: "PC_searchTypes",
      PC_selectLanguage: "PC_selectLanguage"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PT_typeForAttribute: "PT_typeForAttribute",
      PC_languages: "PC_languages",
      PC_selectedLanguage: "PC_selectedLanguage"
    })
  },
  beforeRouteLeave(to, from, next) {
    this.PT_resetProductTypeValues()
    next()
  }
}
</script>
<style lang="scss">
.product-catalog-wrapper .product-catalog__types .lang-select {
  position: absolute;
  right: 15px;
  top: 0px;
}
</style>
