import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = () => ({
  PB_openSearchTypesModal: false,
  PB_typesWithGivenBrand: [],
  PB_selectedBrand: ""
})

const getters = {
  PB_openSearchTypesModal: (state) => state.PB_openSearchTypesModal,
  PB_typesWithGivenBrand: (state) => state.PB_typesWithGivenBrand,
  PB_selectedBrand: (state) => state.PB_selectedBrand
}

const mutations = {
  PB_openSearchTypesModal(state, value) {
    state.PB_openSearchTypesModal = value
  },
  PB_typesWithGivenBrand(state, value) {
    state.PB_typesWithGivenBrand = value
  },
  PB_selectedBrand(state, value) {
    state.PB_selectedBrand = value
  }
}

const actions = {
  PB_openSearchTypesModal({ commit, dispatch }, brand) {
    // reset values
    commit("PB_typesWithGivenBrand", [])
    commit("PB_selectedBrand", brand)
    // send request
    dispatch("PB_searchTypesWithGivenBrand", brand)
  },
  PB_searchTypesWithGivenBrand({ commit, getters }, brand) {
    // show loader
    commit("PC_showLoader", true)

    let params = {
      brand_name: brand.name
    }

    API_MODERATION.get(`${apiUrls.searchTypeByBrand}`, { params })
      .then((r) => r.data.message.product_types)
      .then((types) => {
        commit("PB_typesWithGivenBrand", types)

        // open modal
        commit("PB_openSearchTypesModal", true)

        // hide loader
        commit("PC_showLoader", false)
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
