<template>
  <a-modal
    :visible="visible"
    :title="$t('imageEditing')"
    width="80%"
    @ok="() => false"
    @cancel="handleClose"
  >
    <div style="display: flex; align-items: center; margin-bottom: 15px">
      <Button
        @click="cropImage"
        :disabled="!enableCrop"
        theme="success"
        style="margin-right: 5px"
      >
        {{ $t("crop") }}&nbsp;&nbsp;<a-icon type="scissor" />
      </Button>
      <Button
        @click="rotate"
        theme="primary"
      >
        {{ $t("rotate") }}&nbsp;&nbsp;<a-icon type="sync" />
      </Button>
      <div style="margin-left: auto">
        <div>Width: {{ width }}px</div>
        <div>Height: {{ height }}px</div>
      </div>
    </div>
    <div style="display: flex">
      <div
        v-if="imgSrc"
        class="cropper-wrap margin-top-10"
        style="width: 70%"
      >
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          :view-mode="1"
          :initialAspectRatio="1"
          :background="true"
          :rotatable="true"
          :img-style="{ width: '100%' }"
          :crop="showDetails"
        />
      </div>
      <div
        class="margin-top-10"
        style="margin-left: 20px; width: 30%"
      >
        <div v-if="cropImg">
          <h3>{{ $t("preview") }}:</h3>
          <img
            :src="cropImg"
            style="max-width: 100%; height: auto; border: 1px solid #eee"
          />
        </div>
      </div>
    </div>
    <span slot="footer">
      <Button
        color="primary"
        @click="onSubmit"
        :disabled="!enableButton"
        :loading="processing"
        >{{ $t("save") }}</Button
      >
    </span>
  </a-modal>
</template>
<script>
import Button from "components/Button"
import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"
import { notification } from "ant-design-vue"
import { API_MODERATION } from "@/utils/HttpUtils"
import { updateProductRu } from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "EditProductImagesCrop",
  components: {
    VueCropper,
    Button
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    imgName: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cropImg: "",
      width: 0,
      height: 0,
      processing: false
    }
  },
  computed: {
    enableButton() {
      let show = false
      if (this.cropImg) {
        show = true
      }
      return show
    },
    enableCrop() {
      return Number(this.width) > 50 && Number(this.height) > 50
    }
  },
  methods: {
    handleClose() {
      this.$emit("onClose")
    },
    showDetails(imageInfo) {
      this.width = Math.round(imageInfo.detail.width)
      this.height = Math.round(imageInfo.detail.height)
    },
    async initCropper() {
      fetch(this.imgSrc, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        cache: "force-cache",
        redirect: "follow"
      })
        .then((response) => {
          return response.blob()
        })
        .then((blob) => {
          var fr = new FileReader()
          const self = this
          fr.onload = function (e) {
            self.imgSrc = this.result
            if (self.$refs.cropper) {
              self.$refs.cropper.replace(this.result)
            }
          }
          fr.readAsDataURL(blob)
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error
          })
        })
    },
    async onSubmit() {
      this.processing = true
      const name = this.formatName()

      this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const formData = new FormData()

        formData.append(name, blob)

        try {
          const results = await API_MODERATION.post("/product-catalog/moderation/images", formData)

          const updatedImgIndex = this.product.images.findIndex(
            (item) => item.filename === this.imgName
          )
          const updatedImages = this.product.images.map((item, index) => {
            return index === updatedImgIndex
              ? {
                  ...item,
                  filename: results.data.message[0].filename
                }
              : {
                  ...item
                }
          })

          const data = {
            code: this.product.code,
            product: {
              images: updatedImages
            }
          }

          this.$emit("onUpdateImages", updatedImages)

          try {
            await updateProductRu(data)

            this.$notification.success({
              message: this.$t("imageUpdated")
            })
          } catch (error) {
            notifyResponseError({ error })
          }
        } catch (error) {
          notifyResponseError({
            message: this.$t("imageEditingFailed"),
            error
          })
        } finally {
          this.processing = false
          this.$emit("onClose")
        }
      })
    },
    formatName() {
      let name = this.imgName
      // keep only last part after slash
      const slashIndex = name.lastIndexOf("/")

      if (slashIndex) name = name.substring(slashIndex + 1)

      return name
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    rotate() {
      this.$refs.cropper.rotate(90)
    }
  }
}
</script>
<style lang="scss" scoped>
.crop-modal {
  .details {
    float: right;
  }
}
</style>
