import { computed } from "vue"
import i18n from "@/i18n.js"
export function useLocationComposables() {
  const localityTypes = computed(() => [
    {
      title: i18n.t("locationTypes.STATE"),
      value: "STATE"
    },
    {
      title: i18n.t("locationTypes.DISTRICT"),
      value: "DISTRICT"
    },
    {
      title: i18n.t("locationTypes.SPECIAL_CITY"),
      value: "SPECIAL_CITY"
    },
    {
      title: i18n.t("locationTypes.CITY"),
      value: "CITY"
    },
    {
      title: i18n.t("locationTypes.URBAN"),
      value: "URBAN"
    },
    {
      title: i18n.t("locationTypes.SETTLEMENT"),
      value: "SETTLEMENT"
    },
    {
      title: i18n.t("locationTypes.VILLAGE"),
      value: "VILLAGE"
    }
  ])

  return {
    localityTypes
  }
}
