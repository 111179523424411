<template>
  <div class="phone-number">
    <a-icon
      v-if="row.is_registered"
      type="check-circle"
      class="phone-number--confirmed"
    />
    <a-button
      type="link"
      class="phone-number__link"
      @click="handleCopyPhone()"
    >
      +{{ row.mobile_phone }}
    </a-button>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import { message } from "ant-design-vue"

const props = defineProps({
  row: {
    type: Object,
    required: true
  }
})

const handleCopyPhone = () => {
  navigator.clipboard.writeText(props.row.mobile_phone)

  message.info(i18n.t("copied"))
}
</script>

<style lang="scss" scoped>
.phone-number {
  display: flex;
  align-items: center;
  gap: 4px;

  height: 100%;

  &--confirmed {
    color: $green-color;
  }

  &__link {
    padding: 0;
  }
}
</style>
