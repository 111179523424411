export const dataValidator = (value) => {
  const count = typeof value.count === "number"
  const items = Array.isArray(value.items)

  return count && items
}

export const themeValidator = (value) => {
  const values = ["default", "odd"]

  return values.indexOf(value) !== -1
}

export const columnsValidator = (value) => {
  let error = false

  for (let i = 0; i < value.length; i += 1) {
    if (typeof value[i] !== "object") {
      error = true
      break
    }

    if (!!value[i].title && typeof value[i].title !== "string") {
      error = true
      break
    }

    if (!value[i].key || typeof value[i].key !== "string") {
      error = true
      break
    }

    if (!!value[i].width && typeof value[i].width !== "number") {
      error = true
      break
    }

    if (!!value[i].minWidth && typeof value[i].minWidth !== "number") {
      error = true
      break
    }

    if (!!value[i].maxWidth && typeof value[i].maxWidth !== "number") {
      error = true
      break
    }
  }

  return !error
}

export default {
  data: dataValidator,
  columns: columnsValidator,
  theme: themeValidator
}
