<template>
  <div>
    <a-skeleton :loading="isPending">
      <PageHeader
        :title="$t('directoryOfGoods')"
        :currentLanguage="languageForDataFetch"
        showLanguageSelect
        @onChangeLanguage="changeLanguage"
      >
        <template #filters>
          <a-input-search
            v-for="filter in searchFilters"
            v-model="filteredInfo[filter.key]"
            :placeholder="filter.label"
            :key="filter.key"
            allowClear
            @search="handleTableFilterChange()"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>

          <a-auto-complete
            v-model="filteredInfo.type"
            :placeholder="$t('type')"
            allowClear
            @search="onSearchType"
            @select="handleTableFilterChange"
            @blur="onTypeBlur"
            @change="onAutoCompleteChange"
          >
            <template #dataSource>
              <a-select-option
                v-for="item of fetchedTypes"
                :key="item.oid"
                :value="item.oid"
              >
                {{ item.full_name }}
              </a-select-option>
            </template>
          </a-auto-complete>

          <a-auto-complete
            v-model="filteredInfo.brand"
            :placeholder="$t('brand')"
            allowClear
            @search="onSearchBrand"
            @select="handleTableFilterChange"
            @blur="onBrandBlur"
            @change="onAutoCompleteChange"
          >
            <template #dataSource>
              <a-select-option
                v-for="item of fetchedBrands"
                :key="item.oid"
                :value="item.oid"
              >
                {{ item.name }}
              </a-select-option>
            </template>
          </a-auto-complete>

          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 3px"
            v-model="filteredInfo.created_at"
            @change="handleTableFilterChange()"
          />

          <a-select
            mode="tags"
            :options="statusFilters"
            v-model="filteredInfo.status"
            :placeholder="$t('status')"
            allowClear
            :maxTagCount="1"
            @change="handleTableFilterChange()"
          />

          <a-select
            v-model="filteredInfo.trust"
            :options="postModerationStatuses"
            :placeholder="$t('postModerationStatus')"
            allowClear
            @change="handleTableFilterChange()"
          />

          <a-select
            v-model="filteredInfo.origin"
            :options="productOriginOptions"
            :placeholder="$t('productOrigin')"
            allowClear
            @change="handleTableFilterChange()"
          />

          <a-input-search
            v-model="filteredInfo.public_title"
            allowClear
            :placeholder="$t('publicationTitle')"
            @search="handleTableFilterChange()"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </template>

        <template #actions>
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>

          <a-button
            type="primary"
            icon="plus"
            @click="handleOpenCreateModal()"
          >
            {{ $t("createProduct") }}
          </a-button>
        </template>
      </PageHeader>
    </a-skeleton>

    <div class="table-wrapper">
      <a-table
        :scroll="{ x: 1300 }"
        :columns="columns"
        :dataSource="dataSource"
        :loading="isFetching"
        :pagination="paginationInfo"
        rowKey="code"
        @change="onTableChange"
      >
        <template #sellerRenderer="record">
          <a-popover v-if="record.synthetic_meta?.xml">
            <a-tag color="green">XML</a-tag>

            <template #content>
              {{ record.synthetic_meta?.xml.map(({ seller_slug }) => seller_slug).join(", ") }}
            </template>
          </a-popover>

          <div v-if="record.origin === 'SELLER_MANUAL' && record.created_by">
            {{ record.created_by.name }}
          </div>
        </template>

        <template #photoRenderer="images">
          <TableRendererPhotoGallery :images="(images || []).map((img) => img.filename)" />
        </template>

        <template #localeRenderer="value, record">
          <TableLocaleRenderer
            :product-status="record.status"
            :locales="value"
            :mpcCode="record.code"
            @onClick="(locale) => handleOpenChangeLocalizationModal(record.code, locale)"
          />
        </template>

        <template #dateRenderer="text">
          <TableRendererDateTime :date="text" />
        </template>

        <template #statusRenderer="record">
          <div style="text-transform: uppercase">
            {{ record.status }}
          </div>

          <div class="product-quality">
            <a-tooltip
              v-if="record.trust === 'TRUSTED'"
              :title="$t('moderationTrustStatus.TRUSTED')"
            >
              <a-icon
                type="safety"
                class="trusted-status"
              />
            </a-tooltip>

            <a-tooltip
              v-if="record.quality_mark === 'GOOD'"
              :title="$t(`productQualityMark.GOOD`)"
            >
              <a-icon
                type="trophy"
                color="twoTone"
                class="good-quality"
              />
            </a-tooltip>
          </div>
        </template>

        <template #actionRenderer="record">
          <TableActions
            @onClickEdit="() => handleOpenEditModal(record.code)"
            @onClickImage="() => handleOpenEditModal(record.code, 'gallery')"
            @onClickCopy="() => handleDuplicateProduct(record.code)"
          />
        </template>
      </a-table>
    </div>

    <EditProductLocalizationModal
      :visible="localizationModalVisible"
      :mpcCode="localizationModalVisible ? editableMPC : ''"
      :locale="editableLocale"
      @onClose="handleCloseChangeLocalizationModal"
    />

    <EditProductModal
      :visible="editModalVisible"
      :mpcCode="editModalVisible ? editableMPC : ''"
      :defaultTabPosition="defaultTabPosition"
      @onClose="handleCloseEditProductModal"
      @createProductSuccess="onCreateProduct"
      @updateProductSuccess="onUpdateProduct"
    />
  </div>
</template>

<script setup>
import { ref, computed, provide, onMounted, nextTick } from "vue"
import i18n from "@/i18n"
import { MODERATION_STATUS_FILTERS } from "@/constants/moderation"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery"
import PageHeader from "@/ant-components/PageHeader"
import TableLocaleRenderer from "./_components/TableLocaleRenderer"
import EditProductLocalizationModal from "./_components/EditProductLocalizationModal"
import EditProductModal from "./_components/EditProductModal"
import TableActions from "./_components/TableActions"
import {
  getCategoriesTree,
  fetchProductBrands,
  fetchProductTypes,
  fetchProducts,
  duplicateProduct,
  formatCategoriesTree
} from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import useAntTableQuery from "@/composables/useAntTableQuery"
import useModerationProductsColumns from "./useModerationProductsColumns"

const defaultSorter = {
  columnKey: "timestamp",
  order: "ascend"
}

const defaultFilters = {
  type: "",
  brand: ""
}

const statusFilters = MODERATION_STATUS_FILTERS

const languageForDataFetch = ref("ru")
const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchProducts,
  requestParams: {
    changeRouterQuery: true,
    language: computed(() => languageForDataFetch.value)
  }
})

const { columns } = useModerationProductsColumns({
  sortedInfo: computed(() => sortedInfo.value),
  languageForDataFetch: computed(() => languageForDataFetch.value)
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const isPending = ref(true)
const defaultTabPosition = ref("common")

const categories = ref([])
const fetchedBrands = ref([])
const fetchedTypes = ref([])
const debouncedApiCall = ref(null)

const editableMPC = ref("")
const editableLocale = ref("")
const editModalVisible = ref(false)
const localizationModalVisible = ref(false)

const searchFilters = computed(() => [
  {
    key: "code",
    label: "MPC-code"
  },
  {
    key: "p_code",
    label: i18n.t("p_code")
  },
  {
    key: "created_by_name",
    label: i18n.t("seller")
  },
  {
    key: "meta_xml_seller_slug",
    label: `${i18n.t("seller")} (XML)`
  }
])

const postModerationStatuses = computed(() => [
  {
    key: "TRUSTED",
    label: i18n.t("moderationTrustStatus.TRUSTED")
  },
  {
    key: "UNTRUSTED",
    label: i18n.t("moderationTrustStatus.UNTRUSTED")
  }
])

const productOriginOptions = computed(() => [
  {
    key: "SELLER_MANUAL",
    label: i18n.t("productOriginOptions.SELLER_MANUAL")
  },
  {
    key: "SYNTHETIC_DEFAULT",
    label: i18n.t("productOriginOptions.SYNTHETIC_DEFAULT")
  }
])

const getMeta = async () => {
  try {
    const { data } = await getCategoriesTree()

    categories.value = formatCategoriesTree(data.results)
  } catch (error) {
    if (error?.response?.status === 401) return
    setTimeout(getMeta, 2500)
  }
}

const changeLanguage = ({ language }) => {
  window.localStorage.setItem("moderationLanguageForDataFetch", language)

  languageForDataFetch.value = language
  onTableChange()
}

const onAutoCompleteChange = (value) => {
  if (value) return
  handleTableFilterChange()
}

const onCreateProduct = (data) => {
  editableMPC.value = data.code
  onTableChange()
}

const onUpdateProduct = (data) => {
  if (data.all) {
    updateTableDataRecord({ payload: data.all, identifier: "code" })
  }
  if (data[languageForDataFetch.value]) {
    updateTableDataRecord({ payload: data[languageForDataFetch.value], identifier: "code" })
  }
}

const onSearchBrand = async (value) => {
  clearTimeout(debouncedApiCall.value)

  if (!value) {
    fetchedBrands.value = []
    return
  }

  debouncedApiCall.value = setTimeout(async () => {
    try {
      const { data } = await fetchProductBrands(value)
      fetchedBrands.value = data.results
    } catch (error) {
      notifyResponseError({ error })
    } finally {
      debouncedApiCall.value = null
    }
  }, 500)
}

const onSearchType = async (value) => {
  clearTimeout(debouncedApiCall.value)

  if (!value) {
    fetchedTypes.value = []
    return
  }

  debouncedApiCall.value = setTimeout(async () => {
    try {
      const { data } = await fetchProductTypes(value)
      fetchedTypes.value = data.results
    } catch (error) {
      notifyResponseError({ error })
    } finally {
      debouncedApiCall.value = null
    }
  }, 500)
}

const handleDuplicateProduct = async (mpcCode) => {
  try {
    await duplicateProduct(mpcCode)

    onTableChange()
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("productDublicateError") })
  }
}

const onBrandBlur = () => {
  if (
    !fetchedBrands.value.find(({ oid }) => oid === filteredInfo.value.brand) &&
    filteredInfo.value.brand
  ) {
    filteredInfo.value.brand = undefined
    handleTableFilterChange()
  }
}

const onTypeBlur = () => {
  if (
    !fetchedTypes.value.find(({ oid }) => oid === filteredInfo.value.type) &&
    filteredInfo.value.type
  ) {
    filteredInfo.value.type = undefined
    handleTableFilterChange()
  }
}

const handleOpenChangeLocalizationModal = (mpcCode, locale) => {
  editableMPC.value = mpcCode
  editableLocale.value = locale
  localizationModalVisible.value = true
}
const handleCloseChangeLocalizationModal = () => {
  editableMPC.value = ""
  editableLocale.value = ""
  localizationModalVisible.value = false
}

const handleOpenCreateModal = () => {
  defaultTabPosition.value = "common"
  editModalVisible.value = true
}

const handleOpenEditModal = (mpcCode, tabKey = "common") => {
  if (mpcCode) {
    editableMPC.value = mpcCode
  }
  defaultTabPosition.value = tabKey
  editModalVisible.value = true
}
const handleCloseEditProductModal = () => {
  defaultTabPosition.value = "common"
  editableMPC.value = ""
  editableLocale.value = ""
  editModalVisible.value = false
}

onMounted(() => {
  const langInStorage = window.localStorage.getItem("moderationLanguageForDataFetch")
  languageForDataFetch.value = langInStorage || "ru"

  getMeta()
  setupTable({ defaultSorter, defaultFilters })

  filteredInfo.value.type = ""
  filteredInfo.value.brand = ""

  isPending.value = false

  nextTick(() => {
    onTableChange()
  })
})

provide(
  "categories",
  computed(() => categories.value)
)
</script>

<style lang="scss" scoped>
.trusted-status {
  color: $green-color;
}

.good-quality {
  color: $dark-yellow-color;
}

.product-quality {
  display: flex;
  gap: 8px;

  font-size: 16px;
}
</style>
