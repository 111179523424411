<template>
  <a-popover
    placement="left"
    trigger="click"
    v-if="event.events.length"
  >
    <template #content>
      <div
        class="event-describe"
        :class="{ 'event-describe--wide': showBigPopUp }"
      >
        <a-table
          :dataSource="event.events"
          :columns="columns"
          size="small"
          :scroll="{ y: 350 }"
          :pagination="false"
          :rowKey="(record, index) => record.time + index"
        >
          <template #iconRenderer="icon, _, __, column">
            <a-icon
              v-if="
                (column.key === 'view_icon' && icon === 'view') ||
                (column.key === 'action_icon' && icon !== 'view')
              "
              :type="eventIcons[icon]"
              class="event-describe__icon"
            />
          </template>

          <template #descriptionRenderer="_, record, __, column">
            <div
              v-if="
                (column.key === 'view_description' && record.event_type === 'view') ||
                (column.key === 'action_description' && record.event_type !== 'view')
              "
            >
              {{ getTooltip(record) }}
            </div>
          </template>

          <template #deltaRenderer="value">
            {{ getTimeFromStart(value) }}
          </template>
        </a-table>
      </div>
    </template>

    <div
      class="event"
      :class="getEventClass(event)"
    />
  </a-popover>

  <div
    v-else
    class="event"
    :class="getEventClass(event)"
  />
</template>

<script>
import i18n from "@/i18n"
import useSessionsComposables from "../useSessionsComposables.js"
import getFormatedSum from "@/utils/getFormatedSum.js"
import { computed } from "vue"

export default {
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props) {
    const { eventIcons, eventsWithoutElement, deviceIcons } = useSessionsComposables()

    const hasActionEvents = computed(() =>
      props.event.events.some(({ event_type }) => event_type !== "view")
    )

    const hasViewEvents = computed(() =>
      props.event.events.some(({ event_type }) => event_type === "view")
    )

    const showBigPopUp = computed(() => hasActionEvents.value && hasViewEvents.value)

    const getTooltip = (action) => {
      const actionName = i18n.t(`userEvents.${action.event_type}`)
      let actionType = ""
      if (eventsWithoutElement.includes(action.event_type)) {
        actionType = action.url.replace(/(\/ru)|(\?\?*.+)/gm, "")
      } else {
        actionType = action.element?.replace(/(ms\[)|(ms:m\[)|\]/gm, "")
      }

      if (actionType === "product-card-short" && action.event_type === "click") {
        actionType += " \n" + action.url.match(/CS[0-9]{7,8}/)
      }

      return `${actionName}: ${actionType}`
    }

    const getEventClass = (event) => {
      if (event.has_action) return "event--primary"

      if (event.events_count) return "event--secondary"

      return ""
    }

    const columns = computed(() => [
      {
        key: "action_icon",
        dataIndex: "event_type",
        scopedSlots: {
          customRender: "iconRenderer"
        },
        width: hasActionEvents.value ? 50 : 5
      },
      {
        key: "action_description",
        title: hasActionEvents.value ? "Description" : "",
        dataIndex: "url",
        scopedSlots: {
          customRender: "descriptionRenderer"
        },
        width: hasActionEvents.value ? 150 : 5
      },
      {
        key: "delta",
        dataIndex: "delta",
        title: "Time",
        scopedSlots: {
          customRender: "deltaRenderer"
        },
        align: "center",
        width: 50
      },
      {
        key: "view_description",
        title: hasViewEvents.value ? "View description" : "",
        dataIndex: "url",
        scopedSlots: {
          customRender: "descriptionRenderer"
        },
        width: hasViewEvents.value ? 150 : 5
      },
      {
        key: "view_icon",
        dataIndex: "event_type",
        scopedSlots: {
          customRender: "iconRenderer"
        },
        width: hasViewEvents.value ? 50 : 5
      }
    ])

    const getTimeFromStart = (seconds) => {
      return new Date(seconds * 1000)
        .toLocaleTimeString("en-GB", {
          timeZone: "Etc/UTC",
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit"
        })
        .replace(/00:/gm, "")
    }
    const getLineClass = () => {
      return ""
    }

    return {
      eventIcons,
      deviceIcons,

      columns,
      showBigPopUp,

      getTooltip,
      getFormatedSum,
      getEventClass,
      getTimeFromStart,
      getLineClass
    }
  }
}
</script>

<style lang="scss" scoped>
.event-describe {
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;

  width: 400px;
  max-height: 400px;

  padding-right: 16px;

  &--wide {
    width: 800px;
  }

  &__icon {
    font-size: 20px;

    color: $primary-color;
    width: 20px;
  }
}
.event {
  width: 24px;
  height: 24px;
  border: 1px solid $border-color;
  border-radius: 2px;
  margin-left: 8px;

  &--primary {
    background: $primary-color;
  }

  &--secondary {
    background: $border-color;
  }
}
</style>
