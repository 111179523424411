import Vue from "vue"
import logger from "../utils/logger"

export default {
  CHAT_FIND_REQUEST(state) {
    state.chatFindRequest = true
  },
  CHAT_FIND_RESPONSE(state, { hasNextPage }) {
    state.chatFindHasNextPage = hasNextPage
    state.chatFindRequest = false
  },
  SET_TEXTAREA_FOCUS_FLAG(state, value) {
    state.textareaIsFocused = value
  },

  ON_AUTHORIZATION_SUCCESS(state, data) {
    state.auth = data.body.authorization
  },

  SET_USER(state, value) {
    // Is called from plugin
    state.user = value
  },

  SET_USER_UUID(state, value) {
    state.user.uuid = value
  },

  SET_USER_NAME(state, value) {
    state.user.name = value
  },

  SET_USER_EMAIL(state, value) {
    state.user.email = value
  },

  SET_LOCALE(state, value) {
    state.locale = value
  },

  SELECT_CHAT(state, id) {
    state.currentChatId = id
  },

  SET_FILTER_KEY(state, value) {
    state.filterKey = value
  },

  MESSAGE_REMOVED(state, removedMessage) {
    const chatUuid = removedMessage.chat_uuid
    const messageUuid = removedMessage.uuid

    const chatWhereMessageRemoved = state.chats.find((chat) => chat.uuid === chatUuid)
    const removedMessageIndex = chatWhereMessageRemoved
      ? chatWhereMessageRemoved.messages.findIndex((message) => message.uuid === messageUuid)
      : -1

    if (removedMessageIndex !== -1) {
      const oldMessage = chatWhereMessageRemoved.messages[removedMessageIndex]
      const assignedMessage = Object.assign({}, oldMessage, removedMessage)

      Vue.set(chatWhereMessageRemoved.messages, removedMessageIndex, assignedMessage)
    }
  },

  MESSAGE_EDITED(state, ediredMessage) {
    const chatUuid = ediredMessage.chat_uuid
    const messageUuid = ediredMessage.uuid

    const chatWhereMessageEdited = state.chats.find((chat) => chat.uuid === chatUuid)
    const ediredMessageIndex = chatWhereMessageEdited
      ? chatWhereMessageEdited.messages.findIndex((message) => message.uuid === messageUuid)
      : -1

    if (ediredMessageIndex !== -1) {
      const oldMessage = chatWhereMessageEdited.messages[ediredMessageIndex]
      const assignedMessage = Object.assign({}, oldMessage, ediredMessage)

      Vue.set(chatWhereMessageEdited.messages, ediredMessageIndex, assignedMessage)
    }
  },

  ADD_NEW_MESSAGE(state, { message, uuid }) {
    const chat = state.chats.find((chat) => chat.uuid === uuid)
    chat.messages.push(message)
    const clone = JSON.parse(JSON.stringify(state.chats))
    state.chats = clone
  },

  UPDATE_LAST_MESSAGE(state, { message, uuid }) {
    const chat = state.chats.find((chat) => chat.uuid === uuid)
    chat.last_message = message
    const clone = JSON.parse(JSON.stringify(state.chats))
    state.chats = clone
  },

  SOCKET_CONNECTING(state, bool) {
    state.socket.connecting = bool
  },

  SOCKET_ONOPEN(state) {
    // Is called from plugin
    logger.info("Connection established")

    state.socket.isConnected = true
    state.socket.connecting = false
  },

  SOCKET_ONCLOSE(state, event) {
    // Is called from plugin
    logger.info("Code: " + event.code + `${event.reason ? `. Reason: ${event.reason}` : ""}`)

    if (event.wasClean) {
      logger.info("Clean connection close")
    } else {
      logger.info("Abrupt of connection")
    }

    state.socket.isConnected = false
  },

  SOCKET_ONERROR(state, error) {
    // Is called from plugin
    if (error.message) {
      logger.error(error.message)
    }
  },

  SOCKET_ONMESSAGE(state, data) {
    // Is called from plugin
    state.socket.message = data
  },

  SET_UNREAD_MESSAGES(state, count) {
    state.unreadMessages = count
  },

  INCREMENT_UNREAD_MESSAGES(state, value) {
    state.unreadMessages += value
  },

  INCREMENT_CHAT_UNREAD(state, { id, value }) {
    const chat = state.chats.find((item) => item.uuid === id)
    if (chat) {
      chat.unread_count += value
    }
  },

  RESET_CHAT_UNREAD(state, id) {
    const chat = state.chats.find((item) => item.uuid === id)
    if (chat) {
      chat.unread_count = 0
    }
  },

  CHANGE_MESSAGES_STATUS(state, id) {
    const chat = state.chats.find((item) => item.uuid === id)
    if (chat) {
      chat.messages.map((item) => {
        item.status = "read"
        return item
      })
    }
  },

  SET_CHATS(state, data) {
    const currentChat = state.chats.find((c) => c.uuid === state.currentChatId)
    const currentChatMessages = currentChat ? currentChat.messages : []

    const chats = data.body.results.map((item) => {
      let messages = []

      if (item.uuid === state.currentChatId) {
        messages = currentChatMessages
      }

      return {
        ...item,
        id: item.uuid,
        user: {
          online: item.owner.is_online,
          name: item.owner.username
        },
        messages,
        offset: 0
      }
    })

    if (data.body.offset) {
      chats.forEach((chat) => state.chats.push(chat))
    } else {
      state.chats = chats
    }
    // Update chat offset
    state.chatsOffset = state.chatsOffset + state.chatsLimit
  },

  RESET_CHATS(state) {
    // Is called from SOCKET_ONOPEN_ACTION, which is called from plugin
    state.chats = []
  },

  INCREMENT_CHATS_OFFSET(state, value) {
    state.chatsOffset += value
  },

  BOTTOM_MESSAGES_LOADER(state, value) {
    state.bottomMessagesLoader = value
  },

  TOP_MESSAGES_LOADER(state, value) {
    state.topMessagesLoader = value
  },

  SET_MESSAGES(state, { data, id }) {
    if (!data.body.results.length) return
    const targetChat = state.chats.find((item) => {
      return item.uuid === id
    })
    const messages = data.body.results.map((item) => {
      const files = []
      const images = []
      if (item.attachments.length) {
        item.attachments.forEach((item) => {
          if (item.type === "image") images.push(item)
          else files.push(item)
        })
      }

      return {
        ...item,
        date: item.created_at,
        self: state.user.uuid === item.author.uuid,
        content: item.body,
        files,
        images
      }
    })

    if (!targetChat) return

    if (data.body.offset) {
      // messages.reverse().forEach(item => targetChat.messages.unshift(item))
      targetChat.messages = [...messages, ...targetChat.messages]
    } else {
      targetChat.messages = messages
    }
    // Update chat offset
    targetChat.offset = data.body.offset
  },

  INCREMENT_CHAT_OFFSET(state, { uuid, value }) {
    const currentChat = state.chats.find((item) => {
      return item.uuid === uuid
    })

    if (currentChat) {
      currentChat.offset += value
    }
  },

  ADD_CHAT(state, chat) {
    chat.user = {
      online: chat.owner.is_online,
      name: chat.owner.username,
      offset: 0
    }
    if (!chat.messages) chat.messages = []
    if (chat.messages) {
      chat.last_message = chat.messages[chat.messages.length - 1]
    }
    chat.offset = 0
    state.chats.unshift(chat)
    const clone = JSON.parse(JSON.stringify(state.chats))
    state.chats = clone
  },

  DELETE_CHAT(state, index) {
    state.chats.splice(index, 1)
  },

  LIFT_CHAT(state, index) {
    if (index === 0) return
    const removedArray = state.chats.splice(index, 1)
    state.chats.unshift(removedArray[0])
  },

  SET_CHATS_OFFSET(state, value) {
    state.chatsOffset = value
  },

  UPDATE_ONLINE_STATUS(state, { uuid, status }) {
    state.chats.forEach((chat) => {
      if (chat.opponent_uuid && chat.opponent_uuid === uuid) {
        chat.online_status = status
      }
    })
    const clone = JSON.parse(JSON.stringify(state.chats))
    state.chats = clone
  },

  SET_GLOBAL_QUERY(state, value) {
    state.globalQuery = value
  },

  SET_GET_TOKEN(state, value) {
    state.getToken = value
  },

  SHOW_TYPING(state, value) {
    state.is_typing = value
  },

  SET_SHOW_HINT(state, value) {
    state.showHint = value
  },

  SET_HINT_MESSAGE(state, value) {
    state.hintMessage = value
  },

  SET_SELLERS_PIT(state, value) {
    state.sellersPit = value
  },

  PUSH_SELLERS_PIT(state, value) {
    if (state.sellersPit.includes(value)) return
    state.sellersPit.push(value)
  },

  REMOVE_FROM_SELLERS_PIT(state, value) {
    const index = state.sellersPit.indexOf(value)
    if (index != -1) state.sellersPit.splice(index, 1)
  },

  SET_XHR_URL(state, value) {
    state.xhrUrl = value
  },

  SET_CDN_URL(state, value) {
    state.cdnUrl = value
  },

  SET_MP_URL(state, value) {
    state.mpUrl = value
  },

  SET_LATEST_INIT_REQUEST(state, value) {
    state.latestInitRequest = value
    state.latestInitRequest = Object.assign({}, state.latestInitRequest)
  },

  RESET_ALL(state) {
    state.user = {
      name: "",
      role: "",
      uuid: ""
    }
    state.auth = ""
    state.filterKey = ""
    state.chatsLimit = 15
    state.chatsOffset = 0
    state.messagesLimit = 20
    state.unreadMessages = null
    state.chats = []
    state.currentChatId = null
    state.topMessagesLoader = false
    state.bottomMessagesLoader = false
    state.chatFindRequest = false
    state.chatFindHasNextPage = true
    state.socket = {
      isConnected: false,
      message: "",
      connecting: false
    }
    state.globalQuery = []
    state.is_typing = false
    state.showHint = false
    state.hintMessage = ""
    state.sellersPit = []
    state.latestInitRequest = null
    state.latestInitRequest = {
      type: "",
      payload: null
    }
    state.textareaIsFocused = false
  }
}
