import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  PV_showSearchTypesModal: false,
  PV_atlsWithAttribute: false
})

const getters = {
  PV_showSearchTypesModal: (state) => state.PV_showSearchTypesModal,
  PV_atlsWithAttribute: (state) => state.PV_atlsWithAttribute
}

const mutations = {
  PV_showSearchTypesModal(state, value) {
    state.PV_showSearchTypesModal = value
  },
  PV_atlsWithAttribute(state, value) {
    state.PV_atlsWithAttribute = value
  }
}

const actions = {
  PV_searchTypesWithAttribute({ commit, getters }) {
    // show loader
    commit("PC_showLoader", true)

    let sendData = buildMagicSearch({
      resource: "ATL",
      field: "attribute",
      value: getters.PV_selectedAttribute.code,
      sort: null,
      limit: null,
      offset: null
    })

    // start request
    API_MODERATION.post(`${apiUrls.searchMagic}`, sendData)
      .then((r) => r.data.message.data)
      .then((atls) => {
        // set types
        commit("PV_atlsWithAttribute", atls)
        // hide loader
        commit("PC_showLoader", false)
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        commit("handleError", e)
      })
  },
  PV_resetSearchTypesValues({ commit }) {}
}

export default {
  state,
  getters,
  mutations,
  actions
}
