<template>
  <div>
    <a-button
      type="primary"
      icon="plus"
      @click="openServiceModal({})"
    >
      {{ $t("add") }}
    </a-button>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      rowKey="id"
      :pagination="paginationInfo"
      size="middle"
      :scroll="{ x: true }"
      style="margin-top: 16px"
      @change="onTableChange"
    >
      <template #active="value">
        <a-icon
          :type="value ? 'check-circle' : 'close-circle'"
          class="service-icon"
          :class="[value ? 'service-icon--green' : 'service-icon--red']"
        />
      </template>

      <template #actions="record">
        <a-button
          shape="circle"
          type="dashed"
          icon="edit"
          @click="openServiceModal(record)"
        />
      </template>
    </a-table>

    <DeliveryServiceModal
      :showModal="showModal"
      :prototype="prototype"
      @close="closeServiceModal"
      @updateService="onServiceUpdated"
      @createService="onServiceCreated"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import DeliveryServiceModal from "./components/DeliveryServiceModal.vue"

import { fetchDeliveryServices } from "@/modules/MPAdmin/services/generalSettingService/deliveryService.js"

import useDSColumns from "./useDSColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"

const columns = useDSColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,

  setupTable,
  fetchTableInfo,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchDeliveryServices
})

const prototype = ref()
const showModal = ref(false)

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const openServiceModal = (service) => {
  prototype.value = service
  showModal.value = true
}

const closeServiceModal = () => {
  showModal.value = false
  prototype.value = undefined
}

const onServiceCreated = () => {
  closeServiceModal()
  onTableChange()
}

const onServiceUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeServiceModal()
}

onMounted(() => {
  setupTable({ defaultPagination: { size: "default" } })
  onTableChange()
})
</script>

<style lang="scss" scoped>
.service-icon {
  width: 100%;
  font-size: 20px;

  &--green {
    color: $green-color;
  }

  &--red {
    color: $red-color;
  }
}
</style>
