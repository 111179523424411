<template>
  <button
    class="button"
    :class="buttonClasses"
    v-on="$listeners"
    :disabled="disabled"
  >
    <span
      @click.stop
      v-show="loading"
      class="loader-container"
    >
      <Loader size="small" />
    </span>

    <span class="btn-lable">
      <span
        v-if="icon"
        :class="icon"
        class="icon"
      />

      <span>
        <slot />
      </span>
    </span>
  </button>
</template>

<script>
import validator from "./validator"
import Loader from "components/Loader"

export default {
  components: {
    Loader
  },

  props: {
    theme: {
      type: String
    },

    color: {
      type: String
    },

    size: {
      type: String,
      default: "medium",
      validator: validator.size
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: ""
    },

    className: {
      type: String,
      default: ""
    },

    display: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    outline: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClasses() {
      return [
        this.className,
        this.getTheme,
        this.size,
        this.loading ? "hide-text" : "",
        this.display ? "display" : "",
        this.active ? "active" : "",
        this.outline ? "outline" : ""
      ]
    },
    getTheme() {
      if (this.color && validator.type(this.color)) {
        return this.color
      } else if (this.theme && validator.type(this.theme)) {
        return this.theme
      } else {
        return "primary"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  position: relative;
  vertical-align: middle;
  font-family: "Roboto";
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  font-size: 16px;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box !important;
  padding: 0 15px;
  font-size: 13px;
  display: inline-block;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-in-out;

  &.display {
    width: 100% !important;
  }

  & .icon {
    font-size: 14px;
    margin-right: 3px;
    margin-top: -2px;
  }

  &.outline {
    background-color: transparent !important;
  }

  &.primary {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;

    &:hover {
      background-color: $primary-hover-color;
      border: 1px solid $primary-hover-color;
    }

    &:focus {
      box-shadow: $focus-shadow;
    }
  }

  &.outline.primary {
    color: $primary-color;
  }

  &.secondary {
    background-color: $dark;
    color: #fff;
    border: 1px solid $dark;

    &:hover {
      background-color: $dark-hover-color;
      border: 1px solid $dark-hover-color;
    }

    &:focus {
      box-shadow: $secondary-focus-shadow;
    }
  }

  &.outline.secondary {
    color: $dark;
  }

  &.success {
    background-color: $success-color;
    color: #fff;
    border: 1px solid $success-color;

    &:hover {
      background-color: $success-hover-color;
      border: 1px solid $success-hover-color;
    }

    &:focus {
      box-shadow: $success-focus-shadow;
    }
  }

  &.outline.success {
    color: $success-color;
  }

  &.danger {
    background-color: $danger-color;
    border: 1px solid $danger-color;
    color: #fff;

    &:hover {
      background-color: $danger-hover-color;
      border: 1px solid $danger-hover-color;
    }

    &:focus {
      box-shadow: $danger-focus-shadow;
    }
  }

  &.outline.danger {
    color: $danger-color;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.small {
    padding: 0 12px;
    height: 24px !important;
    line-height: 24px !important;
    font-size: 12px;
  }

  & .btn-lable {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.small .icon {
    line-height: 24px;
    height: 24px;
  }

  &.large .icon {
    line-height: 38px;
    height: 38px;
  }

  &.large {
    padding: 0 20px;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
  }

  &.hide-text {
    color: transparent;
  }

  & .loader-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      position: relative !important;
    }
  }
}
</style>
