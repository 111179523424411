<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PT_showCreateTypeModal"
      :title="'Добавление нового типа'"
    >
      <div>
        <div>
          <label
            ><span>Название типа (полное)<span class="required-field">*</span></span></label
          >
          <Input
            size="medium"
            :value="fullName"
            @input="onInputName"
          />
          <ValidationErrors
            :lengthError="!validNameLength"
            :uniqueError="!PT_checkUniqueTypeNameGet"
          />
        </div>
        <div class="margin-top-10">
          <label><span>Название типа RU (короткая форма)</span></label>
          <Input
            size="medium"
            :value="shortNameRu"
            @input="onInputShortNameRu"
          />
          <ValidationErrors :lengthError="!validShortNameLengthRu" />
        </div>
        <div class="margin-top-10">
          <label><span>Название типа UK (короткая форма)</span></label>
          <Input
            size="medium"
            :value="shortNameUk"
            @input="onInputShortNameUk"
          />
          <ValidationErrors :lengthError="!validShortNameLengthUk" />
        </div>
        <div class="margin-top-10">
          <label><span>Название типа EN (короткая форма)</span></label>
          <Input
            size="medium"
            :value="shortNameEn"
            @input="onInputShortNameEn"
          />
          <ValidationErrors :lengthError="!validShortNameLengthEn" />
        </div>
        <div class="margin-top-10">
          <label><span>Список возможный синонимов (через запятую)</span></label>
          <Input
            size="medium"
            :value="synonyms"
            @input="onInputSynonyms"
          />
          <ValidationErrors
            :lengthError="synonymsLengthError"
            :quantityError="showNumberOfValuesError"
            :uniqueError="!PT_checkUniqueTypeSynonymGet"
            :repetativeValuesError="showRepetativeValuesError"
          />
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewType"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import validArrayItemsCount from "utils/validations/validArrayItemsCount"
import validArrayItemsMaxLength from "utils/validations/validArrayItemsMaxLength"
import validArrayRepetativeItems from "utils/validations/validArrayRepetativeItems"

export default {
  name: "CreateTypeModal",
  components: {
    Button,
    Modal,
    Input,
    ValidationErrors
  },
  created() {},
  data() {
    return {
      timeoutName: null,
      timeoutSynonyms: null,
      validNameLength: true,
      validShortNameLengthRu: true,
      validShortNameLengthUk: true,
      validShortNameLengthEn: true,
      synonymsLengthError: false,
      showNumberOfValuesError: false,
      showRepetativeValuesError: false
    }
  },
  methods: {
    onInputName(value) {
      // validate length
      this.validNameLength = validMaxLength(value, 200)
      this.checkUniqueTypeName(value)
      // set value
      this.PT_nameForNewType(value)
    },
    checkUniqueTypeName(value) {
      clearTimeout(this.timeoutName)
      this.timeoutName = setTimeout(() => {
        this.PT_checkUniqueTypeName(value)
      }, 500)
    },
    onInputShortNameRu(value) {
      // validate length
      this.validShortNameLengthRu = validMaxLength(value, 50)
      // set value
      this.PT_nameShortForNewTypeRu(value)
    },
    onInputShortNameUk(value) {
      // validate length
      this.validShortNameLengthUk = validMaxLength(value, 50)
      // set value
      this.PT_nameShortForNewTypeUk(value)
    },
    onInputShortNameEn(value) {
      // validate length
      this.validShortNameLengthEn = validMaxLength(value, 50)
      // set value
      this.PT_nameShortForNewTypeEn(value)
    },
    onInputSynonyms(value) {
      // build array
      let synonymsArray = value.split(",").map((item) => {
        return item.trim()
      })

      this.synonymsLengthError = !validArrayItemsMaxLength(synonymsArray, 50)
      this.showNumberOfValuesError = !validArrayItemsCount(synonymsArray, 50)
      this.showRepetativeValuesError = !validArrayRepetativeItems(synonymsArray)

      this.checkUniqueTypeSynonym(value)

      // set value
      this.PT_synonymsForNewType(value)
    },
    checkUniqueTypeSynonym(value) {
      clearTimeout(this.timeoutSynonyms)
      this.timeoutSynonyms = setTimeout(() => {
        this.PT_checkUniqueTypeSynonym({ value })
      }, 500)
    },
    createNewType() {
      this.PT_createNewType()
      this.closeModal()
    },
    closeModal() {
      this.PT_showCreateTypeModalSet(false)
      this.resetValues()
    },
    resetValues() {
      this.PT_checkUniqueTypeNameMute(true)
      this.PT_checkUniqueTypeNameSynonym(true)
      this.validNameLength = true
      this.validShortNameLength = true
      this.synonymsLengthError = false
      this.showNumberOfValuesError = false
      this.PT_nameForNewType(false)
      this.PT_nameShortForNewTypeRu(false)
      this.PT_nameShortForNewTypeUk(false)
      this.PT_nameShortForNewTypeEn(false)
      this.PT_synonymsForNewType(false)
      this.showRepetativeValuesError = false
    },
    ...mapMutations("ProductCatalog", {
      PT_nameForNewType: "PT_nameForNewType",
      PT_nameShortForNewTypeRu: "PT_nameShortForNewTypeRu",
      PT_nameShortForNewTypeUk: "PT_nameShortForNewTypeUk",
      PT_nameShortForNewTypeEn: "PT_nameShortForNewTypeEn",
      PT_synonymsForNewType: "PT_synonymsForNewType",
      PT_showCreateTypeModalSet: "PT_showCreateTypeModal",
      PT_checkUniqueTypeNameMute: "PT_checkUniqueTypeName",
      PT_checkUniqueTypeNameSynonym: "PT_checkUniqueTypeSynonym"
    }),
    ...mapActions("ProductCatalog", {
      PT_createNewType: "PT_createNewType",
      PT_checkUniqueTypeName: "PT_checkUniqueTypeName",
      PT_checkUniqueTypeSynonym: "PT_checkUniqueTypeSynonym",
      PC_searchTypes: "PC_searchTypes"
    })
  },
  computed: {
    shortNameRu() {
      return this.PT_nameShortForNewTypeGetRu === false ? "" : this.PT_nameShortForNewTypeGetRu
    },
    shortNameUk() {
      return this.PT_nameShortForNewTypeGetUk === false ? "" : this.PT_nameShortForNewTypeGetUk
    },
    shortNameEn() {
      return this.PT_nameShortForNewTypeGetEn === false ? "" : this.PT_nameShortForNewTypeGetEn
    },
    fullName() {
      return this.PT_nameForNewTypeGet === false ? "" : this.PT_nameForNewTypeGet
    },
    synonyms() {
      return this.PT_synonymsForNewTypeGet === false ? "" : this.PT_synonymsForNewTypeGet
    },
    enableCreateButton() {
      let show = false

      if (
        this.PT_nameForNewTypeGet &&
        this.validNameLength &&
        this.validShortNameLengthRu &&
        this.validShortNameLengthUk &&
        this.validShortNameLengthEn &&
        this.PT_checkUniqueTypeNameGet &&
        this.PT_checkUniqueTypeSynonymGet &&
        !this.synonymsLengthError &&
        !this.showNumberOfValuesError &&
        !this.showRepetativeValuesError
      ) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PT_nameForNewTypeGet: "PT_nameForNewType",
      PT_nameShortForNewTypeGetRu: "PT_nameShortForNewTypeRu",
      PT_nameShortForNewTypeGetUk: "PT_nameShortForNewTypeUk",
      PT_nameShortForNewTypeGetEn: "PT_nameShortForNewTypeEn",
      PT_synonymsForNewTypeGet: "PT_synonymsForNewType",
      PT_showCreateTypeModal: "PT_showCreateTypeModal",
      PT_checkUniqueTypeNameGet: "PT_checkUniqueTypeName",
      PT_checkUniqueTypeSynonymGet: "PT_checkUniqueTypeSynonym",
      PT_typesTableQueryParams: "PT_typesTableQueryParams"
    })
  }
}
</script>
<style lang="scss" scoped></style>
