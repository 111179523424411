var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",staticClass:"select",class:[`select--${_vm.status}`, { 'select--disabled': _vm.disabled }, _vm.className],style:({
    width: _vm.style.width
  })},[(_vm.isLabel)?_c('div',{staticClass:"select__label",class:[`select__label--${_vm.status}`, { 'select__label--disabled': _vm.disabled }]},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.required),expression:"required"}]},[_vm._v("*")])]):_vm._e(),_c('div',{staticClass:"select__trigger",class:[
      `select__trigger--${_vm.status}`,
      { 'select__trigger--disabled': _vm.disabled, 'select__trigger--highlighted': _vm.highlighted }
    ],style:({
      width: _vm.style.width
    }),on:{"click":function($event){!_vm.disabled ? _vm.toggleDropdown() : () => false}}},[_c('div',{staticClass:"select__placeholder",class:[`select__placeholder--${_vm.status}`, { 'select__placeholder--disabled': _vm.disabled }]},[_vm._v(" "+_vm._s(_vm.getPlaceholder())+" ")]),_c('div',{staticClass:"select__arrow",class:[`select__arrow--${_vm.status}`, { 'select__arrow--disabled': _vm.disabled }]},[_c('a-icon',{attrs:{"type":"caret-down"}})],1)]),_c('portal',{attrs:{"to":"select"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.isShowDropdown)?_c('div',{ref:"dropdown",staticClass:"select__dropdown",style:({
          left: `${_vm.offset.left}px`,
          top: `${_vm.offset.top + _vm.elementHeight + _vm.elementMargin}px`,
          width: _vm.dropdownStyle.width
        })},[_c('div',{staticClass:"select__dropdown-items",style:({ 'max-height': _vm.style.maxHeight })},[(_vm.fetching)?_c('div',{staticClass:"select__fetching"},[_c('Loader',{attrs:{"size":"middle"}})],1):(_vm.values.length > 0)?_vm._l((_vm.values),function(item,index){return _c('SelectItem',{key:item[_vm.valueKey],attrs:{"labelKey":_vm.labelKey,"childrenKey":_vm.childrenKey,"onItemSelect":_vm.onItemSelect,"valueKey":_vm.valueKey,"item":item,"tree":_vm.tree,"isSelected":_vm.selected === item[_vm.valueKey],"selected":_vm.selected,"localSelected":_vm.localSelected,"withoutLine":true,"disabled":_vm.disabledArray.length ? _vm.disabledArray[index] : false}})}):_c('div',{staticClass:"select__no-items"},[_vm._v(" No items ")])],2)]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }