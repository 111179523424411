import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearchComplex from "../../utils/buildMagicSearchComplex"
import { notification } from "ant-design-vue"
import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

const state = () => ({
  PB_brandsTableQueryParams: {
    limit: 10,
    ordering: ["-timestamp"],
    offset: 0
  },
  PB_brandsTableData: { count: 0, items: [] },
  PB_brandsTableFetchingStatus: false
})

const getters = {
  PB_brandsTableQueryParams: (state) => state.PB_brandsTableQueryParams,
  PB_brandsTableData: (state) => state.PB_brandsTableData,
  PB_brandsTableFetchingStatus: (state) => state.PB_brandsTableFetchingStatus
}

const mutations = {
  PB_brandsTableQueryParams(state, value) {
    state.PB_brandsTableQueryParams = value
  },
  PB_brandsTableData(state, value) {
    state.PB_brandsTableData = value
  },
  PB_brandsTableFetchingStatus(state, value) {
    state.PB_brandsTableFetchingStatus = value
  },
  PB_brandsTableSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    state.PB_brandsTableData.items[rowIndex][columnKey] = newValue
  },
  PB_brandsTableSetNewArrayValue: (state, { newValue, rowIndex }) => {
    state.PB_brandsTableData.items[rowIndex].synonyms = newValue
  },
  PB_resetBrandsTableValues(state) {
    state.PB_brandsTableQueryParams = {
      limit: 10,
      ordering: ["-timestamp"],
      offset: 0
    }
    state.PB_brandsTableData = { count: 0, items: [] }
  }
}

const actions = {
  PC_searchBrands({ commit, dispatch, getters }, queryParams) {
    commit("PB_brandsTableData", { count: 0, items: [] })
    let fields = ["code", "name", "synonyms", "timestamp", "status"]
    let resource = "PBD"

    let sort = buildSortValue(queryParams.ordering[0])
    let sendData = buildMagicSearchComplex(queryParams, fields, resource, sort)

    let brandsCount
    let brands = []
    // set fetching status
    commit("PB_brandsTableFetchingStatus", true)

    API_MODERATION.post(
      // search brands
      `${apiUrls.searchMagic}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        brandsCount = message.count
        brands = message.data

        let items = buildBrandsTableItems(brands)

        let tableObj = {
          count: brandsCount,
          items: items
        }

        commit("PB_brandsTableData", tableObj)
        commit("PB_brandsTableFetchingStatus", false)

        // update table query params
        commit("PB_brandsTableQueryParams", queryParams)
      })
      .catch((e) => {
        commit("PB_brandsTableFetchingStatus", false)
        commit("handleError", e)
      })
  },
  PB_editBrandsName({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PB_brandsTableData.items[rowIndex][columnKey]
    if (newValue === oldValue) return

    let sendData = {
      code: getters.PB_brandsTableData.items[rowIndex].code,
      brand: {
        [columnKey]: newValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.brand}`, sendData, {
      headers: {
        "Accept-language": getters.PC_selectedLanguage
      }
    })
      .then((r) => {
        commit("PB_brandsTableSetNewValue", { columnKey, newValue, rowIndex })

        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  PB_editBrandsSynonym({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PB_brandsTableData.items[rowIndex].synonymsNames

    if (newValue === oldValue) return

    let arrayNewValue = newValue.split(",").map((item) => {
      return item.trim()
    })

    let sendData = {
      code: getters.PB_brandsTableData.items[rowIndex].code,
      brand: {
        synonyms: arrayNewValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.brand}`, sendData)
      .then((r) => {
        commit("PB_brandsTableSetNewValue", { columnKey, newValue, rowIndex })

        // hide loader
        commit("PC_showLoader", false)

        commit("PB_brandsTableSetNewArrayValue", { newValue: arrayNewValue, rowIndex }) // set array value as well

        notification.info({ message: "Изменения добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildSortValue(colName) {
  let sort = colName
  switch (colName) {
    case "synonymsNames":
      sort = "synonyms"
      break
    case "-synonymsNames":
      sort = "-synonyms"
      break
    case "time":
      sort = "timestamp"
      break
    case "-time":
      sort = "-timestamp"
      break
  }
  return sort
}

function buildBrandsTableItems(brands) {
  return brands.map((item, index) => {
    // synonyms
    item.synonymsNames = item.synonyms.join(", ")
    // time
    item.time = getFormatedDateTimeByTimestamp(item.timestamp)
    // id
    item._id = "brandsTableRow-" + item.timestamp
    return item
  })
}
