<template>
  <div>
    <a-divider>
      <a-icon type="shopping-cart" />
      {{ $t("returnDetails") }}
    </a-divider>

    <a-descriptions
      :column="1"
      bordered
      size="small"
    >
      <a-descriptions-item :label="$t('contactPerson')">
        {{ fetchedSelectedReturn.contact_name }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('phoneNumber')">
        {{ fetchedSelectedReturn.contact_mobile_phone }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('comment')">
        {{ fetchedSelectedReturn.comment_customer }}
      </a-descriptions-item>

      <template v-if="fetchedSelectedReturn.order">
        <a-descriptions-item :label="$t('returnsByOrder')">
          {{ fetchedSelectedReturn.order.code }}
          {{ $t("from").toLowerCase() }} {{ createdOrderDate }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('seller')">
          {{ fetchedSelectedReturn.seller }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('customer')">
          {{ fetchedSelectedReturn.customer_name }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('orderClosed')">
          {{ closedOrderDate }}
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </div>
</template>

<script setup>
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import { computed } from "vue"

const props = defineProps({
  fetchedSelectedReturn: {
    type: Object,
    default: () => ({})
  }
})

const createdOrderDate = computed(() =>
  getFormatedDateTime(props.fetchedSelectedReturn.order.ordered_at)
)

const closedOrderDate = computed(() =>
  getFormatedDateTime(props.fetchedSelectedReturn.order.closed_at)
)
</script>
