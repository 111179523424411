<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PA_showEditAttributeModalGet"
      :title="'Редактирование подсказки атрибута'"
    >
      <div>
        <textarea
          :value="PA_editableAttrValueGet"
          rows="10"
          @input="onInput"
        ></textarea>
        <ValidationErrors :lengthError="maxLengthError" />
      </div>
      <span slot="footer">
        <Button
          color="primary"
          @click="onClick"
          :disabled="!enableCreateButton"
          >Редактировать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"

export default {
  components: {
    Button,
    Modal,
    ValidationErrors
  },
  data() {
    return {
      maxLengthError: false,
      wasEdited: false
    }
  },
  methods: {
    onInput(event) {
      let value = event.target.value
      // validate
      this.maxLengthError = !validMaxLength(value, 1000)

      this.wasEdited = true
      this.PA_editableAttrValue(value)
    },
    onClick() {
      this.PA_editAttributesCell({
        columnKey: "description",
        newValue: this.PA_editableAttrValueGet,
        rowIndex: this.PA_editableAttrRowIndexGet
      })
      this.closeModal()
    },
    closeModal() {
      this.PA_showEditAttributeModal(false)
      this.resetValues()
    },
    resetValues() {
      this.maxLengthError = false
      this.wasEdited = false
    },
    ...mapMutations("ProductCatalog", {
      PA_showEditAttributeModal: "PA_showEditAttributeModal",
      PA_editableAttrValue: "PA_editableAttrValue"
    }),
    ...mapActions("ProductCatalog", {
      PA_editAttributesCell: "PA_editAttributesCell"
    })
  },
  computed: {
    enableCreateButton() {
      let show = false

      if (!this.maxLengthError && this.wasEdited) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PA_showEditAttributeModalGet: "PA_showEditAttributeModal",
      PA_editableAttrValueGet: "PA_editableAttrValue",
      PA_editableAttrRowIndexGet: "PA_editableAttrRowIndex"
    })
  }
}
</script>
