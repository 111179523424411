import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

export default {
  functional: true,
  render(createElement, context) {
    if (context.props.value) {
      return createElement("div", getFormatedDateTimeByTimestamp(context.props.value))
    } else {
      return ""
    }
  }
}
