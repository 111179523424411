import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearchComplex from "../../utils/buildMagicSearchComplex"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"
import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

const state = () => ({
  PU_unitsTableQueryParams: {
    limit: 10,
    offset: 0,
    ordering: ["-timestamp"]
  },
  PU_unitsTableData: { count: 0, items: [] },
  PU_unitsTableFetchingStatus: false,
  PU_selectedUnit: false
})

const getters = {
  PU_unitsTableQueryParams: (state) => state.PU_unitsTableQueryParams,
  PU_unitsTableData: (state) => state.PU_unitsTableData,
  PU_unitsTableFetchingStatus: (state) => state.PU_unitsTableFetchingStatus,
  PU_selectedUnit: (state) => state.PU_selectedUnit
}

const mutations = {
  PU_unitsTableQueryParams(state, value) {
    state.PU_unitsTableQueryParams = value
  },
  PU_unitsTableData(state, value) {
    state.PU_unitsTableData = value
  },
  PU_unitsTableFetchingStatus(state, value) {
    state.PU_unitsTableFetchingStatus = value
  },
  PU_unitsTableSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    if (!state.PU_unitsTableData.items[rowIndex][columnKey])
      state.PU_unitsTableData.items[rowIndex][columnKey] = ""
    state.PU_unitsTableData.items[rowIndex][columnKey] = newValue
    let clone = JSON.parse(JSON.stringify(state.PU_unitsTableData))
    state.PU_unitsTableData = clone
  },
  PU_unitsTableSetNewObjectValue: (state, { field, newValue, rowIndex }) => {
    if (!state.PU_unitsTableData.items[rowIndex].base.length) {
      state.PU_unitsTableData.items[rowIndex].base = [{}]
    }
    state.PU_unitsTableData.items[rowIndex].base[0][field] = newValue
    let clone = JSON.parse(JSON.stringify(state.PU_unitsTableData))
    state.PU_unitsTableData = clone
  },
  PU_unitsTableSetNewArrayValue: (state, { newValue, rowIndex }) => {
    state.PU_unitsTableData.items[rowIndex].synonyms = newValue
  },
  PU_selectedUnit(state, value) {
    state.PU_selectedUnit = value
  },
  PU_resetProductUnitsValues(state) {
    state.PU_unitsTableQueryParams = {
      limit: 10,
      offset: 0,
      ordering: ["-timestamp"]
    }
    state.PU_unitsTableData = { count: 0, items: [] }
  }
}

const actions = {
  async PU_searchUnits({ commit, dispatch, getters }, queryParams) {
    try {
      await dispatch("PU_searchUnitsRequest", queryParams)
      if (!getters.PU_unitsTableData.items.length) return
      let selected
      let present
      // check if selected was set
      if (getters.PU_selectedUnit) {
        selected = getters.PU_selectedUnit
        // check if selected is among table items
        let products = getters.PU_unitsTableData.items
        present = products.find((item) => {
          return item.code === selected.code
        })
      }
      if (!present) {
        commit("PU_selectedUnit", getters.PU_unitsTableData.items[0])
      }
      await dispatch("PU_searchAttributes", {
        queryParams: getters.PU_attributesTableQueryParams,
        selected: getters.PU_selectedUnit
      })
    } catch (e) {
      console.log(e)
    }
  },
  async PU_searchUnitsRequest({ commit, dispatch, getters }, queryParams) {
    let fields = [
      "description",
      "synonyms",
      "name",
      "code",
      "coef",
      "base.name",
      "timestamp",
      "status"
    ]
    let resource = "AUD"

    let sort = buildSortValue(queryParams.ordering[0])
    let sendData = buildMagicSearchComplex(queryParams, fields, resource, sort)

    let unitsCount
    let units = []
    // set fetching status
    commit("PU_unitsTableFetchingStatus", true)

    return API_MODERATION.post(
      // search units
      `${apiUrls.searchMagic}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        unitsCount = message.count
        units = message.data

        let items = buildUnitsTableItems(units)

        let tableObj = {
          count: unitsCount,
          items: items
        }

        commit("PU_unitsTableData", tableObj)
        commit("PU_unitsTableFetchingStatus", false)

        commit("PU_unitsTableQueryParams", queryParams)

        return message
      })
      .catch((e) => {
        commit("PU_unitsTableFetchingStatus", false)
        commit("handleError", e)

        return Promise.reject()
      })
  },
  PU_editUnitsCell({ getters, commit, dispatch }, { columnKey, newValue, rowIndex }) {
    let currentItem = getters.PU_unitsTableData.items[rowIndex]

    let oldValue = currentItem[columnKey]
    if (newValue === oldValue) return

    if (columnKey === "coef" && newValue === "0") {
      newValue = null
    }

    let sendData = {
      code: getters.PU_unitsTableData.items[rowIndex].code,
      unit: {
        [columnKey]: newValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.unit}`, sendData, {
      headers: {
        "Accept-language": getters.PC_selectedLanguage
      }
    })
      .then((r) => {
        commit("PU_unitsTableSetNewValue", { columnKey, newValue, rowIndex })

        if (columnKey === "name") {
          dispatch("PU_searchUnits", getters.PU_unitsTableQueryParams)
        }

        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения продукта добавлены" })

        if (columnKey === "coef" && !currentItem.base.length) {
          notification.error({ message: "Не забудьте добавить базовую еденицу измерения" })
        }
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  PU_editUnitsSynonym({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PU_unitsTableData.items[rowIndex].synonymsNames

    if (newValue === oldValue) return

    let arrayNewValue = newValue.split(",").map((item) => {
      return item.trim()
    })

    let sendData = {
      code: getters.PU_unitsTableData.items[rowIndex].code,
      unit: {
        synonyms: arrayNewValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.unit}`, sendData)
      .then((r) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("PU_unitsTableSetNewValue", { columnKey, newValue, rowIndex })
        commit("PU_unitsTableSetNewArrayValue", { newValue: arrayNewValue, rowIndex }) // set array value as well

        notification.info({ message: "Изменения продукта добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildSortValue(colName) {
  let sort = colName
  switch (colName) {
    case "synonymsNames":
      sort = "synonyms"
      break
    case "-synonymsNames":
      sort = "-synonyms"
      break
    case "time":
      sort = "timestamp"
      break
    case "-time":
      sort = "-timestamp"
      break
    case "base_name":
      sort = "base.name"
      break
    case "-base_name":
      sort = "-base.name"
      break
  }
  return sort
}

function buildUnitsTableItems(units) {
  return units.map((item, index) => {
    // synonyms
    item.synonymsNames = item.synonyms.join(", ")
    // time
    item.time = getFormatedDateTimeByTimestamp(item.timestamp)
    // id
    item._id = "row-" + index
    return item
  })
}
