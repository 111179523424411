<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PA_showProductsModalGet"
      :title="'Внимание! Обнаружены другие значения'"
      size="large"
    >
      <div>
        <label
          ><span
            >Найдены товары со значениями выбранного атрибута, поэтому его тип не может быть
            конвертирован в списковый</span
          ></label
        >
        <SynDataTable
          :data="tableData"
          :queryAction="() => false"
          :queryParams="queryParams"
          rowKey="code"
          :columns="dataTableColumns"
          tableId="ProductAttributesProductsTable"
          class="margin-top"
          :limitValues="limitValues"
          :selectableMode="true"
        ></SynDataTable>
      </div>
      <span slot="footer"> </span>
    </Modal>
  </div>
</template>
<script>
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "ProductsModal",
  components: {
    Modal
  },
  data() {
    return {
      dataTableColumns: [
        {
          key: "code",
          title: "Код",
          width: 90,
          selectable: true
        },
        {
          key: "full_name",
          title: "Полное название",
          selectable: true
        },
        {
          key: "attribute_value",
          title: "Значение",
          selectable: true
        }
      ],
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 }
      ]
    }
  },
  methods: {
    closeModal() {
      this.PA_showProductsModal(false)
    },
    ...mapMutations("ProductCatalog", {
      PA_showProductsModal: "PA_showProductsModal"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PA_showProductsModalGet: "PA_showProductsModal",
      tableData: "PA_productsTable",
      queryParams: "PA_productsTableQueryParams"
    })
  }
}
</script>
<style lang="scss" scoped></style>
