import Invalid from "./renderers/Invalid"
import NewValue from "./renderers/NewValue"
import NewValueEdit from "./renderers/NewValueEdit"
import ValueMultilist from "./renderers/ValueMultilist"
import OldUnit from "./renderers/OldUnit"
import NewUnit from "./renderers/NewUnit"
import DateRenderer from "../../common/DateRenderer"
import { mapState, mapActions } from "vuex"

export default {
  computed: {
    dataTableColumns() {
      return [
        {
          key: "product",
          title: "Код",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          width: 93
        },
        {
          key: "type",
          title: "Тип товара",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "brand",
          title: "Бренд",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true,
          width: 120
        },
        {
          key: "name",
          title: "Название",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "model",
          title: "Модель",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true,
          width: 120
        },
        {
          key: this.AE_selectedAttrGet.value_type === "MULTILIST" ? "old_values" : "old_value",
          renderer: this.AE_selectedAttrGet.value_type === "MULTILIST" ? ValueMultilist : "",
          title: "Значение",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "old_unit",
          title: "Е.И.",
          renderer: OldUnit,
          width: 80
        },
        {
          key: "new_value",
          title: "Новое значение",
          renderer: NewValue,
          editRenderer:
            this.AE_selectedValueTypeGet !== "MULTILIST" && this.AE_selectedValueTypeGet !== "LIST"
              ? NewValueEdit
              : NewValue,
          editRendererProps: {
            onUpdate: this.EA_editTable,
            selectedAttr: this.AE_selectedAttrGet
          },
          filtering:
            this.AE_selectedValueTypeGet === "MULTILIST" || this.AE_selectedValueTypeGet === "LIST"
              ? false
              : {
                  type: "search",
                  placeholder: "Поиск"
                }
        },
        {
          key: "new_unit",
          title: "Новая Е.И.",
          renderer: NewUnit,
          rendererProps: {
            onUpdate: this.EA_editTable,
            selectedAttr: this.AE_selectedAttrGet
          },
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          width: 80
        },
        {
          key: "timestamp",
          title: "Дата",
          filtering: {
            type: "dateRange",
            placeholder: "Поиск"
          },
          renderer: DateRenderer,
          sorting: true,
          width: 87
        },
        {
          key: "status",
          title: "Статус",
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true,
          width: 101
        },
        {
          key: "invalid",
          title: "Ошибка",
          renderer: Invalid,
          filtering: {
            type: "select",
            placeholder: "Поиск",
            values: [
              { label: "All", value: "All" },
              { label: "Error", value: "Error" },
              { label: "Valid", value: "Valid" }
            ],
            dropdownWidth: 51
          },
          width: 65
        }
      ]
    },
    ...mapState("ProductCatalog/attributesEdit", {
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr,
      AE_selectedValueTypeGet: (state) => state.conversion.AE_selectedValueType
    })
  },
  methods: {
    ...mapActions("ProductCatalog/attributesEdit", {
      EA_editTable: "EA_editTable"
    })
  }
}
