import { API_ADS } from "@/utils/HttpUtils.js"
import { transformQuery } from "./servicesUtils.js"

export const fetchBlackListKinds = () => {
  return API_ADS.get("/v1/admin/blacklists/count-kinds/")
}

export const fetchBlackLists = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `/v1/admin/blacklists/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const addToBlackListBulk = (data) => {
  return API_ADS.put("/v1/admin/blacklists/bulk/", data)
}

export const removeFromBlackList = (data) => {
  return API_ADS.post("v1/admin/blacklists/bulk-delete/", data)
}
