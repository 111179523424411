<template>
  <div>
    <a-button
      type="primary"
      @click="openPaymentsMethodModal({})"
      icon="plus"
    >
      {{ $t("add") }}
    </a-button>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      rowKey="id"
      :pagination="paginationInfo"
      size="middle"
      :scroll="{ x: true }"
      style="margin-top: 16px"
      @change="onTableChange"
    >
      <template #activeRenderer="value">
        <a-icon
          :type="value ? 'check-circle' : 'close-circle'"
          class="active-icon"
          :class="[value ? 'active-icon--green' : 'active-icon--red']"
        />
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="edit"
          @click="openPaymentsMethodModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showModal"
      :title="prototype?.id ? $t('editingPaymentMethods') : $t('creatingPaymentMethods')"
      :width="700"
      destroyOnClose
      @close="closeModal"
    >
      <UpdatePaymentMethodsModal
        :prototype="prototype"
        @updateMethod="onUpdateMethod"
        @createMethod="onCreateMethod"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import UpdatePaymentMethodsModal from "./components/UpdatePaymentMethodsModal.vue"

import { fetchPaymentMethods } from "@/modules/MPAdmin/services/generalSettingService/paymentMethodsService.js"

import usePMethodsColumns from "./usePMethodsColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"

const columns = usePMethodsColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,

  setupTable,
  fetchTableInfo,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchPaymentMethods
})

const prototype = ref()
const showModal = ref(false)

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const openPaymentsMethodModal = (method) => {
  prototype.value = method
  showModal.value = true
}

const closeModal = () => {
  showModal.value = false
  prototype.value = undefined
}

const onUpdateMethod = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeModal()
}

const onCreateMethod = () => {
  closeModal()
  onTableChange()
}

onMounted(() => {
  setupTable({ defaultPagination: { size: "default" } })
  onTableChange()
})
</script>

<style lang="scss" scoped>
.active-icon {
  width: 100%;
  font-size: 20px;

  &--green {
    color: $green-color;
  }

  &--red {
    color: $red-color;
  }
}
</style>
