<template>
  <div>
    <div class="editable-cell">
      <div
        v-if="editable"
        class="editable-cell__input-wrapper"
      >
        <a-input
          v-ant-ref="(c) => (input = c)"
          :value="value"
          :type="inputHtmlType"
          @change="handleChange"
          @pressEnter="check"
        />
        <a-icon
          v-show="editable"
          type="check"
          class="editable-cell__submit-icon"
          @click="check"
        />
      </div>
      <div
        v-else
        class="editable-cell__text-wrapper"
      >
        <span
          v-if="error"
          class="text-error"
        >
          {{ text || " " }}
        </span>
        <span v-else>
          {{ text || " " }}
        </span>
        <a-icon
          type="edit"
          class="editable-cell__edit-icon"
          @click="edit"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableEditableCellInput",
  props: {
    text: {
      type: String | Number,
      default: ""
    },
    inputHtmlType: {
      type: String,
      default: "text"
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: null,
      value: this.text,
      editable: false
    }
  },
  methods: {
    handleChange(e) {
      const value = e.target.value

      this.value = value
    },
    check() {
      this.$emit("change", this.value, () => this.closeEdit())
    },
    edit() {
      this.editable = true
      this.$nextTick(() => {
        this.input.focus()
      })
    },
    closeEdit() {
      this.editable = false
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-cell {
  $root: &;
  position: relative;

  &__input-wrapper,
  &__text-wrapper {
    padding-right: 24px;
    min-height: 31px;
  }

  &__text-wrapper {
    padding: 5px 24px 5px 5px;
    // color: #108ee9;
  }

  &__edit-icon,
  &__submit-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
    width: 20px;
    cursor: pointer;
  }

  &__edit-icon {
    // display: none;

    &:hover {
      color: #108ee9;
    }
  }

  &__submit-icon {
    &:hover {
      color: #108ee9;
    }
  }

  &:hover {
    #{$root}__edit-icon {
      display: inline-block;
    }
  }
}

.text-error {
  color: #ffa69e;
}
</style>
