import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_checkUniqueGtins: true
})

const getters = {
  PC_checkUniqueGtins: (state) => state.PC_checkUniqueGtins
}

const mutations = {
  PC_checkUniqueGtins: (state, value) => {
    state.PC_checkUniqueGtins = value
  }
}

const actions = {
  PC_checkUniqueGtins: ({ commit, getters }, valuesArray) => {
    let promiseArray = []
    let searchData

    for (let i = 0; i < valuesArray.length; i++) {
      // skip wrong length
      if (
        valuesArray[i].length !== 14 &&
        valuesArray[i].length !== 13 &&
        valuesArray[i].length !== 12 &&
        valuesArray[i].length !== 8
      )
        continue

      if (getters.PC_EditableProduct.gtin && getters.PC_EditableProduct.gtin.length) {
        // skip values which where already set
        let newValue = true
        for (let k = 0; k < getters.PC_EditableProduct.gtin.length; k++) {
          if (valuesArray[i] === getters.PC_EditableProduct.gtin[k]) {
            newValue = false
            break
          }
        }
        if (newValue) {
          searchData = buildMagicSearch({
            resource: "MPC",
            field: "gtin",
            value: valuesArray[i],
            sort: null,
            limit: null,
            offset: null
          })
          promiseArray.push(API_MODERATION.post(`${apiUrls.searchMagic}`, searchData))
        }
      } else {
        searchData = buildMagicSearch({
          resource: "MPC",
          field: "gtin",
          value: valuesArray[i],
          sort: null,
          limit: null,
          offset: null
        })
        promiseArray.push(API_MODERATION.post(`${apiUrls.searchMagic}`, searchData))
      }
    }
    if (!promiseArray.length) return
    Promise.all(promiseArray)
      .then((r) => {
        commit("PC_checkUniqueGtins", true)
        for (let i = 0; i < r.length; i++) {
          if (r[i].data.message.count) {
            commit("PC_checkUniqueGtins", false)
            break
          }
        }
      })
      .catch((e) => {
        console.log(e.message)
        notification.error({ message: "Ошибка проверки кода" })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
