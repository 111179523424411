<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showDeleteAttributeModalGet"
      :title="'Отвязать атрибут от списка'"
    >
      <div>
        <div>
          <label>
            <span
              >Выбранный список: <strong>{{ PV_selectedList.description }}</strong></span
            >
            <span
              >Атрибут: <strong>{{ PV_selectedAttribute.name }}</strong></span
            >
          </label>
        </div>
      </div>
      <span slot="footer">
        <Button
          color="danger"
          @click="removeAttribute"
          >Отвязать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "DeleteAttributeModal",
  components: {
    Button,
    Modal
  },
  data() {
    return {}
  },
  methods: {
    removeAttribute() {
      this.PV_deleteAttributeFromList()
      this.closeModal()
    },
    closeModal() {
      this.PV_showDeleteAttributeModal(false)
    },
    ...mapMutations("ProductCatalog", {
      PV_showDeleteAttributeModal: "PV_showDeleteAttributeModal"
    }),
    ...mapActions("ProductCatalog", {
      PV_deleteAttributeFromList: "PV_deleteAttributeFromList"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_showDeleteAttributeModalGet: "PV_showDeleteAttributeModal",
      PV_selectedList: "PV_selectedList",
      PV_selectedAttribute: "PV_selectedAttribute"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
</style>
