<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showDeleteValuesConfirmationGet"
      :title="'Подтверждение удаления'"
    >
      <div>
        <label>
          <span
            >Выбранный список: <strong>{{ PV_selectedList.description }}</strong></span
          >
        </label>
        <label>
          <span
            >Удаляемое значение: <strong>{{ PV_valueToBeDeleted.value }}</strong></span
          >
        </label>
      </div>
      <span slot="footer">
        <Button
          color="danger"
          @click="removeValue"
          >Удалить</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"

import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "DeleteValuesConfirmationModal",
  components: {
    Button,
    Modal
  },
  data() {
    return {}
  },
  methods: {
    removeValue() {
      this.PV_deleteValue()
    },
    closeModal() {
      this.PV_showDeleteValuesConfirmation(false)
    },
    ...mapMutations("ProductCatalog", {
      PV_showDeleteValuesConfirmation: "PV_showDeleteValuesConfirmation"
    }),
    ...mapActions("ProductCatalog", {
      PV_deleteValue: "PV_deleteValue"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_showDeleteValuesConfirmationGet: "PV_showDeleteValuesConfirmation",
      PV_selectedList: "PV_selectedList",
      PV_valueToBeDeleted: "PV_valueToBeDeleted"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
</style>
