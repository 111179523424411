<template>
  <div class="tFoot">
    <SynPagination
      v-show="!paginationHide"
      :count="count"
      :perPage="limit"
      @change="onPageChange"
      :currentPage="offset > 0 ? offset / limit + 1 : 1"
    />
  </div>
</template>

<script>
export default {
  name: "TFoot",

  props: {
    count: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 50
    },
    offset: {
      type: Number,
      default: 0
    },
    onPageChange: {
      type: Function,
      required: true
    },
    paginationHide: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/config";

.tFoot {
  border-top: solid 1px $border-color;
}
</style>
