import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_productTableSearchedValus: [],
  PC_editCellAutocomplete: []
})

const getters = {
  PC_productTableSearchedValus: (state) => state.PC_productTableSearchedValus,
  PC_editCellAutocomplete: (state) => state.PC_editCellAutocomplete
}

const mutations = {
  PC_productTableSearchedValus(state, value) {
    state.PC_productTableSearchedValus = value
  },
  PC_editCellAutocomplete(state, value) {
    state.PC_editCellAutocomplete = value
  }
}

const actions = {
  PC_productTableSearchValue({ commit, getters }, { columnKey, newValue }) {
    let resource
    let field = "name"
    if (columnKey === "type_name") {
      resource = "PTD"
      field = "full_name"
    }

    if (columnKey === "brand_name") resource = "PBD"

    let searchData = buildMagicSearch({
      resource,
      field,
      value: newValue,
      sort: null,
      limit: 10,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((types) => {
        commit("PC_productTableSearchedValus", types)
        let typesArray
        if (columnKey === "type_name") {
          typesArray = types.map((item) => {
            return item.full_name
          })
        } else if (columnKey === "brand_name") {
          typesArray = types.map((item) => {
            return item.name
          })
        }
        commit("PC_editCellAutocomplete", typesArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },

  PC_productTableSelectValue({ commit, getters }, { columnKey, newValue, rowIndex }) {
    let selected
    if (columnKey === "type_name") {
      selected = getters.PC_productTableSearchedValus.find((item) => {
        return item.full_name === newValue
      })
    } else if (columnKey === "brand_name") {
      selected = getters.PC_productTableSearchedValus.find((item) => {
        return item.name === newValue
      })
    }
    // edit responding type
    let product = getters.PC_productTableData.items[rowIndex]
    let sendObject = {
      code: product.code,
      product: {}
    }
    if (columnKey === "type_name") {
      sendObject.product.type = selected.code
    } else if (columnKey === "brand_name") {
      sendObject.product.brand = selected.code
    }
    // show loader
    commit("PC_showLoader", true)
    API_MODERATION.put(`${apiUrls.product}`, sendObject)
      .then((r) => {
        commit("PC_productTableSetNewValue", { columnKey, newValue, rowIndex })
        // hide loader
        commit("PC_showLoader", false)
        notification.info({ message: "Изменения продукта добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
