import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearchComplex from "../../utils/buildMagicSearchComplex"
import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PC_languages: [
    {
      label: "ru",
      value: "ru",
      name: "Russian"
    }
  ],
  PC_selectedLanguage: "ru",
  PC_baseLanguage: "ru"
})

const getters = {
  PC_languages: (state) => state.PC_languages,
  PC_languagesWithoutBase: (state) => {
    let clone = JSON.parse(JSON.stringify(state.PC_languages))
    clone.shift()
    return clone
  },
  PC_selectedLanguage: (state) => state.PC_selectedLanguage,
  PC_baseLanguage: (state) => state.PC_baseLanguage
}

const mutations = {
  PC_languages(state, value) {
    // reset to prevent bugs on duplicated mount
    state.PC_languages = [
      {
        label: state.PC_baseLanguage,
        value: state.PC_baseLanguage,
        name: "Russian"
      }
    ]
    // add other languages
    value.forEach((item) => {
      state.PC_languages.push(item)
    })
  },
  PC_selectedLanguage(state, value) {
    state.PC_selectedLanguage = value
  }
}

const actions = {
  PC_fetchLanguages({ commit, getters }) {
    API_MODERATION.get(`${apiUrls.locale}`)
      .then((r) => r.data.message.locales)
      .then((r) => {
        let selectData = r.map((item) => {
          return {
            label: item.code,
            value: item.code,
            name: item.name
          }
        })
        commit("PC_languages", selectData)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  async PC_selectLanguage({ commit, dispatch }, { value, page }) {
    commit("PC_selectedLanguage", value)
    dispatch("PC_reloadTableAfterLangChange", page)
  },
  /* async PC_selectLanguageNoReload({commit}, value){
    commit('PC_selectedLanguage', value);
  }, */
  PC_reloadTableAfterLangChange({ commit, dispatch, getters }, page) {
    switch (page) {
      case "products":
        dispatch("PC_searchProducts", getters.PC_productTableQueryParams)
        break
      case "brands":
        dispatch("PC_searchBrands", getters.PB_brandsTableQueryParams)
        break
      case "types":
        dispatch("PC_searchTypes", getters.PT_typesTableQueryParams)
        break
      case "units":
        dispatch("PU_searchUnits", getters.PU_unitsTableQueryParams)
        break
      case "values":
        dispatch("PV_searchValuesByDescription", getters.PV_valuesDescriptionQueryParams)
        break
      case "attributes":
        dispatch("PA_searchAttributesForSelected", getters.PA_attributesTableQueryParams)
        break
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
