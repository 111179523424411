<template>
  <div
    ref="select"
    class="select"
    :class="[`select--${status}`, { 'select--disabled': disabled }, className]"
    :style="{
      width: style.width
    }"
  >
    <div
      v-if="isLabel"
      class="select__label"
      :class="[`select__label--${status}`, { 'select__label--disabled': disabled }]"
    >
      {{ label }} <span v-show="required">*</span>
    </div>
    <div
      class="select__trigger"
      :class="[
        `select__trigger--${status}`,
        { 'select__trigger--disabled': disabled, 'select__trigger--highlighted': highlighted }
      ]"
      :style="{
        width: style.width
      }"
      @click="!disabled ? toggleDropdown() : () => false"
    >
      <div
        class="select__placeholder"
        :class="[`select__placeholder--${status}`, { 'select__placeholder--disabled': disabled }]"
      >
        {{ getPlaceholder() }}
      </div>

      <div
        class="select__arrow"
        :class="[`select__arrow--${status}`, { 'select__arrow--disabled': disabled }]"
      >
        <a-icon type="caret-down" />
      </div>
    </div>

    <portal to="select">
      <transition name="slide-fade">
        <div
          v-if="isShowDropdown"
          class="select__dropdown"
          :style="{
            left: `${offset.left}px`,
            top: `${offset.top + elementHeight + elementMargin}px`,
            width: dropdownStyle.width
          }"
          ref="dropdown"
        >
          <div
            class="select__dropdown-items"
            :style="{ 'max-height': style.maxHeight }"
          >
            <div
              class="select__fetching"
              v-if="fetching"
            >
              <Loader size="middle" />
            </div>

            <SelectItem
              v-else-if="values.length > 0"
              v-for="(item, index) in values"
              :key="item[valueKey]"
              :labelKey="labelKey"
              :childrenKey="childrenKey"
              :onItemSelect="onItemSelect"
              :valueKey="valueKey"
              :item="item"
              :tree="tree"
              :isSelected="selected === item[valueKey]"
              :selected="selected"
              :localSelected="localSelected"
              :withoutLine="true"
              :disabled="disabledArray.length ? disabledArray[index] : false"
            />

            <div
              class="select__no-items"
              v-else
            >
              No items
            </div>
          </div>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import { getElementOffset } from "../../utils/DOM"
import Loader from "../Loader"
import SelectItem from "components/Select/SelectItem"

export default {
  name: "SingleSelect",
  components: {
    SelectItem,
    Loader
  },
  props: {
    values: {
      type: Array,
      required: true
    },
    selected: {
      type: [String, Number]
    },
    width: {
      type: [String, Number],
      default: "100%"
    },
    dropdownWidth: {
      type: [String, Number],
      default: "triggerWidth"
    },
    maxHeight: {
      type: [String, Number],
      default: 300
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: String,
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    childrenKey: {
      type: String,
      default: "children"
    },
    required: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    fetching: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: "normal"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ""
    },
    tree: {
      type: Boolean,
      default: false
    },
    disabledArray: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isShowDropdown: false,
      style: {
        width: Number.isInteger(this.width) ? `${this.width}px` : this.width,
        maxHeight: Number.isInteger(this.maxHeight) ? `${this.maxHeight}px` : this.maxHeight
      },
      offset: null,
      elementHeight: 0,
      elementMargin: 5,
      elementWidth: 0,
      localSelected: this.selected !== undefined ? false : "",
      allTreeValues: []
    }
  },
  computed: {
    isLabel() {
      return this.label !== ""
    },
    dropdownStyle() {
      let width = ""

      if (this.dropdownWidth === "triggerWidth") {
        width = `${this.elementWidth}px`
      } else if (Number.isInteger(this.dropdownWidth)) {
        width = `${this.dropdownWidth}px`
      } else {
        width = this.dropdownWidth
      }

      return {
        width
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside)

    if (this.tree) {
      this.getAllValues(this.values)
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside)
  },
  methods: {
    toggleDropdown() {
      if (this.isShowDropdown) {
        this.isShowDropdown = false
      } else {
        this.isShowDropdown = true

        this.offset = getElementOffset(this.$refs.select)
        this.elementHeight = this.$refs.select.clientHeight
        this.elementWidth = this.$refs.select.clientWidth
      }
    },
    onItemSelect(itemValue, item) {
      this.isShowDropdown = false
      this.$emit("change", itemValue, item)

      if (this.localSelected !== false) {
        this.localSelected = itemValue
      }

      this.clear()
    },
    handleClickOutside(e) {
      if (
        !this.$refs.select.contains(e.target) &&
        !!this.$refs.dropdown &&
        !this.$refs.dropdown.contains(e.target)
      ) {
        this.isShowDropdown = false
        this.clear()
      }
    },
    clear() {
      this.offset = null
      this.elementHeight = 0
      this.elementWidth = 0
    },
    getAllValues(items) {
      items.map((item) => {
        this.allTreeValues.push({
          [this.valueKey]: item[this.valueKey],
          [this.labelKey]: item[this.labelKey]
        })

        if (item.children.length > 0) {
          this.getAllValues(item.children)
        }
      })
    },
    getPlaceholder() {
      if (!this.tree) {
        return !this.selected && !this.localSelected
          ? this.placeholder
          : this.values.filter((item) => {
              return this.localSelected !== false
                ? item[this.valueKey] === this.localSelected
                : item[this.valueKey] === this.selected
            })[0][this.labelKey]
      } else {
        if (this.allTreeValues.length !== 0) {
          return !this.selected && !this.localSelected
            ? this.placeholder
            : this.allTreeValues.filter((item) => {
                return this.localSelected !== false
                  ? item[this.valueKey] === this.localSelected
                  : item[this.valueKey] === this.selected
              })[0][this.labelKey]
        } else {
          return ""
        }
      }
    }
  },
  watch: {
    values() {
      if (this.tree) {
        this.getAllValues(this.values)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  margin-bottom: 10px;
  position: relative;
  &__label {
    color: $link-default;
    margin-bottom: 5px;
    line-height: 21px;
    &--error {
      color: $danger-color;
    }
  }
  &__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px $light-border;
    height: 30px;
    padding: 5px 10px;
    color: $font-default;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: $light-shadow;
    &--error {
      border-color: $danger-color;
    }
    &--disabled {
      background-color: $input-disabled-bg;
      cursor: default;
    }
    &--highlighted {
      border: 1px solid $danger-color;
    }
  }
  &__placeholder {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $link-default;
    &--error {
      color: $danger-color;
    }
  }
  &__arrow {
    font-size: 12px;
    position: relative;
    right: -5px;
    &--error {
      color: $danger-color;
    }
  }
  &__dropdown {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    border: solid 1px $light-border;
    border-radius: 3px;
    box-shadow: $light-shadow;
  }
  &__dropdown-items {
    overflow: auto;
    position: relative;
  }
  &__fetching {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__no-items {
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
  }
}
</style>
