import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PT_showProductsModal: false,
  PT_productsTable: { count: 0, items: [] },
  PT_productsTableQueryParams: { offset: 0, limit: 100 }
})

const getters = {
  PT_showProductsModal: (state) => state.PT_showProductsModal,
  PT_productsTable: (state) => state.PT_productsTable,
  PT_productsTableQueryParams: (state) => state.PT_productsTableQueryParams
}

const mutations = {
  PT_showProductsModal(state, value) {
    state.PT_showProductsModal = value
  },
  PT_productsTable(state, value) {
    state.PT_productsTable = value
  },
  PT_productsTableQueryParams(state, value) {
    state.PT_productsTableQueryParams = value
  }
}

const actions = {
  async PT_buildProductsTableData({ commit, getters }, products) {
    let newProducts = []
    for (let i = 0; i < products.length; i++) {
      let newProduct = products[i]
      newProduct.full_name = `${(products[i].type && products[i].type.full_name) || ""} ${
        (products[i].brand && products[i].brand.name) || ""
      } ${products[i].name || ""}`
      if (products[i].model) newProduct.full_name += ` (${products[i].model || ""})`

      newProducts.push(newProduct)
    }
    if (newProducts.length) {
      commit("PT_productsTable", { count: newProducts.length, items: newProducts })
    }

    return Promise.resolve
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
