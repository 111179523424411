import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PC_allAtlsOfType: false,
  PC_attributesConnectedToType: false,
  PC_attributesNotConnectedToType: false,
  PC_sortedConnectedAttributes: false
})

const getters = {
  PC_allAtlsOfType: (state) => state.PC_allAtlsOfType,
  PC_attributesConnectedToType: (state) => state.PC_attributesConnectedToType,
  PC_attributesNotConnectedToType: (state) => state.PC_attributesNotConnectedToType,
  PC_sortedConnectedAttributes: (state) => state.PC_sortedConnectedAttributes
}

const mutations = {
  PC_allAtlsOfType(state, value) {
    state.PC_allAtlsOfType = value
  },
  PC_attributesConnectedToType(state, value) {
    state.PC_attributesConnectedToType = value
  },
  PC_attributesNotConnectedToType(state, value) {
    state.PC_attributesNotConnectedToType = value
  },
  PC_sortedConnectedAttributes(state, value) {
    state.PC_sortedConnectedAttributes = value
  },
  PC_resetSortLikeInType(state) {
    state.PC_allAtlsOfType = false
    state.PC_attributesConnectedToType = false
    state.PC_attributesNotConnectedToType = false
    state.PC_sortedConnectedAttributes = false
  }
}

const actions = {
  async PC_sortLikeInType({ commit, getters, dispatch }) {
    if (getters.PC_editableProductAttributes.items.length < 2) return
    try {
      commit("PC_resetSortLikeInType")
      await dispatch("PC_defineConnectedToType")
      if (!getters.PC_attributesConnectedToType) return
      commit("PC_showLoader", true)
      await dispatch("PC_fetchAllAtlsOfType", getters.PC_EditableProduct.type)
      await dispatch("PC_sortConnected")
      await dispatch("PC_updateTableAfterSorting")
      await dispatch("PC_sortProductAttributesAccordingly")
      commit("PC_attributesOrderChanged", true)
    } catch (e) {
      commit("handleError", e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_defineConnectedToType({ commit, getters }) {
    let attributes = getters.PC_editableProductAttributes.items
    let connected = attributes.filter((item) => {
      return item.is_main && item.is_required
    })
    if (connected.length) commit("PC_attributesConnectedToType", connected)
    let notConnected = attributes.filter((item) => {
      return !item.is_main && !item.is_required
    })
    if (notConnected.length) commit("PC_attributesNotConnectedToType", notConnected)
    return Promise.resolve()
  },
  async PC_fetchAllAtlsOfType({ commit, getters }, type) {
    let searchData = buildMagicSearch({
      resource: "ATL",
      field: "product_type",
      value: type.code,
      sort: ["order"],
      limit: 200,
      offset: 0
    })

    return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((atls) => {
        commit("PC_allAtlsOfType", atls)
        return atls
      })
  },
  async PC_sortConnected({ commit, getters }) {
    let connected = getters.PC_attributesConnectedToType
    let atls = getters.PC_allAtlsOfType
    let sorted = []

    for (let i = 0; i < atls.length; i++) {
      let current = connected.find((item) => {
        return item.code === atls[i].attribute
      })
      if (!current) continue
      sorted.push(current)
    }
    commit("PC_sortedConnectedAttributes", sorted)
  },
  async PC_updateTableAfterSorting({ commit, getters }) {
    let sortedConnected = getters.PC_sortedConnectedAttributes
    let notConnected = getters.PC_attributesNotConnectedToType || []
    let allAfterSorting = [...sortedConnected, ...notConnected]
    let attributesData = {
      items: allAfterSorting,
      count: allAfterSorting.length
    }
    commit("PC_setEditableProductAttributes", { attributesData })
  },
  async PC_sortProductAttributesAccordingly({ commit, getters }) {
    let productAttrs = getters.PC_EditableProduct.attributes
    let attrs = getters.PC_editableProductAttributes.items
    let sorted = []
    for (let i = 0; i < attrs.length; i++) {
      let current = productAttrs.find((item) => {
        return attrs[i].code === item.code
      })
      sorted.push(current)
    }
    commit("PC_setEditableProductInnerAttributes", sorted)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
