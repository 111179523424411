<template>
  <div style="margin-right: -8px">
    <a-tooltip placement="topLeft">
      <template slot="title">
        {{ fullText }}
      </template>
      <span>{{ shortText }}</span>
    </a-tooltip>
  </div>
</template>

<script>
export default {
  name: "TitleTooltip",
  props: {
    shortText: {
      type: String,
      default: ""
    },
    fullText: {
      type: String,
      default: ""
    },
    currentSlot: {
      type: String,
      default: ""
    }
  }
}
</script>
