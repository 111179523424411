<template>
  <div>
    <SynDataTable
      :data="tableData"
      :queryAction="querySearch"
      :queryParams="queryParams"
      :fetching="fetchingStatus"
      rowKey="_id"
      :columns="dataTableColumns"
      tableId="ProductCatalogValuesDescriptionTable"
      class="margin-top"
      defaultOrder="-description"
      :rowActions="rowActions"
      :localSorting="true"
      :selectedRow="PV_selectedListGet || {}"
    ></SynDataTable>
    <!-- :headerButtons="headerButtons" -->
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import InputEdit from "./EditComponents/InputEdit"
import regeneratorRuntime from "regenerator-runtime"

export default {
  name: "ValuesDescriptionTable",
  data() {
    return {
      dataTableColumns: [
        {
          key: "description",
          title: "Добавленные списки",
          editRenderer: InputEdit,
          editRendererProps: {
            onUpdate: this.updateCell
          },
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "count",
          title: "Количество",
          width: 109,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "not_valid",
          title: "NOT VALID",
          width: 109,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        }
      ],
      rowActions: [
        {
          label: "Просмотр",
          action: this.searchValuesOfSelectedList
        }
      ],
      // headerButtons: [{
      //   label: 'Создать',
      //   action: () => {
      //     this.PV_showCreateValueListModal(true);
      //   },
      //   theme: 'primary'
      // }],
      timeout: null
    }
  },
  methods: {
    querySearch(queryParams) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PV_searchValuesByDescription(queryParams)
      }, 500)
    },
    searchValuesOfSelectedList(selected) {
      this.PV_reloadDependentTablesForSelectedList(selected)
    },
    ...mapMutations("ProductCatalog", {
      changeQueryParams: "PV_valuesDescriptionQueryParams",
      PV_showCreateValueListModal: "PV_showCreateValueListModal"
    }),
    ...mapActions("ProductCatalog", {
      PV_searchValuesByDescription: "PV_searchValuesByDescription",
      updateCell: "PV_editValueDescription",
      PV_reloadDependentTablesForSelectedList: "PV_reloadDependentTablesForSelectedList"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      queryParams: "PV_valuesDescriptionQueryParams",
      tableData: "PV_valuesDescriptionData",
      fetchingStatus: "PV_valuesDescriptionFetchingStatus",
      PV_selectedListGet: "PV_selectedList"
    })
  }
}
</script>
<style lang="scss" scoped></style>
