import { computed } from "vue"
import i18n from "@/i18n"

export function useExportStatuses() {
  const exportStatuses = computed(() => {
    return {
      STARTED: {
        label: i18n.t("exportStatusStarted"),
        value: "STARTED"
      },
      SUCCESS: {
        label: i18n.t("exportStatusSuccess"),
        value: "SUCCESS"
      },
      REVOKED: {
        label: i18n.t("exportStatusRevorked"),
        value: "REVOKED"
      },
      FAILURE: {
        label: i18n.t("exportStatusFailute"),
        value: "FAILURE"
      },
      RECEIVED: {
        label: i18n.t("exportStatusReceived"),
        value: "RECEIVED"
      },
      PENDING: {
        label: i18n.t("exportStatusPending"),
        value: "PENDING"
      }
    }
  })

  return {
    exportStatuses
  }
}
