<template>
  <div
    class="th"
    :style="style"
  >
    <div class="th__top">
      <div>{{ title }}</div>
      <template v-if="sorting">
        <div class="th__sorting">
          <a-icon
            class="th__sort-icon"
            :class="{ 'primary-text': !this.isSorted(columnKey) }"
            :type="this.isActiveSort(columnKey, 'ASC') ? 'up' : 'down'"
            @click="handleSort(columnKey)"
          />
          <a-icon
            v-if="showSortLockIcon(columnKey)"
            class="th__sortLock-icon"
            :class="{
              'icon-th__sortLock-icon--locked': this.isSortLocked(columnKey)
            }"
            :type="this.isSortLocked(columnKey) ? 'lock' : 'unlock'"
            @click="onSortLock(columnKey)"
          ></a-icon>
        </div>
      </template>
    </div>
    <div
      class="th__bottom"
      v-if="!!filtering"
    >
      <div class="th__filter">
        <component
          :is="filterComponentName"
          :tableQueryAction="queryAction"
          :tableQueryParams="queryParams"
          :filters="filters"
          :updateFilters="updateFilters"
          :columnKey="columnKey"
          v-bind="filtering"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DateRangeFilter from "./filters/DateRangeFilter.vue"
import RangeFilter from "./filters/RangeFilter.vue"
import SearchFilter from "./filters/SearchFilter.vue"
import SelectFilter from "./filters/SelectFilter.vue"

export default {
  name: "Th",

  components: {
    DateRangeFilter,
    RangeFilter,
    SearchFilter,
    SelectFilter
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, Boolean],
      default: false
    },
    minWidth: {
      type: [Number, Boolean],
      default: false
    },
    maxWidth: {
      type: [Number, Boolean],
      default: false
    },
    filtering: {
      type: [Boolean, Object],
      default: false
    },
    columnKey: {
      type: String,
      required: true
    },
    queryAction: {
      type: Function,
      default: () => false
    },
    queryParams: {
      type: Object,
      default: () => {}
    },
    filters: {
      type: Array,
      default: () => []
    },
    updateFilters: {
      type: Function,
      default: () => false
    },
    sorting: {
      type: Boolean,
      default: false
    },
    sortingWithLock: {
      type: Boolean,
      default: false
    },
    localSorting: {
      type: Boolean,
      default: false
    },
    ordering: {
      type: Array,
      default: () => []
    },
    onSort: {
      type: Function,
      default: () => false
    },
    onSortLock: {
      type: Function,
      default: () => false
    }
  },

  data() {
    return {
      style: {
        "min-width": this.getMinWidth(),
        "max-width": this.getMaxWidth()
      },
      filterComponentName: !this.filtering ? null : `${this.capitalize(this.filtering.type)}Filter`
    }
  },

  methods: {
    getMinWidth() {
      let width = "auto"

      if (this.width) {
        width = `${this.width}px`
      } else if (this.minWidth) {
        width = `${this.minWidth}px`
      }

      return width
    },

    getMaxWidth() {
      let width = "none"

      if (this.width) {
        width = `${this.width}px`
      } else if (this.maxWidth) {
        width = `${this.maxWidth}px`
      }

      return width
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    isActiveSort(columnKey, directionKey) {
      return (
        this.ordering.find(
          (item) => item.column === columnKey && item.direction === directionKey
        ) !== undefined
      )
    },

    isSortLocked(columnKey) {
      const currentColumn = [...this.ordering].find((item) => item.column === columnKey)
      return !!currentColumn && currentColumn.locked === true
    },

    showSortLockIcon(columnKey) {
      return (
        this.sortingWithLock &&
        !this.localSorting &&
        this.ordering.find((item) => item.column === columnKey) !== undefined
      )
    },

    isSorted(columnKey) {
      return [...this.ordering].find((item) => item.column === columnKey)
    },

    handleSort(columnKey) {
      if (!this.isSortLocked(columnKey)) {
        this.onSort(columnKey)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/config";

.th {
  flex-grow: 1;
  width: 100%;
  border-right: solid 1px $border-color;
  border-bottom: solid 1px $border-color;
  padding: 7px;
  font-weight: bold;
  font-size: $font-size-small;
  box-sizing: border-box;
  text-transform: uppercase;
  color: $font-color-secondary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    border-right: none;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__bottom {
    margin-top: 10px;
  }

  &__sorting {
    font-size: 18px;
    position: relative;
    top: -3px;
    right: -2px;
    cursor: pointer;
  }

  &__sort-icon {
    color: $font-color-regular;
    font-size: 10px;
    line-height: 12px;

    &--not-sorted {
      color: $font-color-placeholder;
    }
  }

  &__sortLock-icon {
    color: $font-color-placeholder;
    font-size: 15px;

    &--locked {
      color: $font-color-regular;
    }
  }
}

.primary-text {
  color: $primary-color;
}
</style>
