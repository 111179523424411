import { API } from "@/utils/HttpUtils.js"

const formatCategoriesTree = (categories) => {
  return categories.map((category) => {
    const { name, path, children } = category

    return {
      title: name,
      value: path,
      children: children.length > 0 ? formatCategoriesTree(children) : []
    }
  })
}

export const fetchCategories = async () => {
  const { data } = await API.get("mp-admin/categories/tree")

  return formatCategoriesTree(data.results)
}
