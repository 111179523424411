export default {
  props: {
    customAttr: {
      type: Function,
      default: () => ""
    },
    values: {
      type: Array
    },
    valueKey: {
      type: String,
      default: "value"
    },
    labelKey: {
      type: String,
      default: "label"
    },
    dropdownWidth: {
      type: Number,
      default: 0,
      required: false
    }
  }
}
