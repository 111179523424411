<template>
  <div>
    <Modal
      :showModal="AE_changeAttributeModalGet"
      title="Полная или частичная замена атрибута"
      :showCloseButton="false"
      @close="closeModal"
    >
      <p>Выберите метод замены:</p>
      <div class="change-attr">
        <div class="change-attr__item">
          <input
            class="change-attr__input"
            type="radio"
            value="partially"
            v-model="wayChangingAttr"
          />
          <span class="change-attr__label">Частичная замена значений на новый атрибут</span>
        </div>
        <div class="change-attr__item">
          <input
            class="change-attr__input"
            type="radio"
            value="completely"
            v-model="wayChangingAttr"
            @input="resetSelectedTypes"
          />
          <span class="change-attr__label">Полная замена атрибута на новый атрибут</span>
        </div>
      </div>
      <p>Выберите новый атрибут:</p>
      <div class="change-attr__search">
        <Autocomplete
          ref="autocompleteAttr"
          class="change-attr__autocomplete"
          :data="AE_CA_attrsAutocompleteGet"
          event="input"
          size="small"
          placeholder="Поиск атрибута"
          @event="onInput"
          @select="AE_CA_setSelectedAttr"
        />
        <a-icon
          type="search"
          class="change-attr__icon"
        />
      </div>
      <div v-if="wayChangingAttr !== 'completely'">
        <p>Выберите для товаров каких типов будет произведена замена атрибута:</p>
        <ul class="change-attr__options">
          <li
            class="change-attr__option"
            v-for="(option, index) in AE_CA_uniqueTypes"
            :key="`${index}-option`"
          >
            <input
              class="change-attr__input"
              type="checkbox"
              :value="option"
              :id="`option-${index}`"
              v-model="selectedTypes"
            />
            <label
              class="change-attr__label"
              :for="`option-${index}`"
              >{{ option }}</label
            >
          </li>
        </ul>
      </div>
      <!-- <div class="change-attr__show-check">
			<input ref="showCheck" class="change-attr__input" type="checkbox" v-model="showChangedAttribute">
			<span class="change-attr__label">Показать значения нового атрибута после замены</span>
		</div> -->
      <div class="footer">
        <Button
          class="footer_btn"
          theme="secondary"
          @click="closeModal"
        >
          Отмена
        </Button>
        <Button
          @click="startChanging"
          class="footer_btn"
          :disabled="disabledButton"
          >Начать замену</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Autocomplete from "components/Autocomplete"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "ChangeAttributeModal",
  data() {
    return {
      wayChangingAttr: "partially",
      selectedTypes: []
      // showChangedAttribute: true
    }
  },
  components: {
    Button,
    Modal,
    Autocomplete
  },
  computed: {
    ...mapState("ProductCatalog/attributesEdit", {
      AE_changeAttributeModalGet: (state) => state.conversion.AE_changeAttributeModal,
      AE_selectedAttrOld: (state) => state.search.AE_selectedAttr,
      AE_CA_attrsAutocompleteGet: (state) => state.changingAttrModal.AE_CA_attrsAutocomplete,
      AE_CA_selectedAttr: (state) => state.changingAttrModal.AE_CA_selectedAttr,
      AE_CA_uniqueTypes: (state) => state.changingAttrModal.AE_CA_uniqueTypes
      // AE_CA_showChangedTable: state => state.changingAttrModal.AE_CA_showChangedTableCheck
    }),
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    isNewAttrShowChecked() {
      return this.$refs.showCheck.checked
    },
    disabledButton() {
      let disabled
      if (this.AE_CA_selectedAttr.status === "LOCKED") {
        disabled = true
      } else if (this.wayChangingAttr === "partially" && !this.selectedTypes.length) {
        disabled = true
      } else if (
        this.AE_selectedAttr.value_type === "TEMP" &&
        this.AE_CA_selectedAttr.value_type === "STRING"
      ) {
        disabled = false
      } else if (this.AE_selectedAttr.value_type === this.AE_CA_selectedAttr.value_type) {
        disabled = false
      } else if (
        this.AE_selectedAttr.value_type === "LIST" &&
        this.AE_CA_selectedAttr.value_type === "MULTILIST"
      ) {
        disabled = false
      } else if (
        this.AE_selectedAttr.value_type === "MULTILIST" &&
        this.AE_CA_selectedAttr.value_type === "LIST"
      ) {
        disabled = false
      }
      return disabled
    }
  },
  methods: {
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_changeAttributeModal: "AE_changeAttributeModal",
      // AE_CA_showChangedTableCheck: 'AE_CA_showChangedTableCheck',
      AE_selectedAttr: "AE_selectedAttr" // for mutating this old value to new (changed one)
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_CA_fetchAttributes: "AE_CA_fetchAttributes",
      AE_CA_setSelectedAttr: "AE_CA_setSelectedAttr",
      AE_CA_doChange: "AE_CA_doChange",
      AE_tableSearch: "AE_tableSearch",
      AE_CA_resetModalData: "AE_CA_resetModalData"
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    onInput(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.AE_CA_fetchAttributes(value)
      }, 500)
    },
    resetSelectedTypes() {
      this.selectedTypes = []
    },
    closeModal() {
      this.$refs.autocompleteAttr.input = ""
      this.selectedTypes = []
      this.AE_changeAttributeModal(false)
      this.AE_CA_resetModalData()
    },
    async startChanging() {
      this.PC_showLoader(true)
      // this.AE_CA_showChangedTableCheck(this.showChangedAttribute)

      const sendData = {
        attribute: this.AE_selectedAttrOld.code,
        new_attribute: this.AE_CA_selectedAttr.code,
        types: this.selectedTypes
      }
      try {
        const response = await this.AE_CA_doChange(sendData)

        let id = response.data.message.task_id
        this.checkWithInterval(id, this.AE_selectedAttrOld.code)
      } catch (e) {
        this.handleError(e)
      } finally {
        this.closeModal()
        this.PC_showLoader(false)
      }
    },
    async checkWithInterval(id, selectedAttrCode) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]

        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          clearInterval(this.interval)
        }
        if (response.task_state === "SUCCESS") {
          await this.AE_tableSearch({ selectedAttrCode })
          this.$notification.success({ message: "Замена атрибута прошла успешно" })
          this.closeModal()
        }
        if (response.task_state === "FAILURE") {
          this.$notification.alert({ message: `Ошибка соединения: ${response.error_msg}` })
        }
      }, this.celeryInterval)
    }
  }
}
</script>
<style lang="scss" scoped>
.change-attr {
  display: block;

  &__input {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  &__label {
    width: 90%;
    line-height: 1.2;
    margin-bottom: 0;
  }
  &__options {
    padding: 10px;
  }
  &__option {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  &__show-check {
    display: flex;
  }

  &__search {
    display: flex;
    margin-bottom: 20px;
  }

  &__autocomplete {
    width: 100%;
  }

  &__icon {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer {
  text-align: right;
  padding-top: 20px;

  &_btn {
    margin-left: 20px;
  }
}
</style>
