<template>
  <a-table
    :columns="columns"
    :dataSource="dataSource"
    :loading="isFetching"
    :pagination="paginationInfo"
    :scroll="{ x: true }"
    rowKey="order_code"
    @change="onTableChange"
  >
    <template #cs_code="text, record">
      <a-button
        type="link"
        icon="link"
        :href="linkProduct(record)"
        target="_blank"
        style="padding: 0"
      >
        {{ text.cs_code }}
      </a-button>

      <a-tooltip
        placement="bottomLeft"
        :title="record.offer.title"
      >
        {{ record.offer.title }}
      </a-tooltip>
    </template>

    <template #status="text">
      <div :style="{ color: orderStatuses[text].color }">
        {{ orderStatuses[text].title }}
      </div>
    </template>

    <template #order_code="text, record">
      {{ text }}
      <a-icon
        v-if="record.is_one_click"
        type="phone"
      />
    </template>
  </a-table>
</template>

<script setup>
import { onMounted } from "vue"

import { fetchSellerOrders } from "@/modules/ADS/services/sellerServices.js"
import { MARKETPLACE } from "@/constants/common.js"

import { useStatuses } from "@/composables/useStatuses.js"
import useSellerColumns from "../useSellerColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const props = defineProps({
  seller: {
    type: Object,
    default: () => ({})
  }
})

const {
  dataSource,
  isFetching,

  paginationInfo,

  fetchTableInfo
} = useAntTableQuery({
  queryFunction: fetchSellerOrders,
  requestParams: { seller_id: props.seller.seller_id }
})

const { orderStatuses } = useStatuses()
const { sellerOrdersColumns: columns } = useSellerColumns()

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const linkProduct = ({ offer }) => {
  return `${MARKETPLACE}/product/${offer.slug}/${offer.p_code}/${offer.cs_code}`
}

onMounted(() => onTableChange())
</script>
