const state = () => ({
  PC_showEditProductConfirmClose: false,
  PC_showCreateProductConfirmClose: false
})

const getters = {
  PC_showEditProductConfirmClose: (state) => state.PC_showEditProductConfirmClose,
  PC_showCreateProductConfirmClose: (state) => state.PC_showCreateProductConfirmClose
}

const mutations = {
  PC_showEditProductConfirmClose(state, value) {
    state.PC_showEditProductConfirmClose = value
  },
  PC_showCreateProductConfirmClose(state, value) {
    state.PC_showCreateProductConfirmClose = value
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
