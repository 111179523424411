import * as components from "./components"

const install = (Vue) => {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key])
  })
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue)
}

export default install
