import { transformQuery } from "./servicesUtils.js"
import { API } from "@/utils/HttpUtils.js"
import { MPA_PREFIX } from "./commonService.js"

export const fetchInvoices = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `${MPA_PREFIX}/invoices${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    {
      signal
    }
  )
}

export const createInvoice = (payload) => {
  return API.post(`${MPA_PREFIX}/invoices`, payload)
}

export const updateInvoice = ({ uuid, payload }) => {
  return API.patch(`${MPA_PREFIX}/invoices/${uuid}`, payload)
}
