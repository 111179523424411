<template>
  <div class="create-role-modal">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('name')"
        prop="name"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('description')"
        prop="description"
      >
        <a-input v-model="form.description" />
      </a-form-model-item>
    </a-form-model>

    <ModelPermissions
      :list="models"
      :fetching="modelsFetching"
      @change="changeModels"
    />

    <AppPermissions
      ref="appPermissions"
      :permissions="apps"
      :fetching="appsFetching"
      :defaultPermissions="roleApps"
      @change="changeApps"
    />

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import ModelPermissions from "./ModelPermissions.vue"
import AppPermissions from "./AppPermissions.vue"

import { createRole } from "@/modules/UsersManagement/services/rolesService.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  models: {
    type: Array,
    default: () => []
  },
  apps: {
    type: Array,
    default: () => []
  },

  modelsFetching: {
    type: Boolean,
    default: false
  },
  appsFetching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["onCreate", "close"])

const formRef = ref()

const roleModels = ref([])
const roleApps = ref([])
const isFetching = ref(false)

const form = reactive({
  name: "",
  description: ""
})

const formRules = computed(() => ({
  name: [{ required: true, message: i18n.t("emptyError") }],
  description: [{ required: true, message: i18n.t("emptyError") }]
}))

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  isFetching.value = true
  const models = parseModels(roleModels.value)
  const apps = parseApps(roleApps.value)

  try {
    await createRole({
      ...form,
      model_permissions: models,
      app_permissions: apps
    })

    notification.success({ message: i18n.t("roleCreatedSuccessfully") })
    emit("onCreate")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const changeModels = (permissions) => {
  roleModels.value = permissions
}

const changeApps = (permissions) => {
  roleApps.value = permissions
}

const parseModels = (roleModels) => {
  const models = []

  roleModels.map((item) => {
    models.push({
      id: item.id,
      permissions: item.permissions
    })

    item.fields.map((field) => {
      models.push({
        id: field.id,
        permissions: field.permissions
      })
    })
  })

  return models
}

const parseApps = (roleApps) => {
  const apps = []

  roleApps.map((application) => {
    apps.push(application.id)

    if (application.components) {
      application.components.map((component) => {
        apps.push(component.id)

        if (component.views) {
          component.views.map((view) => {
            apps.push(view.id)
          })
        }
      })
    }
  })

  return apps
}
</script>

<style scoped lang="scss">
.create-role-modal {
  display: flex;
  flex-flow: column nowrap;

  padding-bottom: 50px;
}
</style>
