import { render, staticRenderFns } from "./MessagesDisplay.vue?vue&type=template&id=03e1ff07"
import script from "./MessagesDisplay.vue?vue&type=script&lang=js"
export * from "./MessagesDisplay.vue?vue&type=script&lang=js"
import style0 from "./MessagesDisplay.vue?vue&type=style&index=0&id=03e1ff07&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports