<template>
  <a-form
    :form="topUpForm"
    style="padding: 0 32px"
  >
    <a-form-item :label="$t('date')">
      <a-date-picker
        showTime
        v-decorator="['processed_at', { rules: [{ required: true, message: $t('fillFields') }] }]"
      />
    </a-form-item>

    <a-form-item :label="$t('sum')">
      <a-input-number
        v-decorator="[
          'amount',
          {
            rules: [{ required: true, message: $t('fillFields') }]
          }
        ]"
        :min="1"
      />
    </a-form-item>

    <a-form-item :label="$t('applySum')">
      <a-input-number
        v-decorator="['amountConfirm', { rules: [{ required: true, message: $t('fillFields') }] }]"
        :min="0"
      />
    </a-form-item>

    <a-form-item :label="$t('comment')">
      <a-textarea
        v-decorator="[
          'description',
          { rules: [{ max: 1000, message: $t('maxLengthError', { length: 1000 }) }] }
        ]"
        :autoSize="{ minRows: 4, maxRows: 10 }"
      />
    </a-form-item>

    <div style="display: flex; justify-content: flex-end; gap: 16px; width: 100%">
      <a-button @click="clearForm">
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        @click="handleReplenish"
      >
        {{ $t("topUp") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { sellerReplenish } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  props: {
    sellerUuid: {
      type: String,
      required: true
    }
  },

  emits: ["handleUpdateBillingInfo"],

  data() {
    return {
      topUpForm: this.$form.createForm(this, { name: "topUpBalance" })
    }
  },

  methods: {
    handleReplenish() {
      this.topUpForm.validateFields(
        async (err, { amount, amountConfirm, processed_at, description }) => {
          if (err) return

          if (amount !== amountConfirm) {
            this.topUpForm.setFields({
              amount: {
                value: amount,
                errors: [new Error(this.$t("mustBeSameError"))]
              },
              amountConfirm: {
                value: amountConfirm,
                errors: [new Error(this.$t("mustBeSameError"))]
              }
            })
            return
          }

          let data = {
            operation_type: "REPLENISHMENT_BY_ADMINISTRATION",
            amount,
            processed_at: processed_at.toISOString()
          }
          if (description) {
            data.description = description
          }

          try {
            await sellerReplenish(this.sellerUuid, data)

            this.clearForm()
            this.$emit("handleUpdateBillingInfo")
            this.$notification.success({ message: this.$t("balanceReplenished") })
          } catch (error) {
            notifyResponseError({ error })
          }
        }
      )
    },

    clearForm() {
      this.topUpForm.resetFields()
    }
  }
}
</script>
