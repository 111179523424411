<template>
  <div class="wrapper">
    <div class="top-button">
      <a-button @click="addAttributeModalVisible = true">
        {{ $t("addAttribute") }}
      </a-button>

      <a-button
        :disabled="!hasProductType"
        :loading="sortAttributesRequest"
        @click="handleSortAttributes"
      >
        {{ $t("orderSameAsType") }}
      </a-button>

      <a-button
        type="danger"
        :loading="productMainAttributesFetching"
        :disabled="!hasProductType"
        @click="handleAddMainAttributes"
      >
        {{ $t("addMains") }}
      </a-button>

      <a-button
        type="danger"
        :loading="productTypeAllAttributesFetching"
        :disabled="!hasProductType"
        @click="handleAddAllAttributes"
      >
        {{ $t("addAllAttributesByType") }}
      </a-button>

      <a-button @click="handleDeleteEmptyAttributes">
        {{ $t("deleteBlank") }}
      </a-button>
    </div>

    <AttributesTable
      :mpcCode="mpcCode"
      :attributes="attributes"
      :fetching="tableLoading"
      @onSorted="onSorted"
      @updateAttribute="handleUpdate"
      @deleteAttribute="handleDeleteAttribute"
    />

    <AddAttributeModal
      :visible="addAttributeModalVisible"
      :product="product"
      :mappedAttributes="attributes"
      @addNewAttribute="handleAddNewAttribute"
      @closeAddNewAttribute="handleCloseAddNewAttribute"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue"

import AttributesTable from "./_components/AttributesTable.vue"
import AddAttributeModal from "./_components/AddAttributeModal.vue"

const props = defineProps({
  mpcCode: {
    type: String,
    required: true
  },
  product: {
    type: Object,
    default: () => {}
  },
  attributes: {
    type: Array,
    default: () => []
  },
  hasProductType: {
    type: Boolean,
    default: false
  },
  sortAttributesRequest: {
    type: Boolean,
    default: false
  },
  productMainAttributesFetching: {
    type: Boolean,
    default: false
  },
  productTypeAllAttributesFetching: {
    type: Boolean,
    default: false
  },
  fetching: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits([
  "attributesChanged",
  "addNewAttribute",
  "sortAttributes",
  "addMainAttributes",
  "addAllProductTypeAttributes",
  "deleteEmptyAttributes",
  "updateAttribute",
  "deleteAttribute"
])

const addAttributeModalVisible = ref(false)

const tableLoading = computed(
  () =>
    props.fetching ||
    props.sortAttributesRequest ||
    props.productMainAttributesFetching ||
    props.productTypeAllAttributesFetching
)

const handleAddMainAttributes = () => {
  emit("addMainAttributes")
  emit("attributesChanged")
}

const handleAddAllAttributes = () => {
  emit("addAllProductTypeAttributes")
  emit("attributesChanged")
}

const handleUpdate = (attribute) => {
  emit("updateAttribute", attribute)
  emit("attributesChanged")
}

const handleSortAttributes = () => {
  emit("sortAttributes")
  emit("attributesChanged")
}

const handleAddNewAttribute = (params) => {
  addAttributeModalVisible.value = false
  emit("addNewAttribute", params)
  emit("attributesChanged")
}

const handleDeleteAttribute = (index) => {
  emit("deleteAttribute", index)
  emit("attributesChanged")
}

const handleCloseAddNewAttribute = () => {
  addAttributeModalVisible.value = false
}

const handleDeleteEmptyAttributes = () => {
  emit("deleteEmptyAttributes")
  emit("attributesChanged")
}

const onSorted = () => {
  emit("attributesChanged")
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;
}

.top-button {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
</style>
