<template>
  <a-spin :spinning="fetching">
    <a-descriptions
      bordered
      :column="1"
      size="small"
    >
      <a-descriptions-item :label="$t('date')">{{ createdAt }}</a-descriptions-item>

      <a-descriptions-item :label="$t('grade')">
        <span :class="ratingClass">{{ rating }}</span> ({{
          data?.rating_product_description_fits
        }}/{{ data?.rating_delivery }}/{{ data?.rating_service }})
      </a-descriptions-item>

      <a-descriptions-item :label="$t('user')">
        {{ data && data?.author && data?.author?.username }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('comment')">
        {{ data?.comment }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('photos')">
        <TableRendererPhotoGallery :images="data.images" />
      </a-descriptions-item>
    </a-descriptions>
  </a-spin>
</template>

<script>
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

import { fetchSellerReviewById } from "@/modules/MPAdmin/services/sellerReviewsService"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "ParentReviewModal",

  components: {
    TableRendererPhotoGallery
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    parent: {
      type: Number,
      default: null
    },
    ratingOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      data: {},
      fetching: false
    }
  },

  computed: {
    rating() {
      return this.data?.rating_product_overall >= 0
        ? this.ratingOptions[this.data?.rating_product_overall].label
        : ""
    },
    ratingClass() {
      return `rating--${this.data?.rating_product_overall || ""}`
    },
    createdAt() {
      return getFormatedDateTime(this.data?.created_at)
    }
  },

  async mounted() {
    this.fetching = true

    try {
      const { data } = await fetchSellerReviewById(this.parent)

      this.data = data
    } catch (e) {
      notifyResponseError({ error })
      this.$emit("close")
    } finally {
      this.fetching = false
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  &--0 {
    color: $red-color;
  }
  &--1 {
    color: $primary-color;
  }
  &--2 {
    color: $green-color;
  }
}
</style>
