import productPage from "./productPage"
import brandsPage from "./brandsPage"
import typesPage from "./typesPage"
import unitsPage from "./unitsPage"
import valuesPage from "./valuesPage"
import attributesPage from "./attributesPage"
import attributesEdit from "./attributesEdit"
import locale from "./locale"

import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"

import { notification } from "ant-design-vue"

const state = () => ({
  PC_showLoader: false,
  celeryInterval: 3000
})
const getters = {
  PC_showLoader: (state) => state.PC_showLoader,
  celeryInterval: (state) => state.celeryInterval
}

const mutations = {
  PC_showLoader: (state, value) => {
    state.PC_showLoader = value
  },
  handleError(state, e) {
    console.log(`Error in action "${e.$_actionName}": ${e.message}`)
    let msg = ""
    if (e.message && e.response && e.response.status) {
      msg = `(${e.response.status}) ${e.message}`
    } else {
      msg = "Ошибка соединения"
    }
    notification.error({ message: msg })
  }
}

const actions = {
  async SH_checkTask({ commit }, id) {
    let params = new URLSearchParams()
    params.append("task_ids", id)
    return API_MODERATION.get(`${apiUrls.check}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        return r
      })
      .catch((e) => {
        e.$_actionName = "SH_checkTask"
        commit("handleError", e)
        return Promise.reject(e)
      })
  }
}

const modules = {
  productPage,
  brandsPage,
  typesPage,
  unitsPage,
  valuesPage,
  attributesPage,
  attributesEdit,
  locale
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}
