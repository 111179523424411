<template>
  <a-popover
    trigger="click"
    placement="topLeft"
    :visible="visible"
    @visibleChange="resetValue()"
  >
    <template #content>
      <a-form-model>
        <a-form-model-item :label="negative ? $t('takeFromBalance') : $t('addToBalance')">
          <a-input-number
            style="width: 100%"
            :min="0"
            :precision="2"
            v-model="amount"
            @pressEnter="changeBalance()"
          />
        </a-form-model-item>

        <div class="popover-footer-buttons">
          <a-button
            @click="onClose()"
            size="small"
          >
            {{ $t("no") }}
          </a-button>

          <a-button
            type="primary"
            size="small"
            :disabled="balanceChangeIsFetching"
            @click="changeBalance()"
          >
            {{ $t("yes") }}
          </a-button>
        </div>
      </a-form-model>
    </template>

    <a-button
      :icon="negative ? 'minus' : 'plus'"
      style="width: 10%"
      @click="onPopoverClick()"
    />
  </a-popover>
</template>

<script setup>
import { ref } from "vue"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },

  negative: {
    type: Boolean,
    default: false
  },

  balanceChangeIsFetching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["changeVisibility", "submit"])

const amount = ref(0)

const onClose = () => {
  emit("changeVisibility")
}

const changeBalance = () => {
  emit("submit", { value: amount.value, negative: props.negative })
}

const resetValue = () => {
  amount.value = 0
}

const onPopoverClick = () => {
  emit("changeVisibility")
}
</script>

<style scoped lang="scss">
.popover-footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  width: 100%;
}
</style>
