const AVATAR_FEMALE = "@/assets/images/user/avatar-female.svg"
const AVATAR_MALE = "@/assets/images/user/avatar-male.svg"

import { mapState } from "vuex"
export default {
  computed: {
    ...mapState("spvMessenger", {
      cdnUrl: (state) => state.cdnUrl
    })
  },
  methods: {
    getImagePath(name, size = "full") {
      return `${name.slice(0, 16).split("").join("/")}/${size}/${name}`
    },
    getImgUrl(image, size = "full") {
      return `${this.cdnUrl}/media/assets/images/${this.getImagePath(image, size)}`
    },
    getFileUrl(image, type) {
      if (type === "video") type = "videos"
      return `${this.cdnUrl}/media/assets/${type}/${this.getImagePath(image, "full")}`
    },
    getCdnUrls(array, size) {
      return array.map((str) => {
        if (~str.indexOf(this.cdnUrl) || str.indexOf("http") == 0) return str
        else return `${this.cdnUrl}/media/assets/images/${this.getImagePath(str, size)}`
      })
    },
    getAvaSrc(logo, gender, size) {
      if (logo === "moderator") {
        return require("../assets/logo.png")
      } else if (!logo || logo === "placeholder") {
        return gender === "F" ? AVATAR_FEMALE : AVATAR_MALE
      } else {
        return this.getImgUrl(logo, size)
      }
    }
  }
}
