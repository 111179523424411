import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PA_searchedAtlsOfAttribute: false,
  PA_searchedAtlsTable: { count: 0, items: [] },
  PA_searchedAtlsQueryParams: { offset: 0 }
})
const getters = {
  PA_searchedAtlsOfAttribute: (state) => state.PA_searchedAtlsOfAttribute,
  PA_searchedAtlsTable: (state) => state.PA_searchedAtlsTable,
  PA_searchedAtlsQueryParams: (state) => state.PA_searchedAtlsQueryParams
}

const mutations = {
  PA_searchedAtlsOfAttribute(state, value) {
    state.PA_searchedAtlsOfAttribute = value
  },
  PA_searchedAtlsTable(state, value) {
    state.PA_searchedAtlsTable = value
  },
  PA_updateAtlCheckboxes(state, { field, rowIndex, val }) {
    state.PA_searchedAtlsTable.items[rowIndex][field] = val
  },
  PA_resetAtlsOfAttributeValues(state) {
    state.PA_searchedAtlsOfAttribute = false
    state.PA_searchedAtlsTable = { count: 0, items: [] }
    state.PA_searchedAtlsQueryParams = { offset: 0 }
  }
}

const actions = {
  async PA_searchAtls({ commit, dispatch }, attribute) {
    // reset values
    commit("PA_resetAtlsOfAttributeValues")
    // send request
    let response = await dispatch("PA_searchAtlsOfAttribute", attribute.code)
    if (!response) return
    // build table data
    dispatch("PA_buildSearchedAtlsTable")
  },
  async PA_searchAtlsOfAttribute({ commit, getters, dispatch }, attributeCode) {
    // show loader
    commit("PC_showLoader", true)

    let sendData = buildMagicSearch({
      resource: "ATL",
      field: "attribute",
      value: attributeCode,
      sort: null,
      limit: null,
      offset: null
    })

    return API_MODERATION.post(`${apiUrls.searchMagic}`, sendData)
      .then((r) => r.data.message.data)
      .then((atls) => {
        commit("PA_searchedAtlsOfAttribute", atls)

        // hide loader
        commit("PC_showLoader", false)
        return atls
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  async PA_buildSearchedAtlsTable({ commit, getters }) {
    let atls = getters.PA_searchedAtlsOfAttribute
    let newAtls = []
    for (let i = 0; i < atls.length; i++) {
      let code = atls[i].code
      let is_main = atls[i].is_main
      let is_required = atls[i].is_required
      let order = atls[i].order
      let product_type_name = atls[i].product_type_name || ""
      newAtls.push({ code, is_main, is_required, order, product_type_name })
    }
    if (newAtls.length) {
      commit("PA_searchedAtlsTable", { count: newAtls.length, items: newAtls })
    }
    return Promise.resolve()
  },
  PA_editAtlCheckboxes({ commit, dispatch, getters }, { field, val, code, rowIndex }) {
    // update value in the table
    commit("PA_updateAtlCheckboxes", { field, rowIndex, val })
    let sendData = {
      code,
      link: {
        [field]: val
      }
    }
    API_MODERATION.put(`${apiUrls.link}`, sendData)
      .then((r) => r.data.message)
      .then((r) => {
        notification.info({ message: "Изменения добавлены" })
      })
      .catch((e) => {
        // bring the old value back
        commit("PA_updateAtlCheckboxes", { field, rowIndex, val: !val })
        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
