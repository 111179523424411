import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "../../utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PU_attributesTableQueryParams: {
    limit: 10,
    offset: 0,
    ordering: ["-name"]
  },
  PU_attributesTableData: false,
  PU_attributesTableFetchingStatus: false
})

const getters = {
  PU_attributesTableQueryParams: (state) => state.PU_attributesTableQueryParams,
  PU_attributesTableData: (state) => state.PU_attributesTableData,
  PU_attributesTableFetchingStatus: (state) => state.PU_attributesTableFetchingStatus
}

const mutations = {
  PU_attributesTableQueryParams(state, value) {
    state.PU_attributesTableQueryParams = value
  },
  PU_attributesTableData(state, value) {
    state.PU_attributesTableData = value
  },
  PU_attributesTableFetchingStatus(state, value) {
    state.PU_attributesTableFetchingStatus = value
  },
  PU_attributesTableReset(state) {
    state.PU_attributesTableQueryParams = {
      limit: 10,
      offset: 0,
      ordering: ["-name"]
    }
    state.PU_attributesTableData = false
  }
}

const actions = {
  async PU_searchAttributes({ commit, dispatch, getters }, { queryParams, selected }) {
    if (!queryParams) queryParams = getters.PU_attributesTableQueryParams
    if (!selected) selected = getters.PU_selectedUnit

    let sendData = buildMagicSearch({
      resource: "AND",
      field: "default_unit.code",
      value: selected.code,
      sort: queryParams.ordering,
      limit: queryParams.limit,
      offset: queryParams.offset
    })

    let attributesCount
    let attributes = []

    // set fetching status
    commit("PU_attributesTableFetchingStatus", true)

    return API_MODERATION.post(
      // search attributes
      `${apiUrls.searchMagic}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        attributesCount = message.count
        attributes = message.data

        let items = buildAttributesTableItems(attributes)

        let tableObj = {
          count: attributesCount,
          items: items
        }

        commit("PU_attributesTableData", tableObj)
        commit("PU_attributesTableFetchingStatus", false)
        commit("PU_attributesTableQueryParams", queryParams)

        return message
      })
      .catch((e) => {
        commit("PU_attributesTableFetchingStatus", false)
        commit("handleError", e)

        return Promise.reject()
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildAttributesTableItems(attributes) {
  return attributes.map((item, index) => {
    // id
    item._id = "attributesTableRow-" + item.timestamp
    return item
  })
}
