<template>
  <div>
    <div v-show="currentImgIndex !== undefined">
      <div
        class="preview-wrapper"
        :class="{ 'preview-wrapper--zoomed': zoomImage }"
        v-dragscroll
      >
        <img
          ref="previewRef"
          :src="imagePreview"
        />
      </div>

      <div class="preview-wrapper-caption">
        {{ `${previewSizes.width} x ${previewSizes.height} px` }}
        <a-button
          type="dashed"
          shape="circle"
          :icon="zoomImage ? 'zoom-out' : 'zoom-in'"
          @click="handleZoom"
        />
      </div>
    </div>

    <Draggable
      v-model="sortingImages"
      class="images-loader"
      :disabled="!sortStatus"
      :animation="300"
      draggable=".images-loader__item"
      ghostClass="images-loader__item--ghost"
      @end="onEnd"
    >
      <div
        v-for="(file, index) in sortingImages"
        :key="index"
        class="images-loader__item"
        :class="{ 'images-loader__item--sortable': sortStatus }"
      >
        <div
          class="images-loader__thumbnail"
          :class="{ 'images-loader__thumbnail--active': currentImgIndex === index }"
          @click="changeCurrentImg(index)"
        >
          <img :src="file.item" />
        </div>

        <template v-if="!sortStatus">
          <div
            class="images-loader__delete"
            @click="handleRemove(index)"
          >
            <a-button
              type="danger"
              shape="circle"
              size="small"
              icon="delete"
            />
          </div>

          <div
            class="images-loader__edit"
            @click="handleOpenEdit(file)"
          >
            <a-button
              type="primary"
              shape="circle"
              size="small"
              icon="edit"
            />
          </div>
        </template>

        <a-tag
          color="green"
          class="images-loader__cdn-mark"
        >
          CDN
        </a-tag>
      </div>
    </Draggable>

    <a-button
      v-if="sortingImages.length"
      :type="sortStatus ? 'primary' : 'default'"
      icon="swap"
      @click="handleSort"
    >
      {{ sortStatus ? $t("saveOrdering") : $t("orderingFull") }}
    </a-button>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted, computed } from "vue"
import Draggable from "vuedraggable"

const props = defineProps({
  images: {
    type: Array,
    default: () => []
  },
  sortStatus: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["onSort", "onEditClick", "onRemove"])

const sortingImages = ref([...props.images])

const previewRef = ref()

const currentImgIndex = ref()
const zoomImage = ref(false)
const sortStatus = ref(false)
const previewSizes = ref({ width: 0, height: 0 })

const imagePreview = computed(() => {
  if (currentImgIndex === undefined) return ""
  return sortingImages.value[currentImgIndex.value]?.item
})

const changeCurrentImg = (index) => {
  currentImgIndex.value = index
}

const handleSort = () => {
  if (sortStatus.value) {
    emit("onSort", sortingImages.value)
  }
  sortStatus.value = !sortStatus.value
}

const handleOpenEdit = (file) => {
  emit("onEditClick", file)
}

const handleRemove = (index) => {
  if (index === currentImgIndex.value) {
    changeCurrentImg(sortingImages.value.length > 1 ? 0 : undefined)
  }
  emit("onRemove", index)
}

const handleZoom = () => {
  zoomImage.value = !zoomImage.value
}

const onEnd = ({ newIndex }) => {
  changeCurrentImg(newIndex)
}

const setImageListener = () => {
  previewRef.value.onload = (e) => {
    previewSizes.value = {
      width: e.target?.naturalWidth || 0,
      height: e.target?.naturalHeight || 0
    }
  }
}

watch(
  () => props.images,
  (images) => {
    if (sortingImages.value.length !== images.length) {
      changeCurrentImg(images?.length ? 0 : undefined)
    }
    sortingImages.value = images
  }
)

onMounted(() => {
  changeCurrentImg(props.images?.length ? 0 : undefined)
  nextTick(setImageListener)
})
</script>

<style scoped lang="scss">
.preview-wrapper {
  width: 480px;
  aspect-ratio: 1;

  border: 1px solid $border-color;
  border-radius: 5px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--zoomed {
    overflow: scroll;

    img {
      width: max-content;
      height: max-content;
    }
  }

  &-caption {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    padding: 8px 0;
    width: 480px;
  }
}

.images-loader {
  line-height: initial;
  display: flex;
  flex-flow: row wrap;
  gap: 4px 8px;
  position: relative;
  margin: 0;
  padding: 0;
  padding-bottom: 16px;

  &__item {
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &--sortable {
      cursor: move;
    }

    &--dragging {
      opacity: 1;
      cursor: move;
    }

    &--ghost {
      opacity: 0;
    }
  }

  &__error {
    color: #f5222d;
    line-height: 1.524;
    display: flex;
  }

  &__thumbnail {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-size: 0;
    line-height: 0;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    overflow: hidden;

    &--active {
      border-color: $primary-color;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__delete {
    cursor: pointer;
    position: absolute;
    top: -10%;
    right: -10%;
    opacity: 0.9;
  }

  &__edit {
    cursor: pointer;
    position: absolute;
    top: -10%;
    right: 30%;
    opacity: 0.9;
  }

  &__cdn-mark {
    cursor: pointer;
    position: absolute;
    bottom: 4px;
    right: 4px;
    opacity: 0.9;
    font-size: 10px;
    line-height: 10px;
    padding: 2px;
    margin: 0;
    pointer-events: none;
  }
}
</style>
