<template>
  <a-drawer
    :width="720"
    :body-style="{ paddingBottom: '80px' }"
    :visible="isOpened"
    :title="$t('sellerCreating')"
    @close="handleCancel"
  >
    <a-form
      :form="form"
      :label-col="{ span: 7 }"
      :wrapper-col="{ span: 12 }"
    >
      <a-form-item
        :label="$t('companyName')"
        required
      >
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: $t('fieldRequire') }]
            }
          ]"
        />
      </a-form-item>
      <a-form-item :label="$t('owner')">
        <a-select
          v-decorator="[
            'owner',
            {
              rules: [{ required: true, message: $t('fieldRequire') }]
            }
          ]"
          show-search
          :placeholder="$t('enterPhoneNumber')"
          optionLabelProp
          style="width: 200px"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="customersFetching ? undefined : null"
          @search="handleSearchCustomers"
        >
          <a-spin
            v-if="customersFetching"
            slot="notFoundContent"
            size="small"
          />
          <a-select-option
            v-for="item in customers"
            :key="item.uuid"
          >
            {{ item.mobile_phone }} ({{ item.username }} / {{ item.first_name }}
            {{ item.last_name }})
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label="$t('sellerLogo')"
        :help="$t('recommendedSize', { size: '100x100' })"
        required
      >
        <a-upload
          v-decorator="[
            'small_logo',
            {
              rules: [{ required: true, message: $t('fieldRequire') }]
            }
          ]"
          name="small_logo"
          list-type="picture-card"
          :show-upload-list="false"
          :before-upload="beforeUpload"
        >
          <img
            v-if="logoPreviewUrl.length > 0"
            :src="logoPreviewUrl"
            alt="avatar"
            style="width: 100px"
          />
          <div
            v-else
            style="
              width: 100px;
              height: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="ant-upload-text">
              {{ $t("upload") }}
            </div>
          </div>
        </a-upload>
      </a-form-item>
    </a-form>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      }"
    >
      <a-button
        key="back"
        @click="handleCancel"
        :style="{ marginRight: '8px' }"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleCreateSeller"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import useCancellableRequest from "@/composables/useCancellableRequest"
import { createSeller, fetchCustomers } from "@/modules/MPAdmin/services/sellersService"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateSellerModal",
  props: {
    isOpened: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    const { makeRequest } = useCancellableRequest(fetchCustomers)
    return {
      makeRequest
    }
  },

  data() {
    return {
      processing: false,
      customers: [],
      customersFetching: false,
      form: this.$form.createForm(this, { name: "createSeller" }),
      logoPreviewUrl: "",
      fetchTimeout: null
    }
  },
  methods: {
    beforeUpload(file) {
      let fileReader = new FileReader()

      fileReader.addEventListener("load", (event) => {
        this.logoPreviewUrl = event.target.result
      })

      fileReader.readAsDataURL(file)
      return false
    },

    handleCreateSeller() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.processing = true

          const formData = new FormData()

          formData.append("owner", values.owner)
          formData.append("name", values.name)
          formData.append("small_logo", values.small_logo.file)
          formData.append("is_active", false)

          try {
            const { data } = await createSeller(formData)

            this.$notification.success({
              message: this.$t("sellerCreatingSuccess")
            })
            this.$emit("created", data)
            this.$emit("close")
          } catch (error) {
            notifyResponseError({ error, message: this.$t("sellerCreatingError") })
          } finally {
            this.processing = false
          }
        }
      })
    },

    handleCancel() {
      this.form.resetFields()
      this.logoPreviewUrl = ""
      this.customers = []
      this.$emit("close")
    },

    // `/mp-admin/customers?mobile_phone=${mobilePhone}&is_seller_owner=false&is_active=true`
    handleSearchCustomers(value) {
      if (this.fetchTimeout) {
        clearTimeout(this.fetchTimeout)
        this.fetchTimeout = null
      }

      this.fetchTimeout = setTimeout(async () => {
        this.customersFetching = true

        try {
          const queryParams = {
            mobile_phone: value,
            is_seller_owner: false,
            is_active: true
          }
          const { data } = await this.makeRequest({ queryParams })

          this.customers = data.results
        } catch (error) {
          if (error.message === "canceled") return // ignore abortController

          notifyResponseError({ error })
        } finally {
          this.customersFetching = false
          clearTimeout(this.fetchTimeout)
          this.fetchTimeout = null
        }
      }, 1000)
    }
  }
}
</script>
