<template>
  <a-drawer
    :visible="isOpened"
    width="85%"
    @close="handleCancel"
    :body-style="{ paddingBottom: '80px' }"
  >
    <template #title>
      {{ $t("viewOrder") }}
      <template v-if="orderData !== null">
        {{ orderData?.code }}
        <span :style="{ fontWeight: 'normal', marginLeft: '20px' }">
          {{ getFormatedDateTime(orderData.created_at) }}
        </span>
        <span :style="{ fontWeight: 'normal', marginLeft: '20px' }">
          {{ orderData.seller.name }}
        </span>
      </template>
    </template>

    <a-spin :spinning="fetching">
      <OrderItems :items="orderData?.items_in_order || []" />

      <a-row
        :style="{ marginTop: '10px' }"
        :gutter="24"
      >
        <a-col :span="10">
          <a-divider>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <a-icon
                type="history"
                :style="{ marginRight: '10px' }"
              />
              {{ $t("changeHistory") }}
            </div>
          </a-divider>

          <OrderHistory :items="orderHistory || []" />
        </a-col>
        <a-col :span="10">
          <a-divider>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <a-icon
                type="shopping-cart"
                :style="{ marginRight: '10px' }"
              />
              {{ $t("infoAboutOrder") }}
            </div>
          </a-divider>

          <OrderInformation
            v-if="orderData !== null"
            :orderData="orderData"
            ref="orderInformationRef"
          />
        </a-col>
        <a-col :span="4">
          <OrderStatuses
            ref="orderStatusesRef"
            :reject-reasons-buyer="ordersMeta.reject_reasons_buyer"
            :reject-reasons-moderator="ordersMeta.reject_reasons_moderator"
            :reject-reasons-seller="ordersMeta.reject_reasons_seller"
          />
        </a-col>
      </a-row>

      <a-collapse
        :style="{ marginTop: '24px' }"
        :bordered="false"
      >
        <a-collapse-panel
          v-if="orderNotes && orderNotes.length > 0"
          key="1"
          :header="$t('sellerNotes')"
        >
          <OrderNotes
            :items="orderNotes"
            :order-status="orderData.status"
            :reject-reason="orderData.reject_reason?.reason.uk"
          />
        </a-collapse-panel>
        <a-collapse-panel
          v-if="orderReturns && orderReturns.length > 0"
          key="2"
          :header="$t('ordersReturns')"
        >
          <OrderReturns :items="orderReturns" />
        </a-collapse-panel>
        <a-collapse-panel
          v-if="orderTransactions && orderTransactions.length > 0"
          key="3"
          :header="$t('bonusTransactions')"
        >
          <OrderTransactions :items="orderTransactions" />
        </a-collapse-panel>
      </a-collapse>
    </a-spin>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      }"
    >
      <a-button
        key="back"
        @click="handleCancel"
        :style="{ marginRight: '8px' }"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-popconfirm
        :visible="showTtnWarning"
        :title="$t('invalidTtnWarnign')"
        placement="topRight"
        @cancel="showTtnWarning = false"
        @confirm="handleUpdateOrder(true)"
      >
        <a-button
          key="submit"
          type="primary"
          :loading="updateProcessing || showTtnWarning"
          @click="handleUpdateOrder()"
        >
          {{ $t("save") }}
        </a-button>
      </a-popconfirm>
    </div>
  </a-drawer>
</template>

<script>
import OrderItems from "./components/OrderItems.vue"
import OrderHistory from "./components/OrderHistory.vue"
import OrderNotes from "./components/OrderNotes.vue"
import OrderTransactions from "./components/OrderTransactions.vue"
import OrderReturns from "./components/OrderReturns.vue"
import OrderInformation from "./components/OrderInformation.vue"
import OrderStatuses from "./components/OrderStatuses.vue"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import { mapState, mapActions } from "vuex"
import notifyResponseError from "@/utils/notifyResponseError"
import {
  fetchOrderById,
  fetchOrderHistory,
  fetchOrderNotes,
  fetchOrderReturns,
  fetchOrderTransactions,
  updateOrder
} from "@/modules/MPAdmin/services/ordersService.js"

export default {
  // This component used in ADS/CPA
  name: "EditOrderModal",
  components: {
    OrderItems,
    OrderHistory,
    OrderNotes,
    OrderTransactions,
    OrderReturns,
    OrderInformation,
    OrderStatuses
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    orderCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fetching: false,
      updateProcessing: false,
      orderData: null,
      orderHistory: null,
      orderNotes: null,
      orderReturns: null,
      orderTransactions: null,
      showTtnWarning: false
    }
  },
  computed: {
    ...mapState("MPAdmin/orders", {
      ordersMeta: (state) => state.ordersMeta
    })
  },
  watch: {
    isOpened(newValue) {
      if (newValue) {
        this.getOrderInfo()

        // Fetch orderMeta if reject reasons empty
        // Need for ADS/CPA
        if (this.ordersMeta.reject_reasons.length === 0) {
          this.fetchOrdersMeta()
        }
      } else {
        this.fetching = false
        this.processing = false
        this.orderData = null
        this.orderHistory = null
        this.orderNotes = null
        this.orderReturns = null
        this.orderTransactions = null

        this.$refs.orderStatusesRef.statusesForm.resetFields()
      }
    }
  },
  methods: {
    ...mapActions("MPAdmin/orders", ["fetchOrdersMeta"]),
    getFormatedDateTime,
    async getOrderInfo() {
      this.fetching = true

      try {
        const [
          orderResults,
          orderHistoryResults,
          orderNotesResults,
          orderReturnsResults,
          orderTransactionsResults
        ] = await Promise.all([
          fetchOrderById(this.orderCode),
          fetchOrderHistory(this.orderCode),
          fetchOrderNotes(this.orderCode),
          fetchOrderReturns(this.orderCode),
          fetchOrderTransactions(this.orderCode)
        ])

        this.orderData = orderResults.data
        this.orderHistory = orderHistoryResults.data.results
        this.orderNotes = orderNotesResults.data.results
        this.orderReturns = orderReturnsResults.data.results
        this.orderTransactions = orderTransactionsResults.data.results

        // Set form values
        this.$refs.orderStatusesRef.statusesForm.setFieldsValue({
          status: orderResults.data.status,
          moderation_status: orderResults.data.moderation_status
        })

        if (orderResults.data.reject_reason !== null) {
          // Wait render because this input has condition for render
          setTimeout(() => {
            this.$refs.orderStatusesRef.statusesForm.setFieldsValue({
              reject_reason: orderResults.data.reject_reason.id
            })
          }, 1)
        }
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.fetching = false
      }
    },
    async handleUpdateOrder(forseTTN = false) {
      this.showTtnWarning = false

      const isEditingTtn = this.$refs.orderInformationRef.isEditingTtn

      if (isEditingTtn) {
        this.$message.warning(this.$t("confirmTtn"))
        return
      }

      const fields = this.$refs.orderStatusesRef.statusesForm.getFieldsValue([
        "status",
        "reject_reason",
        "moderation_status"
      ])
      const ttn = this.$refs.orderInformationRef.newTtn

      this.updateProcessing = true

      let payload = {
        status: fields.status,
        created_by: this.userName
      }

      const ALLOWED_STATUSES = [
        "CANCELED_BY_SELLER",
        "CANCELED_BY_CUSTOMER",
        "CANCELED_BY_SUPERVISOR"
      ]

      if (~ALLOWED_STATUSES.indexOf(fields.status)) {
        payload = {
          ...payload,
          reject_reason: fields.reject_reason
        }
      }

      if (fields.moderation_status) {
        payload = {
          ...payload,
          moderation_status: fields.moderation_status
        }
      }

      if (forseTTN) {
        payload.ttn = ttn
        payload.ignore_ttn_format_validation = true
      } else if (ttn !== this.orderData.ttn) {
        payload.ttn = ttn
      }

      try {
        const { data } = await updateOrder({ code: this.orderCode, payload })

        this.$emit("on-update", data)
        this.$emit("close")

        this.$notification.success({ message: this.$t("updated") })
      } catch (error) {
        if (error?.response?.data?.code === "EB00002") {
          this.showTtnWarning = true
          return
        }
        notifyResponseError({ error, message: this.$t("notUpdated") })
      } finally {
        this.updateProcessing = false
      }
    },
    handleCancel() {
      this.showTtnWarning = false
      this.$emit("close")
    }
  }
}
</script>
