<template>
  <div
    class="synDataTable"
    :style="tableStyle"
  >
    <p
      v-if="rowKeyUndefined"
      class="synDataTable__error"
    >
      Property rowKey not exist in data item
    </p>

    <Header
      v-if="!hideHeader"
      ref="headerComponent"
      :selectableMode="selectableMode"
      :selectableEnabled="selectableEnabled"
      :onChangeSelectableEnabled="onChangeSelectableEnabled"
      :selectedCalc="selectedCalc"
      :buttons="headerButtons"
      :itemsLimit="itemsLimit"
      :limitValues="limitValues"
      :onChangePageLimit="changePageLimit"
      :tableSettings="tableSettings"
      :defaultSettings="defaultSettings"
      :onChangeSettings="handleChangeSettings"
    >
      <slot
        name="header"
        slot="header"
      />
    </Header>

    <THead
      ref="tHeadComponent"
      :columns="allTableColumns"
      :fixedColumns="fixedColumns"
      :notFixedColumns="notFixedColumns"
      :ifExistFixedColumns="ifExistFixedColumns"
      :tableId="tableId"
      :showRowNumber="showRowNumber"
      :checkRowEnabled="checkRowEnabled"
      :sortRowsEnabled="sortRowsEnabled"
      :queryAction="queryAction"
      :queryParams="queryParams"
      :filters="filters"
      :updateFilters="updateFilters"
      :localSorting="localSorting"
      :ordering="ordering"
      :onSort="handleSort"
      :onSortLock="handleSortLock"
      :onCheckAllRows="handleCheckAllRows"
      :checkedAll="checkedAll"
    />

    <TBody
      ref="tBodyComponent"
      :rowKey="rowKey"
      :columns="allTableColumns"
      :fixedColumns="fixedColumns"
      :notFixedColumns="notFixedColumns"
      :ifExistFixedColumns="ifExistFixedColumns"
      :items="items"
      :tableId="tableId"
      :height="getTBodyHeight()"
      :minRowHeight="minRowHeight"
      :maxRowHeight="maxRowHeight"
      :onScrollX="onTBodyScrollX"
      :rowActions="rowActions"
      :showRowNumber="showRowNumber"
      :rowDisabled="rowDisabled"
      :theme="theme"
      :rowClasses="rowClasses"
      :cellClasses="cellClasses"
      :checkRowEnabled="checkRowEnabled"
      :onCheckRow="handleCheckRow"
      :checkedRows="checkedRows"
      :sortRowsEnabled="sortRowsEnabled"
      :onSortRows="onSortRows"
      :selectableMode="selectableMode"
      :selectableEnabled="selectableEnabled"
      :onCalculation="onCalculation"
      :infiniteScroll="infiniteScroll"
      :loadMore="loadMore"
      :fetching="fetching"
      :showMore="showMore"
      :selectedRow="selectedRow"
      :selectedComparator="selectedComparator"
      :offset="queryParams.offset"
    />

    <TFoot
      v-show="!infiniteScroll"
      ref="tFootComponent"
      :count="data.count"
      :limit="queryParams.limit"
      :offset="queryParams.offset"
      :onPageChange="handlePageChange"
      :paginationHide="infiniteScroll"
    />
  </div>
</template>

<script>
import validator from "./validator"

import Header from "./components/Header.vue"
import THead from "./components/THead.vue"
import TBody from "./components/TBody.vue"
import TFoot from "./components/TFoot.vue"

export default {
  components: {
    Header,
    THead,
    TBody,
    TFoot
  },

  props: {
    hideHeader: {
      type: Boolean,
      default: false
    },
    fetching: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true,
      validator: validator.data
    },
    columns: {
      type: Array,
      required: true,
      validator: validator.columns
    },
    tableId: {
      type: String,
      required: true
    },
    height: {
      type: [String, Number],
      default: "100%"
    },
    maxHeight: {
      type: [String, Number],
      default: "none"
    },
    minRowHeight: {
      type: [Number, String],
      default: "auto"
    },
    maxRowHeight: {
      type: [Number, String],
      default: "none"
    },
    rowActions: {
      type: [Array, Function],
      default: () => []
    },
    showRowNumber: {
      type: Boolean,
      default: false
    },
    rowDisabled: {
      type: Function,
      default: () => false
    },
    theme: {
      type: String,
      default: "default",
      validator: validator.theme
    },
    rowClasses: {
      type: Function,
      default: () => []
    },
    cellClasses: {
      type: Function,
      default: () => []
    },
    onCheckRow: {
      type: [Boolean, Function],
      default: false
    },
    rowKey: {
      type: String,
      required: true
    },
    onSortRows: {
      type: [Boolean, Function],
      default: false
    },
    selectableMode: {
      type: Boolean,
      default: false
    },
    headerButtons: {
      type: Array,
      default: () => []
    },
    queryAction: {
      type: Function,
      required: true
    },
    queryParams: {
      type: Object,
      required: true
    },
    infiniteScroll: {
      type: Boolean,
      default: false
    },
    limitValues: {
      type: Array,
      default: () => [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ]
    },
    initialLimit: {
      type: Number,
      required: false
    },
    localSorting: {
      type: Boolean,
      default: false
    },
    defaultOrder: {
      type: String,
      default: ""
    },
    disableSaveSettings: {
      type: Boolean,
      default: false
    },
    showMore: {
      type: Boolean,
      default: false
    },
    selectedRow: {
      type: Object,
      default: () => {}
    },
    selectedComparator: {
      type: Function,
      default: null
    }
  },

  data() {
    let defaultLimitValue = this.limitValues[0].value

    if (this.initialLimit) {
      const initialValue = this.limitValues.find((v) => v.value === this.initialLimit)

      if (initialValue) {
        defaultLimitValue = initialValue.value
      } else {
        console.warn(
          `initialLimit with value "${this.initialLimit}" was not found in limitValues prop:`,
          this.limitValues
        )
      }
    }

    return {
      tableStyle: {
        height: this.getTableHeight(),
        maxHeight: this.getTableMaxHeight()
      },
      tBodyHeight: null,
      headerLeftOffset: 0,
      checkedRows: [],
      rowKeyUndefined: false,
      checkRowEnabled: typeof this.onCheckRow === "function",
      sortRowsEnabled: typeof this.onSortRows === "function",
      selectableEnabled: false,
      selectedCalc: {
        max: null,
        min: null,
        sum: null,
        average: null,
        countElements: null
      },
      itemsLimit: defaultLimitValue,
      filters: this.getDefaultFilters(),
      // return object if local sorting and array if global
      ordering: this.getDefaultOrdering(),
      tableSettingsKey: `table_${this.tableId}_settings`,
      allTableColumns: this.getAllTableColumns(),
      ifExistFixedColumns: this.checkIfExistFixedColumns(),
      fixedColumns: this.checkIfExistFixedColumns()
        ? this.getAllTableColumns().filter((column) => !!column.fixed)
        : [],
      notFixedColumns: this.checkIfExistFixedColumns()
        ? this.getAllTableColumns().filter((column) => !column.fixed)
        : []
    }
  },

  computed: {
    items() {
      return this.localSorting
        ? this.itemsLocalSorting(
            this.data.items,
            this.ordering[0].column,
            this.ordering[0].direction
          )
        : this.data.items
    },

    defaultSettings() {
      return this.columns.map((column) => ({
        key: column.key,
        fixed: column.fixed || false,
        title: column.title
      }))
    },

    tableSettings() {
      const savedSettings = this.getTableSettingsJson()

      return !savedSettings ? this.defaultSettings : JSON.parse(savedSettings)
    },

    checkedAll() {
      return this.data.items.length > 0 && this.checkedRows.length === this.data.items.length
    }
  },

  beforeMount() {
    this.queryAction({
      ...this.queryParams,
      limit: this.itemsLimit,
      ordering: this.buildOrderingArray()
    })
  },

  mounted() {
    // Check if rowKey exist in data array
    this.checkRowKeyExist()

    this.calculationTbodyHeight()

    this.initEventListeners()
  },

  updated() {
    if (this.ifExistFixedColumns) {
      this.setWidthFixedTablePart()
    }
  },

  beforeDestroy() {
    this.destroyEventListeners()
  },

  methods: {
    getDefaultOrdering() {
      const direction = this.defaultOrder.charAt(0) === "-" ? "DESC" : "ASC"
      const column = direction === "DESC" ? this.defaultOrder.substr(1) : this.defaultOrder

      return this.defaultOrder.trim().length > 0
        ? [
            {
              column,
              direction,
              locked: false
            }
          ]
        : [
            {
              column: "",
              direction: "DESC",
              locked: false
            }
          ]
    },

    buildOrderingArray() {
      const lockedColumns = this.ordering.filter((item) => item.locked === true)
      const notLockedColumns = this.ordering.filter((item) => item.locked === false)

      return [
        ...lockedColumns.map((item) => `${item.direction === "DESC" ? "-" : ""}${item.column}`),
        ...notLockedColumns.map((item) => `${item.direction === "DESC" ? "-" : ""}${item.column}`)
      ]
    },

    getTableHeight() {
      return typeof this.height === "number" ? `${this.height}px` : this.height
    },

    getTableMaxHeight() {
      return typeof this.maxHeight === "number" ? `${this.maxHeight}px` : this.maxHeight
    },

    getTBodyHeight() {
      return this.tBodyHeight !== null ? this.tBodyHeight : "auto"
    },

    calculationTbodyHeight() {
      const headerHeight = !this.hideHeader ? this.$refs.headerComponent.$el.offsetHeight : 0
      const tHeadHeight = this.$refs.tHeadComponent.$el.offsetHeight
      const tFootHeight = this.$refs.tFootComponent.$el.offsetHeight
      const sum = headerHeight + tHeadHeight + tFootHeight

      this.tBodyHeight = `calc(100% - ${sum - 1}px)`
    },

    // scroll THead after TBody horizontal scroll
    onTBodyScrollX(e) {
      this.$refs.tHeadComponent.$el.style.marginLeft = `-${e.target.scrollLeft}px`
    },

    initEventListeners() {
      window.addEventListener("resize", this.calculationTbodyHeight)

      window.addEventListener("resize", this.setWidthFixedTablePart)
    },

    destroyEventListeners() {
      window.removeEventListener("resize", this.calculationTbodyHeight)

      if (this.ifExistFixedColumns) {
        window.removeEventListener("resize", this.setWidthFixedTablePart)
      }
    },

    handleCheckAllRows() {
      if (this.checkedRows.length !== this.data.items.length) {
        this.checkedRows = [...this.data.items]
      } else {
        this.checkedRows = []
      }

      this.onCheckRow([...this.checkedRows])
    },

    unCheckAllRows() {
      if (this.checkRowEnabled) {
        this.checkedRows = []
        this.onCheckRow([])
      }
    },

    handleCheckRow(checked, rowObject) {
      if (checked) {
        this.checkedRows = [...this.checkedRows, rowObject]
      } else {
        this.checkedRows = [...this.checkedRows].filter(
          (item) => item[this.rowKey] !== rowObject[this.rowKey]
        )
      }

      this.onCheckRow([...this.checkedRows], { ...rowObject })
    },

    checkRowKeyExist() {
      for (let i = 0; i < this.data.items.length; i += 1) {
        if (!this.data.items[i][this.rowKey]) {
          this.rowKeyUndefined = true
          break
        }
      }
    },

    onChangeSelectableEnabled(checked) {
      this.selectableEnabled = checked
      this.selectedCalc = {
        max: null,
        min: null,
        sum: null,
        average: null,
        countElements: null
      }
    },

    onCalculation(values) {
      if (values.length > 0) {
        this.selectedCalc.max = Math.max(...values).toFixed(1)
        this.selectedCalc.min = Math.min(...values).toFixed(1)
        this.selectedCalc.sum = values.reduce((prevValue, value) => prevValue + value, 0).toFixed(1)
        this.selectedCalc.average = (this.selectedCalc.sum / values.length).toFixed(1)
        this.selectedCalc.countElements = values.length
      } else {
        this.selectedCalc.max = null
        this.selectedCalc.min = null
        this.selectedCalc.sum = null
        this.selectedCalc.average = null
        this.selectedCalc.countElements = null
      }
    },

    handlePageChange(page) {
      this.queryAction({
        ...this.queryParams,
        offset: this.itemsLimit * page - this.itemsLimit
      })

      this.unCheckAllRows()
    },

    changePageLimit(limit) {
      this.itemsLimit = limit
      this.queryAction({
        ...this.queryParams,
        limit,
        offset: 0
      })

      this.unCheckAllRows()
    },

    getDefaultFilters() {
      let filtersArr = []

      if (!this.queryParams.filters || this.queryParams.filters.length === 0) {
        filtersArr = this.columns
          .filter((column) => !!column.filtering)
          .map((filteredColumn) => ({
            name: filteredColumn.key,
            value: null
          }))
      } else {
        filtersArr = this.queryParams.filters
      }

      return filtersArr
    },

    updateFilters(filters) {
      this.filters = filters
      this.unCheckAllRows()
    },

    loadMore() {
      if (this.data.items.length < this.data.count) {
        this.queryAction({
          ...this.queryParams,
          offset: this.queryParams.offset + this.itemsLimit
        })

        this.unCheckAllRows()
      }
    },

    handleSort(columnKey) {
      if (this.localSorting) {
        this.handleSortLocal(columnKey)
      } else {
        this.handleSortGlobal(columnKey)
      }
    },

    handleSortLocal(columnKey) {
      if (this.ordering[0].column === columnKey) {
        this.ordering[0].direction = this.ordering[0].direction === "ASC" ? "DESC" : "ASC"
      } else {
        this.ordering[0].column = columnKey
        this.ordering[0].direction = "DESC"
      }
    },

    handleSortGlobal(columnKey) {
      const lockedColumns = this.ordering.filter((item) => item.locked === true)
      const notLockedColumn = this.ordering.filter((item) => item.locked === false)[0]
      const newNotLockedColumns =
        !notLockedColumn || notLockedColumn.column !== columnKey
          ? [
              {
                column: columnKey,
                direction: "DESC",
                locked: false
              }
            ]
          : [
              {
                column: columnKey,
                direction: notLockedColumn.direction === "DESC" ? "ASC" : "DESC",
                locked: false
              }
            ]

      this.ordering = [...lockedColumns, ...newNotLockedColumns]

      this.queryAction({
        ...this.queryParams,
        ordering: this.buildOrderingArray()
      })

      this.unCheckAllRows()
    },

    handleSortLock(columnKey) {
      const currentLockStatus = this.ordering.find((item) => item.column === columnKey).locked

      this.ordering = this.ordering
        .map((item) => {
          let newItem = {}

          if (item.column === columnKey) {
            newItem = {
              ...item,
              locked: !item.locked
            }
          } else {
            newItem = item
          }

          return newItem
        })
        .filter(
          (item) => item.column === columnKey || (item.column !== columnKey && item.locked === true)
        )

      if (currentLockStatus === true) {
        this.queryAction({
          ...this.queryParams,
          ordering: this.buildOrderingArray()
        })
      }

      this.unCheckAllRows()
    },

    itemsLocalSorting(items, sortColumn, sortDirection) {
      return items.sort((a, b) => {
        const isNumeric = (n) => !Number.isNaN(parseFloat(n)) && Number.isFinite(n)
        const isNumericCompare = isNumeric(a[sortColumn]) && isNumeric(b[sortColumn])
        const aa = isNumericCompare ? +a[sortColumn] : a[sortColumn]
        const bb = isNumericCompare ? +b[sortColumn] : b[sortColumn]
        let sort

        if (isNumericCompare) {
          sort = sortDirection === "DESC" ? bb - aa : aa - bb
        } else {
          sort = sortDirection === "DESC" ? (aa > bb) - (aa < bb) : (aa < bb) - (aa > bb)
        }

        return sort
      })
    },

    handleChangeSettings(settings) {
      this.allTableColumns = settings.map((columnSettings) => {
        const currentColumn = this.allTableColumns.find(
          (column) => column.key === columnSettings.key
        )

        return {
          ...currentColumn,
          ...columnSettings
        }
      })
      this.ifExistFixedColumns =
        this.allTableColumns.find((column) => column.fixed === true) !== undefined
      this.fixedColumns = this.ifExistFixedColumns
        ? this.allTableColumns.filter((column) => !!column.fixed)
        : []
      this.notFixedColumns = this.ifExistFixedColumns
        ? this.allTableColumns.filter((column) => !column.fixed)
        : []
      if (!this.disableSaveSettings) {
        window.localStorage.setItem(this.tableSettingsKey, JSON.stringify(settings))
      }
    },

    checkIfExistFixedColumns() {
      return this.getAllTableColumns().find((column) => column.fixed === true) !== undefined
    },

    getTableSettingsJson() {
      return window.localStorage.getItem(`table_${this.tableId}_settings`)
    },

    getAllTableColumns() {
      return !this.getTableSettingsJson()
        ? this.columns
        : JSON.parse(this.getTableSettingsJson()).map((columnSettings) => {
            const currentColumn = this.columns.find((column) => column.key === columnSettings.key)

            return {
              ...currentColumn,
              ...columnSettings
            }
          })
    },

    setWidthFixedTablePart() {
      if (this.ifExistFixedColumns) {
        if (this.$refs.tBodyComponent.$refs.trLeft) {
          this.$refs.tBodyComponent.$refs.trLeft.forEach((item) => {
            item.setAttribute(
              "style",
              `width: ${this.$refs.tHeadComponent.$refs.tHeadLeft.clientWidth}px`
            )
          })
        }
        if (this.$refs.tBodyComponent.$refs.trRight) {
          this.$refs.tBodyComponent.$refs.trRight.forEach((item) => {
            item.setAttribute(
              "style",
              `width: calc(100% - ${this.$refs.tHeadComponent.$refs.tHeadLeft.clientWidth}px)`
            )
          })
        }
      }
    }
  },

  watch: {
    columns() {
      this.allTableColumns = this.getAllTableColumns()
      this.ifExistFixedColumns = this.checkIfExistFixedColumns()
      this.fixedColumns = this.checkIfExistFixedColumns()
        ? this.getAllTableColumns().filter((column) => !!column.fixed)
        : []
      this.notFixedColumns = this.checkIfExistFixedColumns()
        ? this.getAllTableColumns().filter((column) => !column.fixed)
        : []
    },

    ["data.items"]() {
      if (this.checkRowEnabled) {
        const updatedCheckedRows = this.$_.map(this.checkedRows, (checkedRow) => {
          return this.$_.find(
            this.data.items,
            this.$_.matches({ [this.rowKey]: checkedRow[this.rowKey] })
          )
        })
        this.onCheckRow(updatedCheckedRows || [])
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/config";

.synDataTable {
  width: 100%;
  border: solid 1px $border-color;
  overflow: hidden;

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    opacity: 1 !important;
  }

  &__error {
    font-size: 20px;
    color: $danger-color;
  }
}
</style>
