<template>
  <div>
    <a-drawer
      :visible="showModal"
      :title="$t('courseChange')"
      :width="500"
      @close="$emit('close')"
    >
      100 {{ $tc("bonus", 100) }} =
      <a-input-number
        v-model="rate"
        :precision="0"
        :min="0"
      />

      {{ $t("uah") }}
      <div class="drawer-footer">
        <a-button
          :disabled="isFetching"
          @click="$emit('close')"
          >{{ $t("cancel") }}</a-button
        >
        <a-button
          type="primary"
          :loading="isFetching"
          @click="onSave"
        >
          {{ $t("apply") }}
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { Modal, notification } from "ant-design-vue"
import i18n from "@/i18n"

import { changeBonusRate } from "@/modules/MPAdmin/services/bonusesService"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  currentRate: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(["handleRefreshData"])

const rate = ref(0)
const isFetching = ref(false)

const onSave = () => {
  if (!rate.value) {
    notification.error({
      message: i18n.t("fillFields")
    })
    return
  }

  Modal.confirm({
    title: i18n.t("applyNeeded"),
    content: `${i18n.t("bonusCourseAlert", { value: rate.value })}`,
    okText: i18n.t("apply"),
    cancelText: i18n.t("cancel"),
    onOk: () => onConfirm()
  })
}

const onConfirm = async () => {
  isFetching.value = true

  try {
    await changeBonusRate({ rate: rate.value })

    notification.success({
      message: i18n.t("bonusRateChanged")
    })
    emit("handleRefreshData")
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

watch(
  () => props.showModal,
  (val) => {
    if (val) {
      rate.value = props.currentRate
    }
  }
)
</script>
