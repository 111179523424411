import { computed } from "vue"
import i18n from "@/i18n"

export default function usePromotionsConstants() {
  const allowedFormats = ["jpg", "jpeg", "png"]

  const allowedFormatsString = computed(() => allowedFormats.join(", "))

  const requiredRule = computed(() => {
    return { required: true, message: i18n.t("emptyError") }
  })

  const nameRules = computed(() => {
    return [requiredRule, { max: 250, message: i18n.t("maxLengthError", { length: 250 }) }]
  })

  const linkRules = computed(() => {
    return [requiredRule, { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]
  })

  return {
    requiredRule,
    nameRules,
    linkRules,
    allowedFormats,
    allowedFormatsString
  }
}
