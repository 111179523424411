<template>
  <a-form-model
    ref="formRef"
    :rules="formRules"
    :model="form"
    class="create-locality-drawer"
    hideRequiredMark
    :colon="false"
  >
    <a-form-model-item
      :label="$t('chooseType')"
      prop="type"
    >
      <a-select
        :options="localityTypes"
        v-model="form.type"
      />
    </a-form-model-item>

    <template v-if="form.type">
      <a-form-model-item
        :label="parentLabel"
        prop="parent"
      >
        <a-select
          v-model="form.parent"
          :options="parentOptions"
          showSearch
          optionFilterProp="title"
        />
      </a-form-model-item>

      <a-form-model-item
        v-if="showKoatuu"
        :label="$t('koatuuLabel')"
        prop="koatuu"
      >
        <a-input v-model="form.koatuu" />
      </a-form-model-item>

      <a-form-model-item
        v-for="lang in languages"
        :label="labelForName(lang.label)"
        :key="`name-${lang.label}`"
        :prop="`name.${lang.value}`"
      >
        <a-input v-model="form.name[lang.value]" />
      </a-form-model-item>
    </template>

    <div class="drawer-footer">
      <a-button @click="onCancel()">
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :disabled="!form.type"
        :loading="isFetching"
        @click="onConfirm()"
      >
        {{ $t("add") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { createLocation } from "@/modules/MPAdmin/services/locationsService"

import { useLocationComposables } from "@/composables/useLocationComposables.js"
import notifyResponseError from "@/utils/notifyResponseError"

const languages = [
  {
    label: "UA",
    value: "uk"
  },
  {
    label: "RU",
    value: "ru"
  },
  {
    label: "EN",
    value: "en"
  }
]

const props = defineProps({
  countryOptions: {
    type: Array,
    default: () => []
  },
  stateOptions: {
    type: Array,
    default: () => []
  },
  districtOptions: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["onCreated", "close"])

const { localityTypes } = useLocationComposables()

const formRef = ref()
const isFetching = ref(false)

const form = reactive({
  type: undefined,
  parent: undefined,
  match: "DISTRICT",
  koatuu: "",
  name: {
    uk: "",
    en: "",
    ru: ""
  }
})

const formRules = computed(() => ({
  type: { required: true, message: i18n.t("emptyError") },
  parent: { required: true, message: i18n.t("emptyError") },
  name: {
    uk: { required: true, message: i18n.t("emptyError") },
    en: { required: true, message: i18n.t("emptyError") },
    ru: { required: true, message: i18n.t("emptyError") }
  }
}))

const showKoatuu = computed(() => {
  const typesWithoutKoatuu = ["COUNTRY", "STATE", "DISTRICT"]
  return !typesWithoutKoatuu.includes(form.type)
})

const parentLabel = computed(() => {
  const labels = {
    STATE: i18n.t("chooseCountry"),
    DISTRICT: i18n.t("chooseState"),
    SPECIAL_CITY: i18n.t("chooseState")
  }

  return labels[form.type] || i18n.t("chooseDistrict")
})

const parentOptions = computed(() => {
  const options = {
    STATE: props.countryOptions,
    DISTRICT: props.stateOptions,
    SPECIAL_CITY: props.stateOptions
  }

  return options[form.type] || props.districtOptions
})

const labelForName = (lang) => {
  return `${i18n.t(`locationTypes.${form.type}`)} (${lang})`
}

const onConfirm = () => {
  formRef.value.validate(async (isValid) => {
    if (!isValid) return
    isFetching.value = true

    const payloadByType = {
      STATE: {
        country: form.parent
      },
      DISTRICT: {
        state: form.parent
      },
      SPECIAL_CITY: {
        state: form.parent,
        special_status: true
      },
      DEFAULT: {
        district: form.parent
      }
    }

    const payload = {
      type: form.type,
      name: form.name,
      ...(payloadByType[form.type] || payloadByType.DEFAULT)
    }

    if (showKoatuu.value && form.koatuu) {
      payload.koatuu = form.koatuu
    }

    try {
      await createLocation(payload)

      notification.success({
        message: i18n.t("created")
      })

      emit("onCreated")
    } catch (error) {
      notifyResponseError({ error })
    } finally {
      isFetching.value = false
    }
  })
}

const onCancel = () => {
  emit("close")
}

watch(
  () => form.type,
  () => {
    form.parent = undefined
  }
)
</script>

<style lang="scss">
.create-locality-drawer {
  padding-bottom: 50px;
}
</style>
