import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = () => ({
  PC_searchConnectionsModal: false,
  PC_connectionsData: null
})

const getters = {
  PC_searchConnectionsModal: (state) => state.PC_searchConnectionsModal,
  PC_connectionsData: (state) => state.PC_connectionsData
}

const mutations = {
  PC_searchConnectionsModal(state, value) {
    state.PC_searchConnectionsModal = value
  },
  PC_connectionsData(state, value) {
    state.PC_connectionsData = value
  }
}

const actions = {
  async PC_searchConnections({ commit }, { brand, model }) {
    let params = {
      brand,
      model
    }
    return API_MODERATION.get(`${apiUrls.connectorsSearch}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        return r.task_ids
      })
      .catch((e) => {
        e.$_actionName = "PC_searchConnections"
        commit("handleError", e)
        return Promise.reject(e)
      })
  },
  async PC_checkSearchConnections({ commit }, ids) {
    let params = new URLSearchParams()
    for (let i = 0; i < ids.length; i++) {
      params.append("task_ids", ids[i])
    }
    return API_MODERATION.get(`${apiUrls.check}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        return r
      })
      .catch((e) => {
        e.$_actionName = "PC_checkSearchConnections"
        commit("handleError", e)
        return Promise.reject(e)
      })
  },
  async PC_addConnection({ commit }, item) {
    commit("PC_showLoader", true)
    let sendData = {
      parser_id: item.parser_id,
      parser_type: item.parser_type,
      product_code: item.product_code,
      name: item.name,
      url: item.url,
      images: item.images
    }
    return API_MODERATION.post(`${apiUrls.connections}`, sendData)
      .then((r) => r.data.message.connection)
      .then((r) => {
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        commit("PC_showLoader", false)
        e.$_actionName = "PC_addConnection"
        return Promise.reject(e)
      })
  },
  async PC_deleteConnection({ commit }, item) {
    commit("PC_showLoader", true)
    let params = {
      parser_id: item.parser_id
    }
    return API_MODERATION.delete(`${apiUrls.connections}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        commit("PC_showLoader", false)
        e.$_actionName = "PC_deleteConnection"
        return Promise.reject(e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
