import { computed } from "vue"
import i18n from "@/i18n"

const useTariffsColumns = () => {
  const tariffsListColumns = computed(() => [
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("active"),
      scopedSlots: {
        customRender: "activeRenderer"
      },
      align: "center",
      fixed: "left",
      width: 120
    },
    {
      key: "title",
      dataIndex: `title.uk`,
      title: i18n.t("tariffName"),
      fixed: "left",
      width: 220
    },
    {
      key: "offers_count_limit",
      dataIndex: "offers_count_limit",
      customRender: (item) => (item === 0 ? i18n.t("notLimited") : item),
      title: i18n.t("offersVisibilityTitle"),
      width: 150
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("description"),
      scopedSlots: {
        customRender: "descriptionCell"
      },
      width: 600
    },
    {
      key: "configs",
      dataIndex: "configs",
      title: i18n.t("confAndPrice"),
      scopedSlots: {
        customRender: "configRenderer"
      },
      width: 640
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 50
    }
  ])

  const billingModalColumns = computed(() => [
    {
      key: "expire_in_days",
      title: i18n.t("expireInDays"),
      scopedSlots: {
        customRender: "expire_in_days"
      },
      width: 280
    },
    {
      key: "price",
      title: i18n.t("fullPrice"),
      scopedSlots: {
        customRender: "price"
      },
      width: 280
    },
    {
      key: "price_per_month",
      title: i18n.t("pricePerMonth"),

      scopedSlots: {
        customRender: "monthPrice"
      },
      width: 280
    },
    {
      key: "is_active",
      title: i18n.t("active"),
      scopedSlots: {
        customRender: "active"
      }
    }
  ])

  return {
    tariffsListColumns,
    billingModalColumns
  }
}

export default useTariffsColumns
