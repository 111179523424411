import i18n from "@/i18n"
import { computed } from "vue"

export default function usePropositionsPageColumns() {
  const columns = computed(() => {
    return [
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("created"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        fixed: "left",
        width: 110
      },
      {
        key: "product__p_code",
        dataIndex: "product.p_code",
        title: i18n.t("p_code"),
        fixed: "left",
        width: 110
      },
      {
        key: "code",
        dataIndex: "code",
        title: i18n.t("cs_code"),
        scopedSlots: {
          customRender: "codeRenderer"
        },
        fixed: "left",
        width: 130
      },
      {
        key: "seller__name",
        dataIndex: "seller.name",
        title: i18n.t("seller"),
        width: 150
      },
      {
        key: "product__name",
        dataIndex: "product.name",
        title: i18n.t("offerName"),
        scopedSlots: {
          customRender: "clampedText"
        },
        width: 150
      },
      {
        key: "price",
        dataIndex: "price",
        title: i18n.t("price"),
        scopedSlots: {
          customRender: "sumRenderer"
        },
        sorter: true,
        width: 120
      },
      {
        key: "price_old",
        dataIndex: "price_old",
        title: i18n.t("priceOld"),
        scopedSlots: {
          customRender: "oldSumRenderer"
        },
        sorter: true,
        width: 130
      },
      {
        key: "quantity",
        dataIndex: "quantity",
        title: i18n.t("count"),
        sorter: true,
        width: 120
      },
      {
        key: "condition_key",
        dataIndex: "condition_key",
        title: i18n.t("condition"),
        scopedSlots: {
          customRender: "condition"
        },
        width: 110
      },
      {
        key: "label",
        dataIndex: "label",
        title: i18n.t("label"),
        scopedSlots: {
          customRender: "labelRender"
        },
        width: 140
      },
      {
        key: "description",
        dataIndex: "description",
        title: i18n.t("description"),
        scopedSlots: {
          customRender: "clampedText"
        },
        width: 100
      },
      {
        key: "warranty",
        dataIndex: "warranty",
        title: i18n.t("warranty"),

        scopedSlots: {
          customRender: "warranty"
        },
        width: 90
      },
      {
        key: "images",
        dataIndex: "images",
        title: i18n.t("offerImages"),
        scopedSlots: {
          customRender: "imagesRenderer"
        },
        width: 80
      },
      {
        key: "product_images",
        dataIndex: "product.images",
        title: i18n.t("productImages"),
        scopedSlots: {
          customRender: "imagesRenderer"
        },
        width: 80
      },
      {
        key: "product_reels",
        title: i18n.t("offerVideo"),
        scopedSlots: {
          customRender: "videoRender"
        },
        width: 70
      },
      {
        key: "delivery__name",
        title: i18n.t("deliveryPolicy"),
        scopedSlots: {
          customRender: "deliveryCell"
        },
        width: 140
      },
      {
        key: "moderation_comment",
        dataIndex: "moderation_comment",
        title: i18n.t("moderCommentShorted"),
        scopedSlots: {
          customRender: "commentRenderer"
        },
        width: 120
      },
      {
        key: "is_active",
        dataIndex: "is_active",
        title: i18n.t("offerStatus"),
        scopedSlots: {
          customRender: "status"
        },
        sorter: true,
        fixed: "right",
        width: 110
      },
      {
        key: "moderation_status",
        dataIndex: "moderation_status",
        title: i18n.t("moderationStatusShorted"),
        scopedSlots: {
          customRender: "moderationCell"
        },
        fixed: "right",
        width: 190
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        width: 65,
        fixed: "right"
      }
    ]
  })

  const editPropositionColumns = computed(() => [
    {
      key: "images",
      title: i18n.t("photo"),
      width: 65,
      scopedSlots: {
        customRender: "offerImages"
      }
    },
    {
      key: "code",
      dataIndex: "code",
      title: "CS Code",
      width: 100
    },
    {
      key: "condition_key",
      dataIndex: "condition_key",
      title: i18n.t("state"),
      scopedSlots: {
        customRender: "condition"
      },
      width: 100
    },
    {
      key: "description",
      dataIndex: "description",
      scopedSlots: {
        customRender: "clampedText"
      },
      title: i18n.t("description")
    }
  ])

  const deliveryPolicyColumns = computed(() => {
    return [
      {
        key: "delivery_method",
        dataIndex: "delivery_method",
        title: i18n.t("deliveryMethods")
      },
      {
        key: "shipment_time_in_days",
        dataIndex: "shipment_time_in_days",
        title: i18n.t("shipmentTime")
      },
      {
        key: "delivery_time_in_days_min",
        dataIndex: "delivery_time_in_days_min",
        title: i18n.t("deliveryTimeMin")
      },
      {
        key: "delivery_time_in_days_max",
        dataIndex: "delivery_time_in_days_max",
        title: i18n.t("deliveryTimeMax")
      },
      {
        key: "shipment_city",
        dataIndex: "shipment_city",
        title: i18n.t("shipmentCity")
      },
      {
        key: "payment_methods",
        dataIndex: "payment_methods",
        title: i18n.t("paymentMethods")
      }
    ]
  })

  return { columns, editPropositionColumns, deliveryPolicyColumns }
}
