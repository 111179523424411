export default () => {
  const userEventPrimaryTypes = ["click", "close", "swipe", "focus", "input", "submit", "close"]
  const userEventSecondaryTypes = [
    "focus-leave",
    "focus_leave",
    "page_enter",
    "page_focus",
    "page_focus_leave",
    "page_leave",
    "view"
  ]

  const userEventTypes = userEventPrimaryTypes.concat(userEventSecondaryTypes)

  const eventIcons = {
    click: "edit",
    close: "close-circle",
    swipe: "swap",
    focus: "zoom-in",
    input: "form",
    submit: "file-done",
    "focus-leave": "zoom-out",
    focus_leave: "zoom-out",
    page_enter: "login",
    page_focus: "file-exclamation",
    page_focus_leave: "file-excel",
    page_leave: "logout",
    view: "eye"
  }

  const eventsWithoutElement = ["page_enter", "page_focus", "page_focus_leave", "page_leave"]

  const deviceIcons = {
    Mobile: "mobile",
    Desktop: "desktop",
    Bot: "robot",
    Tablet: "tablet"
  }

  return {
    userEventPrimaryTypes,
    userEventSecondaryTypes,
    userEventTypes,
    eventIcons,
    eventsWithoutElement,
    deviceIcons
  }
}
