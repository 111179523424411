<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showUpdateAndDeleteModalGet"
      :title="'Внимание!'"
    >
      <div>
        <div>
          <label>
            <span
              ><strong
                >В базе данных найдены товары, что используют выбранное значение. Для удаления
                значения, Вам следует выбрать новое для ниже указанных товаров</strong
              ></span
            >
          </label>

          <div v-if="PV_valuesData.items.length !== 1">
            <div class="row">
              <div class="col-sm-6">
                <label><span>Новое значение:</span></label>
                <Select
                  :values="PV_valuesSelectGet"
                  :selected="PV_defaultSelectValueGet"
                  placeholder="Выберите новое"
                  @select="onSelect"
                  @input="onInput"
                />
              </div>
              <div class="col-sm-6">
                <label><span>Удаляемое значение:</span></label>
                <label
                  ><strong
                    ><span>{{ PV_valueToBeDeleted.value }}</span></strong
                  ></label
                >
              </div>
            </div>
          </div>

          <div v-if="PV_valuesData.items.length === 1">
            <label
              ><span
                >Удаляемое значение является единственным и не может быть заменено другим из этого
                списка. Измените параметры атрибута или добавте в список значение для замены</span
              ></label
            >
          </div>

          <hr />
          <Button
            color="primary"
            size="small"
            @click="exportToExcel"
            class="export-button"
          >
            Export
          </Button>
          <div class="product-table">
            <table id="productTable">
              <tr>
                <th>Код товара</th>
                <th>Полное название</th>
              </tr>
              <tr
                v-for="(product, index) in products"
                :key="index"
              >
                <td>{{ product.code }}</td>
                <td>{{ product.name }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableButton"
          color="danger"
          @click="removeValue"
          >Удалить</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import Select from "components/Selects/BasicSelect"
import { mapActions, mapMutations, mapGetters } from "vuex"
import tableToExcel from "utils/tableToExcel"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateValueModal",
  components: {
    Button,
    Modal,
    Input,
    Select
  },
  data() {
    return {
      timeout: null
    }
  },
  watch: {
    PV_showUpdateAndDeleteModalGet() {
      // reset input value
      if (this.$refs.newValue) {
        this.$refs.newValue.value = ""
      }
    }
  },
  methods: {
    exportToExcel() {
      tableToExcel(
        "productTable",
        `Товары, что используют значение_${this.PV_valueToBeDeleted.value}`,
        `Товары, что используют значение_${this.PV_valueToBeDeleted.value}.xls`
      )
    },
    onInput(value) {
      if (!value) return
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        try {
          let values = await this.PV_valuesByDescription(value)
          //filter value which is to be deleted
          let filtered = values.filter((item) => {
            return item.code !== this.PV_valueToBeDeleted.code
          })

          await this.PV_buildSelectForUpdateAndDelete(filtered)
        } catch (error) {
          notifyResponseError({ error })
        }
      }, 500)
    },
    onSelect(value) {
      // код нижче закоментований і перероблений у зв'язку із:
      // - сюди PV_filteredValues не попадають значення після пошуку в селекті
      // - а сюди this.PV_newValueInsteadOfDeleted треба віддати обєкт із свойством code

      // let selected = this.PV_filteredValues.find(item => {
      //   return item.code === value.value;
      // });
      //   // show selected in select
      // this.PV_defaultSelectValue(value);
      // // set it as new value
      // this.PV_newValueInsteadOfDeleted(selected);
      // // remove placeholder from select options
      // if (this.PV_valuesSelectGet[0].value === { label: 'Выберете новое', value: 1 }) {
      //   let newSelectValues = this.PV_valuesSelectGet;
      //   newSelectValues.shift();
      //   this.PV_valuesSelect(newSelectValues);
      // }

      let selected = this.PV_valuesSelectGet.find((item) => {
        return item.value === value.value
      })
      // show selected in select
      this.PV_defaultSelectValue(value)
      // set it as new value
      this.PV_newValueInsteadOfDeleted({ ...selected, code: selected.value })
      // remove placeholder from select options
      if (this.PV_valuesSelectGet[0].value === { label: "Выберете новое", value: 1 }) {
        let newSelectValues = this.PV_valuesSelectGet
        newSelectValues.shift()
        this.PV_valuesSelect(newSelectValues)
      }
    },
    async removeValue() {
      try {
        this.PC_showLoader(true)
        let id = await this.PV_updateAndDelete()
        this.checkWithInterval(id)
      } catch (e) {
        this.handleError(e)
      }
    },
    async checkWithInterval(id) {
      this.checkInterval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]
        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.checkInterval)
          this.closeModal()
        }
        if (response.task_state === "SUCCESS") {
          this.PV_resetDeleteValues()
          this.PV_resetValuesTalbe()
          this.PV_searchValues({
            selected: this.PV_selectedListGet,
            queryParams: this.PV_valuesQueryParamsGet
          })
          // update number of values in lists table (if selected still exist)
          if (this.PV_selectedListCountGet) {
            this.PV_selectedListCount(this.PV_selectedListCountGet - 1)
          }
          this.$notification.info({ message: "Изменения добавлены" })
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения` })
        }
      }, this.celeryInterval)
    },
    closeModal() {
      this.PV_resetDeleteValues()
      this.PV_showUpdateAndDeleteModal(false)
    },
    ...mapMutations("ProductCatalog", {
      PV_newValueInsteadOfDeleted: "PV_newValueInsteadOfDeleted",
      PV_showUpdateAndDeleteModal: "PV_showUpdateAndDeleteModal",
      PV_defaultSelectValue: "PV_defaultSelectValue",
      PV_valuesSelect: "PV_valuesSelect",
      PC_showLoader: "PC_showLoader",
      handleError: "handleError",
      PV_selectedListCount: "PV_selectedListCount"
    }),
    ...mapActions("ProductCatalog", {
      PV_updateAndDelete: "PV_updateAndDelete",
      PV_resetDeleteValues: "PV_resetDeleteValues",
      PV_resetValuesTalbe: "PV_resetValuesTalbe",
      PV_searchValues: "PV_searchValues",
      SH_checkTask: "SH_checkTask",
      PV_valuesByDescription: "PV_valuesByDescription",
      PV_buildSelectForUpdateAndDelete: "PV_buildSelectForUpdateAndDelete"
    })
  },
  computed: {
    enableButton() {
      let show = false
      if (this.PV_newValueInsteadOfDeletedGet) {
        show = true
      }
      return show
    },
    ...mapGetters("ProductCatalog", {
      PV_newValueInsteadOfDeletedGet: "PV_newValueInsteadOfDeleted",
      PV_showUpdateAndDeleteModalGet: "PV_showUpdateAndDeleteModal",
      products: "PV_productsWithValueToBeDeleted",
      PV_valueToBeDeleted: "PV_valueToBeDeleted",
      PV_valuesData: "PV_valuesData",
      PV_valuesSelectGet: "PV_valuesSelect",
      PV_defaultSelectValueGet: "PV_defaultSelectValue",
      PV_filteredValues: "PV_filteredValues",
      PV_selectedListGet: "PV_selectedList",
      PV_valuesQueryParamsGet: "PV_valuesQueryParams",
      PV_selectedListCountGet: "PV_selectedListCount",
      celeryInterval: "celeryInterval"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}

hr {
  border: none;
  border-top: 1px solid rgba(125, 125, 125, 0.2);
  margin-top: 15px;
}

.product-table {
  overflow-y: scroll;
  max-height: 200px;
  max-width: 100%;
  overflow-x: hidden;
}

.product-table {
  td,
  th {
    padding: 0px 10px 0px 10px;
  }

  tr:nth-child(odd) {
    background: #eeeeeebf;
  }
}

.export-button {
  margin-bottom: 5px;
  margin-left: 2px;
}
</style>
