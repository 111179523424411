import Vue from "vue"
import { globalChatSearch, getChatMessages } from "../../../utils/messengerXhrService"
import ROUTE_SOCKET_RESPONSES from "./routeSocketResponses"
import axios from "axios"
import { splitOnChunksBasedOnWords } from "../utils/splitOnChunks.js"
import Cookies from "js-cookie"

const MAX_MESSAGE_LENGTH = 1000

export default {
  ROUTE_SOCKET_RESPONSES, // is called from plugin
  AUTH_MESSENGER_USER({ state }) {
    try {
      Vue.prototype.$messengerSocket.sendObj({
        actions: ["user", "auth"],
        body: {
          anonymous: false,
          authorization: state.getToken(),
          locale: "ru"
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  GET_ALL_CHATS({ state, commit }) {
    commit("CHAT_FIND_REQUEST")

    try {
      Vue.prototype.$messengerSocket.sendObj({
        actions: ["chat", "find"],
        body: {
          q: state.filterKey,
          limit: state.chatsLimit,
          offset: state.chatsOffset
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  async SEARCH({ state, commit }, value) {
    // comparing to GET_ALL_CHATS, this action always has offset = 0 and q != ''
    try {
      commit("SET_FILTER_KEY", value)
      commit("SET_CHATS_OFFSET", 0)
      commit("CHAT_FIND_REQUEST")
      Vue.prototype.$messengerSocket.sendObj({
        actions: ["chat", "find"],
        body: {
          q: state.filterKey,
          limit: state.chatsLimit,
          offset: 0
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  ON_CHAT_FIND_NOTICE({ commit, dispatch, state }, data) {
    const totalFetchedChats = data.body.limit + data.body.offset
    const hasNextPage = data.body.chats_count - totalFetchedChats > 0

    commit("CHAT_FIND_RESPONSE", { hasNextPage })
    commit("SET_CHATS", data)
    commit("SET_UNREAD_MESSAGES", data.body.total_unread_messages_count)
  },
  GET_CHAT_MESSAGES({ state }, { chatUuid, offset }) {
    try {
      Vue.prototype.$messengerSocket.sendObj({
        actions: ["message", "get_all"],
        body: {
          chat_uuid: chatUuid,
          limit: state.messagesLimit,
          offset
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  GET_CHAT_MESSAGES_BY_XHR({ state, dispatch }, { chatUuid, offset }) {
    getChatMessages({ chatUuid, limit: state.messagesLimit, offset, auth: state.auth }).then(
      (response) => {
        const data = response.data

        dispatch("ON_CHAT_MESSAGES_NOTICE", {
          body: {
            chat_uuid: chatUuid,
            results: data.results,
            offset
          }
        })
      }
    )
  },
  ON_CHAT_MESSAGES_NOTICE({ commit }, data) {
    commit("BOTTOM_MESSAGES_LOADER", false)
    commit("TOP_MESSAGES_LOADER", false)
    commit("SET_MESSAGES", { data, id: data.body.chat_uuid })
  },
  CHAT_WITH_SELLER({ state, commit }, sellerId) {
    // is called from marketplace component
    if (!state.socket.isConnected || state.socket.connecting) return
    // save it to resend if captcha required
    commit("SET_LATEST_INIT_REQUEST", {
      type: "withSeller",
      payload: { sellerId }
    })

    try {
      let body = {
        user_uuid: sellerId
      }

      Vue.prototype.$messengerSocket.sendObj({
        actions: ["chat", "init"],
        body
      })
    } catch (e) {
      console.log(e)
    }
  },
  ON_CHAT_SELLER_NOTICE({ commit, state, dispatch }, data) {
    if (state.user.role === "seller") {
      commit("ADD_CHAT", data.body)
      commit("INCREMENT_UNREAD_MESSAGES", 1)
    } else if (state.user.role === "customer" || state.user.role === "moderator") {
      dispatch("ON_CHAT_INIT", data)
    }
  },
  ON_CHAT_MODERATOR_NOTICE({ commit, state, dispatch }, data) {
    if (state.user.role === "moderator") {
      commit("ADD_CHAT", data.body)
      commit("INCREMENT_UNREAD_MESSAGES", 1)
    } else if (state.user.role === "seller" || state.user.role === "customer") {
      dispatch("ON_CHAT_INIT", data)
    }
  },
  ON_CHAT_INIT({ commit, dispatch, state }, data) {
    if (data.is_new) {
      // if chat is new, add it to the top
      commit("ADD_CHAT", data.body)
    } else if (!data.is_new) {
      // if old (existing), find it by uuid
      const chatIndex = state.chats.findIndex((item) => item.uuid === data.body.uuid)
      const chat = state.chats.find((item) => item.uuid === data.body.uuid)
      if (chat) {
        // lift chat
        commit("LIFT_CHAT", chatIndex)
        // set messages
        commit("SET_MESSAGES", {
          data: { body: { results: data.body.messages, offset: 0 } },
          id: chat.uuid
        })
      } else if (!chat) {
        // if not found, take info from data response and move to the top
        commit("ADD_CHAT", data.body)
        // set current
        commit("SELECT_CHAT", data.body.uuid)
        // set messages
        commit("SET_MESSAGES", {
          data: { body: { results: data.body.messages, offset: 0 } },
          id: data.body.uuid
        })
      }
    }
    commit("SELECT_CHAT", data.body.uuid)
  },

  ON_MESSAGE_NEW_NOTICE({ state, commit, dispatch }, data) {
    // look for the chat
    const chat = state.chats.find((item) => item.uuid === data.body.chat_uuid)
    const chatIndex = state.chats.findIndex((item) => item.uuid === data.body.chat_uuid)
    // if there's one, add the message (for both sender and receiver)
    if (chat) {
      const files = []
      const images = []

      if (data.body.attachments && data.body.attachments.length) {
        data.body.attachments.forEach((item) => {
          if (item.type === "image") images.push(item)
          else files.push(item)
        })
      }

      commit("ADD_NEW_MESSAGE", {
        message: {
          ...data.body,
          date: data.body.created_at,
          self: data.body.author.uuid === state.user.uuid,
          content: data.body.body,
          files,
          images
        },
        uuid: data.body.chat_uuid
      })

      commit("UPDATE_LAST_MESSAGE", {
        message: {
          ...data.body,
          date: data.body.created_at,
          self: data.body.author.uuid === state.user.uuid,
          content: data.body.body
        },
        uuid: data.body.chat_uuid
      })

      commit("LIFT_CHAT", chatIndex)
    } else {
      // get that damn chat and add it to the top afterwards (for receiver)
      dispatch("GET_DAMN_CHAT", data.body.chat_uuid)
    }

    const isMyselfMessage = data.body.author.uuid === state.user.uuid
    const itIsMessageFromCurrentChat = data.body.chat_uuid === state.currentChatId

    // mark incoming message as read if it's in the view
    // upd: now, we read messages only after focus on textarea
    if (!isMyselfMessage && state.textareaIsFocused && itIsMessageFromCurrentChat) {
      dispatch("READ_MESSAGES", state.currentChatId)
    }

    if (!isMyselfMessage) {
      // update global unread count
      commit("INCREMENT_UNREAD_MESSAGES", 1)
      // update those chat unread count
      commit("INCREMENT_CHAT_UNREAD", { id: data.body.chat_uuid, value: 1 })
    }

    commit("INCREMENT_CHAT_OFFSET", { uuid: data.body.chat_uuid, value: 1 })

    //hide 'is typing'
    commit("SHOW_TYPING", false)
  },
  READ_MESSAGES({ state, commit, getters }, id) {
    try {
      // update general unread messages count (count - chat_unread)
      commit("SET_UNREAD_MESSAGES", state.unreadMessages - getters.chat.unread_count)
      // reset chat unread messages to 0
      commit("RESET_CHAT_UNREAD", id)
      // send request
      Vue.prototype.$messengerSocket.sendObj({
        actions: ["message", "read_all"],
        body: {
          chat_uuid: id
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  GET_DAMN_CHAT({ state }, id) {
    try {
      Vue.prototype.$messengerSocket.sendObj({
        actions: ["chat", "get"],
        body: {
          chat_uuid: id,
          messages_limit: 1
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  ON_CHAT_GET_NOTICE({ commit }, data) {
    commit("ADD_CHAT", data.body)
    commit("INCREMENT_CHATS_OFFSET", 1)
  },
  ON_USER_OFFLINE_NOTICE({ commit }, data) {
    commit("UPDATE_ONLINE_STATUS", { uuid: data.body.uuid, status: data.body.is_online })
  },
  ON_USER_ONLINE_NOTICE({ commit }, data) {
    commit("UPDATE_ONLINE_STATUS", { uuid: data.body.uuid, status: data.body.is_online })
  },

  ON_SELLERS_ONLINE_NOTICE({ commit }, data) {
    commit("SET_SELLERS_PIT", data.body.results)
  },
  ON_SELLER_ONLINE_NOTICE({ commit }, data) {
    commit("PUSH_SELLERS_PIT", data.body.results[0])
  },
  ON_SELLER_OFFLINE_NOTICE({ commit }, data) {
    commit("REMOVE_FROM_SELLERS_PIT", data.body.results[0])
  },
  SOCKET_ONOPEN_ACTION({ state, commit, dispatch }) {
    // is called from plugin
    commit(`RESET_CHATS`)
    commit(`SOCKET_ONOPEN`)
    dispatch("AUTH_MESSENGER_USER")
  },
  GLOBAL_CHAT_SEARCH_BY_XHR({ state, dispatch, commit }) {
    if (!state.globalQuery.length) return

    commit("CHAT_FIND_REQUEST")

    globalChatSearch({
      value: state.globalQuery.join(),
      limit: state.chatsLimit,
      offset: state.chatsOffset,
      auth: state.auth
    })
      .then((response) => {
        const data = response.data

        if (!data.count) {
          return commit("RESET_CHATS")
        } else {
          commit("SET_CHATS", {
            body: {
              results: data.results,
              offset: response.config.params.offset
            }
          })
        }

        const totalFetchedChats = response.config.params.limit + response.config.params.offset
        const hasNextPage = data.count - totalFetchedChats > 0

        commit("CHAT_FIND_RESPONSE", { hasNextPage })
      })
      .catch(() => {
        commit("CHAT_FIND_RESPONSE", { hasNextPage: true })
      })
  },
  ON_INFORM_TYPING_NOTICE({ commit }, data) {
    // methods in components are subscribed to this action
    // console.log(`user ${data.body.username} is typing`)
  },
  ON_SESSION_ID_NOTICE({}, data) {
    const oneHour = 3600 // seconds
    document.cookie = `spv_messenger_session=${data.body.sessionid}; max-age=${oneHour}`
  },
  ON_PROFILE_NOTICE({ commit }, data) {
    if (data.body.uuid) commit("SET_USER_UUID", data.body.uuid)
    if (data.body.first_name) commit("SET_USER_NAME", data.body.first_name)
    if (data.body.email) commit("SET_USER_EMAIL", data.body.email)
  },
  ON_UPDATE_PROFILE_NOTICE({ commit }, data) {
    if (data.body.first_name) commit("SET_USER_NAME", data.body.first_name)
    if (data.body.email) commit("SET_USER_EMAIL", data.body.email)
  },
  ON_CAPTCHA_NOTICE({}) {
    // we subscribe to this action from mp project to show a modal with captcha
  },

  initChat({ commit, dispatch }, seller) {
    dispatch("hasChat", seller)
    const requestId = +`${Math.random()}`.substring(2)

    commit("SET_LATEST_INIT_REQUEST", {
      type: "withSeller",
      payload: { sellerId: seller.uuid, requestId }
    })
  },

  hasChat({ state, commit, dispatch }, seller) {
    const chatBySeller = state.chats.find((chat) => seller.uuid === chat.owner.uuid)

    if (!chatBySeller) {
      dispatch("CHAT_WITH_SELLER", seller.uuid)
    } else {
      dispatch("getChatMessages", {
        chatUuid: chatBySeller.uuid,
        offset: 0,
        unreadCount: chatBySeller.unread_count
      })
      commit("SELECT_CHAT", chatBySeller.uuid)
    }
  },

  getChatMessages({ state, commit }, { chatUuid, offset, unreadCount }) {
    Vue.prototype.$messengerSocket.sendObj({
      actions: ["message", "get_all"],
      body: {
        chat_uuid: chatUuid,
        limit: state.messagesLimit + unreadCount || 0,
        offset
      }
    })
  },

  ON_MESSAGE_EDIT_NOTICE({ commit, state }, { body }) {
    const targetChat = state.chats.find((chat) => chat.uuid === body.chat_uuid)
    const isLastMessage = targetChat.last_message.uuid === body.uuid

    if (isLastMessage) {
      commit("UPDATE_LAST_MESSAGE", {
        message: { ...body, content: body.body },
        uuid: body.chat_uuid
      })
    }

    commit("MESSAGE_EDITED", body)
  },

  REMOVE_MESSAGE(_, { chatUuid, messageUuid }) {
    Vue.prototype.$messengerSocket.sendObj({
      actions: ["message", "remove"],
      body: {
        chat: chatUuid,
        message: messageUuid
      }
    })
  },

  ON_MESSAGE_REMOVE_NOTICE({ commit, state }, { body }) {
    const targetChat = state.chats.find((chat) => chat.uuid === body.chat_uuid)
    const isLastMessage = targetChat.last_message.uuid === body.uuid

    if (isLastMessage) {
      commit("UPDATE_LAST_MESSAGE", {
        message: { ...body, content: body.body },
        uuid: body.chat_uuid
      })
    }

    commit("MESSAGE_REMOVED", body)
  }
}
