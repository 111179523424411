<template>
  <div>
    <div class="wrapper">
      <div
        v-if="fetching"
        class="wrapper__loader"
      >
        <a-spin size="large" />
      </div>

      <div v-else>
        <GalleryStandalong
          :images="images"
          @onSort="handleSort"
          @onRemove="handleRemove"
          @onEditClick="handleOpenEdit"
        />

        <EditProductImagesCrop
          :visible="visibleCropModal"
          :imgSrc="editableImageSrc"
          :imgName="editableImageName"
          :product="product"
          @onClose="handleCloseEdit"
          @onUpdateImages="$emit('onUpdateImages', $event)"
        />

        <EditProductImagesUpload
          :product="product"
          @onUpdateImages="onAddImages"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import GalleryStandalong from "@/components/Gallery/GalleryStandalong/GalleryStandalong.vue"
import EditProductImagesCrop from "./_components/EditProductImagesCrop.vue"
import EditProductImagesUpload from "./_components/EditProductImagesUpload.vue"
import { updateProductRu } from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import { computed, nextTick, onMounted, ref, watch } from "vue"

const props = defineProps({
  mpcCode: {
    type: String,
    required: true
  },
  product: {
    type: Object,
    default: () => ({})
  },
  fetching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["onUpdateImages", "onUpdateImages"])

const images = ref([])
const visibleCropModal = ref(false)
const editableImageSrc = ref("")
const editableImageName = ref("")

const formatedImages = computed(() => {
  if (!props.product?.images?.length) return []

  return props.product.images.map((item) => ({
    on_cdn: item.on_cdn,
    filename: item.filename,
    item: item.on_cdn
      ? IMAGES_CDN + "/media/assets/images/" + getImagePath(item.filename, "full")
      : item.filename
  }))
})

const onAddImages = (newImages) => {
  images.value = [...images.value, ...newImages]

  nextTick(() => {
    handleUpdateImages()
    emit("onUpdateImages", images.value)
  })
}

const handleOpenEdit = (item) => {
  editableImageSrc.value = item.item
  editableImageName.value = item.filename
  visibleCropModal.value = true
}

const handleCloseEdit = () => {
  editableImageSrc.value = ""
  editableImageName.value = ""
  visibleCropModal.value = false
}

const handleSort = (newImages) => {
  images.value = newImages

  nextTick(() => {
    handleUpdateImages()
  })
}

const handleRemove = (index) => {
  images.value.splice(index, 1)
  emit("onUpdateImages", images.value)
  handleUpdateImages()
}

const handleUpdateImages = async () => {
  const data = {
    code: props.product.code,
    product: {
      images: images.value
    }
  }

  try {
    await updateProductRu(data)

    notification.success({
      message: i18n.t("imagesChangesSaved")
    })
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("imagesChangesFailed") })
  }
}

onMounted(() => {
  images.value = [...formatedImages.value]
})

watch(
  () => props.mpcCode,
  (value) => {
    if (value) return
    images.value = []
  }
)

watch(
  () => formatedImages.value,
  (value) => {
    images.value = [...value]
  }
)
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;
}
</style>
