<template>
  <span :class="{ 'limit-is-exceeded': limitIsExceeded }">
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      required: true
    },

    value: {
      default: 0
    }
  },

  computed: {
    limitIsExceeded() {
      const ordersLimitCount = this.row.amount_free_orders || 0
      const noTariff = !this.row.tariff_config

      return noTariff && this.value >= ordersLimitCount
    }
  }
}
</script>

<style lang="scss" scoped>
.limit-is-exceeded {
  color: $red-color;
}
</style>
