import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const actions = {
  async AE_replaceAttrValue({ commit, state, dispatch }, sendData) {
    return API_MODERATION.put(apiUrls.attributeEditValue, sendData)
      .then((r) => r.data)
      .then((r) => {
        notification.info({ message: "Изменения добавлены" })
        return r
      })
      .catch((e) => {
        e.$_actionName = "AE_removeAttrValue"
        return Promise.reject(e)
      })
  },
  async AE_removeAttrValue({ commit, state, dispatch }, url) {
    return API_MODERATION.delete(url)
      .then((r) => r.data)
      .then((r) => {
        notification.info({ message: "Изменения добавлены" })
        return r
      })
      .catch((e) => {
        e.$_actionName = "AE_removeAttrValue"
        return Promise.reject(e)
      })
  }
}

export default {
  actions
}
