<template>
  <div class="table-cell__new-value">
    <div
      v-if="AE_selectedValueTypeGet === 'MULTILIST' && this.AE_selectedAttr.value_type === 'STRING'"
    >
      <div
        v-for="val in row.new_values"
        :key="val"
      >
        {{ val }}
      </div>
    </div>
    <span v-else>
      {{ row.new_value }}
    </span>
    <a-icon
      v-if="invalidError"
      class="table-cell__icon-error"
      type="exclamation-circle"
    />
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  props: ["row"],
  computed: {
    invalidError() {
      if (this.row.invalid) {
        return this.row.invalid == "VALUE" || this.row.invalid == "BOTH"
      }
      return false
    },
    ...mapState("ProductCatalog/attributesEdit", {
      AE_selectedAttr: (state) => state.search.AE_selectedAttr,
      AE_selectedValueTypeGet: (state) => state.conversion.AE_selectedValueType
    })
  }
}
</script>

<style lang="scss">
.table-cell__new-value {
  position: relative;
}
.table-cell__icon-error {
  color: $danger-color;
  position: absolute;
  right: -4px;
  top: -4px;
}
</style>
