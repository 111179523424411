<template>
  <div class="attributes-edit__replacement">
    <div class="row">
      <div class="col replacement__section">
        <label class="section__label"><span>Найти значение:</span></label>
        <div class="section__input">
          <Input
            :value="oldValue"
            :disabled="!allowEditing"
            @input="oldValueInput"
          />
        </div>
        <Button
          class="section__button"
          color="primary"
          :disabled="!allowEditing || !allowRemoval"
          @click="onRemove"
        >
          Удалить
        </Button>
      </div>
    </div>
    <div class="row">
      <div class="col replacement__section">
        <label class="section__label"><span>Заменить на:</span></label>
        <div class="section__input">
          <Input
            :value="newValue"
            :disabled="!allowEditing"
            @input="newValueInput"
          />
        </div>
        <Button
          class="section__button"
          color="primary"
          :disabled="!allowEditing || !allowReplacement"
          @click="onReplace"
        >
          Заменить
        </Button>
      </div>
    </div>
    <div class="row">
      <div class="col replacement__checkboxes">
        <Checkbox
          :checked="allProductsCheckbox"
          class="checkbox"
          style="margin-right: 10px"
          :disabled="!allowEditing"
          @change="toggleCheckboxes"
        >
          По всем товарам с атрибутом
        </Checkbox>
        <Checkbox
          :checked="selectedProductsCheckbox"
          class="checkbox"
          :disabled="!allowEditing"
          @change="toggleCheckboxes"
        >
          Согласно фильтров ({{ AE_tableData.count }})
        </Checkbox>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "components/Input"
import Button from "components/Button"
import Checkbox from "components/Checkbox"
import { mapState, mapMutations, mapActions } from "vuex"
import regeneratorRuntime from "regenerator-runtime"
import { buildDataTableGetUrl } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

export default {
  components: {
    Input,
    Button,
    Checkbox
  },
  data() {
    return {
      oldValue: "",
      newValue: "",
      allProductsCheckbox: true,
      selectedProductsCheckbox: false
    }
  },
  computed: {
    allowEditing() {
      return (
        this.AE_selectedAttr &&
        this.AE_tableData.items.length &&
        this.AE_selectedAttr.value_type !== "LIST" &&
        this.AE_selectedAttr.value_type !== "MULTILIST" &&
        this.AE_selectedValueTypeGet !== "LIST" &&
        this.AE_selectedValueTypeGet !== "MULTILIST"
      )
    },
    allowRemoval() {
      return (
        !!this.oldValue &&
        this.AE_selectedAttr.value_type !== "LIST" &&
        this.AE_selectedAttr.value_type !== "MULTILIST" &&
        this.AE_selectedValueTypeGet !== "LIST" &&
        this.AE_selectedValueTypeGet !== "MULTILIST"
      )
    },
    allowReplacement() {
      return !!this.oldValue && !!this.newValue
    },
    ...mapState("ProductCatalog/attributesEdit", {
      AE_selectedAttr: (state) => state.search.AE_selectedAttr,
      AE_selectedValueTypeGet: (state) => state.conversion.AE_selectedValueType,
      AE_tableData: (state) => state.table.AE_tableData,
      AE_queryParams: (state) => state.table.AE_queryParams
    })
  },
  methods: {
    oldValueInput(value) {
      this.oldValue = value
    },
    newValueInput(value) {
      this.newValue = value
    },
    toggleCheckboxes() {
      this.allProductsCheckbox = !this.allProductsCheckbox
      this.selectedProductsCheckbox = !this.selectedProductsCheckbox
    },
    async onRemove() {
      try {
        this.PC_showLoader(true)
        let url
        if (this.selectedProductsCheckbox) {
          url = buildDataTableGetUrl(apiUrls.attributeEditValue, this.AE_queryParams)
          url += `&attribute=${this.AE_selectedAttr.code}&value=${this.oldValue}`
        } else {
          url = `${apiUrls.attributeEditValue}?attribute=${this.AE_selectedAttr.code}&value=${this.oldValue}`
        }
        await this.AE_removeAttrValue(url)
        this.resetValues()
        this.AE_tableSearch({ selectedAttrCode: this.AE_selectedAttr.code })
      } catch (e) {
        this.handleError(e)
      } finally {
        this.PC_showLoader(false)
      }
    },
    async onReplace() {
      try {
        this.PC_showLoader(true)
        let sendData = {}
        if (this.selectedProductsCheckbox) {
          let params = this.AE_queryParams
          let filters
          if (!!params.filters) {
            filters = Object.keys(params.filters)
              .filter((key) => {
                return !!params.filters[key].value
              })
              .map((key) => {
                const name = params.filters[key].name
                const value = params.filters[key].value
                if (Array.isArray(value)) {
                  return { [`${name}_min`]: value[0], [`${name}_max`]: value[1] }
                }
                return { [name]: value }
              })
            Object.assign(sendData, ...filters)
          }
          sendData.attribute = this.AE_selectedAttr.code
          sendData.old_value = this.oldValue
          sendData.new_value = this.newValue
        } else {
          sendData = {
            attribute: this.AE_selectedAttr.code,
            old_value: this.oldValue,
            new_value: this.newValue
          }
        }
        await this.AE_replaceAttrValue(sendData)
        this.resetValues()
        this.AE_tableSearch({ selectedAttrCode: this.AE_selectedAttr.code })
      } catch (e) {
        this.handleError(e)
      } finally {
        this.PC_showLoader(false)
      }
    },
    resetValues() {
      this.oldValue = ""
      this.newValue = ""
      this.allProductsCheckbox = true
      this.selectedProductsCheckbox = false
    },
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_removeAttrValue: "AE_removeAttrValue",
      AE_replaceAttrValue: "AE_replaceAttrValue",
      AE_checkTask: "AE_checkTask",
      AE_tableSearch: "AE_tableSearch"
    })
  }
}
</script>
<style lang="scss">
.attributes-edit {
  &__replacement {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 15px 15px;
    height: 160px;

    .replacement {
      &__section {
        display: flex;

        .section {
          &__label {
            width: 35%;
            margin-right: 10px;
          }

          &__input {
            width: 40%;
            margin-right: 10px;
          }

          &__button {
            width: 25%;
          }
        }
      }

      @media (max-width: 1459px) {
        &__checkboxes {
          .checkbox {
            display: block;
          }
        }
      }
    }
  }
}
</style>
