<template>
  <a-spin
    :spinning="isFetching"
    style="max-width: 1420px; margin: 0 auto"
  >
    <!-- ADD NEW FILTER -->
    <a-form
      :form="addFilterForm"
      layout="vertical"
      class="add-filter"
    >
      <template v-for="lang in languages">
        <a-form-item
          :label="`${$t('filterName')} ${lang.label}`"
          :required="false"
        >
          <a-input v-decorator="[lang.value, { rules: nameRules }]" />
        </a-form-item>
      </template>
      <a-form-item
        :label="$t('filterType')"
        :required="false"
      >
        <a-select
          v-decorator="['type', { rules: [{ required: true, message: $t('emptyError') }] }]"
          :options="filterTypeOptions"
        />
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          icon="plus"
          @click="handleAddNewFilter"
        >
          {{ $t("add") }} {{ $t("filter").toLowerCase() }}
        </a-button>
      </a-form-item>
    </a-form>
    <!-- TABLE -->

    <a-table
      :dataSource="filters"
      :columns="columns"
      :components="components"
      :rowKey="(record, index) => `${record.uuid}_${index}`"
      :pagination="false"
      :scroll="{ x: true }"
      class="filters-table"
    >
      <template #handleRenderer>
        <div class="handle">
          <a-icon type="menu" />
        </div>
      </template>

      <template
        v-for="columnKey in languages"
        #[columnKey.value]="record, _, index"
      >
        <TableEditableCellInput
          :text="record.name[columnKey.value]"
          :key="columnKey.value + record.name[columnKey.value] + index"
          :class="{
            'red-text':
              checkIsNameDuplicate(columnKey.value, record.name[columnKey.value]) ||
              !record.name[columnKey.value].trim()
          }"
          @change="(value, closeEdit) => editCell(columnKey.value, value, record, index, closeEdit)"
        />
      </template>

      <template #filterTypeRender="record, _, index">
        <a-select
          style="width: 100%"
          :value="record.type"
          :options="filterTypeOptions"
          @change="(e) => onFieldChange(e, index, 'type')"
        />
      </template>

      <template #rangeRender="values, record">
        <a-button
          v-if="record.type === 'custom_range_filter'"
          class="custom-range-input"
          :class="{ 'red-text': !values || !values.length }"
          @click="editCustomRangeFilter(record.id)"
        >
          {{ values?.length || 0 }}
          <a-icon type="edit" />
        </a-button>
      </template>

      <template #rangeCalculating="record">
        <div :class="{ 'red-text': missingOffers[record.id] }">
          {{
            missingOffers[record.id] !== undefined ? missingOffers[record.id] : $t("noDataShorted")
          }}
        </div>
      </template>

      <template #actions="record, _, index">
        <div class="action-buttons">
          <a-tooltip :title="$t('calculateCount')">
            <a-button
              type="dashed"
              shape="circle"
              @click="calculateOffers(record)"
            >
              <a-icon type="undo" />
            </a-button>
          </a-tooltip>
          <a-tooltip :title="$t('downloadFileWithOffers')">
            <a-button
              type="dashed"
              shape="circle"
              :disabled="!missingOffers[record.id]"
              @click="downloadFilterOffers(record)"
            >
              <a-icon type="download" />
            </a-button>
          </a-tooltip>
          <a-popconfirm
            :title="$t('deletingConfirmation')"
            @confirm="handleDeleteFilter(record, index)"
          >
            <a-button
              type="dashed"
              shape="circle"
            >
              <a-icon type="delete" />
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>

    <!-- CALCULATE ALL FITERS(No api for this now) -->

    <!-- <div class="total-missing">
      {{ $t("notInAllFilters") }}:
      <span class="total-missing__counter">
        {{ totalMissingCount !== undefined ? totalMissingCount : $t("noDataShorted") }}
      </span>
      <a-button
        style="margin-left: 86px"
        type="dashed"
        shape="circle"
        @click="calculateAllOffers()"
      >
        <a-icon type="undo" />
      </a-button>
      <a-button
        type="dashed"
        shape="circle"
        :disabled="totalMissingCount === undefined || totalMissingCount === 0"
        @click="downloadAllFilterOffers()"
      >
        <a-icon type="download" />
      </a-button>
    </div> -->
  </a-spin>
</template>

<script>
import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper"
import TableEditableCellInput from "@/ant-components/TableEditableCellInput"

import useMPAConst from "@/modules/MPAdmin/constants.js"
import useCategoriesPageColumns from "../../useCategoriesPageColumns"

import {
  fetchOffersMissingFilter,
  deleteFilterById
} from "@/modules/MPAdmin/services/categoriesService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  components: {
    TableEditableCellInput
  },

  props: {
    languages: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      default: () => []
    },
    filtersIsChanged: {
      type: Boolean,
      default: false
    },
    isFetching: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { filterTypeOptions } = useMPAConst()
    const { filtersColumns } = useCategoriesPageColumns()

    return { columns: filtersColumns, filterTypeOptions }
  },

  data() {
    return {
      components: {
        body: {
          wrapper: TableGraggableWrapper
        }
      },

      addFilterForm: this.$form.createForm(this, { name: "addFilterForm" }),

      totalMissingCount: undefined,
      missingOffers: {}
    }
  },
  provide() {
    const appData = {}
    Object.defineProperty(appData, "dataSource", {
      get: () => this.filters
    })

    return {
      appData
    }
  },

  watch: {
    filters() {
      if (this.isFetching) return
      this.$emit("filtersChanged")
    }
  },

  computed: {
    nameRules() {
      return [{ required: true, message: this.$t("emptyError") }]
    }
  },

  methods: {
    onFieldChange(value, index, field) {
      this.$emit("handleFieldChange", { value, index, field })
    },

    async calculateOffers(rowItem) {
      if (rowItem.id) {
        try {
          const response = await fetchOffersMissingFilter(this.$route.params.id, rowItem.id)
          this.missingOffers = { ...this.missingOffers, [rowItem.id]: response.data.count }

          this.$message.success(this.$t("calculatingIsSuccess"))
        } catch {
          this.$message.error(this.$t("calculatingFailed"))
        }
      }
    },

    calculateAllOffers() {},

    downloadFilterOffers(rowItem) {
      this.$emit("downloadFilterOffers", rowItem)
    },

    downloadAllFilterOffers() {},

    editCell(key, value, rowItem, index, closeEdit) {
      closeEdit()
      this.onFieldChange({ ...rowItem.name, [key]: value }, index, "name")
    },

    handleAddNewFilter() {
      this.addFilterForm.validateFields((err, values) => {
        if (err) return
        const { type, ...name } = values
        const newFilter = {
          id: null,
          name: name,
          type: type
        }

        this.$emit("handleAddNewFilter", newFilter)
        this.addFilterForm.resetFields()
      })
    },

    async handleDeleteFilter(record, index) {
      if (record.id) {
        try {
          await deleteFilterById(this.$route.params.id, record.id)
          this.$notification.success({
            message: this.$t("deleted")
          })
          this.$emit("deleteFilter", index)
        } catch (error) {
          notifyResponseError({ error })
        }
      } else {
        this.$emit("deleteFilter", index)
      }
    },

    editCustomRangeFilter(id) {
      if (this.filtersIsChanged) {
        this.$notification.warning({
          message: this.$t("editCustomRangeFilterWarning")
        })
      } else {
        this.$router.push({
          name: "EditFilterCustomRanges",
          params: { id: this.$route.params.id, filterId: id }
        })
      }
    },

    checkIsNameDuplicate(key, value) {
      const encounteredValues = new Set()

      for (const row of this.filters) {
        const currentValue = row.name?.[key]
        if (currentValue === value) {
          if (encounteredValues.has(value)) {
            return true
          }
          encounteredValues.add(value)
        }
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-table {
  min-height: 400px;
}
.action-buttons {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
}

.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
.custom-range-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background-color;
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 4px 12px;
}

.total-missing {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 16px 56px;
  gap: 8px;
  color: $grey-font;
  &__counter {
    min-width: 50px;
    color: $font-default;
    text-align: right;
  }
}
.add-filter {
  display: flex;
  margin-left: 66px;
  gap: 16px;
  & > * {
    width: 224px;
    font-weight: 500;
  }
  & > :last-child {
    display: flex;
    align-items: flex-end;
  }
}
.red-text {
  color: $red-color;
  border-color: $red-color;
}
</style>
