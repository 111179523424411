<template>
  <a-drawer
    :visible="showModal"
    :title="prototype.id ? $t('editingDeliveryService') : $t('creatingDeliveryService')"
    closable
    :width="700"
    @close="$emit('close')"
  >
    <a-form
      :form="deliveryServiceForm"
      style="margin-bottom: 40px"
    >
      <a-form-item
        :colon="false"
        style="margin: 0"
      >
        <template #label>{{ $t("name") }}, UA ({{ ukNameLength }}/{{ nameMaxLength }})</template>
        <a-input
          v-decorator="[
            'name_uk',
            {
              rules: nameRules,
              initialValue: ''
            }
          ]"
          @change="(e) => (ukNameLength = e.target.value.length || 0)"
        />
      </a-form-item>

      <a-form-item
        :colon="false"
        style="margin: 0"
      >
        <template #label>{{ $t("name") }}, EN ({{ enNameLength }}/{{ nameMaxLength }}) </template>
        <a-input
          v-decorator="[
            'name_en',
            {
              rules: nameRules,
              initialValue: ''
            }
          ]"
          @change="(e) => (enNameLength = e.target.value.length || 0)"
        />
      </a-form-item>

      <a-form-item
        :colon="false"
        style="margin: 0"
      >
        <template #label>{{ $t("name") }}, RU ({{ ruNameLength }}/{{ nameMaxLength }}) </template>
        <a-input
          v-decorator="[
            'name_ru',
            {
              rules: nameRules,
              initialValue: ''
            }
          ]"
          @change="(e) => (ruNameLength = e.target.value.length || 0)"
        />
      </a-form-item>

      <a-form-item
        :label="$t('outputSortOrder')"
        :colon="false"
        style="margin: 0"
      >
        <a-input-number
          v-decorator="[
            'ordering',
            {
              initialValue: 0
            }
          ]"
          :min="0"
          :max="999"
          :precision="0"
        />
      </a-form-item>

      <div
        v-if="imagePath"
        class="logo"
      >
        <img :src="imagePath" />
      </div>

      <FileLoader
        ref="fileInput"
        style="padding: 16px 12px"
        @change="handleChooseFiles"
        :limit="1"
        :allowedFormats="allowedFormats"
        :acceptTypes="acceptTypes"
        :maxKb="5 * 1024"
      />

      <a-form-item
        :label="$t('deliveryServiceActivity')"
        :colon="false"
        style="margin: 0"
      >
        <a-switch
          v-decorator="[
            'is_active',
            {
              valuePropName: 'checked',
              initialValue: false
            }
          ]"
          :checkedChildren="$t('yes')"
          :unCheckedChildren="$t('no')"
        />
      </a-form-item>

      <div class="drawer-footer">
        <a-button
          class="form-button-padding"
          @click="$emit('close')"
        >
          {{ $t("close") }}
        </a-button>
        <a-button
          type="primary"
          :loading="fetching"
          class="form-button-padding"
          @click="onSave"
        >
          {{ $t("apply") }}
        </a-button>
      </div>
    </a-form>
  </a-drawer>
</template>

<script>
import FileLoader from "@/components/FileLoader"
import { IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"

import {
  createDeliveryService,
  updateDeliveryService
} from "@/modules/MPAdmin/services/generalSettingService/deliveryService"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateDeliveryMethodsModal",

  components: {
    FileLoader
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    prototype: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      fetching: false,
      images: [],
      allowedFormats: ["jpg", "jpeg", "png", "gif", "bmp"],
      acceptTypes: ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp"],
      nameMaxLength: 200,
      ukNameLength: 0,
      enNameLength: 0,
      ruNameLength: 0
    }
  },

  beforeCreate() {
    this.deliveryServiceForm = this.$form.createForm(this, { name: "DeliveryServiceModal" })
  },

  methods: {
    async onSave() {
      this.deliveryServiceForm.validateFields(async (err, values) => {
        if (err) return
        if (this.images.length === 0 && !this.prototype.id) {
          this.$notification.error({
            message: this.$t("chooseLogo")
          })
          return
        }
        this.fetching = true

        const { name_uk, name_en, name_ru, ordering, is_active } = values

        let actionData = new FormData()
        actionData.append(
          "name",
          JSON.stringify({
            ru: name_ru,
            uk: name_uk,
            en: name_en
          })
        )

        this.images.forEach((file) => {
          actionData.append("image", file)
        })
        actionData.append("ordering", ordering)
        actionData.append("is_active", is_active)

        try {
          if (this.prototype.id) {
            const { data } = await updateDeliveryService(actionData, this.prototype.id)
            this.$emit("updateService", data)
          } else {
            await createDeliveryService(actionData)
            this.$emit("createService")
          }

          this.$notification.success({
            message: this.$t(this.prototype.id ? "updated" : "created")
          })
        } catch (error) {
          notifyResponseError({
            error,
            message: this.$t(this.prototype.id ? "updatingError" : "creatingError")
          })
        } finally {
          this.fetching = false
        }
      })
    },
    handleChooseFiles({ files }) {
      this.images = files
    }
  },
  computed: {
    imagePath() {
      return this.prototype && this.prototype.image
        ? `${IMAGES_CDN}/media/assets/images/${getImagePath(this.prototype.image, "full")}`
        : ""
    },

    nameRules() {
      return [
        { required: true, message: this.$t("fieldRequire") },
        { max: 200, message: this.$t("maxLengthError", { length: 200 }) }
      ]
    }
  },
  watch: {
    showModal(val) {
      this.ukNameLength = this.prototype?.name?.uk?.length || 0
      this.enNameLength = this.prototype?.name?.en?.length || 0
      this.ruNameLength = this.prototype?.name?.ru?.length || 0

      if (val && this.prototype.id) {
        this.$nextTick(() => {
          const { name, api_integration, ...data } = this.prototype
          this.deliveryServiceForm.setFieldsValue({
            name_uk: name.uk,
            name_en: name.en,
            name_ru: name.ru,
            ...data
          })
        })
      } else {
        if (this.$refs.fileInput?.files?.length) {
          this.$refs.fileInput.removeFile(0)
        }
        this.deliveryServiceForm.resetFields()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 120px;
  width: 120px;

  border: 1px solid #d9d9d9;
  border-radius: 2px;

  margin: 10px 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
