import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"

/**
 * reusable action
 * is used to
 * 1)fetch attrs of editable product (on click of edit product button)
 * 2)fetch a single 'new attribute' which is to be added to product attrs (add attribute modal)
 * 3)fetch attrs of a given product in order to add them to product attrs (copy attrs from product modal)
 * 4)to check attrs on type change, and on load new attr or coping attrs from another product
 * 5)after missing required attributes were added
 */

const actions = {
  async PC_fetchProductAttributes(
    { commit, getters, dispatch },
    { product, attributes, mutationName, newAttribute, checkAttributes, localeHeader }
  ) {
    if (checkAttributes) {
      // check if product has attributes
      if (!product.attributes || product.attributes.length === 0) return
    }
    try {
      // we'll need all product attributes and all attributes ATLs
      // action for attributes
      let fetchedAttributes = await dispatch("PC_fetchProductAttributesObjects", {
        product,
        attributes,
        localeHeader
      })
      if (!fetchedAttributes) fetchedAttributes = []

      // action for atls
      let fetchedATLsData
      if (product.type) {
        fetchedATLsData = await dispatch("PC_fetchProductAtlsObjects", { product, attributes })
      } else {
        fetchedATLsData = null
      }

      // function to build table data
      let attributesData = buildProductAttributesTableData({
        product,
        attributes,
        mutationName,
        newAttribute,
        fetchedAttributes,
        fetchedATLsData
      })

      // commit a mutation
      if (newAttribute) {
        commit(mutationName, { items: attributesData.items })
      } else {
        commit(mutationName, { attributesData })
      }
    } catch (e) {
      commit("handleError", e)
    } finally {
      // hide loader
      commit("PC_showLoader", false)
    }
    return true
  },
  async PC_fetchProductAttributesObjects(
    { commit, getters },
    { product, attributes, localeHeader }
  ) {
    let params = new URLSearchParams()
    attributes.forEach((item) => {
      params.append("code", item.code)
    })
    params.append("complex", true)

    // show loader
    commit("PC_showLoader", true)

    let headers = {}
    if (localeHeader) {
      headers["Accept-language"] = getters.PC_languageForEditLocaleModal
    }

    return API_MODERATION.get(
      // get attributes
      `${apiUrls.attribute}`,
      { params, headers }
    ).then((r) => r.data.message.records)
  },
  async PC_fetchProductAtlsObjects({ commit, getters }, { product, attributes }) {
    let keysNames = [] // for atl search
    attributes.forEach((item) => {
      keysNames.push(item.code)
    })
    // only search ATLs if product type is defined
    let searchData

    let typeCode = (product.type[0] && product.type[0].code) || product.type.code
    searchData = buildMagicSearch({
      resource: "ATL",
      field: ["attribute", "product_type"],
      value: [keysNames, typeCode],
      sort: null,
      limit: keysNames.length,
      offset: 0
    })

    // show loader
    commit("PC_showLoader", true)

    return API_MODERATION.post(
      // search ATLs for each attribute
      `${apiUrls.searchMagic}`,
      searchData
    ).then((r) => r.data.message.data)
  },
  async PC_restartFetchAttributes({ commit, getters, dispatch }) {
    let sendObject = {
      product: getters.PC_EditableProduct,
      attributes: getters.PC_EditableProduct.attributes,
      mutationName: "PC_setEditableProductAttributes",
      newAttribute: false,
      checkAttributes: true
    }
    return await dispatch("PC_fetchProductAttributes", sendObject)
  },
  async PC_updateAttributesChanges({ commit, getters, dispatch }) {
    //update product.attributes object according to changes log
    await dispatch("PC_updateValueChanges", getters.PC_EditableProduct)
    await dispatch("PC_updateUnitChanges", getters.PC_EditableProduct)
  }
}

export default {
  actions
}

function buildProductAttributesTableData({
  product,
  attributes,
  mutationName,
  newAttribute,
  fetchedAttributes,
  fetchedATLsData
}) {
  // sort fetched attrs to match product attrs order
  if (fetchedAttributes.length > 1) {
    fetchedAttributes = sortAttributesLikeInProduct(fetchedAttributes, product)
  }

  // build all necessary table fields
  let items = fetchedAttributes
  for (let i = 0; i < fetchedAttributes.length; i++) {
    let currentAttribute = fetchedAttributes[i]
    let defaultUnit = fetchedAttributes[i].default_unit
    let defaultUnits = fetchedAttributes[i].possible_units
    let correspondingAtl

    if (fetchedATLsData !== null && fetchedATLsData !== undefined) {
      // some types can have no ATLs and respond with an empty array
      if (fetchedATLsData.length === 0) {
        correspondingAtl = null
      } else {
        correspondingAtl = fetchedATLsData.find((item) => {
          return item.attribute === currentAttribute.code
        })
      }
    } else {
      correspondingAtl = null
    }

    // id
    items[i]._id = "attributesTableRow-" + i

    if (
      correspondingAtl !== null &&
      correspondingAtl !== undefined &&
      fetchedATLsData !== undefined
    ) {
      // is_main
      items[i].is_main = (correspondingAtl && correspondingAtl.is_main) || ""
      if (correspondingAtl.is_main === true) items[i].is_main = "Да"
      if (correspondingAtl.is_main === false) items[i].is_main = "Нет"
      // is_required
      items[i].is_required = (correspondingAtl && correspondingAtl.is_required) || ""
      if (correspondingAtl.is_required === true) items[i].is_required = "Да"
      if (correspondingAtl.is_required === false) items[i].is_required = "Нет"
      // order
      items[i].order = (correspondingAtl && correspondingAtl.order) || ""
    } else {
      items[i].is_main = ""
      items[i].is_required = ""
      items[i].order = ""
    }

    if (currentAttribute.default_unit === null) {
      items[i].defaultUnitName = ""
      items[i].unitsNames = ""
    } else {
      // but default unit still can have no base and as a result no linked units
      // default unit name
      items[i].defaultUnitName = defaultUnit.name
      // linked units names
      items[i].unitsNames = defaultUnits
      // assign unitsNames to defaultUnitName in case if there no linked units
      if (items[i].unitsNames === null) items[i].unitsNames = defaultUnit.name
    }
    // unit value
    if (newAttribute) {
      items[i].unitValue = ""
    } else {
      if (currentAttribute.possible_values) {
        items[i].unitValue = currentAttribute.possible_values
      } else {
        items[i].unitValue = product.attributes[i].value
      }
    }
  }

  let attributesData = {
    count: fetchedAttributes.length,
    items
  }

  return attributesData
}

function sortAttributesLikeInProduct(fetchedAttributes, product) {
  // loop through product attrs and push fetchedAttributes with same code to an array
  let sortedAttributes = []
  for (let i = 0; i < product.attributes.length; i++) {
    // extract code of product attr
    let code = product.attributes[i].code
    // corresponding attr in fetched attrs
    let wanted = fetchedAttributes.find((item) => {
      return item.code === code
    })

    sortedAttributes.push(wanted)
  }
  return sortedAttributes
}
