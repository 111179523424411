import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_valuesQueryParams: {
    limit: 10,
    ordering: ["-value"],
    offset: 0
  },
  PV_valuesData: { count: 0, items: [] },
  PV_valuesFetchingStatus: false
})

const getters = {
  PV_valuesQueryParams: (state) => state.PV_valuesQueryParams,
  PV_valuesData: (state) => state.PV_valuesData,
  PV_valuesFetchingStatus: (state) => state.PV_valuesFetchingStatus
}

const mutations = {
  PV_valuesQueryParams(state, value) {
    state.PV_valuesQueryParams = value
  },
  PV_valuesData(state, value) {
    state.PV_valuesData = value
  },
  PV_valuesFetchingStatus(state, value) {
    state.PV_valuesFetchingStatus = value
  },
  PV_valuesSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    state.PV_valuesData.items[rowIndex][columnKey] = newValue
  }
}

const actions = {
  async PV_searchValues({ commit, dispatch, getters }, { selected, queryParams }) {
    let field = []
    let value = []
    if (queryParams.filters) {
      field = queryParams.filters.map((f) => {
        if (f.name === "value_ru" && f.value) {
          return "value"
        } else if (f.value) {
          return f.name
        }
      })
      value = queryParams.filters.map((f) => {
        if (f.name && f.value) {
          return f.value
        }
      })
    }
    field.push("description")
    value.push(`= ${selected.description}`)

    queryParams.ordering = queryParams.ordering.map((orderName) => {
      return orderName.replace("value_ru", "value")
    })

    let sendData = buildMagicSearch({
      resource: "VLD",
      field,
      value,
      sort: queryParams.ordering,
      limit: queryParams.limit,
      offset: queryParams.offset
    })
    // set fetching status
    commit("PV_valuesFetchingStatus", true)
    return API_MODERATION.post(
      // get vaues lists
      `${apiUrls.searchMagicV2}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        let valuesCount = message.count
        let values = message.data
        let items = buildValuesDescriptionTableItems(values)
        let tableObj = {
          count: valuesCount,
          items: items
        }
        commit("PV_valuesData", tableObj)

        commit("PV_valuesFetchingStatus", false)

        commit("PV_valuesQueryParams", queryParams)
        return message
      })
      .catch((e) => {
        commit("PV_valuesFetchingStatus", false)
        commit("handleError", e)
        return null
      })
  },
  PV_editValuesCell({ getters, commit }, { columnKey, newValue, rowIndex, accept_lang = "ru" }) {
    let oldValue = getters.PV_valuesData.items[rowIndex][columnKey]
    if (newValue === oldValue) return

    let sendData = {
      code: getters.PV_valuesData.items[rowIndex].code,
      value: newValue
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.value}`, sendData, {
      headers: {
        "Accept-language": accept_lang
      }
    })
      .then((r) => {
        commit("PV_valuesSetNewValue", { columnKey, newValue, rowIndex })

        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  PV_resetValuesTalbe({ commit }) {
    commit("PV_valuesQueryParams", {
      limit: 10,
      filteredColumns: [],
      ordering: [],
      offset: 0
    })
    commit("PV_valuesData", { count: 0, items: [] })
    commit("PV_valuesFetchingStatus", { count: 0, items: [] })
    commit("PV_valuesFetchingStatus", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildValuesDescriptionTableItems(values) {
  return values.map((item, index) => {
    // id
    item._id = `valuesRow-${index}`
    return item
  })
}
