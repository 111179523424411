import { API } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import axios from "axios"

const state = () => ({
  PC_imagesModal: false
})

const getters = {
  PC_imagesModal: (state) => state.PC_imagesModal
}

const mutations = {
  PC_imagesModal(state, value) {
    state.PC_imagesModal = value
  }
}

const actions = {
  async PC_imagesModal({ commit, getters, dispatch }, product) {
    try {
      commit("PC_showLoader", true)
      // reset
      commit("PC_resetEditedProductImages")
      commit("PC_setEditableProduct", { brand: { name: "" }, type: { name: "" } })
      commit("PC_productConnections", false)
      // fetch recent data
      product = await dispatch("PC_fetchSelectedProductData", { product, size: "full" })
      // set
      commit("PC_setEditableProduct", product)
      await dispatch("PC_productConnections", product)
      commit("PC_imagesModal", true)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_updateProductTableImageUrls({ commit, getters }) {
    // find editable product index among table products
    let products = getters.PC_productTableData.items
    let product = getters.PC_EditableProduct
    let index = products.findIndex((item) => {
      return item.code === product.code
    })
    let newImagesUrls = product.imagesUrls
    commit("PC_productTableUpdateImagesUrls", { newImagesUrls, index })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
