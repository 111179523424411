<template>
  <div>
    <a-layout-header class="header">
      <div
        v-if="loginRequest || fetching"
        class="header__loader"
      >
        <a-spin size="large" />
      </div>
      <template v-else>
        <div class="logo" />

        <Navigation v-if="isLogged" />

        <div style="margin-left: auto">
          <!-- <LangSwitcher /> -->

          <LoginNav />
        </div>
      </template>
    </a-layout-header>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Navigation from "./Navigation/Navigation"
import LoginNav from "@/modules/Auth/components/LoginNav"
import Loader from "@/components/Loader"
import LangSwitcher from "@/components/LangSwitcher.vue"

export default {
  name: "Header",
  components: {
    Navigation,
    LoginNav,
    Loader,
    LangSwitcher
  },
  props: {
    fetching: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("Auth", {
      isLogged: (state) => state.isLogged,
      loginRequest: (state) => state.loginRequest
    })
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  position: "fixed";
  z-index: 1;
  width: "100%";
  background-color: #fff;
  align-items: center;

  &__loader {
    text-align: center;
    width: 100%;
  }
}
</style>
