<template>
  <div>
    <div
      v-for="note in items"
      :style="{ marginBottom: '5px' }"
    >
      <span :style="{ fontWeight: 500, marginRight: '10px' }">{{
        getFormatedDateTime(note.created_at)
      }}</span>
      <template v-if="note.type === 'MISSED_CALL'">
        {{ $t("failedCall") }}
      </template>
      <template v-if="note.type === 'ORDER_CANCELLED'">
        {{ canceledText }} {{ note.body }}
      </template>
      <template v-else>
        {{ note.body }}
      </template>
    </div>
  </div>
</template>

<script>
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

export default {
  name: "OrderNotes",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    orderStatus: {
      type: String,
      required: true
    },
    rejectReason: {
      type: String,
      default: ""
    }
  },
  computed: {
    canceledText() {
      let whoCanceled = ""

      switch (this.orderStatus) {
        case "CANCELED_BY_SELLER":
          whoCanceled = this.$t("orderStatuses.canceled_by_seller")
          break
        case "CANCELED_BY_SUPERVISOR":
          whoCanceled = this.$t("orderStatuses.canceled_by_supervisor")
          break
        case "CANCELED_BY_CUSTOMER":
          whoCanceled = this.$t("orderStatuses.canceled_by_customer")
      }

      if (whoCanceled) {
        return `${whoCanceled}. ${this.rejectReason}`
      }

      return this.rejectReason
    }
  },
  methods: {
    getFormatedDateTime
  }
}
</script>
