<template>
  <div>
    <a-table
      :pagination="false"
      :loading="fetchingSummaryCpa"
      :dataSource="balanceDataSource"
      :columns="balanceTableColumns"
      :defaultExpandedRowKeys="[1]"
      size="middle"
    >
      <template #balanceCell="balance">
        <a-input-group compact>
          <a-input-number
            :value="balance"
            :parser="(value) => value.replace(' ', '')"
            :formatter="(value) => getFormatedSum(value)"
            disabled
            class="balance-input"
            :class="{ 'negative-sum': balance < 0 }"
          />

          <ChangeBalancePopover
            :visible="addToBalanceVisible"
            :balanceChangeIsFetching="balanceChangeIsFetching"
            @changeVisibility="addToBalanceVisible = !addToBalanceVisible"
            @submit="changeBalance"
          />

          <ChangeBalancePopover
            :visible="takeFromBalanceVisible"
            :balanceChangeIsFetching="balanceChangeIsFetching"
            negative
            @changeVisibility="takeFromBalanceVisible = !takeFromBalanceVisible"
            @submit="changeBalance"
          />
        </a-input-group>
      </template>

      <template #divideCell="text">
        {{ `${getFormatedSum(text.total)} ${$t("uah")}` }}
        <a-divider class="cell-divider" />
        {{ `${getFormatedSum(text.count, true)} ${$t("pieces")}` }}
      </template>

      <template #sumCell="sum">
        <span :class="{ 'negative-sum': sum < 0 }">
          {{ `${getFormatedSum(sum)} ${$t("uah")}` }}
        </span>
      </template>

      <template #expandedRowRender>
        <a-table
          :pagination="false"
          :loading="fetchingSummaryCpa"
          :dataSource="balanceDataSource"
          :columns="additionalBalanceTableColumns"
          size="middle"
          :rowKey="(_, index) => index"
        >
          <template #divideCell="text">
            {{ `${getFormatedSum(text.total)} ${$t("uah")}` }}
            <a-divider class="cell-divider" />
            {{ `${getFormatedSum(text.count, true)} ${$t("pieces")}` }}
          </template>
        </a-table>
      </template>

      <template #footer>
        <div class="write-off-button">
          <a-button
            type="primary"
            ghost
            :disabled="substractDisabled"
            :loading="subtrackingFetching"
            @click="handleSubtractCPA()"
          >
            {{ $t("debit") }}
          </a-button>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import ChangeBalancePopover from "./ChangeBalancePopover.vue"

import useSellerColumns from "@/modules/ADS/pages/Sellers/useSellerColumns.js"
import getFormatedSum from "@/utils/getFormatedSum.js"

import {
  getSummaryCpa,
  subtractSeller,
  replenishBilling
} from "@/modules/ADS/services/sellerServices.js"

import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  seller: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["onChangeSeller", "onChangeBalance"])

const { additionalBalanceTableColumns, balanceTableColumns } = useSellerColumns()

const fetchingSummaryCpa = ref(true)
const balanceDataSource = ref([])
const addToBalanceVisible = ref(false)
const takeFromBalanceVisible = ref(false)
const balanceChangeIsFetching = ref(false)
const substractDisabled = ref(false)
const subtrackingFetching = ref(false)

const getBalance = async () => {
  try {
    fetchingSummaryCpa.value = true
    const { data } = await getSummaryCpa(props.seller.seller_id)

    const fields = {
      available: "applied_total",
      on_hold: "on_hold",
      not_available: "canceled_total"
    }

    const payload = {}

    for (const key of Object.keys(data)) {
      if (typeof data[key] === "number") {
        payload[key] = data[key]
      } else if (fields[key]) {
        payload[fields[key]] = data[key].total
      }
    }

    balanceDataSource.value[0] = { key: 1, ...data }
    if (!data.available.total && !data.not_available.total) {
      substractDisabled.value = true
    }

    emit("onChangeSeller", payload)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    fetchingSummaryCpa.value = false
  }
}

const handleSubtractCPA = async () => {
  try {
    subtrackingFetching.value = true

    await subtractSeller(props.seller.seller_id)

    notifySuccess()
    getBalance()
    emit("onChangeBalance")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    subtrackingFetching.value = false
  }
}

const changeBalance = async ({ value, negative }) => {
  if (balanceChangeIsFetching.value) return

  if (typeof value !== "number") {
    notification.error({
      message: i18n.t("error"),
      description: i18n.t("floatError")
    })
    return
  }

  if (value < 0.01) {
    notification.error({
      message: i18n.t("error"),
      description: i18n.t("incorrectSumError")
    })
    return
  }

  try {
    balanceChangeIsFetching.value = true

    await replenishBilling(props.seller.uuid, { amount: negative ? -value : value })

    getBalance()
    notifySuccess()
    addToBalanceVisible.value = false
    takeFromBalanceVisible.value = false
    emit("onChangeBalance")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    nextTick(() => {
      balanceChangeIsFetching.value = false
    })
  }
}

const notifySuccess = () => {
  notification.success({
    message: i18n.t("success")
  })
}

onMounted(getBalance)

watch(addToBalanceVisible, (visible) => {
  if (!visible) return
  takeFromBalanceVisible.value = false
})

watch(takeFromBalanceVisible, (visible) => {
  if (!visible) return
  addToBalanceVisible.value = false
})
</script>

<style scoped lang="scss">
.cell-divider {
  margin: 4px -4px;
}

.write-off-button {
  display: flex;
  justify-content: flex-end;
}

.balance-input {
  width: 60%;
}

.negative-sum {
  color: $red-color;
}
</style>
