var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticStyle:{"margin-bottom":"40px"},attrs:{"form":_vm.paymentForm}},[_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("name"))+", UA ("+_vm._s(_vm.ukNameLength)+"/"+_vm._s(_vm.nameMaxLength)+")")]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name.uk',
        {
          rules: _vm.nameRules,
          initialValue: ''
        }
      ]),expression:"[\n        'name.uk',\n        {\n          rules: nameRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.ukNameLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("name"))+", EN ("+_vm._s(_vm.enNameLength)+"/"+_vm._s(_vm.nameMaxLength)+") ")]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name.en',
        {
          rules: _vm.nameRules,
          initialValue: ''
        }
      ]),expression:"[\n        'name.en',\n        {\n          rules: nameRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.enNameLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("name"))+", RU ("+_vm._s(_vm.ruNameLength)+"/"+_vm._s(_vm.nameMaxLength)+") ")]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name.ru',
        {
          rules: _vm.nameRules,
          initialValue: ''
        }
      ]),expression:"[\n        'name.ru',\n        {\n          rules: nameRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.ruNameLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('outputSortOrder'),"colon":false}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'ordering',
        {
          initialValue: 0
        }
      ]),expression:"[\n        'ordering',\n        {\n          initialValue: 0\n        }\n      ]"}],attrs:{"min":0,"max":999,"precision":0}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("description"))+" ("+_vm._s(_vm.descriptionLength)+"/"+_vm._s(_vm.descriptionMaxLength)+") ")]},proxy:true}])},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'description',
        {
          rules: _vm.descriptionRules,
          initialValue: ''
        }
      ]),expression:"[\n        'description',\n        {\n          rules: descriptionRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.descriptionLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0","width":"120px"},attrs:{"label":_vm.$t('apiIntegration'),"colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_integration']),expression:"['api_integration']"}],attrs:{"placeholder":_vm.$t('no')}},[_c('a-select-option',{attrs:{"value":undefined}},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")]),_vm._l((_vm.paymentsApiIntegration),function(apiIntegration){return _c('a-select-option',{key:apiIntegration,attrs:{"value":apiIntegration}},[_vm._v(" "+_vm._s(apiIntegration)+" ")])})],2)],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('paymentMethodActivity'),"colon":false}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'is_active',
        {
          valuePropName: 'checked'
        }
      ]),expression:"[\n        'is_active',\n        {\n          valuePropName: 'checked'\n        }\n      ]"}],attrs:{"checkedChildren":"ON","unCheckedChildren":"OFF"}})],1),_c('div',{staticClass:"drawer-footer"},[_c('a-button',{staticClass:"form-button-padding",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('a-button',{staticClass:"form-button-padding",attrs:{"type":"primary","loading":_vm.isFetching},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("apply"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }