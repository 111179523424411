<template>
  <div v-if="AE_showTableGet">
    <h3>Выбранные товары с указанным атрибутом</h3>
    <SynDataTable
      :data="AE_tableDataGet"
      :queryAction="queryAction"
      :queryParams="AE_queryParamsGet"
      :fetching="AE_fetchingStatusGet"
      rowKey="product"
      :columns="dataTableColumns"
      tableId="AttributesEdit"
      class="margin-top"
      defaultOrder="-timestamp"
      :headerButtons="headerButtons"
      :limitValues="limitValues"
      theme="odd"
    />
  </div>
</template>
<script>
import storeMapMixin from "./storeMapMixin"
import dataTableColumnsMixin from "./dataTableColumnsMixin"
import regeneratorRuntime from "regenerator-runtime"
import { mapState, mapMutations } from "vuex"

export default {
  mixins: [storeMapMixin, dataTableColumnsMixin],
  data() {
    return {
      timeout: null,
      interval: null,
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ]
    }
  },
  computed: {
    ...mapState("ProductCatalog/attributesEdit", {
      AE_selectedValueTypeGet: (state) => state.conversion.AE_selectedValueType,
      AE_selectedAttr: (state) => state.search.AE_selectedAttr
    }),
    headerButtons() {
      return [
        {
          label: "Применить",
          action: () => {
            this.submit()
          },
          theme: "success",
          disabled:
            (this.AE_selectedAttr.value_type === "LIST" &&
              this.AE_selectedValueTypeGet === "LIST") ||
            (this.AE_selectedAttr.value_type === "MULTILIST" &&
              this.AE_selectedValueTypeGet === "MULTILIST")
        },
        {
          label: "Отменить",
          action: () => {
            this.EA_cancel(this.AE_selectedAttrGet.code)
          },
          theme: "danger"
        }
      ]
    }
  },
  methods: {
    queryAction(queryParams) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.AE_queryParams(queryParams)
        this.AE_tableSearch({ selectedAttrCode: this.AE_selectedAttrGet.code })
      }, 500)
    },
    async submit() {
      try {
        this.PC_showLoader(true)
        let selectedAttrCode = this.AE_selectedAttrGet.code
        //request
        let id = await this.EA_submitChanges(selectedAttrCode)
        this.checkWithInterval(id)
      } catch (e) {
        this.handleError(e)
      }
    },
    async checkWithInterval(id) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]
        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.interval)
        }
        if (response.task_state === "SUCCESS") {
          let code = response.task_result.status_code
          if (code === 200) {
            this.$notification.success({ message: "Изменения добавлены" })
            this.AE_resetSearchValues()
            this.resetConversionValues()
            this.resetTableValues()
          } else if (code === 400) {
            this.$notification.error({
              message: "Вы должны исправить все ошибки перед сохранением"
            })
            //filter table by errors
            let selectedAttrCode = this.AE_selectedAttrGet.code
            this.EA_filterErrors(selectedAttrCode)
          }
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
        }
      }, this.celeryInterval)
    }
  }
}
</script>
<style lang="scss"></style>
