<template>
  <span ref="mainInput">
    <Autocomplete
      :data="PU_editCellAutocomplete"
      event="input"
      @event="emitInput($event, field, rowIndex)"
      :size="'small'"
      @select="submitEditCell($event, field, rowIndex)"
      :placeholder="row[field]"
      @esc="cancelEditCell"
      @blur="cancelEditCell"
    ></Autocomplete>
  </span>
</template>

<script>
import Autocomplete from "components/Autocomplete"
import { mapGetters } from "vuex"

export default {
  components: {
    Autocomplete
  },
  name: "InputEdit",
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onEvent: Function,
    onSelect: Function
  },
  data() {
    return {
      timeout: null
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
  },
  methods: {
    emitInput(event, field) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let sendObject = {
          columnKey: field,
          newValue: event,
          row: this.row
        }
        this.onEvent(sendObject)
      }, 500)
    },
    submitEditCell(event, field, rowIndex) {
      let sendObject = {
        columnKey: field,
        newValue: event,
        rowIndex
      }
      this.onSelect(sendObject)
      this.close()
    },
    cancelEditCell() {
      this.close()
    }
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PU_editCellAutocomplete: "PU_editCellAutocomplete"
    })
  }
}
</script>
<style scoped>
span {
  display: inline-block;
  width: 90%;
  margin: 7px;
}
</style>
