import { render, staticRenderFns } from "./SyncStatusRender.vue?vue&type=template&id=279898b8&scoped=true"
import script from "./SyncStatusRender.vue?vue&type=script&setup=true&lang=js"
export * from "./SyncStatusRender.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SyncStatusRender.vue?vue&type=style&index=0&id=279898b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279898b8",
  null
  
)

export default component.exports