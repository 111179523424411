import ADS from "./ADS/uk"
import common from "./common/uk"
import messenger from "./Messenger/uk"
import MPAdmin from "./MPAdmin/uk"
import Tags from "./Tags/uk"
import Moderation from "./Moderation/uk"
import Auth from "./Auth/uk"
import UserManagment from "./UserManagment/uk"

export default {
  ...ADS,
  ...common,
  ...MPAdmin,
  ...messenger,
  ...Tags,
  ...Moderation,
  ...Auth,
  ...UserManagment
}
