<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="AE_wrongStatusModalGet"
      :title="'Внимание!'"
    >
      <div>
        <template v-if="AE_wrongStatusAttrGet.status == 'IDLE'">
          <label
            ><span
              >Атрибут <strong>"{{ AE_wrongStatusAttrGet.name }}"</strong> имеет статус
              <strong>"IDLE"</strong> и не подлежит редактированию</span
            ></label
          >
        </template>
        <template
          v-if="
            AE_wrongStatusAttrGet.value_type !== 'STRING' &&
            AE_wrongStatusAttrGet.value_type !== 'NUMERIC'
          "
        >
          <label>
            <span>
              Атрибут <strong>"{{ AE_wrongStatusAttrGet.name }}" </strong> имеет тип
              <strong>{{ AE_wrongStatusAttrGet.value_type }}</strong> и не подлежит редактированию
            </span>
          </label>
        </template>
      </div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapMutations, mapState } from "vuex"

export default {
  components: {
    Button,
    Modal
  },
  computed: {
    ...mapState("ProductCatalog/attributesEdit", {
      AE_wrongStatusModalGet: (state) => state.search.AE_wrongStatusModal,
      AE_wrongStatusAttrGet: (state) => state.search.AE_wrongStatusAttr
    })
  },
  methods: {
    closeModal() {
      this.AE_wrongStatusModal(false)
      this.AE_resetSearchValues()
    },
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_wrongStatusModal: "AE_wrongStatusModal",
      AE_resetSearchValues: "AE_resetSearchValues"
    })
  }
}
</script>
<style lang="scss"></style>
