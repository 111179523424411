<template>
  <div>
    <a-input
      v-if="!onlyEnter"
      v-model="value"
      :placeholder="placeholder"
      @input="handleInput"
    />
    <a-input
      v-else
      v-model="value"
      :placeholder="placeholder"
      @enter="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: "SearchFilter",
  props: {
    placeholder: {
      type: String,
      default: "Search"
    },
    tableQueryAction: Function,
    tableQueryParams: Object,
    columnKey: String,
    filters: Array,
    updateFilters: Function,
    onlyEnter: Boolean
  },
  data() {
    return {
      value: ""
    }
  },
  created() {
    const initialFilter = (this.filters || []).find((f) => f.name === this.columnKey) || {}

    this.value = initialFilter.value || ""
  },
  methods: {
    handleInput(value) {
      const filters = this.filters.map((filter) => {
        let newFilters = {}

        if (filter.name === this.columnKey) {
          newFilters = {
            name: filter.name,
            value: value.target.value
          }
        } else {
          newFilters = filter
        }

        return newFilters
      })

      this.tableQueryAction({
        ...this.tableQueryParams,
        offset: 0,
        filters
      })

      this.updateFilters(filters)
    }
  }
}
</script>

<style scoped></style>
