<template>
  <div
    class="message__main"
    :class="{ message__self: isSelf }"
  >
    <a-avatar
      class="message__avatar"
      :loadError="() => false"
      :size="50"
      :src="userAvatarAbsolutePath"
    />

    <div class="message__align-center">
      <div class="message__info">
        <div
          class="message__info-header"
          v-if="!isRemoved"
        >
          <p class="message__name">
            {{ username }}
          </p>
          <p class="message__time">
            {{ createdAt }}
          </p>
        </div>
        <div class="message__content">
          <div v-if="isRemoved">
            <a-icon
              type="delete"
              style="font-size: 14px"
            />
            {{ $t("messageDeleted") }}
          </div>
          <div v-else-if="messageBody">
            <div class="content__text">
              <pre v-if="!mediaContent.length">{{ messageBody }}</pre>

              <!-- CONTENT WITH MEDIA CONTENT -->
              <span
                v-else
                v-for="(record, index) in mediaContent"
                :key="index"
              >
                <!-- PRODUCT CODE -->
                <a
                  v-if="record.type === 'pCode'"
                  :href="getProductUrlByProductCode(record.body)"
                  target="_blank"
                  >{{ record.body }}</a
                >

                <!-- LINKS -->
                <a
                  v-else-if="record.type === 'link'"
                  :href="record.body"
                  target="_blank"
                  >{{ record.body }}</a
                >

                <!-- SIMPLE TEXT -->
                <pre v-else>{{ record.body }}</pre>
              </span>
            </div>
          </div>
          <template
            v-if="
              (!isRemoved || moderatorSearchComponent) && message.images && message.images.length
            "
          >
            <div class="content__images">
              <span
                v-for="(img, index) in message.images"
                :key="index"
                class="content__image-container"
              >
                <img
                  :src="img.file"
                  alt="img"
                  class="content__image"
                  @load="onLoad"
                  @click="
                    openGallery({
                      images: message.images.map((img) => img.file),
                      startIndex: index
                    })
                  "
                />
                <span
                  class="content__images-download-wrap"
                  @click="$emit('loadImage', img.file)"
                >
                  <a-icon
                    class="content__images-download"
                    type="download"
                  />
                </span>
              </span>
            </div>
          </template>
          <template
            v-if="(!isRemoved || moderatorSearchComponent) && message.files && message.files.length"
          >
            <p
              v-for="(file, index) in message.files"
              :key="`${file}-${index}`"
              class="content__file"
            >
              <a @click.prevent="$emit('loadFile', file.file)">
                <a-avatar
                  class="file__icon"
                  :size="32"
                  icon="file"
                />
                <div>
                  <div class="file__name">{{ file.name_original }}</div>
                  <span>{{ $t("download") }}</span>
                </div>
              </a>
            </p>
          </template>
          <span
            v-if="!isRemoved"
            class="message__status"
          >
            <span
              style="padding-left: 8px"
              v-if="!isRemoved && message.status === 'read'"
            >
              <a-icon
                class="message__status-read"
                type="check"
              />
              <a-icon
                class="message__status-read"
                type="check"
              />
            </span>
            <a-icon
              v-else-if="!isRemoved && message.status === 'sent'"
              class="message__status-sent"
              type="check"
            />
            <span v-if="isEdited || (isRemoved && moderatorSearchComponent)">
              <template v-if="isEdited">{{ $t("edit") }}</template>
              <span
                class="message__edit-history"
                v-if="moderatorSearchComponent && isEdited"
              >
                (<a
                  @click.prevent="
                    $emit('showEditHistory', {
                      messageUuid: message.uuid,
                      originalMessageBody: messageBody
                    })
                  "
                  >{{ $t("changeHistory").toLowerCase() }}</a
                >)
              </span>
              <template v-if="isRemoved && moderatorSearchComponent">{{
                $t("messageDeleted")
              }}</template>
            </span>
          </span>
        </div>
      </div>
      <div class="message__options">
        <a-dropdown
          v-if="isSelf && !isRemoved && !moderatorSearchComponent"
          @visibleChange="dwopdownIsVisible = $event"
          :trigger="['click']"
          placement="bottomLeft"
          overlayClassName="force-dropdown-placement"
          :getPopupContainer="getPopupContainer"
        >
          <div
            class="message-wrapper__options"
            :style="dwopdownIsVisible ? 'opacity: 1' : null"
            ref="dropdownContainer"
          >
            <a-icon type="more" />
          </div>

          <a-menu
            slot="overlay"
            class="dropdown-overlay"
            @click="handleDropdownClick($event.key)"
            @click.native.stop
          >
            <a-menu-item
              v-if="message.type === 'SIMPLE'"
              key="edit"
              ><a-icon type="edit" /> {{ $t("edit") }}
            </a-menu-item>
            <a-menu-item
              key="delete"
              class="dropdown-overlay__danger"
            >
              <a-icon type="delete" /> {{ $t("delete") }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageItem",
  props: {
    message: {
      type: Object,
      required: true
    },
    isSelf: {
      type: Boolean,
      required: true
    },
    isRemoved: {
      type: Boolean,
      required: true
    },
    isEdited: {
      type: Boolean,
      required: true
    },
    moderatorSearchComponent: {
      type: Boolean,
      required: true
    },
    userAvatarAbsolutePath: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    mediaContent: {
      type: Array,
      required: true
    },
    messageBody: {
      type: String,
      required: true
    },
    chatUuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dwopdownIsVisible: false
    }
  },
  methods: {
    getProductUrlByProductCode(pCode = "") {
      let url = ""

      try {
        url = `${new URL(process.env.VUE_APP_API_MARKETPLACE).origin}/product/slug/${pCode}`
      } finally {
        return url
      }
    },
    getPopupContainer() {
      return this.$refs.dropdownContainer
    },
    handleDropdownClick(key) {
      this.dwopdownIsVisible = false

      if (key === "delete") {
        this.$emit("remove", this.message.uuid)
      } else if (key === "edit") {
        this.$emit("edit", {
          chatUuid: this.chatUuid,
          messageUuid: this.message.uuid,
          messageBody: this.message.body
        })
      }
    },
    openGallery(galleryOptions) {
      this.$root.$emit("showMessengerGallery", galleryOptions)
    },
    onLoad(e) {
      const imageEl = e.target

      if (!imageEl) return

      const { height } = getComputedStyle(imageEl)

      if (parseFloat(height) < 200) {
        imageEl.parentElement.style.height = height
      }
    }
  }
}
</script>
