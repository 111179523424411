import { computed } from "vue"
import i18n from "@/i18n"

export function useOfferCondition() {
  const conditions = computed(() => {
    return [
      { backKey: "new", backValue: 0, description: i18n.t("condition_new"), value: 1 },
      {
        backKey: "p_discount",
        backValue: 0,
        description: i18n.t("condition_p_discount"),
        value: 1
      },
      {
        backKey: "refurbished",
        backValue: 0,
        description: i18n.t("condition_refurbished"),
        value: 2
      },
      { backKey: "used", backValue: 5, description: i18n.t("condition_used_5"), value: 3 },
      { backKey: "used", backValue: 4, description: i18n.t("condition_used_4"), value: 4 },
      { backKey: "used", backValue: 3, description: i18n.t("condition_used_3"), value: 5 },
      { backKey: "used", backValue: 2, description: i18n.t("condition_used_2"), value: 2 },
      { backKey: "used", backValue: 1, description: i18n.t("condition_used_1"), value: 1 },
      {
        backKey: "w_undetermined",
        backValue: 0,
        description: i18n.t("condition_w_undetermined"),
        value: 1
      }
    ]
  })

  const getOfferConditionString = (conditionKey, conditionValue) => {
    const conditionValueInt = parseInt(conditionValue)
    const isUsedState = conditionKey === "used"
    const result = conditions.value.find((record) => {
      if (isUsedState) {
        return record.backKey === conditionKey && record.backValue === conditionValueInt
      } else {
        return record.backKey === conditionKey
      }
    })

    return result.description || null
  }

  return {
    getOfferConditionString
  }
}
