<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h2>Справочник списка возможных значений по атрибутам</h2>
      </div>
    </div>
    <SearchValueInLists />
    <div class="row">
      <div class="col-sm-7">
        <h3>Добавленные списки</h3>
        <ValuesDescriptionTable />
      </div>
      <div class="col-sm-5">
        <h3>Список значений "{{ PV_selectedList.description }}"</h3>
        <ValuesTable />
        <CreateValueModal />
        <DeleteValuesConfirmationModal />
        <UpdateAndDeleteModal />

        <h3 class="margin-top">
          Перечень атрибутов, что используют список "{{ PV_selectedList.description }}"
        </h3>
        <AttributesTable />
        <SearchTypesModal />
        <AddAttributeModal />
        <DeleteAttributeModal />
        <ConfirmApprovalModal />
      </div>
    </div>
    <CreateValueListModal />
  </div>
</template>
<script>
import CreateValueListModal from "./CreateValueListModal/CreateValueListModal"
import ValuesDescriptionTable from "./ValuesDescriptionTable/ValuesDescriptionTable"
import ValuesTable from "./ValuesTable/ValuesTable"
import CreateValueModal from "./CreateValueModal/CreateValueModal"
import Button from "components/Button"
import { mapActions, mapMutations, mapGetters } from "vuex"
import DeleteValuesConfirmationModal from "./DeleteValuesConfirmationModal/DeleteValuesConfirmationModal"
import UpdateAndDeleteModal from "./UpdateAndDeleteModal/UpdateAndDeleteModal"
import AttributesTable from "./AttributesTable/AttributesTable"
import SearchTypesModal from "./SearchTypesModal/SearchTypesModal"
import AddAttributeModal from "./AddAttributeModal/AddAttributeModal"
import DeleteAttributeModal from "./DeleteAttributeModal/DeleteAttributeModal"
import ConfirmApprovalModal from "./ConfirmApprovalModal"
import Select from "components/Select/Select"
import SearchValueInLists from "./SearchValueInLists/SearchValueInLists"

export default {
  metaInfo: {
    title: "MS: Value Lists"
  },
  name: "ProductCatalogValues",
  components: {
    DeleteValuesConfirmationModal,
    Button,
    ValuesDescriptionTable,
    CreateValueModal,
    CreateValueListModal,
    ValuesTable,
    UpdateAndDeleteModal,
    AttributesTable,
    SearchTypesModal,
    AddAttributeModal,
    DeleteAttributeModal,
    ConfirmApprovalModal,
    Select,
    SearchValueInLists
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_selectedList: "PV_selectedList",
      PV_attributesData: "PV_attributesData",
      PV_valuesData: "PV_valuesData",
      PC_languages: "PC_languages",
      PC_selectedLanguage: "PC_selectedLanguage"
    })
  },
  methods: {
    onSelectLang(value) {
      if (value === this.PC_selectedLanguage) return
      let page = "values"
      this.PC_selectLanguage({ value, page })
    },
    ...mapMutations("ProductCatalog", {
      PV_resetListTableValues: "PV_resetListTableValues"
    }),
    ...mapActions("ProductCatalog", {
      PC_selectLanguage: "PC_selectLanguage"
    })
  },
  beforeRouteLeave(to, from, next) {
    this.PV_resetListTableValues()
    next()
  }
}
</script>
<style lang="scss"></style>
