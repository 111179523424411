<template>
  <span ref="mainInput">
    <input
      ref="editableCell"
      type="text"
      v-model="myVal"
      @keyup.enter="(e) => submitEditCell(field, e.target.value, rowIndex)"
      @keyup.esc="cancelEditCell"
      @input="onInput"
    />
    <ValidationErrors
      :lengthError="showError"
      :emptyError="showEmptyError"
      :uniqueError="!PB_checkUniqueBrandGet"
      :quantityError="showNumberOfValuesError"
      :repetativeValuesError="showRepetativeValuesError"
    />
    <p class="hint">Укажите значения через запятую</p>
  </span>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validArrayItemsCount from "utils/validations/validArrayItemsCount"
import validArrayItemsMaxLength from "utils/validations/validArrayItemsMaxLength"
import validArrayRepetativeItems from "utils/validations/validArrayRepetativeItems"
import validEmpty from "utils/validations/validEmpty"

export default {
  name: "InputEditSynonyms",
  components: {
    ValidationErrors
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onUpdate: Function
  },
  data() {
    return {
      showError: false,
      showEmptyError: false,
      sendObject: {},
      showHint: false,
      myVal: this.row[this.field],
      timeout: null,
      showNumberOfValuesError: false,
      showRepetativeValuesError: false
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
    this.closeOnBlurEvent()
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PB_checkUniqueBrandGet: "PB_checkUniqueBrandSynonym"
    })
  },
  methods: {
    onInput(event) {
      let value = event.target.value

      this.checkUniqueBrandSynonym(value)
      this.showEmptyError = !validEmpty(value)

      // build array
      let synonymsArray = value.split(",").map((item) => {
        return item.trim()
      })

      this.showNumberOfValuesError = !validArrayItemsCount(synonymsArray, 50)
      this.showError = !validArrayItemsMaxLength(synonymsArray, 50)
      this.showRepetativeValuesError = !validArrayRepetativeItems(synonymsArray)
    },
    checkUniqueBrandSynonym(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PB_checkUniqueBrandSynonym({ value, rowIndex: this.rowIndex })
      }, 500)
    },
    submitEditCell(field, value, rowIndex) {
      if (
        this.showError ||
        !this.PB_checkUniqueBrandGet ||
        this.showEmptyError ||
        this.showNumberOfValuesError ||
        this.showRepetativeValuesError
      )
        return

      this.submitSynonym(field, value, rowIndex)

      this.onUpdate(this.sendObject)
      this.PB_checkUniqueBrandMute(true)
      this.close()
    },
    submitSynonym(field, value, rowIndex) {
      this.sendObject = {
        columnKey: "synonymsNames",
        newValue: value,
        rowIndex
      }
    },
    cancelEditCell() {
      this.PB_checkUniqueBrandMute(true)
      this.close()
    },
    closeOnBlurEvent() {
      let context = this
      this.$refs.editableCell.addEventListener("blur", context.close, false)
    },
    unsubscribeOnBlur() {
      this.PB_checkUniqueBrandMute(true)
      let context = this
      this.$refs.editableCell.removeEventListener("blur", context.close, false)
    },
    ...mapMutations("ProductCatalog", {
      PB_checkUniqueBrandMute: "PB_checkUniqueBrandSynonym"
    }),
    ...mapActions("ProductCatalog", {
      PB_checkUniqueBrandSynonym: "PB_checkUniqueBrandSynonym"
    })
  },
  beforeDestroy() {
    this.unsubscribeOnBlur()
  }
}
</script>

<style lang="scss" scoped>
.hint {
  color: $info-color;
  font-size: $medium-font-size;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-top: 0px;
  position: static;
}
</style>
