<template>
  <div>
    <PageHeader :title="$t('roles')">
      <template #actions>
        <a-button
          type="primary"
          icon="plus"
          :loading="isMetaFetching"
          @click="openCreateModal()"
        >
          {{ $t("create") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      rowKey="id"
      :columns="columns"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #isActiveRenderer="isActive">
        <div :class="[isActive ? 'success-text' : 'secondary-text']">
          {{ isActive ? $t("active") : $t("inactive") }}
        </div>
      </template>

      <template #actionsRenderer="record">
        <a-button
          icon="edit"
          shape="circle"
          type="dashed"
          :disabled="isMetaFetching"
          @click="openEditModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="createModalIsOpened"
      :title="$t('createRole')"
      :width="1200"
      destroyOnClose
      @close="closeCreateModal"
    >
      <CreateRole
        :models="permissionsModels"
        :apps="permissionsApps"
        :modelsFetching="permissionsModelsFetching"
        :appsFetching="permissionsAppsFetching"
        @onCreate="onCreateRole"
        @close="closeCreateModal"
      />
    </a-drawer>

    <a-drawer
      :visible="editModalIsOpened"
      :title="$t('editRole')"
      :width="1200"
      @close="closeEditModal"
    >
      <EditRole
        v-if="editModalIsOpened"
        :models="permissionsModels"
        :apps="permissionsApps"
        :role="editedRole"
        :modelsFetching="permissionsModelsFetching"
        :appsFetching="permissionsAppsFetching"
        @onUpdate="onUpdateRole"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"

import CreateRole from "./components/CreateRole.vue"
import EditRole from "./components/EditRole.vue"
import PageHeader from "@/ant-components/PageHeader/index.js"

import {
  getRoles,
  fetchPermissionApps,
  fetchPermissionModels
} from "@/modules/UsersManagement/services/rolesService.js"

import useUsersColumns from "@/modules/UsersManagement/useUsersColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo
  // updateTableDataRecord
} = useAntTableQuery({
  queryFunction: getRoles,
  requestParams: { changeRouterQuery: true }
})

const { rolesColumns: columns } = useUsersColumns({ sortedInfo: computed(() => sortedInfo.value) })

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const createModalIsOpened = ref(false)
const editModalIsOpened = ref(false)
const editedRole = ref()
const permissionsModels = ref([])
const permissionsApps = ref([])
const permissionsModelsFetching = ref(false)
const permissionsAppsFetching = ref(false)

const isMetaFetching = computed(
  () => permissionsModelsFetching.value || permissionsAppsFetching.value
)

const getModels = async () => {
  permissionsModelsFetching.value = true

  try {
    const { data } = await fetchPermissionModels()
    permissionsModels.value = data.results
    permissionsModelsFetching.value = false
  } catch (error) {
    if (error?.response?.status === 401) return

    setTimeout(getModels, 2500)
  }
}

const getApp = async () => {
  permissionsAppsFetching.value = true

  try {
    const { data } = await fetchPermissionApps()
    permissionsApps.value = data.results
    permissionsAppsFetching.value = false
  } catch (error) {
    if (error?.response?.status === 401) return

    setTimeout(getApp, 2500)
  }
}

const getMeta = () => {
  getApp()
  getModels()
}

const openCreateModal = () => {
  createModalIsOpened.value = true
}

const closeCreateModal = () => {
  createModalIsOpened.value = false
}

const openEditModal = (row) => {
  editedRole.value = row
  editModalIsOpened.value = true
}

const closeEditModal = () => {
  editModalIsOpened.value = false
  editedRole.value = undefined
}

const onUpdateRole = (payload) => {
  // TODO: change table updating to row updating after API_V2 will be available
  // updateTableDataRecord({ payload, identifier: "id" })
  onTableChange()
  closeEditModal()
}

const onCreateRole = () => {
  closeCreateModal()
  onTableChange()
}

onMounted(() => {
  getMeta()

  setupTable({
    defaultSorter: {
      columnKey: "created_at",
      order: "ascend"
    }
  })
  onTableChange()
})
</script>

<style scoped lang="scss">
.success-text {
  color: $green-color;
}
.secondary-text {
  opacity: 0.45;
}
</style>
