<template>
  <span
    class="badge"
    :class="{ [type]: type }"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      default: "default",
      type: String
    }
  },
  name: "Badge"
}
</script>

<style lang="scss" scoped>
span.badge {
  width: auto;
  height: 20px;
  padding: 0 4px;
  margin: 0 4px;
  min-width: 20px;
  line-height: 20px;
  border-radius: 20px;
  vertical-align: middle;
  display: inline-block;
  font-size: $small-font-size;
  font-weight: 700;
  text-align: center;
}

.default {
  background-color: $dark;
  color: #fff;
}
.white {
  background-color: #fff;
  color: $primary-color;
}
.green {
  background-color: $success-color;
  color: #fff;
}
.blue {
  background-color: $primary-color;
  color: #fff;
}
.black {
  background-color: $black;
  color: #fff;
}
</style>
