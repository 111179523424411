export const getTdOffset = (el, tableWidth, tableHeight, scrollTop, scrollLeft) => {
  const topPos = el.offsetParent.offsetTop
  const leftPos = el.offsetLeft
  const rightPos = tableWidth - (el.offsetLeft + el.clientWidth)
  const bottomPos = tableHeight - (topPos + el.clientHeight)
  const topOffset = el.offsetParent.offsetTop - scrollTop
  const leftOffset = el.offsetLeft - scrollLeft
  const rightOffset = tableWidth - (leftOffset + el.clientWidth)
  const bottomOffset = tableHeight - (topOffset + el.clientHeight)

  const leftOffsetExist = leftOffset > 100
  const topOffsetExist = topOffset > el.clientHeight
  const rightOffsetExist = rightOffset > 100
  const bottomOffsetExist = bottomOffset > el.clientHeight

  return {
    topPos,
    rightPos,
    bottomPos,
    leftPos,
    topOffset,
    rightOffset,
    bottomOffset,
    leftOffset,
    topExist: topOffsetExist,
    rightExist: rightOffsetExist,
    bottomExist: bottomOffsetExist,
    leftExist: leftOffsetExist
  }
}

export const getTdPosition = (el, parentClass) => {
  const { top, left } = getTdOffset(el, parentClass)

  return {
    top,
    left
  }
}
