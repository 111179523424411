<template>
  <div
    class="th"
    :style="style"
  >
    <a-checkbox
      @change="(e) => onCheckAllRows(e.target.checked)"
      :checked="checkedAll"
    />
  </div>
</template>

<script>
export default {
  name: "ThCheckbox",

  props: {
    width: {
      type: [Number, Boolean],
      default: false
    },
    minWidth: {
      type: [Number, Boolean],
      default: false
    },
    maxWidth: {
      type: [Number, Boolean],
      default: false
    },
    onCheckAllRows: {
      type: Function,
      required: true
    },
    checkedAll: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      style: {
        "min-width": this.getMinWidth(),
        "max-width": this.getMaxWidth()
      }
    }
  },

  methods: {
    getMinWidth() {
      let width = "auto"

      if (this.width) {
        width = `${this.width}px`
      } else if (this.minWidth) {
        width = `${this.minWidth}px`
      }

      return width
    },

    getMaxWidth() {
      let width = "none"

      if (this.width) {
        width = `${this.width}px`
      } else if (this.maxWidth) {
        width = `${this.maxWidth}px`
      }

      return width
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/config";

.th {
  flex-grow: 1;
  width: 100%;
  border-right: solid 1px $border-color;
  border-bottom: solid 1px $border-color;
  padding: 7px;
  font-weight: bold;
  font-size: $font-size-small;
  box-sizing: border-box;
  text-transform: uppercase;
  color: $font-color-secondary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
