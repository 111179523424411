<template>
  <div class="spv-messenger">
    <div class="moderator-search main-messenger">
      <div class="search-header">
        <h2>{{ $t("searchWithinAllChats") }}</h2>
        <form
          class="search-wrap"
          @keydown.enter="onSearch"
        >
          <div class="search-wrap__item">
            <a-input
              :placeholder="$t('firstUser')"
              @change="onFirstInput"
            >
              <a-icon
                slot="suffix"
                type="search"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </div>
          <div class="search-wrap__item">
            <a-input
              :placeholder="$t('secondUser')"
              @change="onSecondInput"
            >
              <a-icon
                slot="suffix"
                type="search"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </div>
          <div class="search-wrap__button">
            <a-button
              type="primary"
              :disabled="!enableButton"
              @click="onSearch"
            >
              {{ $t("search") }}
            </a-button>
          </div>
        </form>
      </div>
      <div
        v-if="globalQuery.length && chats.length"
        class="serch-results"
      >
        <div class="moderator-messenger">
          <div class="messenger-sidebar">
            <SidePannelUsers
              isModeratorSearch
              style="height: 100%; max-height: unset"
            />
          </div>
          <div class="messenger-content">
            <MessagesDisplay
              moderatorSearchComponent
              @showEditHistory="onShowEditHistory"
            />
          </div>
        </div>
      </div>
    </div>
    <GlobalGallery />
    <MessageHistoryModal
      :visible="showEditHistory"
      :hasNextPage="showEditHistoryHasNextPage"
      :editHistory="editHistory"
      :originalMessageBody="showEditHistoryOriginalMessageBody"
      @getNextPage="getEditHistoryNextPage"
      @cancel="onCloseEditHistory"
    />
  </div>
</template>

<script>
import MessagesDisplay from "./MessagesDisplay"
import SidePannelUsers from "./SidePannelUsers"
import GlobalGallery from "../CommonComponents/GlobalGallery"
import MessageHistoryModal from "./MessageHistoryModal"
import { mapState, mapMutations, mapActions } from "vuex"
import { getMessageHistory } from "../../../../utils/messengerXhrService"

export default {
  name: "SpvMessengerModerator",
  components: {
    MessagesDisplay,
    SidePannelUsers,
    GlobalGallery,
    MessageHistoryModal
  },
  data() {
    return {
      firstInput: "",
      secondInput: "",

      showEditHistory: false,
      showEditHistoryMessageUuid: "",
      showEditHistoryOffset: 0,
      showEditHistoryLimit: 15,
      showEditHistoryHasNextPage: false,
      showEditHistoryOriginalMessageBody: "",
      editHistory: []
    }
  },
  computed: {
    enableButton() {
      return this.firstInput || this.secondInput
    },
    ...mapState("spvMessenger", {
      chats: (state) => state.chats,
      globalQuery: (state) => state.globalQuery,
      auth: (state) => state.auth
    })
  },
  mounted() {
    this.SELECT_CHAT(null)
    this.SET_CHATS_OFFSET(0)
    this.RESET_CHATS()
  },
  methods: {
    onSearch() {
      const query = [this.firstInput, this.secondInput].filter((item) => item)

      this.SET_GLOBAL_QUERY(query)
      this.SET_CHATS_OFFSET(0)
      this.GLOBAL_CHAT_SEARCH_BY_XHR()
    },
    onFirstInput(e) {
      this.firstInput = e.target.value
    },
    onSecondInput(e) {
      this.secondInput = e.target.value
    },
    onShowEditHistory({ messageUuid, originalMessageBody }) {
      this.showEditHistory = true
      this.showEditHistoryMessageUuid = messageUuid
      this.showEditHistoryOriginalMessageBody = originalMessageBody

      this.getEditHistoryNextPage()
    },
    getEditHistoryNextPage() {
      getMessageHistory({
        messageUuid: this.showEditHistoryMessageUuid,
        limit: this.showEditHistoryLimit,
        offset: this.showEditHistoryOffset,
        auth: this.auth
      }).then((response) => {
        this.editHistory.push(...response.data)
        this.showEditHistoryOffset += this.showEditHistoryLimit

        if (this.showEditHistoryOffset < response.count) {
          this.showEditHistoryHasNextPage = true
        }
      })
    },
    onCloseEditHistory() {
      this.showEditHistory = false
      this.showEditHistoryMessageUuid = ""
      this.showEditHistoryOffset = 0
      this.showEditHistoryLimit = 15
      this.showEditHistoryHasNextPage = false
      this.showEditHistoryOriginalMessageBody = ""
      this.editHistory = []
    },
    ...mapMutations("spvMessenger", [
      "SET_CHATS_OFFSET",
      "SET_GLOBAL_QUERY",
      "RESET_CHATS",
      "SELECT_CHAT"
    ]),
    ...mapActions("spvMessenger", ["GLOBAL_CHAT_SEARCH_BY_XHR"])
  }
}
</script>
