import { render, staticRenderFns } from "./ModerationStatus.vue?vue&type=template&id=5e57c753&scoped=true"
import script from "./ModerationStatus.vue?vue&type=script&setup=true&lang=js"
export * from "./ModerationStatus.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ModerationStatus.vue?vue&type=style&index=0&id=5e57c753&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e57c753",
  null
  
)

export default component.exports