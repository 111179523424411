import i18n from "@/i18n"
import { computed } from "vue"

export default ({ filteredInfo }) => {
  const columns = computed(() => [
    {
      key: "value",
      dataIndex: "value",
      title: i18n.t(filteredInfo.value.kind),
      elipsis: true,
      width:
        filteredInfo.value.kind !== "category"
          ? filteredInfo.value.kind !== "brand"
            ? 120
            : 200
          : 400
    },
    {
      key: "date_added",
      dataIndex: "date_added",
      title: i18n.t("dateAdded"),
      width: 150
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: i18n.t("note")
    },
    {
      key: i18n.t("actions"),
      scopedSlots: { customRender: "actions" },
      fixed: "right"
    }
  ])

  return {
    columns
  }
}
