import locales from "../constants/locales"
import Cookies from "js-cookie"

export default () => {
  // get locale value from cookies, if locale not setted return browser language
  const defaultLanguage = Cookies.get("language")
  const currentLocale =
    (locales.some((item) => item.value === defaultLanguage) && defaultLanguage) || "uk"
  return currentLocale
}
