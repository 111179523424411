<template>
  <div class="wrapper custom-checkbox">
    <template v-if="field === 'is_main'">
      <Checkbox
        @change="checkboxChange"
        :checked="row.is_main"
      />
    </template>
    <template v-if="field === 'is_required'">
      <Checkbox
        @change="checkboxChange"
        :checked="row.is_required"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import Checkbox from "components/Checkbox/index"

export default {
  components: {
    Checkbox
  },
  props: ["row", "rowIndex", "field"],
  data() {
    return {
      timeout: null
    }
  },
  methods: {
    checkboxChange(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PA_editAtlCheckboxes({
          field: this.field,
          val,
          code: this.row.code,
          rowIndex: this.rowIndex
        })
      }, 200)
    },
    ...mapActions("ProductCatalog", {
      PA_editAtlCheckboxes: "PA_editAtlCheckboxes"
    })
  }
}
</script>
<style>
.custom-checkbox.wrapper {
  position: relative;
  top: 1px;
  left: 4px;
}
.custom-checkbox .checkbox__wrapper {
  display: block;
}
</style>
