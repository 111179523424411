<template>
  <div class="wrapper">
    <AttributesTable
      :mpcCode="mpcCode"
      :attributes="attributes"
      :fetching="fetching"
      :locale="locale"
      @updateAttribute="handleUpdate"
    />
  </div>
</template>

<script setup>
import AttributesTable from "./_components/AttributesTable.vue"

const props = defineProps({
  mpcCode: {
    type: String,
    required: true
  },
  locale: {
    type: String,
    required: true
  },
  product: {
    type: Object,
    default: () => {}
  },
  attributes: {
    type: Array,
    default: () => []
  },
  fetching: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["updateAttribute", "attributesChanged"])

const handleUpdate = (attribute) => {
  emit("updateAttribute", attribute)
  emit("attributesChanged")
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;

  &__loader {
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
