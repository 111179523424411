<template>
  <div class="tHead">
    <template v-if="ifExistFixedColumns">
      <div
        class="tHead__left"
        ref="tHeadLeft"
      >
        <Th
          v-if="sortRowsEnabled"
          :key="`th-sort-${tableId}`"
          :width="32"
          columnKey="th-sort"
        />

        <ThCheckbox
          v-if="checkRowEnabled"
          :key="`th-checkbox-${tableId}`"
          :width="32"
          columnKey="th-checkbox"
          :onCheckAllRows="onCheckAllRows"
          :checkedAll="checkedAll"
        />

        <Th
          v-if="showRowNumber"
          :key="`th-number-${tableId}`"
          :width="40"
          columnKey="th-number"
        />

        <Th
          v-for="column in fixedColumns"
          :key="`th-${column.key}-${tableId}`"
          :columnKey="column.key"
          :title="column.title || ''"
          :width="column.width || false"
          :minWidth="column.minWidth || false"
          :maxWidth="column.maxWidth || false"
          :filtering="column.filtering"
          :queryAction="queryAction"
          :queryParams="queryParams"
          :filters="filters"
          :updateFilters="updateFilters"
          :sorting="column.sorting"
          :sortingWithLock="column.sortingWithLock"
          :localSorting="localSorting"
          :ordering="ordering"
          :onSort="onSort"
          :onSortLock="onSortLock"
        />
      </div>

      <div
        class="tHead__right"
        ref="tHeadRight"
      >
        <Th
          v-for="column in notFixedColumns"
          :key="`th-${column.key}-${tableId}`"
          :columnKey="column.key"
          :title="column.title || ''"
          :width="column.width || false"
          :minWidth="column.minWidth || false"
          :maxWidth="column.maxWidth || false"
          :filtering="column.filtering"
          :queryAction="queryAction"
          :queryParams="queryParams"
          :filters="filters"
          :updateFilters="updateFilters"
          :sorting="column.sorting"
          :sortingWithLock="column.sortingWithLock"
          :localSorting="localSorting"
          :ordering="ordering"
          :onSort="onSort"
          :onSortLock="onSortLock"
        />
      </div>
    </template>

    <template v-else>
      <Th
        v-if="sortRowsEnabled"
        :key="`th-sort-${tableId}`"
        :width="32"
        columnKey="th-sort"
      />

      <ThCheckbox
        v-if="checkRowEnabled"
        :key="`th-checkbox-${tableId}`"
        :width="32"
        columnKey="th-checkbox"
        :onCheckAllRows="onCheckAllRows"
        :checkedAll="checkedAll"
      />

      <Th
        v-if="showRowNumber"
        :key="`th-number-${tableId}`"
        :width="40"
        columnKey="th-number"
      />

      <Th
        v-for="column in columns"
        :key="`th-${column.key}-${tableId}`"
        :columnKey="column.key"
        :title="column.title || ''"
        :width="column.width || false"
        :minWidth="column.minWidth || false"
        :maxWidth="column.maxWidth || false"
        :filtering="column.filtering"
        :queryAction="queryAction"
        :queryParams="queryParams"
        :filters="filters"
        :updateFilters="updateFilters"
        :sorting="column.sorting"
        :sortingWithLock="column.sortingWithLock"
        :localSorting="localSorting"
        :ordering="ordering"
        :onSort="onSort"
        :onSortLock="onSortLock"
      />
    </template>
  </div>
</template>

<script>
import Th from "./Th.vue"
import ThCheckbox from "./ThCheckbox.vue"

export default {
  name: "THead",

  components: {
    Th,
    ThCheckbox
  },

  props: {
    tableId: {
      type: String,
      required: true
    },
    ifExistFixedColumns: {
      type: Boolean,
      default: false
    },
    fixedColumns: {
      type: Array,
      required: true
    },
    notFixedColumns: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    showRowNumber: {
      type: Boolean,
      required: true
    },
    checkRowEnabled: {
      type: Boolean,
      required: true
    },
    sortRowsEnabled: {
      type: Boolean,
      required: true
    },
    queryAction: {
      type: Function,
      required: true
    },
    queryParams: {
      type: Object,
      required: true
    },
    filters: {
      type: Array,
      default: () => []
    },
    updateFilters: {
      type: Function,
      default: () => false
    },
    localSorting: {
      type: Boolean,
      required: true
    },
    ordering: {
      type: Array,
      required: true
    },
    onSort: {
      type: Function,
      required: true
    },
    onSortLock: {
      type: Function,
      required: true
    },
    onCheckAllRows: {
      type: Function,
      required: true
    },
    checkedAll: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/config";

.tHead {
  width: 100%;
  display: flex;
  justify-content: stretch;
  margin-bottom: -1px;
  z-index: 1;
  position: relative;

  &__left,
  &__right {
    display: flex;
    justify-content: stretch;
    flex-grow: 1;
  }

  &__left {
    .th:last-child {
      border-right: solid 1px $border-color;
    }
  }
}
</style>
