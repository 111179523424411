<template>
  <a-table
    :dataSource="dataSource"
    :loading="isFetching"
    :columns="columns"
    :pagination="paginationInfo"
    rowKey="id"
    :scroll="{ x: true }"
    @change="onTableChange"
  >
    <template #title>
      <div class="brand_header">
        <div class="table-title">
          {{ $t("brandSynonyms") }}
        </div>

        <a-input-search
          allowClear
          style="width: 300px"
          :placeholder="$t('enterBrandName')"
          v-model="filteredInfo.search"
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button>
              <a-icon type="search" />
            </a-button>
          </template>
        </a-input-search>
      </div>
    </template>

    <template #synonyms="text, record">
      <TableEditableCellInput
        :text="text.join(', ')"
        key="synonyms"
        @change="(value, closeEdit) => editCell(value, record, closeEdit)"
      />
    </template>
  </a-table>
</template>

<script setup>
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import { onMounted } from "vue"
import { fetchBrands, updateBrand } from "@/modules/MPAdmin/services/searchSettingsService"

import TableEditableCellInput from "@/ant-components/TableEditableCellInput"

import useSynonymsColumns from "../useSynonymsColumns.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  fetchTableInfo,
  handleTableFilterChange
} = useAntTableQuery({
  queryFunction: fetchBrands
})

const { brandsColumns: columns } = useSynonymsColumns()

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const editCell = async (value, record, closeEdit) => {
  try {
    await updateBrand({ ...record, synonyms: value.split(/,|, | , |\n/) }, record.id)

    notification.success({ message: i18n.t("updated") })
    onTableChange()
    closeEdit()
  } catch (error) {
    notifyResponseError({ error })
  }
}

onMounted(onTableChange)
</script>

<style lang="scss" scoped>
.brand_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-title {
  font-weight: 500;
  font-size: 16px;
  color: $font-default;
}
</style>
