import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      key: "labels",
      title: i18n.t("labels"),
      scopedSlots: {
        customRender: "labelRender"
      },
      fixed: "left",
      width: 250
    },
    {
      key: "name_uk",
      dataIndex: "name.uk",
      title: "UA",
      scopedSlots: {
        customRender: "cellRender"
      },
      fixed: "left",
      width: 200
    },
    {
      key: "name_ru",
      dataIndex: "name.ru",
      title: "RU",
      scopedSlots: {
        customRender: "cellRender"
      },
      width: 200
    },
    {
      key: "name_en",
      dataIndex: "name.en",
      title: "EN",
      scopedSlots: {
        customRender: "cellRender"
      },
      width: 200
    },
    {
      key: "background_color",
      dataIndex: "background_color",
      title: i18n.t("colorOfFill"),
      scopedSlots: {
        customRender: "cellRender"
      },
      width: 100
    },
    {
      key: "font_color",
      dataIndex: "font_color",
      title: i18n.t("fontColor"),
      scopedSlots: {
        customRender: "cellRender"
      },
      width: 100
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("description"),
      width: 200
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 80
    }
  ])
  return columns
}
