import { API_MODERATION, buildDataTableGetUrl } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = () => ({
  VE_verificationModal: false,
  VE_count: 0,
  VE_showSecondStep: false,
  VE_verificationFinished: false,
  VE_verifiedTotal: 0,
  VE_notVerifiedTotal: 0,
  VE_processedTotal: 0,
  VE_exportErrorsTableData: { count: 0, items: [] }
})

const getters = {
  VE_verificationModal: (state) => state.VE_verificationModal,
  VE_count: (state) => state.VE_count,
  VE_showSecondStep: (state) => state.VE_showSecondStep,
  VE_verificationFinished: (state) => state.VE_verificationFinished,
  VE_verifiedTotal: (state) => state.VE_verifiedTotal,
  VE_notVerifiedTotal: (state) => state.VE_notVerifiedTotal,
  VE_processedTotal: (state) => state.VE_processedTotal,
  VE_exportErrorsTableData: (state) => state.VE_exportErrorsTableData
}

const mutations = {
  VE_setVerificationModal(state, value) {
    state.VE_verificationModal = value
  },
  VE_setCount(state, value) {
    state.VE_count = value
  },
  VE_setShowSecondStep(state, value) {
    state.VE_showSecondStep = value
  },
  VE_setVerificationFinished(state, value) {
    state.VE_verificationFinished = value
  },
  VE_setVerifiedTotal(state, value) {
    state.VE_verifiedTotal = value
  },
  VE_setNotVerifiedTotal(state, value) {
    state.VE_notVerifiedTotal = value
  },
  VE_setExportErrorsTableData(state, value) {
    state.VE_exportErrorsTableData = value
  },
  VE_setProcessedTotal(state, value) {
    state.VE_processedTotal = value
  },
  VE_setErrorsTableDataItems(state, value) {
    state.VE_exportErrorsTableData.items.push(value)
  },
  VE_setErrorsTableDataCount(state, value) {
    state.VE_exportErrorsTableData.count = value
  },
  VE_resetVerificationValues(state) {
    state.VE_count = 0
    state.VE_showSecondStep = false
    state.VE_verificationFinished = false
    state.VE_verifiedTotal = 0
    state.VE_notVerifiedTotal = 0
    state.VE_exportErrorsTableData = { count: 0, items: [] }
  }
}

const actions = {
  async VE_checkCount({ commit }, queryParams) {
    return API_MODERATION.get(buildDataTableGetUrl(apiUrls.verify, queryParams, true))
      .then((r) => r.data.message.count)
      .then((r) => {
        commit("VE_setCount", r)
        return r
      })
      .catch((e) => {
        e.$_actionName = "VE_checkCount"
        return Promise.reject(e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
