import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const state = () => ({
  PB_showCreateBrandModal: false,
  PB_nameForNewBrand: "",
  PB_synonymsForNewBrand: ""
})

const getters = {
  PB_showCreateBrandModal: (state) => state.PB_showCreateBrandModal,
  PB_nameForNewBrand: (state) => state.PB_nameForNewBrand,
  PB_synonymsForNewBrand: (state) => state.PB_synonymsForNewBrand
}

const mutations = {
  PB_showCreateBrandModal(state, value) {
    state.PB_showCreateBrandModal = value
  },
  PB_nameForNewBrand(state, value) {
    state.PB_nameForNewBrand = value
  },
  PB_synonymsForNewBrand(state, value) {
    state.PB_synonymsForNewBrand = value
  }
}

const actions = {
  PB_createNewBrand({ commit, getters, dispatch }) {
    // if it's not processing already
    if (getters.PC_showLoader) return
    // show loader
    commit("PC_showLoader", true)

    let synonyms = getters.PB_synonymsForNewBrand.split(",").map((item) => {
      return item.trim()
    })
    let brand = {
      name: getters.PB_nameForNewBrand
    }
    if (synonyms.length && synonyms[0] !== "") brand.synonyms = synonyms
    // start request
    API_MODERATION.post(`${apiUrls.brand}`, brand)
      .then((r) => r.data.message.brand)
      .then((product) => {
        restartTableSearch({ getters, dispatch })

        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PB_resetCreateBrandValues")

        notification.info({ message: "Новый бренд добавлен" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        if (e.response.status === 409) {
          console.log(e.message)
          notification.info({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  },
  PB_resetCreateBrandValues({ commit }) {
    commit("PB_nameForNewBrand", "")
    commit("PB_synonymsForNewBrand", "")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function restartTableSearch({ getters, dispatch }) {
  dispatch("PC_searchBrands", getters.PB_brandsTableQueryParams)
}
