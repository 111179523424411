import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("active"),
      scopedSlots: {
        customRender: "active"
      },
      align: "center",
      fixed: "left",
      width: 100
    },
    {
      key: "name_uk",
      dataIndex: "name.uk",
      title: `${i18n.t("name")} (UA)`,
      fixed: "left",
      width: 380
    },
    {
      key: "name_en",
      dataIndex: "name.en",
      title: `${i18n.t("name")} (EN)`,
      width: 380
    },
    {
      key: "name_ru",
      dataIndex: "name.ru",
      title: `${i18n.t("name")} (RU)`,
      width: 380
    },
    {
      key: "ordering",
      dataIndex: "ordering",
      title: i18n.t("outputSortOrder"),
      width: 220
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 50
    }
  ])

  return columns
}
