import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { API_MODERATION } from "utils/HttpUtils"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

// change attribute modal data/ CA - change attribute

const state = {
  AE_CA_selectedAttr: {},
  AE_CA_selectedAttrText: "",
  AE_CA_uniqueTypes: [],
  AE_CA_fetchedAttributes: [],
  AE_CA_attrsAutocomplete: [],
  AE_CA_AutocompleteFetching: "",
  // AE_CA_showChangedTableCheck: false,
  AE_CA_reloadTable: false
}

const mutations = {
  AE_CA_attrsAutocomplete(state, value) {
    state.AE_CA_attrsAutocomplete = value
  },
  AE_CA_fetchedAttributes(state, value) {
    state.AE_CA_fetchedAttributes = value
  },
  AE_CA_selectedAttr(state, value) {
    state.AE_CA_selectedAttr = value
  },
  AE_CA_setSelectedAttrText(state, value) {
    state.AE_CA_selectedAttrText = value
  },
  AE_CA_uniqueTypes(state, value) {
    state.AE_CA_uniqueTypes = value
  },
  // AE_CA_showChangedTableCheck(state, value) {
  //   state.AE_CA_showChangedTableCheck = value
  // },
  AE_CA_reloadTable(state, value) {
    state.AE_CA_reloadTable = value
  }
}

const actions = {
  AE_CA_setSelectedAttr({ state, commit, rootState }, value) {
    const AE_selectedAttrType =
      rootState.ProductCatalog.attributesEdit.search.AE_selectedAttr.value_type

    commit("AE_CA_setSelectedAttrText", value)
    const attr = state.AE_CA_fetchedAttributes.find((item) => {
      return item.name === value
    })
    if (attr.status !== "LOCKED") {
      if (AE_selectedAttrType === "TEMP" && attr.value_type !== "STRING") {
        notification.warning({ message: `Выбранный новый атрибут должен иметь тип 'STRING'` })
      } else if (
        attr.value_type !== AE_selectedAttrType &&
        AE_selectedAttrType !== "TEMP" &&
        AE_selectedAttrType !== "LIST" &&
        AE_selectedAttrType !== "MULTILIST"
      ) {
        notification.warning({
          message: `Выбранный новый атрибут должен иметь тип ${AE_selectedAttrType}`
        })
      } else if (
        (AE_selectedAttrType === "LIST" || AE_selectedAttrType === "MULTILIST") &&
        (attr.value_type === "LIST" || attr.value_type === "MULTILIST")
      ) {
      } else {
        notification.warning({
          message: `Выбранный новый атрибут должен иметь тип 'LIST' или 'MULTILIST', при условии что оба использует один список`
        })
      }
    } else {
      notification.warning({ message: "Выбранный новый атрибут заблокирован" })
    }
    commit("AE_CA_selectedAttr", attr)
  },
  AE_CA_fetchAttributes({ commit }, value) {
    try {
      let searchData = buildMagicSearch({
        resource: "AND",
        field: "name",
        value,
        sort: null,
        limit: 20,
        offset: 0
      })
      return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((attrs) => {
          commit("AE_CA_fetchedAttributes", attrs)
          let attrsArray = attrs.map((item) => {
            return item.name
          })
          commit("AE_CA_attrsAutocomplete", attrsArray)
          return attrs
        })
        .catch((e) => {
          e.$_actionName = "AE_CA_fetchAttributes"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    }
  },
  async AE_CA_doChange({ state, commit, dispatch }, sendData) {
    return API_MODERATION.patch(`${apiUrls.attributeEditChange}`, sendData)
      .then((r) => {
        // if (state.AE_CA_showChangedTableCheck) {
        //   commit('AE_selectedAttr', state.AE_CA_selectedAttr)
        //   dispatch('AE_processSelectedAttr')
        //   commit('AE_attrAutocompleteDefault', state.AE_CA_selectedAttr.name)
        // } else {
        commit("AE_CA_reloadTable", true)
        // }
        return r
      })
      .catch((e) => {
        return e
      })
      .finally(() => {
        dispatch("AE_CA_resetModalData")
      })
  },
  AE_CA_resetModalData({ commit }) {
    commit("AE_CA_attrsAutocomplete", [])
    commit("AE_CA_setSelectedAttrText", "")
    commit("AE_CA_fetchedAttributes", [])
    commit("AE_CA_selectedAttr", {})
  }
}
export default {
  state,
  mutations,
  actions
}
