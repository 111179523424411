import Vue from "vue"

Vue.filter("formatState", (value) => {
  switch (value) {
    case "NEW":
      return "Новий"
    case "MANAGER_APPROVED":
      return "Підтверджений менеджером"
    case "CANCELED":
      return "Відмінений"
    case "SHIPPED":
      return "Відвантажений"
    case "DELIVERED":
      return "Доставлений"
  }
})

Vue.filter("slice", (value) => {
  if (value.length < 100) return value
  else return value.slice(0, 100) + "..."
})

Vue.filter("quantity", (value) => {
  value = +value

  if (value <= 5) {
    return value + " шт."
  } else if (value >= 5 && value < 20) {
    return "5-19 шт."
  } else if (value >= 20 && value < 50) {
    return "20-49 шт."
  } else if (value >= 50 && value < 100) {
    return "50-99 шт."
  } else if (value >= 100) {
    return "100+ шт."
  }
})

Vue.filter("capitalize", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
