<template>
  <a-form
    :form="paymentForm"
    style="margin-bottom: 40px"
  >
    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label>{{ $t("name") }}, UA ({{ ukNameLength }}/{{ nameMaxLength }})</template>

      <a-input
        v-decorator="[
          'name.uk',
          {
            rules: nameRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (ukNameLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label>{{ $t("name") }}, EN ({{ enNameLength }}/{{ nameMaxLength }}) </template>

      <a-input
        v-decorator="[
          'name.en',
          {
            rules: nameRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (enNameLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label>{{ $t("name") }}, RU ({{ ruNameLength }}/{{ nameMaxLength }}) </template>

      <a-input
        v-decorator="[
          'name.ru',
          {
            rules: nameRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (ruNameLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <a-form-item
      :label="$t('outputSortOrder')"
      :colon="false"
      style="margin: 0"
    >
      <a-input-number
        v-decorator="[
          'ordering',
          {
            initialValue: 0
          }
        ]"
        :min="0"
        :max="999"
        :precision="0"
      />
    </a-form-item>

    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label
        >{{ $t("description") }} ({{ descriptionLength }}/{{ descriptionMaxLength }})
      </template>
      <a-textarea
        v-decorator="[
          'description',
          {
            rules: descriptionRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (descriptionLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <a-form-item
      :label="$t('apiIntegration')"
      :colon="false"
      style="margin: 0; width: 120px"
    >
      <a-select
        v-decorator="['api_integration']"
        :placeholder="$t('no')"
      >
        <a-select-option :value="undefined">
          {{ $t("no") }}
        </a-select-option>

        <a-select-option
          v-for="apiIntegration in paymentsApiIntegration"
          :key="apiIntegration"
          :value="apiIntegration"
        >
          {{ apiIntegration }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      :label="$t('paymentMethodActivity')"
      :colon="false"
      style="margin: 0"
    >
      <a-switch
        v-decorator="[
          'is_active',
          {
            valuePropName: 'checked'
          }
        ]"
        checkedChildren="ON"
        unCheckedChildren="OFF"
      />
    </a-form-item>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="$emit('close')"
      >
        {{ $t("close") }}
      </a-button>

      <a-button
        type="primary"
        class="form-button-padding"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { PAYMENTS_API_INTEGRATION } from "@/constants/common.js"

import {
  createPaymentMethod,
  updatePaymentMethod
} from "@/modules/MPAdmin/services/generalSettingService/paymentMethodsService"

import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreatePaymentMethodsModal",

  props: {
    prototype: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      paymentForm: this.$form.createForm(this, {
        name: "PaymentMethodModal",
        mapPropsToFields: () => {
          return {
            "name.uk": this.$form.createFormField({
              value: this.prototype?.name?.uk || ""
            }),
            "name.en": this.$form.createFormField({
              value: this.prototype?.name?.en || ""
            }),
            "name.ru": this.$form.createFormField({
              value: this.prototype?.name?.ru || ""
            }),
            ordering: this.$form.createFormField({
              value: this.prototype?.ordering || 0
            }),
            description: this.$form.createFormField({
              value: this.prototype?.description || ""
            }),
            api_integration: this.$form.createFormField({
              value: this.prototype?.api_integration || undefined
            }),
            is_active: this.$form.createFormField({
              value: this.prototype?.is_active || false
            })
          }
        }
      }),
      paymentsApiIntegration: PAYMENTS_API_INTEGRATION,
      isFetching: false,
      nameMaxLength: 200,
      descriptionMaxLength: 1000,
      ukNameLength: 0,
      enNameLength: 0,
      ruNameLength: 0,
      descriptionLength: 0
    }
  },

  methods: {
    onSave() {
      this.paymentForm.validateFields(async (err, values) => {
        if (!err) {
          this.isFetching = true

          try {
            if (this.prototype.id) {
              const { data } = await updatePaymentMethod(
                {
                  ...values,
                  api_integration: values?.api_integration || null
                },
                this.prototype.id
              )
              this.$emit("updateMethod", data)
            } else {
              await createPaymentMethod({
                ...values,
                api_integration: values?.api_integration || null
              })
              this.$emit("createMethod")
            }

            this.$notification.success({
              message: this.$t(this.prototype?.id ? "updated" : "created")
            })
          } catch (error) {
            notifyResponseError({ error })
          } finally {
            this.isFetching = false
          }
        }
      })
    }
  },

  computed: {
    nameRules() {
      return [
        { required: true, message: this.$t("fieldRequire") },
        { max: 200, message: this.$t("maxLengthError", { length: 200 }) }
      ]
    },
    descriptionRules() {
      return [{ max: 1000, message: this.$t("maxLengthError", { length: 1000 }) }]
    }
  },

  mounted() {
    this.ukNameLength = this.prototype?.name?.uk?.length || 0
    this.enNameLength = this.prototype?.name?.en?.length || 0
    this.ruNameLength = this.prototype?.name?.ru?.length || 0
    this.descriptionLength = this.prototype.description?.length || 0
  }
}
</script>
