<template>
  <div style="margin-bottom: 40px">
    <DataTableWrapper
      :columns="columns"
      :data="dataSource"
      :removeItem="removeTableItem"
      :currentCover="currentCover"
      @changeCover="handleChangeCover"
    />

    <a-form-item :label="$t('listingSearch')">
      <a-select
        showSearch
        :options="searchResults"
        :placeholder="$t('enterListingNumber')"
        style="width: 100%"
        @search="(e) => onSearchListings(e)"
        @select="selectListing"
      />
    </a-form-item>

    <a-spin
      v-if="currentListing !== null"
      :spinning="fetchingProducts"
    >
      <DataTableWrapper
        :columns="columns"
        :data="searchTable"
        :removeItem="removeSearchTableItem"
        :currentCover="currentCover"
        @changeCover="handleChangeCover"
      />
    </a-spin>

    <div v-if="currentListing != null">
      <a-form-item :label="$t('variations')">
        <a-select
          showSearch
          :options="getAllAttrs"
          :placeholder="$t('startPrint')"
          style="width: 100%"
          @select="selectVariation"
        />
      </a-form-item>

      <a-alert
        type="error"
        v-if="!selectedItemsContainCover"
        :message="$t('chooseProductCover')"
      />
      <a-alert
        type="error"
        v-if="!selectedAttrs.length"
        :message="$t('chooseCharacteristic')"
      />
      <a-alert
        type="error"
        v-for="error in getAttrsErrors"
        :key="error"
        :message="error"
      />
    </div>

    <div v-show="selectedAttrs.length">
      <VariationsList
        :label="$t('chosenCharForVar')"
        :data="selectedAttrs"
        @change="changeSelectedAttrs"
      />
    </div>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :disabled="isDisabled"
        :loading="fetching"
        @click="onSave"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import DataTableWrapper from "./components/DataTableWrapper.vue"
import VariationsList from "./components/VariationsList.vue"

import {
  searchListings,
  getProductsByListing,
  updateListing
} from "@/modules/MPAdmin/services/productsService.js"

import useProductsColumns from "../../useProductsColumns.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  components: {
    DataTableWrapper,
    VariationsList
  },

  props: {
    data: {
      type: Array
    },

    listing: {
      type: Number,
      default: null
    }
  },

  setup() {
    const { productEditListingColumns: columns } = useProductsColumns()

    return {
      columns
    }
  },

  data() {
    return {
      dataSource: [],

      searchTable: [],

      fetching: false,

      currentListing: null,
      selectedAttrs: [],
      searchResults: [],
      currentCover: null
    }
  },

  methods: {
    removeSearchTableItem(item) {
      this.searchTable = this.searchTable.filter((j) => j.id !== item.id)
    },

    selectVariation(variation) {
      this.addAttr(variation)
    },

    changeSelectedAttrs(value) {
      this.selectedAttrs = value
    },

    handleChangeCover(id) {
      this.currentCover = id
    },

    addAttr(value) {
      if (this.selectedAttrs.some((attr) => attr.name === value)) {
        this.$notification.info({ message: this.$t("variationIsAdded") })
        return
      }

      let newAttr = {}

      this.dataSource.forEach(({ attrs }) => {
        newAttr = attrs.find((attribute) => attribute.name.ru === value)
      })
      if (newAttr) {
        this.changeSelectedAttrs([...this.selectedAttrs, { ...newAttr, name: newAttr.name.ru }])
      } else {
        this.$notification.error({ message: this.$t("a") })
      }
    },

    async selectListing(listing) {
      if (listing && typeof listing === "number") {
        this.fetchingProducts = true

        const { data } = await getProductsByListing(listing)

        this.currentListing = listing
        this.searchTable = data.results
        this.searchTable.count = data.count

        this.fetchingProducts = false
      }
    },

    removeTableItem(item) {
      this.dataSource = this.dataSource.filter((j) => j.id !== item.id)
    },

    async onSave() {
      this.fetching = true

      const items = [
        ...new Set([...this.searchTable.map((j) => j.id), ...this.dataSource.map((j) => j.id)])
      ]

      const data = {
        products: items,
        variations: [...new Set(this.selectedAttrs.map((item) => item.name))],
        cover_product: this.currentCover
      }

      try {
        await updateListing(data, this.currentListing)

        this.$notification.success({ message: this.$t("updated") })

        this.$emit("close")
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.fetching = false
      }
    },

    setTableData(data) {
      this.dataSource = data
    },

    async onSearchListings(value) {
      if (value) {
        const { data } = await searchListings(`${value}`)

        this.searchResults = data.results.map(({ id }) => ({ value: id, label: id }))
      } else {
        this.searchResults = []
      }
    }
  },

  computed: {
    isDisabled() {
      return Boolean(
        this.currentListing == null ||
          this.fetchingProducts ||
          this.selectedAttrs.length == 0 ||
          !this.selectedItemsContainCover ||
          this.getAttrsErrors.length > 0
      )
    },

    getAttrsErrors() {
      const errors = []

      this.selectedAttrs.forEach((attr) => {
        const { name: attrName } = attr

        const notContainAttrItems = []

        notContainAttrItems.push(
          ...this.searchTable.filter((item) => {
            return item.attrs.every((attr) => {
              return attr.name.ru !== attrName
            })
          })
        )

        notContainAttrItems.push(
          ...this.dataSource.filter((item) => {
            return item.attrs.every((attr) => {
              return attr.name.ru !== attrName
            })
          })
        )

        if (notContainAttrItems.length) {
          let productsPCodes = notContainAttrItems.map((item) => item.p_code).join(", ")
          let message = `${this.$t("inNextProducts", [productsPCodes])} «${attrName}»`
          errors.push(message)
        }
      })

      return errors
    },

    getAllAttrs() {
      let attrs = []

      this.dataSource.forEach((item) => {
        if (item.attrs && item.attrs.length) {
          item.attrs.forEach((attr) => {
            const key = attr.name.ru

            attrs = [...attrs, { value: key, label: key }]
          })
        }
      })

      return attrs
    },

    selectedItemsContainCover() {
      let containCover = false

      for (let i = 0; i < this.searchTable.length; i++) {
        if (this.searchTable[i].id === this.currentCover) {
          containCover = true
          break
        }
      }

      if (containCover) return containCover

      for (let i = 0; i < this.dataSource.length; i++) {
        if (this.dataSource[i].id === this.currentCover) {
          containCover = true
          break
        }
      }

      return containCover
    }
  },

  mounted() {
    this.setTableData(this.data)

    if (this.listing && typeof this.listing === "number") {
      this.selectListing(this.listing)
      this.currentCover = this.data[0].listing.cover_product
      this.selectedAttrs = this.data[0].listing.variations.map((item) => ({ name: item }))
    }
  }
}
</script>
