<template>
  <div class="messages__hint">
    {{ hintMessage }}
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "HintMessage",

  computed: {
    ...mapState("spvMessenger", {
      hintMessage: (state) => state.hintMessage
    })
  }
}
</script>
