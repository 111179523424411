<template>
  <div>
    <a-collapse>
      <a-collapse-panel
        v-for="(alert, index) in notEmptyAlerts"
        :key="`alert-${index}`"
        :header="`${alert.campaign_label}`"
      >
        <a-list
          item-layout="horizontal"
          :data-source="alert.alerts"
          :row-key="(record) => record.uuid"
        >
          <template #renderItem="item">
            <a-list-item>
              <template #actions>
                <a-button
                  :loading="deleteIds.findIndex((uuid) => item.uuid === uuid) !== -1"
                  @click="handleDeleteAlert(alert.campaign_uuid, item.uuid)"
                >
                  <a-icon type="delete" />
                </a-button>
              </template>
              <a-list-item-meta :description="campaignAlertStatuses[item.message]">
                <template #title>
                  <TableRendererDateTime :date="item.created_at" />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"

import { fetchCPCAlerts, deleteAlert } from "@/modules/ADS/services/CPCServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useAdsComposables from "@/modules/ADS/useAdsComposables.js"

export default {
  name: "CampaignAlerts",

  components: {
    TableRendererDateTime
  },

  setup() {
    const { campaignAlertStatuses } = useAdsComposables()

    return {
      campaignAlertStatuses
    }
  },

  data: () => {
    return {
      alerts: [],
      fetching: false,
      deleteIds: []
    }
  },

  computed: {
    notEmptyAlerts() {
      return this.alerts.filter((item) => {
        return item.alerts.length > 0
      })
    }
  },

  mounted() {
    this.getAlerts()
  },

  methods: {
    async getAlerts() {
      this.fetching = true

      try {
        const { data } = await fetchCPCAlerts()

        this.alerts = data
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.fetching = false
      }
    },
    async handleDeleteAlert(campaignUuid, alertUuid) {
      this.deleteIds = [...this.deleteIds, alertUuid]

      try {
        await deleteAlert(alertUuid)

        this.getAlerts()
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.deleteIds = [...this.deleteIds].filter((item) => {
          return item !== alertUuid
        })
      }
    }
  }
}
</script>
