export default {
  // search
  searchMagic: "/product-catalog/moderation/search/magic",
  searchMagicV2: "/product-catalog/moderation/v2/search/magic", // version 2
  search: "/product-catalog/moderation/search",
  searchAttributes: "/product-catalog/moderation/search/get_attributes_by_type",
  searchAttributesOfList: "/product-catalog/moderation/search/get_attributes_by_list_description",
  searchTypeByBrand: "/product-catalog/moderation/search/get_type_by_brand",
  searchProductsWithAttributeValue: "/product-catalog/moderation/search/get_values_by_attribute",
  searchProductsWithAttribute: "/product-catalog/moderation/dictionary/attribute/replace",
  attributeEdit: "/product-catalog/moderation/dictionary/attribute/values-update",
  attributeEditValue: "/product-catalog/moderation/dictionary/attribute/values-update/value",
  attributeEditChange: "/product-catalog/moderation/dictionary/attribute/values-update/attribute",
  // pages and components
  product: "/product-catalog/moderation/product",
  images: "/product-catalog/moderation/images",
  attribute: "/product-catalog/moderation/dictionary/attribute",
  attributeOrder: "/product-catalog/moderation/dictionary/link/order-update",
  brand: "/product-catalog/moderation/dictionary/brand",
  type: "/product-catalog/moderation/dictionary/product-type",
  unit: "/product-catalog/moderation/dictionary/unit",
  link: "/product-catalog/moderation/dictionary/link",
  // lists and values
  lists: "/product-catalog/moderation/dictionary/value-list/lists",
  value: "/product-catalog/moderation/dictionary/value-list/value",
  deleteValue: "/product-catalog/moderation/dictionary/value-list/delete-value",
  createFromAttribute:
    "/product-catalog/moderation/dictionary/value-list/lists/create-from-attribute",
  // validations
  validateBrand: "/product-catalog/moderation/validate/brand",
  validateUnit: "/product-catalog/moderation/validate/unit",
  validateProductType: "/product-catalog/moderation/validate/product-type",
  validateValueList: "/product-catalog/moderation/validate/value-list",
  validateAttribute: "/product-catalog/moderation/validate/attribute",

  "product-server__v2--get": "product-catalog/products-server/v2/get",
  "product-server__v3--get": "product-catalog/products-server/v3/get",
  export: "/product-catalog/moderation/product/export",
  exportHistory: "/product-catalog/moderation/export_dash",
  locale: "/product-catalog/locale",

  // export
  connectors: "/product-catalog/moderation/connectors",
  connectorsSearch: "/product-catalog/moderation/connectors/search",
  connectorsParser: "/product-catalog/moderation/connectors/parser",
  connections: "/product-catalog/moderation/connectors/connections",

  //user
  userName: "api/product-catalog/user-info",

  verify: "/product-catalog/moderation/product/verify",
  check: "/product-catalog/check_tasks",

  // duplicate
  duplicateProduct: (mpcCode) => `/product-catalog/moderation/v2/product/${mpcCode}/duplicate/`
}
