import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PT_showCreateAttributeModal: false,
  PT_attributesForCreateNewAtlAutocomplete: [{ label: "", value: 1 }],
  PT_attributesForNewAtl: false,
  PT_selectedAttribute: false,
  PT_atlIsMain: false,
  PT_atlIsRequired: false
})

const getters = {
  PT_showCreateAttributeModal: (state) => state.PT_showCreateAttributeModal,
  PT_attributesForCreateNewAtlAutocomplete: (state) =>
    state.PT_attributesForCreateNewAtlAutocomplete,
  PT_attributesForNewAtl: (state) => state.PT_attributesForNewAtl,
  PT_selectedAttribute: (state) => state.PT_selectedAttribute,
  PT_atlIsMain: (state) => state.PT_atlIsMain,
  PT_atlIsRequired: (state) => state.PT_atlIsRequired
}

const mutations = {
  PT_showCreateAttributeModal(state, value) {
    state.PT_showCreateAttributeModal = value
  },
  PT_attributesForCreateNewAtlAutocomplete(state, value) {
    state.PT_attributesForCreateNewAtlAutocomplete = value
  },
  PT_attributesForNewAtl(state, value) {
    state.PT_attributesForNewAtl = value
  },
  PT_selectedAttribute(state, value) {
    state.PT_selectedAttribute = value
  },
  PT_atlIsMain(state, value) {
    state.PT_atlIsMain = value
  },
  PT_atlIsRequired(state, value) {
    state.PT_atlIsRequired = value
  }
}

const actions = {
  PT_createNewAttribute({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let lastItemOrder =
      (getters.PT_searchedAttributesTable.count &&
        getters.PT_searchedAttributesNewOrder[getters.PT_searchedAttributesTable.count - 1]
          .order) ||
      0

    let atl = {
      product_type: getters.PT_typeCodeForAttribute,
      attribute: getters.PT_selectedAttribute.code,
      is_main: getters.PT_atlIsMain,
      is_required: getters.PT_atlIsRequired,
      order: lastItemOrder + 2
    }

    // start request
    API_MODERATION.post(`${apiUrls.link}`, atl)
      .then((r) => r.data.message)
      .then((r) => {
        // reload table with attrs
        let selected = getters.PT_selectedType || getters.PT_typesTableData.items[0]
        dispatch("PT_searchAttributes", selected)

        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PT_resetCreateAttributeValues")

        notification.info({ message: "Новый атрибут добавлен" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PT_resetCreateAttributeValues")

        commit("handleError", e)
      })
  },
  PT_resetCreateAttributeValues({ commit }) {
    commit("PT_showCreateAttributeModal", false)
    commit("PT_attributesForCreateNewAtlAutocomplete", [{ label: "", value: 1 }])
    commit("PT_attributesForNewAtl", false)
    commit("PT_selectedAttribute", false)
    commit("PT_atlIsMain", false)
    commit("PT_atlIsRequired", false)
  },
  PT_fetchAttributesForNewValueAutocomplete({ commit, getters }, value) {
    let searchData = buildMagicSearch({
      resource: "AND",
      field: "name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((attrs) => {
        // filter out existing attrs
        let existing = getters.PT_searchedAttributesTable.items
        if (existing) {
          attrs = attrs.filter((item) => {
            let valid = true
            if (existing.find((attr) => attr.attrCode === item.code)) {
              valid = false
            }
            return valid
          })
        }

        commit("PT_attributesForNewAtl", attrs)
        let attrsArray = attrs.map((item) => {
          return item.name
        })
        commit("PT_attributesForCreateNewAtlAutocomplete", attrsArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PT_processSelectedAttributeValue({ commit, getters, dispatch }, value) {
    let selectedValue = getters.PT_attributesForNewAtl.find((item) => {
      return item.name === value
    })

    // set
    commit("PT_selectedAttribute", selectedValue)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
