<template>
  <div class="chosen-tariff-info">
    <div>
      <label>{{ $t("newTariff") }}</label>
      <Select
        v-model="tariffs"
        propName="titleRu"
        @change="handleSelectNewTariff"
      />
    </div>
    <div>
      <label>{{ $t("validity") }}</label>
      <Select
        v-model="tariffCongigValues"
        propName="expire_in_days"
        @change="handleSelectTariffConfig"
      />
    </div>

    <div
      v-if="
        selectedTariffConfig &&
        selectedTariffConfig.price === 0 &&
        !isTariffExpired &&
        tariff_config.price !== 0
      "
      class="alert"
    >
      <span>{{ $t("chosingTariffError") }} {{ getFormatedDateTime(tariff_expire_at) }}</span>
    </div>
    <template v-else-if="selectedTariffConfig && selectedTariffConfig.price === 0">
      <span>{{ $t("expirationDate") }}</span>

      <a-date-picker
        show-time
        v-model="dateFinish"
        format="DD.MM.YYYY"
        :disabled-date="disabledDate"
      />
    </template>
    <template v-else-if="selectedTariffConfig">
      <a-descriptions
        bordered
        :column="1"
        size="small"
      >
        <a-descriptions-item :label="$t('tariffPrice')">
          {{ selectedTariffConfig?.price ? selectedTariffConfig.price : 0 }}
          {{ $t("uah") }}
        </a-descriptions-item>

        <a-descriptions-item :label="`${$t('currentBalance')} ${$t('ofSeller')}`">
          {{ bill?.balance || 0 }}
          {{ $t("uah") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('returnOfBalance')">
          {{ tariff_rest ? tariff_rest : 0 }} {{ $t("uah") }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('saldoAfterOperation')">
          <span :class="{ alert: saldo < 0 }">{{ saldo ? saldo : 0 }} {{ $t("uah") }}</span>
        </a-descriptions-item>
      </a-descriptions>

      <a-button
        type="primary"
        style="margin-left: auto; width: fit-content"
        @click="openConfirmChange"
        :disabled="saldo < 0 || !selectedTariffConfig || !selectedTariffConfig.price"
      >
        {{ $t("change") }}
      </a-button>
    </template>
  </div>
</template>

<script>
import Select from "@/components/Select"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"
import { sellerChangeTariff, fetchAllTariffs } from "@/modules/MPAdmin/services/billingService.js"

import moment from "moment"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  components: {
    Select
  },

  props: {
    sellerUuid: {
      type: String,
      required: true
    },
    bill: {
      type: Number | String,
      default: 0
    },
    tariff_config: {
      type: Number | String,
      default: 0
    },
    tariff_rest: {
      type: Number | String,
      default: 0
    },
    tariff_expire_at: {
      type: Number | String,
      default: 0
    }
  },

  data() {
    return {
      confirmModal: false,
      selectedNewTariff: null,
      selectedTariffConfig: null,
      dateFinish: null,
      tariffs: []
    }
  },

  mounted() {
    fetchAllTariffs().then((tariffs) => {
      this.tariffs = tariffs
        .filter((item) => item.configs.length > 0)
        .map((item) => {
          return {
            ...item,
            titleRu: item.title.uk || item.title.en || item.title.ru
          }
        })
    })
  },

  computed: {
    isTariffExpired() {
      const today = new Date().getTime()
      const tariffExpire = new Date(this.tariff_expire_at).getTime()

      return today > tariffExpire
    },

    tariffCongigValues: {
      get: function () {
        return this.selectedNewTariff?.configs
          ? this.selectedNewTariff.configs.filter((item) => item.is_active)
          : []
      },
      set: function (value) {
        this.selectedNewTariff = value
      }
    },

    saldo() {
      return this.selectedTariffConfig
        ? this.bill?.balance + this.tariff_rest - this.selectedTariffConfig.price
        : 0
    }
  },

  methods: {
    getFormatedDateTime,

    openConfirmChange() {
      this.$confirm({
        content: `${this.$t("questionToChangeTariff")} ${this.selectedNewTariff?.titleRu} / ${
          this.selectedTariffConfig.expire_in_days
        } ${this.$t("dayShorted")} ${this.$t("costOf")} ${
          this.selectedTariffConfig.price
        } ${this.$t("uah")}?`,
        onOk: () => {
          if (!this.selectedTariffConfig.is_active) {
            this.$notification.error({ message: this.$t("tariffConfigNotActive") })
            return
          }
          const sendObj = {
            new_tariff_config: this.selectedTariffConfig.id
          }
          if (this.dateFinish) {
            sendObj.expired_at_custom = this.dateFinish.toISOString()
          }

          sellerChangeTariff(this.sellerUuid, sendObj)
            .then(() => {
              this.$notification.success({ message: this.$t("success") })
              this.$emit("handleUpdateBillingInfo")
            })
            .catch((error) => {
              notifyResponseError({ error })
            })
        }
      })
    },

    handleSelectNewTariff(value) {
      this.selectedTariffConfig = {}
      this.selectedNewTariff = value ? value : null
      this.tariffCongigValues = this.selectedNewTariff
    },

    handleSelectTariffConfig(value) {
      this.selectedTariffConfig = value
      const today = new Date()
      this.dateFinish =
        value.price === 0 ? today.setDate(today.getDate() + value.expire_in_days) : ""
      value.price === 0 ? (this.dateFinish = new Date(this.dateFinish)) : ""
    },

    disabledDate(current) {
      return current && current < moment().endOf("day")
    }
  }
}
</script>

<style lang="scss">
.alert {
  color: $red-color;
}
.chosen-tariff-info {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  font-size: 14px;
  padding-left: 32px;

  & label {
    line-height: 30px;
  }
  & > * {
    width: 33%;
  }
  & > :nth-child(3) {
    width: 100%;
  }
}
</style>
