<template>
  <div class="page-not-found">
    <div class="page-not-found__title">404</div>
    <div class="page-not-found__sub-title">Page not found</div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style lang="scss" scoped>
.page-not-found {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-size: 50px;
    line-height: 50px;
  }

  &__sub-title {
    font-size: 40px;
    line-height: 40px;
  }
}
</style>
