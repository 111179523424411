<template>
  <span
    ref="mainInput"
    class="wrapper"
  >
    <Autocomplete
      :data="PA_unitsToEditAttributeAutocomplete"
      event="input"
      @event="emitInput($event, field, rowIndex)"
      :size="'small'"
      @select="submitEditCell($event, field, rowIndex)"
      :placeholder="(row.default_unit[0] && row.default_unit[0].name) || ''"
      @esc="cancelEditCell"
      @blur="cancelEditCell"
    ></Autocomplete>
  </span>
</template>

<script>
import Autocomplete from "components/Autocomplete"
import { mapGetters } from "vuex"

export default {
  components: {
    Autocomplete
  },
  name: "InputEdit",
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onEvent: Function,
    onSelect: Function
  },
  data() {
    return {
      timeout: null
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
  },
  methods: {
    emitInput(event) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.onEvent(event)
      }, 500)
    },
    submitEditCell(event, field, rowIndex) {
      let sendObject = {
        columnKey: field,
        newValue: event,
        row: this.row,
        rowIndex
      }
      this.onSelect(sendObject)
      this.close()
    },
    cancelEditCell() {
      this.close()
    }
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PA_unitsToEditAttributeAutocomplete: "PA_unitsToEditAttributeAutocomplete"
    })
  }
}
</script>
<style scoped>
span {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  height: 100%;
  padding: 2px 7px;
}
</style>
