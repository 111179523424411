export default {
  synthetic: "Synthetic Admin",
  authorization: "Авторизація",
  signIn: "Увійти",
  login: "Логін",
  userName: "Ім'я користувача",
  password: "Пароль",
  passwordConfirm: "Підтвердіть пароль",
  wrongLoginOrPassword: "Вказано неправильний логін або пароль",
  forgotPass: "Забули пароль?",
  restorePass: "Відновити",
  signOut: "Вийти"
}
