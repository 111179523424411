import BrandsTable from "./brandsTable/brandsTable"
import CreateBrands from "./createBrands/createBrands"
import SearchTypes from "./searchTypes/searchTypes"
import CheckUniqueBrand from "../validations/checkUniqueBrand"

const modules = {
  BrandsTable,
  CreateBrands,
  SearchTypes,
  CheckUniqueBrand
}

export default {
  modules
}
