export default (array) => {
  let sorted_arr = array.slice().sort() //clone the array so the original array won't be modified
  let results = []
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      //check if sorted siblings are the same
      results.push(sorted_arr[i])
    }
  }
  return !results.length
}
