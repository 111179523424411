export default function (queryParams, fields, resource, sort) {
  let queryArray = []
  let sorting = []
  if (sort) sorting.push(sort)
  if (queryParams.filters) {
    for (let i = 0; i < queryParams.filters.length; i++) {
      if (queryParams.filters[i].value !== null && queryParams.filters[i].value !== "") {
        // special case for timestamp filter
        if (fields[i] === "timestamp") {
          // let formatedFrom, formatedTill;

          let from = queryParams.filters[i].value[0]
          // let fromY = from.substring(0, 4);
          // let fromM = from.substring(5, 7);
          // let fromD = from.substring(8, 10);
          // Раніше відправляло timestamp з 18:00 по 18:00 тепер з 00:00 по 00:00
          let secondsInDay = 86400
          // let fromDate = new Date(`${fromY}.${fromM}.${fromD}`)
          // fromDate.setTime( fromDate.getTime() + fromDate.getTimezoneOffset() * 60 * 1000 )
          // formatedFrom = Math.round(); // Unix timestamp

          let till = queryParams.filters[i].value[1]
          // let tillY = till.substring(0, 4);
          // let tillM = till.substring(5, 7);
          // let tillD = till.substring(8, 10);
          // let tillDate = new Date(`${tillY}.${tillM}.${tillD}`)
          // tillDate.setTime( tillDate.getTime() + tillDate.getTimezoneOffset() * 60 * 1000 )
          // formatedTill = Math.round( / 1000 + secondsInDay); // Unix timestamp

          let objTill = {
            field: fields[i],
            value: `< ${till}`
          }

          queryArray.push(objTill)

          let objFrom = {
            field: fields[i],
            value: `>= ${from}`
          }

          queryArray.push(objFrom)
        } else {
          // regular case for all filters
          let obj = {
            field: fields[i],
            value: queryParams.filters[i].value
          }

          // special case for select filter with '---'
          if (obj.value === "---") obj.value = [null]

          queryArray.push(obj)
        }
      }
    }
  }
  let returnObj = {
    resource: resource,
    sorting: sorting,
    offset: queryParams.offset,
    limit: queryParams.limit
  }
  if (queryArray.length) {
    returnObj.filters = queryArray
  }
  return returnObj
}
