import avatarFemale from "@/assets/images/user/avatar-female.svg"
import avatarMale from "@/assets/images/user/avatar-male.svg"

export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const IMAGES_CDN = process.env.VUE_APP_IMAGES_CDN
export const IMAGES_API_CDN = process.env.VUE_APP_API_IMAGES_CDN
export const MARKETPLACE = process.env.VUE_APP_API_MARKETPLACE
export const MESSENGER_URL = process.env.VUE_APP_MESSENGER_URL
export const MESSENGER_URL_XHR = process.env.VUE_APP_MESSENGER_URL_XHR
export const MESSENGER_DOMAIN = process.env.VUE_APP_MESSENGER_DOMAIN
export const IMAGE_FEMALE = avatarFemale
export const IMAGE_MALE = avatarMale

export const AVATAR_MODERATION_STATUS_FAILED = "MODERATION_FAILED"
export const AVATAR_MODERATION_STATUS_EXPECTS = "EXPECTS_MODERATION"

export const PAYMENTS_API_INTEGRATION = ["MONO", "MONO_PARTS", "PRIVAT_HIRE_PURCHASE"]
