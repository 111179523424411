<template>
  <div class="pagination">
    <div class="col-4 pagination__info">
      <small>
        {{ $t("recordsFrom") }} {{ (currentPage - 1) * perPage + 1 }} {{ $t("to").toLowerCase() }}
        {{ (currentPage - 1) * perPage + perPage }}
        {{ $t("fromRecords", { count: count }) }}
      </small>
    </div>
    <div class="col-8 right">
      <ul class="pagination__list">
        <li
          v-if="hasPrev()"
          class="pagination__item pagination__item--arrow"
        >
          <a
            href=""
            @click.prevent="changePage(prevPage)"
          >
            <a-icon type="left" />
          </a>
        </li>

        <li
          v-if="this.currentPage > this.pageRange + 1"
          class="pagination__item"
        >
          <a
            href=""
            @click.prevent="changePage(1)"
            >1</a
          >
        </li>

        <li
          v-if="this.currentPage > this.pageRange + 2"
          class="splitter pagination__item"
        >
          ...
        </li>

        <li
          v-for="page in pages"
          :key="page.id"
          class="pagination__item"
          :class="{ current: currentPage === page }"
        >
          <a
            href=""
            @click.prevent="changePage(page)"
          >
            {{ page }}
          </a>
        </li>

        <li
          v-if="this.currentPage < this.totalPages - (this.pageRange + 1)"
          class="splitter pagination__item"
        >
          ...
        </li>

        <li
          v-if="this.currentPage < this.totalPages - this.pageRange"
          class="pagination__item"
        >
          <a
            href=""
            @click.prevent="changePage(totalPages)"
          >
            {{ this.totalPages }}
          </a>
        </li>

        <li
          v-if="hasNext()"
          class="pagination__item pagination__item--arrow"
        >
          <a
            href=""
            @click.prevent="changePage(nextPage)"
          >
            <a-icon type="right" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    count: {
      type: Number,
      default: 1
    },
    pageRange: {
      type: Number,
      default: 2
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.perPage)
    },
    pages() {
      const pages = []

      for (let i = this.rangeStart; i <= this.rangeEnd; i += 1) pages.push(i)

      return pages
    },
    rangeStart() {
      const start = this.currentPage - this.pageRange

      return start > 1 && start < this.currentPage ? start : 1
    },
    rangeEnd() {
      const end = this.currentPage + this.pageRange

      return end < this.totalPages ? end : this.totalPages
    },
    nextPage() {
      return this.currentPage + 1
    },
    prevPage() {
      return this.currentPage - 1
    }
  },
  methods: {
    changePage(page) {
      this.$emit("change", page)
    },
    hasPrev() {
      return this.currentPage > 1
    },
    hasNext() {
      return this.currentPage < this.totalPages
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/config";

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;

  &__info {
    font-size: $font-size-small;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    margin: 0;
  }

  &__item {
    margin: 0 5px;

    a {
      text-decoration: none;
      color: $font-color-secondary;

      &:hover {
        color: $primary-color;
      }
    }

    &--arrow {
      font-size: 12px;
      position: relative;
      & * {
        font-size: 12px;
      }
    }

    &.current {
      a {
        color: $primary-color;
      }
    }
  }
}
</style>
