import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PA_showAddListModal: false,
  PA_selectedListToAddAttribute: false,
  PA_listsToAddAttributeAutocomplete: []
})

const getters = {
  PA_showAddListModal: (state) => state.PA_showAddListModal,
  PA_selectedListToAddAttribute: (state) => state.PA_selectedListToAddAttribute,
  PA_listsToAddAttributeAutocomplete: (state) => state.PA_listsToAddAttributeAutocomplete
}

const mutations = {
  PA_showAddListModal(state, value) {
    state.PA_showAddListModal = value
  },
  PA_selectedListToAddAttribute(state, value) {
    state.PA_selectedListToAddAttribute = value
  },
  PA_listsToAddAttributeAutocomplete(state, value) {
    state.PA_listsToAddAttributeAutocomplete = value
  },
  AP_resetAddListValues() {
    state.PA_selectedListToAddAttribute = false
    state.PA_listsToAddAttributeAutocomplete = []
  }
}

const actions = {
  async PA_addAttributeToList({ commit, dispatch, getters }) {
    let products = await dispatch("PA_addAttrToListAndUpdateProducts")
    let response
    if (products && products instanceof Array) {
      response = await dispatch("PA_buildProductsTableData", products)
      if (!response) return
      commit("PA_showProductsModal", true)
    } else {
      // set selected type to LIST
      commit("PA_selectedAttributeType", "LIST")
      // reset values of secondary tables and restart main table
      commit("PA_resetValuesOfAttributeValues")
      commit("PA_resetAtlsOfAttributeValues")
      response = dispatch("PA_searchAttributesForSelected")
      if (!response) return
    }
    // reset values
    commit("AP_resetAddListValues")
  },
  async PA_addAttrToListAndUpdateProducts({ commit, getters }) {
    // show loader
    commit("PC_showLoader", true)

    let sendData = {
      attribute: getters.PA_selectedAttribute.code,
      description: getters.PA_selectedListToAddAttribute.description
    }

    // start request
    return API_MODERATION.patch(`${apiUrls.createFromAttribute}`, sendData)
      .then((r) => r.data.message)
      .then((r) => {
        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Новый атрибут добавлен" })
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        if (e.response && e.response.status === 400 && e.response.data.message.length) {
          let products = e.response.data.message
          return products
        } else {
          commit("handleError", e)
          return null
        }
      })
  },
  PA_searchListsToAddAttribute({ commit, getters }, value) {
    let lists = getters.PV_initialValuesDescriptionItems
    if (!lists) return

    let listsNamesArray = lists.map((item) => {
      return item.description
    })

    let filteredLists = listsNamesArray.filter((item) => {
      return item.trim().toLocaleLowerCase().indexOf(value.trim().toLocaleLowerCase()) !== -1
    })

    commit("PA_listsToAddAttributeAutocomplete", filteredLists)
  },
  PA_processSelectedListToAddAttribute({ commit, getters }, value) {
    let selectedList = getters.PV_initialValuesDescriptionItems.find((item) => {
      return item.description === value
    })
    commit("PA_selectedListToAddAttribute", selectedList)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
