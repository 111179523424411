<template>
  <div class="product-catalog__brands">
    <div class="row">
      <div class="col">
        <Select
          @change="onSelectLang"
          placeholder="Выберите язык"
          :values="PC_languages"
          :width="58"
          :selected="PC_selectedLanguage"
          class="lang-select"
        />
      </div>
    </div>
    <h2>Справочник брендов</h2>
    <BrandsTable />
    <CreateBrandModal />
    <SearchTypesModal />
  </div>
</template>
<script>
import CreateBrandModal from "./CreateBrandModal/CreateBrandModal"
import SearchTypesModal from "./SearchTypesModal/SearchTypesModal"
import BrandsTable from "./BrandsTable/BrandsTable"
import { mapActions, mapMutations, mapGetters } from "vuex"
import Select from "components/Select/Select"

export default {
  metaInfo: {
    title: "MS: Brands"
  },
  name: "ProductCatalogBrands",
  components: {
    BrandsTable,
    CreateBrandModal,
    SearchTypesModal,
    Select
  },
  methods: {
    onSelectLang(value) {
      if (value === this.PC_selectedLanguage) return
      let page = "brands"
      this.PC_selectLanguage({ value, page })
    },
    ...mapMutations("ProductCatalog", {
      PB_resetBrandsTableValues: "PB_resetBrandsTableValues"
    }),
    ...mapActions("ProductCatalog", {
      PC_selectLanguage: "PC_selectLanguage"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PC_languages: "PC_languages",
      PC_selectedLanguage: "PC_selectedLanguage"
    })
  },
  beforeRouteLeave(to, from, next) {
    this.PB_resetBrandsTableValues()
    next()
  }
}
</script>
<style lang="scss"></style>
