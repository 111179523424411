import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_valuesDescriptionQueryParams: {
    limit: 50,
    ordering: ["-description"],
    offset: 0
  },
  PV_valuesDescriptionData: { count: 0, items: [] },
  PV_valuesDescriptionFetchingStatus: false,
  PV_initialValuesDescriptionItems: [],
  PV_selectedList: false
})

const getters = {
  PV_valuesDescriptionQueryParams: (state) => state.PV_valuesDescriptionQueryParams,
  PV_valuesDescriptionData: (state) => state.PV_valuesDescriptionData,
  PV_valuesDescriptionFetchingStatus: (state) => state.PV_valuesDescriptionFetchingStatus,
  PV_initialValuesDescriptionItems: (state) => state.PV_initialValuesDescriptionItems,
  PV_selectedList: (state) => state.PV_selectedList,
  PV_selectedListCount: (state) => state.PV_selectedList.count
}

const mutations = {
  PV_valuesDescriptionQueryParams(state, value) {
    state.PV_valuesDescriptionQueryParams = value
  },
  PV_valuesDescriptionData(state, value) {
    state.PV_valuesDescriptionData = value
  },
  PV_valuesDescriptionFetchingStatus(state, value) {
    state.PV_valuesDescriptionFetchingStatus = value
  },
  PV_valuesDescriptionSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    state.PV_valuesDescriptionData.items[rowIndex][columnKey] = newValue
  },
  PV_initialValuesDescriptionItems: (state, value) => {
    state.PV_initialValuesDescriptionItems = value
  },
  PV_selectedList(state, value) {
    state.PV_selectedList = value
  },
  PV_selectedListCount(state, value) {
    state.PV_selectedList.count = value
  },
  PV_resetListTableValues(state) {
    state.PV_valuesDescriptionQueryParams = {
      limit: 50,
      ordering: ["-description"],
      offset: 0
    }
    state.PV_valuesDescriptionData = { count: 0, items: [] }
  }
}

const actions = {
  async PV_searchValuesByDescription({ commit, dispatch, getters }, queryParams) {
    if (queryParams && queryParams.filters && queryParams.filters.length) {
      dispatch("PV_filterValueDescriptionItems", queryParams)
      return
    }
    let response = await dispatch("PV_searchValuesByDescriptionRequest")
    if (!response) return

    // if not empty search values and attributes for the first list or for the selected list

    if (!getters.PV_selectedList) {
      commit("PV_selectedList", response[0])
    }
    let selected = getters.PV_selectedList
    if (response.length) {
      response = await dispatch("PV_searchValues", {
        selected,
        queryParams: getters.PV_valuesQueryParams
      })
      if (!response) return
      response = await dispatch("PV_searchAttributes", selected)
      if (!response) return
    }

    // update table query params
    if (queryParams) {
      commit("PV_valuesDescriptionQueryParams", queryParams)
    }
  },
  async PV_searchValuesByDescriptionRequest({ commit, getters }) {
    // set fetching status
    commit("PV_valuesDescriptionFetchingStatus", true)
    return API_MODERATION.get(
      // get vaues lists
      `${apiUrls.lists}`,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        let valuesCount = message.length + 1
        let values = message
        let items = buildValuesDescriptionTableItems(values)
        let tableObj = {
          count: valuesCount,
          items: items
        }
        commit("PV_valuesDescriptionData", tableObj)
        commit("PV_initialValuesDescriptionItems", items)

        commit("PV_valuesDescriptionFetchingStatus", false)
        return message
      })
      .catch((e) => {
        commit("PV_valuesDescriptionFetchingStatus", false)
        commit("handleError", e)
        return null
      })
  },
  PV_filterValueDescriptionItems({ commit, dispatch, getters }, queryParams) {
    let filtered = getters.PV_initialValuesDescriptionItems
    for (let i = 0; i < queryParams.filters.length; i++) {
      if (queryParams.filters[i].value === null) continue
      let value = queryParams.filters[i].value.toLowerCase()
      let field = queryParams.filters[i].name
      filtered = filtered.filter((item) => {
        return String(item[field]).toLowerCase().indexOf(value) !== -1
      })
    }
    let tableObj = {
      count: filtered.length + 1,
      items: filtered
    }
    commit("PV_valuesDescriptionData", tableObj)

    // update table query params
    commit("PV_valuesDescriptionQueryParams", queryParams)
  },
  PV_editValueDescription({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PV_valuesDescriptionData.items[rowIndex][columnKey]
    if (newValue === oldValue) return

    let sendData = {
      old_description: oldValue,
      new_description: newValue
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.lists}`, sendData)
      .then((r) => {
        commit("PV_valuesDescriptionSetNewValue", { columnKey, newValue, rowIndex })

        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения продукта добавлены" })
        if (columnKey === "coef") {
          notification.info({ message: "Не забудьте добавить базовую еденицу измерения" })
        }
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  async PV_reloadDependentTablesForSelectedList({ commit, getters, dispatch }, selected) {
    // reset
    dispatch("PV_resetValuesTalbe")
    dispatch("PV_resetAttributesTable")

    window.scrollTo(0, 0)
    commit("PV_selectedList", selected)
    let response = await dispatch("PV_searchValues", {
      selected,
      queryParams: getters.PV_valuesQueryParams
    })
    if (!response) return
    await dispatch("PV_searchAttributes", selected)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildValuesDescriptionTableItems(values) {
  return values.map((item, index) => {
    // id
    item._id = `valuesDescriptionRow-${index}`
    return item
  })
}
