<template>
  <div>
    <Modal
      :showModal="AE_confirmConversionModalGet"
      :title="title"
      @close="closeModal"
    >
      <div>
        <template v-if="tempValueTypeGet !== 'NUMERIC'">
          <label>
            <span
              >Будет запущен процесс конвертации и указанный атрибут будет заблокирована на
              редактирование во всех товарах, пока новые значения временной таблицы не заменят
              старые. Подтвердить?</span
            >
          </label>
        </template>
        <template v-if="tempValueTypeGet === 'NUMERIC'">
          <label>
            <span>Выберите для атрибута новую единицу измерения по умолчанию:</span>
          </label>
          <Autocomplete
            :data="unitsAutocomplete.data"
            :defaultValue="unitsAutocomplete.default"
            class="autocomplete-section__autocomplete"
            :size="'small'"
            event="input"
            :disabled="true"
            @event="AE_fetchUnits"
            @select="setSelectedUnit"
          />
          <label
            v-if="unitsAutocomplete.selectedObject"
            class="margin-top-10"
          >
            <span
              >Выбранная единица измерения:
              <strong v-if="unitsAutocomplete.selectedObject">{{
                unitsAutocomplete.selectedObject.name
              }}</strong>
            </span>
          </label>
          <label class="margin-top-10">
            <span
              >Внимание! данные в колонке "Новое значение" будут перезаписаны согласно алгоритму
              определения единицы измерения.</span
            >
          </label>
        </template>
      </div>
      <span slot="footer">
        <Button
          color="primary"
          style="margin-right: 0.25rem"
          @click="onConfirm"
        >
          Подтвердить
        </Button>
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Autocomplete from "components/Autocomplete"
import { mapActions, mapMutations, mapState } from "vuex"
import regeneratorRuntime from "regenerator-runtime"

export default {
  components: {
    Button,
    Modal,
    Autocomplete
  },
  data() {
    return {
      interval: null
    }
  },
  computed: {
    title() {
      return this.tempValueTypeGet === "NUMERIC"
        ? "Выберите единицу измерения"
        : "Требуется подтверждение"
    },
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    ...mapState("ProductCatalog/attributesEdit", {
      AE_confirmConversionModalGet: (state) => state.conversion.AE_confirmConversionModal,
      tempValueTypeGet: (state) => state.conversion.tempValueType,
      unitsAutocomplete: (state) => state.conversion.unitsAutocomplete,
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr
    })
  },
  methods: {
    onConfirm() {
      let sendObj
      if (this.tempValueTypeGet === "NUMERIC") {
        sendObj = {
          selectedAttrCode: this.AE_selectedAttrGet.code
        }
        if (this.unitsAutocomplete.selectedObject) {
          this.unitsByBase(this.unitsAutocomplete.selectedObject)
          sendObj.selectedUnitCode = this.unitsAutocomplete.selectedObject.code
        }
        this.submitConversion(sendObj)
      } else if (this.tempValueTypeGet === "STRING") {
        sendObj = { selectedAttrCode: this.AE_selectedAttrGet.code }
        this.submitConversion(sendObj)
      }
    },
    async submitConversion({ selectedAttrCode, selectedUnitCode }) {
      try {
        this.PC_showLoader(true)
        let id = await this.AE_applyConversion({ selectedAttrCode, selectedUnitCode })
        this.checkWithInterval(id, selectedAttrCode)
      } catch (e) {
        this.handleError(e)
      }
    },
    async checkWithInterval(id, selectedAttrCode) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]

        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.interval)
        }
        if (response.task_state === "SUCCESS") {
          await this.AE_tableSearch({ selectedAttrCode })
          this.AE_selectedValueType(this.tempValueTypeGet)
          this.$notification.info({
            message: 'Изменения будут добавлены после нажатия на кнопку "Применить"'
          })
          this.closeModal()
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
        }
      }, this.celeryInterval)
    },
    closeModal() {
      this.AE_confirmConversionModal(false)
      this.tempValueType(null)
    },
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_confirmConversionModal: "AE_confirmConversionModal",
      resetUnitsAutocomplete: "resetUnitsAutocomplete",
      tempValueType: "tempValueType",
      AE_selectedValueType: "AE_selectedValueType"
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_fetchUnits: "AE_fetchUnits",
      setSelectedUnit: "setSelectedUnit",
      unitsByBase: "unitsByBase",
      AE_applyConversion: "AE_applyConversion",
      AE_tableSearch: "AE_tableSearch"
    })
  }
}
</script>
<style lang="scss"></style>
