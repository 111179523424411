<template>
  <div class="row">
    <div class="col">
      <a-collapse
        :bordered="false"
        style="background: transparent"
      >
        <a-collapse-panel style="border: none">
          <template #header>
            Поиск значения по всем добавленным спискам <a-icon type="search" />
          </template>

          <Input
            size="medium"
            :value="inputValue"
            @input="onInput"
            @enter="onSearch"
            class="input-search"
          />
          <Button
            color="primary"
            @click="onSearch"
            :disabled="inputValue === ''"
          >
            Поиск
          </Button>
        </a-collapse-panel>
      </a-collapse>
      <SearchValueInListsModal />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import Input from "components/Input"
import SearchValueInListsModal from "./SearchValueInListsModal/SearchValueInListsModal"
import Button from "components/Button"

export default {
  components: {
    Input,
    SearchValueInListsModal,
    Button
  },
  data() {
    return {
      inputValue: ""
    }
  },
  methods: {
    onInput(value) {
      this.inputValue = value
    },
    onSearch() {
      if (this.inputValue === "") return
      this.PV_searchValueInLists(this.inputValue)
    },
    ...mapMutations("ProductCatalog", {}),
    ...mapActions("ProductCatalog", {
      PV_searchValueInLists: "PV_searchValueInLists"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {})
  }
}
</script>
<style lang="scss" scoped>
.input-search {
  width: 25em;
  display: inline-block;
  margin-right: 0.25rem;
}
</style>
