import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  PC_requiredAttributesOfType: false,
  PC_mainAttributesOfType: false,
  PC_missingRequiredAttributes: false,
  PC_missingMainAttributes: false,
  PC_missingAttributesObjects: false,
  PC_missingMainAttributesObjects: false
})

const getters = {
  PC_requiredAttributesOfType: (state) => state.PC_requiredAttributesOfType,
  PC_missingRequiredAttributes: (state) => state.PC_missingRequiredAttributes,
  PC_missingMainAttributes: (state) => state.PC_missingMainAttributes,
  PC_missingAttributesObjects: (state) => state.PC_missingAttributesObjects,
  PC_missingMainAttributesObjects: (state) => state.PC_missingMainAttributesObjects
}

const mutations = {
  PC_requiredAttributesOfType(state, value) {
    state.PC_requiredAttributesOfType = value
  },
  PC_setMainAttributesOfType(state, value) {
    state.PC_mainAttributesOfType = value
  },
  PC_missingRequiredAttributes(state, value) {
    let clone = JSON.parse(JSON.stringify(state.PC_missingRequiredAttributes))
    state.PC_missingRequiredAttributes = clone
    state.PC_missingRequiredAttributes = value
  },
  PC_setMissingMainAttributes(state, value) {
    let clone = JSON.parse(JSON.stringify(state.PC_missingMainAttributes))
    state.PC_missingMainAttributes = clone
    state.PC_missingMainAttributes = value
  },
  PC_missingAttributesObjects(state, value) {
    state.PC_missingAttributesObjects = value
  },
  PC_resetCheckRequiredAttributes(state, value) {
    state.PC_requiredAttributesOfType = false
    state.PC_missingRequiredAttributes = false
    state.PC_missingAttributesObjects = false
  },
  PC_resetCheckMainAttributes(state) {
    state.PC_mainAttributesOfType = false
    state.PC_missingMainAttributes = false
    state.PC_missingAttributesObjects = false
  },
  compareFilterMain(state) {
    if (!state.PC_missingMainAttributes) {
      return
    }
    state.PC_missingMainAttributes = state.PC_missingMainAttributes.filter(
      (item) => !item.is_required
    )
  },
  compareFilterRequired(state) {
    if (!state.PC_missingRequiredAttributes) {
      return
    }
    state.PC_missingRequiredAttributes = state.PC_missingRequiredAttributes.filter(
      (item) => !item.is_main
    )
  }
}

const actions = {
  async PC_checkRequiredAttributeOfType({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)
    let product = getters.PC_EditableProduct

    let sendData = buildMagicSearch({
      resource: "ATL",
      field: ["product_type", "is_required"],
      value: [product.type.code, [true]],
      sort: null,
      limit: 200,
      offset: null
    })
    // start request
    return API_MODERATION.post(`${apiUrls.searchMagic}`, sendData)
      .then((r) => r.data.message.data)
      .then((r) => {
        // assign response product
        commit("PC_requiredAttributesOfType", r)
        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  async PC_checkMainAttributeOfType({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)
    let product = getters.PC_EditableProduct

    let sendData = buildMagicSearch({
      resource: "ATL",
      field: ["product_type", "is_main"],
      value: [product.type.code, [true]],
      sort: null,
      limit: 200,
      offset: null
    })

    // start request
    return API_MODERATION.post(`${apiUrls.searchMagic}`, sendData)
      .then((r) => r.data.message.data)
      .then((r) => {
        // assign response product

        commit("PC_setMainAttributesOfType", r)
        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  async PC_missingRequiredAttributes({ commit, getters, dispatch }) {
    let currentAttrs = getters.PC_editableProductAttributes.items
    let requiredAttrs = getters.PC_requiredAttributesOfType
    let missingAttrs = []

    for (let i = 0; i < requiredAttrs.length; i++) {
      let addedAlready = currentAttrs.find((item) => {
        return item.code === requiredAttrs[i].attribute
      })
      if (!addedAlready) {
        missingAttrs.push(requiredAttrs[i])
      }
    }

    commit("PC_missingRequiredAttributes", missingAttrs)
  },
  async PC_missingMainAttributes({ state, commit, getters }) {
    let currentAttrs = getters.PC_editableProductAttributes.items
    let mainAttrs = state.PC_mainAttributesOfType
    let missingAttrs = []

    for (let i = 0; i < mainAttrs.length; i++) {
      let addedAlready = currentAttrs.find((item) => {
        return item.code === mainAttrs[i].attribute
      })
      if (!addedAlready) {
        missingAttrs.push(mainAttrs[i])
      }
    }

    commit("PC_setMissingMainAttributes", missingAttrs)
  },

  async PC_addAndFetchMissingAttributes({ commit, dispatch }) {
    // обязательные
    let attrs = await dispatch("PC_fetchMissingAttributesObjects")
    if (!attrs) return
    await dispatch("PC_assignMissingAttributes")

    await dispatch("PC_updateAttributesChanges")
    // to get responding atls as well
    let response = dispatch("PC_restartFetchAttributes")
    if (!response) return
    commit("PC_resetCheckRequiredAttributes")
    commit("compareFilterMain")
  },

  async PC_addMainAttributes({ commit, dispatch }) {
    // основные
    let attrs = await dispatch("PC_fetchMainAttributes")
    if (!attrs) return
    await dispatch("PC_assignMainAttributes")

    await dispatch("PC_updateAttributesChanges")
    // to get responding atls as well
    let response = dispatch("PC_restartFetchAttributes")
    if (!response) return
    commit("PC_resetCheckMainAttributes")
    commit("compareFilterRequired")
  },

  async PC_fetchMissingAttributesObjects({ commit, getters }) {
    // обязательные
    // show loader
    commit("PC_showLoader", true)
    let attrs = getters.PC_missingRequiredAttributes
    let codesArray = attrs.map((item) => {
      return item.attribute
    })

    let params = new URLSearchParams()
    codesArray.forEach((item) => {
      params.append("code", item)
    })
    params.append("complex", true)

    // start request
    return API_MODERATION.get(`${apiUrls.attribute}`, { params })
      .then((r) => r.data.message.records)
      .then((r) => {
        // assign response product
        commit("PC_missingAttributesObjects", r)

        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },

  async PC_fetchMainAttributes({ state, commit }) {
    // основные
    // show loader
    commit("PC_showLoader", true)
    let attrs = state.PC_missingMainAttributes
    let codesArray = attrs.map((item) => {
      if (item) {
        return item.attribute
      }
    })

    let params = new URLSearchParams()
    codesArray.forEach((item) => {
      params.append("code", item)
    })
    params.append("complex", true)

    // start request
    return API_MODERATION.get(`${apiUrls.attribute}`, { params })
      .then((r) => r.data.message.records)
      .then((r) => {
        // assign response product
        commit("PC_missingAttributesObjects", r)

        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },

  async PC_assignMissingAttributes({ commit, getters }) {
    let items = getters.PC_missingAttributesObjects

    let newAttributes = []
    // push to editable product.attributes
    for (let i = 0; i < items.length; i++) {
      let value = ""
      // if type is not 'list' push empty string
      if (items[i].value_type && items[i].value_type !== "LIST") value = ""
      // if type is 'list' push first among 'possible values'
      if (
        items[i].value_type &&
        items[i].value_type === "LIST" &&
        items[i].possible_values &&
        items[i].possible_values[0] &&
        items[i].possible_values[0].code
      ) {
        value = items[i].possible_values[0].code
      }
      // if type is 'multilist' push first among 'possible values'
      if (
        items[i].value_type &&
        items[i].value_type === "MULTILIST" &&
        items[i].possible_values &&
        items[i].possible_values[0] &&
        items[i].possible_values[0].code
      ) {
        value = [items[i].possible_values[0].code]
      }

      let obj = {
        code: items[i].code,
        value: value,
        name: items[i].name
      }

      try {
        if (items[i].value_type && items[i].value_type == "NUMERIC" && items[i].default_unit) {
          obj.unit = items[i].default_unit.code
        }
      } catch (e) {
        console.error(e, "obj.unit = items[i].default_unit.code")
      }

      newAttributes.push(obj)
    }
    for (let i = 0; i < newAttributes.length; i++) {
      commit("PC_pushEditableProductInnerAttributes", newAttributes[i])
    }
    // add flag that attrs were added
    if (getters.PC_someAttributesAdded !== true) commit("PC_someAttributesAdded", true)
    return Promise.resolve()
  },
  async PC_assignMainAttributes({ commit, getters }) {
    let items = getters.PC_missingAttributesObjects

    let newAttributes = []
    // push to editable product.attributes
    for (let i = 0; i < items.length; i++) {
      let value = ""
      // if type is not 'list' push empty string
      if (items[i].value_type && items[i].value_type !== "LIST") value = ""
      // if type is 'list' push first among 'possible values'
      if (
        items[i].value_type &&
        items[i].value_type === "LIST" &&
        items[i].possible_values &&
        items[i].possible_values[0] &&
        items[i].possible_values[0].code
      ) {
        value = items[i].possible_values[0].code
      }
      // if type is 'multilist' push first among 'possible values'
      if (
        items[i].value_type &&
        items[i].value_type === "MULTILIST" &&
        items[i].possible_values &&
        items[i].possible_values[0] &&
        items[i].possible_values[0].code
      ) {
        value = [items[i].possible_values[0].code]
      }

      let obj = {
        code: items[i].code,
        value: value,
        name: items[i].name
      }

      if (items[i].value_type && items[i].value_type == "NUMERIC" && items[i].default_unit) {
        obj.unit = items[i].default_unit.code
      }

      newAttributes.push(obj)
    }
    for (let i = 0; i < newAttributes.length; i++) {
      commit("PC_pushEditableProductInnerAttributes", newAttributes[i])
    }
    // add flag that attrs were added
    if (getters.PC_someAttributesAdded !== true) commit("PC_someAttributesAdded", true)
    return Promise.resolve()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
