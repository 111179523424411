<template>
  <div
    v-if="!tree"
    class="select-item"
    :class="{ 'select-item--disabled': disabled }"
    @click.prevent.stop="onSelect"
  >
    {{ item[labelKey] }}
  </div>
  <div
    v-else
    class="select-item-tree"
  >
    <div
      class="select-item-tree__header"
      :class="{ 'select-item-tree__header--withoutLine': withoutLine }"
    >
      <div
        @click.prevent.stop="onItemSelect(item[valueKey])"
        class="select-item-tree__header-title"
        :class="{ 'select-item-tree__header-title--selected': isSelected }"
      >
        {{ item[labelKey] }}
      </div>
    </div>
    <div
      v-if="item[childrenKey].length > 0"
      class="select-item-tree__children"
    >
      <SelectItem
        v-for="childrenItem in item[childrenKey]"
        :key="childrenItem[valueKey]"
        :labelKey="labelKey"
        :childrenKey="childrenKey"
        :onItemSelect="onItemSelect"
        :valueKey="valueKey"
        :item="childrenItem"
        :tree="tree"
        :isSelected="selected === childrenItem[valueKey]"
        :selected="selected"
        :localSelected="localSelected"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectItem",
  props: {
    labelKey: String,
    childrenKey: String,
    onItemSelect: Function,
    valueKey: String,
    item: Object,
    tree: Boolean,
    localSelected: [String, Number, Boolean],
    selected: [String, Number],
    isSelected: {
      type: Boolean,
      default: false
    },
    withoutLine: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSelect() {
      if (this.disabled) return
      this.onItemSelect(this.item[this.valueKey], this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-item {
  padding: 3px 10px;
  border-bottom: solid 1px $light-border;
  cursor: pointer;

  &--disabled {
    color: rgba(0, 0, 0, 0.44);
  }

  &:hover {
    background-color: $default-shadow-color;
  }

  &:last-child {
    border-bottom: none;
  }
}

.select-item-tree {
  $this: &;
  cursor: pointer;

  &__header {
    padding: 3px 10px;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 14px;
      left: 0;
      height: 1px;
      width: 10px;
      background-color: $light-border;
    }

    &--withoutLine {
      &:before {
        display: none;
      }
    }
  }

  &__header-title {
    word-break: break-all;
    padding: 0 5px;
    width: calc(100% - 10px);

    &--selected,
    &:hover {
      background-color: $default-shadow-color;
    }
  }

  &__children {
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      background-color: $light-border;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>
