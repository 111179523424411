<template>
  <div class="edit-seller-modal">
    <a-tabs defaultActiveKey="editSeller">
      <a-tab-pane key="editSeller">
        <template #tab><a-icon type="edit" />{{ $t("sellerEditing") }}</template>

        <SellerForm
          ref="sellerFormRef"
          :seller="seller"
          @onRemoveLabel="onRemoveLabel"
          @onChangeSeller="onChangeSeller"
        />

        <CpaSummary
          :seller="seller"
          @onChangeBalance="onChangeBalance"
          @onChangeSeller="onChangeSeller"
        />
      </a-tab-pane>

      <a-tab-pane key="balanceHistory">
        <template #tab><a-icon type="history" />{{ $t("balanceHistory") }}</template>

        <BalanceHistory
          ref="balanceHistoryRef"
          :uuid="seller.uuid"
          :slug="seller.slug"
        />
      </a-tab-pane>
    </a-tabs>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="handleClose()"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="loading"
        @click="handleConfirm()"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import i18n from "@/i18n"
import { notification, Modal } from "ant-design-vue"

import BalanceHistory from "./BalanceHistoryTab/BalanceHistory.vue"
import { CpaSummary, SellerForm } from "./EditSellerTab/index.js"

import { editSeller, removeSellerLabel } from "@/modules/ADS/services/sellerServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  seller: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["submit", "close", "onChangeSeller"])

const sellerFormRef = ref()
const balanceHistoryRef = ref()

const loading = ref(false)

const notifySuccess = () => {
  notification.success({
    message: i18n.t("success")
  })
}

const handleConfirm = async () => {
  const formModel = await sellerFormRef.value.onValidate()

  if (!formModel) return

  try {
    loading.value = true
    const { data } = await editSeller(props.seller.seller_id, formModel)

    notifySuccess()
    emit("submit", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    loading.value = false
  }
}

const onRemoveLabel = () => {
  Modal.confirm({
    title: `${i18n.t("clearCustomLabel")} ${props.seller.name}?`,
    cancelText: i18n.t("cancel"),
    onOk: removeCustomLabel,
    onCancel() {}
  })
}

const removeCustomLabel = async () => {
  try {
    const { data } = await removeSellerLabel(props.seller.seller_id)

    notifySuccess()
    onChangeSeller(data)
  } catch (error) {
    notifyResponseError({ error })
  }
}

const handleClose = () => {
  emit("close")
}

const onChangeSeller = (data) => {
  emit("onChangeSeller", { ...data, uuid: props.seller.uuid })
}

const onChangeBalance = () => {
  if (!balanceHistoryRef.value) return
  balanceHistoryRef.value.onTableChange()
}
</script>

<style lang="scss" scoped>
.edit-seller-modal {
  padding-bottom: 70px;
}
</style>

<style lang="scss">
// Removes gray line under tabs
.edit-seller-modal {
  & .ant-tabs-bar {
    border-bottom: none;
  }
}
</style>
