<template>
  <span ref="mainInput">
    <input
      ref="editableCell"
      type="text"
      v-model="myVal"
      @keyup.enter="(e) => submitEditCell(field, e.target.value, rowIndex)"
      @keyup.esc="cancelEditCell"
      @input="onInput"
    />
    <ValidationErrors
      :lengthError="showError"
      :emptyError="showEmptyError"
      :uniqueError="!PU_checkUniqueUnitGet && myVal !== initialValue && myVal !== ''"
    />
  </span>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validEmpty from "utils/validations/validEmpty"
import validMaxLength from "utils/validations/validMaxLength"

export default {
  name: "InputEditName",
  components: {
    ValidationErrors
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onUpdate: Function
  },
  data() {
    return {
      showError: false,
      showEmptyError: false,
      sendObject: {},
      myVal: this.row[this.field],
      initialValue: this.row[this.field],
      timeout: null
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
    if (this.PC_selectedLanguage === this.PC_baseLanguage) {
      this.validateOnMount()
    }
    this.closeOnBlurEvent()
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PU_checkUniqueUnitGet: "PU_checkUniqueUnitName",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage"
    })
  },
  methods: {
    validateOnMount() {
      if (this.myVal !== this.initialValue) {
        this.PU_checkUniqueUnitName(this.$refs.editableCell.value)
      }
    },
    onInput(event) {
      let value = event.target.value

      this.showEmptyError = !validEmpty(value)
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        this.showError = !validMaxLength(value, 10)
        this.checkUniqueUnitName(value)
      }
    },
    checkUniqueUnitName(value) {
      if (this.myVal !== this.initialValue) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.PU_checkUniqueUnitName(value)
        }, 500)
      }
    },
    submitEditCell(field, value, rowIndex) {
      if (this.showError || !this.PU_checkUniqueUnitGet || this.showEmptyError) return

      this.submitName(field, value, rowIndex)

      this.onUpdate(this.sendObject)
      this.PU_checkUniqueUnitMute(true)
      this.close()
    },
    submitName(field, value, rowIndex) {
      this.sendObject = {
        columnKey: field,
        newValue: value,
        rowIndex
      }
    },
    cancelEditCell() {
      this.PU_checkUniqueUnitMute(true)
      this.close()
      // this.resetValues();
    },
    resetValues() {
      this.showError = false
      this.showEmptyError = false
      this.sendObject = {}
    },
    closeOnBlurEvent() {
      let context = this
      this.$refs.editableCell.addEventListener("blur", context.close, false)
    },
    unsubscribeOnBlur() {
      this.PU_checkUniqueUnitMute(true)
      let context = this
      this.$refs.editableCell.removeEventListener("blur", context.close, false)
    },
    ...mapMutations("ProductCatalog", {
      PU_checkUniqueUnitMute: "PU_checkUniqueUnitName"
    }),
    ...mapActions("ProductCatalog", {
      PU_checkUniqueUnitName: "PU_checkUniqueUnitName"
    })
  },
  beforeDestroy() {
    this.unsubscribeOnBlur()
  }
}
</script>
