import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=9afcef7a&scoped=true"
import script from "./Avatar.vue?vue&type=script&setup=true&lang=js"
export * from "./Avatar.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=9afcef7a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9afcef7a",
  null
  
)

export default component.exports