import { computed, h } from "vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo, activeTable }) => {
  const columns = computed(() => {
    let cols = [
      {
        key: "ordered_at",
        dataIndex: "ordered_at",
        title: i18n.t("dateOrder"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sortOrder: sortedInfo.value.columnKey === "ordered_at" && sortedInfo.value.order,
        sorter: true,
        fixed: "left",
        width: 105
      },
      {
        key: "updated_at",
        dataIndex: "updated_at",
        title: i18n.t("updatedAt"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sortOrder: sortedInfo.value.columnKey === "updated_at" && sortedInfo.value.order,
        sorter: true,
        fixed: "left",
        width: 125
      },
      {
        dataIndex: "seller",
        key: "seller",
        title: i18n.t("sellerPlusSlug"),
        scopedSlots: { customRender: "slugRenderer" },
        fixed: "left",
        width: 150
      },
      {
        key: "order_code",
        dataIndex: "order_code",
        title: i18n.t("code"),
        scopedSlots: { customRender: "orderCodeRenderer" },
        fixed: "left",
        width: 110
      },
      {
        key: "status",
        dataIndex: "status",
        title: i18n.t("statusOrder"),
        scopedSlots: { customRender: "orderStatusRenderer" },
        width: 135
      },
      {
        key: "referral",
        dataIndex: "referral",
        title: "Ref",
        scopedSlots: { customRender: "referalRenderer" },
        width: 100
      },
      {
        key: "receiver",
        dataIndex: "receiver.first_name",
        title: i18n.t("buyer"),
        scopedSlots: { customRender: "receiverRenderer" },
        width: 185
      },
      {
        key: "cs_code",
        dataIndex: "offer.cs_code",
        scopedSlots: {
          title: "csCodeTitle",
          customRender: "csCodeRenderer"
        },
        width: 195
      },
      {
        key: "quantity",
        dataIndex: "quantity",
        title: i18n.t("countShorted"),
        width: 75
      },
      {
        key: "price_per_item",
        dataIndex: "price_per_item",
        title: `${i18n.t("price")}, ${i18n.t("uah")}`,
        customRender: (sum) => getFormatedSum(sum),
        width: 105
      },
      {
        key: "price_total",
        dataIndex: "price_total",
        title: `${i18n.t("sum")}, ${i18n.t("uah")}`,
        customRender: (sum) => getFormatedSum(sum),
        width: 105
      },
      {
        key: "cpa_percent",
        dataIndex: `${activeTable.value}.percent`,
        title: activeTable.value === "cpa" ? "CPA" : "REF",
        scopedSlots: { customRender: "cpaOrRefRenderer" },
        width: 70
      },
      {
        key: "commission",
        dataIndex: `${activeTable.value}.commission`,
        title:
          activeTable.value === "cpa"
            ? `${i18n.t("commission")}, ${i18n.t("uah")}`
            : i18n.t("rewardShorted"),
        customRender: (sum) => getFormatedSum(sum),
        width: 125
      },
      {
        key: activeTable.value === "cpa" ? "cpa_status" : "ref_status",
        dataIndex: `${activeTable.value}.status`,
        title:
          activeTable.value === "cpa" ? i18n.t("commissionStatus") : i18n.t("rewardStatusShorted"),
        scopedSlots: {
          customRender: "cpaOrRefStatusRenderer"
        },
        width: 120
      },
      {
        key: activeTable.value === "cpa" ? "cpa_code" : "ref_code",
        dataIndex: `${activeTable.value}.code`,
        title: i18n.t("billing"),
        width: 115
      },
      {
        key: "is_cpa_comment",
        dataIndex: `${activeTable.value}.comment`,
        title: i18n.t("note"),
        elipsis: true,
        scopedSlots: { customRender: "commentRenderer" },
        width: 150
      },
      {
        key: "actions",
        title: i18n.t("actions"),
        scopedSlots: { customRender: "actionsRenderer" },
        fixed: "right",
        width: 105
      }
    ]

    if (activeTable.value === "cpa") {
      cols = cols.filter((item) => item.dataIndex !== "referral")
    }

    return cols
  })

  return { columns }
}
