<template>
  <div class="wrapper">
    <a-table
      :rowKey="(record, index) => `${record.code}_${index}`"
      :columns="columns"
      :data-source="attributes"
      :loading="fetching"
      :locale="tableLocale"
      :pagination="false"
    >
      <template #unitNameTitleRenderer>
        <TitleTooltip
          :short-text="$t('unitShorted')"
          :full-text="$t('unit')"
        />
      </template>

      <template #typeTitleRenderer>
        <TitleTooltip
          short-text="T"
          :full-text="$t('type')"
        />
      </template>

      <template #mainTitleRenderer>
        <TitleTooltip
          short-text="M"
          full-text="Main"
        />
      </template>

      <template #requiredTitleRenderer>
        <TitleTooltip
          short-text="R"
          full-text="Required"
        />
      </template>

      <template #statusTitleRenderer>
        <TitleTooltip
          short-text="S"
          full-text="Status"
        />
      </template>

      <template #indexRenderer="_, __, index">
        {{ index + 1 }}
      </template>

      <template #nameRenderer="text">
        {{ text }}
      </template>

      <template #publicNameRenderer="text">
        {{ text }}
      </template>

      <template #publicNameLocalizedRenderer="text">
        {{ text }}
      </template>

      <template #valueRenderer="value, record">
        <template v-if="record.type === 'MULTILIST'">
          {{ value?.map(({ value }) => value)?.join(", ") || "" }}
        </template>

        <template v-else-if="record.type === 'BOOLEAN'">
          {{ value ? $t("yes") : $t("no") }}
        </template>

        <template v-else>
          {{ value?.value?.toString() }}
        </template>
      </template>

      <template #valueLocalizedRenderer="value, record">
        <EditAttributeValue
          v-if="record.value_type === 'TEMP' || record.value_type === 'STRING'"
          :attribute="record"
          @change="handleAttributeChanged"
        />

        <template v-else-if="record.type === 'MULTILIST'">
          {{ value?.map(({ value }) => value)?.join(", ") || "" }}
        </template>

        <template v-else-if="record.type === 'LIST' || record.type === 'NUMERIC'">
          {{ value?.value || "" }}
        </template>

        <template v-else-if="record.type === 'BOOLEAN'">
          {{ value ? $t("yes") : $t("no") }}
        </template>
      </template>

      <template #typeRenderer="value">
        <a-tooltip
          placement="top"
          :title="value"
        >
          <div class="text-status">
            {{ getFirstCharset(value) }}
          </div>
        </a-tooltip>
      </template>

      <template #isMainRenderer="value">
        <a-tooltip
          v-if="value === true || value === false"
          placement="top"
        >
          <template #title>
            <span>{{ value === true ? $t("yes") : $t("no") }}</span>
          </template>

          <div
            class="status"
            :class="{
              'status--success': value === true,
              'status--error': value === false
            }"
          />
        </a-tooltip>
      </template>

      <template #isRequiredRenderer="value">
        <a-tooltip
          v-if="value === true || value === false"
          placement="top"
        >
          <template #title>
            <span>{{ value === true ? $t("yes") : value === false ? $t("no") : "" }}</span>
          </template>
          <div
            class="status"
            :class="{
              'status--success': value === true,
              'status--error': value === false
            }"
          />
        </a-tooltip>
      </template>

      <template #statusRenderer="value">
        <a-tooltip
          placement="top"
          :title="value"
        >
          <div
            class="text-status"
            :class="{
              'text-status--success': value === 'ACTIVE',
              'text-status--disabled': value === 'LOCKED'
            }"
          >
            {{ getFirstCharset(value) }}
          </div>
        </a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { computed } from "vue"
import i18n from "@/i18n"

import TitleTooltip from "@/ant-components/TitleTooltip"
import EditAttributeValue from "../../../../EditProductModal/_components/EditProductAttributesTab/_components/EditAttributeValue.vue"

const props = defineProps({
  mpcCode: {
    type: String,
    required: true
  },
  attributes: {
    type: Array,
    default: () => []
  },
  fetching: {
    type: Boolean,
    default: false
  },
  locale: {
    type: String,
    required: true
  }
})

const emit = defineEmits(["updateAttribute"])

const tableLocale = computed(() => ({
  filterConfirm: i18n.t("search"),
  filterReset: i18n.t("reset"),
  emptyText: i18n.t("noData")
}))

const handleAttributeChanged = async (attribute) => {
  emit("updateAttribute", attribute)
}

const getFirstCharset = (text) => {
  return text?.charAt(0)
}

const columns = computed(() => [
  {
    title: "",
    width: 50,
    dataIndex: "index",
    scopedSlots: {
      customRender: "indexRenderer"
    }
  },
  {
    title: "AND-code",
    width: 120,
    dataIndex: "code"
  },
  {
    title: i18n.t("name"),
    dataIndex: "name",
    width: 170,
    scopedSlots: {
      customRender: "nameRenderer"
    }
  },
  {
    title: `${i18n.t("nameForPublish")} (${props.locale})`,
    dataIndex: "public_name",
    width: 170,
    scopedSlots: {
      customRender: "publicNameLocalizedRenderer"
    }
  },
  {
    dataIndex: "unit",
    width: 110,
    slots: {
      title: "unitNameTitleRenderer"
    },
    customRender: (unit, record) => (record.type === "NUMERIC" ? unit?.name : "")
  },
  {
    title: i18n.t("value"),
    dataIndex: "stable_value",
    width: 220,
    scopedSlots: {
      customRender: "valueRenderer"
    }
  },
  {
    title: `${i18n.t("value")} (${props.locale})`,
    dataIndex: "value",
    width: 400,
    scopedSlots: {
      customRender: "valueLocalizedRenderer"
    }
  },
  {
    dataIndex: "type",
    slots: {
      title: "typeTitleRenderer"
    },
    scopedSlots: {
      customRender: "typeRenderer"
    },
    width: 45
  },
  {
    slots: {
      title: "mainTitleRenderer"
    },
    scopedSlots: {
      customRender: "isMainRenderer"
    },
    dataIndex: "is_main",
    width: 45
  },
  {
    dataIndex: "is_required",
    slots: { title: "requiredTitleRenderer" },
    scopedSlots: {
      customRender: "isRequiredRenderer"
    },
    width: 45
  },
  {
    slots: { title: "statusTitleRenderer" },
    scopedSlots: {
      customRender: "statusRenderer"
    },
    dataIndex: "status",
    width: 45
  }
])
</script>

<style lang="scss" scoped>
.text-status {
  font-weight: 500;

  &--success {
    color: #52c41a;
  }

  &--disabled {
    color: #c6c6c6;
  }

  &--error {
    color: #ff4d4f;
  }
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &--success {
    background-color: #b7eb8f;
  }

  &--error {
    background-color: #ff4d4f;
  }
}
</style>
