<template>
  <div
    @dblclick="checkCoef"
    class="wrapper"
  >
    <div :title="(row && row.base && row.base[0] && row.base[0].code) || ''">
      {{ (row && row.base && row.base[0] && row.base[0].name) || "" }}
    </div>
    <a-tooltip
      v-if="row && row.base && row.base[0] && row.base[0].name"
      title="Очистить базовую и коэффициент"
      style="cursor: pointer"
      @click="onClear"
    >
      <a-icon type="delete" />
    </a-tooltip>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"

export default {
  props: ["row", "rowIndex"],
  methods: {
    onClear() {
      this.PU_clearBaseAndCoef({ row: this.row, rowIndex: this.rowIndex })
    },
    checkCoef(event) {
      if (this.row && !this.row.coef) {
        event.stopPropagation()
      }
    },
    ...mapActions("ProductCatalog", {
      PU_clearBaseAndCoef: "PU_clearBaseAndCoef"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {})
  }
}
</script>
<style scoped>
.wrapper {
  height: 100%;
  /*margin: 0 -7px;*/
  padding: 7px 7px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
