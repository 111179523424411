import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const actions = {
  PT_editAttributeCheckboxes({ commit, dispatch, getters }, { field, val, code, rowIndex }) {
    // update value in the table
    commit("PT_updateAttributeCheckboxes", { field, rowIndex, val })
    let sendData = {
      code,
      link: {
        [field]: val
      }
    }
    API_MODERATION.put(`${apiUrls.link}`, sendData)
      .then((r) => r.data.message)
      .then((r) => {
        notification.info({ message: "Изменения добавлены" })
      })
      .catch((e) => {
        // bring the old value back
        commit("PT_updateAttributeCheckboxes", { field, rowIndex, val: !val })
        commit("handleError", e)
      })
  }
}

export default {
  actions
}
