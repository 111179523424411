<template>
  <div
    v-if="row.timestamp"
    v-html="timeValue"
  />
</template>

<script>
export default {
  props: {
    row: Object
  },
  computed: {
    timeValue() {
      if (!this.row.timestamp) return
      let time = this.row.timestamp
      let dateValue = time.substring(0, 10)
      let timeValue = time.substring(11)
      time = `<div>${dateValue}</div>
        <div>${timeValue}</div>`
      return time
    }
  }
}
</script>
