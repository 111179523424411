<template>
  <div>
    <a-spin
      :spinning="fetching"
      class="return-drawer"
    >
      <ReturnItems :items="returnItems" />

      <div class="return-info">
        <ReturnHistory
          class="return-info__item"
          :dataHistory="dataHistory"
          :selectedReturn="fetchedSelectedReturn"
        />

        <ReturnDetails
          class="return-info__item"
          :fetchedSelectedReturn="fetchedSelectedReturn"
        />

        <a-form
          class="return-info__item--thin"
          style="width: 20%; margin-top: 64px"
        >
          <a-form-item
            class="return-info__item--thin__item"
            :label="$t('returnStatus')"
          >
            <a-select
              :options="returnStatuses"
              v-model="actionData.status"
              style="width: 100%"
            />
          </a-form-item>

          <a-form-item
            class="return-info__item--thin__item"
            :label="$t('returnPoint')"
          >
            <a-select
              style="width: 100%"
              :options="returnAims"
              v-model="actionData.aim"
            />
          </a-form-item>

          <a-form-item
            class="return-info__item--thin__item"
            :label="$t('reason')"
          >
            <a-select
              style="width: 100%"
              :options="returnReasonsToSelect"
              v-model="actionData.reason"
            />
          </a-form-item>
        </a-form>
      </div>

      <template v-if="fetchedSelectedReturn?.images?.length">
        <div class="return-info__item">
          <span class="return-label">{{ $t("attachedPhotos") }}</span>

          <div class="images-wrap">
            <img
              v-for="(img, index) in fetchedSelectedReturn.images"
              :key="`${index}-img`"
              :src="getImageUrl(img)"
              @click="openGallery"
            />
          </div>
        </div>
      </template>
    </a-spin>

    <div class="drawer-footer">
      <a-button @click="$emit('close')">
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isSaving"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import {
  fetchReturnItem,
  fetchAllReasons,
  updateReturn
} from "@/modules/MPAdmin/services/returnsService.js"

import { IMAGES_CDN } from "@/constants/common.js"
import useReturnsPageColumns from "@/modules/MPAdmin/pages/Returns/useReturnsPageColumns.js"

import ReturnHistory from "./components/ReturnHistory.vue"
import ReturnDetails from "./components/ReturnDetails.vue"
import ReturnItems from "./components/ReturnItems.vue"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"
import getImagePath from "@/utils/getImagePath.js"
import getCurrentLocale from "@/utils/getCurrentLocale.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  name: "EditReturnModal",

  components: {
    ReturnHistory,
    ReturnItems,
    ReturnDetails
  },

  setup() {
    const { returnedOffersColumns, returnHistoryColumns } = useReturnsPageColumns()

    return {
      returnedOffersColumns,
      returnHistoryColumns
    }
  },

  inject: ["galleryRef"],

  data() {
    return {
      actionData: {
        status: "",
        reason: "",
        aim: "",
        created_by: ""
      },
      returnReasons: [],

      fetchedSelectedReturn: {},
      returnItems: [],
      dataHistory: [],

      fetching: false,
      isSaving: false
    }
  },

  async mounted() {
    this.getReturn()
    this.getReasons()
  },

  computed: {
    ...mapState("Auth", {
      user: (state) => state.profile.username
    }),

    createdReturnDate() {
      return getFormatedDateTime(this.selectedReturn.created_at)
    },

    returnReasonsToSelect() {
      return this.returnReasons.map((item) => ({
        label: item.name ? item.name[getCurrentLocale()] : item.help,
        value: item.uuid
      }))
    },

    aims() {
      return {
        RETURN_PRODUCT_REFUNDS: this.$t("returnProductsRefunds"),
        EXCHANGE_PRODUCT: this.$t("exchangeProduct"),
        REPAIR_PRODUCT: this.$t("repair")
      }
    }
  },

  methods: {
    async onSave() {
      this.isSaving = true
      this.actionData.created_by = this.user

      if (this.actionData.reason === "") {
        delete this.actionData.reason
      }

      try {
        await updateReturn(this.selectedReturn.uuid, this.actionData)

        this.$notification.success({ message: this.$t("success") })
        this.$emit("updateReturn")
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.isSaving = false
      }
    },

    getImageUrl(path) {
      return `${IMAGES_CDN}/media/assets/images/${getImagePath(path, "full")}`
    },

    openGallery() {
      this.galleryRef.handleEvent({ images: this.fetchedSelectedReturn.images })
    },

    async getReturn() {
      this.fetching = true

      try {
        const { data } = await fetchReturnItem(this.selectedReturn.uuid)

        this.returnItems = data.items.map(({ item_in_order, ...item }) => {
          return {
            ...item,
            images_of_product: item_in_order.offer.product.images,
            images_of_offer: item_in_order.images,
            item_in_order
          }
        })

        this.actionData = {
          ...this.actionData,
          status: data.status,
          aim: data.aim,
          reason: data.reason?.uuid || ""
        }

        this.fetchedSelectedReturn = data

        this.dataHistory = data.history
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.fetching = false
      }
    },

    async getReasons() {
      const { data } = await fetchAllReasons()
      this.returnReasons = data.results
    }
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    selectedReturn: {
      type: Object,
      default: () => ({})
    },
    returnStatuses: {
      type: Array,
      required: true
    },
    returnAims: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.return-drawer {
  padding-bottom: 50px;
}

.return-label {
  font-weight: bold;
  font-size: 16px;
  color: $font-default;

  margin-top: 16px;
}

.return-info {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  &__item {
    width: 40%;

    margin-top: 16px;

    &--thin {
      width: 24.5%;

      & * {
        margin: 0;
      }
    }
  }
}

.images-wrap {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  margin-top: 16px;

  & img {
    width: 150px;
    aspect-ratio: 1;
    object-fit: contain;

    padding: 4px;

    border: 1px solid $border-color;
    border-radius: 2px;

    cursor: pointer;
  }
}
</style>
