<template>
  <div class="session-graph__item">
    <div
      v-for="(event, index) in events_per_5_seconds"
      :key="`event-${index}`"
    >
      <SessionItemPopover :event="event" />
    </div>

    <div class="timeline">
      <a-popover
        v-for="(line, index) of eventsPerMinuteAdditional"
        :class="{ timeline__item: line?.new_page }"
        :style="{
          background: line?.color || '#000',
          height: line?.length ? `${(line.length / 12) * 100}%` : ''
        }"
        placement="left"
        :key="`${line.url}_${index}`"
      >
        <template #content>
          <a-descriptions
            style="max-width: 400px"
            :column="1"
            bordered
            size="small"
          >
            <a-descriptions-item :label="$t('device')">
              {{ line?.device_type }}
            </a-descriptions-item>

            <a-descriptions-item :label="$t('trafficSource')">
              {{ line?.traffic_source }}
            </a-descriptions-item>

            <a-descriptions-item :label="$t('url')">
              {{ line?.url.split("?")[0] }}
            </a-descriptions-item>
          </a-descriptions>
        </template>
      </a-popover>
    </div>
  </div>
</template>

<script>
import SessionItemPopover from "./SessionItemPopover.vue"

export default {
  components: {
    SessionItemPopover
  },

  props: {
    events_per_5_seconds: {
      type: Array,
      default: () => []
    },

    eventsPerMinuteAdditional: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline {
  position: absolute;
  top: 0;
  left: 0px;
  width: 8px;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  &__item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: inherit;
    left: -4px;
    margin-top: -5px;
    z-index: 1;
  }
}
</style>
