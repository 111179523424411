import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo } = {}) => {
  const postOfficesColumns = computed(() => [
    {
      key: "number",
      dataIndex: "number",
      title: i18n.t("postOfficeOrBox"),
      scopedSlots: {
        customRender: "defaultCellRenderer"
      },
      fixed: "left",
      width: 115
    },
    {
      key: "address",
      dataIndex: "address",
      title: i18n.t("address"),
      scopedSlots: {
        customRender: "defaultCellRenderer"
      },
      width: 610
    },
    {
      key: "type",
      dataIndex: "type",
      title: i18n.t("type"),
      scopedSlots: {
        customRender: "postTypeRenderer"
      },
      width: 105
    },
    {
      key: "schedule",
      dataIndex: "schedule",
      title: i18n.t("workSchedule"),
      scopedSlots: {
        customRender: "scheduleRenderer"
      },
      width: 170
    },
    {
      key: "max_weight",
      dataIndex: "meta.max_weight",
      title: i18n.t("weightTo"),
      scopedSlots: {
        customRender: "weightRenderer"
      },
      align: "center",
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "max_weight" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "max_cost",
      dataIndex: "meta.max_declared_cost",
      title: `${i18n.t("maxCost")}, ${i18n.t("uah")}`,
      scopedSlots: {
        customRender: "maxCostRenderer"
      },
      align: "center",
      width: 125
    },
    {
      key: "send_limits_on_dimensions",
      dataIndex: "meta.send_limits_on_dimensions",
      title: i18n.t("sizeLimitsBySend"),
      scopedSlots: {
        customRender: "sizeLimitRenderer"
      },
      width: 175
    },
    {
      key: "receive_limits_on_dimensions",
      dataIndex: "meta.receive_limits_on_dimensions",
      title: i18n.t("sizeLimitsByReceive"),
      scopedSlots: {
        customRender: "sizeLimitRenderer"
      },
      width: 175
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      fixed: "right",
      width: 140
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      fixed: "right",
      width: 65
    }
  ])

  return {
    postOfficesColumns
  }
}
