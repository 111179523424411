import { transformQuery } from "./servicesUtils"
import { API } from "@/utils/HttpUtils"

export const fetchReturns = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/returns/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchReturnItem = (id) => {
  return API.get(`/mp-admin/returns/${id}/`)
}

export const fetchAllReasons = () => {
  return API.get("/mp-admin/return-reasons/")
}

export const updateReturn = (id, data) => {
  return API.patch(`/mp-admin/returns/${id}/`, data)
}
