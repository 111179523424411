import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PV_showProductsModal: false,
  PV_productsTable: { count: 0, items: [] },
  PV_productsTableQueryParams: { offset: 0, limit: 100 }
})

const getters = {
  PV_showProductsModal: (state) => state.PV_showProductsModal,
  PV_productsTable: (state) => state.PV_productsTable,
  PV_productsTableQueryParams: (state) => state.PV_productsTableQueryParams
}

const mutations = {
  PV_showProductsModal(state, value) {
    state.PV_showProductsModal = value
  },
  PV_productsTable(state, value) {
    state.PV_productsTable = value
  },
  PV_productsTableQueryParams(state, value) {
    state.PV_productsTableQueryParams = value
  }
}

const actions = {
  async PV_buildProductsTableData({ commit, getters }, products) {
    if (!products.length) return
    let newProducts = []
    for (let i = 0; i < products.length; i++) {
      let newProduct = products[i]
      newProduct.full_name = `${(products[i].type && products[i].type.full_name) || ""} ${
        (products[i].brand && products[i].brand.name) || ""
      } ${products[i].name || ""}`
      if (products[i].model) newProduct.full_name += ` (${products[i].model || ""})`

      let attributeCode = getters.PV_selectedAttributeForNewAttribute.code

      let attributeObject = products[i].attributes.find((item) => {
        return item.code === attributeCode
      })

      newProduct.attribute_value = attributeObject.value

      newProducts.push(newProduct)
    }
    if (newProducts.length) {
      commit("PV_productsTable", { count: newProducts.length, items: newProducts })
    }

    return Promise.resolve
  },
  async PV_ProductsTableQueryAction({ commit, getters, dispatch }, queryParams) {
    let products = await dispatch("PV_checkIfAttributeUsedInProducts", queryParams)
    if (!products) return
    let response
    if (products.length) {
      response = await dispatch("PV_buildProductsTableData", products)
      if (!response) return
      commit("PV_showProductsModal", true)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
