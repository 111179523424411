export default {
  directoryOfGoods: "Довідник товарів",
  createProduct: "Створити товар",
  verification: "Верифікація",
  searchByTitleForPublication: "Пошук за назвою для публікації",
  productType: "Тип товару",
  fullProductType: "Тип товару (повний)",
  titleForPublication: "Назва для публ.",
  editingProductLocalization: "Редагування локалізації товару",
  editingProductLocalizationDescription:
    "Додавання локалізації можливе тільки після проходження товаром верифікації (статус 'VERIFIED' і 'TRANSFERRED')",
  makeDuplicate: "Зробити дублікат",
  productEditing: "Редагування товару",
  productCreating: "Створення нового товару",
  productCreatingWarning: "Ви хочете скасувати створення товару?",
  productEditingWarning: "Ви хочете скасувати редагування товару?",
  exportSuccessWithErrors: "Експорт успішний але з помилками",
  exportSuccess: "Експорт успішний",
  exportFailed: "Експорт не успішний",
  exportError: "Помилка експорту",
  errorInTask: "Помилка в таску",
  loadAttributesByTypeError: "Помилка завантаження атрибутів за типом товару",
  generalProductFormFilledWithErrors: 'Форма у вкладці "Загальне" заповнена з помилками',
  createProductSuccess: "Товар успішно створено",
  creationProductFail: "Товар не створено",
  productAlreadyExistWithThisName: "Товар із таким найменуванням чи моделлю вже існує",
  productLoadError: "Помилка завантаження товару",
  productsLoadError: "Помилка завантаження товарів",
  loadAttributesError: "Помилка завантаження атрибутів",
  loadMainAttributesError: "Помилка завантаження основних атрибутів",
  mainAttributesIsEmpty: "Основні атрибути відсутні у цьому типі",
  typeAttributesIsEmpty: "Атрибути відсутні у цьому типі",
  duplicateAttributeError: "Такий атрибут вже доданий",
  fullProductTitle: "Повне найменування товару",
  fullType: "Тип (повний)",
  shortType: "Тип (короткий)",
  enterProductType: "Введіть тип товару",
  seriesName: "Найменування (серія)",
  enterProductBrand: "Введіть brand товару",
  publicationTitle: "Назва для публікації",
  currentCategory: "Поточна категорія",
  newCategory: "Нова категорія",
  categoryId: "ID категорії",
  categoryCanNotContainProducts: "Ця категорія не може містити товарів",
  categoryNotFound: "Категорія не знайдена",
  shortProductDescription: "Короткий опис товару",
  productDescription: "Опис товару",
  fillTitleOrModel: "Заповніть поле 'Найменування' або 'Модель'",
  fillModelOrTitle: "Заповніть поле 'Модель' або 'Найменування'",
  gtinLengthError: "Повинно бути 8, 12, 13 або 14 числових значень",
  saveOrdering: "Зберегти сортування",
  orderingFull: "Сортування",
  imagesChangesSaved: "Зміни зображень збережені",
  imagesChangesFailed: "Помилка оновлення зображень",
  imageEditingFailed: "Помилка редагування зображення",
  imagesAdded: "Зображення додані",
  imageEditing: "Редагування зображення",
  preview: "Попередній перегляд",
  imageUpdated: "Зображення оновлено",
  addAttribute: "Додати атрибут",
  errorInAttributes: "Помилки в артибутах",
  copyFromProduct: "Скопіювати з товару",
  orderSameAsType: "Сортувати як в типі",
  addMains: "Додати основні",
  addAllAttributesByType: "Додати усі атрибути за типом",
  deleteBlank: "Видалити пусті",
  unit: "Одиниця вимірювання",
  units: "Одиниці вимірювання",
  characteristics: "Характеристики",
  listValues: "Спискові значення",
  valueProcessing: "Обробка значень",
  unitShorted: "О.в.",
  nameForPublish: "Назва для публікації",
  deleteAttributeConfirm: "Ви хочете видалити атрибут",
  contextSearchByAttribute: "Контекстний пошук по назві атрибуту",
  attributeSearch: "Пошук атрибуту",
  attributeValue: "Значення атрибуту",
  productLocalizationEditing: "Редагування локалізації товару",
  translationTitleForPublish: "Переклад назви для публікації",
  nameTranslation: "Переклад найменування",
  setValueInMainLocale: "Задайте значення для цього поля в основній локалізації (ru)",
  descriptionTranslate: "Переклад опису",
  publicTitleUpdated: "Значення public_title для {code} успішно оновлено",
  valueUpdated: "Значення 'value' для {code} успішно оновлено",
  publicTitleNotUpdated: "Значення public_title не оновлено",
  valueNotUpdated: "Значення 'value' не оновлено",
  localizationEditingWarning: "Ви хочете скасувати редагування локалізації товару?",
  shortDescriptionTranslation: "Переклад короткого опису товару",
  productDublicateError: "Помилка дублювання товару",
  naming: "Найменування",
  fillAttributesValueError: "Будь ласка, заповніть всі значення атрибутів",
  closeAttributesValueError: "Будь ласка, завершіть редагування атрибутів",
  public_title: {
    ru: "Назва (RU)",
    uk: "Назва (UA)"
  },
  moderationTrustStatus: {
    TRUSTED: "Перевірено",
    UNTRUSTED: "Очікує"
  },
  productQualityMark: {
    PERFECT: "Найкраща якість",
    GOOD: "Висока якість",
    AVERAGE: "Середня якість",
    FAIR: "Нормальна якість",
    POOR: "Низька якість",
    VERY_POOR: "Дуже погана якість"
  },
  productModerationStatuses: {
    TEMP: "Тимчасовий",
    NEW: "Новий",
    EDITED: "Редагований",
    VERIFIED: "Верифікований",
    NOT_VERIFIED: "Не верифікований",
    TRANSFERRED: "Переміщений",
    MODERATION_REQUIRED: "Потребує модерації"
  },
  productOriginOptions: {
    SELLER_MANUAL: "Ручне створення",
    SYNTHETIC_DEFAULT: "Інші"
  },
  unitIsEmptyPlaceholder: "Відсутня",
  postModerationStatus: "Статус пост-модерації",
  productOrigin: "Походження",

  // Export
  exportPageTitle: "Експорт товарів",
  exportHistoryTitle: "Історія екпортів",
  exportProductsToDBTitle: "Експорт товарів в центральну БД",
  exportSellerSlug: "Slug продавця",
  exportExecutor: "Користувач",
  exportTotalProducts: "Всього товарів",
  exportSuccessProducts: "Вивантажено успішно",
  exportErrorProducts: "Помилки при вивантажені",
  exportDateEnd: "Дата закінчення",
  exportStatusStarted: "Виконується",
  exportStatusSuccess: "Завершено",
  exportStatusRevorked: "Відмінено",
  exportStatusFailute: "Помилка",
  exportStatusReceived: "В черзі",
  exportStatusPending: "В черзі (ближче)",
  exportStatusFilter: "Статус експорту",
  exportProductStatus: "Статус товара",
  exportStartConfirm: "Запустити експорт вибраних товарів?",
  exportStartSuccess: "Експорт успішно запущено",
  exportStartError: "Експорт не запустився",
  exportFailedStoped: "Експорт не зупинено",
  exportSuccessStoped: "Експорт зупинено",
  exportErrors: "Помилки експорту"
}
