import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_showDeleteAttributeModal: false
})

const getters = {
  PV_showDeleteAttributeModal: (state) => state.PV_showDeleteAttributeModal
}

const mutations = {
  PV_showDeleteAttributeModal(state, value) {
    state.PV_showDeleteAttributeModal = value
  }
}

const actions = {
  PV_deleteAttributeFromList({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let attribute = {
      description: getters.PV_selectedList.description,
      attribute: getters.PV_selectedAttribute.code,
      action: "DELETE"
    }

    // start request
    API_MODERATION.patch(`${apiUrls.lists}`, attribute)
      .then((r) => r.message)
      .then((r) => {
        // restart table
        dispatch("PV_resetValuesTalbe")
        dispatch("PV_resetAttributesTable")
        dispatch("PV_searchValuesByDescription")
        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Атрибут отвязан от списка" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
