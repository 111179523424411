import { API_ADS, simpleBuildQuery } from "@/utils/HttpUtils.js"
import { transformQuery } from "./servicesUtils.js"

export const fetchSellers = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `/v1/admin/sellers/stats/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const editSeller = (sellerId, data) => {
  return API_ADS.patch(`/v1/admin/sellers/${sellerId}`, data)
}

export const removeSellerLabel = (sellerId) => {
  return API_ADS.patch(`/v1/admin/sellers/${sellerId}`, {
    default_label: null,
    label_exists: false
  })
}

export const fetchSellerOrders = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `v1/admin/sellers/${requestParams.seller_id}/orders/${transformQuery(queryParams)}`,
    { signal }
  )
}

export const subtractSeller = (sellerId) => {
  return API_ADS.post(`/v1/admin/sellers/${sellerId}/subtract-cpa/`)
}

export const getStatistics = (sellerId) => {
  return API_ADS.get(`/v1/admin/sellers/${sellerId}/weekly-stats/`)
}

export const getSummaryCpa = (sellerId) => {
  return API_ADS.get(`/v1/admin/sellers/${sellerId}/cpa-summary/`)
}

export const getBillingTransactions = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `/v1/admin/sellers/${requestParams.seller_id}/billing/transactions/${transformQuery(
      queryParams
    )}`,
    { signal }
  )
}

export const replenishBilling = (sellerId, data) => {
  return API_ADS.post(`/v1/admin/sellers/${sellerId}/billing/replenishment`, data)
}

export const getCpaReport = (sellerSlug, data) => {
  const queryString = simpleBuildQuery(data)

  return API_ADS.get(`/v1/admin/sellers/${sellerSlug}/cpa-report${queryString}`, {
    responseType: "blob"
  })
}
