import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo = {} } = {}) => {
  const columns = computed(() => [
    {
      key: "slug",
      dataIndex: "slug",
      title: "Slug",
      fixed: "left"
    },
    {
      key: "name",
      title: i18n.t("seller"),
      scopedSlots: {
        customRender: "name"
      },
      fixed: "left"
    },
    {
      key: "health",
      dataIndex: "health",
      title: `${i18n.t("health")} ${i18n.t("ofSeller").toLowerCase()}`,
      scopedSlots: {
        customRender: "health"
      },
      width: 150,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "health" && sortedInfo?.value?.order
    },
    {
      key: "reviews_count",
      dataIndex: "reviews_count",
      title: i18n.t("reviews"),
      width: 110,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "reviews_count" && sortedInfo?.value?.order
    },
    {
      key: "reviews_count_positive_percent",
      dataIndex: "reviews_count_positive_percent",
      title: i18n.t("positiveReviews"),
      width: 140,
      scopedSlots: {
        customRender: "percent"
      },
      sorter: true,
      sortOrder:
        sortedInfo?.value?.columnKey === "reviews_count_positive_percent" &&
        sortedInfo?.value?.order
    },
    {
      key: "orders_count",
      dataIndex: "orders_count",
      title: i18n.t("orders"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "orders_count" && sortedInfo?.value?.order,
      scopedSlots: {
        customRender: "ordersCount"
      }
    },
    {
      key: "orders_count_canceled_total_percent",
      dataIndex: "orders_count_canceled_total_percent",
      title: i18n.t("cancelledOrders"),
      width: 130,
      scopedSlots: {
        customRender: "percent"
      },
      sorter: true,
      sortOrder:
        sortedInfo?.value?.columnKey === "orders_count_canceled_total_percent" &&
        sortedInfo?.value?.order
    },
    {
      key: "orders_count_closed_total_percent",
      dataIndex: "orders_count_closed_total_percent",
      title: i18n.t("closedOrders"),
      width: 130,
      scopedSlots: {
        customRender: "percent"
      },
      sorter: true,
      sortOrder:
        sortedInfo?.value?.columnKey === "orders_count_closed_total_percent" &&
        sortedInfo?.value?.order
    },
    {
      key: "products_count",
      dataIndex: "products_count",
      title: i18n.t("productCount"),
      width: 120,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "products_count" && sortedInfo?.value?.order
    },
    {
      key: "offers_count",
      dataIndex: "offers_count",
      title: i18n.t("offerCount"),
      scopedSlots: {
        customRender: "offers"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "offers_count" && sortedInfo?.value?.order
    },
    {
      key: "users_count",
      dataIndex: "users_count",
      title: i18n.t("users"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "users_count" && sortedInfo?.value?.order
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      width: 120,
      scopedSlots: {
        customRender: "status"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "is_active" && sortedInfo?.value?.order,
      fixed: "right"
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right"
    }
  ])

  return {
    columns
  }
}
