import { render, staticRenderFns } from "./EditProductImagesTab.vue?vue&type=template&id=879c7db4&scoped=true"
import script from "./EditProductImagesTab.vue?vue&type=script&setup=true&lang=js"
export * from "./EditProductImagesTab.vue?vue&type=script&setup=true&lang=js"
import style0 from "./EditProductImagesTab.vue?vue&type=style&index=0&id=879c7db4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "879c7db4",
  null
  
)

export default component.exports