import { render, staticRenderFns } from "./ProductsList.vue?vue&type=template&id=26a40675&scoped=true"
import script from "./ProductsList.vue?vue&type=script&setup=true&lang=js"
export * from "./ProductsList.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ProductsList.vue?vue&type=style&index=0&id=26a40675&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a40675",
  null
  
)

export default component.exports