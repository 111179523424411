<template>
  <a-form-model
    ref="formRef"
    :model="form"
    style="padding-bottom: 70px; margin-top: 30px"
  >
    <a-descriptions
      bordered
      :column="1"
      size="small"
    >
      <a-descriptions-item :label="$t('seller')">
        <a
          :href="sellerUrl"
          target="_blank"
        >
          {{ order?.seller?.name }}
        </a>
      </a-descriptions-item>
      <a-descriptions-item :label="$t('authorFullname')">
        <b>{{ author?.username }}</b>
        {{ author?.first_name }}
        {{ author?.middle_name }}
        {{ author?.last_name }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="author?.mobile_phone"
        :label="$t('phoneNumber')"
      >
        <div
          class="copy-text"
          @click="handleCopyText(author.mobile_phone)"
        >
          +{{ author.mobile_phone }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('ratingOverall')">
        {{ ratingOptions[rating_product_overall]?.label }}
        ({{ rating_product_description_fits }}/{{ rating_delivery }}/{{ rating_service }})
      </a-descriptions-item>

      <a-descriptions-item :label="$t('orderNumber')">
        {{ order?.code }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('comment')">
        {{ comment }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="images?.length"
        :label="$t('photo')"
      >
        <TableRendererPhotoGallery :images="images" />
      </a-descriptions-item>
    </a-descriptions>

    <a-form-model-item
      :label="$t('moderationStatus')"
      prop="moderation_status"
    >
      <a-select
        style="width: 100%"
        v-model="form.moderation_status"
        :options="moderationStatusOptions"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('ordering')"
      prop="ordering"
    >
      <a-input-number
        v-model="form.ordering"
        :precision="0"
        :min="0"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="fetching"
        @click="handleUpdateReview"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
import { reactive, ref } from "vue"
import { message, notification } from "ant-design-vue"
import i18n from "@/i18n"

import { MARKETPLACE } from "@/constants/common"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { updateSellerReview } from "@/modules/MPAdmin/services/sellerReviewsService"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "EditSellerReview",

  components: {
    TableRendererPhotoGallery
  },

  props: {
    prototype: {
      type: Object,
      default: () => ({})
    },
    moderationStatusOptions: {
      type: Array,
      default: () => []
    },
    ratingOptions: {
      type: Array,
      default: () => []
    }
  },

  setup(props, ctx) {
    const { emit } = ctx
    const {
      author,
      rating_product_overall,
      order,
      images,
      rating_product_description_fits,
      rating_delivery,
      rating_service,
      comment,
      ordering,
      moderation_status,
      id: reviewId
    } = props.prototype

    const sellerUrl = `${MARKETPLACE}/seller/${order?.seller?.slug}/`

    const form = reactive({
      moderation_status: Object.keys(moderation_status)?.[0]
        ? Object.keys(moderation_status)[0]
        : "",
      ordering: typeof ordering === "number" ? ordering : ""
    })
    const formRef = ref()

    const fetching = ref(false)

    const handleCopyText = (text) => {
      navigator.clipboard.writeText(text)

      message.info(i18n.t("copied"))
    }

    const handleUpdateReview = async () => {
      const isValid = await formRef.value.validate()
      if (!isValid) return

      fetching.value = true

      const payload = {
        moderation_status: form.moderation_status,
        ordering: typeof form.ordering === "number" ? form.ordering : null
      }

      try {
        const { data } = await updateSellerReview(payload, reviewId)

        emit("updateReview", data)
        notification.success({ message: i18n.t("updated") })
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        fetching.value = false
      }
    }

    return {
      author,
      rating_product_overall,
      order,
      images,
      rating_product_description_fits,
      rating_delivery,
      rating_service,
      comment,

      form,
      formRef,

      fetching,

      sellerUrl,
      handleCopyText,
      handleUpdateReview
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-text {
  cursor: pointer;

  color: $primary-color;
}
</style>
