<template>
  <div
    v-if="events?.length"
    class="session-graph"
    v-dragscroll
  >
    <div
      v-for="({ events_per_5_seconds, additional }, index) in events"
      :key="`minute-${index}`"
    >
      {{ index + 1 }}

      <SessionMinuteList
        :eventsPerMinuteAdditional="additional"
        :events_per_5_seconds="events_per_5_seconds"
      />
    </div>
  </div>

  <div
    v-else
    class="empty-graph"
  >
    <a-empty />
  </div>
</template>

<script>
import SessionMinuteList from "./SessionMinuteList.vue"

export default {
  components: {
    SessionMinuteList
  },

  props: {
    events: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.session-graph {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;

  overflow: auto;
  padding-left: 8px;
  overflow: auto;

  &__item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 2px;

    padding-left: 8px;

    position: relative;
  }
}

.empty-graph {
  width: 100%;
}
</style>
