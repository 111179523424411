import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo } = {}) => {
  const rolesColumns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 130
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "name" && sortedInfo?.value?.order,
      width: 700
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("description"),
      width: 800
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      fixed: "right",
      width: 120
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      fixed: "right",
      width: 70
    }
  ])

  const usersColumns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 140
    },
    {
      key: "username",
      dataIndex: "username",
      title: i18n.t("userName"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "username" && sortedInfo?.value?.order,
      fixed: "left",
      width: 200
    },
    {
      key: "first_name",
      dataIndex: "first_name",
      title: i18n.t("firstName"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "first_name" && sortedInfo?.value?.order,
      width: 350
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      scopedSlots: {
        customRender: "contactsRenderer"
      },
      width: 250
    },
    {
      key: "roles",
      dataIndex: "roles",
      title: i18n.t("roles"),
      customRender: (roles) => roles.map(({ name }) => name).join(", "),
      width: 500
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "is_active" && sortedInfo?.value?.order,
      fixed: "right",
      width: 120
    },
    {
      key: "is_superuser",
      dataIndex: "is_superuser",
      title: i18n.t("isSuper"),
      customRender: (isSuper) => (isSuper ? "Super" : ""),
      fixed: "right",
      width: 110
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      fixed: "right",
      width: 150
    }
  ])

  return {
    rolesColumns,
    usersColumns
  }
}
