<template>
  <div class="add-black-list">
    <a-drawer
      width="730px"
      :title="$t('additionalButtons.addToBlackList')"
      placement="right"
      :closable="true"
      :visible="addBlackListVisible"
      @close="handleClose"
    >
      <a-radio-group
        :options="options"
        @change="onChangePointBlackList"
      />
      <div class="footer">
        <a-divider type="horizontal" />
        <div class="footer-buttons">
          <a-button
            class="form-button-padding"
            @click="handleClose"
          >
            {{ $t("cancel") }}
          </a-button>
          <a-button
            type="primary"
            @click="handleConfirm"
          >
            {{ $t("apply") }}
          </a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: "AddToBlackList",
  props: {
    addBlackListVisible: {
      type: Boolean,
      default: false
    },
    selectedCsCodes: {
      type: Number,
      default: 1
    },
    selectedPCodes: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      codeKind: "cs_code"
    }
  },
  computed: {
    options() {
      return [
        {
          label: `CS_code (${this.selectedCsCodes})`,
          value: "cs_code"
        },
        {
          label: `P_code (${this.selectedPCodes})`,
          value: "p_code"
        }
      ]
    }
  },
  methods: {
    onChangePointBlackList(e) {
      this.codeKind = e.target.value
    },
    handleConfirm() {
      this.$emit("submit", this.codeKind)
    },
    handleClose() {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
.add-black-list {
  position: relative;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px 16px;

  &-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
