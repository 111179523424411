import orders from "./../pages/Orders/store"

const MPAdmin = {
  namespaced: true,
  state: {
    locale: "ru"
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    orders
  }
}

export default MPAdmin
