<template>
  <div>
    <h2>
      Изменение значений по атрибуту
      <span v-if="AE_selectedAttrGet">"{{ AE_selectedAttrGet.name }}"</span>
    </h2>
    <div class="row attributes-edit__filters">
      <div class="col-sm-4">
        <h3>Поиск характеристики</h3>
        <AttributesEditSearch />
      </div>
      <div class="col-sm-4">
        <h3>Конвертация типа атрибута</h3>
        <AttributesEditConversion />
      </div>
      <div class="col-sm-4">
        <h3>Поиск значений и их замена</h3>
        <AttributesEditReplacement />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <AttributesEditTable />
      </div>
    </div>
  </div>
</template>
<script>
import AttributesEditSearch from "./AttributesEditSearch/AttributesEditSearch"
import AttributesEditConversion from "./AttributesEditConversion/AttributesEditConversion"
import AttributesEditReplacement from "./AttributesEditReplacement/AttributesEditReplacement"
import AttributesEditTable from "./AttributesEditTable/AttributesEditTable"
import { mapState, mapMutations, mapActions } from "vuex"

export default {
  metaInfo: {
    title: "MS: Attributes-edit"
  },
  components: {
    AttributesEditSearch,
    AttributesEditConversion,
    AttributesEditReplacement,
    AttributesEditTable
  },
  computed: {
    ...mapState("ProductCatalog/attributesEdit", {
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr
    })
  }
}
</script>
<style lang="scss">
.attributes-edit {
  &__filters {
    margin-bottom: 15px;
  }
}

@media (max-width: 1485px) {
  .attributes-edit {
    &__search,
    &__conversion,
    &__replacement {
      height: 150px;
    }
  }
}

@media (max-width: 1459px) {
  .attributes-edit {
    &__search,
    &__conversion,
    &__replacement {
      height: 165px;
    }
  }
}

@media (max-width: 1250px) {
  .attributes-edit {
    &__search,
    &__conversion,
    &__replacement {
      height: 170px;
    }
  }
}
</style>
