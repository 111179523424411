const state = () => ({
  PC_submitMsg: false,
  PC_someAttributeRequired: false
})

const getters = {
  PC_submitMsg: (state) => state.PC_submitMsg,
  PC_someAttributeRequired: (state) => state.PC_someAttributeRequired
}

const mutations = {
  PC_submitMsg(state, value) {
    state.PC_submitMsg = value
  },
  PC_someAttributeRequired(state, value) {
    state.PC_someAttributeRequired = value
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
