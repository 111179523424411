<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PA_showAddListModalGet"
      :title="'Связать атрибут со списком?'"
    >
      <div>
        <label><span>Выберите список значений</span></label>
        <Autocomplete
          :data="PA_listsToAddAttributeAutocompleteGet"
          event="input"
          @event="fetchList"
          @select="PA_processSelectedListToAddAttribute"
          :size="'small'"
          :defaultValue="list"
        />
      </div>
      <span slot="footer">
        <Button
          color="primary"
          @click="onAdd"
          :disabled="!enableCreateButton"
          >Добавить список</Button
        >
      </span>
    </Modal>
    <ProductsModal />
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"
import Autocomplete from "components/Autocomplete"
import ProductsModal from "../ProductsModal/ProductsModal"

export default {
  name: "AddListModal",
  components: {
    ProductsModal,
    Button,
    Modal,
    Autocomplete
  },
  data() {
    return {}
  },
  methods: {
    fetchList(value) {
      if (value === "") {
        // reset
        this.PA_selectedListToAddAttribute(false)
        return
      }
      this.PA_searchListsToAddAttribute(value)
    },
    onAdd() {
      this.PA_addAttributeToList()
      this.closeModal()
    },
    closeModal() {
      this.PA_showAddListModalMute(false)
    },
    ...mapMutations("ProductCatalog", {
      PA_showAddListModalMute: "PA_showAddListModal",
      PA_selectedListToAddAttribute: "PA_selectedListToAddAttribute"
    }),
    ...mapActions("ProductCatalog", {
      PA_addAttributeToList: "PA_addAttributeToList",
      PA_searchListsToAddAttribute: "PA_searchListsToAddAttribute",
      PA_processSelectedListToAddAttribute: "PA_processSelectedListToAddAttribute"
    })
  },
  computed: {
    list() {
      return this.PA_selectedListToAddAttributeGet === false
        ? ""
        : this.PA_selectedListToAddAttributeGet.description
    },
    enableCreateButton() {
      let show = false

      if (this.PA_selectedListToAddAttributeGet) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PA_showAddListModalGet: "PA_showAddListModal",
      PA_listsToAddAttributeAutocompleteGet: "PA_listsToAddAttributeAutocomplete",
      PA_selectedListToAddAttributeGet: "PA_selectedListToAddAttribute"
    })
  }
}
</script>
<style lang="scss" scoped></style>
