export default {
  tagsList: "List of tags",
  createTag: "Create tag",
  tagRating: "Rating",
  tagRatingTip: "Biggest rating shows first on tag page",
  tagRatingModalTip: ", first CS-code will be last on tag's page",
  tagRatingModalTipSameOrder: "CS-code will be sorted by P-code factor rating",
  tagName: "Tag's name",
  slug: "Slug",
  heading1: "H1",
  thisFieldMustBeFilled: "This field is required",
  titleIsIncorrect: "Title is incorrect",
  slugIsIncorrect: "Tag's slug is incorrect",
  offerQuantity: "Offers quantity",
  tagEditing: "Tag's editing",
  settings: "Settings",
  addNewCodes: "Add new CS-codes",
  codesAddedSuccessfully: "CS-codes added successfully",
  tagActivity: "Tag's activity",
  noteFromManager: "Note from manager",
  productInformation: "Product information",
  detailsFromSeller: "Details from seller",
  newPrice: "New price",
  oldPrice: "Old price",
  sequenceNumber: "Sequence number",
  massiveInput: "Massive input",
  inAscendingOrder: "In ascending order",
  sameForAll: "The same for all",
  startFrom: "Start from",
  changeSequenceNumber: "Change sequence number",
  tag: {
    title: "Tag's name",
    slug: "Slug",
    seo_h1: "H1",
    _note: "Note"
  },
  confirmToDeleteOffers: "Delete selected offers?",
  massiveCsCodesHelp: "List of CS codes separated by comma, space or Enter",
  seoBlankError: "All SEO fields must be filled in to activate the tag",
  codesNotFound: "Error when mass entered, some CS codes not found.",
  maxNoteError: "Note max length: ",
  tagActivityChanged: "Tag activity successfully changed",
  noOffersInTagError: "The tag must contain at least 1 sentence",
  changeTagRating: "Change rating"
}
