<template>
  <a-table
    :columns="columns"
    :data-source="items"
    row-key="id"
    :pagination="false"
    :style="{ maxWidth: '900px' }"
  >
    <template #dateRenderer="text">
      <TableRendererDateTime :date="text" />
    </template>

    <template #senderRanderer="text, record">
      <template v-if="record.sender_data.seller.name">
        <div>{{ record.sender_data.seller.name }}</div>
        <div :style="{ opacity: '0.45' }">+{{ record.sender_data.mobile_phone }}</div>
      </template>
      <template v-else>
        <div>
          {{ record.sender_data.first_name }} {{ record.sender_data.last_name }}
          {{ record.sender_data.middle_name }}
        </div>
        <div :style="{ opacity: '0.45' }">+{{ record.sender_data.mobile_phone }}</div>
      </template>
    </template>

    <template #receiverRanderer="text, record">
      <template v-if="record.receiver_data.seller.name">
        <div>{{ record.receiver_data.seller.name }}</div>
        <div :style="{ opacity: '0.45' }">+{{ record.receiver_data.mobile_phone }}</div>
      </template>
      <template v-else>
        <div>
          {{ record.receiver_data.first_name }} {{ record.receiver_data.last_name }}
          {{ record.receiver_data.middle_name }}
        </div>
        <div :style="{ opacity: '0.45' }">+{{ record.receiver_data.mobile_phone }}</div>
      </template>
    </template>
  </a-table>
</template>

<script>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import getFormatedSum from "@/utils/getFormatedSum"

export default {
  name: "OrderTransactions",
  components: {
    TableRendererDateTime
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    columns() {
      return [
        {
          key: "index",
          dataIndex: "index",
          title: "№",
          width: 30,
          customRender: (text, record, index) => index + 1
        },
        {
          key: "created_at",
          dataIndex: "created_at",
          title: this.$t("editDate"),
          width: 120,
          scopedSlots: { customRender: "dateRenderer" }
        },
        {
          key: "sender_data",
          dataIndex: "sender_data",
          title: this.$t("sender"),
          scopedSlots: { customRender: "senderRanderer" }
        },
        {
          key: "receiver_data",
          dataIndex: "receiver_data",
          title: this.$t("receiver"),
          scopedSlots: { customRender: "receiverRanderer" }
        },
        {
          key: "p_code",
          dataIndex: "p_code",
          title: "P-code"
        },
        {
          key: "amount",
          dataIndex: "amount",
          title: `${this.$t("sum")}, ${this.$t("uah")}`,
          customRender: (data) => getFormatedSum(data, true)
        },
        {
          key: "status",
          dataIndex: "status",
          title: this.$t("status"),
          customRender: (data) => this.getStatusText(data)
        }
      ]
    }
  },
  methods: {
    getStatusText(value) {
      switch (value) {
        case "NEW":
          return this.$t("orderStatuses.new")
        case "CANCELED":
          return this.$t("canceledStatus")
        case "CLOSED":
          return this.$t("orderStatuses.closed")
        default:
          ""
      }
    }
  }
}
</script>
