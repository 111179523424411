<template>
  <div style="height: 800px; position: relative">
    <SyntheticMessenger
      ref="syntheticMessengerRef"
      @onSellerBillingClick="onSellerBillingClick"
      @onSellerSettingsClick="onSellerSettingsClick"
      @error="handleDisconnect"
      @onUnreadChange="onUnreadChange"
      @onOpenGallery="onOpenGallery"
    />

    <SellerBillingModal
      v-if="showBillingModal"
      :showModal="showBillingModal"
      :sellerUuid="sellerBilling.uuid"
      :tariffs="tariffs"
      @close="showBillingModal = false"
    />

    <EditSellerModal
      :isOpened="Boolean(sellerEditingInfo?.slug)"
      :defaultSellerSlug="sellerEditingInfo?.slug"
      :defaultSellerName="sellerEditingInfo?.username"
      @close="onCloseSellerEditModal"
    />
  </div>
</template>

<script>
import SyntheticMessenger from "@synthetic_ua/messenger-front"
import { notification } from "ant-design-vue"
import { inject, onMounted, ref } from "vue"

import { API_V2 } from "@/utils/HttpUtils"
import getImagePath from "@/utils/getImagePath"
import { IMAGES_CDN, MESSENGER_URL_XHR, MESSENGER_DOMAIN, MARKETPLACE } from "@/constants/common"
import SellerBillingModal from "@/modules/MPAdmin/pages/Billing/SellerBillingModal/index.vue"
import EditSellerModal from "@/modules/MPAdmin/pages/Sellers/components/EditSellerModal"
import { fetchAllTariffs, fetchBillingSellerById } from "../MPAdmin/services/billingService"
import { mapMutations } from "vuex"

const base_url = `${MESSENGER_URL_XHR}/api/v2`

export default {
  components: {
    SyntheticMessenger,
    SellerBillingModal,
    EditSellerModal
  },

  setup() {
    const galleryRef = inject("galleryRef")

    const syntheticMessengerRef = ref()

    const messengerToken = ref()
    const showBillingModal = ref(false)
    const sellerBilling = ref()
    const isBillingFetching = ref(false)
    const sellerEditingInfo = ref()
    const tariffs = ref([])

    const getFullImagePath = ({ filename, size = "full" }) =>
      `${IMAGES_CDN}/media/assets/images/${getImagePath(filename, size)}`

    const getMeta = async () => {
      try {
        const tariffsList = await fetchAllTariffs()

        tariffs.value = tariffsList.map((tariff, index) => ({
          label: tariff.title.ru,
          value: tariff.id,
          key: index
        }))
      } catch (error) {
        if (error?.response?.status === 401) return

        setTimeout(getMeta, 2500)
      }
    }

    const connectMessenger = async () => {
      try {
        await syntheticMessengerRef.value?.install({
          sso_access_token: messengerToken.value,
          role: "moderator",
          language: "uk",
          base_url,
          domain: MESSENGER_DOMAIN,
          cdn_path_function: getFullImagePath,
          marketplace_url: MARKETPLACE
        })
      } catch {
        setTimeout(connectMessenger, 2500)
      }
    }

    const handleDisconnect = () => {
      connectMessenger()
    }

    const setupMessenger = async () => {
      try {
        const { data } = await API_V2.get("/proxy/msg/support-auth")
        messengerToken.value = data.token

        connectMessenger()
      } catch {}
    }

    const onSellerBillingClick = async (user) => {
      try {
        isBillingFetching.value = true

        const { data } = await fetchBillingSellerById(user.uuid)
        sellerBilling.value = data

        showBillingModal.value = true
      } catch (err) {
        const errors = err.response.data.detail || []
        const errorMessage = errors[0] || this.$t("openBillingError")

        notification.error({ message: errorMessage })
      } finally {
        isBillingFetching.value = false
      }
    }

    const onSellerSettingsClick = (user) => {
      sellerEditingInfo.value = user
    }

    const onCloseSellerEditModal = () => {
      sellerEditingInfo.value = undefined
    }

    const onOpenGallery = (payload) => {
      galleryRef.value.handleEvent({
        images: payload.media.filter(({ type }) => type === "image").map((media) => media.file)
      })
    }

    onMounted(setupMessenger)

    return {
      syntheticMessengerRef,
      showBillingModal,
      tariffs,
      sellerEditingInfo,
      sellerBilling,
      handleDisconnect,
      onSellerBillingClick,
      onSellerSettingsClick,
      onCloseSellerEditModal,
      onOpenGallery
    }
  },

  methods: {
    ...mapMutations("spvMessenger", ["SET_UNREAD_MESSAGES"]),
    onUnreadChange(unread) {
      this.SET_UNREAD_MESSAGES(unread)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@synthetic_ua/messenger-front/dist/style.css";
</style>
