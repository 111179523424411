<template>
  <div class="layout-content">
    <div class="layout-content__container">
      <div
        v-if="fetching"
        class="layout-content__loader"
      >
        <a-spin size="large" />
      </div>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from "components/Loader"

export default {
  name: "Content",
  components: {
    Loader
  },
  props: {
    fetching: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-content {
  padding: 0;
  margin-top: 0;

  &__container {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px 30px;
  }
}

.layout-content__loader {
  text-align: center;
}
</style>
