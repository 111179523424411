<template>
  <a-form
    :form="labelForm"
    style="margin-bottom: 40px"
  >
    <div class="label-names">
      <a-form-item :label="`${$t('text')}, UK (${name.uk.length}/40)`">
        <a-input
          v-decorator="['uk', { rules: nameRules }]"
          :required="true"
          @change="(e) => (name.uk = e.target.value || '')"
        />
      </a-form-item>

      <a-form-item :label="`${$t('text')}, EN (${name.en.length}/40)`">
        <a-input
          v-decorator="['en', { rules: nameRules }]"
          :required="true"
          @change="(e) => (name.en = e.target.value || '')"
        />
      </a-form-item>

      <a-form-item :label="`${$t('text')}, RU (${name.ru.length}/40)`">
        <a-input
          v-decorator="['ru', { rules: nameRules }]"
          :required="true"
          @change="(e) => (name.ru = e.target.value || '')"
        />
      </a-form-item>
    </div>
    <div class="row margin-top">
      <div class="col-4">{{ $t("colorFill") }}:</div>
      <div class="col-4">{{ $t("fontColor") }}:</div>
      <div class="col-4">{{ $t("example") }}:</div>
      <div class="col-4">
        <Sketch
          :disableAlpha="true"
          v-model="colors.background"
        />
      </div>
      <div class="col-4">
        <Sketch
          :disableAlpha="true"
          v-model="colors.color"
        />
      </div>
      <div class="col-4">
        <div class="example-box">
          <LableExample
            :color="colors.color.hex"
            :backgroundColor="colors.background.hex"
            :value="example"
          />
        </div>
      </div>
    </div>

    <a-form-item :label="$t('code')">
      <a-input
        v-decorator="['code', { rules: codeRules }]"
        :required="true"
        @change="(e) => (code = e.target.value)"
      />
    </a-form-item>

    <a-form-item :label="`${$t('description')}: (${description.length}/500)`">
      <a-textarea
        v-decorator="['description']"
        :autoSize="{ minRows: 4, maxRows: 8 }"
        @change="(e) => (description = e.target.value)"
      />
    </a-form-item>
    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="fetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { Sketch } from "vue-color"
import LableExample from "./LabelExample.vue"

import {
  createOfferLabel,
  updateOfferLabel
} from "@/modules/MPAdmin/services/generalSettingService/offerLabelsSettings.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateLabelModal",

  components: {
    Sketch,
    LableExample
  },

  props: {
    prototype: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      labelForm: this.$form.createForm(this, {
        name: "labelForm",

        mapPropsToFields: () => {
          return {
            ru: this.$form.createFormField({
              value: this.prototype?.name?.ru || ""
            }),
            en: this.$form.createFormField({
              value: this.prototype?.name?.en || ""
            }),
            uk: this.$form.createFormField({
              value: this.prototype?.name?.uk || ""
            }),
            code: this.$form.createFormField({
              value: this.prototype?.code || ""
            }),
            description: this.$form.createFormField({
              value: this.prototype?.description || ""
            })
          }
        }
      }),

      name: {
        uk: "",
        en: "",
        ru: ""
      },
      code: "",
      description: "",
      colors: {
        background: {
          hex: "#000000"
        },
        color: {
          hex: "#FFFFFF"
        }
      },
      description: "",
      fetching: false
    }
  },

  methods: {
    async onSave() {
      this.labelForm.validateFields(async (err, values) => {
        if (err) return

        this.fetching = true

        const { uk, en, ru, code, description } = values

        const actionData = {
          background_color: this.colors.background.hex,
          font_color: this.colors.color.hex,
          code: code,
          description: description,
          name: JSON.stringify({ uk, en, ru })
        }

        try {
          if (this.prototype.id) {
            const { data } = await updateOfferLabel(actionData, this.prototype.id)

            this.$emit("updateLabel", data)
          } else {
            const { data } = await createOfferLabel(actionData)
            this.$emit("createLabel", data)
          }

          this.$notification.success({
            message: this.$t(this.prototype.id ? "updated" : "created")
          })
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    clear() {
      if (this.prototype.id) {
        this.colors.background.hex = this.prototype.background_color || "#000000"

        this.colors.color.hex = this.prototype.font_color || "#FFFFFF"

        this.name = { ...this.prototype.name }

        this.code = this.prototype.code

        this.description = this.prototype.description
      }
    }
  },
  computed: {
    codeRules() {
      return [
        { required: true, message: this.$t("emptyError") }
      ]
    },
    nameRules() {
      return [
        { required: true, message: this.$t("emptyError") },
        { max: 40, message: this.$t("maxLengthError", { length: 40 }) }
      ]
    },
    example() {
      return this.name.uk || this.name.en || this.name.ru || this.$t("example")
    }
  },
  mounted() {
    this.clear()
  }
}
</script>

<style lang="scss" scoped>
.example-box {
  width: 100% !important;
  box-sizing: border-box !important;
  border: 1px solid $light-border !important;
  box-shadow: $light-shadow !important;
  border-radius: 3px;
  height: 100%;

  & .example-box__lable {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.label-names {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  & > * {
    flex-grow: 1;
  }
}
</style>
