<template>
  <div class="seller-info">
    <a-avatar
      shape="square"
      :size="64"
      icon="shop"
      :src="parseFullImagePath"
    />

    <div>
      <a
        :href="sellerUrl"
        target="_blank"
        rel="noopener noreferrer"
        >{{ value }}</a
      >
      <div v-if="row?.owner_mobile_phone">+{{ row.owner_mobile_phone }}</div>
      <div :style="`color: ${healthStatus.color}`">{{ healthStatus.title }}</div>
    </div>
  </div>
</template>

<script>
import { MARKETPLACE, IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import useHealthStatuses from "@/composables/useHealthStatuses"

export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: () => true
    }
  },

  setup() {
    const { healthStatuses } = useHealthStatuses()

    return { healthStatuses }
  },

  computed: {
    sellerUrl() {
      return `${MARKETPLACE}/seller/${this.row.slug}/`
    },
    healthStatus() {
      return this.healthStatuses[this.row.health]
    },
    parseFullImagePath() {
      return this.row.small_logo
        ? `${IMAGES_CDN}/media/assets/images/${getImagePath(this.row.small_logo, "full")}`
        : ""
    }
  }
}
</script>

<style lang="scss" scoped>
.seller-info {
  display: flex;
  align-items: center;
  gap: 10px;

  font-weight: 500;
}
</style>
