<template>
  <div>
    <a-popover
      v-if="status === 'FAILURE' && failed_reason"
      :content="failed_reason"
      placement="topRight"
    >
      <a-tag :class="`sync-status sync-status--${status}`">
        <a-icon :type="buttonIcons[status]" />
        {{ $t(`sync_status_${status}`) }}
      </a-tag>
    </a-popover>

    <a-tag
      v-else
      :class="`sync-status sync-status--${status}`"
    >
      <a-icon :type="buttonIcons[status]" />
      {{ $t(`sync_status_${status}`) }}
    </a-tag>
  </div>
</template>

<script setup>
const props = defineProps({
  status: {
    type: String,
    default: ""
  },
  failed_reason: {
    type: String,
    default: ""
  }
})

const buttonIcons = {
  ERROR: "exclamation-circle",
  FAILURE: "close-circle",
  SUCCESS: "check-circle"
}
</script>

<style lang="scss" scoped>
.sync-status {
  min-width: max-content;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  gap: 4px;

  border-radius: 2px;

  &--ERROR {
    color: $grey-font;
    background-color: color-mix(in srgb, $grey-font 7.5%, white);
    border-color: color-mix(in srgb, $grey-font 50%, white);
    box-shadow: $grey-font;
    accent-color: $grey-font;
  }

  &--FAILURE {
    color: $red-color;
    background-color: color-mix(in srgb, $red-color 7.5%, white);
    border-color: color-mix(in srgb, $red-color 50%, white);
    box-shadow: $red-color;
    accent-color: $red-color;
  }

  &--SUCCESS {
    color: $green-color;
    background-color: color-mix(in srgb, $green-color 7.5%, white);
    border-color: color-mix(in srgb, $green-color 50%, white);
    box-shadow: $green-color;
    accent-color: $green-color;
  }
}
</style>
