<template>
  <div ref="mainInput">
    <!-- v-show="AE_selectedValueTypeGet !== 'LIST' && AE_selectedValueTypeGet !== 'MULTILIST'" -->
    <input
      ref="editableCell"
      type="text"
      :value="initValue"
      @keyup.enter="(e) => submitEditCell(field, e.target.value, rowIndex)"
      @keyup.esc="cancelEditCell"
      @input="onInput"
    />
    <ValidationErrors
      :lengthError="showLengthError"
      :emptyError="showEmptyError"
    />
  </div>
</template>
<script>
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validMaxLength from "utils/validations/validMaxLength"
import validEmpty from "utils/validations/validEmpty"
import { mapState } from "vuex"

export default {
  components: {
    ValidationErrors
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    value: String,
    onUpdate: Function,
    selectedAttr: Object
  },
  data() {
    return {
      showLengthError: false,
      showEmptyError: false,
      initValue: this.value
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
    this.closeOnBlurEvent()
  },
  beforeDestroy() {
    this.unsubscribeOnBlur()
  },
  computed: {
    ...mapState("ProductCatalog/attributesEdit", {
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr,
      AE_selectedValueTypeGet: (state) => state.conversion.AE_selectedValueType
    })
  },
  methods: {
    onInput(event) {
      let value = event.target.value
      this.showLengthError = !validMaxLength(value, 50)
      this.showEmptyError = !validEmpty(value)
      // set
      this.initValue = value
    },
    submitEditCell(field, value, rowIndex) {
      if (this.showLengthError || this.showEmptyError || value == this.value) return

      this.onUpdate({
        newValue: value,
        row: this.row,
        selectedAttrCode: this.selectedAttr.code,
        field: this.field,
        rowIndex: this.rowIndex,
        oldValue: this.value
      })
      this.close()
    },
    cancelEditCell() {
      this.close()
    },
    closeOnBlurEvent() {
      let context = this
      this.$refs.editableCell.addEventListener("blur", context.close, false)
    },
    unsubscribeOnBlur() {
      let context = this
      this.$refs.editableCell.removeEventListener("blur", context.close, false)
    }
  }
}
</script>
