export default {
  methods: {
    forceDownload(url, fileName) {
      const xhr = new XMLHttpRequest()
      xhr.open("GET", url, true)
      /*xhr.withCredentials = true;
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');*/
      xhr.responseType = "blob"
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL
        const imageUrl = urlCreator.createObjectURL(this.response)
        const tag = document.createElement("a")
        tag.href = imageUrl
        tag.download = fileName
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      }
      xhr.send()
    }
  }
}
