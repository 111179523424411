<template>
  <div
    class="users"
    ref="usersContainer"
    @mousedown.prevent
  >
    <div
      v-for="chat in uniqueChats"
      :key="chat.uuid"
      class="users__user user"
      :class="{ 'users__user--active': chat.uuid === currentChatId }"
      @click="onChatClick(chat.uuid)"
    >
      <div
        v-if="isModeratorSearch"
        class="user__avatar"
      >
        <div class="user__avatar-wrapper">
          <a-icon
            type="user"
            style="font-size: 18px"
          />
        </div>
      </div>
      <div
        v-else
        class="user__avatar"
        :class="chat.online_status ? 'user__avatar--online' : 'user__avatar--offline'"
      >
        <div class="user__avatar-wrapper">
          <img :src="getAvaSrc(chat.logo, getOpponentGender(chat))" />
        </div>
      </div>
      <div class="user__description">
        <div class="user__message-meta">
          <div class="user__name">
            <div
              v-if="isSeller(chat)"
              class="user__label-seller"
            >
              {{ $t("seller").charAt(0) }}
            </div>
            {{ chat.title }}
          </div>
          <div
            class="user__time"
            v-if="chat.last_message"
          >
            {{ formatDate(chat.last_message.created_at) }}
          </div>
        </div>
        <div
          v-if="chat.last_message"
          class="user__message-preview"
          :class="{ 'user__message-preview--unread': chat.unread_count > 0 }"
        >
          <div class="user__last-message">
            <div
              v-if="itsMyMessage(chat.last_message)"
              class="user__myself"
            >
              {{ $t("you") }}:
            </div>
            <template v-if="chat.last_message.is_removed">
              <a-icon
                type="delete"
                :style="{ color: '#161616', margin: '0 0 0 4px' }"
              />
              {{ $t("messageDeleted") }}
            </template>
            <template v-else-if="chat.last_message.type === 'SIMPLE'">
              {{ chat.last_message.body }}
            </template>
            <template
              v-if="
                chat.last_message.type === 'WITH_ATTACHMENTS' &&
                chat.last_message.attachments.length > 0
              "
            >
              <a-icon
                type="paper-clip"
                :style="{ color: '#161616', margin: '0 0 0 4px' }"
              />
              {{
                chat.last_message.attachments[0].name_original ||
                chat.last_message.attachments[0].name
              }}
            </template>
          </div>
          <div
            v-if="chat.unread_count > 0"
            class="user__messages-counter"
          >
            {{ chat.unread_count }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="hasNextPage"
      class="users__loader"
      ref="loader"
    >
      <a-icon type="loading" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import moment from "moment"
import imagePathMixin from "../../mixins/imagePathMixin"

export default {
  mixins: [imagePathMixin],
  props: {
    isModeratorSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("spvMessenger", ["chats"]),

    ...mapState("spvMessenger", {
      currentChatId: (state) => state.currentChatId,
      userUuid: (state) => state.user.uuid || "",
      chatFindRequest: (state) => state.chatFindRequest,
      hasNextPage: (state) => state.chatFindHasNextPage
    }),

    uniqueChats() {
      const results = []

      this.chats.forEach((chat) => {
        if (!results.find((resultChat) => resultChat.uuid === chat.uuid)) results.push(chat)
      })

      return results
    }
  },
  mounted() {
    this.$refs.usersContainer.addEventListener("scroll", this.onScrollBottom, false)
  },
  beforeDestroy() {
    this.$refs.usersContainer.removeEventListener("scroll", this.onScrollBottom, false)
  },
  methods: {
    ...mapMutations("spvMessenger", ["SELECT_CHAT", "BOTTOM_MESSAGES_LOADER"]),

    ...mapActions("spvMessenger", [
      "GET_CHAT_MESSAGES",
      "GET_CHAT_MESSAGES_BY_XHR",
      "GET_ALL_CHATS",
      "GLOBAL_CHAT_SEARCH_BY_XHR"
    ]),

    itsMyMessage(message) {
      return message.author.uuid === this.userUuid
    },

    getOpponentGender(chat) {
      return [...chat.participants, chat.owner].find((member) => member.role !== "moderator").gender
    },

    isSeller(chat) {
      const myselfIsChatOwner = chat.owner.uuid === this.userUuid

      if (myselfIsChatOwner) {
        return chat.participants.some((participant) => participant.role === "seller")
      } else {
        return chat.owner.role === "seller"
      }
    },

    isModerator(chat) {
      return chat.participants.some((participant) => participant.role === "moderator")
    },

    onChatClick(uuid) {
      this.SELECT_CHAT(uuid)
      this.BOTTOM_MESSAGES_LOADER(true)

      if (this.isModeratorSearch) {
        this.GET_CHAT_MESSAGES_BY_XHR({ chatUuid: uuid, offset: 0 })
      } else {
        this.GET_CHAT_MESSAGES({ chatUuid: uuid, offset: 0 })
      }
    },

    onScrollBottom() {
      if (this.chatFindRequest) return

      const { scrollTop, scrollHeight, clientHeight } = this.$refs.usersContainer
      const isNotScrolled = scrollTop === 0

      if (isNotScrolled) return

      const loaderHeight = this.$refs.loader.clientHeight
      const scrolledToBottom = scrollTop >= scrollHeight - clientHeight - loaderHeight

      if (scrolledToBottom && this.hasNextPage) {
        if (this.isModeratorSearch) {
          this.GLOBAL_CHAT_SEARCH_BY_XHR()
        } else {
          this.GET_ALL_CHATS()
        }
      }
    },

    formatDate(date) {
      const formattedDate = moment(date).locale(this.$i18n.locale)
      const isToday = formattedDate.isSame(new Date(), "day")
      const isYesterday = formattedDate.isSame(moment().subtract(1, "day"), "day")
      const isSomeYear = formattedDate.isSame(new Date(), "year")

      if (isToday) {
        return formattedDate.format("LT")
      } else if (isYesterday) {
        return formattedDate.calendar()
      } else if (isSomeYear) {
        const datePattern = "DD MMM, LT"

        return formattedDate.format(datePattern)
      } else {
        return formattedDate.format("L, LT")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.users {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: calc(100% - 56px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bfbfbf;

    &:hover {
      background-color: #8c8c8c;
    }
  }

  &__user {
    position: relative;
    border-top: 1px solid #eee;

    &:first-child {
      border-top: 0;
    }

    &--active {
      background-color: #f5f5f5;

      &:before {
        position: absolute;
        background-color: #685f74;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        width: 4px;
        content: "";
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  &__loader {
    padding: 16px 12px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.user {
  padding: 0 16px;
  min-height: 96px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__avatar {
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
    position: relative;
    margin-right: 12px;
    background-color: transparent;
    display: flex;

    &-wrapper {
      background-color: #fff;
      min-width: 48px;
      min-height: 48px;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &--online,
    &--offline {
      &:after {
        width: 14px;
        height: 14px;
        border-radius: 7px;
        display: inline-block;
        position: absolute;
        top: -6px;
        left: -6px;
        border: 3px solid #fff;
        content: "";
      }
    }

    &--online {
      &:after {
        background-color: #7cb305;
      }
    }

    &--offline {
      &:after {
        background-color: #ff4d4f;
      }
    }
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 48px - 12px);
  }

  &__label-seller {
    background-color: #006d75;
    color: #e6fffb;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    margin-right: 4px;
    font-weight: 400;
  }

  &__message-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    font-size: 12px;
    line-height: 18px;
    color: #161616;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    word-break: break-all;
    font-weight: 500;
  }

  &__time {
    color: #8c8c8c;
    font-size: 12px;
    line-height: 18px;
    padding-left: 8px;
    white-space: nowrap;
    display: inline-block;
  }

  &__message-preview {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;

    &--unread {
      font-weight: 500;

      & > .user__last-message {
        color: #141414;
      }
    }
  }

  &__myself {
    color: #8c8c8c;
    font-weight: 400;
    display: inline;
  }

  &__last-message {
    color: #434343;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    word-break: break-all;
  }

  &__messages-counter {
    background-color: #389e0d;
    flex-shrink: 0;
    color: #f6ffed;
    border-radius: 9px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    font-weight: 500;
    min-width: 18px;
    text-align: center;
  }
}
</style>
