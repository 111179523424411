<template>
  <span>
    {{ ordersLimitCount }} {{ $tc("ordersLeft", ordersLimitCount).toLowerCase() }}
    <div>
      {{ getDate }}
    </div>
  </span>
</template>

<script>
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

export default {
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    ordersLimitCount() {
      const count = this.row.amount_free_orders || 0

      return count
    },
    getDate() {
      return getFormatedDateTime(this.row.amount_free_orders_set_at)
    }
  }
}
</script>
