<template>
  <Modal
    @close="$emit('cancel')"
    :showModal="visible"
    :title="$t('confirmation')"
    :showCloseButton="false"
  >
    <p>{{ $t("deleteMessageForAll") }}?</p>
    <div
      slot="footer"
      class="footer"
    >
      <Button
        color="secondary"
        @click="$emit('cancel')"
      >
        {{ $t("no") }}
      </Button>
      <Button
        color="primary"
        @click="$emit('ok')"
      >
        {{ $t("yes") }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../../Modals/Modal"
import Button from "../../../Button"

export default {
  name: "RemoveConfirmationModal",
  components: {
    Modal,
    Button
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
