<template>
  <div class="editModal">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        prop="username"
        :label="$t('userName')"
      >
        <a-input v-model="form.username" />
      </a-form-model-item>

      <a-form-model-item
        prop="email"
        label="Email"
        required
      >
        <a-input v-model="form.email" />
      </a-form-model-item>

      <a-form-model-item
        prop="password"
        :label="$t('password')"
      >
        <a-input
          v-model="form.password"
          type="password"
        />
      </a-form-model-item>

      <a-form-model-item
        prop="passwordConfirm"
        :label="$t('passwordConfirm')"
      >
        <a-input
          v-model="form.passwordConfirm"
          type="password"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        :loading="isFetching"
        type="primary"
        @click="onSubmit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import { createUser } from "@/modules/UsersManagement/services/usersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const emit = defineEmits(["onCreate"])

const formRef = ref()
const form = reactive({
  username: "",
  email: "",
  password: "",
  passwordConfirm: ""
})

const isFetching = ref(false)

const validatePass = (_, value, callback) => {
  if (value === "") {
    callback(new Error(i18n.t("emptyError")))
  } else if (value !== form.password) {
    callback(new Error(i18n.t("passwordsNotMatchError")))
  } else {
    callback()
  }
}

const validateEmail = (_, value, callback) => {
  if (!value) {
    callback(new Error(i18n.t("emptyError")))
  } else if (!value.match(/[^\s@]+@[^\s@]+\.[^\s@]+/)) {
    callback(new Error(i18n.t("invalidEmailError")))
  } else {
    callback()
  }
}

const formRules = computed(() => ({
  username: [{ required: true, message: i18n.t("emptyError") }],
  email: [{ validator: validateEmail, trigger: "blur" }],
  password: [
    { required: true, message: i18n.t("emptyError") },
    { min: 4, message: i18n.t("minLengthError", { length: 4 }) }
  ],
  passwordConfirm: [{ validator: validatePass, trigger: "blur" }]
}))

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    isFetching.value = true

    await createUser(form)

    notification.success({ message: i18n.t("userCreatedSuccessfully") })
    emit("onCreate")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>
