import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = {
  showVldApproveModal: false,
  chosenValueCode: ""
}

const mutations = {
  SHOW_VLD_APPROVE_MODAL(state) {
    state.showVldApproveModal = true
  },
  HIDE_VLD_APPROVE_MODAL(state) {
    state.showVldApproveModal = false
  },
  SET_CHOSEN_CODE(state, payload) {
    state.chosenValueCode = payload.code
  }
}

const actions = {
  confirmApproval({ state, dispatch, getters }) {
    const sendObject = {
      code: state.chosenValueCode,
      approval_status: "APPROVED"
    }
    return API_MODERATION.patch(apiUrls.value, sendObject)
      .then((r) => {
        dispatch("PV_searchValuesByDescription", getters.PV_valuesDescriptionQueryParams)
        return r
      })
      .then((r) => {
        return r.data.message
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  }
}

export default {
  state,
  mutations,
  actions
}
