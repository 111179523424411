import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { buildProductTableItems } from "@/modules/ProductCatalog/store/productPage/productPage/productPage"

const state = () => ({
  PC_showEditLocaleModal: false,
  PC_localizedProductAttributes: false,
  PC_localizedProduct: false,
  PC_editableLocale: false,
  PC_localizedNameWasEdited: false,
  PC_localizedPublicNameWasEdited: false,
  PC_localizedDescriptionWasEdited: false,
  PC_languageForEditLocaleModal: false,
  PC_localizedAttributeValueWasEdited: false,
  PC_editedAttributeLocalValues: [],
  PC_localizedProductType: false,
  PC_localizedProductBrand: false
})

const getters = {
  PC_showEditLocaleModal: (state) => state.PC_showEditLocaleModal,
  PC_localizedProductAttributes: (state) => state.PC_localizedProductAttributes,
  PC_localizedProduct: (state) => state.PC_localizedProduct,
  PC_editableLocale: (state) => state.PC_editableLocale,
  PC_localizedNameWasEdited: (state) => state.PC_localizedNameWasEdited,
  PC_localizedPublicNameWasEdited: (state) => state.PC_localizedPublicNameWasEdited,
  PC_localizedDescriptionWasEdited: (state) => state.PC_localizedDescriptionWasEdited,
  PC_languageForEditLocaleModal: (state) => state.PC_languageForEditLocaleModal,
  PC_localizedAttributeValueWasEdited: (state) => state.PC_localizedAttributeValueWasEdited,
  PC_editedAttributeLocalValues: (state) => state.PC_editedAttributeLocalValues,
  PC_localizedProductType: (state) => state.PC_localizedProductType,
  PC_localizedProductBrand: (state) => state.PC_localizedProductBrand
}

const mutations = {
  PC_showEditLocaleModal(state, value) {
    state.PC_showEditLocaleModal = value
  },
  PC_localizedProductAttributes(state, { attributesData }) {
    state.PC_localizedProductAttributes = attributesData
    let clone = JSON.parse(JSON.stringify(state.PC_localizedProductAttributes))
    state.PC_localizedProductAttributes = clone
  },
  PC_localizedProductAttributesReset(state) {
    state.PC_localizedProductAttributes = false
  },
  PC_localizedProduct(state, value) {
    state.PC_localizedProduct = value
  },
  PC_localizedProductName(state, value) {
    state.PC_localizedProduct.name = value
  },
  PC_localizedPublicProductName(state, value) {
    state.PC_localizedProduct.public_title = value
  },
  PC_localizedProductDescription(state, value) {
    state.PC_localizedProduct.description = value
  },
  PC_editableLocale(state, value) {
    state.PC_editableLocale = value
  },
  PC_localizedNameWasEdited(state, value) {
    state.PC_localizedNameWasEdited = value
  },
  PC_localizedPublicNameWasEdited(state, value) {
    state.PC_localizedPublicNameWasEdited = value
  },
  PC_localizedDescriptionWasEdited(state, value) {
    state.PC_localizedDescriptionWasEdited = value
  },
  PC_languageForEditLocaleModal(state, value) {
    state.PC_languageForEditLocaleModal = value
  },
  PC_localizedAttributeValueWasEdited(state, value) {
    state.PC_localizedAttributeValueWasEdited = value
  },
  PC_localizedProductType(state, value) {
    state.PC_localizedProductType = value
  },
  PC_localizedProductBrand(state, value) {
    state.PC_localizedProductBrand = value
  },
  PC_attributesLocalValueChangeLog: (
    state,
    { columnKey, newValue, rowIndex, oldValue, tableItemsCode }
  ) => {
    // add to change log, which is going to be executed on submit
    let isSet = false
    if (state.PC_editedAttributeLocalValues.length) {
      isSet = state.PC_editedAttributeLocalValues.find((item) => {
        return item.code === tableItemsCode
      })
    }

    if (isSet) {
      isSet.value = newValue
    } else {
      state.PC_editedAttributeLocalValues.push({
        code: tableItemsCode,
        value: newValue
      })
    }
    //
  },
  PC_editedAttributeLocalValues(state, value) {
    state.PC_editedAttributeLocalValues = value
  }
}

const actions = {
  async PC_showEditLocaleModal({ commit, getters, dispatch }, { product, lang }) {
    // reset
    commit("PC_resetProductValues")
    commit("PC_emptyEditableProductAttributes")
    await dispatch("PC_resetEditLocaleValues")
    if (product.status !== "VERIFIED" && product.status !== "TRANSFERRED") {
      // open
      commit("PC_showEditLocaleModal", true)
      return Promise.resolve()
    }
    try {
      let productInitial = product
      await dispatch("PC_fetchBaseLangData", productInitial)
      // set editable locale
      await dispatch("PC_selectLanguage", { value: lang, page: "product" })
      await dispatch("PC_fetchLocalizedValues", { product: productInitial, locale: lang })
      commit("PC_languageForEditLocaleModal", lang)
    } catch (e) {
      console.log(e)
    }

    // open
    commit("PC_showEditLocaleModal", true)
  },
  async PC_fetchBaseLangData({ commit, getters, dispatch }, product) {
    try {
      product = await dispatch("PC_fetchSelectedProductData", { product, size: "500x500" })
      // fetch attributes
      let sendObject = {
        product,
        attributes: product.attributes,
        mutationName: "PC_setEditableProductAttributes",
        newAttribute: false,
        checkAttributes: true
      }
      await dispatch("PC_fetchProductAttributes", sendObject)
    } catch (e) {
      console.log(e)
    }
    return Promise.resolve()
  },
  async PC_fetchLocalizedValues({ commit, getters, dispatch }, { product, locale }) {
    try {
      let productBase = product
      let productLocal = await dispatch("PC_fetchLocalizedProductData", { product, locale })
      // fetch attributes
      let sendObject = {
        product: productLocal,
        attributes: productLocal.attributes,
        mutationName: "PC_localizedProductAttributes",
        newAttribute: false,
        checkAttributes: true,
        localeHeader: true
      }
      await dispatch("PC_fetchProductAttributes", sendObject)
      // got through attributes.items
      // assign unitValue as localValue
      commit("PC_editableProductAttributesLocalValues", getters.PC_localizedProductAttributes.items)
      // assign local names
      if (getters.PC_localizedProductAttributes) {
        let localNames = getters.PC_localizedProductAttributes.items.map((item) => {
          return item.name
        })
        commit("PC_editableProductAttributesLocalNames", localNames)
      }
      // assign local units
      if (getters.PC_localizedProductAttributes) {
        let localUnits = getters.PC_localizedProductAttributes.items.map((item) => {
          return item.unitsNames
        })
        commit("PC_editableProductAttributesLocalUnits", localUnits)
      }

      // await dispatch('PC_fetchLocalizedProductType', productBase);
      // await dispatch('PC_fetchLocalizedProductBrand', productBase);
    } catch (e) {
      commit("handleError", e)
    }
    return Promise.resolve()
  },
  async PC_fetchLocalizedProductData({ commit, dispatch, getters }, { product, locale }) {
    // show loader
    commit("PC_showLoader", true)

    let params = {
      params: { code: product.code },
      headers: {
        "Accept-language": locale
      }
    }

    return API_MODERATION.get(`${apiUrls.product}`, params)
      .then((r) => r.data.message.records[0])
      .then((product) => {
        product = buildProductTableItems([product], "500x500")[0]
        commit("PC_localizedProduct", product)
        // hide loader
        commit("PC_showLoader", false)
        return product
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  async PC_fetchLocalizedProductType({ commit, getters }, product) {
    let params = {
      params: {
        code: product.type[0].code
      },
      headers: {
        "Accept-language": getters.PC_selectedLanguage
      }
    }
    return API_MODERATION.get(`${apiUrls.type}`, params)
      .then((r) => r.data.message.records[0])
      .then((r) => {
        commit("PC_localizedProductType", r)
      })
  },
  async PC_fetchLocalizedProductBrand({ commit, getters }, product) {
    let params = {
      params: {
        code: product.brand[0].code
      },
      headers: {
        "Accept-language": getters.PC_selectedLanguage
      }
    }
    return API_MODERATION.get(`${apiUrls.brand}`, params)
      .then((r) => r.data.message.records[0])
      .then((r) => {
        commit("PC_localizedProductBrand", r)
      })
  },
  PC_editLocalAttributesTableInput({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue =
      (getters.PC_EditableProduct.attributes[rowIndex] &&
        getters.PC_EditableProduct.attributes[rowIndex].value) ||
      ""
    /* */
    if (newValue === oldValue) return
    let tableItemsCode = getters.PC_editableProductAttributes.items[rowIndex].code
    commit("PC_attributesLocalValueChangeLog", {
      columnKey,
      newValue,
      rowIndex,
      oldValue,
      tableItemsCode
    })
    // set in table
    commit("PC_editableProductAttributesLocalValuesUpdate", { columnKey, newValue, rowIndex })
  },
  async PC_resetEditLocaleValues({ commit }) {
    commit("PC_localizedProductAttributesReset")
    commit("PC_localizedProduct", false)
    commit("PC_editableLocale", false)
    commit("PC_localizedNameWasEdited", false)
    commit("PC_localizedPublicNameWasEdited", false)
    commit("PC_localizedDescriptionWasEdited", false)
    commit("PC_languageForEditLocaleModal", false)
    commit("PC_localizedAttributeValueWasEdited", false)
    commit("PC_editedAttributeLocalValues", [])
    return Promise.resolve()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
