import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_showAddAttributeModal: false,
  PV_attributesForNewAttributeAutocomplete: [],
  PV_attributesForNewAttribute: [],
  PV_selectedAttributeForNewAttribute: false
})

const getters = {
  PV_showAddAttributeModal: (state) => state.PV_showAddAttributeModal,
  PV_attributesForNewAttributeAutocomplete: (state) =>
    state.PV_attributesForNewAttributeAutocomplete,
  PV_attributesForNewAttribute: (state) => state.PV_attributesForNewAttribute,
  PV_selectedAttributeForNewAttribute: (state) => state.PV_selectedAttributeForNewAttribute
}

const mutations = {
  PV_showAddAttributeModal(state, value) {
    state.PV_showAddAttributeModal = value
  },
  PV_attributesForNewAttributeAutocomplete(state, value) {
    state.PV_attributesForNewAttributeAutocomplete = value
  },
  PV_attributesForNewAttribute(state, value) {
    state.PV_attributesForNewAttribute = value
  },
  PV_selectedAttributeForNewAttribute(state, value) {
    state.PV_selectedAttributeForNewAttribute = value
  }
}

const actions = {
  PV_fetchAttributesToAddNewAttribute({ commit, getters }, value) {
    let searchData = buildMagicSearch({
      resource: "AND",
      field: "name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((attrs) => {
        // filter out attributes, which are already added
        attrs = attrs.filter((item) => {
          let notAdded = true
          let added = getters.PV_initialAttributes
          for (let i = 0; i < added.length; i++) {
            if (added[i].code === item.code) {
              notAdded = false
              break
            }
          }
          return notAdded
        })

        // add attribute type to the name
        attrs = attrs.map((item) => {
          item.name += ` (${item.value_type})`
          return item
        })
        commit("PV_attributesForNewAttribute", attrs)
        let unitsArray = attrs.map((item) => {
          return item.name
        })
        commit("PV_attributesForNewAttributeAutocomplete", unitsArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  async PV_addSelectedAttributeValue({ commit, getters, dispatch }, value) {
    let selectedValue = getters.PV_attributesForNewAttribute.find((item) => {
      return item.name === value
    })

    // check that the selected attribute has type String
    if (selectedValue && selectedValue.value_type !== "STRING") {
      // show message
      notification.error({
        message: `Данный атрибут не может быть выбран, так как уже имеет другой тип (${selectedValue.value_type})`
      })
      // update default autocomplete value (switch the value to run the observer, which is watching this value)
      commit("PV_selectedAttributeForNewAttribute", selectedValue)
      setTimeout(() => {
        commit("PV_selectedAttributeForNewAttribute", false)
      }, 0)
      return
    }

    // set selected
    commit("PV_selectedAttributeForNewAttribute", selectedValue)
  },
  async PV_addNewAttribute({ commit, getters, dispatch }) {
    let products = await dispatch("PV_addAttrToListAndUpdateProducts")
    if (products && products instanceof Array) {
      let response = await dispatch("PV_buildProductsTableData", products)
      if (!response) return
      commit("PV_showProductsModal", true)
    } else {
      // restartTable
      dispatch("PV_searchValuesByDescription")
    }
    // reset values
    dispatch("PV_resetAddAttributeValues")
  },
  async PV_addAttrToListAndUpdateProducts({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let sendData = {
      attribute: getters.PV_selectedAttributeForNewAttribute.code,
      description: getters.PV_selectedList.description
    }

    // start request
    return API_MODERATION.patch(`${apiUrls.createFromAttribute}`, sendData)
      .then((r) => r.data.message)
      .then((r) => {
        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Новый атрибут добавлен" })
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        if (e.response && e.response.status === 400 && e.response.data.message.length) {
          let products = e.response.data.message
          return products
        } else {
          commit("handleError", e)
          return null
        }
      })
  },
  PV_resetAddAttributeValues({ commit }) {
    commit("PV_attributesForNewAttributeAutocomplete", [])
    commit("PV_attributesForNewAttribute", [])
    commit("PV_selectedAttributeForNewAttribute", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
