var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-alert',{attrs:{"type":"info","showIcon":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("allowedFormats"))+": "+_vm._s(_setup.allowedFormatsString)+". "+_vm._s(_vm.$t("maxSize30"))+" ")]},proxy:true}])}),_c('a-tabs',{model:{value:(_setup.activeLanguage),callback:function ($$v) {_setup.activeLanguage=$$v},expression:"activeLanguage"}},_vm._l((_setup.allowed_locales),function(lang){return _c('a-tab-pane',{key:lang,attrs:{"tab":lang.toUpperCase()}},_vm._l((_setup.allowed_devices),function(device){return _c('div',{key:device,staticClass:"device"},[_c('div',{staticClass:"device__title"},[_vm._v(" "+_vm._s(device)+" ")]),_vm._l((_setup.allowed_image_sizes),function(size){return _c('div',{key:size,staticClass:"device__item",attrs:{"bordered":false}},[_c(_setup.SingleImageLoader,{attrs:{"image":{
              url:
                _vm.prototype &&
                _vm.prototype?.images_by_lang &&
                _vm.prototype?.images_by_lang[lang][device][size]
                  ? _vm.prototype.images_by_lang[lang][device][size]
                  : null
            },"responsive":true,"allowedFormats":_setup.allowedFormats,"parseCDN":true,"options":{
              path: `images_by_lang.${lang}.${device}.${size}`,
              lang,
              device,
              size
            },"uploadPromise":_setup.uploadPromise,"removingPromise":_setup.removingPromise}}),_c('a-card-meta',{staticClass:"device__item__info",scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" "+_vm._s(size)+" "),(device === 'computer')?[_vm._v(" "+_vm._s(size === "1x" ? "(1220x435px)" : "(2440x870px)")+" ")]:_vm._e()]},proxy:true}],null,true)})],1)})],2)}),0)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }