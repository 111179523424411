var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"seo-settings",attrs:{"form":_vm.seoForm}},[_vm._l((_vm.languages),function(lang){return _c('a-form-item',{key:`title-${lang.value}`,scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title, "+_vm._s(lang.label.toUpperCase())+" ("),_c('span',{class:{
          'red-text': _vm.titleIsIncorrect(lang.value)
        }},[_vm._v(_vm._s(_vm.titleLength[lang.value]))]),_vm._v("/"+_vm._s(_vm.titleMaxLength.join("-"))+") ")]},proxy:true}],null,true)},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`title_${lang.value}`]),expression:"[`title_${lang.value}`]"}],on:{"change":(e) => _vm.onChangeTitle(e, lang.value)}})],1)}),_vm._l((_vm.languages),function(lang){return _c('a-form-item',{key:`description-${lang.value}`,scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description, "+_vm._s(lang.label.toUpperCase())+" ("),_c('span',{class:{
          'red-text': _vm.descriptionIsIncorrect(lang.value)
        }},[_vm._v(_vm._s(_vm.descriptionLength[lang.value]))]),_vm._v("/"+_vm._s(_vm.descriptionMaxLength.join("-"))+") ")]},proxy:true}],null,true)},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([`description_${lang.value}`]),expression:"[`description_${lang.value}`]"}],attrs:{"autoSize":{ minRows: 5, maxRows: 5 }},on:{"change":(e) => _vm.onChangeDescription(e, lang.value)}})],1)}),_vm._l((_vm.languages),function(lang){return _c('a-form-item',{key:`keyword-${lang.value}`,scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Keywords, "+_vm._s(lang.label.toUpperCase())+" ("),_c('span',{class:{
          'red-text': _vm.keywordsIsIncorrect(lang.value)
        }},[_vm._v(_vm._s(_vm.keywordsLength[lang.value]))]),_vm._v("/"+_vm._s(_vm.keywordsMaxLength)+") ")]},proxy:true}],null,true)},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([`keywords_${lang.value}`]),expression:"[`keywords_${lang.value}`]"}],attrs:{"autoSize":{ minRows: 5, maxRows: 5 }},on:{"change":(e) => _vm.onChangeKeywords(e, lang.value)}})],1)}),_c('a-collapse',{staticStyle:{"width":"100%","background-color":"inherit"},attrs:{"accordion":"","bordered":false}},_vm._l((_vm.languages),function(lang){return _c('a-collapse-panel',{key:`text-${lang.value}`,staticStyle:{"border":"none"},attrs:{"forceRender":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("SEO-text, "+_vm._s(lang.label.toUpperCase()))]},proxy:true}],null,true)},[_c('a-form-item',[_c('EditorHtml',{directives:[{name:"decorator",rawName:"v-decorator",value:([`text_${lang.value}`]),expression:"[`text_${lang.value}`]"}],attrs:{"id":`editor-${lang.value}`,"value":_vm.seoForm.getFieldValue(`text_${lang.value}`),"headers":[false, 4, 5, 6]}})],1)],1)}),1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }