import Metrics from "./Metrics"
import Sessions from "./pages/Sessions/index.js"

export default {
  path: "/metrics",
  component: Metrics,
  meta: {
    permissionType: "application",
    permissionCode: "METR1",
    code: "METR1",
    withoutChecking: true,
    layout: "ant-layout"
  },
  children: [
    {
      path: "sessions",
      component: Sessions,
      meta: {
        permissionType: "component",
        permissionCode: "METR1SES1",
        code: "SES1",
        withoutChecking: true,
        layout: "ant-layout"
      }
    }
  ]
}
