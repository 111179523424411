<template>
  <div
    class="i-wrapper"
    :class="{ [state]: state, xs: width == 'xs' }"
    @mousedown.prevent
  >
    <label>
      <span v-if="isLabel">{{ label }} {{ required ? "*" : "" }}</span>
      <span
        class="select"
        ref="select"
        @click="toggleShow"
        :class="{ [size]: size, ['disabled']: disabled }"
      >
        <span class="nowrap">
          {{ currentValue || $t("chooseFromList") }}
        </span>
        <span class="triangle"></span>

        <transition name="slide-fade">
          <div
            v-if="isShow && !disabled"
            class="select-content"
          >
            <span
              v-if="!value.length"
              class="description noborder"
            >
              {{ $t("noData") }}
            </span>
            <ul>
              <li v-if="search">
                <input
                  type="text"
                  v-model="searchInItems"
                />
              </li>
              <li
                v-for="item in !searchResult.length ? value : searchResult"
                @click.prevent.stop="!disabled ? handleChange(item) : ''"
              >
                <a
                  href=""
                  @click.prevent
                >
                  {{ propName !== "" ? item[propName] : item }}
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    size: {
      type: String,
      default: "medium"
    },
    status: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    propName: {
      type: [String],
      default: ""
    },
    defaultValueId: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: this.status,
      current: null,
      isShow: false,
      searchInItems: "",
      searchResult: []
    }
  },
  watch: {
    defaultValueId(id) {
      this.current = this.value.filter((obj) => obj.id === id)[0]
    },
    searchInItems() {
      if (this.searchInItems) {
        this.searchResult = this.value.filter((value) => {
          if (value.name.toLowerCase().includes(this.searchInItems.toLowerCase())) return value
        })
      } else {
        this.searchResult = []
      }
    },
    value: {
      handler(val) {
        if (val.length) {
          this.current = "" // якщо змінити список айтемів у дропдауні, вибране раніше значення видаляється
        }
      }
    }
  },
  methods: {
    handleChange(item) {
      this.current = item
      this.isShow = false

      this.$emit("change", item)
    },
    toggleShow() {
      if (event.target.tagName === "INPUT") return
      this.isShow = !this.isShow
    },
    hide(e) {
      if (this.$refs.select.contains(e.target)) return
      this.isShow = false
    }
  },
  computed: {
    isLabel() {
      return this.label !== ""
    },
    currentValue() {
      if (this.current == null && this.defaultValueId !== 0 && this.propName && this.value) {
        const targetDefaultObj = this.value.filter((obj) => obj.id == this.defaultValueId)[0]

        return targetDefaultObj[this.propName]
      } else if (this.current != null) {
        return this.propName !== "" ? this.current[this.propName] : this.current
      }
    }
  },
  created() {
    document.addEventListener("mousedown", this.hide)
  },
  destroyed() {
    document.removeEventListener("mousedown", this.hide)
  }
}
</script>

<style lang="scss" scoped>
.nowrap {
  white-space: nowrap !important;
  overflow: hidden;
}

.select {
  box-shadow: $light-shadow;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  margin: 0;
  border: 1px solid rgba(125, 125, 125, 0.3);
  padding: 0 10px;
  position: relative;
  border-radius: 3px;
  padding-right: 30px !important;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 13.33px;
  width: 100% !important;

  .triangle {
    position: absolute;
    top: calc(50% - 3px);
    right: 16px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: $link-default transparent transparent transparent;
    display: inline-block;
    box-sizing: border-box;
    content: "";
  }

  &.disabled {
    background-color: #f0f0f0;
  }

  &.small {
    height: 24px !important;
    line-height: 24px !important;

    & .select-content {
      top: 26px;

      & .description,
      & .info {
        height: 24px;
        line-height: 24px;
      }

      & ul li {
        min-height: 24px;

        & a {
          padding: 4px 20px;
        }
      }
    }
  }

  &.medium {
    height: 30px !important;
    line-height: 30px !important;

    & .select-content {
      top: 32px;

      & .description,
      & .info {
        height: 30px;
        line-height: 30px;
      }

      & ul li {
        min-height: 30px;

        & a {
          padding: 6px 20px;
        }
      }
    }
  }

  &.large {
    height: 38px !important;
    line-height: 38px !important;

    & .select-content {
      top: 40px;

      & .description,
      & .info {
        height: 40px;
        line-height: 40px;
      }

      & ul li {
        min-height: 40px;

        & a {
          padding: 10px 20px;
        }
      }
    }
  }

  .select-content {
    width: calc(100% + 2px);
    box-sizing: border-box;
    height: auto;
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    font-size: 0;
    line-height: 0;
    left: -1px;
    box-shadow: $element-shadow;
    border: 1px solid $light-border;
    background-color: $page-color;
    z-index: 999;

    & .description {
      margin: 0;
      width: 100%;
      display: inline-block;
      font-size: $small-font-size;
      font-weight: 700;
      text-transform: uppercase;
      color: $dark;
      padding: 0 20px;
      border-bottom: 1px solid $light-border;
    }

    & .info {
      width: 100%;
      text-align: center;
      display: inline-block;
      font-size: $small-font-size;
      font-weight: 700;
      color: $dark;
      padding: 0 20px;
    }

    & ul {
      padding: 0;
      margin: 0;
      max-height: 245px;
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: $light-border;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: $light-border;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: $dark;
      }

      &::-webkit-scrollbar-thumb:active {
        border-radius: 0;
        background-color: $black;
      }

      li {
        height: auto;
        border-bottom: 1px solid $light-border;

        &:last-child {
          border: 0;
        }

        a {
          height: auto;
          line-height: 20px;
          display: block;
          word-break: break-word;
          font-size: $small-font-size;

          &:hover {
            background-color: $default-shadow-color;
          }
        }

        input {
          display: block;
          padding: 6px 20px !important;
          word-break: break-word;
          font-size: $small-font-size;
        }
      }
    }
  }

  .inline {
    display: inline-block;
  }

  .i-wrapper {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error {
    span {
      color: $danger-color;
      border: 0 !important;
    }

    input {
      border: 1px solid $danger-color;
    }
  }

  .success {
    span {
      color: $success-color;
      border: 0 !important;
    }

    input {
      border: 1px solid $success-color;
    }
  }

  .display {
    display: block !important;
    width: 100% !important;
  }
}

.xs {
  max-width: 80px;
}
</style>
