import messengerStore from "./messengerStore"
import SpvMessengerModerator from "./components/MessengerMain/ModeratorSearch.vue"
import logger from "./utils/logger"
import clearLastSlash from "./utils/clearLastSlash"

export default {
  install(Vue, options) {
    const { store } = options

    store.registerModule("spvMessenger", messengerStore)

    if (options.moderatorSearch) {
      Vue.component("SpvMessengerModerator", SpvMessengerModerator)
    }

    if (options.xhrUrl) {
      store.commit("spvMessenger/SET_XHR_URL", clearLastSlash(options.xhrUrl))
    }

    if (options.cdnUrl) {
      if (options.cdnUrl.slice(-21) === "/media/assets/images/") {
        options.cdnUrl = options.cdnUrl.slice(0, -21)
      }
      store.commit("spvMessenger/SET_CDN_URL", clearLastSlash(options.cdnUrl))
    }

    if (options.mpUrl) {
      store.commit("spvMessenger/SET_MP_URL", clearLastSlash(options.mpUrl))
    }

    if (options && store) {
      Vue.prototype.$connectMessengerSocket = function ({ getToken, user }) {
        store.commit("spvMessenger/SOCKET_CONNECTING", true)
        store.commit("spvMessenger/SET_USER", user)

        const token = getToken ? getToken() : null

        if (token) {
          store.commit("spvMessenger/SET_GET_TOKEN", getToken)
        }

        Vue.prototype.$messengerSocket = new WebSocket(
          `${clearLastSlash(options.url)}/?sender=moderator`
        )
        // Send data handler
        Vue.prototype.$messengerSocket.sendObj = async function (data) {
          const dataObj = data

          if (store.state.spvMessenger.getToken) {
            dataObj.token = store.state.spvMessenger.getToken()
          }

          logger.request(data)

          Vue.prototype.$messengerSocket.send(JSON.stringify(dataObj))
        }

        const handleOpen = () => {
          store.dispatch("spvMessenger/SOCKET_ONOPEN_ACTION")
        }

        const handleClose = (event) => {
          unsubscribeAll()

          store.commit("spvMessenger/RESET_ALL")
          store.commit(`spvMessenger/SOCKET_ONCLOSE`, event)
        }

        const handleError = (error) => {
          store.commit(`spvMessenger/SOCKET_ONERROR`, error)
        }

        const handleMessage = (event) => {
          const data = JSON.parse(event.data)

          logger.response(data)

          store.commit(`spvMessenger/SOCKET_ONMESSAGE`, data)
          store.dispatch(`spvMessenger/ROUTE_SOCKET_RESPONSES`, data)
        }

        Vue.prototype.$messengerSocket.addEventListener("open", handleOpen, false)
        Vue.prototype.$messengerSocket.addEventListener("close", handleClose, false)
        Vue.prototype.$messengerSocket.addEventListener("error", handleError, false)
        Vue.prototype.$messengerSocket.addEventListener("message", handleMessage, false)

        function unsubscribeAll() {
          Vue.prototype.$messengerSocket.removeEventListener("open", handleOpen)
          Vue.prototype.$messengerSocket.removeEventListener("close", handleClose)
          Vue.prototype.$messengerSocket.removeEventListener("error", handleError)
          Vue.prototype.$messengerSocket.removeEventListener("message", handleMessage)
        }
      }
    }
  }
}
