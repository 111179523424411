import i18n from "@/i18n"
import { computed } from "vue"

export default function useSessionsColumns() {
  const sessionsListColumns = computed(() => [
    {
      key: "user",
      scopedSlots: {
        customRender: "userRenderer"
      },
      title: "User",
      fixed: "left",
      width: 50
    },
    {
      key: "cart",
      dataIndex: "events_count.cart",
      title: i18n.t("cartCount"),
      sorter: (a, b) => a.events_count.cart - b.events_count.cart,
      align: "center",
      width: 90
    },
    {
      key: "checkout_start",
      dataIndex: "events_count.checkout_start",
      title: i18n.t("checkoutStartCount"),
      sorter: (a, b) => a.events_count.checkout_start - b.events_count.checkout_start,
      align: "center",
      width: 120
    },
    {
      key: "checkout_submit",
      dataIndex: "events_count.checkout_submit",
      title: i18n.t("checkoutSubmitCount"),
      sorter: (a, b) => a.events_count.checkout_submit - b.events_count.checkout_submit,
      align: "center",
      width: 120
    },
    {
      key: "unique_pages",
      dataIndex: "unique_pages",
      title: i18n.t("unique_pages_shorted"),
      sorter: (a, b) => a.unique_pages - b.unique_pages,
      onFilter: (value, record) => record.unique_pages === value,
      align: "center",
      width: 90
    },
    {
      key: "sessions_count",
      dataIndex: "sessions_count",
      title: i18n.t("sessionsCount"),
      sorter: (a, b) => a.sessions_count - b.sessions_count,
      onFilter: (value, record) => record.sessions_count === value,
      align: "center",
      width: 90
    },
    {
      key: "null_sessions_count",
      dataIndex: "null_sessions_count",
      slots: { title: "nullSessionsTitle" },
      sorter: (a, b) => a.null_sessions_count - b.null_sessions_count,
      filters: [],
      onFilter: (value, record) => record.null_sessions_count === value,
      align: "center",
      width: 90
    },
    {
      key: "non_relevant_sessions_count",
      dataIndex: "non_relevant_sessions_count",
      slots: { title: "notRelevantSessionsTitle" },
      sorter: (a, b) => a.non_relevant_sessions_count - b.non_relevant_sessions_count,
      onFilter: (value, record) => record.non_relevant_sessions_count === value,
      align: "center",
      width: 90
    },
    {
      key: "total",
      dataIndex: "events_count.total",
      title: i18n.t("eventsCount"),
      sorter: (a, b) => a.events_count.total - b.events_count.total,
      align: "center",
      width: 90
    }
  ])

  return {
    sessionsListColumns
  }
}
