<template>
  <a-drawer
    :title="drawerTitle"
    width="90%"
    :visible="visible"
    destroyOnClose
    @close="onClose"
  >
    <a-tabs
      v-model="tabPosition"
      type="card"
    >
      <a-tab-pane
        key="common"
        :tab="$t('general')"
        forceRender
      >
        <EditProductCommonTab
          ref="productFormRef"
          :visible="visible"
          :product="product"
          :fetching="productFetching"
          @formChanged="onProductChanged"
          @onTypeChanged="onProductTypeChange"
        />
      </a-tab-pane>
      <a-tab-pane
        key="attributes"
        :tab="$t('attributes')"
        :disabled="!hasProductCode"
      >
        <EditProductAttributesTab
          :mpcCode="productCode"
          :hasProductType="hasProductType"
          :attributes="mappedAttributes"
          :fetching="attributesFetching"
          :productMainAttributesFetching="productMainAttributesFetching"
          :productTypeAllAttributesFetching="productTypeAllAttributesFetching"
          :sortAttributesRequest="sortAttributesRequest"
          :product="product"
          @updateAttribute="handleUpdateMappedAttributes"
          @addMainAttributes="getProductMainAttributes"
          @addAllProductTypeAttributes="getAllProductTypeAttributes"
          @sortAttributes="handleSortAttributes"
          @addNewAttribute="handleAddNewAttribute"
          @deleteAttribute="handleDeleteAttribute"
          @deleteEmptyAttributes="handleDeleteEmptyAttributes"
          @attributesChanged="onProductChanged"
        />
      </a-tab-pane>
      <a-tab-pane
        key="gallery"
        :tab="$t('gallery')"
        :disabled="!hasProductCode"
      >
        <EditProductImagesTab
          :mpc-code="productCode"
          :product="product"
          :fetching="productFetching"
          @onUpdateImages="updateImages"
        />
      </a-tab-pane>
    </a-tabs>

    <div class="drawer-footer">
      <div style="display: flex; align-items: center; gap: 4px">
        {{ $t("productQualityMark.GOOD") }}:
        <a-switch
          v-model="isProductGood"
          :disabled="!mpcCode"
          :loading="saveProcessing"
          @change="onQualityStatusChange"
        />
      </div>

      <div style="display: flex; align-items: center; gap: 4px">
        {{ $t("moderationTrustStatus.TRUSTED") }}:
        <a-switch
          v-model="isProductTrusted"
          :disabled="disableProductTrustChange"
          :loading="saveProcessing"
          @change="onTrustStatusChange"
        />
      </div>

      <a-button
        type="primary"
        :disabled="disableProductSave"
        :loading="hasProductCode ? saveProcessing : createProcessing"
        @click="() => (hasProductCode ? onUpdateProduct() : onCreateProduct())"
      >
        {{ hasProductCode ? $t("save") : $t("create") }}
      </a-button>

      <a-button
        v-if="hasProductCode"
        :disabled="!exportIsPossible || saveProcessing || attributesFetching"
        :loading="exportProcessing"
        :class="[exportIsPossible ? 'export-button' : '']"
        @click="handleExport"
      >
        {{ `${$t("save")} + ${$t("export")}` }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { computed, provide, ref, watch } from "vue"
import { notification, message, Modal } from "ant-design-vue"
import i18n from "@/i18n"

import EditProductCommonTab from "./_components/EditProductCommonTab.vue"
import EditProductAttributesTab from "./_components/EditProductAttributesTab/EditProductAttributesTab.vue"
import EditProductImagesTab from "./_components/EditProductImagesTab/EditProductImagesTab.vue"

import {
  createProduct,
  checkExportTask,
  exportProduct,
  fetchProductRu,
  fetchProductLocalized,
  updateProductLocalized,
  updateProductRu,
  fetchAttributesByType,
  notifyCreateProductError,
  approveProductTrustStatus,
  changeProductQualityMark
} from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { AVAILABLE_FOR_EXPORT_STATUSES, REJECTED_EXPORT_STATUSES } from "@/constants/moderation"
import { formatValuesForRequest } from "./_components/EditProductAttributesTab/_components/utils"

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  mpcCode: {
    type: String,
    default: ""
  },
  defaultTabPosition: {
    type: String,
    default: "common"
  },
  languageForDataFetch: {
    type: String,
    default: "ru"
  }
})
const emit = defineEmits(["onClose", "createProductSuccess", "updateProductSuccess"])

const productFormRef = ref()

const tabPosition = ref("common")

const productFetching = ref(false)
const attributesFetching = ref(false)
const productMainAttributesFetching = ref(false)

const saveProcessing = ref(false)
const createProcessing = ref(false)

const product = ref({})
const mappedAttributes = ref([])

const sortAttributesRequest = ref(false)
const productDataIsChanged = ref(false)
const productTypeAllAttributesFetching = ref(false)

const exportProcessing = ref(false)
const checkExportInterval = ref()

const isProductTrusted = ref(false)
const isProductGood = ref(false)

const disableProductSave = computed(
  () =>
    productFetching.value ||
    attributesFetching.value ||
    exportProcessing.value ||
    !productDataIsChanged.value
)
const productCode = computed(() => props.mpcCode || product.value?.code || "")
const hasProductCode = computed(() => Boolean(productCode.value?.length))
const hasProductType = computed(() => Boolean(product.value?.type))
const disableProductTrustChange = computed(() =>
  Boolean(product.value.trust === "TRUSTED" || !props.mpcCode)
)

const exportIsPossible = computed(
  () => AVAILABLE_FOR_EXPORT_STATUSES.includes(product.value?.status) || productDataIsChanged.value
)

const drawerTitle = computed(() => {
  if (!hasProductCode.value) return i18n.t("productCreating")
  return `${i18n.t("productEditing")}: ${product.value?.code} (${product.value?.status})`
})

const stopExportInterval = (interval = checkExportInterval.value) => {
  exportProcessing.value = false
  clearInterval(interval)
}

const onProductChanged = () => {
  productDataIsChanged.value = true
}

const updateImages = (images) => {
  product.value.images = [...images]
}

const onClose = () => {
  if (!productDataIsChanged.value) {
    clearData()
    return
  }

  Modal.confirm({
    title: hasProductCode.value
      ? i18n.t("productEditingWarning")
      : i18n.t("productCreatingWarning"),
    content: i18n.t("cancelEditingWarning"),
    okText: i18n.t("close"),
    cancelText: i18n.t("cancel"),
    onOk: clearData
  })
}

const clearData = () => {
  productDataIsChanged.value = false
  product.value = {}
  mappedAttributes.value = []
  tabPosition.value = "common"
  emit("onClose")
}

const handleExport = async () => {
  try {
    exportProcessing.value = true

    const updateProductResponse = await onUpdateProduct()
    if (!updateProductResponse) {
      exportProcessing.value = false
      return
    }

    const [updateProductResponseRu] = updateProductResponse

    if (REJECTED_EXPORT_STATUSES.includes(updateProductResponseRu.data.status)) {
      notification.error({
        message: `${i18n.t("exportError")} ${props.mpcCode}`
      })
      exportProcessing.value = false
      return
    }

    const { code } = updateProductResponseRu.data

    if (!code) {
      exportProcessing.value = false
      notification.error({
        message: i18n.t("exportError")
      })
      return
    }

    const { data } = await exportProduct(code)

    if (!data.task) {
      notification.success({ message: i18n.t("exportSuccess") })
      return
    }

    const params = { task_ids: data.task }

    handleCheckExportTasks({ params, response: { data } })
  } catch (error) {
    exportProcessing.value = false
    notifyResponseError({ error, message: `${i18n.t("exportError")} ${props.mpcCode}` })
  }
}

const handleCheckExportTasks = ({ params, response: exportResponse }) => {
  checkExportInterval.value = setInterval(async () => {
    try {
      const { data } = await checkExportTask(params)

      const { task_state, task_result } = data.message[0]

      if (task_state === "FAILURE") {
        notification.error({
          message: i18n.t("exportFailed")
        })

        stopExportInterval(checkExportInterval.value)
        return
      }

      if (task_state === "SUCCESS") {
        if (task_result?.errors.length) {
          notification.warning({
            message: i18n.t("exportSuccessWithErrors")
          })
        } else {
          notification.success({
            message: i18n.t("exportSuccess")
          })
        }

        stopExportInterval(checkExportInterval.value)
        emit("updateProductSuccess", { all: { code: productCode.value, status: "TRANSFERRED" } })
      }
    } catch (error) {
      stopExportInterval(checkExportInterval.value)

      notifyResponseError({
        message: `${i18n.t("errorInTask")} ${exportResponse.data.task}`,
        error: error?.response?.data?.message
          ? { response: { data: error.response.data.message } }
          : error
      })
    }
  }, 1000)
}

// Update attribute
const handleUpdateMappedAttributes = (attribute) => {
  const attrIndex = mappedAttributes.value.findIndex(({ code }) => code === attribute.code)
  if (attrIndex < 0) return
  mappedAttributes.value[attrIndex] = { ...mappedAttributes.value[attrIndex], ...attribute }
}

const handleSortAttributes = async () => {
  sortAttributesRequest.value = true

  try {
    const queryParams = { order_by: "order" }

    const { data } = await fetchAttributesByType({
      code: product.value.type.code,
      queryParams
    })

    const sortedAttributes = data.results
      .map(({ code }) => mappedAttributes.value.find((item) => item.code === code) || undefined)
      .filter(Boolean)

    mappedAttributes.value = [
      ...sortedAttributes,
      ...mappedAttributes.value.filter(
        ({ code }) => sortedAttributes.findIndex((item) => item.code === code) === -1
      )
    ]

    sortAttributesRequest.value = false
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadAttributesByTypeError") })
    sortAttributesRequest.value = false
  }
}

const handleAddNewAttribute = ({ attr, order }) => {
  const { default_unit, ...fields } = attr

  const newItem = {
    ...fields,
    unit: default_unit || null
  }

  mappedAttributes.value.splice(+order - 1, 0, newItem)
}

const handleDeleteAttribute = (index) => {
  mappedAttributes.value = mappedAttributes.value.filter((_, itemIndex) => itemIndex !== index)
}

const handleDeleteEmptyAttributes = () => {
  mappedAttributes.value = mappedAttributes.value.filter(({ value, type }) => {
    if (type === "URL") return true
    if (typeof value?.value === "boolean") return true

    return value?.value || value?.length
  })
}

const onUpdateProduct = async () => {
  const emptyAttrs = mappedAttributes.value.filter(({ value, type }) => {
    if (type === "URL") return false
    if (typeof value?.value === "boolean") return false
    if (type === "NUMERIC") return !value?.value?.toString()

    return !value?.value && !value?.length
  })

  if (emptyAttrs.length) {
    return notification.warning({
      message: i18n.t("fillAttributesValueError")
    })
  }

  try {
    const [formRu, formUk] = await productFormRef.value.getLocalizedFormValues()

    return handleUpdateProduct(formRu, formUk)
  } catch {
    return null
  }
}

const handleUpdateProduct = async (formRu, formUk) => {
  try {
    saveProcessing.value = true

    const { category, type, ...form } = formRu

    const attributes = formatValuesForRequest(mappedAttributes.value)

    const product = {
      ...form,
      type: type || null,
      attributes,
      synthetic_meta: { category }
    }

    const data = {
      code: productCode.value,
      product
    }

    if (!data.code) {
      notification.error({ message: i18n.t("notUpdated") })
      return
    }

    const response = await Promise.all([
      await updateProductRu(data),
      await updateProductLocalized({ code: data.code, product: formUk })
    ])

    const [{ data: productRu }, { data: productUk }] = response

    product.value = {
      ...productRu,
      public_title: {
        ru: productRu.public_title,
        uk: productUk.public_title
      },
      description: {
        ru: productRu.description,
        uk: productUk.description
      }
    }
    notification.success({ message: i18n.t("updated") })
    productDataIsChanged.value = false
    emit("updateProductSuccess", { ru: productRu, uk: productUk })
    return response
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("notUpdated") })
    return null
  } finally {
    saveProcessing.value = false
  }
}

const onCreateProduct = async () => {
  try {
    const [formRu, formUk] = await productFormRef.value.getLocalizedFormValues()

    handleCreateProduct(formRu, formUk)
  } catch {}
}

const handleCreateProduct = async (formRu, formUk) => {
  try {
    createProcessing.value = true
    const { category, ...form } = formRu

    const { data } = await createProduct({ ...form, synthetic_meta: { category } })

    await updateProductLocalized({ code: data.code, product: formUk })

    getProduct(data.code)

    notification.success({ message: i18n.t("createProductSuccess") })
    emit("createProductSuccess", data)
  } catch (e) {
    notifyCreateProductError(e)
  } finally {
    createProcessing.value = false
  }
}

const getProduct = async (code) => {
  productFetching.value = true

  try {
    const productData = await getProductInfo(code)

    product.value = productData
    productDataIsChanged.value = false

    if (productData.trust === "TRUSTED") {
      isProductTrusted.value = true
    } else {
      isProductTrusted.value = false
    }

    if (productData.quality_mark === "GOOD") {
      isProductGood.value = true
    } else {
      isProductGood.value = false
    }

    if (!productData?.attributes?.length) return
    mappedAttributes.value = productData.attributes
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("productLoadError") })
  } finally {
    productFetching.value = false
  }
}

const getProductMainAttributes = async () => {
  if (!product.value?.type?.code) return

  try {
    productMainAttributesFetching.value = true
    const queryParams = {
      order_by: "order",
      is_main: true
    }

    const { data } = await fetchAttributesByType({
      code: product.value.type.code,
      queryParams
    })

    if (!data.results.length) {
      message.info(i18n.t("mainAttributesIsEmpty"))
      return
    }

    const mainAttrs = data.results.map(({ default_unit, ...attr }) => {
      const existedAttribute = mappedAttributes.value.find((item) => attr.code === item.code)
      if (existedAttribute) return existedAttribute

      return {
        ...attr,
        unit: default_unit || {
          code: null,
          value: null
        },
        value: {
          code: null,
          value: null
        }
      }
    })

    mappedAttributes.value = [
      ...mainAttrs,
      ...mappedAttributes.value.filter(
        (item) => !mainAttrs.some((mainAttr) => mainAttr.code === item.code)
      )
    ]

    handleSortAttributes()
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadMainAttributesError") })
  } finally {
    productMainAttributesFetching.value = false
  }
}

const getAllProductTypeAttributes = async () => {
  if (!product.value?.type?.code) return

  try {
    productTypeAllAttributesFetching.value = true
    const queryParams = { order_by: "order" }

    const { data } = await fetchAttributesByType({
      code: product.value.type.code,
      queryParams
    })

    if (!data.results.length) {
      message.info(i18n.t("typeAttributesIsEmpty"))
      return
    }

    const typeAttrs = data.results.map(({ default_unit, ...attr }) => {
      const existedAttribute = mappedAttributes.value.find((item) => attr.code === item.code)
      if (existedAttribute) return existedAttribute

      return {
        ...attr,
        unit: default_unit || { code: null, name: null },
        value: {
          code: null,
          value: null
        }
      }
    })

    mappedAttributes.value = [
      ...typeAttrs,
      ...mappedAttributes.value.filter((item) => !typeAttrs.some(({ code }) => code === item.code))
    ]
    handleSortAttributes()
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadAttributesError") })
  } finally {
    productTypeAllAttributesFetching.value = false
  }
}

const onProductTypeChange = async (type) => {
  product.value.type = type
}

const getProductInfo = async (code) => {
  return new Promise(async (res, rej) => {
    try {
      const params = {
        code: code || props.mpcCode
      }

      const [results, resultsUk] = await Promise.all([
        fetchProductRu(params),
        fetchProductLocalized(params)
      ])

      const { public_title: publicTitleRu, description: descriptionRu } = results.data
      const { public_title: publicTitleUk, description: descriptionUk } = resultsUk.data

      res({
        ...results.data,
        public_title: {
          uk: publicTitleUk || "",
          ru: publicTitleRu || ""
        },
        description: {
          uk: descriptionUk || "",
          ru: descriptionRu || ""
        }
      })
    } catch (e) {
      rej(e)
    }
  })
}

const onTrustStatusChange = async (value) => {
  if (!value) return

  try {
    saveProcessing.value = true

    await approveProductTrustStatus({ code: props.mpcCode })

    product.value.trust = "TRUSTED"
    emit("updateProductSuccess", { all: { code: productCode.value, trust: product.value.trust } })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    saveProcessing.value = false
  }
}

const onQualityStatusChange = async (value) => {
  try {
    saveProcessing.value = true
    await changeProductQualityMark({ code: props.mpcCode, qualityMark: value ? "GOOD" : null })
    product.value.quality_mark = value ? "GOOD" : null
    emit("updateProductSuccess", {
      all: { code: productCode.value, quality_mark: product.value.quality_mark }
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    saveProcessing.value = false
  }
}

provide("languageForDataFetch", { value: "ru" })

watch(
  () => props.visible,
  (value) => {
    if (value) {
      tabPosition.value = props.defaultTabPosition
    } else {
      isProductTrusted.value = false
      isProductGood.value = false
      tabPosition.value = "common"
      productFormRef.value?.onResetForm()
      stopExportInterval(checkExportInterval.value)
    }
  }
)

watch(
  () => props.mpcCode,
  (value) => {
    if (value.length > 0) {
      getProduct(value)
    }
  }
)
</script>

<style lang="scss" scoped>
.export-button {
  background-color: $green-color;
  color: $background-color;
}
</style>
