<template>
  <span ref="mainInput">
    <input
      ref="editableCell"
      type="text"
      v-model="myVal"
      @keyup.enter="(e) => submitEditCell(field, e.target.value, rowIndex)"
      @keyup.esc="cancelEditCell"
      @input="onInput"
    />
    <ValidationErrors
      :lengthError="showError"
      :emptyError="showEmptyError"
      :uniqueError="showUniqueError"
    />
  </span>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validEmpty from "utils/validations/validEmpty"
import validMaxLength from "utils/validations/validMaxLength"

export default {
  name: "InputEditValue",
  components: {
    ValidationErrors
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onUpdate: Function,
    accept_lang: String
  },
  data() {
    return {
      showError: false,
      showEmptyError: false,
      showUniqueError: false,
      sendObject: {},
      myVal: this.row[this.field],
      initialValue: this.row[this.field],
      timeout: null
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
    this.closeOnBlurEvent()
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_valuesData: "PV_valuesData",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage"
    })
  },
  methods: {
    onInput(event) {
      let value = event.target.value

      this.showError = !validMaxLength(value, 50)
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        this.showEmptyError = !validEmpty(value)
        this.validateUnique({ value, lang: this.accept_lang })
      }
    },
    validateUnique({ value, lang }) {
      let uniqueError = false

      let duplicate = this.PV_valuesData.items.find((item) => {
        return item[`value_${lang}`] === value
      })

      if (duplicate) {
        uniqueError = true
      }
      this.showUniqueError = uniqueError
    },
    submitEditCell(field, value, rowIndex) {
      if (this.showError || this.showUniqueError || this.showEmptyError) return

      this.submitName(field, value, rowIndex)

      this.onUpdate(this.sendObject)
      this.close()
    },
    submitName(field, value, rowIndex) {
      this.sendObject = {
        columnKey: field,
        newValue: value,
        rowIndex,
        accept_lang: this.accept_lang
      }
    },
    cancelEditCell() {
      this.close()
    },
    closeOnBlurEvent() {
      let context = this
      this.$refs.editableCell.addEventListener("blur", context.close, false)
    },
    unsubscribeOnBlur() {
      let context = this
      this.$refs.editableCell.removeEventListener("blur", context.close, false)
    }
  }
}
</script>
