<template>
  <a-drawer
    :visible="showModal"
    :title="$t('transferBonuses')"
    :width="700"
    @close="$emit('close')"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('enterSum')"
        prop="amount"
      >
        <a-input-number
          v-model="form.amount"
          :min="1"
          :precision="0"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('recipientSearch')"
        prop="receiver"
      >
        <a-auto-complete
          v-model="form.receiver"
          showSearch
          :dataSource="fetchedReceivers"
          :placeholder="$t('phoneNumber')"
          @select="(e) => handleChooseUser(e)"
          @search="(e) => handleCustomersSearch(e)"
        >
          <a-input>
            <template #suffix>
              <a-icon
                v-show="fetchingReceivers"
                type="loading"
              />
            </template>
          </a-input>
        </a-auto-complete>
      </a-form-model-item>

      <a-descriptions
        v-if="currentChoosedUser"
        :column="1"
        bordered
        size="small"
        :title="$t('recipient')"
      >
        <a-descriptions-item label="Username">
          {{ currentChoosedUser.username }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('firstName')">
          {{ currentChoosedUser.first_name }} {{ currentChoosedUser.middle_name }}
          {{ currentChoosedUser.last_name }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('phoneNumber')">
          {{ currentChoosedUser.mobile_phone }}
        </a-descriptions-item>

        <a-descriptions-item label="UUID">
          {{ currentChoosedUser.uuid }}
        </a-descriptions-item>
      </a-descriptions>

      <a-form-model-item
        :label="$t('comment')"
        prop="comment_sender"
      >
        <a-textarea
          v-model="form.comment_sender"
          :autoSize="{ minRows: 4, maxRows: 10 }"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="$emit('close')"
        >{{ $t("cancel") }}</a-button
      >
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
        >{{ $t("apply") }}</a-button
      >
    </div>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, computed, watch } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import { transactBonus, searchCustomers } from "@/modules/MPAdmin/services/bonusesService"
import notifyResponseError from "@/utils/notifyResponseError"
import useCancellableRequest from "@/composables/useCancellableRequest"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["handleRefreshData", "close"])

const { makeRequest } = useCancellableRequest(searchCustomers)

const formRef = ref()
const form = reactive({
  amount: 1,
  receiver: undefined,
  comment_sender: ""
})
const fetchedReceivers = ref([])
const fetchingReceivers = ref(false)
const isFetching = ref(false)
const currentChoosedUser = ref(null)

const requiredRule = computed(() => {
  return [{ required: true, message: i18n.t("emptyError") }]
})

const formRules = computed(() => ({
  amount: requiredRule.value,
  receiver: requiredRule.value,
  comment_sender: [
    ...requiredRule.value,
    { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }
  ]
}))

const onSave = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  isFetching.value = true

  try {
    await transactBonus(form)

    notification.success({
      message: `${form.amount} ${i18n.tc("someBonusesTransfered", form.amount)}`
    })
    handleChooseUser(null)

    emit("handleRefreshData")
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleCustomersSearch = async (receiver) => {
  fetchingReceivers.value = true

  try {
    const { data } = await makeRequest({ params: { receiver } })

    fetchedReceivers.value = data.results.map((user) => getNormalizedUser(user))
  } catch (error) {
    if (error.message === "canceled") return

    notifyResponseError({ error })
  } finally {
    fetchingReceivers.value = false
  }
}

const handleChooseUser = (uuid) => {
  currentChoosedUser.value = uuid
    ? fetchedReceivers.value.find((user) => user.info.uuid === uuid).info
    : null
}

const getNormalizedUser = (user) => {
  return {
    value: user.uuid,
    text: `${user.mobile_phone} ${
      user.username ? `(${user.username} / ` : ""
    }${`${user.first_name} ${user.middle_name} ${user.last_name})`} ${user.uuid}`,
    info: user
  }
}

watch(
  () => props.showModal,
  () => {
    if (!formRef.value) return
    formRef.value.resetFields()
  }
)
</script>
