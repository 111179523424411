<template>
  <div
    class="messages-display messages"
    :class="{ 'messages-display--empty-state': !chat }"
  >
    <ul v-if="chat">
      <a-spin
        v-if="topMessagesLoader"
        class="messages__top-loader"
      />
      <li
        class="message"
        v-for="message in chat.messages"
        :key="message.date"
      >
        <!-- **************** SYSTEM MESSAGE **************** -->
        <template v-if="message.type && message.type === 'PAGE_SOURCE'">
          <div class="message__product-info-wrap">
            <div class="message__hr"></div>
            <div class="message__product-info">
              <div class="product-info__details">
                <div class="product-info__code">
                  {{ $t("chatOpenedFromProduct") }}:
                  <a
                    v-if="hasCorrectOfferCode(parseJSONBody(message))"
                    :href="offerLink(parseJSONBody(message))"
                    target="_blank"
                    >{{ parseJSONBody(message).code }}</a
                  >
                  <a
                    v-else
                    :href="productLink(message)"
                    target="_blank"
                    >{{ parseJSONBody(message).p_code }}</a
                  >
                </div>
                <div class="product-info__product-name">{{ parseJSONBody(message).name }}</div>
              </div>
              <div class="product-info__image">
                <img
                  :src="getImgUrl(parseJSONBody(message).image, '200x200')"
                  @click="openGallery([parseJSONBody(message).image])"
                />
              </div>
            </div>
          </div>
        </template>
        <!-- **************** SIMPLE MESSAGE **************** -->
        <template
          v-if="message.type && (message.type === 'SIMPLE' || message.type === 'WITH_ATTACHMENTS')"
        >
          <MessageItem
            :message="message"
            :isSelf="checkSelf(message)"
            :userAvatarAbsolutePath="getAvaSrc(message.author.logo, message.author.gender)"
            :username="message.author.first_name || message.author.username"
            :createdAt="formatDateAndTime(message.created_at)"
            :mediaContent="getMediaContentArray(message.body)"
            :isRemoved="message.is_removed || false"
            :isEdited="message.is_edited || false"
            :messageBody="message.body || ''"
            :chatUuid="chat.uuid"
            :moderatorSearchComponent="moderatorSearchComponent"
            @showEditHistory="$emit('showEditHistory', $event)"
            @loadImage="(img) => forceDownload(img, 'newImage')"
            @loadFile="(file) => forceDownload(file, 'newFile')"
            @remove="handleRemove"
            @edit="$emit('edit', $event)"
          />
        </template>
      </li>
      <HintMessage v-if="showHint" />
      <a-spin
        v-if="bottomMessagesLoader && !topMessagesLoader && chat.messages.length === 0"
        class="messages__bottom-loader"
      />
    </ul>
    <template
      v-if="chats.length === 0"
      class="chat__loader"
    >
      {{ $t("noAvaliableChats") }}
    </template>
    <template
      v-else-if="!chat"
      class="chat__loader"
    >
      {{ $t("selectChatFromList") }}
    </template>
    <RemoveConfirmationModal
      :visible="showRemoveConfirmation"
      @ok="removeConfirmed"
      @cancel="cancelRemove"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import moment from "moment"
import forceDownloadMixin from "../../mixins/forceDownloadMixin"
import imagePathMixin from "../../mixins/imagePathMixin"
import HintMessage from "../CommonComponents/HintMessage"
import MessageItem from "./MessageItem"
import RemoveConfirmationModal from "./RemoveConfirmationModal"

export default {
  mixins: [forceDownloadMixin, imagePathMixin],
  components: {
    HintMessage,
    MessageItem,
    RemoveConfirmationModal
  },
  props: {
    moderatorSearchComponent: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      scrollHeightBeforeGetPagination: null,
      newMessageUnsubscribe: null,
      olderMessagesUnsubscribe: null,
      showRemoveConfirmation: false,
      removeMessageUuid: "",
      removeMessageChatUuid: ""
    }
  },
  computed: {
    ...mapState("spvMessenger", {
      user: (state) => state.user,
      chats: (state) => state.chats,
      currentChatId: (state) => state.currentChatId,
      messagesLimit: (state) => state.messagesLimit,
      bottomMessagesLoader: (state) => state.bottomMessagesLoader,
      topMessagesLoader: (state) => state.topMessagesLoader,
      showHint: (state) => state.showHint,
      mpUrl: (state) => state.mpUrl
    }),
    ...mapGetters("spvMessenger", ["chat"])
  },
  methods: {
    checkSelf(message) {
      if (!this.moderatorSearchComponent) {
        return message.self
      } else if (this.moderatorSearchComponent) {
        const lastMessageIndex = this.chat.messages.length - 1
        const lastMessage = this.chat.messages[lastMessageIndex]

        return message.author.uuid === lastMessage.author.uuid
      }
    },
    hasCorrectOfferCode(productInfo) {
      const offerCode = productInfo.code
      const productCode = productInfo.p_code
      /**
       * Previously, there was a bug in which the product code
       * was written in the offer code field
       */
      const offerCodeDoesNotMatchProductCode = offerCode !== productCode

      return offerCode && offerCodeDoesNotMatchProductCode
    },
    getMediaContentArray(str) {
      if (str === null) return []

      const pCodeTest = /\b(p|P)0[a-zA-Z0-9]{6}\b/gi
      const linkTest =
        /((http|https):\/\/)?(www.)?([a-z0-9-]+\.)+[a-z]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
      const links = str.match(linkTest)
      const pCodes = str.match(pCodeTest)
      let results = []

      if (!pCodes && !links) return results

      if (links) {
        let lastIndex = 0

        links.forEach((link) => {
          const linkIndex = str.indexOf(link, lastIndex)

          results.push({
            type: "link",
            startIndex: linkIndex,
            endIndex: linkIndex + link.length,
            result: link
          })

          lastIndex = linkIndex + link.length
        })
      }

      if (pCodes) {
        let lastIndex = 0

        pCodes.forEach((link) => {
          const linkIndex = str.slice(lastIndex).match(new RegExp(`\\b${link}\\b`, "i")).index

          results.push({
            type: "pCode",
            startIndex: linkIndex + lastIndex,
            endIndex: linkIndex + lastIndex + link.length,
            result: link
          })

          lastIndex = linkIndex + link.length
        })
      }

      results.sort((a, b) => a.startIndex - b.startIndex)

      let skipNextCodes = 0

      results = results.filter((result) => {
        if (result.type === "link") {
          const matchAll = result.result.match(this.pCodeTest)

          skipNextCodes = matchAll ? matchAll.length : 0
        }

        if (result.type === "pCode" && skipNextCodes) {
          skipNextCodes -= 1

          return false
        }

        return true
      })

      const mediaContent = []

      results.forEach((item, i, arr) => {
        // Adding string before first media content
        if (i === 0 && item.startIndex !== 0) {
          mediaContent.push({
            type: "string",
            body: str.slice(0, item.startIndex)
          })
        }

        // Adding string between previous media content
        if (i !== 0 && arr[i - 1].endIndex !== item.startIndex) {
          mediaContent.push({
            type: "string",
            body: str.slice(arr[i - 1].endIndex, item.startIndex)
          })
        }

        // Adding media content
        mediaContent.push({
          type: item.type,
          body: item.result
        })

        // Adding string after last media content
        if (arr.length - 1 === i && item.endIndex !== str.length) {
          mediaContent.push({
            type: "string",
            body: str.slice(item.endIndex, str.length)
          })
        }
      })

      return mediaContent
    },
    resetData() {
      this.scrollHeightBeforeGetPagination = null
      this.newMessageUnsubscribe = null
      this.olderMessagesUnsubscribe = null
    },
    scrollToBottom() {
      this.$nextTick().then(() => {
        const element = this.$el

        if (element) {
          element.scrollTop = element.scrollHeight - element.clientHeight
        }
      })
    },
    openGallery(galleryOptions) {
      this.$root.$emit("showMessengerGallery", galleryOptions)
    },
    handleRemove(messageUuid) {
      this.showRemoveConfirmation = true
      this.removeMessageUuid = messageUuid
      this.removeMessageChatUuid = this.currentChatId
    },
    removeConfirmed() {
      this.REMOVE_MESSAGE({
        chatUuid: this.removeMessageChatUuid,
        messageUuid: this.removeMessageUuid
      })
      this.cancelRemove()
    },
    cancelRemove() {
      this.showRemoveConfirmation = false
      this.removeMessageUuid = ""
      this.removeMessageChatUuid = ""
    },
    isScrolledToBottom() {
      const { scrollTop, clientHeight, scrollHeight } = this.$el
      const scrollBottom = scrollHeight - (scrollTop + clientHeight)

      return scrollBottom === 0
    },
    subscribeForNewMessage() {
      this.newMessageUnsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "spvMessenger/ADD_NEW_MESSAGE") {
          const isItMessageFromCurrentChat =
            mutation.payload.message.chat_uuid === this.currentChatId
          const isScrolledToBottomBeforeMessageRender = this.isScrolledToBottom()
          const isItMySelfMessage = this.checkSelf(mutation.payload.message)

          if (
            (isItMessageFromCurrentChat && isScrolledToBottomBeforeMessageRender) ||
            isItMySelfMessage
          )
            this.scrollToBottom()
        }
      })
    },
    subscribeForOlderMessages() {
      this.olderMessagesUnsubscribe = this.$store.subscribe(async (mutation) => {
        if (mutation.type === "spvMessenger/SET_MESSAGES") {
          await this.$nextTick()
          await this.$nextTick()

          this.setPreviousScrollAfterGetOldMessages()
        }
      })
    },
    setPreviousScrollAfterGetOldMessages() {
      if (this.scrollHeightBeforeGetPagination === null || !this.$el) return

      const { scrollHeight } = this.$el

      if (scrollHeight > this.scrollHeightBeforeGetPagination) {
        this.$el.scrollTop = scrollHeight - this.scrollHeightBeforeGetPagination
      }
    },
    scrollHandler() {
      const { scrollTop, clientHeight, scrollHeight } = this.$el

      const isScrolledToTop = scrollTop === 0
      const hasScroll = scrollHeight !== clientHeight

      if (isScrolledToTop && hasScroll) {
        this.getNextPage()
      }
    },
    getNextPage() {
      const { scrollHeight } = this.$el

      this.scrollHeightBeforeGetPagination = scrollHeight

      this.TOP_MESSAGES_LOADER(true)

      const payload = {
        chatUuid: this.currentChatId,
        offset: this.chat.offset + this.messagesLimit
      }

      if (this.moderatorSearchComponent) {
        this.GET_CHAT_MESSAGES_BY_XHR(payload)
      } else {
        this.GET_CHAT_MESSAGES(payload)
      }
    },
    formatDateAndTime(date) {
      const formattedDate = moment(date).locale(this.$i18n.locale)
      const isToday = formattedDate.isSame(new Date(), "day")
      const isYesterday = formattedDate.isSame(moment().subtract(1, "day"), "day")
      const isSomeYear = formattedDate.isSame(new Date(), "year")

      if (isToday) {
        return formattedDate.format("LT")
      } else if (isYesterday) {
        return formattedDate.calendar()
      } else if (isSomeYear) {
        const datePattern = "DD MMM, LT"

        return formattedDate.format(datePattern)
      } else {
        return formattedDate.format("L, LT")
      }
    },
    parseJSONBody(message) {
      return JSON.parse(message.body)
    },
    productLink(message) {
      return `${this.mpUrl}/product/${this.parseJSONBody(message).slug}/${
        this.parseJSONBody(message).p_code
      }`
    },
    offerLink(productInfo) {
      return `${this.mpUrl}/product/${productInfo.slug}/${productInfo.p_code}/${productInfo.code}`
    },
    ...mapMutations("spvMessenger", ["TOP_MESSAGES_LOADER"]),
    ...mapActions("spvMessenger", [
      "GET_CHAT_MESSAGES",
      "GET_CHAT_MESSAGES_BY_XHR",
      "REMOVE_MESSAGE"
    ])
  },
  watch: {
    currentChatId() {
      this.resetData()
      this.scrollToBottom()
    },
    bottomMessagesLoader(bool) {
      if (!bool) {
        this.scrollToBottom()
      }
    }
  },
  mounted() {
    this.subscribeForNewMessage()
    this.subscribeForOlderMessages()

    if (this.chat && this.chat.messages.length > 0) {
      this.scrollToBottom()
    }

    this.$el.addEventListener("scroll", this.scrollHandler)
    this.$root.$on("messenger-hint", this.scrollToBottom)
  },
  beforeDestroy() {
    if (this.newMessageUnsubscribe) this.newMessageUnsubscribe()
    if (this.olderMessagesUnsubscribe) this.olderMessagesUnsubscribe()

    this.$el.removeEventListener("scroll", this.scrollHandler)
    this.$root.$off("messenger-hint", this.scrollToBottom)
  }
}
</script>

<style>
pre {
  white-space: pre-wrap;
  font-size: 14px;
  font-style: normal;
  display: inline;
  font-family: "Roboto";
}
</style>
