import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const productBrandAutocomplete = {
  state: {
    PC_selectedProductBrand: false,
    PC_productBrandsAutocomplete: [],
    PC_productBrandObjects: [],
    PC_setProductBrandPlaceholder: ""
  },

  getters: {
    PC_selectedProductBrand: (state) => state.PC_selectedProductBrand,
    PC_productBrandsAutocomplete: (state) => state.PC_productBrandsAutocomplete,
    PC_productBrandObjects: (state) => state.PC_productBrandObjects,
    PC_productBrandPlaceholder: (state) => state.PC_setProductBrandPlaceholder
  },

  mutations: {
    PC_setSelectedProductBrand: (state, selectedBrand) => {
      state.PC_selectedProductBrand = selectedBrand
    },
    PC_setProductBrandsAutocomplete: (state, { brandsArray }) => {
      state.PC_productBrandsAutocomplete = brandsArray
    },
    PC_setProductBrandObjects: (state, { brands }) => {
      state.PC_productBrandObjects = brands
    },
    PC_setProductBrandPlaceholder: (state, placeholder) => {
      state.PC_setProductBrandPlaceholder = placeholder
    },
    PC_resetBrandAutocompleteValues: (state) => {
      state.PC_selectedProductBrand = false
      state.PC_productBrandsAutocomplete = []
      state.PC_productBrandObjects = []
      state.PC_setProductBrandPlaceholder = ""
    },
    PC_selectedProductBrand(state, value) {
      state.PC_selectedProductBrand = value
    }
  },

  actions: {
    PC_fetchProductBrandAutocomplete({ commit, getters }, value) {
      let searchData = buildMagicSearch({
        resource: "PBD",
        field: "name",
        value,
        sort: null,
        limit: 20,
        offset: 0
      })
      API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((brands) => {
          commit("PC_setProductBrandObjects", { brands })
          let brandsArray = brands.map((item) => {
            return item.name
          })
          commit("PC_setProductBrandsAutocomplete", { brandsArray })
        })
        .catch((e) => {
          commit("handleError", e)
        })
    },
    PC_processSelectedProductBrand({ commit, getters }, value) {
      let selectedBrand = getters.PC_productBrandObjects.find((item) => {
        return item.name === value
      })
      commit("PC_setSelectedProductBrand", selectedBrand)
      commit("PC_setEditableProductBrand", selectedBrand)
    },
    PC_setProductBrandPlaceholder({ commit, getters }, placeholder) {
      if (placeholder === "noBrand") {
        commit("PC_setProductBrandPlaceholder", " ") // reset watcher
        setTimeout(() => {
          commit("PC_setProductBrandPlaceholder", "")
        }, 0)
      } else {
        commit("PC_setProductBrandPlaceholder", placeholder)
      }
    }
  }
}

export default productBrandAutocomplete
