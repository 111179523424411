<template>
  <div>
    <PageHeader title="">
      <template #filters>
        <a-select
          v-model="filteredInfo.is_active"
          :placeholder="$t('status')"
          allowClear
          @change="handleTableFilterChange()"
        >
          <a-select-option
            key="true"
            value="true"
          >
            {{ $t("active") }}
          </a-select-option>
          <a-select-option
            key="false"
            value="false"
          >
            {{ $t("inactive") }}
          </a-select-option>
        </a-select>
      </template>

      <template #actions>
        <a-button
          type="primary"
          icon="plus"
          @click="openTariffModal()"
        >
          {{ $t("add") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      rowKey="id"
      :pagination="paginationInfo"
      size="middle"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #activeRenderer="value">
        <a-icon
          :type="value ? 'check-circle' : 'close-circle'"
          class="tariff-icon"
          :class="[value ? 'tariff-icon--green' : 'tariff-icon--red']"
        />
      </template>

      <template #descriptionRenderer="value">
        <div v-if="value">
          <div
            v-if="typeof value[getCurrentLocale()] === 'string'"
            class="clamped-text"
          >
            {{ value[getCurrentLocale()] }}
          </div>
          <div
            v-else
            class="clamped-text"
          >
            {{ value }}
          </div>
        </div>
      </template>

      <template #configRenderer="values">
        <Configs
          :configs="values"
          style="width: calc(100% - 50px)"
        />
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="edit"
          @click="openTariffModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showModal"
      :title="prototype?.id ? $t('tariffPlanSettings') : $t('addTariffPlan')"
      :width="1000"
      destroyOnClose
      @close="closeTariffModal()"
    >
      <CreateEditBillingModal
        :prototype="prototype"
        @createTariff="onTariffCreated"
        @updateTariff="onTariffUpdated"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import CreateEditBillingModal from "./components/CreateEditBillingModal.vue"
import Configs from "./components/Configs.vue"
import PageHeader from "@/ant-components/PageHeader"

import getCurrentLocale from "@/utils/getCurrentLocale.js"
import { fetchBillingTariffs } from "@/modules/MPAdmin/services/generalSettingService/billingTariffsService.js"

import useTariffsColumns from "./useTariffsColumns"
import useAntTableQuery from "@/composables/useAntTableQuery"

const { tariffsListColumns: columns } = useTariffsColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchBillingTariffs
})

const prototype = ref()
const showModal = ref(false)

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value })
}

const openTariffModal = (tariff) => {
  prototype.value = tariff
  showModal.value = true
}

const closeTariffModal = () => {
  showModal.value = false
  prototype.value = undefined
}

const onTariffCreated = () => {
  closeTariffModal()
  onTableChange()
}

const onTariffUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeTariffModal()
}

onMounted(() => {
  setupTable({ defaultPagination: { size: "default" } })
  onTableChange()
})
</script>

<style lang="scss" scoped>
.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: help;
}
.tariff-icon {
  width: 100%;
  font-size: 20px;

  &--green {
    color: $green-color;
  }

  &--red {
    color: $red-color;
  }
}
</style>
