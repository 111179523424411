var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tBody",staticClass:"tBody",class:`tBody-${_vm.tableId}`,style:({ height: _vm.height })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editableCell.rowIndex !== null),expression:"editableCell.rowIndex !== null"}],ref:"fullContent",staticClass:"tBody__fullContent",on:{"dblclick":function($event){_vm.editableCellBuffer.canEdit
        ? _vm.editCell(_vm.editableCellBuffer.rowIndex, _vm.editableCellBuffer.columnKey)
        : () => false},"mouseleave":_vm.hideFullContent}}),(_vm.items.length === 0 && _vm.fetching)?_c('div',{staticClass:"tBody__loader"},[_c('a-spin',{attrs:{"size":"large"}})],1):[(_vm.ifExistFixedColumns)?[_c('draggable',{attrs:{"handle":".row-move"},model:{value:(_vm.sortedItems),callback:function ($$v) {_vm.sortedItems=$$v},expression:"sortedItems"}},_vm._l((_vm.items),function(rowItem,rowIndex){return _c('div',{key:`${_vm.tableId}-tr-${rowIndex}`,staticClass:"tBody__tr",class:[
            {
              'tBody__tr--disabled': _vm.rowDisabled(rowItem, rowIndex),
              'tBody__tr--selected': _vm.isSelectedRow(rowItem, rowIndex),
              'tBody__tr--withFixedColumns': _vm.ifExistFixedColumns
            },
            ..._vm.getTrClasses(rowItem, rowIndex)
          ],style:(_vm.trStyle)},[_c('div',{ref:"trLeft",refInFor:true,staticClass:"tBody__tr-left"},[_c('RowActions',{ref:"rowActionsFixedColumns",refInFor:true,attrs:{"row":rowItem,"actions":_vm.getRowActions(rowItem, rowIndex),"rowIndex":rowIndex,"disabled":_vm.rowDisabled(rowItem, rowIndex)}}),(_vm.sortRowsEnabled)?_c('div',{key:`${_vm.tableId}-td-${rowIndex}.cellSort`,staticClass:"tBody__td tBody__td--sort",class:[
                {
                  'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex)
                }
              ],style:({
                'min-width': _vm.getMinTdWidth(32),
                'max-width': _vm.getMaxTdWidth(32)
              })},[_c('div',{staticClass:"tBody__td-value"},[_c('div',{staticClass:"row-move"},[_c('a-icon',{attrs:{"type":"menu"}})],1)])]):_vm._e(),(_vm.checkRowEnabled)?_c('div',{key:`${_vm.tableId}-td-${rowIndex}.cellCheckbox`,staticClass:"tBody__td tBody__td--sort",class:[
                {
                  'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex)
                }
              ],style:({
                'min-width': _vm.getMinTdWidth(32),
                'max-width': _vm.getMaxTdWidth(32)
              })},[_c('div',{staticClass:"tBody__td-value"},[_c('CheckRow',{attrs:{"disabled":_vm.rowDisabled(rowItem, rowIndex),"onChange":(checked) => _vm.onCheckRow(checked, rowItem),"checked":_vm.isChecked(rowItem[_vm.rowKey]),"data":rowItem}})],1)]):_vm._e(),(_vm.showRowNumber)?_c('div',{key:`${_vm.tableId}-td-${rowIndex}.cellNumber`,staticClass:"tBody__td tBody__td--sort",class:[
                {
                  'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex)
                }
              ],style:({
                'min-width': _vm.getMinTdWidth(40),
                'max-width': _vm.getMaxTdWidth(40)
              })},[_c('div',{staticClass:"tBody__td-value"},[_vm._v(" "+_vm._s(rowIndex + 1)+" ")])]):_vm._e(),_vm._l((_vm.fixedColumns),function(column,columnIndex){return _c('div',{key:`${_vm.tableId}-td-${rowIndex}.${columnIndex}`,ref:"td",refInFor:true,staticClass:"tBody__td",class:[
                {
                  'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex),
                  'tBody__td--editable': !!column.editRenderer,
                  'tBody__td--selectable': !!column.selectable
                },
                ..._vm.getTdClasses(rowItem, columnIndex, rowIndex)
              ],style:({
                'min-width': _vm.getMinTdWidth(column.width, column.minWidth),
                'max-width': _vm.getMaxTdWidth(column.width, column.maxWidth)
              }),attrs:{"data-rowIndex":rowIndex,"data-colIndex":columnIndex},on:{"dblclick":function($event){_vm.cellCanEdit(column, rowItem) && !_vm.rowDisabled(rowItem, rowIndex)
                  ? _vm.editCell(rowIndex, column.key)
                  : () => false}}},[_c('div',{staticClass:"tBody__td-value"},[(
                    _vm.editableCell.columnKey === column.key && _vm.editableCell.rowIndex === rowIndex
                  )?[(!!column.editRenderer)?_c(column.editRenderer,_vm._b({tag:"component",attrs:{"row":rowItem,"rowIndex":rowIndex,"columnIndex":columnIndex,"field":column.key,"value":rowItem[column.key],"id":`${column.key}_${rowIndex}_cellEdit`,"disabled":_vm.rowDisabled(rowItem, rowIndex),"close":_vm.cancelEditCell}},'component',column.editRendererProps,false)):_vm._e()]:[(!column.renderer)?[_vm._v(" "+_vm._s(rowItem[column.key])+" ")]:[(!!column.renderer)?_c(column.renderer,_vm._b({tag:"component",attrs:{"row":rowItem,"rowIndex":rowIndex,"columnIndex":columnIndex,"field":column.key,"value":rowItem[column.key],"id":`${column.key}_${rowIndex}_cellCustom`,"disabled":_vm.rowDisabled(rowItem, rowIndex)}},'component',column.rendererProps,false)):_vm._e()]]],2)])})],2),_c('div',{ref:"trRight",refInFor:true,staticClass:"tBody__tr-right"},_vm._l((_vm.notFixedColumns),function(column,columnIndex){return _c('div',{key:`${_vm.tableId}-td-${rowIndex}.${columnIndex}`,ref:"td",refInFor:true,staticClass:"tBody__td",class:[
                {
                  'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex),
                  'tBody__td--editable': !!column.editRenderer,
                  'tBody__td--selectable': !!column.selectable
                },
                ..._vm.getTdClasses(rowItem, columnIndex, rowIndex)
              ],style:({
                'min-width': _vm.getMinTdWidth(column.width, column.minWidth),
                'max-width': _vm.getMaxTdWidth(column.width, column.maxWidth)
              }),attrs:{"data-rowIndex":rowIndex,"data-colIndex":columnIndex},on:{"dblclick":function($event){_vm.cellCanEdit(column, rowItem) && !_vm.rowDisabled(rowItem, rowIndex)
                  ? _vm.editCell(rowIndex, column.key)
                  : () => false}}},[_c('div',{staticClass:"tBody__td-value"},[(
                    _vm.editableCell.columnKey === column.key && _vm.editableCell.rowIndex === rowIndex
                  )?[(!!column.editRenderer)?_c(column.editRenderer,_vm._b({tag:"component",attrs:{"row":rowItem,"rowIndex":rowIndex,"columnIndex":columnIndex,"field":column.key,"value":rowItem[column.key],"id":`${column.key}_${rowIndex}_cellEdit`,"disabled":_vm.rowDisabled(rowItem, rowIndex),"close":_vm.cancelEditCell}},'component',column.editRendererProps,false)):_vm._e()]:[(!column.renderer)?[_vm._v(" "+_vm._s(rowItem[column.key])+" ")]:[(!!column.renderer)?_c(column.renderer,_vm._b({tag:"component",attrs:{"row":rowItem,"rowIndex":rowIndex,"columnIndex":columnIndex,"field":column.key,"value":rowItem[column.key],"id":`${column.key}_${rowIndex}_cellCustom`,"disabled":_vm.rowDisabled(rowItem, rowIndex)}},'component',column.rendererProps,false)):_vm._e()]]],2)])}),0)])}),0)]:[_c('draggable',{attrs:{"handle":".row-move"},model:{value:(_vm.sortedItems),callback:function ($$v) {_vm.sortedItems=$$v},expression:"sortedItems"}},_vm._l((_vm.items),function(rowItem,rowIndex){return _c('div',{key:`${_vm.tableId}-tr-${rowIndex}`,staticClass:"tBody__tr",class:[
            {
              'tBody__tr--disabled': _vm.rowDisabled(rowItem, rowIndex),
              'tBody__tr--selected': _vm.isSelectedRow(rowItem, rowIndex)
            },
            ..._vm.getTrClasses(rowItem, rowIndex)
          ],style:(_vm.trStyle)},[_c('RowActions',{ref:"rowActions",refInFor:true,attrs:{"row":rowItem,"actions":_vm.getRowActions(rowItem, rowIndex),"rowIndex":rowIndex,"disabled":_vm.rowDisabled(rowItem, rowIndex)}}),(_vm.sortRowsEnabled)?_c('div',{key:`${_vm.tableId}-td-${rowIndex}.cellSort`,staticClass:"tBody__td tBody__td--sort",class:[
              {
                'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex)
              }
            ],style:({
              'min-width': _vm.getMinTdWidth(32),
              'max-width': _vm.getMaxTdWidth(32)
            })},[_c('div',{staticClass:"tBody__td-value"},[_c('div',{staticClass:"row-move"},[_c('a-icon',{attrs:{"type":"menu"}})],1)])]):_vm._e(),(_vm.checkRowEnabled)?_c('div',{key:`${_vm.tableId}-td-${rowIndex}.cellCheckbox`,staticClass:"tBody__td tBody__td--sort",class:[
              {
                'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex)
              }
            ],style:({
              'min-width': _vm.getMinTdWidth(32),
              'max-width': _vm.getMaxTdWidth(32)
            })},[_c('div',{staticClass:"tBody__td-value"},[_c('CheckRow',{attrs:{"disabled":_vm.rowDisabled(rowItem, rowIndex),"onChange":(checked) => _vm.onCheckRow(checked, rowItem),"checked":_vm.isChecked(rowItem[_vm.rowKey]),"data":rowItem}})],1)]):_vm._e(),(_vm.showRowNumber)?_c('div',{key:`${_vm.tableId}-td-${rowIndex}.cellNumber`,staticClass:"tBody__td tBody__td--sort",class:[
              {
                'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex)
              }
            ],style:({
              'min-width': _vm.getMinTdWidth(40),
              'max-width': _vm.getMaxTdWidth(40)
            })},[(!_vm.fetching)?_c('div',{staticClass:"tBody__td-value"},[_vm._v(" "+_vm._s(_vm.getPage(rowIndex))+" ")]):_vm._e()]):_vm._e(),_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:`${_vm.tableId}-td-${rowIndex}.${columnIndex}`,ref:"td",refInFor:true,staticClass:"tBody__td",class:[
              {
                'tBody__td--disabled': _vm.rowDisabled(rowItem, rowIndex),
                'tBody__td--editable': !!column.editRenderer,
                'tBody__td--selectable': !!column.selectable
              },
              ..._vm.getTdClasses(rowItem, columnIndex, rowIndex)
            ],style:({
              'min-width': _vm.getMinTdWidth(column.width, column.minWidth),
              'max-width': _vm.getMaxTdWidth(column.width, column.maxWidth)
            }),attrs:{"data-rowIndex":rowIndex,"data-colIndex":columnIndex},on:{"dblclick":function($event){_vm.cellCanEdit(column, rowItem) && !_vm.rowDisabled(rowItem, rowIndex)
                ? _vm.editCell(rowIndex, column.key)
                : () => false},"mouseenter":(e) =>
                _vm.showFullContent(
                  e,
                  rowIndex,
                  column.key,
                  _vm.cellCanEdit(column, rowItem) && !_vm.rowDisabled(rowItem, rowIndex)
                ),"mouseleave":_vm.leaveFromCell}},[_c('div',{staticClass:"tBody__td-value"},[(_vm.editableCell.columnKey === column.key && _vm.editableCell.rowIndex === rowIndex)?[(!!column.editRenderer)?_c(column.editRenderer,_vm._b({tag:"component",attrs:{"row":rowItem,"rowIndex":rowIndex,"columnIndex":columnIndex,"field":column.key,"value":rowItem[column.key],"id":`${column.key}_${rowIndex}_cellEdit`,"disabled":_vm.rowDisabled(rowItem, rowIndex),"close":_vm.cancelEditCell}},'component',column.editRendererProps,false)):_vm._e()]:[(!column.renderer)?[_vm._v(" "+_vm._s(rowItem[column.key])+" ")]:[(!!column.renderer)?_c(column.renderer,_vm._b({tag:"component",attrs:{"row":rowItem,"rowIndex":rowIndex,"columnIndex":columnIndex,"field":column.key,"value":rowItem[column.key],"id":`${column.key}_${rowIndex}_cellCustom`,"disabled":_vm.rowDisabled(rowItem, rowIndex)}},'component',column.rendererProps,false)):_vm._e()]]],2)])})],2)}),0)],(_vm.items.length !== 0 && _vm.fetching)?_c('div',{staticClass:"tBody__infiniteLoader"},[_c('a-spin')],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }