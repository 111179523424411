// TODO: In mpa api v2 permissions is in token, use cookies to get applications

export default () => {
  const stripTrailingSlash = (str) =>
    str.charAt(str.length - 1) === "/" ? str.substr(0, str.length - 1) : str

  const getCodes = (code) => code.split("/")

  const checkRouteUrl = ({ code, url, applications }) => {
    const codes = getCodes(code)
    const application = applications.find((item) => item.code === codes[0])

    if (!application) return false

    const component = application.components.find((item) => item.code === codes[1])
    if (!component) return stripTrailingSlash(application.link) === stripTrailingSlash(url)

    return stripTrailingSlash(component.link) === stripTrailingSlash(url)
  }

  const checkApplicationPermission = ({ code, applications }) => {
    const codes = getCodes(code)
    const application = applications.find((item) => item.code === codes[0])

    return Boolean(application)
  }

  const checkComponentPermission = ({ code, applications }) => {
    const codes = getCodes(code)

    const application = applications.find((item) => item.code === codes[0])
    if (!application) return false

    const component = application.components.find((item) => item.code === codes[1])
    return Boolean(component)
  }

  return {
    checkRouteUrl,
    checkApplicationPermission,
    checkComponentPermission
  }
}
