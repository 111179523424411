<template>
  <div>
    <Modal
      :showModal="AE_conversionInProgressModal"
      :title="'Требуется подтверждение'"
      :showCloseButton="false"
      :footerClassName="'conversion-in-progress-modal__buttons'"
      @close="closeModal"
    >
      <div>
        <label
          ><span
            >По данному атрибуту уже запущен процесс конвертации (дата: {{ formattedDate }},
            пользователь: {{ userName }}). Продолжить этот процесс или удалить данные и начать
            сначала?</span
          ></label
        >
      </div>
      <span slot="footer">
        <Button
          color="danger"
          @click="onDeny"
          style="margin-right: 0.25rem"
        >
          Начать сначала
        </Button>
        <Button
          color="primary"
          @click="onConfirm"
        >
          Продолжить
        </Button>
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapState } from "vuex"
import regeneratorRuntime from "regenerator-runtime"
import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

export default {
  components: {
    Button,
    Modal
  },
  data() {
    return {
      interval: null
    }
  },
  methods: {
    async onConfirm() {
      let data = await this.tableSearchRequest({ selectedAttrCode: this.AE_selectedAttrGet.code })
      await this.fetchPossibleUnits(data.data[0])
      this.AE_showTable(true)
      this.closeModal()
    },
    async onDeny() {
      try {
        this.PC_showLoader(true)
        await this.EA_removeTempTable(this.AE_selectedAttr.code)

        let data = await this.AE_processSelectedAttr()
        this.checkWithInterval(data)
      } catch (e) {
        this.handleError(e)
      }
    },
    async checkWithInterval(id) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]

        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.interval)
          this.closeModal()
        }
        if (response.task_state === "SUCCESS") {
          let data = response.task_result.records
          this.AE_tableData({
            items: data,
            count: data.length
          })
          this.fetchPossibleUnits(data[0])
          this.AE_showTable(true)
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
        }
      }, this.celeryInterval)
    },
    closeModal() {
      this.AE_conversionInProgressModalSet(false)
    },
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_conversionInProgressModalSet: "AE_conversionInProgressModal",
      AE_showTable: "AE_showTable",
      AE_tableData: "AE_tableData"
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      tableSearchRequest: "tableSearchRequest",
      fetchPossibleUnits: "fetchPossibleUnits",
      EA_removeTempTable: "EA_removeTempTable",
      AE_processSelectedAttr: "AE_processSelectedAttr"
    })
  },
  computed: {
    formattedDate() {
      return getFormatedDateTimeByTimestamp(this.AE_selectedAttrSearchConflict.timestamp)
    },
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    ...mapState("ProductCatalog/attributesEdit", {
      AE_conversionInProgressModal: (state) => state.search.AE_conversionInProgressModal,
      AE_selectedAttrSearchConflict: (state) => state.search.AE_selectedAttrSearchConflict,
      AE_selectedAttr: (state) => state.search.AE_selectedAttr,
      userName: (state) => state.search.userName,
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr
    })
  }
}
</script>
<style lang="scss">
.conversion-in-progress-modal__buttons {
  justify-content: flex-end;
}
</style>
