<template>
  <Modal
    @close="$emit('cancel')"
    :showModal="visible"
    :title="$t('changeHistory')"
  >
    <div class="edit-history">
      <div class="edit-history__item edit-history__item--original">
        <div class="edit-history__item-date">
          {{ $t("lastOption") }}
        </div>
        <div class="edit-history__item-body">{{ originalMessageBody }}</div>
      </div>

      <div
        class="edit-history__item"
        v-for="item in editHistory"
        :key="item.uuid"
      >
        <div class="edit-history__item-date">
          {{ formatDateAndTime(item.edited_at) }}
        </div>
        <div class="edit-history__item-body">{{ item.body }}</div>
      </div>

      <!-- <div class="edit-history__get-next" v-if="hasNextPage">
        <Button @click="!fetching ? $emit('getNextPage') : void 0">{{ fetching ? 'Загрузка...' : 'Показать больше' }}</Button>
      </div> -->
    </div>
  </Modal>
</template>

<script>
import moment from "moment"
import Modal from "../../../Modals/Modal"
import Button from "../../../Button"

export default {
  name: "MessageHistoryModal",
  components: {
    Modal,
    Button
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    // hasNextPage: {
    //   type: Boolean,
    //   required: true
    // },
    editHistory: {
      type: Array,
      required: true
    },
    originalMessageBody: {
      type: String,
      required: true
    }
    // fetching: {
    //   type: Boolean,
    //   required: true
    // }
  },
  methods: {
    formatDateAndTime(date) {
      const formattedDate = moment(date).locale($i18n.locale)
      const isToday = formattedDate.isSame(new Date(), "day")
      const isYesterday = formattedDate.isSame(moment().subtract(1, "day"), "day")
      const isSomeYear = formattedDate.isSame(new Date(), "year")

      if (isToday) {
        return formattedDate.format("LT")
      } else if (isYesterday) {
        return formattedDate.calendar()
      } else if (isSomeYear) {
        const datePattern = "DD MMM, LT"

        return formattedDate.format(datePattern)
      } else {
        return formattedDate.format("L, LT")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-history {
  &__get-next {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  &__item {
    $item: &;
    padding: 0 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    &-date {
      font-size: 12px;
      color: #8c8c8c;
    }

    &-body {
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding: 8px;
      white-space: pre-wrap;
    }

    &--original {
      #{$item}-body {
        border: 1px solid #000;
      }
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
</style>
