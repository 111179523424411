<template>
  <div
    @dblclick="checkBase"
    class="wrapper"
  >
    {{ name }}
  </div>
</template>
<script>
export default {
  props: ["row", "rowIndex"],
  data() {
    return {}
  },
  computed: {
    name() {
      if (!this.row.default_unit) {
        return ""
      }
      const defaultUnitIsArray = Array.isArray(this.row.default_unit)

      if (defaultUnitIsArray && this.row.default_unit.length === 0) {
        return ""
      }

      return defaultUnitIsArray ? this.row.default_unit[0].name : this.default_unit.name
    }
  },
  methods: {
    checkBase(event) {
      if (this.row.value_type !== "NUMERIC") {
        event.stopPropagation()
      }
    }
  }
}
</script>
<style scoped>
.wrapper {
  height: 100%;
  padding: 7px;
  width: 100%;
  position: absolute;
}
</style>
