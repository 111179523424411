<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showCreateValueModal"
      :title="'Создание новых значений'"
    >
      <div>
        <div>
          <label>
            <span
              >Выбранный список: <strong v-if="descriptionList.length">{{ descriptionList }}</strong
              ><strong v-else>{{ PV_selectedList.description }}</strong></span
            >
          </label>
          <label>
            <span>Ввести список значений вручную, указав каждое последущее с новой строки</span>
          </label>
          <textarea
            ref="listValues"
            @input="onValuesInput"
            rows="5"
            class="margin-top-10"
          ></textarea>
          <ValidationErrors
            :lengthError="valuesLengthError"
            :quantityError="showNumberOfValuesError"
            :repetativeValuesError="showRepetativeValuesError"
            :uniqueError="showUniqueError"
            :emptyError="showEmptyError"
          />
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewValue"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validEmpty from "utils/validations/validEmpty"
import validArrayItemsCount from "utils/validations/validArrayItemsCount"
import validArrayItemsMaxLength from "utils/validations/validArrayItemsMaxLength"
import validArrayRepetativeItems from "utils/validations/validArrayRepetativeItems"

export default {
  name: "CreateValueModal",
  components: {
    Button,
    Modal,
    ValidationErrors
  },
  data() {
    return {
      timeoutValues: null,
      valuesLengthError: false,
      showNumberOfValuesError: false,
      showRepetativeValuesError: false,
      showUniqueError: false,
      showEmptyError: false
    }
  },
  props: {
    descriptionList: {
      type: String,
      default: ""
    }
  },
  watch: {
    PV_showCreateValueModal() {
      // reset textarea value
      if (this.$refs.listValues) {
        this.$refs.listValues.value = ""
      }
    }
  },
  methods: {
    onValuesInput(event) {
      let value = event.target.value
      // build array
      let valuesArray = value
        .split("\n")
        .map((item) => {
          return item.trim()
        })
        .filter((item) => item !== "")

      this.showEmptyError = !validEmpty(value)
      this.showNumberOfValuesError = !validArrayItemsCount(valuesArray, 50)
      this.valuesLengthError = !validArrayItemsMaxLength(valuesArray, 50)
      this.showRepetativeValuesError = !validArrayRepetativeItems(valuesArray)
      this.validateUnique(valuesArray)

      // set
      if (valuesArray && valuesArray.length) {
        this.PV_valuesForNewValue(valuesArray)
      }
    },
    validateUnique(valuesArray) {
      let uniqueError = false
      loopMain: for (let i = 0; i < valuesArray.length; i++) {
        for (let k = 0; k < this.PV_valuesData.items.length; k++) {
          // change PV_valuesData.items[].value => PV_valuesData.items[].value_ru
          if (valuesArray[i].trim() === this.PV_valuesData.items[k].value_ru.trim()) {
            uniqueError = true
            break loopMain
          }
        }
      }
      this.showUniqueError = uniqueError
    },
    createNewValue() {
      this.PV_createNewValue(this.descriptionList)
      this.closeModal()
    },
    closeModal() {
      this.PV_showCreateValueModalSet(false)
      this.resetValues()
    },
    resetValues() {
      this.timeoutValues = null
      this.valuesLengthError = false
      this.showNumberOfValuesError = false
      this.showRepetativeValuesError = false
      this.showUniqueError = false
      this.showEmptyError = false
    },
    ...mapMutations("ProductCatalog", {
      PV_valuesForNewValue: "PV_valuesForNewValue",
      PV_showCreateValueModalSet: "PV_showCreateValueModal"
    }),
    ...mapActions("ProductCatalog", {
      PV_createNewValue: "PV_createNewValue"
    })
  },
  computed: {
    enableCreateButton() {
      let show = false

      if (
        this.PV_valuesForNewValueGet &&
        !this.showUniqueError &&
        !this.valuesLengthError &&
        !this.showNumberOfValuesError &&
        !this.showRepetativeValuesError &&
        !this.showEmptyError &&
        this.$refs.listValues.value
      ) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PV_valuesForNewValueGet: "PV_valuesForNewValue",
      PV_showCreateValueModal: "PV_showCreateValueModal",
      PV_valuesData: "PV_valuesData",
      PV_selectedList: "PV_selectedList"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
</style>
