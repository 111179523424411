import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PU_unitTableSearchedValus: [],
  PU_editCellAutocomplete: []
})

const getters = {
  PU_unitTableSearchedValus: (state) => state.PU_unitTableSearchedValus,
  PU_editCellAutocomplete: (state) => state.PU_editCellAutocomplete
}

const mutations = {
  PU_unitTableSearchedValus(state, value) {
    state.PU_unitTableSearchedValus = value
  },
  PU_editCellAutocomplete(state, value) {
    state.PU_editCellAutocomplete = value
  }
}

const actions = {
  PU_unitTableSearchValue({ commit, getters }, { columnKey, newValue, row }) {
    let resource = "AUD"
    let field = "name"

    let searchData = buildMagicSearch({
      resource,
      field: [field, "base"],
      value: [newValue, [null]],
      sort: null,
      limit: 20,
      offset: 0
    })

    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((units) => {
        // exclude self
        units = units.filter((item) => {
          return item.code !== row.code
        })
        commit("PU_unitTableSearchedValus", units)
        let unitsArray = units.map((item) => {
          return item.name
        })
        commit("PU_editCellAutocomplete", unitsArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },

  async PU_unitTableSelectValue({ commit, getters, dispatch }, { columnKey, newValue, rowIndex }) {
    let selected = getters.PU_unitTableSearchedValus.find((item) => {
      return item.name === newValue
    })
    // edit responding unit
    let unit = getters.PU_unitsTableData.items[rowIndex]
    let sendObject = {
      code: unit.code,
      unit: {}
    }
    sendObject.unit.base = selected.code

    // show loader
    commit("PC_showLoader", true)
    API_MODERATION.put(`${apiUrls.unit}`, sendObject)
      .then((r) => {
        dispatch("PU_unitTableReplaceValues", { columnKey, newValue, rowIndex, selected })
        // hide loader
        commit("PC_showLoader", false)
        notification.info({ message: "Изменения продукта добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  async PU_unitTableReplaceValues(
    { commit, getters },
    { columnKey, newValue, rowIndex, selected }
  ) {
    // set new value for base.name
    let field = "name"
    commit("PU_unitsTableSetNewObjectValue", { field, newValue, rowIndex })
    // set new value for base.code
    field = "code"
    newValue = selected.code
    commit("PU_unitsTableSetNewObjectValue", { field, newValue, rowIndex })
    return Promise.resolve()
  },
  PU_clearBaseAndCoef({ commit }, { row, rowIndex }) {
    // show loader
    commit("PC_showLoader", true)
    let sendObject = {
      code: row.code,
      unit: {
        base: null
        /* "coef": 0 */
      }
    }
    API_MODERATION.put(`${apiUrls.unit}`, sendObject)
      .then((r) => {
        // update values in table
        commit("PU_unitsTableSetNewObjectValue", { field: "name", newValue: null, rowIndex })
        commit("PU_unitsTableSetNewValue", { columnKey: "coef", newValue: null, rowIndex })
        // hide loader
        commit("PC_showLoader", false)
        notification.info({ message: "Изменения добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
