<template>
  <div>
    <a-modal
      :title="`${$t('seller')} ${seller.slug}`"
      :dialog-style="{ top: '20px' }"
      :visible="visible"
      :footer="null"
      width="100%"
      @cancel="handleClose()"
    >
      <div
        v-if="!isFetching && visible"
        class="charts"
      >
        <ChartRender
          v-if="dataStatistics.weeks && visible"
          :data="dataStatistics.weeks"
          :blue-column="{ title: $t('spent'), key: 'cost' }"
          :red-column="{ title: `${$t('commision')}`, key: 'commission' }"
        />
        <ChartRender
          v-if="dataStatistics.weeks && visible"
          :data="dataStatistics.weeks"
          :blue-column="{ title: $t('soldSum'), key: 'sold_price' }"
          :red-column="{ title: $t('soldWithoutCancel'), key: 'sold_success' }"
        />
        <ChartRender
          v-if="dataStatistics.weeks && visible"
          :data="dataStatistics.weeks"
          :blue-column="{ title: $t('spent'), key: 'cost' }"
          :red-column="{ title: $t('soldWithoutCancel'), key: 'sold_success' }"
        />
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"

import ChartRender from "./ChartRender.vue"

import { getStatistics } from "@/modules/ADS/services/sellerServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  seller: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["close"])

const dataStatistics = ref([])
const isFetching = ref(false)

const getStats = async (visible) => {
  if (!visible) {
    dataStatistics.value = []
    return
  }

  try {
    isFetching.value = true

    const { data } = await getStatistics(props.seller.seller_id)

    dataStatistics.value = data
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleClose = () => {
  emit("close")
}

watch(() => props.visible, getStats)
</script>

<style lang="scss" scoped>
.charts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
