import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = () => ({
  PT_checkUniqueTypeName: true,
  PT_checkUniqueTypeSynonym: true,
  PT_synonymFailedValidation: ""
})

const getters = {
  PT_checkUniqueTypeName: (state) => state.PT_checkUniqueTypeName,
  PT_checkUniqueTypeSynonym: (state) => state.PT_checkUniqueTypeSynonym,
  PT_synonymFailedValidation: (state) => state.PT_synonymFailedValidation
}

const mutations = {
  PT_checkUniqueTypeName: (state, value) => {
    state.PT_checkUniqueTypeName = value
  },
  PT_checkUniqueTypeSynonym: (state, value) => {
    state.PT_checkUniqueTypeSynonym = value
  },
  PT_synonymFailedValidation(state, value) {
    state.PT_synonymFailedValidation = value
  }
}

const actions = {
  PT_checkUniqueTypeName: ({ commit, getters }, value) => {
    if (value === "") {
      commit("PT_checkUniqueTypeName", true)
      return
    }

    let params = {
      name: value.trim()
    }

    API_MODERATION.get(`${apiUrls.validateProductType}`, { params })
      .then((r) => r.data.message.unique)
      .then((unique) => {
        if (unique) commit("PT_checkUniqueTypeName", true)
        if (!unique) commit("PT_checkUniqueTypeName", false)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PT_checkUniqueTypeSynonym: ({ commit, getters }, { value, rowIndex }) => {
    if (value === "") {
      commit("PT_checkUniqueTypeSynonym", true)
      return
    }

    let synonymsArray = value.split(",").map((item) => {
      return item.trim()
    })

    if (rowIndex !== undefined) {
      // this stage is only for editing, not creating new type
      // check only those which are not set already
      let oldSynonyms = getters.PT_typesTableData.items[rowIndex].synonyms

      // remove synonyms, which are already set from synonymsARRAY
      for (let i = 0; i < oldSynonyms.length; i++) {
        synonymsArray = synonymsArray.filter((item) => {
          return item !== oldSynonyms[i]
        })
      }
    }

    let promiseArray = []
    for (let i = 0; i < synonymsArray.length; i++) {
      // skip empty strings
      if (synonymsArray[i] === "") continue
      let params = {
        name: synonymsArray[i]
      }

      promiseArray.push(API_MODERATION.get(`${apiUrls.validateProductType}`, { params }))
    }

    Promise.all(promiseArray)
      .then((r) => {
        // array of objects
        for (let i = 0; i < r.length; i++) {
          if (!r[i].data.message.unique) {
            commit("PT_checkUniqueTypeSynonym", false)
            commit("PT_synonymFailedValidation", synonymsArray[i])
            break
          } else {
            commit("PT_checkUniqueTypeSynonym", true)
            commit("PT_synonymFailedValidation", "")
          }
        }
      })
      .catch((e) => {
        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
