import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PV_attributesQueryParams: {
    limit: 50,
    ordering: ["-name"],
    offset: 0
  },
  PV_attributesData: { count: 0, items: [] },
  PV_initialAttributes: false,
  PV_attributesFetchingStatus: false,
  PV_selectedAttribute: false
})

const getters = {
  PV_attributesQueryParams: (state) => state.PV_attributesQueryParams,
  PV_attributesData: (state) => state.PV_attributesData,
  PV_attributesFetchingStatus: (state) => state.PV_attributesFetchingStatus,
  PV_selectedAttribute: (state) => state.PV_selectedAttribute,
  PV_initialAttributes: (state) => state.PV_initialAttributes
}

const mutations = {
  PV_attributesQueryParams(state, value) {
    state.PV_attributesQueryParams = value
  },
  PV_attributesData(state, value) {
    state.PV_attributesData = value
  },
  PV_attributesFetchingStatus(state, value) {
    state.PV_attributesFetchingStatus = value
  },
  PV_selectedAttribute(state, value) {
    state.PV_selectedAttribute = value
  },
  PV_initialAttributes(state, value) {
    state.PV_initialAttributes = value
  }
}

const actions = {
  async PV_searchAttributes({ commit, dispatch, getters }, selected) {
    let params = {
      description: selected.description
    }

    // show loader
    commit("PC_showLoader", true)

    // start request
    return API_MODERATION.get(`${apiUrls.searchAttributesOfList}`, { params })
      .then((r) => r.data.message)
      .then((attributes) => {
        let attributesClone = JSON.parse(JSON.stringify(attributes))
        commit("PV_initialAttributes", attributesClone)
        attributes = buildValuesDescriptionTableItems(attributes)
        let tableObj = {
          count: attributes.length,
          items: attributes
        }
        commit("PV_attributesData", tableObj)
        // hide loader
        commit("PC_showLoader", false)
        return attributes
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  PV_resetAttributesTable({ commit }) {
    commit("PV_attributesQueryParams", {
      limit: 50,
      sortedColumns: [],
      filteredColumns: [],
      ordering: [],
      offset: 0
    })
    commit("PV_attributesData", { count: 0, items: [] })
    commit("PV_initialAttributes", false)
    commit("PV_attributesFetchingStatus", false)
    commit("PV_selectedAttribute", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildValuesDescriptionTableItems(values) {
  return values.map((item, index) => {
    // id
    item._id = `valuesRow-${index}`
    return item
  })
}
