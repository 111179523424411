import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PA_unitsToEditAttribute: [],
  PA_unitsToEditAttributeAutocomplete: []
})

const getters = {
  PA_unitsToEditAttribute: (state) => state.PA_unitsToEditAttribute,
  PA_unitsToEditAttributeAutocomplete: (state) => state.PA_unitsToEditAttributeAutocomplete
}

const mutations = {
  PA_unitsToEditAttribute(state, value) {
    state.PA_unitsToEditAttribute = value
  },
  PA_unitsToEditAttributeAutocomplete(state, value) {
    state.PA_unitsToEditAttributeAutocomplete = value
  }
}

const actions = {
  PA_editAttributesCell({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PA_attributesTableData.items[rowIndex][columnKey]
    if (newValue === oldValue) return

    let sendData = {
      code: getters.PA_attributesTableData.items[rowIndex].code,
      attribute: {
        [columnKey]: newValue
      }
    }

    // show loader
    commit("PC_showLoader", true)
    let headers = {}
    if (columnKey === "public_name" || columnKey === "description") {
      headers = { "Accept-language": getters.PC_selectedLanguage }
    }

    API_MODERATION.put(`${apiUrls.attribute}`, sendData, { headers })
      .then((r) => {
        commit("PA_attributesTableSetNewValue", { columnKey, newValue, rowIndex })
        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения продукта добавлены" })
        if (columnKey === "coef") {
          notification.error({ message: "Не забудьте добавить базовую еденицу измерения" })
        }
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  PA_editAttributesSynonym({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PA_attributesTableData.items[rowIndex].synonymsNames

    if (newValue === oldValue) return

    let arrayNewValue = newValue.split(",").map((item) => {
      return item.trim()
    })

    let sendData = {
      code: getters.PA_attributesTableData.items[rowIndex].code,
      attribute: {
        synonyms: arrayNewValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.attribute}`, sendData)
      .then((r) => {
        commit("PA_attributesTableSetNewValue", { columnKey, newValue, rowIndex })
        // hide loader
        commit("PC_showLoader", false)

        commit("PA_attributesTableSetNewArrayValue", { newValue: arrayNewValue, rowIndex }) // set array value as well

        notification.info({ message: "Изменения продукта добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  },
  async PA_editAttributesType({ getters, commit, dispatch }, { row, newValue, rowIndex }) {
    try {
      let oldValue = getters.PA_attributesTableData.items[rowIndex].value_type
      if (newValue === oldValue) return

      let defaultUnit = getters.PA_attributesTableData.items[rowIndex].default_unit

      let response = await dispatch("PA_editAttributesTypeInDb", {
        row,
        newValue,
        oldValue,
        rowIndex
      })
      if (!response) return
      if (oldValue === "NUMERIC" && defaultUnit) {
        // update value in table
        commit("PA_attributesTableSetNewUnitValue", { newValue: null, rowIndex })
      }
    } catch (e) {
      console.log(e)
    }
  },
  async PA_editAttributesTypeInDb({ getters, commit }, { row, newValue, oldValue, rowIndex }) {
    // show loader
    commit("PC_showLoader", true)
    let sendData = {
      code: row.code,
      attribute: {
        value_type: newValue
      }
    }
    return API_MODERATION.put(`${apiUrls.attribute}`, sendData)
      .then((r) => {
        // update value in table
        commit("PA_attributesTableSetNewValue", { columnKey: "value_type", newValue, rowIndex })
        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения атрибута добавлены" })
        return r
      })
      .catch((e) => {
        if (e.response.data.message == "Can't change value type! Use convert.") {
          notification.error({
            message:
              "Нельзя изменить: значение используется в продуктах. Используйте конвертацию с обработкой значений"
          })
        } else {
          commit("handleError", e)
        }
        // hide loader
        commit("PC_showLoader", false)
        return null
      })
  },
  PA_fetchUnitsToEditAttribute({ commit }, value) {
    let searchData = buildMagicSearch({
      resource: "AUD",
      field: "name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((units) => {
        commit("PA_unitsToEditAttribute", units)
        let unitsArray = units.map((item) => {
          return item.name
        })
        commit("PA_unitsToEditAttributeAutocomplete", unitsArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PA_processSelectedUnitToEditAttribute(
    { commit, getters, dispatch },
    { columnKey, newValue, row, rowIndex }
  ) {
    let selectedUnit = getters.PA_unitsToEditAttribute.find((item) => {
      return item.name === newValue
    })
    // show loader
    commit("PC_showLoader", true)
    let sendData = {
      code: row.code,
      attribute: {
        default_unit: selectedUnit.code
      }
    }
    return API_MODERATION.put(`${apiUrls.attribute}`, sendData)
      .then((r) => {
        // update value in table
        console.log(newValue)
        commit("PA_attributesTableSetNewUnitValue", { newValue, rowIndex })
        // reset values
        dispatch("PA_resetUnitToEditAttributeValues")
        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения атрибута добавлены" })
        return r
      })
      .catch((e) => {
        // reset values
        dispatch("PA_resetUnitToEditAttributeValues")
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  PA_resetUnitToEditAttributeValues({ commit }) {
    commit("PA_unitsToEditAttribute", [])
    commit("PA_unitsToEditAttributeAutocomplete", [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
