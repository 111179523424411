<template>
  <div>
    <Modal
      :title="`Конвертация STRING атрибута в тип значений ${listType}`"
      :showModal="AE_conversionToListsModalGet ? true : false"
      :showCloseButton="false"
      @close="closeModal"
    >
      <p>Выберите тип конвертации для списковых значений:</p>
      <div class="convert-attr">
        <div class="convert-attr__item">
          <input
            class="convert-attr__input"
            type="radio"
            value="existedList"
            v-model="wayConverting"
            @input="resetNewListTitle"
          />
          <span class="convert-attr__label"
            >Использовать существующий список из справочника (новые будут добавлены)</span
          >
        </div>
        <div class="convert-attr__item">
          <input
            class="convert-attr__input"
            type="radio"
            value="newList"
            v-model="wayConverting"
            @input="resetSelectedList"
          />
          <span class="convert-attr__label"
            >Создать новый уникальный список по всем найденным значениям атрибута</span
          >
        </div>
      </div>

      <div v-show="wayConverting !== 'newList'">
        <!-- <p>Укажите используемый разделитель для определения нескольких значений</p>
				<input class="convert-attr__splitter" type="text" value=";"> -->
        <p>Выберите список из справочника</p>
        <Autocomplete
          ref="autocompleteLists"
          :data="listsForAutocomplete"
          placeholder="Название списка"
          @select="setListForConverting"
        />

        <div class="conversion_lists">
          <div
            v-if="AE_CSL_selectedListAttrs.length"
            class="conversion_list"
          >
            <p class="conversion_list_title">Значения выбранного списка:</p>
            <ul>
              <li
                v-for="val in AE_CSL_selectedListValues"
                :key="val.code"
              >
                {{ val.value }}
              </li>
            </ul>
          </div>
          <div
            v-if="AE_CSL_selectedListValues.length"
            class="conversion_list"
          >
            <p class="conversion_list_title">Атрибуты, что используют выбранный список</p>
            <ul>
              <li
                v-for="attr in AE_CSL_selectedListAttrs"
                :key="attr.code"
              >
                {{ attr.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-show="wayConverting === 'newList'">
        <p>Введите название для нового списка значений выбранного атрибута:</p>
        <input
          v-model="newListTitle"
          placeholder="Название нового списка"
        />
      </div>

      <div
        slot="footer"
        class="footer"
      >
        <Button
          class="footer_btn"
          @click="closeModal"
          theme="secondary"
        >
          Отмена
        </Button>
        <Button
          :disabled="disabledConvertButton"
          @click="startConverting"
          class="footer_btn"
          >Начать конвертацию</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Autocomplete from "components/Autocomplete"
import { mapActions, mapMutations, mapState } from "vuex"
import { isEmpty } from "lodash"

export default {
  components: {
    Button,
    Modal,
    Autocomplete
  },
  data() {
    return {
      wayConverting: "existedList",
      newListTitle: ""
    }
  },
  created() {
    this.getAllLists()
  },
  computed: {
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    ...mapState("ProductCatalog/attributesEdit", {
      AE_conversionToListsModalGet: (state) => state.conversion.AE_conversionToListsModal,
      AE_selectedAttr: (state) => state.search.AE_selectedAttr,
      AE_CSL_lists: (state) => state.convertToListModal.AE_CSL_lists,
      AE_CSL_selectedListAttrs: (state) => state.convertToListModal.AE_CSL_selectedListAttrs,
      AE_CSL_selectedListValues: (state) => state.convertToListModal.AE_CSL_selectedListValues,
      AE_CSL_selectedList: (state) => state.convertToListModal.AE_CSL_selectedList
    }),
    listType() {
      return this.AE_conversionToListsModalGet
    },
    listsForAutocomplete() {
      return this.AE_CSL_lists.map((l) => {
        return {
          name: l.description
        }
      })
    },
    disabledConvertButton() {
      return isEmpty(this.AE_CSL_selectedList) && !this.newListTitle
    }
  },
  methods: {
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_conversionToListsModal: "AE_conversionToListsModal",
      AE_CSL_selectedListSet: "AE_CSL_selectedList",
      AE_CSL_selectedListValuesSet: "AE_CSL_selectedListValues",
      AE_CSL_selectedListAttrsSet: "AE_CSL_selectedListAttrs"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_CSL_DoConvertation: "AE_CSL_DoConvertation",
      AE_tableSearch: "AE_tableSearch",
      fetchLists: "fetchLists",
      setListForConverting: "setListForConverting",
      AE_CSL_resetModalData: "AE_CSL_resetModalData"
    }),
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    closeModal() {
      this.resetSelectedList()
      this.resetNewListTitle()
      this.AE_conversionToListsModal(false)
    },
    resetNewListTitle() {
      // this.getAllLists()
      this.newListTitle = ""
    },
    resetSelectedList() {
      // this.getAllLists()
      this.$refs.autocompleteLists.input = ""
      this.AE_CSL_resetModalData()
    },
    async startConverting() {
      if (!this.newListTitle && !isEmpty(this.AE_CSL_selectedList)) {
        this.newListTitle = this.AE_CSL_selectedList.description
      }
      const payload = {
        new_list_description: this.newListTitle,
        attribute: this.AE_selectedAttr.code,
        new_value_type: this.listType
      }

      try {
        let response = await this.AE_CSL_DoConvertation(payload)
        let id = response.data.message.task_id
        this.checkWithInterval(id, this.AE_selectedAttr.code)
      } catch (e) {
        // commit('ProductCatalog/handleError', e, {root: true});
      } finally {
        this.closeModal()
      }
    },
    async checkWithInterval(id, selectedAttrCode) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]

        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.interval)
        }
        if (response.task_state === "SUCCESS") {
          await this.AE_tableSearch({ selectedAttrCode })
          this.$notification.info({
            message:
              'Конвертация успешна. Изменения будут добавлены после нажатия кнопки "Применить"'
          })
          this.closeModal()
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
          this.closeModal()
        }
      }, this.celeryInterval)
    },
    getAllLists() {
      try {
        this.fetchLists()
      } catch (e) {
        /// commit('ProductCatalog/handleError', e, {root: true});
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.convert-attr {
  display: block;

  &__input {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 2px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
  }

  &__label {
    width: 90%;
    line-height: 1.2;
    margin-bottom: 0;
  }

  &__splitter {
    width: 20%;
  }
}
.conversion_lists {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}
.conversion_list {
  width: 45%;
  ul {
    max-height: 300px;
    overflow: auto;
    padding-left: 20px;
  }
  &_title {
    height: 50px;
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &_btn {
    margin-left: 20px;
  }
}
</style>
