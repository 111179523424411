import { mapState, mapMutations, mapActions } from "vuex"

export default {
  computed: {
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    ...mapState("ProductCatalog/attributesEdit", {
      AE_showTableGet: (state) => state.table.AE_showTable,
      AE_tableDataGet: (state) => state.table.AE_tableData,
      AE_queryParamsGet: (state) => state.table.AE_queryParams,
      AE_fetchingStatusGet: (state) => state.table.AE_fetchingStatus,
      AE_selectedAttrGet: (state) => state.search.AE_selectedAttr
    })
  },
  methods: {
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_showTable: "AE_showTable",
      AE_queryParams: "AE_queryParams",
      AE_resetSearchValues: "AE_resetSearchValues",
      resetConversionValues: "resetConversionValues",
      resetTableValues: "resetTableValues"
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_tableSearch: "AE_tableSearch",
      EA_submitChanges: "EA_submitChanges",
      EA_cancel: "EA_cancel",
      EA_filterErrors: "EA_filterErrors"
    })
  }
}
