/* mixin for base component */
export default {
  watch: {
    searchText() {
      this.$emit("searchchange", this.searchText)
    }
  },

  methods: {
    searchTextInOptions(arr) {
      return arr.filter((option) => {
        try {
          let result = this.filterPredicate(option[this.labelKey], this.searchText)

          if (result) return true
          else if (option.children && option.children.length) {
            return this.searchTextInOptions(option.children).length
          } else return false
        } catch (e) {
          return true
        }
      })
    }
  },

  computed: {
    getStyle() {
      if (this.dropdownWidth) {
        return `width: ${this.dropdownWidth}px`
      } else {
        return ``
      }
    }
  }
}
