import { computed } from "vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo } = {}) => {
  const sellerListColumns = computed(() => [
    {
      key: "name",
      title: i18n.t("seller"),
      scopedSlots: { customRender: "sellerRenderer" },
      fixed: "left",
      width: 215
    },
    {
      dataIndex: "slug",
      key: "slug",
      title: i18n.t("slug"),
      scopedSlots: { customRender: "slugRenderer" },
      width: 200
    },
    {
      title: i18n.t("defaultCLabel"),
      dataIndex: "default_label",
      key: "default_label",
      width: 160,
      scopedSlots: { customRender: "basicCustomLabelRenderer" },
      elipsis: true
    },
    {
      key: "cpa_status",
      dataIndex: "cpa_status",
      title: `CPA ${i18n.t("status")}`,
      scopedSlots: {
        customRender: "cpaStatusRenderer"
      },
      width: 130
    },
    {
      key: "date_created",
      dataIndex: "date_created",
      title: i18n.t("dateAdded"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "date_created" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "offers_count",
      dataIndex: "offers_count",
      title: i18n.t("offerCountShorted"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "offers_count" && sortedInfo?.value?.order,
      width: 120
    },
    {
      key: "balance",
      dataIndex: "balance",
      title: i18n.t("balance"),
      scopedSlots: {
        customRender: "sumRender"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "balance" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "on_hold",
      dataIndex: "on_hold",
      title: i18n.t("on_hold"),
      customRender: (sum) => getFormatedSum(sum),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "on_hold" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "leftover",
      dataIndex: "leftover",
      title: i18n.t("leftover"),
      scopedSlots: {
        customRender: "sumRender"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "leftover" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "applied_total",
      dataIndex: "applied_total",
      title: i18n.t("applied_total"),
      customRender: (sum) => getFormatedSum(sum),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "applied_total" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "pending_total",
      dataIndex: "pending_total",
      title: i18n.t("pending_total"),
      customRender: (sum) => getFormatedSum(sum),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "pending_total" && sortedInfo?.value?.order,
      width: 140
    },
    {
      key: "canceled_total",
      dataIndex: "canceled_total",
      title: i18n.t("canceled_total"),
      customRender: (sum) => getFormatedSum(sum),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "canceled_total" && sortedInfo?.value?.order,
      width: 150
    },
    {
      key: "actions",
      title: i18n.t("actions"),
      scopedSlots: { customRender: "actions" },
      fixed: "right",
      width: 145
    }
  ])

  const additionalBalanceTableColumns = computed(() => [
    {
      key: "available",
      dataIndex: "available",
      title: i18n.t("applied_total"),
      scopedSlots: { customRender: "divideCell" },
      width: 300
    },
    {
      key: "pending",
      dataIndex: "pending",
      title: i18n.t("pending_total"),
      scopedSlots: { customRender: "divideCell" },
      width: 300
    },
    {
      key: "not_available",
      dataIndex: "not_available",
      title: i18n.t("canceled_total"),
      scopedSlots: { customRender: "divideCell" },
      width: 300
    }
  ])

  const balanceTableColumns = computed(() => [
    {
      key: "balance",
      dataIndex: "balance",
      title: i18n.t("balance"),
      scopedSlots: { customRender: "balanceCell" },
      width: 300
    },
    {
      key: "on_hold",
      dataIndex: "on_hold",
      title: i18n.t("on_hold"),
      scopedSlots: { customRender: "divideCell" },
      width: 300
    },
    {
      key: "leftover",
      dataIndex: "leftover",
      title: i18n.t("leftover"),
      scopedSlots: { customRender: "sumCell" },
      width: 300
    }
  ])

  const transactionTableColumns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("balanceChanges"),
      scopedSlots: { customRender: "dateRenderer" },
      width: 90
    },
    {
      key: "transaction_type",
      title: i18n.t("transactionType"),
      scopedSlots: { customRender: "transactionTypeRenderer" },
      width: 120
    },
    {
      key: "balance_before",
      dataIndex: "balance_before",
      title: i18n.t("balanceBefore"),
      scopedSlots: { customRender: "sumCell" },
      align: "center",
      width: 80
    },
    {
      key: "amount",
      title: i18n.t("sum") + ", " + i18n.t("uah"),
      scopedSlots: { customRender: "amountCell" },
      align: "center",
      width: 90
    },
    {
      key: "billing_items_count",
      dataIndex: "billing_items_count",
      title: i18n.t("writtenOff") + ", " + i18n.t("pieces"),
      scopedSlots: { customRender: "defaultCell" },
      align: "center",
      width: 100
    },
    {
      key: "amount_declined",
      dataIndex: "amount_declined",
      title: i18n.t("declined") + ", " + i18n.t("uah"),
      scopedSlots: { customRender: "sumCell" },
      align: "center",
      width: 100
    },
    {
      key: "billing_items_declined_count",
      dataIndex: "billing_items_declined_count",
      title: i18n.t("declined") + ", " + i18n.t("pieces"),
      scopedSlots: { customRender: "defaultCell" },
      align: "center",
      width: 100
    },
    {
      key: "balance_after",
      dataIndex: "balance_after",
      title: i18n.t("balanceAfter"),
      scopedSlots: { customRender: "sumCell" },
      align: "center",
      width: 80
    }
  ])

  const sellerOrdersColumns = computed(() => [
    {
      key: "ordered_at",
      dataIndex: "ordered_at",
      title: i18n.t("dateOrder"),
      elipsis: true,
      width: 130
    },
    {
      key: "updated_at",
      dataIndex: "updated_at",
      title: i18n.t("updatedAt"),
      width: 110
    },
    {
      key: "order_code",
      dataIndex: "order_code",
      title: i18n.t("codeOrder"),
      scopedSlots: { customRender: "order_code" },
      width: 130
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("statusOrder"),
      scopedSlots: { customRender: "status" },
      width: 130
    },
    {
      key: "offer",
      dataIndex: "offer",
      title: "CS-code",
      scopedSlots: { customRender: "cs_code" },
      width: 200
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: i18n.t("countShorted"),
      elipsis: true,
      width: 90
    },
    {
      key: "price_per_item",
      dataIndex: "price_per_item",
      title: i18n.t("price"),
      elipsis: true,
      width: 90
    },
    {
      key: "price_total",
      dataIndex: "price_total",
      title: i18n.t("sum"),
      elipsis: true,
      width: 90
    }
  ])

  return {
    sellerListColumns,
    additionalBalanceTableColumns,
    balanceTableColumns,
    transactionTableColumns,
    sellerOrdersColumns
  }
}
