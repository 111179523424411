<template>
  <div>
    <div class="table-filters">
      <a-input-search
        v-for="key in filterKeys"
        :key="`filter-${key}`"
        v-model="filteredInfo[key]"
        :placeholder="$t(key)"
        allowClear
        @search="handleTableFilterChange()"
      >
        <template #enterButton>
          <a-button icon="search" />
        </template>
      </a-input-search>

      <a-button
        icon="undo"
        @click="handleTableFiltersReset()"
      >
        {{ $t("resetFilters") }}
      </a-button>

      <a-button
        style="flex-basis: unset"
        type="primary"
        icon="plus"
        @click="openLabelModal()"
      >
        {{ $t("add") }}
      </a-button>
    </div>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      rowKey="id"
      :scroll="{ x: true }"
      :pagination="paginationInfo"
      size="middle"
      @change="onTableChange"
    >
      <template #labelRender="{ name, background_color, font_color }">
        <div
          :style="{
            color: font_color,
            backgroundColor: background_color,
            borderColor: background_color
          }"
          class="label-render"
        >
          {{ name.uk }}
        </div>
      </template>

      <template #cellRender="text">
        <div style="width: 100%; word-break: break-all">{{ text }}</div>
      </template>

      <template #actions="record">
        <div style="display: flex; gap: 8px">
          <a-button
            type="dashed"
            icon="edit"
            shape="circle"
            @click="openLabelModal(record)"
          />

          <a-popconfirm
            :title="$t('deleteLabelAlert')"
            placement="left"
            @confirm="deleteLabel(record)"
          >
            <a-button
              shape="circle"
              icon="delete"
              type="danger"
            />
          </a-popconfirm>
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="showModal"
      :title="prototype?.id ? $t('editingLabels') : $t('creatingLabels')"
      :width="1200"
      destroyOnClose
      @close="closeLabelModal()"
    >
      <UpdateLabelModal
        :prototype="prototype"
        @updateLabel="onLabelChanged"
        @createLabel="onLabelCreated"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import UpdateLabelModal from "./components/UpdateLabelModal.vue"

import {
  fetchLabels,
  removeLabel
} from "@/modules/MPAdmin/services/generalSettingService/labelsService.js"
import notifyResponseError from "@/utils/notifyResponseError"

import useLabelsColumns from "./useLabelsColumns"
import useAntTableQuery from "@/composables/useAntTableQuery"

const columns = useLabelsColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchLabels
})

const prototype = ref()
const showModal = ref(false)

const filterKeys = ["name", "background_color", "font_color"]

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value })
}

const openLabelModal = (label) => {
  prototype.value = label
  showModal.value = true
}

const closeLabelModal = () => {
  showModal.value = false
  prototype.value = undefined
}

const deleteLabel = async (payload) => {
  try {
    await removeLabel(payload)

    notification.success({ message: i18n.t("labelDeleted") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onLabelCreated = () => {
  closeLabelModal()
  onTableChange()
}

const onLabelChanged = (data) => {
  updateTableDataRecord({ identifier: "id", payload: data })
  closeLabelModal()
}

onMounted(() => {
  setupTable({ defaultPagination: { size: "default" } })
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-filters {
  display: flex;
  gap: 16px;

  padding-bottom: 16px;

  & > * {
    flex-basis: 300px;
  }

  & > :nth-child(4) {
    flex-basis: unset;
    margin-left: auto;
  }
}

.label-render {
  width: fit-content;
  padding: 4px 6px;
  position: relative;

  border-radius: 8px 4px 4px 0;

  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  pointer-events: none;

  &::before {
    content: "";

    position: absolute;
    bottom: -5.6px;
    left: 0;

    border: 6px solid transparent;
    border-left-color: inherit;
    border-left-style: solid;
    border-left-width: 10px;
  }
}
</style>
