<template>
  <span
    class="select-wrap label"
    :style="{ 'margin-bottom': `${marginBottom}px` }"
    @click="openOptions"
  >
    <span
      :class="{ 'label-isError': isError }"
      v-if="label"
    >
      {{ label }}
      <Loader v-if="fetching" />
    </span>

    <div
      class="selection"
      ref="select"
      :class="{
        'active visible': showMenu,
        error: isError,
        disabled: isDisabled
      }"
    >
      <a-icon
        type="down"
        class="select-icon"
      />

      <input
        v-show="!isDisabled"
        class="search"
        autocomplete="off"
        tabindex="0"
        :id="id"
        :name="name"
        :value="searchText"
        @input="onInput"
        ref="input"
        @keyup.esc="closeOptions"
        @blur="blurInput"
        @keydown.enter.prevent
        @keyup.enter.prevent="enterItem"
        @keydown.delete="deleteTextOrItem"
      />

      <div class="text-wrapper">
        <div
          class="text"
          :class="textClass"
          @mousedown.prevent
          :data-vss-custom-attr="searchTextCustomAttr"
        >
          {{ inputText }}
        </div>
      </div>

      <template v-if="portal">
        <portal to="select">
          <div
            :style="{
              position: 'absolute',
              left: `${offset.left}px`,
              top: `${offset.top + elementHeight + elementMargin}px`,
              width: `${elementWidth}px`,
              'z-index': '1000'
            }"
            class="basic-selection__menu"
            ref="menu"
            @mousedown.prevent
            :class="menuClass"
            tabindex="-1"
            v-show="showMenu && filteredOptions.length && !isDisabled"
          >
            <Item
              v-for="(option, idx) in filteredOptions"
              :key="option.value"
              :option="option"
              :customAttr="customAttrs[idx] ? customAttrs[idx] : ''"
              :selectItem="selectItem"
              :mousedownItem="mousedownItem"
              :withoutLine="true"
              :tree="tree"
              :item="option"
              :labelKey="labelKey"
              :valueKey="valueKey"
              :selectedValue="selectedValue"
              :searchText="searchText"
            />
          </div>
        </portal>
      </template>

      <template v-else>
        <div
          class="basic-selection__menu"
          ref="menu"
          :style="getStyle"
          @mousedown.prevent
          :class="menuClass"
          tabindex="-1"
          v-show="showMenu && filteredOptions.length && !isDisabled"
        >
          <Item
            v-for="(option, idx) in filteredOptions"
            :key="option.value"
            :option="option"
            :customAttr="customAttrs[idx] ? customAttrs[idx] : ''"
            :selectItem="selectItem"
            :mousedownItem="mousedownItem"
            :withoutLine="true"
            :tree="tree"
            :item="option"
            :labelKey="labelKey"
            :valueKey="valueKey"
            :selectedValue="selectedValue"
            :searchText="searchText"
          />
        </div>
      </template>
    </div>
  </span>
</template>

<script>
import common from "../common"
import baseMixin from "../mixins/baseMixin"
import commonMixin from "../mixins/commonMixin"
import optionAwareMixin from "../mixins/optionAwareMixin"

export default {
  mixins: [baseMixin, commonMixin, optionAwareMixin],

  components: {
    Item: () => import("./components/Item"),
    Loader: () => import("../../Loader.vue")
  },

  props: {
    selected: {
      type: [Object, null],
      default: () => {
        return null
      }
    },

    label: {
      type: String,
      default: "",
      required: false
    },

    tree: {
      type: Boolean,
      default: false,
      required: false
    },

    fetching: {
      type: Boolean,
      default: false,
      required: false
    },

    showAllOptions: {
      type: Boolean,
      default: false,
      required: false
    },

    portal: {
      type: Boolean,
      default: false,
      required: false
    },

    marginBottom: {
      type: Number,
      default: 5,
      required: false
    }
  },

  data() {
    return {
      showMenu: false,
      searchText: "",
      mousedownState: false, // mousedown on option menu
      offset: {
        left: 0,
        top: 0
      },
      elementHeight: 30,
      elementMargin: 5,
      elementWidth: 0
    }
  },

  computed: {
    searchTextCustomAttr() {
      if (this.selected && this.selected[this.valueKey]) {
        return this.customAttr(this.selected)
      }

      return ""
    },

    selectedValue() {
      if (this.selected) return this.selected[this.valueKey]
      else return null
    },

    inputText() {
      if (this.searchText) {
        return ""
      } else {
        let text = this.placeholder

        if (this.selected && this.selected[this.labelKey]) {
          text = this.selected[this.labelKey]
        }

        return text
      }
    },

    customAttrs() {
      try {
        if (Array.isArray(this.values)) {
          return this.values.map((o) => this.customAttr(o))
        }
      } catch (e) {
        // if there is an error, just return an empty array
      }

      return []
    },

    textClass() {
      if (!this.selected || (!this.selected[this.labelKey] && this.placeholder)) {
        return "default"
      } else {
        return ""
      }
    },

    menuClass() {
      return {
        visible: this.showMenu,
        hidden: !this.showMenu
      }
    },

    filteredOptions() {
      if (this.searchText && !this.showAllOptions) {
        return this.searchTextInOptions(this.values)
      }

      return this.values
    }
  },

  methods: {
    deleteTextOrItem() {
      if (!this.searchText && this.selected) {
        this.selectItem({})
        this.openOptions()
      }
    },

    onInput(event) {
      this.searchText = event.target.value

      this.$emit("input", event.target.value)
    },

    openOptions() {
      common.openOptions(this)
    },

    blurInput() {
      common.blurInput(this)
    },

    closeOptions() {
      common.closeOptions(this)
    },

    prevItem() {
      common.prevItem(this)
    },

    nextItem() {
      common.nextItem(this)
    },

    enterItem() {
      common.enterItem(this)
    },

    pointerAdjust() {
      common.pointerAdjust(this)
    },

    mousedownItem() {
      common.mousedownItem(this)
    },

    selectItem(option) {
      this.searchText = "" // reset text when select item
      this.closeOptions()
      this.$emit("select", option)

      if (option[this.valueKey] === option[this.labelKey]) {
        this.searchText = option[this.valueKey]
      }

      this.$refs.input.blur()
    }
  }
}
</script>

<style lang="scss">
.selection {
  color: $font-default;
  max-width: 100%;
  padding: 0 30px 0 10px;
  font-size: 13.33px;
  border: 1px solid $border-color;
  border-radius: 3px;
  box-shadow: $light-shadow;
  line-height: 24px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
  white-space: normal;
  background: #fff;
  display: inline-block;
  outline: none;
  text-align: left;
  word-wrap: break-word;
  position: relative;

  & .select-icon {
    position: absolute;
    height: 16px;
    width: 16px;
    line-height: 20px;
    text-align: center;
    font-size: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
    color: $dark;
    border-radius: 10px;
    display: inline-block;
    content: "";
  }

  &:hover {
    border-color: $border-hover-color;
  }

  &.visible {
    border-color: $border-focus-color !important;
    box-shadow: $focus-shadow;

    & .text {
      color: $dark;
    }
  }

  &.error {
    border-color: $border-danger-focus-color !important;

    &.visible {
      box-shadow: $danger-focus-shadow !important;
    }

    & .text {
      color: $danger-color;
    }

    & .select-icon {
      color: $danger-color !important;
    }
  }

  &.disabled {
    border: 1px solid $border-color !important;
    cursor: default !important;
    background-color: $input-disabled-bg;

    &.visible {
      border: 1px solid $border-color !important;
      box-shadow: none !important;
    }

    & .text {
      color: $danger-color;
      cursor: default !important;
    }
  }
}

.basic-selection__menu {
  background-color: #fff;
  max-height: 300px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  left: -1px;
  top: calc(100% + 5px);
  position: absolute;
  border: 1px solid $border-color;
  z-index: 4;
  cursor: pointer;
  border-radius: 3px;

  & .item {
    cursor: pointer;
    padding: 5px 10px;
    min-height: 30px;
    line-height: 20px;

    &:not(.selected):hover {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.selection > input.search {
  background: none transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: text;
  top: 0;
  left: 1px;
  width: 100%;
  outline: none;
  padding: inherit;
  position: absolute;
  z-index: 2;
  height: 28px !important;
  line-height: 28px !important;
}

.selection > .text-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.text-wrapper > .text {
  cursor: text;
  position: relative;
  left: 1px;
  top: 2px;
  line-height: initial;
  z-index: 3;
  display: inline-block;
  transition: none;
  white-space: pre;

  &.default {
    color: $dark;
  }
}

span.label {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  cursor: default;

  span {
    display: block;
    margin-bottom: 5px;
    color: $link-default;
  }
}

span.label-isError {
  color: $danger-color !important;
}

.selected {
  background-color: $primary-hover-color !important;
  color: #fff !important;
}

.select-wrap {
  display: flex !important;
  flex-direction: column;
}
</style>
