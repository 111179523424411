import AttributesTable from "./attributesTable/attributesTable"
import CreateAttribute from "./createAttribute/createAttribute"
import CheckUniqueAttribute from "../validations/checkUniqueAttribute"
import EditAttribute from "./editAttribute/editAttribute"
import AtlsTable from "./atlsTable/atlsTable"
import AddType from "./addType/addType"
import ValuesTable from "./valuesTable/valuesTable"
import DeleteList from "./deleteList/deleteList"
import AddList from "./addList/addList"
import ShowProducts from "./showProducts/showProducts"
import EditDescription from "./editDescription/editDescription"

const modules = {
  AttributesTable,
  CreateAttribute,
  CheckUniqueAttribute,
  EditAttribute,
  AtlsTable,
  AddType,
  ValuesTable,
  DeleteList,
  AddList,
  ShowProducts,
  EditDescription
}

export default {
  modules
}
