const state = () => ({
  PC_showEditLocalAttrModal: false,
  PC_editedLocalAttrValue: false,
  PC_editedLocalAttrRow: false,
  PC_editedLocalAttrRowIndex: false
})

const getters = {
  PC_showEditLocalAttrModal: (state) => state.PC_showEditLocalAttrModal,
  PC_editedLocalAttrValue: (state) => state.PC_editedLocalAttrValue,
  PC_editedLocalAttrRow: (state) => state.PC_editedLocalAttrRow,
  PC_editedLocalAttrRowIndex: (state) => state.PC_editedLocalAttrRowIndex
}

const mutations = {
  PC_showEditLocalAttrModal(state, value) {
    state.PC_showEditLocalAttrModal = value
  },
  PC_editedLocalAttrValue(state, value) {
    state.PC_editedLocalAttrValue = value
  },
  PC_editedLocalAttrRow(state, value) {
    state.PC_editedLocalAttrRow = value
  },
  PC_editedLocalAttrRowIndex(state, value) {
    state.PC_editedLocalAttrRowIndex = value
  },
  PC_resetEditedLocalAttrValues(state) {
    state.PC_showEditLocalAttrModal = false
    state.PC_editedLocalAttrValue = false
    state.PC_editedLocalAttrRow = false
    state.PC_editedLocalAttrRowIndex = false
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
