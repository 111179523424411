import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_showCreateProductModal: false,
  PC_showSecondStep: false,
  PC_searchedAttributesByType: false,
  PC_searchedAtlsByType: false
})

const getters = {
  PC_showCreateProductModal: (state) => state.PC_showCreateProductModal,
  PC_showSecondStep: (state) => state.PC_showSecondStep,
  PC_searchedAttributesByType: (state) => state.PC_searchedAttributesByType,
  PC_searchedAtlsByType: (state) => state.PC_searchedAtlsByType
}

const mutations = {
  PC_showCreateProductModal(state, booleanValue) {
    state.PC_showCreateProductModal = booleanValue
  },
  PC_showSecondStep(state, booleanValue) {
    state.PC_showSecondStep = booleanValue
  },
  PC_searchedAttributesByType(state, value) {
    state.PC_searchedAttributesByType = value
  },
  PC_searchedAtlsByType(state, value) {
    state.PC_searchedAtlsByType = value
  }
}

const actions = {
  PC_showCreateProductModal({ commit, dispatch }) {
    // reset values:
    dispatch("PC_resetCreateProductValues")
    // set values:
    let newProduct = {
      brand: { name: "" },
      type: { name: "" },
      barcode: "",
      code: "",
      typeName: "",
      brandName: "",
      use_public_title: null
    }
    dispatch("PC_setProductTypePlaceholder", "noType")
    dispatch("PC_setProductBrandPlaceholder", "noBrand")
    commit("PC_currentEditableProduct", newProduct)
    commit("PC_setEditableProduct", newProduct)
    commit("PC_showCreateProductModal", true)
  },
  async PC_showSecondStep({ commit, getters, dispatch, rootState }, product) {
    // reset values except editable product and attributes table data
    await dispatch("PC_resetCreateProductValuesBeforeSecondStep")
    // create new product
    let response = await dispatch("PC_createNewProduct", product)
    if (!response) return
    commit("PC_showSecondStep", true)
  },
  async PC_createNewProduct({ commit, getters }, product) {
    commit("PC_showLoader", true)
    // start request
    return API_MODERATION.post(`${apiUrls.product}`, product)
      .then((r) => r.data.message.product)
      .then((product) => {
        // assign response product
        let newProduct = product
        newProduct.imagesUrls = []

        commit("PC_setEditableProduct", newProduct)

        // hide loader
        commit("PC_showLoader", false)
        notification.info({ message: "Новый продукт добавлен" })
        return product
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  },
  async PC_resetCreateProductValues({ commit }) {
    commit("PC_showSecondStep", false)
    commit("PC_resetProductValues")
    commit("PC_resetBrandAutocompleteValues")
    commit("PC_resetTypeAutocompleteValues")
    commit("PC_submitMsg", false)
    commit("PC_emptyEditableProductAttributes")
    commit("PC_searchedAttributesByType", false)
    commit("PC_searchedAtlsByType", false)
    return Promise.resolve()
  },
  async PC_resetCreateProductValuesBeforeSecondStep({ commit }) {
    commit("PC_resetProductValuesWithExceptions")
    commit("PV_valuesForNewGtin", false)
    commit("PC_resetBrandAutocompleteValues")
    commit("PC_resetTypeAutocompleteValues")
    commit("PC_submitMsg", false)
    return Promise.resolve()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
