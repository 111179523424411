<template>
  <div class="attribute-value">
    <a-input
      v-if="props.attribute.value_type === 'STRING' || props.attribute.value_type === 'TEMP'"
      v-model="attributeValue"
      @change="onTextAttributeChange"
    />

    <a-input-number
      v-else-if="props.attribute.value_type === 'NUMERIC'"
      v-model="attributeValue"
      style="width: 100%"
      @change="onNumericAttributeChange"
    />

    <a-radio-group
      v-else-if="props.attribute.value_type === 'BOOLEAN'"
      :value="attributeValue"
      class="attribute-value__radio"
    >
      <a-radio
        v-for="option in attributeBooleanOptions"
        :value="option.value"
        :key="option.value"
        @click="onBooleanAttributeChange(option.value)"
      >
        {{ option.label }}
      </a-radio>
    </a-radio-group>

    <a-select
      v-else-if="props.attribute.value_type.endsWith('LIST')"
      v-model="attributeValue"
      :mode="props.attribute.value_type === 'MULTILIST' ? 'tags' : 'default'"
      :maxTagTextLength="30"
      showSearch
      allowClear
      optionFilterProp="children"
      @change="onListAttributeChange"
      @focus="getAttributeListOptions"
    >
      <template #dropdownRender="menu">
        <a-spin :spinning="isFetching">
          <TypeNodes :vnodes="menu" />
        </a-spin>
      </template>

      <a-select-option
        v-for="option in attributeListOptions"
        :key="option.code"
        :value="option.code"
      >
        {{ option.value }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, inject, defineComponent } from "vue"
import i18n from "@/i18n"
import { fetchAttributeValues } from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"

const TypeNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true
    }
  },
  render() {
    return this.vnodes
  }
})

const props = defineProps({
  attribute: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["change"])
const language = inject("languageForDataFetch")

const attributeValue = ref()
const attributeListOptions = ref([])
const isFetching = ref(false)

const attributeBooleanOptions = computed(() => [
  {
    label: i18n.t("yes"),
    value: true
  },
  {
    label: i18n.t("no"),
    value: false
  }
])

const getAttributeListOptions = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchAttributeValues({
      code: props.attribute.code,
      language: language.value
    })

    attributeListOptions.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const onTextAttributeChange = (e) => {
  const { value } = e.target
  emit("change", { code: props.attribute.code, value: { value: value || null } })
}

const onNumericAttributeChange = (value) => {
  emit("change", { code: props.attribute.code, value: { value: isNaN(value) ? null : value } })
}

const onListAttributeChange = (value) => {
  if (Array.isArray(value)) {
    const attrValue = value.filter((item) => item.startsWith("VLD"))

    attributeValue.value = [...attrValue]

    emit("change", {
      code: props.attribute.code,
      value: attrValue.map((item) => attributeListOptions.value.find(({ code }) => code === item))
    })
    return
  }

  const attrValue = attributeListOptions.value.find((option) => option.code === value)
  emit("change", { code: props.attribute.code, value: attrValue })
}

const onBooleanAttributeChange = (value) => {
  if (attributeValue.value !== value) {
    attributeValue.value = value
    emit("change", { code: props.attribute.code, value: { code: null, value } })
  } else {
    attributeValue.value = undefined
    emit("change", { code: props.attribute.code, value: { code: null, value: null } })
  }
}

onMounted(async () => {
  if (props.attribute.value && Array.isArray(props.attribute.value)) {
    attributeValue.value = props.attribute.value?.map((option) => option.code)
  } else if (props.attribute.value_type === "LIST") {
    attributeValue.value = props.attribute?.value?.code || undefined
  } else {
    attributeValue.value =
      props.attribute?.value?.value !== null ? props.attribute.value.value : undefined
  }

  if (
    props.attribute.value &&
    Array.isArray(props.attribute.value) &&
    props.attribute.value?.length
  ) {
    attributeListOptions.value = props.attribute.value
  } else if (
    props.attribute.value &&
    !Array.isArray(props.attribute.value) &&
    props.attribute.value_type === "LIST"
  ) {
    attributeListOptions.value = [props.attribute.value]
  }
})
</script>

<style lang="scss" scoped>
.attribute-value {
  width: 100%;

  & * {
    width: 100%;
  }

  &__radio {
    display: flex;

    & * {
      width: fit-content;
    }
  }
}
</style>
