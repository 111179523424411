import Vue from "vue"
import VueI18n from "vue-i18n"
import en from "./locales/en.js"
import uk from "./locales/uk.js"
import getCurrentLocale from "./utils/getCurrentLocale.js"

Vue.use(VueI18n)

const slavicLanguagesPluralizationRule = (choice, choicesLength) => {
  if (choice === 0) {
    return 0
  }
  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2
  }
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }
  return choicesLength < 4 ? 2 : 3
}
export default new VueI18n({
  locale: getCurrentLocale(),
  fallbackLocale: "uk",
  messages: {
    en,
    uk
  },
  pluralizationRules: {
    uk: slavicLanguagesPluralizationRule
  }
})
