<template>
  <div class="pc--attributes-table">
    <template v-if="PT_searchedAttributesOfTypeGet.length">
      <SynDataTable
        :data="tableData"
        :queryAction="() => false"
        :queryParams="queryParams"
        rowKey="code"
        :columns="dataTableColumns"
        tableId="ProductTypesAttributesTable"
        :headerButtons="headerButtons"
        :narrowedRowsCount="1"
        :sortRowsEnabled="true"
        :onSortRows="onSort"
        class="margin-top"
        :limitValues="limitValues"
        :rowActions="rowActions"
        theme="odd"
      ></SynDataTable>
    </template>
    <template v-else>
      <Button
        color="primary"
        @click="PT_showCreateAttributeModal(true)"
        class="lonely-button"
        >Привязать</Button
      >
    </template>
  </div>
</template>
<script>
import Button from "components/Button"
import Checkboxes from "./RendererComponents/Checkboxes"

import { mapMutations, mapActions, mapGetters } from "vuex"

export default {
  name: "AttributesTable",
  components: {
    Button
  },
  created() {},
  data() {
    return {
      dataTableColumns: [
        {
          key: "name",
          title: "Название атрибута"
        },
        {
          key: "is_main",
          title: "M",
          width: 40,
          renderer: Checkboxes
        },
        {
          key: "is_required",
          title: "R",
          width: 40,
          renderer: Checkboxes
        }
      ],
      headerButtons: [
        {
          label: "Привязать",
          action: () => {
            this.PT_showCreateAttributeModal(true)
          },
          theme: "primary"
        }
      ],
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 }
      ],
      rowActions: [
        {
          label: "Отвязать",
          action: this.removeAttribute
        }
      ]
    }
  },
  methods: {
    removeAttribute(row) {
      this.PT_attributeToBeRemoved(row)
      this.PT_confirmRemoveAttributeModal(true)
    },
    onSort(items) {
      this.PT_updateAttributesOrder(items)
    },
    ...mapMutations("ProductCatalog", {
      PT_openAttributesModal: "PT_openAttributesModal",
      PT_showCreateAttributeModal: "PT_showCreateAttributeModal",
      PT_confirmRemoveAttributeModal: "PT_confirmRemoveAttributeModal",
      PT_attributeToBeRemoved: "PT_attributeToBeRemoved"
    }),
    ...mapActions("ProductCatalog", {
      PT_updateAttributesOrder: "PT_updateAttributesOrder"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PT_openAttributesModalGet: "PT_openAttributesModal",
      PT_searchedAttributesOfTypeGet: "PT_searchedAttributesOfType",
      tableData: "PT_searchedAttributesTable",
      queryParams: "PT_searchedAttributesQueryParams"
    })
  }
}
</script>
<style lang="scss">
.pc--attributes-table .lonely-button {
  margin-top: 3px;
}
body .pc--attributes-table [class^="rowActions"] {
  right: 89px;
}
</style>
