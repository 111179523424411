import Merchant from "./pages/Merchant"
import Sellers from "./pages/Sellers"
import BlackList from "./pages/BlackList"
import CPA from "./pages/CPA"
import CPC from "./pages/CPC"
import Referrers from "./pages/Referrers"
import ADS from "./ADS"
import i18n from "@/i18n"

export default {
  path: "/ads",
  meta: {
    permissionType: "application",
    permissionCode: "00ADS",
    code: "00ADS",
    title: "ADS"
  },
  component: ADS,
  children: [
    {
      path: "merchant",
      component: Merchant,
      meta: {
        permissionType: "component",
        permissionCode: "00ADS/M01MERCH",
        code: "M01MERCH",
        layout: "ant-layout",
        title: "Merchant"
      }
    },
    {
      path: "sellers",
      component: Sellers,
      meta: {
        permissionType: "component",
        permissionCode: "00ADS/M01SELL",
        code: "M01SELL",
        layout: "ant-layout",
        title: i18n.t("sellers")
      }
    },
    {
      path: "blacklists",
      component: BlackList,
      meta: {
        permissionType: "component",
        permissionCode: "00ADS/M01BLCL",
        code: "M01BLCL",
        layout: "ant-layout",
        title: i18n.t("blackList")
      }
    },
    {
      path: "orders",
      component: CPA,
      meta: {
        permissionType: "component",
        permissionCode: "00ADS/M01CPA",
        code: "M01CPA",
        layout: "ant-layout",
        title: "CPA"
      }
    },
    {
      path: "campaigns",
      component: CPC,
      meta: {
        permissionType: "component",
        permissionCode: "00ADS/M02CPC",
        code: "M02CPC",
        layout: "ant-layout",
        title: "CPC"
      }
    },
    {
      path: "referrers",
      component: Referrers,
      meta: {
        permissionType: "component",
        permissionCode: "00ADS/M02REF",
        code: "M02REF",
        layout: "ant-layout",
        title: i18n.t("referrers")
      }
    }
  ]
}
