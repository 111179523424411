import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PA_showDeleteListModal: false
})

const getters = {
  PA_showDeleteListModal: (state) => state.PA_showDeleteListModal
}

const mutations = {
  PA_showDeleteListModal(state, value) {
    state.PA_showDeleteListModal = value
  }
}

const actions = {
  PA_deleteListFromAttribute({ commit, dispatch, getters }) {
    // show loader
    commit("PC_showLoader", true)

    let attribute = {
      description: getters.PA_searchedValuesOfAttribute[0].description,
      attribute: getters.PA_selectedAttribute.code,
      action: "DELETE"
    }

    // start request
    API_MODERATION.patch(`${apiUrls.lists}`, attribute)
      .then((r) => r.message)
      .then((r) => {
        // reset values of secondary tables and restart main table
        commit("PA_resetValuesOfAttributeValues")
        commit("PA_resetAtlsOfAttributeValues")
        dispatch("PA_searchAttributesForSelected")

        // change value type in PA_selectedAttribute.value_type
        commit("PA_selectedAttributeType", "STRING")

        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Атрибут отвязан от списка" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
