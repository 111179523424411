import ADS from "./ADS/en"
import common from "./common/en"
import MPAdmin from "./MPAdmin/en"
import messenger from "./Messenger/en"
import Tags from "./Tags/en"
import Moderation from "./Moderation/en"
import Auth from "./Auth/en"
import UserManagment from "./UserManagment/en"

export default {
  ...ADS,
  ...common,
  ...MPAdmin,
  ...messenger,
  ...Tags,
  ...Moderation,
  ...Auth,
  ...UserManagment
}
