import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import axios from "axios"

const state = () => ({
  PC_connectorsModal: false,
  PC_connectorsSources: false,
  PC_connectorsSourcesSelect: false,
  PC_selectedConnectorsSource: 1,
  PC_productConnections: false,
  PC_urlToCheckInSource: false
})

const getters = {
  PC_connectorsModal: (state) => state.PC_connectorsModal,
  PC_connectorsSources: (state) => state.PC_connectorsSources,
  PC_connectorsSourcesSelect: (state) => state.PC_connectorsSourcesSelect,
  PC_selectedConnectorsSource: (state) => state.PC_selectedConnectorsSource,
  PC_productConnections: (state) => state.PC_productConnections,
  PC_urlToCheckInSource: (state) => state.PC_urlToCheckInSource
}

const mutations = {
  PC_connectorsModal(state, value) {
    state.PC_connectorsModal = value
  },
  PC_connectorsSources(state, value) {
    state.PC_connectorsSources = value
  },
  PC_connectorsSourcesSelectSet(state, value) {
    state.PC_connectorsSourcesSelect = value
  },
  PC_connectorsSourcesSelect(state, value) {
    state.PC_connectorsSourcesSelect = value.map((item) => {
      return { label: item.name, value: item.name }
    })
    state.PC_connectorsSourcesSelect.unshift({ label: "Выберите источник", value: 1 })
  },
  PC_selectedConnectorsSource(state, value) {
    state.PC_selectedConnectorsSource = value
  },
  PC_productConnections(state, value) {
    state.PC_productConnections = value
  },
  PC_productConnectionsPush(state, value) {
    if (!state.PC_productConnections) state.PC_productConnections = []
    state.PC_productConnections.push(value)
  },
  PC_urlToCheckInSource(state, value) {
    state.PC_urlToCheckInSource = value
  },
  PC_removeAddedConnectionFromTable(state, index) {
    state.PC_productConnections.splice(index, 1)
  }
}

const actions = {
  async PC_connectorsModal({ commit, getters, dispatch }, product) {
    try {
      await dispatch("PC_resetConnectorsModalValues")
      commit("PC_showLoader", true)

      commit("PC_setEditableProduct", product)
      await dispatch("PC_connectorsSources")
      await dispatch("PC_productConnections", product)

      commit("PC_connectorsModal", true)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_connectorsSources({ commit }) {
    return API_MODERATION.get(`${apiUrls.connectors}`)
      .then((r) => r.data.message)
      .then((r) => {
        commit("PC_connectorsSources", r)
        commit("PC_connectorsSourcesSelect", r)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  async PC_productConnections({ commit }, product) {
    let params = {
      product_code: product.code
    }
    return API_MODERATION.get(`${apiUrls.connections}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        commit("PC_productConnections", r)
        return r
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  async PC_selectConnectorsSource({ commit, getters }, source) {
    if (source === 1) return
    let selected = getters.PC_connectorsSources.find((item) => {
      return item.name === source
    })
    commit("PC_selectedConnectorsSource", selected)
  },
  async PC_removeAddedConnection({ commit, dispatch }, connection) {
    try {
      commit("PC_showLoader", true)
      await dispatch("PC_removeAddedConnectionRequest", connection)
      await dispatch("PC_removeAddedConnectionFromTable", connection)
    } catch (e) {
      console.log(e)
    } finally {
      commit("PC_showLoader", false)
    }
  },
  async PC_removeAddedConnectionRequest({}, connection) {
    let params = {
      parser_id: connection.parser_id
    }
    return API_MODERATION.delete(`${apiUrls.connections}`, { params })
      .then((r) => r.data.message)
      .then((r) => {
        return r
      })
      .catch((e) => {
        commit("handleError", e)
        return Promise.reject()
      })
  },
  async PC_removeAddedConnectionFromTable({ commit, getters }, connection) {
    let index = false
    let connections = getters.PC_productConnections
    for (let i = 0; i < connections.length; i++) {
      if (connections[i].parser_id === connection.parser_id) {
        index = i
        break
      }
    }
    if (!index === false) return
    commit("PC_removeAddedConnectionFromTable", index)
  },
  async PC_resetConnectorsModalValues({ commit }) {
    commit("PC_connectorsSources", false)
    commit("PC_connectorsSourcesSelectSet", false)
    commit("PC_selectedConnectorsSource", 1)
    commit("PC_productConnections", false)
    commit("PC_urlToCheckInSource", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
