<template>
  <div>
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      hideRequiredMark
      class="tag-settings"
    >
      <a-form-model-item
        :label="$t('name')"
        prop="title"
      >
        <a-input v-model="form.title" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('slug')"
        prop="slug"
      >
        <a-input-group compact>
          <a-input
            v-model="form.slug"
            :disabled="slugDisabled"
            style="width: 90%"
          />

          <a-button
            style="width: 10%"
            @click="handleSlugEdit"
          >
            <a-icon type="edit" />
          </a-button>
        </a-input-group>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('noteFromManager')"
        prop="_note"
      >
        <a-textarea
          :placeholder="$t('startPrint')"
          :rows="2"
          v-model="form._note"
        />
      </a-form-model-item>
    </a-form-model>

    <OffersTable
      ref="offersTable"
      :slug="tag.slug"
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed } from "vue"
import i18n from "@/i18n"
import OffersTable from "./OffersTable.vue"

const props = defineProps({
  tag: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["setEdited"])

const formRef = ref()
const form = reactive({
  title: props.tag?.title || "",
  slug: props.tag?.slug || "",
  _note: props.tag?._note || ""
})
const formRules = computed(() => ({
  title: [requiredRule.value],
  slug: [requiredRule.value],
  _note: noteRules.value
}))

const slugDisabled = ref(true)

const handleSlugEdit = () => {
  slugDisabled.value = !slugDisabled.value
}

const disableSlugInput = () => {
  slugDisabled.value = true
}

const getSettingsFormValues = () => {
  return new Promise(async (res) => {
    try {
      await formRef.value.validate()
      res(form)
    } catch {
      res(null)
    }
  })
}

const requiredRule = computed(() => ({
  required: true,
  message: i18n.t("fieldMustNotBeBlank")
}))

const noteRules = computed(() => [
  {
    max: 500,
    message: `${i18n.t("maxNoteError")}500`
  }
])

defineExpose({
  disableSlugInput,
  getSettingsFormValues
})
</script>

<style lang="scss" scoped>
.tag-settings {
  display: flex;
  justify-content: space-between;

  & > * {
    flex-basis: calc(25% - 16px);
  }

  & > :last-child {
    flex-basis: calc(50% - 16px);
  }
}
</style>
