<template>
  <a-drawer
    :visible="isModalOpen"
    :title="$t('synonymCreating')"
    :width="600"
    @close="$emit('close')"
  >
    <a-textarea
      :placeholder="$t('synonymSeparated')"
      :autoSize="{ minRows: 4, maxRows: 10 }"
      v-model="synonyms"
    />

    <span class="drawer-footer">
      <a-button
        type="primary"
        :loading="loading"
        :disabled="!isValid"
        @click="onSave"
      >
        {{ $t("create") }}
      </a-button>
    </span>
  </a-drawer>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import { createSynonyms } from "@/modules/MPAdmin/services/searchSettingsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["close"])

const loading = ref(false)
const synonyms = ref("")

const resetCreateData = () => {
  synonyms.value = ""
}

const isValid = computed(() => synonyms.value.length > 2 && synonyms.value.includes(","))

const onSave = async () => {
  const actionData = {
    synonyms: synonyms.value.split(/,|, | , |\n/).map((item) => item.trim())
  }

  try {
    loading.value = true

    await createSynonyms(actionData)

    notification.success({ message: i18n.t("created") })
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    loading.value = false
  }
}

watch(() => props.isModalOpen, resetCreateData)
</script>
