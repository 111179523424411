<template>
  <div class="attributes-edit__conversion">
    <div class="row">
      <div class="col conversion__select-section">
        <label class="select-section__label"><span>Новый тип значений:</span></label>
        <div class="select-section__select">
          <Select
            v-if="AE_selectedAttr && AE_tableData.items.length"
            :values="AE_valueTypesGet"
            :selected="AE_selectedValueTypeGet"
            :disabled="!allowConversion"
            :highlighted="!allowConversion"
            @change="onSelectValueType"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col conversion__autocomplete-section">
        <label class="autocomplete-section__label"><span>Единица измерения:</span></label>
        <div class="autocomplete-section__unit">
          <strong>{{
            unitsAutocomplete.selectedObject && unitsAutocomplete.selectedObject.name
          }}</strong>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <span
            >Доступные единицы измерения:
            <strong v-if="AE_selectedValueTypeGet == 'NUMERIC' && possibleUnits">{{
              unitsString
            }}</strong>
          </span>
        </label>
        <Button
          class="btn__change-atribute"
          @click="showModalChangeAttr"
          :disabled="!allowChanging"
        >
          Заменить атрибут
        </Button>
      </div>
    </div>
    <ConfirmConversionModal />
    <WrongValueTypeModal />
    <ChangeAttributeModal />
    <ConversionToListsModal />
    <ConversionBooleanModal />
  </div>
</template>
<script>
import Select from "components/Select/Select"
import Autocomplete from "components/Autocomplete"
import Button from "components/Button"
import { mapState, mapMutations, mapActions } from "vuex"
import ConfirmConversionModal from "./ConfirmConversionModal/ConfirmConversionModal"
import WrongValueTypeModal from "./WrongValueTypeModal/WrongValueTypeModal"
import ChangeAttributeModal from "./ChangeAttributeModal/ChangeAttributeModal"
import ConversionToListsModal from "./ConversionModal/ConversionToListsModal"
import ConversionBooleanModal from "./ConversionModal/ConversionBooleanModal"

export default {
  components: {
    ConfirmConversionModal,
    WrongValueTypeModal,
    Select,
    Autocomplete,
    Button,
    ChangeAttributeModal,
    ConversionToListsModal,
    ConversionBooleanModal
  },
  computed: {
    allowConversion() {
      if (this.AE_selectedAttr.value_type == "TEMP" && this.AE_selectedValueTypeGet !== "TEMP") {
        return false
      }
      if (this.AE_selectedAttr.value_type == this.AE_selectedValueTypeGet) {
        return true
      }
      return false
    },
    allowChanging() {
      return (
        this.AE_selectedAttr && this.AE_selectedAttr.value_type === this.AE_selectedValueTypeGet
      )
    },
    unitsString() {
      if (!this.possibleUnits) return null
      return this.possibleUnits
        .map((item) => {
          return item.name
        })
        .join(", ")
    },
    ...mapState("ProductCatalog/attributesEdit", {
      AE_valueTypesGet: (state) => state.conversion.AE_valueTypes,
      AE_selectedValueTypeGet: (state) => state.conversion.AE_selectedValueType,
      AE_selectedAttr: (state) => state.search.AE_selectedAttr,
      AE_tableData: (state) => state.table.AE_tableData,
      unitsAutocomplete: (state) => state.conversion.unitsAutocomplete,
      possibleUnits: (state) => state.search.possibleUnits,
      tempValueTypeGet: (state) => state.conversion.tempValueType
    })
  },
  methods: {
    onSelectValueType(value) {
      if (value == this.AE_selectedValueTypeGet) return
      if (
        (value === "BOOLEAN" && this.AE_selectedValueTypeGet === "STRING") ||
        (value === "BOOLEAN" && this.AE_selectedValueTypeGet === "TEMP")
      ) {
        this.AE_conversionBooleanModal(true)
        return
      }
      if (this.AE_selectedValueTypeGet === "BOOLEAN" && value !== "STRING") {
        this.AE_wrongValueTypeModal(true)
        return
      }
      if (value === "STRING" && this.AE_selectedValueTypeGet === "BOOLEAN") {
        this.AE_conversionBooleanModal(true)
        return
      }
      if (
        value !== "STRING" &&
        this.AE_selectedValueTypeGet !== "TEMP" &&
        (this.AE_selectedValueTypeGet === "LIST" || this.AE_selectedValueTypeGet === "MULTILIST")
      ) {
        this.AE_wrongValueTypeModal(true)
        return
      }
      if ((value == "LIST" || value == "MULTILIST") && this.AE_selectedValueTypeGet !== "NUMERIC") {
        this.getAllLists()
        this.AE_conversionToListsModal(value)
        return
      }
      if (value !== "STRING" && value !== "NUMERIC" && this.AE_selectedValueTypeGet !== "TEMP") {
        this.AE_wrongValueTypeModal(true)
        return
      }
      this.AE_confirmConversionModal(true)
      this.tempValueType(value)
    },
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_selectedValueType: "AE_selectedValueType",
      tempValueType: "tempValueType",
      AE_confirmConversionModal: "AE_confirmConversionModal",
      AE_wrongValueTypeModal: "AE_wrongValueTypeModal",
      AE_changeAttributeModal: "AE_changeAttributeModal",
      AE_conversionToListsModal: "AE_conversionToListsModal",
      AE_conversionBooleanModal: "AE_conversionBooleanModal"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_applyConversion: "AE_applyConversion",
      fetchLists: "fetchLists"
    }),
    showModalChangeAttr() {
      this.AE_changeAttributeModal(true)
    },
    getAllLists() {
      try {
        this.fetchLists()
      } catch (e) {
        commit("ProductCatalog/handleError", e, { root: true })
      }
    }
  }
}
</script>
<style lang="scss">
.attributes-edit {
  &__conversion {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 15px 15px;
    height: 160px;

    .conversion {
      &__select-section {
        display: flex;

        .select-section {
          &__label {
            width: 35%;
            margin-right: 10px;
          }

          &__select {
            width: 40%;
            margin-right: 10px;
          }

          &__button {
            width: 25%;
          }

          &__highlighted {
            color: $danger-color;
          }
        }
      }

      &__autocomplete-section {
        display: flex;

        .autocomplete-section {
          &__label {
            width: 35%;
            margin-right: 10px;
          }

          &__autocomplete,
          &__unit {
            width: 40%;
            margin-right: 10px;
          }

          &__button {
            width: 25%;
          }
        }
      }
    }
  }
}

@media (max-width: 1459px) {
  .attributes-edit {
    &__conversion {
      .conversion {
        &__select-section {
          .select-section {
            &__label {
              width: 50%;
            }

            &__select {
              width: 50%;
              margin-right: 0;
            }
          }
        }

        &__autocomplete-section {
          .autocomplete-section {
            &__label {
              width: 50%;
            }

            &__unit {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
