<template>
  <div class="user-name">
    <div>{{ row.username }}</div>
    <div class="user-name__name">
      {{ fullName }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  row: {
    type: Object,
    default: () => ({})
  }
})

const fullName = computed(() => {
  return [props.row.last_name, props.row.first_name, props.row.middle_name]
    .filter(Boolean)
    .join(" ")
})
</script>

<style scoped lang="scss">
.user-name {
  display: flex;
  flex-direction: column;
  font-weight: 500;

  &__name {
    font-weight: 400;
    text-transform: capitalize;
  }
}
</style>
