import { mapState } from "vuex"

export default {
  computed: {
    possibleUnits() {
      let selectObj = []
      let units = JSON.parse(JSON.stringify(this.possibleUnitsGet)) // clone object

      for (let i = 0; i < units.length; i++) {
        selectObj.push({
          label: units[i].name,
          value: units[i].code
        })
      }
      return selectObj
    },
    ...mapState("ProductCatalog/attributesEdit", {
      possibleUnitsGet: (state) => state.search.possibleUnits
    })
  }
}
