import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo = {} } = { sortedInfo: {} }) => {
  const columns = computed(() => [
    {
      key: "date_created",
      dataIndex: "date_created",
      title: i18n.t("dateCreated"),
      scopedSlots: { customRender: "dateRenderer" },
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "date_created" && sortedInfo.value.order,
      fixed: "left",
      width: 180
    },
    {
      key: "seller.slug",
      dataIndex: "seller.name",
      title: i18n.t("seller"),
      fixed: "left",
      width: 170
    },
    {
      key: "label",
      dataIndex: "label",
      title: "Custom label",
      width: 170
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("campaignStatus"),
      scopedSlots: { customRender: "status" },
      width: 100
    },
    {
      key: "balance",
      dataIndex: "balance",
      title: i18n.t("balance"),
      sortOrder: sortedInfo.value.columnKey === "balance" && sortedInfo.value.order,
      sorter: true,
      width: 170
    },
    {
      key: "yesterday_expenses",
      dataIndex: "yesterday_expenses",
      title: i18n.t("consumptionYesterday"),
      sortOrder: sortedInfo.value.columnKey === "yesterday_expenses" && sortedInfo.value.order,
      sorter: true,
      width: 170
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      fixed: "right",
      width: 60
    }
  ])

  const expensesColumns = computed(() => [
    {
      dataIndex: "day",
      title: i18n.t("date"),
      width: "110px"
    },
    {
      dataIndex: "cost",
      title: i18n.t("sum"),
      width: "170px"
    }
  ])

  return {
    columns,
    expensesColumns
  }
}
