var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"select-wrap label",style:({ 'margin-bottom': `${_vm.marginBottom}px` }),on:{"click":_vm.openOptions}},[(_vm.label)?_c('span',{class:{ 'label-isError': _vm.isError }},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.fetching)?_c('Loader'):_vm._e()],1):_vm._e(),_c('div',{ref:"select",staticClass:"selection",class:{
      'active visible': _vm.showMenu,
      error: _vm.isError,
      disabled: _vm.isDisabled
    }},[_c('a-icon',{staticClass:"select-icon",attrs:{"type":"down"}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDisabled),expression:"!isDisabled"}],ref:"input",staticClass:"search",attrs:{"autocomplete":"off","tabindex":"0","id":_vm.id,"name":_vm.name},domProps:{"value":_vm.searchText},on:{"input":_vm.onInput,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeOptions.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.enterItem.apply(null, arguments)}],"blur":_vm.blurInput,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.deleteTextOrItem.apply(null, arguments)}]}}),_c('div',{staticClass:"text-wrapper"},[_c('div',{staticClass:"text",class:_vm.textClass,attrs:{"data-vss-custom-attr":_vm.searchTextCustomAttr},on:{"mousedown":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.inputText)+" ")])]),(_vm.portal)?[_c('portal',{attrs:{"to":"select"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu && _vm.filteredOptions.length && !_vm.isDisabled),expression:"showMenu && filteredOptions.length && !isDisabled"}],ref:"menu",staticClass:"basic-selection__menu",class:_vm.menuClass,style:({
            position: 'absolute',
            left: `${_vm.offset.left}px`,
            top: `${_vm.offset.top + _vm.elementHeight + _vm.elementMargin}px`,
            width: `${_vm.elementWidth}px`,
            'z-index': '1000'
          }),attrs:{"tabindex":"-1"},on:{"mousedown":function($event){$event.preventDefault();}}},_vm._l((_vm.filteredOptions),function(option,idx){return _c('Item',{key:option.value,attrs:{"option":option,"customAttr":_vm.customAttrs[idx] ? _vm.customAttrs[idx] : '',"selectItem":_vm.selectItem,"mousedownItem":_vm.mousedownItem,"withoutLine":true,"tree":_vm.tree,"item":option,"labelKey":_vm.labelKey,"valueKey":_vm.valueKey,"selectedValue":_vm.selectedValue,"searchText":_vm.searchText}})}),1)])]:[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu && _vm.filteredOptions.length && !_vm.isDisabled),expression:"showMenu && filteredOptions.length && !isDisabled"}],ref:"menu",staticClass:"basic-selection__menu",class:_vm.menuClass,style:(_vm.getStyle),attrs:{"tabindex":"-1"},on:{"mousedown":function($event){$event.preventDefault();}}},_vm._l((_vm.filteredOptions),function(option,idx){return _c('Item',{key:option.value,attrs:{"option":option,"customAttr":_vm.customAttrs[idx] ? _vm.customAttrs[idx] : '',"selectItem":_vm.selectItem,"mousedownItem":_vm.mousedownItem,"withoutLine":true,"tree":_vm.tree,"item":option,"labelKey":_vm.labelKey,"valueKey":_vm.valueKey,"selectedValue":_vm.selectedValue,"searchText":_vm.searchText}})}),1)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }