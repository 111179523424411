<template>
  <a-drawer
    :visible="showModal"
    @close="$emit('close')"
    :title="$t('courseHistory')"
    :width="700"
  >
    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      rowKey="uuid"
      :columns="columns"
      :pagination="paginationInfo"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>
    </a-table>
  </a-drawer>
</template>

<script setup>
import { watch } from "vue"

import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/"
import { getBonusHistoryRate } from "@/modules/MPAdmin/services/bonusesService.js"

import useBonusesPageColumns from "../useBonusesPageColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const { historyRateColumns: columns } = useBonusesPageColumns()
const {
  dataSource,
  isFetching,

  paginationInfo,

  fetchTableInfo,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: getBonusHistoryRate
})

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

watch(
  () => props.showModal,
  (val) => {
    if (!val) return
    handleTableFiltersReset()
  }
)
</script>
