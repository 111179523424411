<template>
  <a-drawer
    width="730px"
    :title="`${$t('referrer')} ${referrer?.code}`"
    placement="right"
    :closable="true"
    :visible="visible"
    @close="handleClose"
  >
    <div class="referref-info">
      <div>{{ $t("avaliableForWriteOff") }}:</div>
      <div>{{ getFormatedSum(referrer?.available_total) }} {{ $t("uah") }}</div>
      <div>
        {{ referrer?.available_count }}
        {{ $tc("position", referrer?.available_count).toLowerCase() }}
      </div>
      <a-button
        type="primary"
        :loading="fetchingSubtract"
        @click="handleSubtractRef"
      >
        {{ $t("writeOff") }}
      </a-button>
    </div>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="handleClose"
      >
        {{ $t("close") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { subtractRef } from "@/modules/ADS/services/refferersServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

import getFormatedSum from "@/utils/getFormatedSum.js"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  referrer: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["update", "close"])

const fetchingSubtract = ref(false)

const handleSubtractRef = async () => {
  try {
    fetchingSubtract.value = true

    await subtractRef(props.referrer?.code)

    notification.success({
      message: i18n.t("success")
    })
    handleClose()
    emit("update")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    fetchingSubtract.value = false
  }
}

const handleClose = () => {
  emit("close")
}
</script>

<style lang="scss" scoped>
.referref-info {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 4px;

  width: 30%;
  font-size: 16px;

  & > :first-child {
    font-weight: 500;
    margin-bottom: 10px;
  }
}
</style>
