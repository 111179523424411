import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearchComplex from "../../utils/buildMagicSearchComplex"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"
import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

const state = () => ({
  PT_typesTableQueryParams: {
    limit: 10,
    ordering: ["-timestamp"],
    offset: 0
  },
  PT_typesTableData: { count: 0, items: [] },
  PT_typesTableFetchingStatus: false,
  PT_selectedType: false
})

const getters = {
  PT_typesTableQueryParams: (state) => state.PT_typesTableQueryParams,
  PT_typesTableData: (state) => state.PT_typesTableData,
  PT_typesTableFetchingStatus: (state) => state.PT_typesTableFetchingStatus,
  PT_selectedType: (state) => state.PT_selectedType
}

const mutations = {
  PT_typesTableQueryParams(state, value) {
    state.PT_typesTableQueryParams = value
  },
  PT_typesTableData(state, value) {
    state.PT_typesTableData = value
  },
  PT_typesTableFetchingStatus(state, value) {
    state.PT_typesTableFetchingStatus = value
  },
  PT_typesTableSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    state.PT_typesTableData.items[rowIndex][columnKey] = newValue
  },
  PT_typesTableSetNewArrayValue: (state, { newValue, rowIndex }) => {
    state.PT_typesTableData.items[rowIndex].synonyms = newValue
  },
  PT_selectedType(state, value) {
    state.PT_selectedType = value
  },
  PT_resetProductTypeValues(state) {
    state.PT_typesTableQueryParams = {
      limit: 10,
      ordering: ["-timestamp"],
      offset: 0
    }
    state.PT_typesTableData = { count: 0, items: [] }
  }
}

const actions = {
  async PC_searchTypes({ commit, dispatch, getters }, queryParams) {
    commit("PT_typesTableData", { count: 0, items: [] })
    let types = await dispatch("PC_searchTypesInDb", queryParams)
    if (!types || !types.length) return

    // set [0] item as selected
    commit("PT_selectedType", types[0])
    // update attributes table
    dispatch("PT_searchAttributes", types[0])
  },
  async PC_searchTypesInDb({ commit, dispatch, getters }, queryParams) {
    let fields = ["code", "full_name", "name", "synonyms", "timestamp", "status"]
    let resource = "PTD"

    let sort = buildSortValue(queryParams.ordering[0])
    let sendData = buildMagicSearchComplex(queryParams, fields, resource, sort)

    let typesCount
    let types = []
    // set fetching status
    commit("PT_typesTableFetchingStatus", true)

    return API_MODERATION.post(
      // search types
      `${apiUrls.searchMagicV2}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        typesCount = message.count
        types = message.data

        let items = buildTypesTableItems(types)

        let tableObj = {
          count: typesCount,
          items: items
        }

        commit("PT_typesTableData", tableObj)
        commit("PT_typesTableFetchingStatus", false)

        // update table query params
        commit("PT_typesTableQueryParams", queryParams)

        return types
      })
      .catch((e) => {
        commit("PT_typesTableFetchingStatus", false)
        commit("handleError", e)
        return null
      })
  },
  PT_editTypesName({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PT_typesTableData.items[rowIndex][columnKey]
    if (newValue === oldValue) return

    let sendData = {
      code: getters.PT_typesTableData.items[rowIndex].code,
      product_type: {
        [columnKey]: newValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.type}`, sendData, {
      headers: {
        "Accept-language": getters.PC_selectedLanguage
      }
    })
      .then((r) => {
        commit("PT_typesTableSetNewValue", { columnKey, newValue, rowIndex })

        // hide loader
        commit("PC_showLoader", false)

        notification.info({ message: "Изменения продукта добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
      })
  },
  PT_editTypesSynonym({ getters, commit }, { columnKey, newValue, rowIndex }) {
    let oldValue = getters.PT_typesTableData.items[rowIndex].synonymsNames

    if (newValue === oldValue) return

    let arrayNewValue = newValue.split(",").map((item) => {
      return item.trim()
    })

    let sendData = {
      code: getters.PT_typesTableData.items[rowIndex].code,
      product_type: {
        synonyms: arrayNewValue
      }
    }

    // show loader
    commit("PC_showLoader", true)

    API_MODERATION.put(`${apiUrls.type}`, sendData)
      .then((r) => {
        commit("PT_typesTableSetNewValue", { columnKey, newValue, rowIndex })

        // hide loader
        commit("PC_showLoader", false)

        commit("PT_typesTableSetNewArrayValue", { newValue: arrayNewValue, rowIndex }) // set array value as well

        notification.info({ message: "Изменения продукта добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function buildSortValue(colName) {
  let sort = colName
  switch (colName) {
    case "synonymsNames":
      sort = "synonyms"
      break
    case "-synonymsNames":
      sort = "-synonyms"
      break
    case "time":
      sort = "timestamp"
      break
    case "-time":
      sort = "-timestamp"
      break
  }
  return sort
}

function buildTypesTableItems(types) {
  return types.map((item, index) => {
    if (item.synonyms) {
      item.synonymsNames = (item.synonyms && item.synonyms.join(", ")) || ""
    }
    // time
    item.time = getFormatedDateTimeByTimestamp(item.timestamp)
    // id
    item._id = "row-" + index
    return item
  })
}
