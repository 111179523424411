<template>
  <a-form
    :form="deliveryForm"
    style="margin-bottom: 40px"
  >
    <!-- NAME -->
    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label>{{ $t("name") }}, UA ({{ ukNameLength }}/{{ nameMaxLength }})</template>
      <a-input
        v-decorator="[
          'name.uk',
          {
            rules: nameRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (ukNameLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label>{{ $t("name") }}, EN ({{ enNameLength }}/{{ nameMaxLength }}) </template>
      <a-input
        v-decorator="[
          'name.en',
          {
            rules: nameRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (enNameLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <template #label>{{ $t("name") }}, RU ({{ ruNameLength }}/{{ nameMaxLength }}) </template>
      <a-input
        v-decorator="[
          'name.ru',
          {
            rules: nameRules,
            initialValue: ''
          }
        ]"
        @change="(e) => (ruNameLength = e.target.value.length || 0)"
      />
    </a-form-item>

    <!-- DELIVERY TYPE -->
    <a-form-item
      :label="$t('deliveryType')"
      :colon="false"
      style="margin: 0"
    >
      <a-select
        :placeholder="$t('chooseType')"
        :options="deliveryTypes"
        v-decorator="['type', { rules: [{ required: true, message: $t('emptyError') }] }]"
        style="width: 100%"
      />
    </a-form-item>

    <!-- DELIVERY SERVICE -->
    <a-form-item
      :label="$t('deliveryService')"
      :colon="false"
      style="margin: 0"
    >
      <a-select
        :placeholder="$t('chooseDeliveryService')"
        :options="deliveryServices"
        v-decorator="['service', { rules: [{ required: true, message: $t('emptyError') }] }]"
        style="width: 100%"
      />
    </a-form-item>

    <!-- SORT -->
    <a-form-item
      :label="$t('outputSortOrder')"
      :colon="false"
      style="margin: 0"
    >
      <a-input-number
        v-decorator="[
          'ordering',
          {
            initialValue: 0,
            rules: [{ required: true, message: $t('emptyError') }]
          }
        ]"
        :min="0"
        :max="999"
        :precision="0"
      />
    </a-form-item>

    <!-- DESCRIPTION (CURRENTLY DISABLED IN SNT-6435) -->
    <!-- <a-form-item :colon="false" style="margin: 0">
        <template #label
          >{{ $t("description") }} ({{ descriptionLength }}/{{ descriptionMaxLength }})
        </template>
        <a-textarea
          v-decorator="[
            'description',
            {
              rules: descriptionRules,
              initialValue: ''
            }
          ]"
          @change="(e) => (descriptionLength = e.target.value.length || 0)"
        />
      </a-form-item> -->

    <!-- IS SELLER FILTERING -->
    <a-form-item
      :colon="false"
      style="margin: 0"
    >
      <a-checkbox v-decorator="['is_seller_filter', { valuePropName: 'checked' }]">
        {{ $t("takeFilteringBySellers") }}
      </a-checkbox>
    </a-form-item>

    <!-- PAYMENT METHODS -->
    <a-form-item
      :label="$t('supportedPayments')"
      :colon="false"
      style="margin: 0"
    >
      <a-checkbox-group
        v-decorator="[
          'payment_methods',
          { rules: [{ required: true, message: $t('emptyError') }] }
        ]"
        class="margin-top"
        style="display: flex; flex-flow: column nowrap; gap: 8px"
      >
        <a-checkbox
          v-for="paymentMethod of paymentMethods"
          :key="paymentMethod.value"
          :value="paymentMethod.value"
          style="
            margin: 0;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
        >
          {{ paymentMethod.label }}
        </a-checkbox>
      </a-checkbox-group>
    </a-form-item>

    <!-- IS ACTIVE -->
    <a-form-item
      :label="$t('deliveryMethodActivity')"
      :colon="false"
      style="margin: 0"
    >
      <a-switch
        v-decorator="[
          'is_active',
          {
            valuePropName: 'checked',
            initialValue: false
          }
        ]"
        :checkedChildren="$t('yes')"
        :unCheckedChildren="$t('no')"
      />
    </a-form-item>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="$emit('close')"
      >
        {{ $t("close") }}
      </a-button>
      <a-button
        type="primary"
        class="form-button-padding"
        :loading="fetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import {
  createDeliveryMethod,
  updateDeliveryMethod
} from "@/modules/MPAdmin/services/generalSettingService/deliveryMethodsService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  name: "CreateDeliveryMethodsModal",

  props: {
    prototype: {
      type: Object,
      default: () => ({})
    },
    deliveryTypes: {
      type: Array,
      default: () => []
    },

    deliveryServices: {
      type: Array,
      default: () => []
    },

    paymentMethods: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      deliveryForm: this.$form.createForm(this, {
        name: "deliveryMethodModal",
        mapPropsToFields: () => {
          const { name, type, payment_methods, service, ordering, is_seller_filter, is_active } =
            this.prototype
          return {
            "name.uk": this.$form.createFormField({
              value: name?.uk || ""
            }),
            "name.en": this.$form.createFormField({
              value: name?.en || ""
            }),
            "name.ru": this.$form.createFormField({
              value: name?.ru || ""
            }),
            "name.uk": this.$form.createFormField({
              value: this.prototype?.name?.uk || ""
            }),
            type: this.$form.createFormField({
              value: type ? Object.keys(type)[0] : []
            }),
            payment_methods: this.$form.createFormField({
              value: payment_methods ? payment_methods.map((item) => item.uuid) : []
            }),
            service: this.$form.createFormField({
              value: service
            }),
            ordering: this.$form.createFormField({
              value: ordering
            }),
            is_seller_filter: this.$form.createFormField({
              value: is_seller_filter
            }),
            is_active: this.$form.createFormField({
              value: is_active
            })
          }
        }
      }),

      fetching: false,

      nameMaxLength: 200,
      descriptionMaxLength: 1000,
      ukNameLength: 0,
      enNameLength: 0,
      ruNameLength: 0,
      descriptionLength: 0
    }
  },

  mounted() {
    this.fetchData()

    this.ukNameLength = this.prototype?.name?.uk?.length || 0
    this.enNameLength = this.prototype?.name?.en?.length || 0
    this.ruNameLength = this.prototype?.name?.ru?.length || 0
    this.descriptionLength = this.prototype.description?.length || 0
  },

  computed: {
    nameRules() {
      return [
        { required: true, message: this.$t("fieldRequire") },
        {
          max: this.nameMaxLength,
          message: this.$t("maxLengthError", { length: this.nameMaxLength })
        }
      ]
    },
    descriptionRules() {
      return [{ max: 1000, message: this.$t("maxLengthError", { length: 1000 }) }]
    }
  },

  methods: {
    async fetchData() {},

    onSave() {
      this.deliveryForm.validateFields(async (err, values) => {
        if (err) return
        this.fetching = true

        try {
          if (this.prototype.id) {
            const { data } = await updateDeliveryMethod(values, this.prototype?.id || null)

            const payment_methods = data?.payment_methods.map((methodId) => {
              const method = this.paymentMethods.find((item) => item.value === methodId)

              if (!method) return methodId
              return { name: method.label, uuid: method.value }
            })
            this.$emit("updateMethod", { ...data, payment_methods })
          } else {
            await createDeliveryMethod(values)
            this.$emit("createMethod")
          }

          this.$notification.success({
            message: this.$t(this.prototype.id ? "updated" : "created")
          })
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.fetching = false
        }
      })
    }
  }
}
</script>
