<template>
  <transition name="modal">
    <div
      v-show="showModal"
      class="modal"
      :style="getStyles"
      :class="[className]"
    >
      <div
        @mousedown.self="$emit('close')"
        class="modal__mask"
      >
        <div
          class="modal__container"
          :class="containerClassList"
          :style="[{ width: style.width }, marginAutoStyle]"
          ref="container"
        >
          <div
            v-show="hasSlot('header') || title"
            class="modal__header"
            :class="headerClassName"
          >
            <h3
              v-if="title"
              class="modal__header--title"
            >
              {{ title }}
            </h3>
            <button
              type="button"
              class="modal__header--close"
              @click="$emit('close')"
            >
              &times;
            </button>
            <slot name="header"></slot>
          </div>

          <div
            class="modal__body"
            :class="bodyClassName"
          >
            <slot></slot>
          </div>

          <div
            v-show="hasSlot('footer') || showCloseButton"
            class="modal__footer"
            :class="[footerClassName, { 'modal__footer--with-button': showCloseButton }]"
          >
            <Button
              v-if="showCloseButton"
              color="secondary"
              @click="$emit('close')"
            >
              {{ $t("close") }}
            </Button>
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from "../Button"

export default {
  components: {
    Button
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String, Boolean],
      default: false
    },
    size: {
      type: String,
      default: "small"
    },
    zIndex: {
      type: Number
    },
    className: {
      type: String,
      default: ""
    },
    headerClassName: {
      type: String,
      default: ""
    },
    bodyClassName: {
      type: String,
      default: ""
    },
    footerClassName: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    marginAuto: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    hasSlot(name) {
      if (!this.width) {
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      }
      return []
    }
  },

  computed: {
    style() {
      return { width: Number.isInteger(this.width) ? `${this.width}px` : this.width }
    },
    getStyles() {
      let styles = {}

      if (this.zIndex) {
        styles["z-index"] = this.zIndex
      }

      return styles
    },
    containerClassList() {
      return {
        "modal__container--small": this.size == "small",
        "modal__container--large": this.size == "large",
        "modal__container--extra-large": this.size == "extra-large"
      }
    },
    marginAutoStyle() {
      return this.marginAuto ? { "margin-top": "auto", "margin-bottom": "auto" } : ""
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  overflow: auto;

  &__mask {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    padding: 5em 0;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 2px;
    @media (min-width: 576px) {
      .modal &--small {
        width: 500px;
      }
    }
    @media (min-width: 900px) {
      //added .modal for higher css selector value
      .modal &--large {
        width: 860px;
      }
      .modal &--extra-large {
        width: 1140px;
      }
    }
  }

  &__body {
    padding: 30px 20px;
    position: relative;

    h1 {
      margin-bottom: 0;
    }
  }

  &__header {
    position: relative;
    padding: 20px 55px 20px 20px;
    border-bottom: solid 1px $border-color;
    &--title {
      margin: 0;
    }
    &--close {
      background-color: transparent;
      border: 0;
      -webkit-appearance: none;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      outline: none;
      cursor: pointer;
      padding: 0px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: solid 1px $border-color;
  }
  &__footer--with-button {
    justify-content: flex-end;
  }
  &__footer--with-button > :not(:last-child) {
    margin-right: 0.25rem;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
