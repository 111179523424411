<template>
  <div class="table-cell__new-value">
    <Select
      v-if="row.new_value_type == 'NUMERIC' && unitsAutocomplete.selectedObject"
      :values="possibleUnits"
      :selected="value || null"
      placeholder="Выберите значение"
      @change="onChange"
    />
    <a-icon
      v-if="invalidError"
      type="exclamation-circle"
      class="table-cell__icon-error"
    />
  </div>
</template>
<script>
import Select from "components/Select/Select"
import possibleUnitsMixin from "../../../mixins/possibleUnitsMixin"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  mixins: [possibleUnitsMixin],
  components: {
    Select
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    value: String,
    onUpdate: Function,
    selectedAttr: Object
  },
  computed: {
    invalidError() {
      if (this.row.invalid) {
        return this.row.invalid == "UNIT" || this.row.invalid == "BOTH"
      }
      return false
    },
    ...mapState("ProductCatalog/attributesEdit", {
      unitsAutocomplete: (state) => state.conversion.unitsAutocomplete
    })
  },
  methods: {
    onChange(newValue) {
      if (newValue == this.value) return
      this.onUpdate({
        newValue,
        row: this.row,
        selectedAttrCode: this.selectedAttr.code,
        field: this.field,
        rowIndex: this.rowIndex,
        oldValue: this.value
      })
    }
  }
}
</script>
