import { getElementOffset } from "utils/DOM"

export default {
  openOptions(self) {
    self.$refs.input.focus()
    self.showMenu = true
    self.mousedownState = false

    const select = self.$refs.select

    self.offset = getElementOffset(select)
    self.elementHeight = select.clientHeight
    const width = self.dropdownWidth || select.clientWidth
    self.elementWidth = width
  },

  blurInput(self) {
    if (!self.mousedownState) {
      self.searchText = ""
      self.closeOptions()
    }
  },

  closeOptions(self) {
    self.showMenu = false
  },

  prevItem(self) {
    const prevIndex = self.pointer - 1
    const prevIndexScrollTop = self.$el.offsetHeight * prevIndex
    if (prevIndex >= 0) {
      self.pointer = prevIndex
    }
    self.$refs.menu.scrollTop = prevIndexScrollTop
  },

  nextItem(self) {
    const nextIndex = self.pointer + 1
    const nextIndexScrollTop = self.$el.offsetHeight * nextIndex

    if (nextIndex <= self.filteredOptions.length - 1) {
      self.pointer = nextIndex
    }

    const currentMenuHeight = self.$refs.menu.offsetHeight
    const currentPage = Math.ceil(
      (self.$refs.menu.scrollTop + self.$el.offsetHeight) / currentMenuHeight
    )
    const itemPage = Math.ceil(nextIndexScrollTop / currentMenuHeight)

    if (currentPage !== itemPage) {
      self.$refs.menu.scrollTop = (itemPage - 1) * self.$refs.menu.offsetHeight
    }
  },

  enterItem(self) {
    const currentItem = self.filteredOptions[self.pointer]
    if (currentItem) {
      self.selectItem(currentItem)
    }
  },

  mousedownItem(self) {
    self.mousedownState = true
  }
}
