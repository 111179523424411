const UsersManagement = () => import("./UsersManagement") //lazy load

import Users from "./pages/Users/Users"
import Roles from "./pages/Roles/Roles"
import i18n from "@/i18n"

export default {
  path: "/users-management",
  component: UsersManagement,
  meta: {
    permissionType: "application",
    permissionCode: "UMGMT",
    code: "UMGMT"
  },
  children: [
    {
      path: "users",
      component: Users,
      meta: {
        permissionType: "component",
        permissionCode: "UMGMT/USRCMP",
        code: "USRCMP",
        layout: "ant-layout",
        title: i18n.t("users")
      }
    },
    {
      path: "roles",
      component: Roles,
      meta: {
        permissionType: "component",
        permissionCode: "UMGMT/RLSCMP",
        code: "RLSCMP",
        layout: "ant-layout",
        title: i18n.t("roles")
      }
    }
  ]
}
