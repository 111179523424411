<template>
  <a-badge
    :color="orderModerationStatuses[status].color"
    :text="orderModerationStatuses[status].title"
  />
</template>

<script setup>
import { useStatuses } from "@/composables/useStatuses"

const props = defineProps({
  status: {
    type: String,
    default: ""
  }
})

const { orderModerationStatuses } = useStatuses()
</script>

<style lang="scss" scoped>
.ACTIVE {
  color: $green-color;
}

.ON_MODERATION {
  color: $warning-color;
}
.MODERATION_FAILED {
  color: $red-color;
}
</style>
