<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PB_showCreateBrandModal"
      :title="'Добавление нового бренда'"
    >
      <div>
        <div>
          <label
            ><span>Название бренда<span class="required-field">*</span></span></label
          >
          <Input
            size="medium"
            :value="PB_nameForNewBrandGet"
            @input="onInputName"
          />
          <ValidationErrors
            :lengthError="!validNameLength"
            :uniqueError="!PB_checkUniqueBrandNameGet"
          />
        </div>
        <div class="margin-top-10">
          <label><span>Список возможный синонимов (через запятую)</span></label>
          <Input
            size="medium"
            :value="PB_synonymsForNewBrandGet"
            @input="onInputSynonyms"
          />
          <ValidationErrors
            :lengthError="synonymsLengthError"
            :uniqueError="!PB_checkUniqueBrandSynonymGet"
            :quantityError="showNumberOfValuesError"
            :showRepetativeValuesError="showRepetativeValuesError"
          />
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewBrand"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validArrayItemsCount from "utils/validations/validArrayItemsCount"
import validArrayItemsMaxLength from "utils/validations/validArrayItemsMaxLength"
import validArrayRepetativeItems from "utils/validations/validArrayRepetativeItems"

export default {
  name: "EditProductModal",
  components: {
    Button,
    Modal,
    Input,
    ValidationErrors
  },
  data() {
    return {
      timeoutName: null,
      timeoutSynonyms: null,
      validNameLength: true,
      synonymsLengthError: false,
      showNumberOfValuesError: false,
      showRepetativeValuesError: false
    }
  },
  methods: {
    onInputName(value) {
      // validate length
      this.validNameLength = validMaxLength(value, 50)
      this.checkUniqueBrandName(value)
      // set value
      this.PB_nameForNewBrand(value)
    },
    checkUniqueBrandName(value) {
      clearTimeout(this.timeoutName)
      this.timeoutName = setTimeout(() => {
        this.PB_checkUniqueBrandName(value)
      }, 500)
    },
    onInputSynonyms(value) {
      this.checkUniqueBrandSynonym(value)

      // build array
      let synonymsArray = value.split(",").map((item) => {
        return item.trim()
      })

      this.showNumberOfValuesError = !validArrayItemsCount(synonymsArray, 50)
      this.synonymsLengthError = !validArrayItemsMaxLength(synonymsArray, 50)
      this.showRepetativeValuesError = !validArrayRepetativeItems(synonymsArray)
    },
    checkUniqueBrandSynonym(value) {
      clearTimeout(this.timeoutSynonyms)
      this.timeoutSynonyms = setTimeout(() => {
        this.PB_checkUniqueBrandSynonym({ value })
      }, 500)
      // set value
      this.PB_synonymsForNewBrand(value)
    },
    createNewBrand() {
      this.PB_createNewBrand()
      this.closeModal()
    },
    closeModal() {
      this.PB_showCreateBrandModalSet(false)
      this.resetValues()
    },
    resetValues() {
      this.PB_checkUniqueBrandNameMute(true)
      this.PB_checkUniqueBrandNameSynonym(true)
      this.validNameLength = true
      this.synonymsLengthError = false
      this.showNumberOfValuesError = false
      this.PB_nameForNewBrand("")
      this.PB_synonymsForNewBrand("")
      this.showRepetativeValuesError = false
    },
    ...mapMutations("ProductCatalog", {
      PB_nameForNewBrand: "PB_nameForNewBrand",
      PB_synonymsForNewBrand: "PB_synonymsForNewBrand",
      PB_showCreateBrandModalSet: "PB_showCreateBrandModal",
      PB_checkUniqueBrandNameMute: "PB_checkUniqueBrandName",
      PB_checkUniqueBrandNameSynonym: "PB_checkUniqueBrandSynonym"
    }),
    ...mapActions("ProductCatalog", {
      PB_createNewBrand: "PB_createNewBrand",
      PB_checkUniqueBrandName: "PB_checkUniqueBrandName",
      PB_checkUniqueBrandSynonym: "PB_checkUniqueBrandSynonym",
      PC_searchBrands: "PC_searchBrands"
    })
  },
  computed: {
    enableCreateButton() {
      let show = false

      if (
        this.PB_nameForNewBrandGet &&
        this.validNameLength &&
        this.PB_checkUniqueBrandNameGet &&
        this.PB_checkUniqueBrandSynonymGet &&
        !this.synonymsLengthError &&
        !this.showNumberOfValuesError &&
        !this.showRepetativeValuesError
      ) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PB_nameForNewBrandGet: "PB_nameForNewBrand",
      PB_synonymsForNewBrandGet: "PB_synonymsForNewBrand",
      PB_showCreateBrandModal: "PB_showCreateBrandModal",
      PB_checkUniqueBrandNameGet: "PB_checkUniqueBrandName",
      PB_checkUniqueBrandSynonymGet: "PB_checkUniqueBrandSynonym"
    })
  }
}
</script>
<style lang="scss" scoped></style>
