<template>
  <Bar
    :chartData="chartData"
    :options="{ responsive: true, maintainAspectRatio: false }"
  />
</template>

<script setup>
import { onMounted, ref, computed } from "vue"
import { Bar } from "vue-chartjs"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js"

const props = defineProps({
  data: { type: Array, default: () => [] },
  redColumn: { type: Object, default: () => ({}) },
  blueColumn: { type: Object, default: () => ({}) }
})

const chartData = ref({})

const getWeeks = computed(() => {
  return props.data.map((item) => {
    return item.key
  })
})

const getValues = (columnKey) => {
  return props.data.map((item) => {
    return item[columnKey]
  })
}

onMounted(() => {
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  chartData.value = {
    labels: getWeeks.value,
    datasets: [
      {
        label: props.blueColumn.title,
        backgroundColor: "#2980b9",
        data: getValues(props.blueColumn.key)
      },
      {
        label: props.redColumn.title,
        backgroundColor: "#f87979",
        data: getValues(props.redColumn.key)
      }
    ]
  }
})
</script>
