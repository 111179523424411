<template>
  <div class="permissions">
    <div class="permissions__left">
      <div class="permissions__left-title">
        {{ $t("modelPermissions") }}
      </div>

      <a-spin
        :spinning="fetching"
        class="permissions__list"
      >
        <div
          v-for="item in list"
          :key="item.id"
          class="permissions__list-item"
          :class="{ 'permissions__list-item--selected': selectedPermission.id === item.id }"
          @click.stop="togglePermission(item)"
        >
          <div class="permissions__list-item-left">
            <a-checkbox
              :checked="isPermissionChecked(item.id)"
              style="margin-right: 8px"
              @change="(e) => togglePermissionCheckbox(e.target.checked, item)"
            />
            {{ item.display_name }}
          </div>
          <div
            v-if="crudEnabled && isPermissionCrudShow(item.id)"
            class="permissions__list-item-right"
          >
            <span
              v-for="crudItem in crudArray"
              :key="crudItem"
              class="permissions__crud-item"
              :class="{
                'permissions__crud-item--active': isPermissionCrudActive(item.id, crudItem)
              }"
              @click.stop="permissionCrudChange(item.id, crudItem)"
            >
              {{ crudItem }}
            </span>
          </div>
        </div>
      </a-spin>
    </div>

    <div class="permissions__right">
      <div class="permissions__right-title">
        {{ $t("fields") }}
      </div>

      <div
        v-show="selectedPermission.fields"
        class="permissions__fields-list"
      >
        <div
          v-for="item in selectedPermission.fields"
          :key="item.id"
          class="permissions__fields-list-item"
        >
          <div class="permissions__fields-list-item-left">
            <a-checkbox
              :checked="isFieldChecked(item.id)"
              @change="(e) => toggleFieldCheckbox(e.target.checked, item)"
              :disabled="!isPermissionChecked(item.parent.id)"
            />
            {{ item.display_name }}
          </div>
          <div
            v-if="crudEnabled && isFieldChecked(item.id)"
            class="permissions__fields-list-item-right"
          >
            <span
              v-for="crudItem in crudArray"
              :key="crudItem"
              @click.self="fieldCrudChange(item.id, crudItem)"
              class="permissions__crud-item"
              :class="{ 'permissions__crud-item--active': isFieldCrudActive(item.id, crudItem) }"
            >
              {{ crudItem }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue"

const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  crudEnabled: {
    type: Boolean,
    default: true
  },
  fetching: {
    type: Boolean,
    default: false
  },
  defaultPermissions: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["change"])

const selectedPermission = ref({})
const permissions = ref([])
const crudArray = ["C", "R", "U", "D"]

const encodeCrud = (object) => {
  const stringArray = [
    object.C ? "C" : "_",
    object.R ? "R" : "_",
    object.U ? "U" : "_",
    object.D ? "D" : "_"
  ]

  return stringArray.join("")
}

const permissionCrudChange = (permissionId, crudId) => {
  permissions.value = permissions.value.map((item) => {
    if (permissionId !== item.id) {
      return item
    } else {
      const oldCrud = decodeCrud(item.permissions)
      const newCrud = encodeCrud({
        ...oldCrud,
        [crudId]: !oldCrud[crudId]
      })

      return {
        ...item,
        permissions: newCrud
      }
    }
  })

  emit("change", permissions.value)
}

const fieldCrudChange = (fieldId, crudId) => {
  permissions.value = permissions.value.map((permission) => {
    if (selectedPermission.value.id !== permission.id) {
      return permission
    } else {
      return {
        ...permission,
        fields: permission.fields.map((field) => {
          if (field.id !== fieldId) {
            return field
          } else {
            const oldCrud = decodeCrud(field.permissions)
            const newCrud = encodeCrud({
              ...oldCrud,
              [crudId]: !oldCrud[crudId]
            })

            return {
              ...field,
              permissions: newCrud
            }
          }
        })
      }
    }
  })

  emit("change", permissions.value)
}

const decodeCrud = (string) => {
  return {
    C: string[0] !== "_",
    R: string[1] !== "_",
    U: string[2] !== "_",
    D: string[3] !== "_"
  }
}

const togglePermission = (permission) => {
  if (selectedPermission.value.id === permission.id) {
    selectedPermission.value = {}
  } else {
    selectedPermission.value = permission
  }
}

const togglePermissionCheckbox = (checked, value) => {
  if (checked) {
    selectedPermission.value = value
    permissions.value = [...permissions.value, value]
  } else {
    permissions.value = permissions.value.filter((item) => item.id !== value.id)
  }

  emit("change", permissions.value)
}

const toggleFieldCheckbox = (checked, value) => {
  permissions.value = permissions.value.map((permission) => {
    if (permission.id === selectedPermission.value.id) {
      return {
        ...permission,
        fields: checked
          ? [...permission.fields, value]
          : permission.fields.filter((item) => item.id !== value.id)
      }
    } else {
      return permission
    }
  })

  emit("change", permissions.value)
}

const isPermissionChecked = (permissionId) => {
  return permissions.value.some((item) => item.id === permissionId)
}

const isFieldChecked = (fieldId) => {
  return (
    selectedPermission.value &&
    permissions.value.some(
      (item) =>
        item.id === selectedPermission.value.id && item.fields.some((field) => field.id === fieldId)
    )
  )
}

const isPermissionCrudShow = (permissionId) => {
  return permissions.value.some((item) => item.id === permissionId)
}

const isPermissionCrudActive = (permissionId, crudId) => {
  const currentPermission = permissions.value.find((item) => item.id === permissionId)
  return currentPermission.permissions.indexOf(crudId) !== -1
}

const isFieldCrudActive = (fieldId, crudId) => {
  const currentPermission = permissions.value.find(
    (item) => item.id === selectedPermission.value.id
  )
  if (currentPermission) {
    const currentField = currentPermission.fields.find((item) => item.id === fieldId)
    return currentField.permissions.indexOf(crudId) !== -1
  }
  return false
}

const updatePermissionsValue = () => {
  if (!props.defaultPermissions) {
    return
  }

  const newPermissions = props.defaultPermissions
    .map((defaultItem) => {
      const fullItem = props.list.find((listItem) => listItem.id === defaultItem.id)
      if (fullItem) {
        return {
          ...fullItem,
          permissions: defaultItem.permissions,
          fields: fullItem.fields
            .map((item) => {
              const fullFieldItem = props.defaultPermissions.find(
                (defaultItem) => item.id === defaultItem.id
              )
              return fullFieldItem || false
            })
            .filter(Boolean)
        }
      }
      return false
    })
    .filter(Boolean)

  permissions.value = newPermissions
}

onMounted(updatePermissionsValue)

watch(() => props.list, updatePermissionsValue, { deep: true })
</script>

<style lang="scss" scoped>
.permissions {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  &__left,
  &__right {
    width: calc(50% - 10px);
  }

  &__list,
  &__fields-list {
    border: solid 1px $light-border;
    max-height: 230px;
    overflow: auto;
    margin-bottom: 15px;
  }

  &__list-item,
  &__fields-list-item {
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: solid 1px $light-border;
    padding: 2px 5px;
    cursor: pointer;
    color: $link-default;

    &:last-child {
      border-bottom: none;
    }

    &--selected {
      background-color: $default-shadow-color;
    }
  }

  &__list-item-left {
    display: flex;
    align-items: center;
  }

  &__list-item-right {
    margin-right: 16px;
  }

  &__crud-item {
    color: $link-default;
    margin-left: 3px;
    font-weight: bold;

    &--active {
      color: $primary-color;
    }
  }

  &__left-title,
  &__right-title {
    margin-bottom: 5px;
  }
}
</style>
