<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <a-form-model-item
      :extra="$t('massiveCsCodesHelp')"
      prop="cs_codes"
    >
      <a-textarea
        v-model="form.cs_codes"
        :autoSize="{ minRows: 4, maxRows: 14 }"
      />
    </a-form-model-item>

    <div class="sequence-number">
      <div>
        {{ $t("tagRating") }}
      </div>

      <a-form-model-item prop="ordering">
        <a-select
          v-model="form.ordering"
          :options="sequenceNumberOrderOptions"
        />
      </a-form-model-item>

      <a-form-model-item prop="sort">
        <a-input-number
          :min="1"
          v-model="form.sort"
        />
      </a-form-model-item>
    </div>

    <a-alert
      style="margin-top: 16px"
      type="info"
      showIcon
    >
      <template #message>
        {{
          form.ordering === "ascending"
            ? `${$t("tagRatingTip")}${$t("tagRatingModalTip")}`
            : $t("tagRatingModalTipSameOrder")
        }}
      </template>
    </a-alert>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        icon="plus"
        @click="onSubmit"
      >
        {{ $t("add") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { ref, reactive, computed } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import router from "@/router"

import { addOffersToTag } from "@/modules/MPAdmin/services/tagsService"
import useTagConstants from "../../useTagsConstants"
import notifyResponseError from "@/utils/notifyResponseError"

const emit = defineEmits(["onCsCodesAdded", "handleModal"])

const { sequenceNumberOrderOptions } = useTagConstants()

const isFetching = ref(false)
const formRef = ref()
const form = reactive({
  cs_codes: "",
  ordering: "ascending",
  sort: 1
})

const formRules = computed(() => ({
  cs_codes: [{ validator: checkCSCodes, trigger: "blur" }]
}))

const checkCSCodes = (_, value, callback) => {
  if (value.trim() === "") {
    callback(new Error(i18n.t("thisFieldMustBeFilled")))
  } else {
    const splitedCodes = value.split(/[\r\n, ]+/).filter(Boolean)

    for (const code of splitedCodes) {
      if (
        code.slice(0, 2) !== "CS" ||
        code.toUpperCase() !== code ||
        code.length < 9 ||
        code.length > 10
      ) {
        callback(new Error(i18n.t("invalidCsCode")))
        return
      }
    }
    callback()
  }
}

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }
  const csCodes = getCsCodesArray(form)

  isFetching.value = true

  try {
    await addOffersToTag(router.currentRoute.params.uuid, csCodes)

    notification.success({ message: i18n.t("codesAddedSuccessfully") })
    emit("onCsCodesAdded")
    handleClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleClose = () => {
  formRef.value.resetFields()
  emit("handleModal")
}

const getCsCodesArray = ({ cs_codes, ordering, sort }) => {
  return cs_codes
    .split(/[\r\n, ]+/)
    .filter((item) => item.trim() !== "")
    .map((item, index) => ({
      offer: item,
      sort: ordering === "ascending" ? sort + index : sort
    }))
}
</script>

<style lang="scss" scoped>
.sequence-number {
  display: flex;
  align-items: center;
  gap: 16px;

  & > * {
    margin: 0;
  }

  & > :nth-child(2) {
    margin-left: auto;
  }
}
</style>
