<template>
  <a-modal
    :title="$t('addAttribute')"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :cancel-text="$t('cancel')"
    :ok-text="$t('add')"
    :body-style="{
      height: '250px',
      position: 'relative'
    }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-spin :spinning="attributeFetching">
      <a-form-model
        ref="formRef"
        :model="form"
        :rules="formRules"
        hideRequiredMark
      >
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item
              :label="$t('contextSearchByAttribute')"
              prop="attrKey"
            >
              <a-select
                v-model="form.attrKey"
                :placeholder="$t('attributeSearch')"
                :show-search="true"
                :loading="fetching"
                style="width: 100%"
                :show-arrow="false"
                :filter-option="false"
                @search="onSearchAttributes"
                @change="fetchSelectedAttribute"
              >
                <a-select-option
                  v-for="item in attributes"
                  :key="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row
          v-if="selectedAttr"
          :gutter="24"
        >
          <a-col :span="12">
            <a-form-model-item
              :label="$t('attributeValue')"
              prop="attrValue"
            >
              <a-input
                v-if="valueType === 'TEMP' || valueType === 'STRING' || valueType === 'NUMERIC'"
                :type="valueType === 'NUMERIC' ? 'number' : 'text'"
                v-model="form.attrValue"
              />

              <div @click="getPossibleValues()">
                <a-select
                  v-if="valueType === 'LIST' || valueType === 'MULTILIST'"
                  :mode="valueType === 'MULTILIST' ? 'multiple' : 'default'"
                  :show-arrow="true"
                  v-model="form.attrValue"
                  optionFilterProp="children"
                >
                  <a-select-option
                    v-for="item in attributePossibleValues"
                    :key="item.code"
                  >
                    {{ item.value }}
                  </a-select-option>
                </a-select>
              </div>

              <a-switch
                v-if="valueType === 'BOOLEAN'"
                v-model="form.attrValue"
                :checkedChildren="$t('yes')"
                :unCheckedChildren="$t('no')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :label="$t('orderingFull')"
              prop="attrOrder"
            >
              <a-input v-model="form.attrOrder" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue"
import i18n from "@/i18n"
import { message } from "ant-design-vue"

import {
  fetchAttributeValues,
  fetchAttributes,
  fetchAttributesByType
} from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  product: {
    type: Object,
    default: () => ({})
  },
  mappedAttributes: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["addNewAttribute", "closeAddNewAttribute"])

const formRef = ref()
const form = reactive({
  attrKey: null,
  attrValue: "",
  attrOrder: 1
})
const formRules = computed(() => ({
  attrKey: [{ required: true, message: i18n.t("emptyError") }]
}))
const attributes = ref([])
const mappedSelectedAttribute = ref({})
const attributePossibleValues = ref([])
const confirmLoading = ref(false)
const fetching = ref(false)
const attributeFetching = ref(false)
const debouncedApiCall = ref(null)

const selectedAttr = computed(() => attributes.value.find((item) => item.code === form.attrKey))
const valueType = computed(() => selectedAttr.value?.value_type)

watch(
  () => selectedAttr.value,
  (value) => {
    if (value?.value_type === "MULTILIST") {
      form.attrValue = []
    } else if (value?.value_type === "BOOLEAN") {
      form.attrValue = false
    } else {
      form.attrValue = ""
    }
  }
)

const getPossibleValues = async () => {
  try {
    const { data } = await fetchAttributeValues({ code: form.attrKey })

    attributePossibleValues.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onSearchAttributes = async (value) => {
  clearTimeout(debouncedApiCall.value)

  if (!value) {
    attributes.value = []
    return
  }

  fetching.value = true

  const queryParams = {
    limit: 20,
    offset: 0,
    name: value
  }

  debouncedApiCall.value = setTimeout(async () => {
    try {
      const { data } = await fetchAttributes(queryParams)

      attributes.value = data.results
    } catch (error) {
      notifyResponseError({ error, message: i18n.t("loadAttributesError") })
    } finally {
      debouncedApiCall.value = null
      fetching.value = false
    }
  }, 500)
}

const fetchSelectedAttribute = async (value) => {
  if (props.mappedAttributes.some(({ code }) => code === value)) {
    message.error(i18n.t("duplicateAttributeError"))
    form.attrKey = null
    return
  }

  if (!props.product.type?.code) {
    mappedSelectedAttribute.value = {
      ...attributes.value.find(({ code }) => code === value),
      is_main: false,
      is_required: false
    }

    return
  }

  try {
    attributeFetching.value = true

    const { data } = await fetchAttributesByType({
      code: props.product.type.code,
      queryParams: {}
    })

    const currentAttributeInType = data.results.find(({ code }) => code === value)

    if (!currentAttributeInType) {
      mappedSelectedAttribute.value = {
        ...attributes.value.find(({ code }) => code === value),
        is_main: false,
        is_required: false
      }
      return
    }

    mappedSelectedAttribute.value = currentAttributeInType
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadAttributesError") })

    mappedSelectedAttribute.value = {
      ...attributes.value.find(({ code }) => code === value),
      is_main: false,
      is_required: false
    }
  } finally {
    attributeFetching.value = false
  }
}

const findAttributeValueInPossible = (value) => {
  return attributePossibleValues.value.find(({ code }) => code === value)
}

const formatAttributeValue = (value, type) => {
  const attributeFormatters = {
    MULTILIST: (value) => value.map((item) => findAttributeValueInPossible(item)),
    LIST: (value) => findAttributeValueInPossible(value),
    NUMERIC: (value) => ({ value }),
    default: (value) => ({ value })
  }

  const attributeFormatter = attributeFormatters[type] || attributeFormatters.default

  return attributeFormatter(value)
}

const handleOk = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  const params = {
    attr: {
      ...mappedSelectedAttribute.value,
      value: formatAttributeValue(form.attrValue, mappedSelectedAttribute.value.value_type)
    },
    order: form.attrOrder
  }

  resetForm()
  emit("addNewAttribute", params)
}

const handleCancel = () => {
  resetForm()
  emit("closeAddNewAttribute")
}

const resetForm = () => {
  formRef.value.resetFields()
  attributes.value = []
  mappedSelectedAttribute.value = {}
  attributeFetching.value = false
  fetching.value = false
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
</style>
