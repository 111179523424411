<template>
  <a-drawer
    width="730px"
    :title="$t('creatingCampaign')"
    placement="right"
    :closable="true"
    :visible="isVisible"
    @close="handleClose"
  >
    <a-form layout="vertical">
      <a-form-item
        :label="$t('seller')"
        :wrapper-col="{ span: 12 }"
      >
        <a-select
          show-search
          label-in-value
          default-value=""
          :value="selectedSeller"
          :placeholder="$t('searchBySellerSlug')"
          style="width: 100%"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="sellerFetching ? undefined : $t('notFound')"
          @search="handleSearchSellerBySlug"
          @change="handleChangeSelectedSeller"
        >
          <template
            v-if="sellerFetching"
            #notFoundContent
          >
            <a-spin size="small" />
          </template>
          <a-select-option
            v-for="item in sellers"
            :key="item.slug"
            :value="item.slug"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="Custom label"
        :wrapper-col="{ span: 12 }"
      >
        <a-input v-model="label" />
      </a-form-item>
      <a-form-item
        :label="$t('balance')"
        :wrapper-col="{ span: 12 }"
      >
        <a-input
          v-model="balance"
          :suffix="$t('uah').toUpperCase()"
        />
      </a-form-item>
    </a-form>

    <div class="footer">
      <a-divider
        type="horizontal"
        style="margin: 16px 0"
      />
      <div class="footer-buttons">
        <a-button
          class="form-button-padding"
          @click="handleClose"
        >
          {{ $t("cancel") }}
        </a-button>
        <a-button
          type="primary"
          :disabled="createBtnIsDisabled"
          :loading="createRequest"
          @click="handleCreateCampaing"
        >
          {{ $t("create") }}
        </a-button>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { createCampaign } from "@/modules/ADS/services/CPCServices.js"
import { searchSellerByName } from "@/modules/ADS/services/CPAServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  name: "CreateCampaingModal",

  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      sellers: [],
      selectedSeller: undefined,
      label: "",
      balance: "",
      sellerFetching: false,
      createRequest: false
    }
  },

  computed: {
    createBtnIsDisabled() {
      return !this.selectedSeller || !this.label || !this.balance
    }
  },

  methods: {
    async handleCreateCampaing() {
      try {
        this.createRequest = true

        await createCampaign(this.selectedSeller.key, this.label, this.balance)

        this.$notification.success({ message: this.$t("created") })
        this.handleClose()
        this.$emit("onSuccess")
      } catch (error) {
        notifyResponseError({ error })
      } finally {
        this.createRequest = false
      }
    },

    clearModal() {
      this.sellers = []
      this.selectedSeller = undefined
      this.label = ""
      this.balance = ""
    },

    handleClose() {
      this.$emit("close")
      this.clearModal()
    },

    async handleSearchSellerBySlug(search) {
      this.sellerFetching = true
      try {
        const { data } = await searchSellerByName({ params: { search } })
        this.sellers = data.results
      } catch (error) {
        if (error?.message === "canceled") return

        notifyResponseError({ error })
      } finally {
        this.sellerFetching = false
      }
    },

    handleChangeSelectedSeller(value) {
      this.selectedSeller = value
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px 16px;

  &-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.padding-bottom {
  padding-bottom: 16px;
}
</style>
