export default {
  user: {
    name: "",
    role: "",
    uuid: ""
  },
  filterKey: "",
  chatsLimit: 15,
  chatsOffset: 0,
  messagesLimit: 20,
  unreadMessages: null,
  chats: [],
  currentChatId: null,
  topMessagesLoader: false,
  bottomMessagesLoader: false,
  chatFindRequest: false,
  chatFindHasNextPage: true,
  socket: {
    isConnected: false,
    message: "",
    connecting: false // todo
  },
  globalQuery: [],
  getToken: null,
  is_typing: false,
  showHint: false,
  hintMessage: "",
  sellersPit: [],
  xhrUrl: "",
  cdnUrl: "",
  mpUrl: "",
  auth: "",
  latestInitRequest: { type: "", payload: null },
  locale: "ru",
  textareaIsFocused: false
}
