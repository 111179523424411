<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showConvertConfirmationModalGet"
      :title="'Подтверждение изменения типа атрибута'"
    >
      <div>
        <div>
          <label>
            <span
              ><strong>Внимание!</strong> Данный атрибут уже имеет значения, указанные в товарах.
              Сменить тип атрибута на списковый с привязкой этих значений?</span
            >
            <hr />
            <div class="values">
              <div v-for="item in PV_attributeValuesGet">- {{ item }}</div>
            </div>
          </label>
        </div>
      </div>
      <span slot="footer">
        <Button
          color="primary"
          @click="confirm"
          >Подтвердить</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "ConvertConfirmationModal",
  components: {
    Button,
    Modal
  },
  data() {
    return {}
  },
  methods: {
    confirm() {
      this.PV_convertStringWithValues(true)
      this.PV_showConvertConfirmationModal(false)
    },
    closeModal() {
      this.PV_resetAutocompleteValueForSelectedAttr()
      this.PV_showConvertConfirmationModal(false)
    },
    ...mapMutations("ProductCatalog", {
      PV_showConvertConfirmationModal: "PV_showConvertConfirmationModal",
      PV_convertStringWithValues: "PV_convertStringWithValues",
      PV_attributeValues: "PV_attributeValues",
      PV_valuesForNewValueList: "PV_valuesForNewValueList"
    }),
    ...mapActions("ProductCatalog", {
      PV_resetAutocompleteValueForSelectedAttr: "PV_resetAutocompleteValueForSelectedAttr"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_showConvertConfirmationModalGet: "PV_showConvertConfirmationModal",
      PV_attributeValuesGet: "PV_attributeValues"
    })
  }
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
hr {
  border: none;
  border-top: 1px solid rgba(125, 125, 125, 0.2);
  margin-top: 15px;
}
.values {
  font-weight: 300;
  overflow-y: scroll;
  max-height: 200px;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
