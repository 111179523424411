import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_showCreateValueListModal: false,
  PV_descriptionForNewValue: false,
  PV_valuesForNewValueList: false,
  PV_attributesForNewValue: [],
  PV_selectedAttributeValue: false,
  PV_attributesForNewValueAutocomplete: [],
  PV_convertStringWithValues: false,
  PV_convertStringWithNoValues: false,
  PV_showConvertConfirmationModal: false,
  PV_attributeValues: []
})

const getters = {
  PV_showCreateValueListModal: (state) => state.PV_showCreateValueListModal,
  PV_descriptionForNewValue: (state) => state.PV_descriptionForNewValue,
  PV_valuesForNewValueList: (state) => state.PV_valuesForNewValueList,
  PV_attributesForNewValue: (state) => state.PV_attributesForNewValue,
  PV_selectedAttributeValue: (state) => state.PV_selectedAttributeValue,
  PV_attributesForNewValueAutocomplete: (state) => state.PV_attributesForNewValueAutocomplete,
  PV_convertStringWithValues: (state) => state.PV_convertStringWithValues,
  PV_convertStringWithNoValues: (state) => state.PV_convertStringWithNoValues,
  PV_showConvertConfirmationModal: (state) => state.PV_showConvertConfirmationModal,
  PV_attributeValues: (state) => {
    return state.PV_attributeValues
  }
}

const mutations = {
  PV_showCreateValueListModal(state, value) {
    state.PV_showCreateValueListModal = value
  },
  PV_descriptionForNewValue(state, value) {
    state.PV_descriptionForNewValue = value
  },
  PV_valuesForNewValueList(state, value) {
    state.PV_valuesForNewValueList = value
  },
  PV_attributesForNewValue(state, value) {
    state.PV_attributesForNewValue = value
  },
  PV_selectedAttributeValue(state, value) {
    state.PV_selectedAttributeValue = value
  },
  PV_attributesForNewValueAutocomplete(state, value) {
    state.PV_attributesForNewValueAutocomplete = value
  },
  PV_convertStringWithValues(state, value) {
    state.PV_convertStringWithValues = value
  },
  PV_convertStringWithNoValues(state, value) {
    state.PV_convertStringWithNoValues = value
  },
  PV_showConvertConfirmationModal(state, value) {
    state.PV_showConvertConfirmationModal = value
  },
  PV_attributeValues(state, value) {
    state.PV_attributeValues = value
  }
}

const actions = {
  PV_createNewValueList({ commit, getters, dispatch }) {
    /**
     * for 'PV_convertStringWithValues' flag run POST /dictionary/value-list/lists/create-from-attribute
     * for 'PV_convertStringWithNoValues' flag run 1) edit attr and change its type to list 2) add values to it
     * for no flags just add values to the attr
     */
    if (getters.PV_convertStringWithValues) {
      dispatch("PV_createNewValueListForStringWithValues")
    } else if (getters.PV_convertStringWithNoValues) {
      dispatch("PV_createNewValueListForStringWithNoValues")
    } else {
      dispatch("PV_createNewValueListForList")
    }
  },
  PV_createNewValueListForStringWithValues({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let list = {
      description: getters.PV_descriptionForNewValue,
      attribute: getters.PV_selectedAttributeValue.code
    }

    // start request
    return API_MODERATION.post(`${apiUrls.createFromAttribute}`, list)
      .then((r) => r.message)
      .then((r) => {
        // restart table search
        dispatch("PV_searchValuesByDescription")
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PV_resetCreateValueListValues")

        notification.info({ message: "Новый список добавлен" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PV_resetCreateValueListValues")

        commit("handleError", e)
      })
  },
  async PV_createNewValueListForStringWithNoValues({ commit, getters, dispatch }) {
    let response = dispatch("PV_editAttributeType")
    if (!response) return
    dispatch("PV_createNewValueListForList")
  },
  async PV_editAttributeType({ commit, getters }) {
    // show loader
    commit("PC_showLoader", true)

    let data = {
      code: getters.PV_selectedAttributeValue.code,
      attribute: {
        value_type: "LIST"
      }
    }

    // start request
    return API_MODERATION.put(`${apiUrls.attribute}`, data)
      .then((r) => {
        // hide loader
        commit("PC_showLoader", false)
        return r
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        commit("handleError", e)
        return null
      })
  },
  PV_createNewValueListForList({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let values = getters.PV_valuesForNewValueList

    let list = {
      description: getters.PV_descriptionForNewValue,
      values,
      attr_code: getters.PV_selectedAttributeValue.code
    }

    // start request
    API_MODERATION.post(`${apiUrls.lists}`, list)
      .then((r) => r.message)
      .then((r) => {
        // restartTableSearch({getters, dispatch});
        dispatch("PV_searchValuesByDescription")
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PV_resetCreateValueListValues")

        notification.info({ message: "Новый список добавлен" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PV_resetCreateValueListValues")

        commit("handleError", e)
      })
  },
  PV_resetCreateValueListValues({ commit }) {
    commit("PV_descriptionForNewValue", false)
    commit("PV_valuesForNewValueList", false)
    commit("PV_attributesForNewValue", [])
    commit("PV_selectedAttributeValue", false)
    commit("PV_attributesForNewValueAutocomplete", [])
    commit("PV_convertStringWithValues", false)
    commit("PV_convertStringWithNoValues", false)
    commit("PV_attributeValues", [])
  },
  async PV_fetchAttributesForNewValueAutocomplete({ commit }, value) {
    try {
      let searchData = buildMagicSearch({
        resource: "AND",
        field: "name",
        value,
        sort: null,
        limit: 20,
        offset: 0
      })
      return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((attrs) => {
          // add attribute type to the name
          attrs = attrs.map((item) => {
            item.name += ` (${item.value_type})`
            return item
          })
          commit("PV_attributesForNewValue", attrs)
          let unitsArray = attrs.map((item) => {
            return item.name
          })
          commit("PV_attributesForNewValueAutocomplete", unitsArray)
          return attrs
        })
        .catch((e) => {
          e.$_actionName = "PV_fetchAttributesForNewValueAutocomplete"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("handleError", e)
    }
  },
  async PV_processSelectedAttributeValue({ commit, getters, dispatch }, value) {
    let selectedValue = getters.PV_attributesForNewValue.find((item) => {
      return item.name === value
    })
    // check that the selected attribute has type LIST or STRING
    if (
      selectedValue &&
      selectedValue.value_type !== "LIST" &&
      selectedValue.value_type !== "STRING"
    ) {
      await dispatch("PV_setWarningForWrongAttributeType", selectedValue)
      return
    }
    // if the type is "LIST", check that the selected attribute has not been linked to a list already
    if (selectedValue && selectedValue.value_type === "LIST") {
      let isLinkedAlready = await dispatch("PV_checkLinkedAttribute", selectedValue)
      if (isLinkedAlready === null) return
      if (isLinkedAlready === false) {
        await dispatch("PV_setWarningForLinkedAttribute", selectedValue)
        return
      }
    }

    // reset flags
    commit("PV_convertStringWithValues", false)
    commit("PV_convertStringWithNoValues", false)

    /**
     * if the type is "String", check if products have some values with that attribute
     *   if yes: show confirmation modal
     *     if confirmed set 'PV_convertStringWithValues' flag, set PV_valuesForNewValueList(true)
     *     else reset autocomplete input
     *   if no: show message that it's gonna be converted and set 'PV_convertStringWithNoValues flag',
     *   later in modal show those values instead of textarea
     *
     * later on submit
     * for 'PV_convertStringWithValues' flag run POST /dictionary/value-list/lists/create-from-attribute
     * for 'PV_convertStringWithNoValues' flag run 1) edit attr and change its type to list 2) add values to it
     * for no flags just add values to the attr
     */

    let response
    if (selectedValue && selectedValue.value_type === "STRING") {
      response = await dispatch("PV_checkAttributeValues", selectedValue)
      if (response === null) return
      if (response.length) {
        commit("PV_showConvertConfirmationModal", true)
      } else {
        notification.error({ message: `Тип атрибута будет конвертирован в "LIST"` })
        commit("PV_convertStringWithNoValues", true)
      }
    }

    // set
    commit("PV_selectedAttributeValue", selectedValue)
  },
  async PV_checkLinkedAttribute({ commit }, attribute) {
    let searchData = buildMagicSearch({
      resource: "VLD",
      field: "attributes",
      value: attribute.code,
      sort: null,
      limit: 1,
      offset: null
    })
    return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.count)
      .then((count) => {
        // check count
        if (Number(count) === 0) {
          return true
        }
        return false
      })
      .catch((e) => {
        commit("handleError", e)
        return null
      })
  },
  async PV_setWarningForWrongAttributeType({ dispatch }, selectedValue) {
    // show message
    notification.error({
      message: `Данный атрибут не может быть выбран, так как уже имеет другой тип (${selectedValue.value_type})`
    })
    await dispatch("PV_resetAutocompleteValueForSelectedAttr", selectedValue)
    return Promise.resolve()
  },
  async PV_setWarningForLinkedAttribute({ dispatch }, selectedValue) {
    // show message
    notification.error({ message: `Данный атрибут уже привязан к списку` })
    await dispatch("PV_resetAutocompleteValueForSelectedAttr", selectedValue)
    return Promise.resolve()
  },
  async PV_resetAutocompleteValueForSelectedAttr({ commit }, selectedValue) {
    if (!selectedValue) selectedValue = ""
    // switch the value to run the observer, which is watching this value
    commit("PV_selectedAttributeValue", selectedValue)
    setTimeout(() => {
      commit("PV_selectedAttributeValue", false)
    }, 0)
    return Promise.resolve()
  },
  async PV_checkAttributeValues({ commit }, selectedValue) {
    // show loader
    commit("PC_showLoader", true)

    let params = {
      attribute: selectedValue.code
    }

    // start request
    return API_MODERATION.get(`${apiUrls.searchProductsWithAttributeValue}`, { params })
      .then((r) => r.data.message.values)
      .then((values) => {
        commit("PV_attributeValues", values)
        // hide loader
        commit("PC_showLoader", false)
        return values
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        commit("handleError", e)
        return null
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
