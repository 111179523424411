<template>
  <label
    class="checkbox"
    :class="className"
  >
    <span
      class="checkbox__wrapper"
      :class="{ 'checkbox__wrapper--disabled': disabled }"
    >
      <input
        type="checkbox"
        :disabled="disabled"
        v-model="checkedStatus"
      />
      <div
        class="checkbox__icon"
        :class="{
          'checkbox__icon--checked': checkedStatus,
          'checkbox__icon--disabled': disabled
        }"
      ></div>
      <span
        class="checkbox__label"
        :class="{
          'checkbox__label--checked': checkedStatus,
          'checkbox__label--disabled': disabled
        }"
      >
        <slot></slot>
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: "auto"
    },
    className: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Object],
      default: ""
    }
  },
  computed: {
    checkedStatus: {
      get() {
        return this.checked || false
      },
      set(checked) {
        this.$emit("change", checked, this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-block;

  input {
    position: absolute;
    left: -99999px;
    z-index: -999999;
    visibility: hidden;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__icon {
    display: block;
    width: 16px;
    height: 16px;
    border: solid 1px $light-border;
    position: relative;
    margin-right: 10px;

    &--checked {
      border-color: $primary-color;

      &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background-color: $primary-color;
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }

    &--disabled {
      border-color: $dark;

      &:after {
        background-color: $dark;
      }
    }
  }

  &__label {
    margin: 0;

    &--checked {
      color: $primary-color;
    }

    &--disabled {
      color: $dark;
    }
  }
}
</style>
