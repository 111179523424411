<template>
  <a-table
    :columns="columns"
    :dataSource="dataSource"
    :loading="isFetching"
    :pagination="paginationInfo"
    rowKey="mpc_code"
    @change="onTableChange"
  >
    <template #errorMessagesRenderer="text, _, index">
      <ul>
        <li
          v-for="(errorMessage, errorMessageIndex) in text"
          :key="`error-${index}-message-${errorMessageIndex}`"
        >
          {{ errorMessage }}
        </li>
      </ul>
    </template>
  </a-table>
</template>

<script setup>
import { computed, onMounted } from "vue"
import { fetchExportErrors } from "../../services/exportHistoryService.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const props = defineProps({
  jobOid: {
    type: String,
    default: null
  }
})

const {
  dataSource,
  isFetching,

  paginationInfo,

  fetchTableInfo
} = useAntTableQuery({
  queryFunction: fetchExportErrors,
  requestParams: { oid: props.jobOid }
})

const columns = computed(() => [
  {
    key: "mpc_code",
    dataIndex: "mpc_code",
    title: "MPC-code"
  },
  {
    key: "messages",
    dataIndex: "messages",
    title: "Errors",
    scopedSlots: { customRender: "errorMessagesRenderer" }
  }
])

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

onMounted(() => {
  onTableChange()
})
</script>
