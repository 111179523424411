<template>
  <div>Main page</div>
</template>

<script>
export default {
  name: "MainPage"
}
</script>

<style scoped></style>
