<template>
  <a-form-model
    ref="formRef"
    :model="form"
  >
    <a-form-model-item
      :label="$t('tagRating')"
      prop="ordering"
    >
      <a-select
        v-model="form.ordering"
        style="width: 100%"
        :options="sequenceNumberOrderOptions"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('startFrom')"
      prop="sort"
    >
      <a-input-number
        :min="1"
        v-model="form.sort"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="handleClose()"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="editOffers()"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { ref, reactive } from "vue"
import router from "@/router"

import { editTagOffers } from "@/modules/MPAdmin/services/tagsService.js"
import useTagConstants from "../../useTagsConstants.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  expandedRowKeys: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["handleModal", "submit"])

const { sequenceNumberOrderOptions } = useTagConstants()

const formRef = ref()
const form = reactive({
  ordering: "ascending",
  sort: 1
})
const isFetching = ref(false)

const editOffers = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    isFetching.value = true
    const csCodes = getCsCodesArray(form)

    await editTagOffers(router.currentRoute.params.uuid, csCodes)

    handleClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const getCsCodesArray = ({ ordering, sort }) => {
  const csCodes = props.expandedRowKeys.map((item, index) => ({
    offer: item,
    sort: ordering === "ascending" ? sort + index : sort
  }))
  return csCodes
}

const handleClose = () => {
  emit("handleModal")
  emit("submit")
  formRef.value.resetFields()
}
</script>
