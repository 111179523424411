<template>
  <div>
    <SynDataTable
      :data="tableData"
      :queryAction="queryAction"
      :queryParams="queryParams"
      :fetching="fetchingStatus"
      rowKey="_id"
      :columns="dataTableColumns"
      tableId="ProductCatalogValuesTable"
      class="margin-top"
      :headerButtons="headerButtons"
      defaultOrder="-value"
      :rowActions="rowActions"
      :minRowHeight="34"
      :limitValues="limitValues"
    ></SynDataTable>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import InputEdit from "./Renderers/InputEdit"
import ApprovalStatusRenderer from "./Renderers/ApprovalStatusRenderer"
import HighlightCellCyrillic from "@/modules/ProductCatalog/components/common/HighlightCellCyrillic"

export default {
  name: "ValuesTable",
  data() {
    return {
      headerButtons: [
        {
          label: "Добавить",
          action: () => {
            this.PV_showCreateValueModal(true)
          },
          theme: "primary"
        }
      ]
    }
  },
  methods: {
    startDeleteProcess(val) {
      this.PV_valueToBeDeleted(val)
      this.PV_showDeleteValuesConfirmation(true)
    },
    handleConfirmStatus(val) {
      this.SHOW_VLD_APPROVE_MODAL()
      this.SET_CHOSEN_CODE(val)
    },
    queryAction(queryParams) {
      this.PV_searchValues({ selected: this.PV_selectedList, queryParams })
    },
    ...mapMutations("ProductCatalog", {
      changeQueryParams: "PV_valuesQueryParams",
      PV_showCreateValueModal: "PV_showCreateValueModal",
      PV_valueToBeDeleted: "PV_valueToBeDeleted",
      PV_showDeleteValuesConfirmation: "PV_showDeleteValuesConfirmation",
      SHOW_VLD_APPROVE_MODAL: "SHOW_VLD_APPROVE_MODAL",
      SET_CHOSEN_CODE: "SET_CHOSEN_CODE"
    }),
    ...mapActions("ProductCatalog", {
      updateCell: "PV_editValuesCell",
      PV_searchValues: "PV_searchValues"
    }),
    rowActions(row, rowIndex) {
      const actions = [
        {
          icon: "delete",
          action: this.startDeleteProcess
        }
      ]
      if (row.approval_status !== "APPROVED") {
        actions.push({
          icon: "plus-circle",
          action: this.handleConfirmStatus
        })
      }
      return actions
    }
  },
  computed: {
    dataTableColumns() {
      return [
        {
          key: "value_ru",
          title: `Список значений (RU)`,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEdit,
          editRendererProps: {
            onUpdate: this.updateCell,
            accept_lang: "ru"
          },
          filtering: this.valueFilter,
          sorting: this.valueSorting
        },
        {
          key: "value_uk",
          title: `Список значений (UA)`,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEdit,
          editRendererProps: {
            onUpdate: this.updateCell,
            accept_lang: "uk"
          }
        },
        {
          key: "code",
          title: `CODE`,
          filtering: this.valueFilter
        },
        {
          key: "approval_status",
          title: "МОДЕРАЦИЯ",
          width: 100,
          renderer: ApprovalStatusRenderer,
          filtering: {
            type: "search"
          }
        }
      ]
    },
    valueFilter() {
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        return {
          type: "search",
          placeholder: "Поиск"
        }
      }
      return false
    },
    valueSorting() {
      return this.PC_selectedLanguage === this.PC_baseLanguage
    },
    limitValues() {
      return [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ]
    },
    ...mapGetters("ProductCatalog", {
      queryParams: "PV_valuesQueryParams",
      tableData: "PV_valuesData",
      fetchingStatus: "PV_valuesFetchingStatus",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage",
      PV_selectedList: "PV_selectedList"
    })
  }
}
</script>
<style lang="scss" scoped></style>
