<template>
  <a-select
    v-model="attributeUnit"
    style="width: 100%"
    showArrow
    @change="onUnitChange"
    @focus="getAttributeUnitOptions"
  >
    <template #dropdownRender="menu">
      <a-spin :spinning="isFetching">
        <TypeNodes :vnodes="menu" />
      </a-spin>
    </template>

    <a-select-option value="">{{ $t("unitIsEmptyPlaceholder") }}</a-select-option>
    <a-select-option
      v-for="unit in attributeUnitOptions"
      :key="unit.code"
      :value="unit.code"
    >
      {{ unit.name }}
    </a-select-option>
  </a-select>
</template>

<script setup>
import { fetchAttributeUnits } from "@/modules/Moderation/services/moderationProductsService"
import { ref, inject, onMounted, defineComponent } from "vue"

const TypeNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true
    }
  },
  render() {
    return this.vnodes
  }
})

const props = defineProps({
  attribute: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["change"])
const language = inject("languageForDataFetch")

const isFetching = ref(false)
const attributeUnit = ref("")
const attributeUnitOptions = ref([])

const getAttributeUnitOptions = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchAttributeUnits({
      code: props.attribute.code,
      language: language.value
    })

    attributeUnitOptions.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const onUnitChange = (code) => {
  const unit = attributeUnitOptions.value.find((unit) => unit.code === code)

  emit("change", { code: props.attribute.code, unit: unit || null })
}

onMounted(() => {
  if (!props.attribute.unit) return

  attributeUnitOptions.value = [props.attribute.unit]
  attributeUnit.value = props.attribute.unit.code
})
</script>
