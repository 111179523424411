<template>
  <a-form
    :form="seoForm"
    class="seo-settings"
  >
    <!-- TITLE -->
    <a-form-item
      v-for="lang in languages"
      :key="`title-${lang.value}`"
    >
      <template #label>
        Title, {{ lang.label.toUpperCase() }} (<span
          :class="{
            'red-text': titleIsIncorrect(lang.value)
          }"
          >{{ titleLength[lang.value] }}</span
        >/{{ titleMaxLength.join("-") }})
      </template>

      <a-input
        v-decorator="[`title_${lang.value}`]"
        @change="(e) => onChangeTitle(e, lang.value)"
      />
    </a-form-item>

    <!-- DESCRIPTION -->
    <a-form-item
      v-for="lang in languages"
      :key="`description-${lang.value}`"
    >
      <template #label>
        Description, {{ lang.label.toUpperCase() }} (<span
          :class="{
            'red-text': descriptionIsIncorrect(lang.value)
          }"
          >{{ descriptionLength[lang.value] }}</span
        >/{{ descriptionMaxLength.join("-") }})
      </template>

      <a-textarea
        v-decorator="[`description_${lang.value}`]"
        :autoSize="{ minRows: 5, maxRows: 5 }"
        @change="(e) => onChangeDescription(e, lang.value)"
      />
    </a-form-item>

    <!-- KEYWORDS -->
    <a-form-item
      v-for="lang in languages"
      :key="`keyword-${lang.value}`"
    >
      <template #label>
        Keywords, {{ lang.label.toUpperCase() }} (<span
          :class="{
            'red-text': keywordsIsIncorrect(lang.value)
          }"
          >{{ keywordsLength[lang.value] }}</span
        >/{{ keywordsMaxLength }})
      </template>

      <a-textarea
        v-decorator="[`keywords_${lang.value}`]"
        :autoSize="{ minRows: 5, maxRows: 5 }"
        @change="(e) => onChangeKeywords(e, lang.value)"
      />
    </a-form-item>

    <!-- SEO TEXT -->
    <a-collapse
      style="width: 100%; background-color: inherit"
      accordion
      :bordered="false"
    >
      <a-collapse-panel
        v-for="lang in languages"
        :key="`text-${lang.value}`"
        :forceRender="true"
        style="border: none"
      >
        <template #header>SEO-text, {{ lang.label.toUpperCase() }}</template>
        <a-form-item>
          <EditorHtml
            :id="`editor-${lang.value}`"
            v-decorator="[`text_${lang.value}`]"
            :value="seoForm.getFieldValue(`text_${lang.value}`)"
            :headers="[false, 4, 5, 6]"
          />
        </a-form-item>
      </a-collapse-panel>
    </a-collapse>
  </a-form>
</template>

<script>
import EditorHtml from "@/components/Editor/EditorHtml.vue"

export default {
  components: {
    EditorHtml
  },

  props: {
    languages: {
      type: Array,
      required: true
    },
    seo_title: {
      type: Object,
      default: () => ({})
    },
    seo_description: {
      type: Object,
      default: () => ({})
    },
    seo_keywords: {
      type: Object,
      default: () => ({})
    },
    seo_text: {
      type: Object,
      default: () => ({})
    }
  },

  mounted() {
    for (const lang of this.languages.map((lang) => lang.value)) {
      this.titleLength[lang] = this.seo_title[lang]?.length || 0
      this.descriptionLength[lang] = this.seo_description[lang]?.length || 0
      this.keywordsLength[lang] = this.seo_keywords[lang]?.length || 0
    }
  },

  data() {
    return {
      seoForm: this.$form.createForm(this, {
        name: "seoForm",
        mapPropsToFields: () => {
          return {
            title_uk: this.$form.createFormField({
              value: this.seo_title.uk || ""
            }),
            title_en: this.$form.createFormField({
              value: this.seo_title.en || ""
            }),
            title_ru: this.$form.createFormField({
              value: this.seo_title.ru || ""
            }),
            description_uk: this.$form.createFormField({
              value: this.seo_description.uk || ""
            }),
            description_en: this.$form.createFormField({
              value: this.seo_description.en || ""
            }),
            description_ru: this.$form.createFormField({
              value: this.seo_description.ru || ""
            }),
            keywords_uk: this.$form.createFormField({
              value: this.seo_keywords.uk?.join(", ") || ""
            }),
            keywords_en: this.$form.createFormField({
              value: this.seo_keywords.en?.join(", ") || ""
            }),
            keywords_ru: this.$form.createFormField({
              value: this.seo_keywords.ru?.join(", ") || ""
            }),
            text_uk: this.$form.createFormField({
              value: this.seo_text.uk || ""
            }),
            text_en: this.$form.createFormField({
              value: this.seo_text.en || ""
            }),
            text_ru: this.$form.createFormField({
              value: this.seo_text.ru || ""
            })
          }
        },
        onValuesChange: () => {
          this.$emit("formChanged")
        }
      }),

      titleLength: {
        uk: 0,
        en: 0,
        ru: 0
      },
      descriptionLength: {
        uk: 0,
        en: 0,
        ru: 0
      },
      keywordsLength: {
        uk: 0,
        en: 0,
        ru: 0
      },

      titleMaxLength: [30, 65],
      descriptionMaxLength: [120, 320],
      keywordsMaxLength: 200
    }
  },

  methods: {
    onChangeTitle(e, key) {
      this.titleLength[key] = e.target.value?.length || 0
    },

    onChangeDescription(e, key) {
      this.descriptionLength[key] = e.target.value?.length || 0
    },

    onChangeKeywords(e, key) {
      this.keywordsLength[key] = e.target.value?.length || 0
    },

    getFields() {
      let fields = {}
      this.seoForm.validateFields((err, val) => {
        if (err) {
          fields = null
        } else {
          const data = { seo_title: {}, seo_description: {}, seo_keywords: {}, seo_text: {} }

          for (const key in val) {
            data[`seo_${key.split("_")[0]}`][key.split("_")[1]] = key.startsWith("keywords")
              ? val[key].split(/[, \n]+/g)
              : val[key]
          }
          fields = data
        }
      })
      return fields
    },

    titleIsIncorrect(lang) {
      return (
        this.titleLength[lang] < this.titleMaxLength[0] ||
        this.titleLength[lang] > this.titleMaxLength[1]
      )
    },

    descriptionIsIncorrect(lang) {
      return (
        this.descriptionLength[lang] < this.descriptionMaxLength[0] ||
        this.descriptionLength[lang] > this.descriptionMaxLength[1]
      )
    },

    keywordsIsIncorrect(lang) {
      return (
        this.keywordsLength[lang] > this.keywordsMaxLength || this.keywordsLength[lang.value] === 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.seo-settings {
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 16px;
  & > * {
    width: 31%;
  }
}

.red-text {
  color: $red-color;
}
</style>
