<template>
  <div>
    <a-button
      @click="handleEditMethodModal({})"
      icon="plus"
      type="primary"
    >
      {{ $t("add") }}
    </a-button>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      :pagination="paginationInfo"
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      style="margin-top: 16px"
      @change="onTableChange"
    >
      <template #isFiltered="text">
        <a-icon
          :type="text ? 'check-circle' : 'close-circle'"
          class="is-filtered-icon"
          :class="[text ? 'is-filtered-icon--green' : 'is-filtered-icon--red']"
        />
      </template>

      <template #type="value">
        <div>{{ Object.values(value)[0] }}</div>
      </template>

      <template #paymentMethods="methods">
        {{ methods.map((method) => method.name).join(", ") }}
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          @click="handleEditMethodModal(record)"
        >
          <a-icon type="edit" />
        </a-button>
      </template>
    </a-table>

    <a-drawer
      :visible="showModal"
      :title="prototype?.id ? $t('editingDeliveryMethods') : $t('creatingDeliveryMethods')"
      :width="700"
      destroyOnClose
      @close="closeModal()"
    >
      <CreateDeliveryMethodsModal
        :prototype="prototype"
        :deliveryTypes="deliveryTypes"
        :deliveryServices="deliveryServices"
        :paymentMethods="paymentMethods"
        @updateMethod="onUpdateMethod"
        @createMethod="onCreateMethod"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref } from "vue"
import CreateDeliveryMethodsModal from "./components/CreateDeliveryMethodsModal.vue"

import {
  fetchDeliveryMethods,
  fetchDeliveryMethodsMeta
} from "@/modules/MPAdmin/services/generalSettingService/deliveryMethodsService.js"
import { fetchDeliveryServices } from "@/modules/MPAdmin/services/generalSettingService/deliveryService.js"
import { fetchPaymentMethods } from "@/modules/MPAdmin/services/generalSettingService/paymentMethodsService.js"

import getCurrentLocale from "@/utils/getCurrentLocale.js"

import useDMColumns from "./useDMColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"
import { onMounted } from "vue"

const columns = useDMColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,

  setupTable,
  fetchTableInfo,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchDeliveryMethods
})

const prototype = ref()
const showModal = ref(false)
const deliveryTypes = ref([])
const deliveryServices = ref([])
const paymentMethods = ref([])

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const getMetadata = async () => {
  const { data } = await fetchDeliveryMethodsMeta()
  if (!data.types) return

  deliveryTypes.value = Object.entries(data.types).map(([value, label]) => ({
    value,
    label
  }))
}

const getDeliveryServices = async () => {
  const { data } = await fetchDeliveryServices({
    queryParams: { pageSize: 100, is_active: true }
  })

  if (!data) return

  deliveryServices.value = data.results
    .filter(({ is_active }) => Boolean(is_active))
    .map((item) => ({
      label: item.name[getCurrentLocale()],
      value: item.id
    }))
}

const getPaymentMethods = async () => {
  const { data } = await fetchPaymentMethods({
    queryParams: {
      pageSize: 100,
      is_active: true
    }
  })

  if (!data) return

  paymentMethods.value = data.results.map(({ uuid, name }) => ({
    value: uuid,
    label: name[getCurrentLocale()]
  }))
}

const handleEditMethodModal = (method) => {
  getPaymentMethods()
  getDeliveryServices()

  showModal.value = true
  prototype.value = method
}

const closeModal = () => {
  showModal.value = false
  prototype.value = undefined
}

const onUpdateMethod = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeModal()
}

const onCreateMethod = () => {
  closeModal()
  onTableChange()
}

onMounted(() => {
  setupTable({ defaultPagination: { size: "default" } })

  getMetadata()
  getDeliveryServices()
  getPaymentMethods()

  onTableChange()
})
</script>

<style lang="scss" scoped>
.is-filtered-icon {
  width: 100%;
  font-weight: 500;
  font-size: 20px;

  &--green {
    color: $green-color;
  }

  &--red {
    color: $red-color;
  }
}
</style>
