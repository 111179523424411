import { render, staticRenderFns } from "./SellerReviews.vue?vue&type=template&id=5eb63538&scoped=true"
import script from "./SellerReviews.vue?vue&type=script&setup=true&lang=js"
export * from "./SellerReviews.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SellerReviews.vue?vue&type=style&index=0&id=5eb63538&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb63538",
  null
  
)

export default component.exports