import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"

const state = () => ({
  PV_checkUniqueValueDescription: true
})

const getters = {
  PV_checkUniqueValueDescription: (state) => state.PV_checkUniqueValueDescription
}

const mutations = {
  PV_checkUniqueValueDescription: (state, value) => {
    state.PV_checkUniqueValueDescription = value
  }
}

const actions = {
  PV_checkUniqueValueDescription: ({ commit, getters }, { value }) => {
    if (value === "") {
      commit("PV_checkUniqueValueDescription", true)
      return
    }

    let params = {
      description: value.trim()
    }

    API_MODERATION.get(`${apiUrls.validateValueList}`, { params })
      .then((r) => r.data.message.unique)
      .then((unique) => {
        if (unique) commit("PV_checkUniqueValueDescription", true)
        if (!unique) commit("PV_checkUniqueValueDescription", false)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
