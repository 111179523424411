<template>
  <div>
    <PageHeader :title="$t('syncManagment')">
      <template #filters>
        <a-input-search
          :placeholder="$t('searchSeller')"
          v-model="filteredInfo.seller"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          :placeholder="$t('sellerActivity')"
          :options="sellerActiveOptions"
          v-model="filteredInfo.seller_is_active"
          allowClear
          @change="handleTableFilterChange()"
        />

        <a-select
          :placeholder="$t('syncState')"
          :options="syncActiveOptions"
          v-model="filteredInfo.is_active"
          allowClear
          @change="handleTableFilterChange()"
        />

        <a-select
          v-model="filteredInfo.status"
          mode="multiple"
          :options="syncStatuses"
          :placeholder="$t('syncStatus')"
          allowClear
          @change="handleTableFilterChange()"
        />
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("clearFilters") }}
        </a-button>

        <a-button
          type="primary"
          :disabled="!selectedRowKeys?.length"
          @click="handleMatchSynchronizations()"
        >
          {{ $t("compare") }}
        </a-button>

        <a-button
          type="primary"
          :disabled="!selectedRowKeys?.length"
          @click="handleApproveSynchronizations()"
        >
          {{ $t("apply") }}
        </a-button>

        <a-dropdown :disabled="!selectedRowKeys?.length">
          <a-button
            type="primary"
            icon="setting"
            ghost
            :loading="actionsFetching"
          >
            {{ $t("processManagement") }}
          </a-button>

          <template #overlay>
            <a-menu>
              <a-menu-item
                :disabled="syncActivationDisabled"
                @click="handleActivateSyncs"
              >
                <a-icon type="play-circle" />
                {{ $t("activate") }}
              </a-menu-item>

              <a-menu-item
                :disabled="syncDeactivationDisabled"
                @click="handleDeactivateSyncs"
              >
                <a-icon type="pause-circle" />
                {{ $t("stop") }}
              </a-menu-item>

              <a-menu-item
                :disabled="selectedRows?.length !== 1"
                @click="handleUpdateSync"
              >
                <a-icon type="sync" />
                {{ $t("update") }}
              </a-menu-item>

              <a-menu-item
                :disabled="selectedRows?.length !== 1"
                @click="handleOpenDeletionModal"
                :class="[selectedRows?.length !== 1 ? '' : 'error']"
              >
                <a-icon type="delete" />
                {{ $t("delete") }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="paginationInfo"
      :loading="isFetching"
      :scroll="{ x: true }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectRows }"
      rowKey="uuid"
      @change="onTableChange"
    >
      <template
        v-for="title of customTitles"
        #[`custom-title-${title}`]
      >
        <div
          :key="`custom-title-${title}`"
          style="display: flex; align-items: center; gap: 4px"
        >
          {{ customTitleTranslates[title] }}
          <a-tooltip>
            <a-icon
              type="info-circle"
              theme="twoTone"
            />

            <template #title>
              {{ $t(`syncTableTooltips.${title}`) }}
            </template>
          </a-tooltip>
        </div>
      </template>

      <template #seller="sellerName, { image }">
        <div class="seller">
          <a-avatar
            class="seller__image"
            shape="square"
            icon="shop"
            :size="24"
            :src="getFullImagePath(image)"
          />

          <a-tooltip
            :title="sellerName"
            placement="topLeft"
          >
            <div class="clamped-text">
              {{ sellerName }}
            </div>
          </a-tooltip>
        </div>
      </template>

      <template #slug="value">
        <a-tooltip
          :title="value"
          placement="topLeft"
        >
          <div class="clamped-text">{{ value }}</div>
        </a-tooltip>
      </template>

      <template #percent="value">
        {{ value.percentage }}% ({{ getFormatedSum(value.count, true) }})
      </template>

      <template #state="value">
        <div :class="{ success: value }">
          {{ value ? $t("syncIsActive") : $t("syncIsDisabled") }}
        </div>
      </template>

      <template #status="value, { failed_reason }">
        <SyncStatusRender
          :status="value"
          :failed_reason="failed_reason"
        />
      </template>

      <template #actions="{ status, slug, url }">
        <div style="display: flex; gap: 8px">
          <a-tooltip
            :title="$t('downloadReport')"
            placement="topRight"
          >
            <a-button
              :disabled="status === 'SUCCESS'"
              type="dashed"
              shape="circle"
              icon="download"
              @click="handleDownloadReport(slug)"
            />
          </a-tooltip>
          <a-tooltip
            :title="$t('syncXmlLink')"
            placement="topRight"
          >
            <a-button
              type="dashed"
              shape="circle"
              icon="link"
              @click="handleCopyText(url)"
            />
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <a-modal
      :visible="showDeleteSyncModal"
      :title="$t('syncDeletion')"
      :okButtonProps="{ props: { type: 'danger', loading: isDeleting } }"
      :okText="$t('delete')"
      @ok="handleDeleteSync(selectedRows[0].uuid)"
      @cancel="handleOpenDeletionModal"
    >
      <div class="deletion-modal">
        <div class="deletion-modal__label">{{ $t("seller") }}:</div>
        <div>
          {{ selectedRows[0]?.seller }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import { notification, message } from "ant-design-vue"
import i18n from "@/i18n"

import * as syncService from "@/modules/MPAdmin/services/syncService.js"

import useSyncPageColumns from "./useSyncPageColumns.js"
import SyncStatusRender from "./components/SyncStatusRender.vue"

import getImagePath from "@/utils/getImagePath.js"
import getFormatedSum from "@/utils/getFormatedSum.js"

import { IMAGES_CDN } from "@/constants/common.js"
import useMPAConsts from "@/modules/MPAdmin/constants.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: syncService.fetchSynchronizations,
  requestParams: { changeRouterQuery: true }
})

const columns = useSyncPageColumns({ sortedInfo: computed(() => sortedInfo.value) })
const { syncStatuses, syncActiveOptions, sellerActiveOptions } = useMPAConsts()

const selectedRowKeys = ref([])
const selectedRows = ref([])

const actionsFetching = ref(false)
const showDeleteSyncModal = ref(false)
const isDeleting = ref(false)

const customTitles = [
  "last_sync_total",
  "last_sync_count",
  "last_sync_failure_count",
  "last_sync_new_count",
  "matched",
  "confirmed",
  "active",
  "is_active"
]

const syncActivationDisabled = computed(() => {
  return selectedRows.value.some(({ is_active }) => Boolean(is_active))
})

const syncDeactivationDisabled = computed(() => {
  return selectedRows.value.some(({ is_active }) => !Boolean(is_active))
})

const selectedSyncsIncludeFailured = computed(() => {
  return selectedRows.value.some(({ status }) => status === "FAILURE")
})

const customTitleTranslates = computed(() => {
  return {
    last_sync_total: i18n.t("total"),
    last_sync_count: i18n.t("validCount"),
    last_sync_failure_count: i18n.t("invalidCount"),
    last_sync_new_count: i18n.t("waitForMatching"),
    matched: i18n.t("comparedCount"),
    confirmed: i18n.t("approvedCount"),
    active: i18n.t("activeCount")
  }
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const onSelectRows = (rowKeys, rows) => {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
}

const clearSelectedRows = () => {
  selectedRows.value = []
  selectedRowKeys.value = []
}

const handleOpenDeletionModal = () => {
  showDeleteSyncModal.value = !showDeleteSyncModal.value
}

const handleMatchSynchronizations = () => {
  if (selectedSyncsIncludeFailured.value) {
    notification.warning({ message: i18n.t("matchWarning") })
    return
  }
  syncService.matchSynchronizations(selectedRows.value)
  clearSelectedRows()
}

const handleApproveSynchronizations = () => {
  if (selectedSyncsIncludeFailured.value) {
    notification.warning({ message: i18n.t("approveWarning") })
    return
  }
  syncService.approveSynchronizations(selectedRows.value)
  clearSelectedRows()
}

const handleActivateSyncs = async () => {
  try {
    actionsFetching.value = true

    await syncService.activateSynchronizations(selectedRows.value)

    notification.success({ message: i18n.t("activated") })
    clearSelectedRows()
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    actionsFetching.value = false
  }
}

const handleDeactivateSyncs = async () => {
  try {
    actionsFetching.value = true

    await syncService.deactivateSynchronizations(selectedRows.value)

    notification.success({ message: i18n.t("deactivated") })
    clearSelectedRows()
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    actionsFetching.value = false
  }
}

const handleDeleteSync = async (uuid) => {
  try {
    isDeleting.value = true

    await syncService.deleteSynchronization(uuid)

    notification.success({ message: i18n.t("deleted") })
    showDeleteSyncModal.value = false
    clearSelectedRows()
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isDeleting.value = false
  }
}

const handleUpdateSync = () => {
  const { slug, seller } = selectedRows.value[0]

  syncService.updateSynchronization({ seller: slug }, seller)
  clearSelectedRows()
}

const handleDownloadReport = (slug) => {
  notification.info({ message: i18n.t("downloadReportWillStart") })
  syncService.getSyncReport(slug)
}

const handleCopyText = (text) => {
  navigator.clipboard.writeText(text)
  message.info(i18n.t("copied"))
}

const getFullImagePath = (path) => `${IMAGES_CDN}/media/assets/images/${getImagePath(path, "full")}`

onMounted(() => {
  setupTable({
    defaultPagination: {
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${i18n.t("fromRecords", { count: total })}`
    }
  })

  onTableChange()
})
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  margin-bottom: 16px;
  &__actions {
    width: fit-content;
    display: flex;
    gap: 16px;
  }
  &__filters {
    width: fit-content;
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    & > * {
      width: 220px;
    }
    & > :first-child {
      width: 275px;
    }
  }
}
.seller {
  display: flex;
  align-items: center;
  gap: 8px;

  &__image {
    min-width: 24px;
    min-height: 24px;
  }
}
.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.success {
  color: $green-color;
  border-color: $green-color;
  accent-color: $green-color;
}

.error {
  color: $red-color;
}
.deletion-modal {
  color: rgba(0, 0, 0, 0.85);

  display: flex;
  flex-flow: column nowrap;
  gap: 8px;

  &__label {
    font-size: 14px;

    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
