<template>
  <div>
    <template v-if="PV_initialAttributes">
      <SynDataTable
        :data="tableData"
        :queryAction="() => false"
        :queryParams="queryParams"
        :fetching="fetchingStatus"
        rowKey="_id"
        :columns="dataTableColumns"
        tableId="ProductValuesAttributesTable"
        class="margin-top"
        defaultOrder="-name"
        :rowActions="rowActions"
        :headerButtons="headerButtons"
      ></SynDataTable>
    </template>
    <template v-if="!PV_initialAttributes">
      <Button
        color="primary"
        @click="PV_showAddAttributeModal(true)"
        class="lonely-button"
        >Добавить</Button
      >
    </template>
  </div>
</template>
<script>
import Button from "components/Button"
import { mapGetters, mapActions, mapMutations } from "vuex"

export default {
  name: "AttributesTable",
  components: {
    Button
  },
  data() {
    return {
      dataTableColumns: [
        {
          key: "code",
          title: "Код",
          width: 90
        },
        {
          key: "name",
          title: "Атрибуты"
        },
        {
          key: "value_type",
          title: "Тип значений",
          width: 110
        }
      ],
      rowActions: [
        {
          label: "Поиск типов",
          action: this.searchTypes
        },
        {
          label: "Удалить",
          action: this.deleteAttribute
        }
      ],
      headerButtons: [
        {
          label: "Добавить",
          action: () => {
            this.PV_showAddAttributeModal(true)
          },
          theme: "primary"
        }
      ]
    }
  },
  methods: {
    deleteAttribute(val) {
      this.PV_selectedAttribute(val)
      this.PV_showDeleteAttributeModal(true)
    },
    searchTypes(val) {
      this.PV_selectedAttribute(val)
      this.PV_searchTypesWithAttribute()
      this.PV_showSearchTypesModal(true)
    },
    ...mapMutations("ProductCatalog", {
      changeQueryParams: "PV_attributesQueryParams",
      PV_selectedAttribute: "PV_selectedAttribute",
      PV_showSearchTypesModal: "PV_showSearchTypesModal",
      PV_showAddAttributeModal: "PV_showAddAttributeModal",
      PV_showDeleteAttributeModal: "PV_showDeleteAttributeModal"
    }),
    ...mapActions("ProductCatalog", {
      PV_searchTypesWithAttribute: "PV_searchTypesWithAttribute"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      queryParams: "PV_attributesQueryParams",
      tableData: "PV_attributesData",
      fetchingStatus: "PV_attributesFetchingStatus",
      PV_initialAttributes: "PV_initialAttributes"
    })
  }
}
</script>
<style lang="scss" scoped></style>
