import { computed, h } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => {
    return [
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("reviewDate"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
        fixed: "left",
        width: 110
      },
      {
        key: "seller_name",
        dataIndex: "order.seller.name",
        title: i18n.t("seller"),
        scopedSlots: {
          customRender: "sellerRenderer"
        },
        fixed: "left",
        width: 140
      },
      {
        key: "author_id",
        dataIndex: "author.id",
        title: "User id",
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "author_id" && sortedInfo?.value?.order,
        width: 100
      },
      {
        key: "author_fullname",
        title: i18n.t("authorFullname"),
        scopedSlots: {
          customRender: "fullname"
        },
        width: 230
      },
      {
        key: "author_contacts",
        dataIndex: "author",
        title: h("div", ["Email", h("br"), i18n.t("phoneNumber")]),
        scopedSlots: {
          customRender: "contactsRenderer"
        },
        width: 200
      },
      {
        key: "rating_product_overall",
        title: i18n.t("ratingOverall"),
        sorter: true,
        sortOrder:
          sortedInfo?.value?.columnKey === "rating_product_overall" && sortedInfo?.value?.order,
        scopedSlots: {
          customRender: "rating"
        },
        width: 115
      },
      {
        key: "comment",
        dataIndex: "comment",
        title: i18n.t("comment"),
        scopedSlots: {
          customRender: "comment"
        },
        width: 300
      },
      {
        key: "product_images",
        title: i18n.t("reviewAddedPhotos"),
        scopedSlots: {
          customRender: "images"
        },
        width: 120
      },
      {
        key: "order_code",
        dataIndex: "order.code",
        title: i18n.t("orderNumber"),
        scopedSlots: {
          customRender: "orderRenderer"
        },
        width: 120
      },
      {
        key: "ordering",
        dataIndex: "ordering",
        title: i18n.t("sorting"),
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "ordering" && sortedInfo?.value?.order,
        width: 90
      },
      {
        key: "parent",
        title: i18n.t("parent"),
        scopedSlots: {
          customRender: "parent"
        },
        width: 80
      },
      {
        key: "moderation_status",
        dataIndex: "moderation_status",
        title: i18n.t("moderationStatus"),
        scopedSlots: {
          customRender: "statusRender"
        },
        fixed: "right",
        width: 150
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        fixed: "right",
        width: 65
      }
    ]
  })
  return columns
}
