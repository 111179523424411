import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_searchedProductsOfType: [],
  PC_copiedAttributes: [],
  PC_selectedProductToCopyAttrs: {}
})

const getters = {
  PC_searchedProductsOfType: (state) => state.PC_searchedProductsOfType,
  PC_copiedAttributes: (state) => state.PC_copiedAttributes,
  PC_selectedProductToCopyAttrs: (state) => state.PC_selectedProductToCopyAttrs
}

const mutations = {
  PC_setSearchedProductsOfType: (state, { products, currentProduct }) => {
    let filteredProducts = products.filter((item) => {
      return (
        item.attributes && // attributes key exist
        item.attributes.length && // there are some attributes
        item.code !== currentProduct.code
      ) // product is not the same as the current one
    })
    state.PC_searchedProductsOfType = filteredProducts
  },
  PC_copiedAttributes: (state, { attributesData }) => {
    state.PC_copiedAttributes = attributesData.items
  },
  PC_resetSearchedProductsOfTypeValues(state) {
    state.PC_searchedProductsOfType = []
    state.PC_copiedAttributes = []
    state.PC_selectedProductToCopyAttrs = {}
  },
  PC_selectedProductToCopyAttrs(state, value) {
    state.PC_selectedProductToCopyAttrs = value
  },
  PC_selectedProductToCopyAttrsSpliceAttrs(state, value) {
    let attrs = state.PC_selectedProductToCopyAttrs.attributes
    let removeIndex = attrs.findIndex((item) => item.code === value)
    attrs.splice(removeIndex, 1)
  }
}

const actions = {
  PC_searchProductOfType({ commit, getters }, value) {
    // reset
    commit("PC_resetSearchedProductsOfTypeValues")

    let currentProduct = getters.PC_EditableProduct
    let typeName =
      (currentProduct.type[0] && currentProduct.type[0].full_name) || currentProduct.type.full_name

    let searchData = buildMagicSearch({
      resource: "MPC",
      field: ["name", "type_name"],
      value: [value, typeName],
      sort: null,
      limit: 20,
      offset: 0
    })

    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((products) => {
        commit("PC_setSearchedProductsOfType", { products, currentProduct })
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PC_selectProductOfType({ commit, getters, dispatch }, value) {
    commit("PC_selectedProductToCopyAttrs", value)

    let sendData = {
      product: value,
      attributes: value.attributes,
      mutationName: "PC_copiedAttributes",
      newAttribute: false,
      checkAttributes: false
    }
    dispatch("PC_fetchProductAttributes", sendData)
  },
  async PC_addNewAttributes({ commit, getters, dispatch }) {
    let product = getters.PC_EditableProduct
    // throw away attrs, which are already added to product
    let filteredAttrs = getters.PC_copiedAttributes.filter((item) => {
      let flag = true
      for (let i = 0; i < product.attributes.length; i++) {
        let code = product.attributes[i].code
        if (code === item.code || item.status === "IDLE") {
          commit("PC_selectedProductToCopyAttrsSpliceAttrs", code) // remove this attr among PC_selectedProductToCopyAttrs
          flag = false
          break
        }
      }
      return flag
    })

    // if there're nothing left after filtering
    if (filteredAttrs.length === 0) {
      notification.info({ message: "Продукт не содержит новых аттрибутов" })
      // close modal
      commit("PC_showCopyFromProductModal", false)
      return
    }

    // commit new attrs to product attributes and to attributes table
    for (let i = 0; i < filteredAttrs.length; i++) {
      // make sure that id is unique
      filteredAttrs[i]._id = "attributesTableRow-" + filteredAttrs[i].code

      let order = product.attributes.length
      let sendObject = {
        attr: filteredAttrs[i],
        order
      }

      if (filteredAttrs[i].value_type === "LIST" && filteredAttrs[i].possible_values) {
        sendObject.value = getters.PC_selectedProductToCopyAttrs.attributes[i].value
      } else if (filteredAttrs[i].value_type === "MULTILIST" && filteredAttrs[i].possible_values) {
        if (getters.PC_selectedProductToCopyAttrs.attributes[i].value instanceof Array) {
          sendObject.value = getters.PC_selectedProductToCopyAttrs.attributes[i].value
        } else {
          sendObject.value = [getters.PC_selectedProductToCopyAttrs.attributes[i].value]
        }
      } else {
        sendObject.value = filteredAttrs[i].unitValue
      }

      if (getters.PC_selectedProductToCopyAttrs.attributes[i].unit) {
        sendObject.attr.selectedUnit = {
          code: getters.PC_selectedProductToCopyAttrs.attributes[i].unit
        }
      }

      commit("PC_setNewAttribute", sendObject)
    }

    // close modal
    commit("PC_showCopyFromProductModal", false)

    await dispatch("PC_updateAttributesChanges")
    let attrs = await dispatch("PC_restartFetchAttributes")
    if (!product.type) return
    let missing = await dispatch("PC_checkRequiredAttributeOfType")
    if (!missing) return
    dispatch("PC_missingRequiredAttributes")
    dispatch("PC_missingMainAttributes")
  },
  async PC_replaceAttributes({ commit, getters, dispatch }) {
    let product = getters.PC_EditableProduct
    let attrs = getters.PC_copiedAttributes
    attrs = attrs.filter((item) => {
      return item.status !== "IDLE"
    })
    // empty product.attributes
    commit("PC_emptyEditableProductInnerAttributes")
    // empty attrs table
    commit("PC_emptyEditableProductAttributes")
    // commit new attrs to product attributes and to attributes table
    for (let i = 0; i < attrs.length; i++) {
      let order = product.attributes.length
      let sendObject = {
        attr: attrs[i],
        value: attrs[i].unitValue,
        order
      }

      if (attrs[i].possible_values) {
        sendObject.value = getters.PC_selectedProductToCopyAttrs.attributes[i].value
      } else {
        sendObject.value = attrs[i].unitValue
      }

      if (getters.PC_selectedProductToCopyAttrs.attributes[i].unit) {
        sendObject.attr.selectedUnit = {
          code: getters.PC_selectedProductToCopyAttrs.attributes[i].unit
        }
      }

      commit("PC_setNewAttribute", sendObject)
    }
    // close modal
    commit("PC_showCopyFromProductModal", false)

    let attributes = await dispatch("PC_restartFetchAttributes")
    if (!product.type) return
    dispatch("PC_checkRequiredAttributeOfType")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
