<template>
  <a-form
    :form="form"
    layout="vertical"
  >
    <a-row :gutter="24">
      <a-col :span="8">
        <a-divider orientation="left">
          {{ $t("sellerInfo") }}
        </a-divider>
        <a-form-item :label="$t('companyName')">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: $t('fieldRequire') }]
              }
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('owner')">
          <a-select
            v-decorator="[
              'owner',
              {
                rules: [{ required: true, message: $t('fieldRequire') }]
              }
            ]"
            show-search
            :placeholder="$t('enterPhoneNumber')"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="customersFetching ? undefined : null"
            @search="handleSearchCustomers"
          >
            <a-spin
              v-if="customersFetching"
              slot="notFoundContent"
              size="small"
            />
            <a-select-option
              v-for="item in customers.length === 0 ? defaultCustomers : customers"
              :key="item.uuid"
            >
              {{ item.mobile_phone }} ({{ item.username }} / {{ item.first_name }}
              {{ item.last_name }})
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="$t('companyManagers')">
          <a-select
            v-decorator="['users']"
            show-search
            :placeholder="$t('enterPhoneNumber')"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="customersFetching ? undefined : null"
            mode="multiple"
            @search="handleSearchCustomers"
            @change="handleChangeUsers"
          >
            <a-spin
              v-if="customersFetching"
              slot="notFoundContent"
              size="small"
            />
            <a-select-option
              v-for="item in customers.length === 0 ? defaultCustomers : customers"
              :key="item.uuid"
            >
              {{ item.mobile_phone }} ({{ item.username }} / {{ item.first_name }}
              {{ item.last_name }})
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="$t('publicPhoneNumber')">
          <a-input
            v-decorator="[
              'mobile_phone_public',
              {
                rules: [{ maxLength: 12, message: $t('maxLengthError', { length: 12 }) }]
              }
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('notifyEmail')">
          <a-select
            v-decorator="['notify_emails']"
            :placeholder="$t('notifyEmailPlaceholder')"
            :show-arrow="false"
            :filter-option="false"
            mode="tags"
            :tokenSeparators="[',']"
          >
            <a-select-option
              v-for="item in notifyEmailsSelectedArray"
              :key="item"
            >
              {{ item }}
              <a-icon
                type="copy"
                style="cursor: pointer; font-size: 12px; margin: 0 4px"
                @click="handleCopyEmail(item)"
              />
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :label="$t('sellerLogo')"
          :help="$t('recommendedSize', { size: '100x100' })"
          required
        >
          <a-upload
            v-decorator="['small_logo']"
            name="small_logo"
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :style="{ width: '128px', height: '128px' }"
          >
            <img
              v-if="logoPreviewUrl.length > 0"
              :src="logoPreviewUrl"
              alt="avatar"
              :style="{ maxWidth: '100%' }"
            />
            <div v-else>
              <div class="ant-upload-text">
                {{ $t("upload") }}
              </div>
            </div>
          </a-upload>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-divider orientation="left">
          {{ $t("accessRestrictions") }}
        </a-divider>
        <a-form-item :label="$t('offersVisibilityTitle')">
          <a-select v-decorator="['offers_visibility']">
            <a-select-option key="ALL">
              {{ $t("showAllOffers") }}
            </a-select-option>
            <a-select-option key="HIDDEN">
              {{ $t("hideOffers") }}
            </a-select-option>
          </a-select>

          <a-textarea
            v-decorator="['hidden_offers_message']"
            :style="{ marginTop: '10px' }"
            :placeholder="$t('textOnSellerPage')"
            :rows="3"
            :disabled="disableHiddenOffersMessage"
          />
        </a-form-item>

        <a-form-item>
          <a-switch v-decorator="['is_backoffice_available', { valuePropName: 'checked' }]">
            <a-icon
              slot="checkedChildren"
              type="check"
            />
            <a-icon
              slot="unCheckedChildren"
              type="close"
            />
          </a-switch>
          &nbsp;{{ $t("allowAccessToSeller") }}
        </a-form-item>

        <a-form-item>
          <a-switch v-decorator="['is_active', { valuePropName: 'checked' }]">
            <a-icon
              slot="checkedChildren"
              type="check"
            />
            <a-icon
              slot="unCheckedChildren"
              type="close"
            />
          </a-switch>
          &nbsp;{{ $t("sellerIsActivated") }}
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-divider orientation="left">
          {{ $t("additionally") }}
        </a-divider>

        <a-form-item :label="$t('sellerHealth')">
          <a-select v-decorator="['health']">
            <a-select-option
              v-for="item in healthStatuses"
              :key="item.value"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="$t('participationInTheBonusProgram')">
          <a-card size="small">
            <a-switch v-decorator="['is_bonuses_available', { valuePropName: 'checked' }]">
              <a-icon
                slot="checkedChildren"
                type="check"
              />
              <a-icon
                slot="unCheckedChildren"
                type="close"
              />
            </a-switch>
            &nbsp;{{ $t("allowGetBonuses") }}

            <a-slider
              v-decorator="['max_bonuses_percent']"
              :step="5"
            />
            % {{ $t("maxBonuses") }}
          </a-card>
        </a-form-item>

        <a-form-item>
          <a-switch v-decorator="['allow_synthetic_delivery_method', { valuePropName: 'checked' }]">
            <a-icon
              slot="checkedChildren"
              type="check"
            />
            <a-icon
              slot="unCheckedChildren"
              type="close"
            />
          </a-switch>
          &nbsp;{{ $t("allow_synthetic_delivery_method") }}
        </a-form-item>
      </a-col>
    </a-row>

    <a-row>
      <a-collapse>
        <a-collapse-panel
          key="1"
          :header="$t('sellerDescription')"
        >
          <EditorHtml
            :value="sellerDescription"
            @change="handleChangeSellerDescription"
          />
        </a-collapse-panel>
      </a-collapse>
    </a-row>
  </a-form>
</template>

<script>
import useHealthStatuses from "@/composables/useHealthStatuses.js"

import EditorHtml from "@/components/Editor/EditorHtml.vue"

import { fetchCustomers } from "@/modules/MPAdmin/services/sellersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useCancellableRequest from "@/composables/useCancellableRequest"

export default {
  name: "SellerEditGeneralForm",

  components: {
    EditorHtml
  },

  props: {
    // Required for correct show default selected labels
    defaultCustomers: {
      type: Array,
      default: () => []
    }
  },

  setup() {
    const { makeRequest } = useCancellableRequest(fetchCustomers)
    const { healthStatuses } = useHealthStatuses()

    return {
      healthStatuses,
      makeRequest
    }
  },

  data() {
    return {
      form: this.$form.createForm(this, {
        name: "editSeller",
        onFieldsChange: (props, fields) => {
          // Emit 'fieldChange' only if one field changed, because when set default values changing more one fields
          if (Object.keys(fields).length === 1) {
            this.$emit("fieldChange")
          }

          if (fields.notify_emails) {
            this.notifyEmailsSelectedArray = fields.notify_emails.value
          }

          if (fields.offers_visibility) {
            this.disableHiddenOffersMessage = fields.offers_visibility.value === "ALL"
          }
        }
      }),
      // Need for render custom select options in notify_emails field
      notifyEmailsSelectedArray: [],
      sellerDescription: "",
      fetchTimeout: null,
      customers: [],
      customersFetching: false,
      logoPreviewUrl: "",
      disableHiddenOffersMessage: true
    }
  },
  methods: {
    handleCopyEmail(email) {
      navigator.clipboard.writeText(email)

      this.$notification.success({
        message: this.$t("emailCopied")
      })
    },
    beforeUpload(file) {
      let fileReader = new FileReader()

      fileReader.addEventListener("load", (event) => {
        this.logoPreviewUrl = event.target.result
      })

      fileReader.readAsDataURL(file)
      return false
    },

    handleSearchCustomers(value) {
      if (this.fetchTimeout) {
        clearTimeout(this.fetchTimeout)
        this.fetchTimeout = null
      }

      this.fetchTimeout = setTimeout(async () => {
        try {
          const queryParams = {
            mobile_phone: value,
            is_seller_owner: false,
            is_active: true
          }
          const { data } = await this.makeRequest({ queryParams })

          this.customers = data.results
        } catch (error) {
          if (error.message === "canceled") return

          notifyResponseError({ error })
        } finally {
          this.customersFetching = false
          clearTimeout(this.fetchTimeout)
          this.fetchTimeout = null
        }
      }, 1000)
    },

    handleChangeSellerDescription(value) {
      this.$emit("fieldChange")

      this.sellerDescription = value
    },

    handleChangeUsers(users) {
      const userUuid = users[users.length - 1]
      const customer = this.customers.find((item) => item.uuid === userUuid)

      if (customer && customer.seller) {
        this.$confirm({
          title: this.$t("applying"),
          content: this.$t("unboundAlert", { seller: customer.seller.name }),
          onCancel: () => {
            this.form.setFieldsValue({
              users: users.filter((item) => item !== userUuid)
            })
          }
        })
      }
    }
  }
}
</script>
