import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const placeholders = computed(() => ({
    name: i18n.t("seller"),
    slug: i18n.t("slug"),
    custom_label: "Custom label",
    cpa_kind: "CPA",
    cpa_status: `CPA ${i18n.t("status")}`,
    is_active: i18n.t("sellerState"),
    is_ref: "Ref",
    feed_logic: i18n.t("feedLogicPlaceholder")
  }))

  const cpaStatusOptions = computed(() => [
    {
      label: i18n.t("active"),
      value: "ACTIVE"
    },
    {
      label: `${i18n.t("paused")} (${i18n.t("cpaStatus.balanceIsOver")})`,
      value: "PAUSED_NO_MONEY"
    },
    {
      label: i18n.t("disabled"),
      value: "TURNED_OFF"
    }
  ])

  const labelExistOptions = computed(() => [
    {
      label: `${i18n.t("appoint")} ${i18n.t("existing").toLowerCase()} custom label`,
      value: true
    },
    {
      label: `${i18n.t("create")} ${i18n.t("new").toLowerCase()} custom label`,
      value: false
    }
  ])

  const reportDateFilterOptions = computed(() => {
    return [
      {
        label: i18n.t("dateWriteOff"),
        value: "WRITE-OFF"
      },
      {
        label: i18n.t("dateOrdered"),
        value: "ORDERED"
      }
    ]
  })

  const getSellerTagsList = (record) => [
    { condition: record.cpa_kind === "REGULAR", tag: { color: "orange", text: "C" } },
    {
      condition: record.cpa_kind === "CATEGORICAL",
      tag: { color: "orange", text: "C2" }
    },
    { condition: !record.is_active, tag: { color: "", text: "B" } },
    { condition: record.is_ref, tag: { color: "purple", text: "R" } },
    { condition: record.feed_logic.ga === "FULL", tag: { color: "cyan", text: "M" } },
    { condition: record.feed_logic.fb === "FULL", tag: { color: "blue", text: "F" } },
    { condition: record.feed_logic.ek === "FULL", tag: { color: "yellow", text: "E" } }
  ]

  return {
    placeholders,
    cpaStatusOptions,
    labelExistOptions,
    reportDateFilterOptions,

    getSellerTagsList
  }
}
