<template>
  <Draggable
    ghostClass="ghost"
    tag="tbody"
    handle=".handle"
    :list="appData.dataSource"
    @end="handleSort"
  >
    <slot></slot>
  </Draggable>
</template>
<script>
import Draggable from "vuedraggable"

export default {
  name: "TableGraggableWrapper",
  components: {
    Draggable
  },
  inject: { appData: {}, onSort: { default: () => {} } },
  methods: {
    handleSort(event) {
      this.onSort(event)
    }
  }
}
</script>
