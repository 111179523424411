<template>
  <a-form
    :form="editRatingForm"
    layout="horizontal"
    style="padding-bottom: 70px"
  >
    <a-divider>{{ $t("editRatingFactor") }}</a-divider>

    <div class="factor-rating-form">
      <a-form-item>
        <div>{{ $t("currentValues") }}:</div>
        <div>{{ $t("newValues") }}:</div>
      </a-form-item>

      <a-form-item label="SHORT">
        {{ getFixedFloat(currentRow.rf_s) }}
        <a-input
          :min="0"
          :max="99999"
          v-decorator="['rf_s']"
        />
      </a-form-item>

      <a-form-item label="MIDDLE">
        {{ getFixedFloat(currentRow.rf_m) }}
        <a-input
          :min="0"
          :max="99999"
          v-decorator="['rf_m']"
        />
      </a-form-item>

      <a-form-item label="LONG">
        {{ getFixedFloat(currentRow.rf_l) }}
        <a-input
          :min="0"
          :max="99999"
          v-decorator="['rf_l']"
        />
      </a-form-item>

      <a-form-item :label="$t('total')">
        {{ getFixedFloat(currentRow.rf) }}
        <a-input
          disabled
          :value="newRating"
        />
      </a-form-item>
    </div>
    <a-divider>{{ $t("editRating") }}</a-divider>

    <a-form-item :label="$t('rating')">
      <a-input-number
        v-decorator="['rating', { initialValue: '' }]"
        :min="0"
        :max="5"
        :precision="0"
      />
    </a-form-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="fetchingRating"
        @click="onSaveRating"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { updateProductsBulk } from "@/modules/MPAdmin/services/productsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  props: {
    currentRow: {
      type: Object,
      default: () => ({})
    },

    showModal: {
      type: Boolean,
      default: false
    }
  },

  emits: ["close"],

  mounted() {
    this.calculateRating()
  },

  data() {
    return {
      editRatingForm: this.$form.createForm(this, {
        name: "editRatingForm",
        mapPropsToFields: () => {
          const { rf_s, rf_m, rf_l, rating } = this.currentRow
          return {
            rf_s: this.$form.createFormField({
              value: rf_s || 0
            }),
            rf_m: this.$form.createFormField({
              value: rf_m || 0
            }),
            rf_l: this.$form.createFormField({
              value: rf_l || 0
            }),
            rating: this.$form.createFormField({
              value: rating || 0
            })
          }
        },
        onValuesChange: () => this.calculateRating()
      }),

      fetchingRating: false,
      current: { ...this.currentRow },
      newRating: 0
    }
  },

  methods: {
    getFixedFloat(val) {
      if (val) {
        return +val.toFixed(2)
      }
    },

    async onSaveRating() {
      this.editRatingForm.validateFields(async (err, values) => {
        if (err) return

        this.fetchingRating = true

        try {
          const { data } = await updateProductsBulk([{ id: this.currentRow.id, ...values }])

          if (!data?.meta?.errors?.length) {
            this.$notification.success({
              message: this.$t("updated")
            })
            this.closeModal()
          } else {
            notifyResponseError({ error: { response: { data: data.meta.errors } } })
          }
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.fetchingRating = false
        }
      })
    },

    calculateRating() {
      this.$nextTick(() => {
        const { rf_s, rf_m, rf_l } = this.editRatingForm.getFieldsValue(["rf_s", "rf_m", "rf_l"])

        this.newRating = +rf_s + +rf_m + +rf_l || ""
      })
    },

    closeModal() {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
.factor-rating-form {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  & > :first-child {
    width: 200px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    color: $font-default;
  }
}
</style>
