<template>
  <a-form
    :form="billingForm"
    style="margin-bottom: 40px"
  >
    <a-tabs v-model="activeLanguage">
      <a-tab-pane
        v-for="lang in languages"
        :key="lang"
        :tab="lang.toUpperCase()"
        forceRender
      >
        <a-form-item :label="$t('name')">
          <a-input
            v-decorator="[
              `title_${lang}`,
              {
                rules: titleRules
              }
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('description')">
          <a-textarea v-decorator="[`description_${lang}`]" />
        </a-form-item>
      </a-tab-pane>
    </a-tabs>

    <a-table
      :dataSource="configs.items"
      :loading="isFetching"
      :columns="columns"
      :pagination="false"
      rowKey="id"
      size="middle"
    >
      <template #title>
        <div style="display: flex; justify-content: space-between">
          <div>{{ $t("configurations") }}</div>
          <a-button
            type="primary"
            @click="addConfig()"
          >
            {{ $t("addConfiguration") }}
          </a-button>
        </div>
      </template>

      <template
        v-for="columnKey of ['expire_in_days', 'price']"
        #[columnKey]="record, _, index"
      >
        <TableEditableCellInput
          :text="record[columnKey]"
          :key="columnKey"
          @change="(value, closeEdit) => editCell(columnKey, value, record, closeEdit, index)"
        />
      </template>

      <template #active="record">
        <a-switch
          :checked="record.is_active"
          @change="toggleActivity({ is_active: !record.is_active, id: record.id })"
        />
      </template>

      <template #monthPrice="{ price, expire_in_days }">
        {{ getPricePerMounth(price, expire_in_days) }}
      </template>
    </a-table>

    <a-form-item style="margin: 0">
      <a-checkbox v-decorator="['auto_renewal_tariff', { valuePropName: 'checked' }]">
        {{ $t("autoRenewTariff") }}
      </a-checkbox>
    </a-form-item>

    <a-form-item style="margin: 0">
      <a-checkbox
        v-decorator="['is_active', { valuePropName: 'checked' }]"
        :disabled="configs.count === 0"
      >
        {{ $t("avaliableSellerToBuy") }}
      </a-checkbox>
    </a-form-item>

    <a-form-item :label="$t('activeOffersCount')">
      <a-input-number
        :min="0"
        :max="999999"
        :precision="0"
        v-decorator="['offers_count_limit', { initialValue: 0 }]"
      />
    </a-form-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
        >{{ $t("apply") }}</a-button
      >
    </div>
  </a-form>
</template>

<script>
import TableEditableCellInput from "@/ant-components/TableEditableCellInput/index.js"

import {
  addTariffConfigs,
  createTariff,
  updateTariff,
  updateTariffConfig
} from "@/modules/MPAdmin/services/generalSettingService/billingTariffsService.js"

import useTariffsColumns from "../useTariffsColumns"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  name: "CreateEditBillingModal",

  components: {
    TableEditableCellInput
  },

  props: {
    prototype: {
      type: Object,
      default: () => ({})
    }
  },

  setup() {
    const { billingModalColumns: columns } = useTariffsColumns()

    return {
      columns
    }
  },

  data() {
    return {
      billingForm: this.$form.createForm(this, {
        name: "billingForm",

        mapPropsToFields: () => {
          return {
            title_uk: this.$form.createFormField({
              value: this.prototype?.title?.uk || ""
            }),
            title_en: this.$form.createFormField({
              value: this.prototype?.title?.en || ""
            }),
            title_ru: this.$form.createFormField({
              value: this.prototype?.title?.ru || ""
            }),
            description_uk: this.$form.createFormField({
              value: this.prototype?.description?.uk || ""
            }),
            description_en: this.$form.createFormField({
              value: this.prototype?.description?.en || ""
            }),
            description_ru: this.$form.createFormField({
              value: this.prototype?.description?.ru || ""
            }),
            auto_renewal_tariff: this.$form.createFormField({
              value: this.prototype?.auto_renewal_tariff || false
            }),
            is_active: this.$form.createFormField({
              value: this.prototype?.is_active || false
            }),
            offers_count_limit: this.$form.createFormField({
              value: this.prototype?.offers_count_limit || 0
            })
          }
        }
      }),

      isFetching: false,
      languages: ["uk", "en", "ru"],
      activeLanguage: "uk",
      configs: {
        items: [],
        count: 0
      }
    }
  },

  mounted() {
    if (this.prototype.id) {
      this.configs.items = this.prototype.configs
      this.configs.count = this.prototype.configs.length
    }
  },

  methods: {
    addConfig() {
      this.configs.items.push({
        expire_in_days: 0,
        price: 0,
        is_active: false,
        isNew: true,
        id: `${++this.configs.count}_${Math.random().toString(36).substr(2, 9)}`
      })
      this.configs.count++
    },

    async onSave() {
      this.billingForm.validateFields(async (err, values) => {
        if (err) {
          this.$notification.error({ message: this.$t("fillFields") })
          return
        }

        this.isFetching = true

        const actionData = {
          title: this.createLanguageObject("title", values),
          description: this.createLanguageObject("description", values),
          offers_count_limit: values.offers_count_limit,
          is_active: values.is_active,
          auto_renewal_tariff: values.auto_renewal_tariff
        }
        try {
          if (this.prototype.id) {
            await this.updateConfigs(this.prototype.id)
            const { data } = await updateTariff(actionData, this.prototype.id)
            this.$emit("updateTariff", data)
          } else {
            const { data } = await createTariff(actionData)

            await this.updateConfigs(data.id)
            this.$emit("createTariff")
          }

          this.$notification.success({
            message: this.$t(this.prototype.id ? "updated" : "created")
          })
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.isFetching = false
        }
      })
    },

    createLanguageObject(fieldPrefix, values) {
      const languageObject = {}
      this.languages.forEach((lang) => {
        languageObject[lang] = values[`${fieldPrefix}_${lang}`]
      })

      return languageObject
    },

    async updateConfigs(tariffId) {
      const promisesArr = []

      this.configs.items.forEach((item) => {
        const sendData = {
          price: item.price,
          expire_in_days: item.expire_in_days,
          is_active: item.is_active
        }

        if (item.isNew) {
          promisesArr.push(addTariffConfigs(tariffId, sendData))
        } else if (item.isChanged) {
          promisesArr.push(updateTariffConfig(tariffId, item.id, sendData))
        }
      })

      await Promise.all(promisesArr)
    },

    toggleActivity({ is_active, id }) {
      this.configs.items = this.configs.items.map((item) => {
        if (item.id === id) {
          item.is_active = is_active
          item.isChanged = true
        }
        return item
      })
    },

    editCell(currentKey, value, record, closeEdit, index) {
      closeEdit()
      const { id } = record
      if (!id) {
        this.configs.items[index][currentKey] = value
      } else {
        this.configs.items[index][currentKey] = value
        this.configs.items[index].isChanged = true
      }
    },

    getPricePerMounth(price, expire_in_days) {
      return +price && +expire_in_days ? Math.round((30 * price) / expire_in_days) : ""
    }
  },

  computed: {
    titleRules() {
      return [
        { required: true, message: this.$t("emptyError") },
        { max: 20, message: this.$t("maxLengthError", { length: 20 }) }
      ]
    }
  }
}
</script>
