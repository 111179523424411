<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PT_showProductsModalGet"
      :title="'Внимание! Обнаружены другие значения'"
      size="large"
    >
      <div>
        <label
          ><span
            >Внимание! Для удаления сначала отредактируйте карточки товаров, что имеют значения по
            данной связке атрибута и типа</span
          ></label
        >
        <SynDataTable
          :data="tableData"
          :queryAction="() => false"
          :queryParams="queryParams"
          rowKey="code"
          :columns="dataTableColumns"
          tableId="ProductValuesProductsTable"
          class="margin-top"
          :selectableMode="true"
          :limitValues="limitValues"
        ></SynDataTable>
      </div>
      <span slot="footer"> </span>
    </Modal>
  </div>
</template>
<script>
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "ProductsModal",
  components: {
    Modal
  },
  data() {
    return {
      dataTableColumns: [
        {
          key: "code",
          title: "Код",
          width: 90,
          selectable: true
        },
        {
          key: "full_name",
          title: "Полное название",
          selectable: true
        }
      ],
      limitValues: [
        { label: "100", value: 100 },
        { label: "200", value: 200 }
      ]
    }
  },
  methods: {
    closeModal() {
      this.PT_showProductsModal(false)
    },
    ...mapMutations("ProductCatalog", {
      PT_showProductsModal: "PT_showProductsModal"
    }),
    ...mapActions("ProductCatalog", {})
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PT_showProductsModalGet: "PT_showProductsModal",
      tableData: "PT_productsTable",
      queryParams: "PT_productsTableQueryParams"
    })
  }
}
</script>
<style lang="scss" scoped></style>
