import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo }) => {
  const columns = computed(() => [
    {
      title: i18n.t("referrer"),
      key: "code",
      dataIndex: "code",
      elipsis: true,
      scopedSlots: {
        customRender: "codeRenderer"
      },
      fixed: "left",
      width: 220
    },
    {
      title: i18n.t("phoneNumber"),
      key: "mobile_phone",
      dataIndex: "mobile_phone",
      width: 160
    },
    {
      title: i18n.t("name"),
      key: "full_name",
      dataIndex: "full_name",
      width: 435
    },
    {
      title: i18n.t("refCount"),
      key: "all_referrals",
      dataIndex: "all_referrals",
      sortOrder: sortedInfo.value.columnKey === "all_referrals" && sortedInfo.value.order,
      sorter: true,
      width: 160
    },
    {
      title: i18n.t("refActiveCount"),
      key: "active_referrals",
      dataIndex: "active_referrals",
      sortOrder: sortedInfo.value.columnKey === "active_referrals" && sortedInfo.value.order,
      sorter: true,
      width: 220
    },
    {
      title: i18n.t("popOver.orderCount"),
      key: "orders_count",
      dataIndex: "orders_count",
      sortOrder: sortedInfo.value.columnKey === "orders_count" && sortedInfo.value.order,
      sorter: true,
      width: 220
    },
    {
      title: i18n.t("positionsToDebit"),
      key: "available_count",
      dataIndex: "available_count",
      sortOrder: sortedInfo.value.columnKey === "available_count" && sortedInfo.value.order,
      sorter: true,
      width: 220
    },
    {
      title: i18n.t("sumToDebit"),
      key: "available_total",
      dataIndex: "available_total",
      sortOrder: sortedInfo.value.columnKey === "available_total" && sortedInfo.value.order,
      sorter: true,
      width: 220
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      scopedSlots: { customRender: "actions" },
      width: 65
    }
  ])

  return {
    columns
  }
}
