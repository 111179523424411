<template>
  <a-drawer
    :title="`${$t('editingProductLocalization')} ${mpcCode} (${locale})`"
    :width="drawerWidth"
    :visible="visible"
    @close="onClose"
  >
    <a-tabs
      v-model="tabPosition"
      type="card"
    >
      <a-tab-pane
        v-if="locale === 'en'"
        key="common"
        :tab="$t('general')"
        forceRender
      >
        <EditProductLocalizationCommonTab
          ref="productCommon"
          :mpc-code="productCode"
          :product="product"
          :product-localized="productLocalized"
          :fetching="productFetching"
          :locale="locale"
          @formChanged="productChange"
        />
      </a-tab-pane>
      <a-tab-pane
        key="attributes"
        :tab="$t('attributes')"
      >
        <EditProductLocalizationAttributesTab
          :mpc-code="productCode"
          :attributes="mappedAttributes"
          :fetching="productFetching"
          :product="product"
          :locale="locale"
          @updateAttribute="handleUpdateMappedAttributes"
          @attributesChanged="productChange"
        />
      </a-tab-pane>
    </a-tabs>

    <div class="modal-buttons-wrapper">
      <a-button
        :style="{ marginRight: '8px' }"
        @click="onClose"
      >
        {{ $t("close") }}
      </a-button>
      <a-button
        type="primary"
        :loading="saveProcessing"
        @click="onSubmit"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import EditProductLocalizationCommonTab from "./_components/EditProductLocalizationCommonTab.vue"
import EditProductLocalizationAttributesTab from "./_components/EditProductLocalizationAttributesTab"
import {
  fetchProductRu,
  fetchProductLocalized,
  updateProductLocalized
} from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { formatValuesForRequest } from "../EditProductModal/_components/EditProductAttributesTab/_components/utils"
import { computed, provide } from "vue"

export default {
  name: "EditProductLocalizationModal",

  components: {
    EditProductLocalizationCommonTab,
    EditProductLocalizationAttributesTab
  },

  props: {
    visible: {
      type: Boolean,
      required: true
    },
    mpcCode: {
      type: String,
      default: ""
    },
    locale: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    provide(
      "languageForDataFetch",
      computed(() => props.locale)
    )
  },
  data() {
    return {
      tabPosition: "attributes",
      productFetching: false,
      saveProcessing: false,
      product: {},
      productLocalized: {},
      mappedAttributes: [],
      hasChangedProductData: false // If true show close modal confirm.
    }
  },
  computed: {
    productCode() {
      return this.mpcCode
    },
    drawerWidth() {
      return window.innerWidth < 1600 ? "95%" : 1500
    }
  },
  watch: {
    mpcCode(value) {
      if (value.length > 0) {
        this.tabPosition = this.locale === "en" ? "common" : "attributes"
        this.getProduct()
      }
    }
  },
  methods: {
    // If product form or attributes changed.
    productChange() {
      this.hasChangedProductData = true
    },
    clearData() {
      this.hasChangedProductData = false
      this.product = {}
      this.productLocalized = {}
      this.mappedAttributes = []
      this.tabPosition = "attributes"
      this.$emit("onClose")
      // this.$refs.productCommon.$refs.form.resetFields()
    },
    onClose() {
      if (this.hasChangedProductData) {
        this.$confirm({
          title: this.$t("localizationEditingWarning"),
          content: this.$t("cancelEditingWarning"),
          okText: this.$t("close"),
          cancelText: this.$t("cancel"),
          onOk: () => {
            this.clearData()
          }
        })
      } else {
        this.clearData()
      }
    },

    // Update attribute
    handleUpdateMappedAttributes(attribute) {
      const attrIndex = this.mappedAttributes.findIndex(({ code }) => code === attribute.code)
      if (attrIndex < 0) return
      this.mappedAttributes[attrIndex] = attribute
    },

    async onSubmit() {
      try {
        this.saveProcessing = true

        const attributes = formatValuesForRequest(this.mappedAttributes)

        const params = {
          code: this.productCode,
          product: {
            attributes
          },
          language: this.locale
        }
        if (this.locale === "en") {
          const commonForm = await this.$refs.productCommon.getLocalizedFormValues()
          params.product = { ...params.product, ...commonForm }
        }

        await updateProductLocalized(params)

        this.hasChangedProductData = false
        this.$notification.success({ message: this.$t("updated") })
      } catch (error) {
        notifyResponseError({ error, message: this.$t("notUpdated") })
      } finally {
        this.saveProcessing = false
      }
    },

    async getProduct() {
      const params = {
        code: this.mpcCode,
        language: this.locale
      }

      this.productFetching = true

      try {
        const [resultsDefaultLocale, resultsEditableLocale] = await Promise.all([
          await fetchProductRu(params),
          await fetchProductLocalized(params)
        ])

        this.product = resultsDefaultLocale.data
        this.productLocalized = resultsEditableLocale.data

        if (!resultsEditableLocale?.data?.attributes?.length) return

        this.mappedAttributes = resultsEditableLocale.data.attributes.map((item, index) => ({
          ...item,
          stable_value: resultsDefaultLocale.data.attributes[index].value
        }))
      } catch (error) {
        notifyResponseError({ error, message: this.$t("notUpdated") })
      } finally {
        this.productFetching = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
