import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo, languageForDataFetch } = {}) => {
  const columns = computed(() => [
    {
      key: "code",
      title: "MPC-code",
      dataIndex: "code",
      width: 170,
      fixed: "left"
    },
    {
      key: "p_code",
      title: "P-code",
      dataIndex: "p_code",
      width: 170,
      fixed: "left"
    },
    {
      key: "meta_xml_seller_slug",
      title: i18n.t("seller"),
      width: 180,
      scopedSlots: {
        customRender: "sellerRenderer"
      }
    },
    {
      key: "type_name",
      dataIndex: "type_name",
      title: i18n.t("type"),
      width: 190
    },
    {
      key: "brand_name",
      title: i18n.t("brand"),
      dataIndex: "brand_name",
      width: 190
    },
    {
      key: "public_title",
      title: `${i18n.t("titleForPublication")} (${languageForDataFetch?.value})`,
      width: 360,
      dataIndex: "public_title",
      ellipsis: true
    },
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "photoRenderer"
      },
      fixed: "right",
      width: 80
    },
    {
      key: "status_locale",
      title: i18n.t("language"),
      dataIndex: "status_locale",
      width: 70,
      fixed: "right",
      scopedSlots: {
        customRender: "localeRenderer"
      }
    },
    {
      key: "timestamp",
      title: i18n.t("date"),
      dataIndex: "timestamp",
      width: 105,
      fixed: "right",
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "timestamp" && sortedInfo?.value?.order
    },
    {
      key: "status",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "statusRenderer"
      },
      width: 160,
      fixed: "right"
    },
    {
      key: "actions",
      width: 150,
      fixed: "right",
      scopedSlots: { customRender: "actionRenderer" }
    }
  ])

  return { columns }
}
