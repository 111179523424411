<template>
  <a-list :header="label">
    <a-list-item
      v-for="item in items"
      :key="item.key"
    >
      {{ item.name }}

      <template #actions>
        <a-button
          type="danger"
          shape="circle"
          size="small"
          icon="delete"
          @click="removeItem(item)"
        />
      </template>
    </a-list-item>
  </a-list>
</template>

<script>
export default {
  name: "VariationsList",

  props: {
    data: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      items: []
    }
  },

  methods: {
    removeItem(item) {
      this.$emit("change", [...this.items.filter((val) => val !== item)])
    }
  },

  watch: {
    data(newData) {
      this.items = [...newData]
    }
  },

  mounted() {
    this.items = [...this.data]
  }
}
</script>
