import productPage from "./productPage/productPage"
import editProduct from "./editProduct/editProduct"
import productTypeAutocomplete from "./editProduct/productTypeAutocomplete"
import productBrandAutocomplete from "./editProduct/productBrandAutocomplete"
import submitEditProduct from "./editProduct/submitEditProduct"
import createProduct from "./createProduct/createProduct"
import addNewAttribute from "./editProduct/addNewAttribute"
import fetchAttributes from "./editProduct/fetchAttributes"
import copyAttributes from "./editProduct/copyAttributes"
import editProductTypeAndBrand from "./productPage/editProductTypeAndBrand"
import submitMsg from "./editProduct/submitMsg"
import checkUniqueBarcode from "../validations/checkUniqueBarcode"
import checkUniqueGtin from "../validations/checkUniqueGtin"
import confirmClose from "./editProduct/confirmClose"
import editLocalAttrModal from "./editProduct/editLocalAttrModal"
import editAttrModal from "./editProduct/editAttrModal"
import editGtin from "./editProduct/editGtin"
import checkRequiredAttributes from "./editProduct/checkRequiredAttributes"
import sortLikeInType from "./editProduct/sortLikeInType"
import editLocale from "./productPage/editLocale"
import connectorsModal from "./productPage/connectorsModal"
import connectionView from "./productPage/connectionView"
import connectorsSearchModal from "./productPage/connectorsSearchModal"
import imagesModal from "./productPage/imagesModal"
import verificationModal from "./productPage/verificationModal"

const modules = {
  editProduct,
  productPage,
  productTypeAutocomplete,
  productBrandAutocomplete,
  submitEditProduct,
  createProduct,
  addNewAttribute,
  fetchAttributes,
  copyAttributes,
  editProductTypeAndBrand,
  submitMsg,
  checkUniqueBarcode,
  checkUniqueGtin,
  confirmClose,
  editAttrModal,
  editLocalAttrModal,
  editGtin,
  checkRequiredAttributes,
  sortLikeInType,
  editLocale,
  connectorsModal,
  connectionView,
  connectorsSearchModal,
  imagesModal,
  verificationModal
}

export default {
  modules
}
