export default {
  applications: "Applications",
  components: "Components",
  views: "Views",
  fields: "Fields",
  modelPermissions: "Model permissions",
  createRole: "Create role",
  editRole: "Edit role",
  roles: "Roles",
  users: "Users",
  isSuper: "Is super",
  createUser: "Create user",
  userCreatedSuccessfully: "User created successfully",
  userUpdatedSuccessfully: "User updated successfully",
  roleCreatedSuccessfully: "Role created successfully",
  roleUpdatedSuccessfully: "Role updated successfully"
}
