var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"checkbox",class:_vm.className},[_c('span',{staticClass:"checkbox__wrapper",class:{ 'checkbox__wrapper--disabled': _vm.disabled }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedStatus),expression:"checkedStatus"}],attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.checkedStatus)?_vm._i(_vm.checkedStatus,null)>-1:(_vm.checkedStatus)},on:{"change":function($event){var $$a=_vm.checkedStatus,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedStatus=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedStatus=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedStatus=$$c}}}}),_c('div',{staticClass:"checkbox__icon",class:{
        'checkbox__icon--checked': _vm.checkedStatus,
        'checkbox__icon--disabled': _vm.disabled
      }}),_c('span',{staticClass:"checkbox__label",class:{
        'checkbox__label--checked': _vm.checkedStatus,
        'checkbox__label--disabled': _vm.disabled
      }},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }