<template>
  <a-form
    :form="subtractComissionForm"
    style="padding: 0 32px"
  >
    <a-form-item :label="$t('reason')">
      <a-input
        v-decorator="[
          'reason',
          { rules: [...getMaxLengthRule, { required: true, message: this.$t('emptyError') }] }
        ]"
      />
    </a-form-item>

    <a-form-item :label="$t('sum')">
      <a-input-number
        v-decorator="['amount', { rules: [{ required: true, message: this.$t('emptyError') }] }]"
        :min="1"
        :precision="0"
      />
    </a-form-item>

    <a-form-item :label="$t('applySum')">
      <a-input-number
        v-decorator="['amountConfirm', { rules: [{ required: true, message: $t('fillFields') }] }]"
        :min="0"
      />
    </a-form-item>

    <a-form-item :label="$t('comment')">
      <a-textarea
        v-decorator="['description', { rules: getMaxLengthRule }]"
        :autoSize="{ minRows: 4, maxRows: 10 }"
      />
    </a-form-item>

    <div style="display: flex; justify-content: flex-end; gap: 16px">
      <a-button @click="handleResetForm">{{ $t("cancel") }}</a-button>
      <a-button
        type="primary"
        :loading="isSubtracting"
        @click="handleSubtract"
      >
        {{ $t("writeOff") }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { sellerSubtract } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  props: {
    prototype: {
      type: Object,
      default: () => {}
    }
  },

  emits: ["handleUpdateBillingInfo"],

  data() {
    return {
      subtractComissionForm: this.$form.createForm(this, { name: "subtractComissionForm" }),
      isSubtracting: false
    }
  },

  methods: {
    async handleSubtract() {
      this.subtractComissionForm.validateFields(async (err, values) => {
        if (err) return

        const { description, amountConfirm, ...otherData } = values

        if (values.amount !== amountConfirm) {
          this.subtractComissionForm.setFields({
            amount: {
              value: values.amount,
              errors: [new Error(this.$t("mustBeSameError"))]
            },
            amountConfirm: {
              value: amountConfirm,
              errors: [new Error(this.$t("mustBeSameError"))]
            }
          })
          return
        }

        this.isSubtracting = true

        if (description) {
          otherData.description = description
        }
        try {
          await sellerSubtract(this.prototype.uuid, otherData)

          this.$notification.success({ message: this.$t("commissionDebited") })
          this.subtractComissionForm.resetFields()
          this.$emit("handleUpdateBillingInfo")
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.isSubtracting = false
        }
      })
    },

    handleResetForm() {
      this.subtractComissionForm.resetFields()
    }
  },

  computed: {
    getMaxLengthRule() {
      return [{ max: 1000, message: this.$t("maxLengthError", { length: 1000 }) }]
    }
  }
}
</script>
