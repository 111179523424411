import { transformQuery } from "./servicesUtils"
import { API } from "@/utils/HttpUtils"

export const fetchProductReviews = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/products-reviews${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchProductReviewsActionsData = () => {
  return API.get("/mp-admin/products-reviews/actions-data")
}

export const fetchProductReview = (id) => {
  return API.get(`/mp-admin/products-reviews/${id}`)
}

export const updateProductReview = (data, id) => {
  return API.put(`/mp-admin/products-reviews/${id}`, data)
}
