import UnitsTable from "./unitsTable/unitsTable"
import CreateUnit from "./createUnit/createUnit"
import CheckUniqueUnit from "../validations/checkUniqueUnit"
import EditUnitBase from "./editUnitBase/editUnitBase"
import AttributesTable from "./attributesTable/attributesTable"

const modules = {
  UnitsTable,
  CreateUnit,
  CheckUniqueUnit,
  EditUnitBase,
  AttributesTable
}

export default {
  modules
}
