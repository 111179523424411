import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "start_timestamp",
      dataIndex: "start_timestamp",
      title: i18n.t("creatingDate"),
      scopedSlots: { customRender: "dateRenderer" },
      fixed: "left",
      sorter: true,
      sortOrder: sortedInfo?.value.columnKey === "start_timestamp" && sortedInfo?.value.order
    },
    {
      key: "meta",
      dataIndex: "meta.xml_slug",
      title: i18n.t("exportSellerSlug"),
      fixed: "left"
    },
    {
      key: "executor",
      dataIndex: "executor",
      title: i18n.t("exportExecutor")
    },
    {
      key: "total_products",
      dataIndex: "total_products",
      title: i18n.t("exportTotalProducts")
    },
    {
      key: "success_products",
      dataIndex: "success_products",
      title: i18n.t("exportSuccessProducts")
    },
    {
      key: "errors_products",
      dataIndex: "errors_products",
      title: i18n.t("exportErrorProducts")
    },
    {
      key: "finish_timestamp",
      dataIndex: "finish_timestamp",
      title: i18n.t("exportDateEnd"),
      scopedSlots: { customRender: "dateRenderer" },
      sorter: true,
      sortOrder: sortedInfo?.value.columnKey === "finish_timestamp" && sortedInfo?.value.order
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "statusRenderer"
      }
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right"
    }
  ])

  return {
    columns
  }
}
