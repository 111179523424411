export default {
  directoryOfGoods: "Directory of goods",
  createProduct: "Create product",
  verification: "Verification",
  searchByTitleForPublication: "Search by title for publication",
  productType: "Product type",
  fullProductType: "Product type (full)",
  titleForPublication: "Title for public.",
  editingProductLocalization: "Editing product localization",
  editingProductLocalizationDescription:
    "Adding localization is possible only after the product passes verification (status 'VERIFIED' and 'TRANSFERRED')",
  makeDuplicate: "Make a duplicate",
  productEditing: "Product editing",
  productCreating: "New product creating",
  productCreatingWarning: "You want to cancel product creating?",
  productEditingWarning: "You want to cancel product editing?",
  exportSuccessWithErrors: "Export success but with errors",
  exportSuccess: "Export successful",
  exportFailed: "Export is not successful",
  exportError: "Export error",
  errorInTask: "Error in task",
  loadAttributesByTypeError: "Loading attributes error by product type",
  formFilledWithErrors: 'Form in "General" tab is filled with errors',
  createProductSuccess: "Product successfully created",
  creationProductFail: "Product not created",
  productAlreadyExistWithThisName: "Product with same name or model is already exist",
  productLoadError: "Product loading error",
  productsLoadError: "Products loading error",
  loadAttributesError: "Attributes loading error",
  loadMainAttributesError: "Main attributes loading error",
  mainAttributesIsEmpty: "Main attributes is empty in this type",
  typeAttributesIsEmpty: "Type attributes is empty in this type",
  duplicateAttributeError: "This attribute is already added",
  fullProductTitle: "Full product name",
  fullType: "Type (full)",
  shortType: "Type (short)",
  enterProductType: "Enter product type",
  seriesName: "Name (series)",
  enterProductBrand: "Ented product brand",
  publicationTitle: "Title for publication",
  currentCategory: "Current category",
  newCategory: "New category",
  categoryId: "Category ID",
  categoryCanNotContainProducts: "This category cannot contain products",
  categoryNotFound: "Category not found",
  shortProductDescription: "Short product description",
  productDescription: "Product description",
  fillTitleOrModel: "Fill in the field 'Name' or 'Model'",
  fillModelOrTitle: "Fill in the field 'Model' or 'Name'",
  gtinLengthError: "Must be 8, 12, 13 or 14 numerical values",
  saveOrdering: "Save ordering",
  orderingFull: "Ordering",
  imagesChangesSaved: "Image changes are saved",
  imagesChangesFailed: "Updating images error",
  imageEditingFailed: "Editing images error",
  imagesAdded: "Images are attached",
  imageEditing: "Image editing",
  preview: "Preview",
  imageUpdated: "Image is updated",
  addAttribute: "Add the attribute",
  errorInAttributes: "Errors in attributes",
  copyFromProduct: "Copy from product",
  orderSameAsType: "Sort as in type",
  addMains: "Add main ones",
  addAllAttributesByType: "Add all attributes by type",
  deleteBlank: "Delete blanks",
  unit: "Unit of measurement",
  units: "Units",
  characteristics: "Characteristics",
  listValues: "List values",
  valueProcessing: "Value processing",
  unitShorted: "Unit",
  nameForPublish: "Title for publication",
  deleteAttributeConfirm: "You want to remove an attribute",
  contextSearchByAttribute: "Contextual search by attribute name",
  attributeSearch: "Attribute search",
  attributeValue: "Attribute value",
  productLocalizationEditing: "Editing product localization",
  translationTitleForPublish: "Translation title for publication",
  nameTranslation: "Translation of the name",
  setValueInMainLocale: "Set a value for this field in the primary locale (ru)",
  descriptionTranslate: "Description translation",
  publicTitleUpdated: "Value public_title for {code} successfully updated",
  valueUpdated: "Value 'value' for {code} successfully updated",
  publicTitleNotUpdated: "Value public_title not updated",
  valueNotUpdated: "Value 'value' not updated",
  localizationEditingWarning: "You want to cancel editing the product localization?",
  shortDescriptionTranslation: "Translation of a brief description of the product",
  productDublicateError: "Product duplication error",
  naming: "Naming",
  fillAttributesValueError: "Please fill in all attribute values",
  closeAttributesValueError: "Please submit all finish editing attributes",
  public_title: {
    ru: "Name (RU)",
    uk: "Name (UA)"
  },
  moderationTrustStatus: {
    TRUSTED: "Trusted",
    UNTRUSTED: "Untrusted"
  },
  productQualityMark: {
    PERFECT: "Perfect quality",
    GOOD: "Good quality",
    AVERAGE: "Average quality",
    FAIR: "Fair quality",
    POOR: "Poor quality",
    VERY_POOR: "Very poor quality"
  },
  productModerationStatuses: {
    TEMP: "Temporary",
    NEW: "New",
    EDITED: "Edited",
    VERIFIED: "Verified",
    NOT_VERIFIED: "Not verified",
    TRANSFERRED: "Transferred",
    MODERATION_REQUIRED: "Moderation required"
  },
  productOriginOptions: {
    SELLER_MANUAL: "Manual creation",
    SYNTHETIC_DEFAULT: "Other"
  },
  unitIsEmptyPlaceholder: "Empty",

  // Export
  exportPageTitle: "Products export",
  exportHistoryTitle: "Export history",
  exportProductsToDBTitle: "Export products to central DB",
  exportSellerSlug: "Seller slug",
  exportExecutor: "User",
  exportTotalProducts: "Products count",
  exportSuccessProducts: "Exported successfully",
  exportErrorProducts: "An error occurred while exporting",
  exportDateEnd: "End date",
  exportStatusStarted: "In processing",
  exportStatusSuccess: "Finished",
  exportStatusRevorked: "Revoked",
  exportStatusFailute: "Error",
  exportStatusReceived: "In queue",
  exportStatusPending: "In queue (closer)",
  exportStatusFilter: "Export status",
  exportProductStatus: "Product status",
  exportStartConfirm: "Start export of chosen products?",
  exportStartSuccess: "Export started successfully",
  exportStartError: "Export start error",
  exportFailedStoped: "Export not stopped",
  exportSuccessStoped: "Export stopped",
  exportErrors: "Export errors"
}
