import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  // common
  const isRefFilterList = computed(() => [
    {
      label: "Ref",
      value: "true"
    },
    {
      label: `${i18n.t("without")} Ref`,
      value: "false"
    }
  ])

  const cpaKindFilterList = computed(() => [
    {
      label: "CPA",
      value: "REGULAR"
    },
    {
      label: "CPA2",
      value: "CATEGORICAL"
    },
    {
      label: `${i18n.t("without")} CPA`,
      value: "DISABLED"
    }
  ])

  const campaignStatuses = computed(() => ({
    ACTIVE: {
      title: i18n.t("active"),
      value: "ACTIVE",
      color: "#00C84A"
    },
    TURNED_OFF: {
      title: i18n.t("disabled"),
      value: "TURNED_OFF",
      color: "#000000"
    },
    PAUSED_NO_MONEY: {
      title: i18n.t("paused"),
      value: "PAUSED_NO_MONEY",
      color: "#FE5C59"
    }
  }))

  const healthStatuses = computed(() => [
    {
      title: i18n.t("critical"),
      value: 0,
      color: "#FF7A00"
    },
    {
      title: i18n.t("low"),
      value: 1,
      color: "#F5A80F"
    },
    {
      title: i18n.t("good"),
      value: 2,
      color: "#219653"
    }
  ])

  const booleanOptions = computed(() => [
    {
      label: i18n.t("yes"),
      value: "true"
    },
    {
      label: i18n.t("no"),
      value: "false"
    }
  ])

  // Sellers
  const isActiveOptions = computed(() => [
    {
      label: i18n.t("active"),
      value: "true"
    },
    {
      label: i18n.t("inactive"),
      value: "false"
    }
  ])

  const feedLogicOptionsList = computed(() => [
    {
      label: i18n.t("standartLogic"),
      value: "BASE"
    },
    {
      label: i18n.t("allOffers"),
      value: "FULL"
    }
  ])

  // Merchant
  const inStockOptions = computed(() => [
    {
      label: i18n.t("inStock"),
      value: "in_stock"
    },
    {
      label: i18n.t("outOfStock"),
      value: "out_of_stock"
    }
  ])

  const offerCommissionStatusOptions = computed(() => [
    {
      label: `CPA ${i18n.t("active")}`,
      value: "REGULAR_ACTIVE"
    },
    {
      label: `CPA ${i18n.t("inactive")}`,
      value: "REGULAR_NOT_ACTIVE"
    },
    {
      label: `CPA2 ${i18n.t("active")}`,
      value: "CATEGORICAL_ACTIVE"
    },
    {
      label: `CPA2 ${i18n.t("inactive")}`,
      value: "CATEGORICAL_NOT_ACTIVE"
    },
    {
      label: i18n.t("cpaStatus.nocpa"),
      value: "DISABLED"
    }
  ])

  // CPA
  const billingStatuses = computed(() => ({
    PENDING: {
      title: i18n.t("yes"),
      value: true
    },
    APPLIED: {
      title: i18n.t("no"),
      value: false
    }
  }))

  const comissionStatuses = computed(() => ({
    PENDING: {
      title: i18n.t("onAudit"),
      value: "PENDING",
      color: "#000"
    },
    APPLIED: {
      title: i18n.t("success"),
      value: "APPLIED",
      color: "#00C84A"
    },
    CANCELED: {
      title: i18n.t("declined"),
      value: "CANCELED",
      color: "#FE5C59"
    }
  }))

  // CPC
  const campaignAlertStatuses = computed(() => ({
    INC_25: i18n.t("inc25"),
    INC_50: i18n.t("inc50"),
    INC_100: i18n.t("inc100"),
    DEC_25: i18n.t("dec25"),
    DEC_50: i18n.t("dec50"),
    DEC_100: i18n.t("dec100"),
    NEGATIVE_BALANCE: i18n.t("negativeBalance"),
    LOW_BALANCE: i18n.t("lowBalance")
  }))

  return {
    isRefFilterList,
    cpaKindFilterList,
    isActiveOptions,
    feedLogicOptionsList,
    campaignStatuses,
    campaignAlertStatuses,
    booleanOptions,
    inStockOptions,
    offerCommissionStatusOptions,
    billingStatuses,
    comissionStatuses,
    healthStatuses
  }
}
