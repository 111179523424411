import { computed } from "vue"
import i18n from "@/i18n"

export default function useTagConstants() {
  const sequenceNumberOrderOptions = computed(() => [
    {
      label: i18n.t("inAscendingOrder"),
      value: "ascending"
    },
    {
      label: i18n.t("sameForAll"),
      value: "same"
    }
  ])

  return {
    sequenceNumberOrderOptions
  }
}
