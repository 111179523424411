<template>
  <span ref="mainInput">
    <input
      ref="editableCell"
      type="text"
      v-model="myVal"
      @keyup.enter="(e) => submitEditCell(field, e.target.value, rowIndex)"
      @keyup.esc="cancelEditCell"
      @input="onInput"
    />
    <ValidationErrors
      :lengthError="showError"
      :floatError="showNumericError"
    />
  </span>
</template>

<script>
import validFloat from "utils/validations/validFloat"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validMaxLength from "utils/validations/validMaxLength"

export default {
  name: "InputEditName",
  components: {
    ValidationErrors
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onUpdate: Function
  },
  data() {
    return {
      showError: false,
      showNumericError: false,
      sendObject: {},
      myVal: this.row[this.field],
      timeout: null
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
    this.closeOnBlurEvent()
  },
  methods: {
    onInput(event) {
      let value = event.target.value

      this.showNumericError = !validFloat(value)
      this.showError = !validMaxLength(value, 50)
    },
    submitEditCell(field, value, rowIndex) {
      if (this.showError || this.showNumericError) return

      this.submitName(field, value, rowIndex)

      this.onUpdate(this.sendObject)
      this.close()
    },
    submitName(field, value, rowIndex) {
      this.sendObject = {
        columnKey: field,
        newValue: value,
        rowIndex
      }
    },
    cancelEditCell() {
      this.close()
      // this.resetValues();
    },
    resetValues() {
      this.showError = false
      this.showNumericError = false
      this.sendObject = {}
    },
    closeOnBlurEvent() {
      let context = this
      this.$refs.editableCell.addEventListener("blur", context.close, false)
    },
    unsubscribeOnBlur() {
      let context = this
      this.$refs.editableCell.removeEventListener("blur", context.close, false)
    }
  },
  beforeDestroy() {
    this.unsubscribeOnBlur()
  }
}
</script>
