const Messenger = {
  namespaced: true,
  state: {
    getToken: () => "",
    user: {}
  },
  mutations: {
    setGetToken(state, value) {
      state.getToken = value
    },
    setUser(state, value) {
      state.user = value
    },
    resetMessengerAccess(state, value) {
      state.getToken = () => ""
      state.user = {}
    }
  }
}

export default Messenger
