import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PA_showProductsModal: false,
  PA_productsTable: { count: 0, items: [] },
  PA_productsTableQueryParams: { offset: 0 }
})

const getters = {
  PA_showProductsModal: (state) => state.PA_showProductsModal,
  PA_productsTable: (state) => state.PA_productsTable,
  PA_productsTableQueryParams: (state) => state.PA_productsTableQueryParams
}

const mutations = {
  PA_showProductsModal(state, value) {
    state.PA_showProductsModal = value
  },
  PA_productsTable(state, value) {
    state.PA_productsTable = value
  },
  PA_productsTableQueryParams(state, value) {
    state.PA_productsTableQueryParams = value
  }
}

const actions = {
  async PA_buildProductsTableData({ commit, getters }, products) {
    if (!products.length) return
    let newProducts = []
    for (let i = 0; i < products.length; i++) {
      let newProduct = products[i]
      newProduct.full_name = `${(products[i].type && products[i].type.full_name) || ""} ${
        (products[i].brand && products[i].brand.name) || ""
      } ${products[i].name || ""}`
      if (products[i].model) newProduct.full_name += ` (${products[i].model || ""})`

      let attributeCode = getters.PA_selectedAttribute.code

      let attributeObject = products[i].attributes.find((item) => {
        return item.code === attributeCode
      })

      newProduct.attribute_value = attributeObject.value

      newProducts.push(newProduct)
    }
    if (newProducts.length) {
      commit("PA_productsTable", { count: newProducts.length, items: newProducts })
    }

    return Promise.resolve
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
