import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PA_showAddTypeModal: false,
  PA_typesForNewAtl: [],
  PA_selectedProductTypeForNewAtl: false,
  PA_typesForNewAtlAutocomplete: [],
  PA_isMainForNewAtl: false,
  PA_isRequiredForNewAtl: false
})

const getters = {
  PA_showAddTypeModal: (state) => state.PA_showAddTypeModal,
  PA_typesForNewAtl: (state) => state.PA_typesForNewAtl,
  PA_selectedProductTypeForNewAtl: (state) => state.PA_selectedProductTypeForNewAtl,
  PA_typesForNewAtlAutocomplete: (state) => state.PA_typesForNewAtlAutocomplete,
  PA_isMainForNewAtl: (state) => state.PA_isMainForNewAtl,
  PA_isRequiredForNewAtl: (state) => state.PA_isRequiredForNewAtl
}

const mutations = {
  PA_showAddTypeModal(state, value) {
    state.PA_showAddTypeModal = value
  },
  PA_typesForNewAtl(state, value) {
    state.PA_typesForNewAtl = value
  },
  PA_selectedProductTypeForNewAtl(state, value) {
    state.PA_selectedProductTypeForNewAtl = value
  },
  PA_typesForNewAtlAutocomplete(state, value) {
    state.PA_typesForNewAtlAutocomplete = value
  },
  PA_isMainForNewAtl(state, value) {
    state.PA_isMainForNewAtl = value
  },
  PA_isRequiredForNewAtl(state, value) {
    state.PA_isRequiredForNewAtl = value
  }
}

const actions = {
  async PA_createNewAtl({ commit, getters, dispatch }) {
    // create atl
    let response = await dispatch("PA_createNewAtlInDatabase")
    if (!response) return
    // reset values
    dispatch("PA_resetCreateAtlValues")
    dispatch("PA_searchAtls", getters.PA_selectedAttribute)
  },
  async PA_createNewAtlInDatabase({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)
    let atl = {
      product_type: getters.PA_selectedProductTypeForNewAtl.code,
      attribute: getters.PA_selectedAttribute.code,
      is_main: getters.PA_isMainForNewAtl,
      is_required: getters.PA_isRequiredForNewAtl,
      order: 0
    }
    // start request
    return API_MODERATION.post(`${apiUrls.link}`, atl)
      .then((r) => r.data.message)
      .then((msg) => {
        // hide loader
        commit("PC_showLoader", false)
        return msg
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PA_resetCreateAtlValues")
        commit("handleError", e)
        return null
      })
  },
  async PA_resetCreateAtlValues({ commit }) {
    commit("PA_typesForNewAtl", [])
    commit("PA_selectedProductTypeForNewAtl", false)
    commit("PA_typesForNewAtlAutocomplete", [])
    commit("PA_isMainForNewAtl", false)
    commit("PA_isRequiredForNewAtl", false)
  },
  PA_fetchTypesForNewAtl({ commit }, value) {
    let searchData = buildMagicSearch({
      resource: "PTD",
      field: "full_name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((types) => {
        commit("PA_typesForNewAtl", types)
        let typesArray = types.map((item) => {
          return item.full_name
        })
        commit("PA_typesForNewAtlAutocomplete", typesArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PA_processSelectedTypeForNewAtl({ commit, getters }, value) {
    let selectedType = getters.PA_typesForNewAtl.find((item) => {
      return item.full_name === value
    })
    commit("PA_selectedProductTypeForNewAtl", selectedType)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
