<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_showAddAttributeModalGet"
      :title="'Добавить атрибут к списку'"
    >
      <div>
        <div>
          <label>
            <span
              >Выбранный список: <strong>{{ PV_selectedList.description }}</strong></span
            >
            <span
              >Начните ввод в поле для поиска атрибута, что будет использовать даный список:</span
            >
          </label>
          <Autocomplete
            :data="PV_attributesForNewAttributeAutocomplete"
            event="input"
            @event="fetchAttributes"
            @select="PV_addSelectedAttributeValue"
            :size="'small'"
            :defaultValue="defaultAttr"
          />
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="addNew"
          >Создать</Button
        >
      </span>
    </Modal>
    <ProductsModal />
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Autocomplete from "components/Autocomplete"

import { mapActions, mapMutations, mapGetters } from "vuex"
import ProductsModal from "../ProductsModal/ProductsModal"

export default {
  name: "AddAttributeModal",
  components: {
    ProductsModal,
    Button,
    Modal,
    Autocomplete
  },
  data() {
    return {
      timeout: null
    }
  },
  methods: {
    fetchAttributes(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PV_fetchAttributesToAddNewAttribute(value)
      }, 500)
    },
    addNew() {
      this.PV_addNewAttribute()
      this.closeModal()
    },
    closeModal() {
      this.PV_showAddAttributeModal(false)
      this.resetValues()
    },
    resetValues() {
      this.timeout = null
    },
    ...mapMutations("ProductCatalog", {
      PV_showAddAttributeModal: "PV_showAddAttributeModal"
    }),
    ...mapActions("ProductCatalog", {
      PV_addNewAttribute: "PV_addNewAttribute",
      PV_addSelectedAttributeValue: "PV_addSelectedAttributeValue",
      PV_fetchAttributesToAddNewAttribute: "PV_fetchAttributesToAddNewAttribute"
    })
  },
  computed: {
    enableCreateButton() {
      let show = false

      if (this.PV_selectedAttributeForNewAttribute) {
        show = true
      }

      return show
    },
    defaultAttr() {
      return this.PV_selectedAttributeForNewAttribute === false
        ? ""
        : this.PV_selectedAttributeForNewAttribute.name
    },
    ...mapGetters("ProductCatalog", {
      PV_showAddAttributeModalGet: "PV_showAddAttributeModal",
      PV_selectedList: "PV_selectedList",
      PV_attributesForNewAttributeAutocomplete: "PV_attributesForNewAttributeAutocomplete",
      PV_selectedAttributeForNewAttribute: "PV_selectedAttributeForNewAttribute"
    })
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 5px 10px;
}
</style>
