export default (array, max) => {
  let valid = true
  for (let i = 0; i < array.length; i++) {
    if (array[i].length > max) {
      valid = false
      break
    }
  }
  return valid
}
