<template>
  <div>
    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      :pagination="paginationInfo"
      rowKey="id"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #title>
        <div style="display: flex; align-items: center; gap: 8px">
          <div class="table-title">
            {{ $t("searchSynonyms") }}
          </div>

          <a-input-search
            allowClear
            style="width: 300px; margin-left: auto"
            :placeholder="$t('enterSynonym')"
            v-model="filteredInfo.synonyms"
            @search="handleTableFilterChange"
          >
            <template #enterButton>
              <a-button>
                <a-icon type="search" />
              </a-button>
            </template>
          </a-input-search>

          <a-button
            type="primary"
            icon="plus"
            @click="isModalOpen = true"
          >
            {{ $t("addSynonyms") }}
          </a-button>
        </div>
      </template>

      <template #synonyms="text, record">
        <TableEditableCellInput
          :text="text.join(', ')"
          key="synonyms"
          @change="(value, closeEdit) => editCell(value, record, closeEdit)"
        />
      </template>
    </a-table>

    <MakeSynonyms
      :isModalOpen="isModalOpen"
      @close="handleCloseModal"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import MakeSynonyms from "./MakeSynonyms.vue"
import TableEditableCellInput from "@/ant-components/TableEditableCellInput/index.js"

import { fetchSynonyms, updateSynonyms } from "@/modules/MPAdmin/services/searchSettingsService.js"

import useSynonymsColumns from "../useSynonymsColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  fetchTableInfo,
  handleTableFilterChange
} = useAntTableQuery({
  queryFunction: fetchSynonyms
})

const { synonymsListColumns: columns } = useSynonymsColumns()

const isModalOpen = ref(false)

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const editCell = async (value, record, closeEdit) => {
  if (!value) {
    notification.error({ message: i18n.t("fillFields") })
    return
  }

  try {
    await updateSynonyms({ ...record, synonyms: value.split(/,|, |\n/) }, record.id)

    notification.success({ message: i18n.t("updated") })
    onTableChange()
    closeEdit()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const handleCloseModal = () => {
  isModalOpen.value = false

  onTableChange()
}

onMounted(onTableChange)
</script>

<style scoped lang="scss">
.table-title {
  font-weight: 500;
  font-size: 16px;
  color: $font-default;
}
</style>
