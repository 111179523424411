<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <a-form-model-item prop="title">
      <a-input
        v-model="form.title"
        size="large"
        placeholder=""
      />
      <div
        :class="`interactiveLabel ${validateStatus.tagName === 'error' ? 'redTextColor' : ''}`"
        :style="`${validateStatus.tagName === 'error' ? 'color: #FF4D4F;' : ''}`"
      >
        {{ $t("tagName") }}
      </div>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button @click="handleClose">{{ $t("cancel") }}</a-button>

      <a-button
        type="primary"
        :disabled="validateStatus.tagName !== 'success'"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { ref, reactive, computed } from "vue"
import slugify from "slugify"
import i18n from "@/i18n"
import router from "@/router"

import notifyResponseError from "@/utils/notifyResponseError.js"
import { createTag } from "@/modules/MPAdmin/services/tagsService.js"

const emit = defineEmits(["close"])

const formRef = ref()
const form = reactive({ title: "" })
const formRules = computed(() => ({
  title: [{ validator: validateName, trigger: "change" }]
}))

const validateStatus = reactive({
  tagName: "validating"
})

const isFetching = ref(false)

const handleClose = () => {
  emit("close")
}

const validateName = (_, value, callback) => {
  if (value.trim() === "") {
    validateStatus.tagName = "error"
    callback(new Error("\u00A0\u00A0\u00A0" + i18n.t("thisFieldMustBeFilled")))
  } else if (slugify(value) === "") {
    validateStatus.tagName = "error"
    callback(new Error("\u00A0\u00A0\u00A0" + i18n.t("titleIsIncorrect")))
  } else if (value.length > 200) {
    validateStatus.tagName = "error"
    callback(new Error("\u00A0\u00A0\u00A0" + i18n.t("maxLengthError", { length: 200 })))
  } else {
    validateStatus.tagName = "success"

    callback()
  }
}

const onSubmit = async () => {
  try {
    formRef.value.validate()
  } catch {
    return
  }

  isFetching.value = true

  const newTag = {
    title: form.title.trim(),
    slug: slugify(form.title.trim(), { lower: true })
  }

  try {
    const { data } = await createTag(newTag)

    router.push({
      path: `/mp-admin/offer-tags/edit/${data.uuid}`
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.interactiveLabel {
  position: absolute;
  left: 2.5%;
  top: -100%;
  z-index: 3;

  height: 100%;

  padding: 0;
  line-height: 16px;

  background: #fff;

  transition: 0.1s;
  pointer-events: none;
}

input:placeholder-shown + .interactiveLabel {
  top: 0%;
  color: rgba(0, 0, 0, 0.65);
}

input:focus + .interactiveLabel {
  top: -100%;
  color: #1890ff;
}
</style>
