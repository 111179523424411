import { API_MODERATION, buildDataTableGetUrl } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const state = () => ({
  AE_showTable: false,
  AE_tableData: { count: 0, items: [] },
  AE_queryParams: {
    limit: 10,
    ordering: ["-timestamp"],
    offset: 0
  },
  AE_fetchingStatus: false
})

const mutations = {
  AE_showTable(state, value) {
    state.AE_showTable = value
  },
  AE_tableData(state, value) {
    state.AE_tableData = value
  },
  AE_tableDataCell(state, { newValue, rowIndex, field }) {
    state.AE_tableData.items[rowIndex][field] = newValue
  },
  AE_tableDataRow(state, { newValue, rowIndex }) {
    state.AE_tableData.items[rowIndex] = newValue
    let clone = JSON.parse(JSON.stringify(state.AE_tableData))
    state.AE_tableData = clone
  },
  AE_queryParams(state, value) {
    state.AE_queryParams = value
  },
  AE_fetchingStatus(state, value) {
    state.AE_fetchingStatus = value
  },
  AE_resetQueryParams(state) {
    state.AE_queryParams = {
      limit: 10,
      ordering: ["-timestamp"],
      offset: 0
    }
  },
  resetTableValues(state) {
    state.AE_showTable = false
    state.AE_tableData = { count: 0, items: [] }
    state.AE_queryParams = {
      limit: 10,
      ordering: ["-timestamp"],
      offset: 0
    }
  }
}

const actions = {
  async AE_tableSearch({ commit, dispatch, state }, { selectedAttrCode }) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      await dispatch("tableSearchRequest", { selectedAttrCode })
      commit("AE_showTable", true)
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    }
    // finally{
    //   commit('ProductCatalog/PC_showLoader', false, {root: true});
    // }
  },
  async tableSearchRequest({ commit, dispatch, state }, { selectedAttrCode }) {
    let queryParams = state.AE_queryParams
    //fine-tune request for search by errors (all/no errors/has errors)
    if (
      queryParams.filters &&
      queryParams.filters[10] &&
      queryParams.filters[10].name == "invalid"
    ) {
      if (queryParams.filters[10].value == "All") {
        queryParams.filters[10].value = null
      } else if (queryParams.filters[10].value == "Valid") {
        queryParams.filters[10].value = "false"
      } else if (queryParams.filters[10].value == "Error") {
        queryParams.filters[10].value = "true"
      }
    }
    let queryString = `${buildDataTableGetUrl(
      apiUrls.attributeEdit,
      queryParams
    )}&attribute=${selectedAttrCode}`

    return await dispatch("tableSearchApi", queryString)
  },
  async tableSearchApi({ commit, dispatch, state }, queryString) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      return API_MODERATION.get(queryString)
        .then((r) => r.data.message)
        .then((r) => {
          commit("AE_tableData", {
            count: r.count,
            items: r.data
          })
          commit("AE_CA_uniqueTypes", r.unique_types)
          //set current new_value_type
          if (r.data.length) {
            commit("AE_selectedValueType", r.data[0].new_value_type)
          }
          return r
        })
        .catch((e) => {
          e.$_actionName = "tableSearchApi"
          return Promise.reject(e)
        })
    } finally {
      commit("ProductCatalog/PC_showLoader", false, { root: true })
    }
  },
  async EA_editTable(
    { commit, state, dispatch },
    { newValue, row, rowIndex, selectedAttrCode, field, oldValue }
  ) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      await dispatch("EA_editTableRequest", {
        newValue,
        row,
        rowIndex,
        selectedAttrCode,
        field,
        oldValue
      })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    } finally {
      commit("ProductCatalog/PC_showLoader", false, { root: true })
    }
  },
  EA_editTableRequest(
    { commit, state },
    { newValue, row, rowIndex, selectedAttrCode, field, oldValue }
  ) {
    //set the new value in the table
    commit("AE_tableDataCell", { newValue, rowIndex, field })

    let sendData = {
      attribute: selectedAttrCode,
      product: row.product,
      [field]: newValue
    }
    return API_MODERATION.put(`${apiUrls.attributeEdit}`, sendData)
      .then((r) => r.data.message)
      .then((r) => {
        //update table row
        commit("AE_tableDataRow", { newValue: r, rowIndex })
        notification.info({ message: "Изменения добавлены" })
        return r
      })
      .catch((e) => {
        //bring the old value back
        commit("AE_tableDataCell", { newValue: oldValue, rowIndex, field })

        e.$_actionName = "EA_editTableRequest"
        return Promise.reject(e)
      })
  },
  async EA_submitChanges({ commit, state, dispatch }, selectedAttrCode) {
    let sendData = {
      attribute: selectedAttrCode
    }
    return API_MODERATION.patch(`${apiUrls.attributeEdit}`, sendData)
      .then((r) => r.data.message.task_id)
      .then((r) => {
        return r
      })
      .catch((e) => {
        e.$_actionName = "EA_submitChanges"
        return Promise.reject(e)
      })
  },
  async EA_filterErrors({ commit, state, dispatch }, selectedAttrCode) {
    let queryParams = state.AE_queryParams
    let queryString = `${apiUrls.attributeEdit}?limit=${queryParams.limit}&offset=${queryParams.offset}&ordering=-timestamp&invalid=true&attribute=${selectedAttrCode}`
    await dispatch("tableSearchApi", queryString)
    commit("AE_resetQueryParams")
  },
  async EA_cancel({ commit, state, dispatch }, selectedAttrCode) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      await dispatch("EA_removeTempTable", selectedAttrCode)
      commit("AE_resetSearchValues")
      commit("resetConversionValues")
      commit("resetTableValues")
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    } finally {
      commit("ProductCatalog/PC_showLoader", false, { root: true })
    }
  }
}

export default {
  state,
  mutations,
  actions
}
