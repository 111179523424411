<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PT_showCreateAttributeModalGet"
      :title="'Добавление атрибута к типу'"
    >
      <div v-if="PT_showCreateAttributeModalGet">
        <div>
          <label
            ><span>Выберите новый атрибут<span class="required-field">*</span></span></label
          >
          <Autocomplete
            :data="PT_attributesForCreateNewAtlAutocomplete"
            event="input"
            @event="fetchAttributes"
            @select="PT_processSelectedAttributeValue"
            :size="'small'"
            :defaultValue="defaultAttr"
          />
        </div>
        <div class="margin-top-10">
          <Checkbox
            @change="checkboxMain"
            :checked="PT_atlIsMainGet"
          >
            Основной
          </Checkbox>
          <Checkbox
            @change="checkboxRequired"
            :checked="PT_atlIsRequiredGet"
            class="checkbox-required"
          >
            Обязательный
          </Checkbox>
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNew"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Autocomplete from "components/Autocomplete"
import Checkbox from "components/Checkbox"

import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "CreateAttributeModal",
  components: {
    Button,
    Modal,
    Autocomplete,
    Checkbox
  },
  created() {},
  data() {
    return {
      timeout: null
    }
  },
  methods: {
    fetchAttributes(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PT_fetchAttributesForNewValueAutocomplete(value)
      }, 500)
    },
    checkboxMain(val) {
      this.PT_atlIsMain(val)
    },
    checkboxRequired(val) {
      this.PT_atlIsRequired(val)
    },
    createNew() {
      this.PT_createNewAttribute()
      this.closeModal()
    },
    closeModal() {
      this.PT_showCreateAttributeModal(false)
      this.resetValues()
    },
    resetValues() {
      this.timeout = null
    },
    ...mapMutations("ProductCatalog", {
      PT_showCreateAttributeModal: "PT_showCreateAttributeModal",
      PT_atlIsMain: "PT_atlIsMain",
      PT_atlIsRequired: "PT_atlIsRequired"
    }),
    ...mapActions("ProductCatalog", {
      PT_processSelectedAttributeValue: "PT_processSelectedAttributeValue",
      PT_createNewAttribute: "PT_createNewAttribute",
      PT_fetchAttributesForNewValueAutocomplete: "PT_fetchAttributesForNewValueAutocomplete"
    })
  },
  computed: {
    defaultAttr() {
      return this.PT_selectedAttribute === false ? "" : this.PT_selectedAttribute.name
    },
    enableCreateButton() {
      let show = false

      if (this.PT_selectedAttribute) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PT_showCreateAttributeModalGet: "PT_showCreateAttributeModal",
      PT_attributesForCreateNewAtlAutocomplete: "PT_attributesForCreateNewAtlAutocomplete",
      PT_selectedAttribute: "PT_selectedAttribute",
      PT_atlIsMainGet: "PT_atlIsMain",
      PT_atlIsRequiredGet: "PT_atlIsRequired"
    })
  }
}
</script>
<style lang="scss" scoped>
.checkbox-required {
  margin-left: 10px;
}
</style>
