import { computed } from "vue"
import i18n from "@/i18n"

export default function useProductsColumns() {
  const productListColumns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "photoRenderer"
      },
      fixed: "left",
      width: 80
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      fixed: "left",
      width: 110
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      width: 350
    },
    {
      key: "category",
      dataIndex: "category",
      title: i18n.t("category"),
      scopedSlots: {
        customRender: "categoryRenderer"
      },
      width: 150
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model"),
      width: 160
    },
    {
      key: "rating",
      dataIndex: "rating",
      title: i18n.t("ratingShorted"),
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      align: "center",
      width: 75
    },
    {
      key: "label",
      dataIndex: "label",
      title: i18n.t("label"),
      scopedSlots: {
        customRender: "labelRender"
      },
      width: 140
    },
    {
      key: "listing_id",
      dataIndex: "listing",
      title: i18n.t("listing"),
      scopedSlots: {
        customRender: "listing"
      },
      width: 120,
      sorter: true
    },
    {
      key: "rf_s",
      dataIndex: "rf_s",
      title: `${i18n.t("ratingShorted")} S`,
      sorter: true,
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      width: 100
    },
    {
      key: "rf_m",
      dataIndex: "rf_m",
      title: `${i18n.t("ratingShorted")} M`,
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      width: 100
    },
    {
      key: "rf_l",
      dataIndex: "rf_l",
      title: `${i18n.t("ratingShorted")} L`,
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      width: 100
    },
    {
      key: "rf",
      dataIndex: "rf",
      title: i18n.t("finalRating"),
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      width: 100
    },
    {
      key: "moderation_status",
      dataIndex: "moderation_status",
      title: i18n.t("moderationStatus"),
      fixed: "right",
      scopedSlots: {
        customRender: "statusRender"
      },
      sorter: true,
      width: 180
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("created"),
      width: 110,
      scopedSlots: {
        customRender: "date"
      },
      fixed: "right"
    },
    {
      key: "actions",
      width: 110,
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right"
    }
  ])

  const addProductTableColumns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      width: 80,
      scopedSlots: {
        customRender: "photo"
      }
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      width: 120
    },
    {
      key: "title",
      dataIndex: "title",
      title: i18n.t("firstName")
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model"),
      width: 200
    }
  ])

  const editProductsColumns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      width: 80,
      scopedSlots: {
        customRender: "photoRenderer"
      }
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      width: 100
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("firstName"),
      width: 800
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model"),
      width: 170
    }
  ])

  const productEditListingColumns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "photoRenderer"
      },
      width: 65
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      width: 100
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name")
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model")
    },
    {
      key: "cover",
      title: i18n.t("cover"),
      width: 60,
      scopedSlots: {
        customRender: "cover"
      }
    },
    {
      key: "listing",
      dataIndex: "listing",
      title: i18n.t("listing"),
      scopedSlots: {
        customRender: "listing"
      },
      width: 100
    }
  ])

  return {
    addProductTableColumns,
    editProductsColumns,
    productEditListingColumns,
    productListColumns
  }
}
