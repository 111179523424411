import { transformQuery } from "./servicesUtils"
import { API } from "@/utils/HttpUtils"

export const fetchPromotionsActionsData = () => {
  return API.get("/mp-admin/main-promotions/actions-data")
}

export const fetchPromotions = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/main-promotions${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchSinglePromotion = (id) => {
  return API.get(`/mp-admin/main-promotions/${id}`)
}

export const createPromotion = (data) => {
  return API.post("/mp-admin/main-promotions", data)
}

export const updatePromotion = (data, id) => {
  return API.patch(`/mp-admin/main-promotions/${id}`, data)
}

export const updatePromotionImage = (data, id) => {
  return API.put(`/mp-admin/main-promotions/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

export const removeItem = (payload) => {}
