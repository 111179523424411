import { computed } from "vue"
import i18n from "@/i18n"

export default function useSynonymsColumns() {
  const brandsColumns = computed(() => [
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("brand"),
      fixed: "left",
      width: 300
    },
    {
      key: "synonyms",
      dataIndex: "synonyms",
      title: i18n.t("synonyms"),
      scopedSlots: {
        customRender: "synonyms"
      },
      width: 300
    }
  ])

  const synonymsListColumns = computed(() => [
    {
      key: "synonyms",
      dataIndex: "synonyms",
      title: i18n.t("synonyms"),
      scopedSlots: {
        customRender: "synonyms"
      },
      width: 300
    }
  ])

  return {
    brandsColumns,
    synonymsListColumns
  }
}
