import { render, staticRenderFns } from "./EditProductAttributesTab.vue?vue&type=template&id=71983793&scoped=true"
import script from "./EditProductAttributesTab.vue?vue&type=script&setup=true&lang=js"
export * from "./EditProductAttributesTab.vue?vue&type=script&setup=true&lang=js"
import style0 from "./EditProductAttributesTab.vue?vue&type=style&index=0&id=71983793&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71983793",
  null
  
)

export default component.exports