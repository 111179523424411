const state = () => ({
  PC_editProductData: false
})

const getters = {
  PC_editProductData: (state) => state.PC_editProductData
}

const mutations = {
  PC_editProductData(state, value) {
    state.PC_editProductData = value
  }
}
const actions = {
  async PC_updateValueChanges({ commit, getters }, product) {
    // loop1
    // go through all edited attributes in change log
    for (let i = 0; i < getters.PC_editedAttributeValues.length; i++) {
      let code = getters.PC_editedAttributeValues[i].code
      let value = getters.PC_editedAttributeValues[i].value
      // go through all existing product attributes
      loop2: for (let k = 0; k < product.attributes.length; k++) {
        // change corresponding attributes
        if (product.attributes[k].code === code) {
          commit("PC_tweakEditableProductInnerAttribute", { value, index: k, field: "value" })
          break loop2
        }
      }
    }
    return Promise.resolve()
  },
  async PC_updateUnitChanges({ commit, getters }, product) {
    // loop1
    // go through all edited attributes in change log
    for (let i = 0; i < getters.PC_editedAttributeDefaultUnits.length; i++) {
      let code = getters.PC_editedAttributeDefaultUnits[i].code
      let value = getters.PC_editedAttributeDefaultUnits[i].value
      // go through all existing product attributes
      loop2: for (let k = 0; k < product.attributes.length; k++) {
        // change corresponding attributes
        if (product.attributes[k].code === code) {
          commit("PC_tweakEditableProductInnerAttribute", { value, index: k, field: "unit" })
          break loop2
        }
      }
    }
    return Promise.resolve()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
