import { API } from "@/utils/HttpUtils"
import { transformQuery } from "./servicesUtils"

export const fetchProducts = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/products${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchProductsMeta = () => {
  return API.get("/mp-admin/products/meta")
}

export const fetchLabelsChoises = () => {
  return API.get("/mp-admin/product-labels")
}

export const updateProduct = (id, data) => {
  return API.put(`/mp-admin/products/${id}`, data)
}

export const updateProductsBulk = (payload) => {
  return API.put("/mp-admin/products/bulk", payload)
}

export const searchProducts = async (value, category) => {
  return API.get(`/mp-admin/products?name=${value}&category=${category}`)
}

export const syncProducts = async (selectedRows) => {
  const codes = selectedRows.map((item) => item.p_code)

  return API.post("/mp-admin/products/synchronize", { codes })
}

export const searchListings = (id) => {
  return API.get(`/mp-admin/listings?id=${id}`)
}

export const getLastListing = () => {
  return API.get("/mp-admin/listings?limit=0")
}
export const createListing = (payload) => {
  return API.post("/mp-admin/listings", payload)
}

export const updateListing = (data, listingId) => {
  return API.put(`/mp-admin/listings/${listingId}`, data)
}

export const getProductsByListing = (id) => {
  return API.get(`/mp-admin/products?listing=${id}`)
}

export const checkExistence = (items) => {
  const params = {
    p_codes: items.map((item) => item.p_code).join()
  }

  return API.get("/mp-admin/products/exists", { params })
}

export const createItems = (payload) => {
  return API.post("/mp-admin/products/bulk", payload)
}

export const changeRatingWithFile = (formData) => {
  return API.post("/mp-admin/products/imports/rf", formData)
}
