<template>
  <div class="search-modal-list">
    <div
      v-for="(keywordList, keyword) in keywords"
      :key="keyword"
      class="search-modal-list__item"
    >
      <div class="search-modal-list__item__title">
        <a-divider class="search-modal-list__item__title__prefix" />

        <div class="search-modal-list__item__title__text">
          {{ $t(`searchWeights.${keyword}`) }}
        </div>

        <a-button
          icon="plus"
          type="primary"
          @click="onShowAddModal({ keyword })"
        />
      </div>

      <div class="search-modal-list__item__tags">
        <a-tag
          v-for="(word, index) in keywordList"
          :color="word.color || 'white'"
          :key="`${word.key} ${index}`"
        >
          {{ word.key }}

          <a-popconfirm
            :title="$t('deletionWarningTitle')"
            @confirm="onRemoveSearchWord({ word, keyword })"
          >
            <a-icon type="close" />
          </a-popconfirm>
        </a-tag>

        <div
          class="search-modal-list__item__tags--empty"
          v-if="!keywordList.length"
        >
          {{ $t("keywordsIsEmpty") }}
        </div>
      </div>
    </div>

    <a-form-model
      class="calculate-query"
      layout="inline"
    >
      <a-form-model-item
        :label="$t('enterSearchQuery')"
        :colon="false"
        :labelCol="{ span: 24, offset: 0 }"
        :wrapperCol="{ span: 24, offset: 0 }"
      >
        <div class="calculate-query__input">
          <a-input
            v-model="calculatedQuery"
            allowClear
            @pressEnter="handleCalculateSearchQuery()"
            @change="productSearchResults = undefined"
          />

          <a-button
            type="dashed"
            :disabled="!calculatedQuery"
            :loading="isCalculating"
            @click="handleCalculateSearchQuery()"
          >
            {{ $t("calculate") }}
          </a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('result')"
        style="margin: 0"
      >
        <div
          v-if="productSearchResults"
          class="calculate-query__result"
        >
          <a-tag
            :color="productSearchResults.score < productSearchResults.min_score ? 'orange' : ''"
          >
            {{ getFormatedSum(productSearchResults.score, true) }}
          </a-tag>

          <a-popover>
            <template #content>
              {{ $t("minKeywordsScore") }}: {{ productSearchResults.min_score }}
            </template>
            <a-icon
              type="info-circle"
              theme="twoTone"
              style="font-size: 16px"
            />
          </a-popover>
        </div>
      </a-form-model-item>
    </a-form-model>

    <a-modal
      :title="$t('addKey')"
      :visible="showAddKeywordModal"
      @cancel="onCloseAddModal"
    >
      <a-form-model
        ref="addWordFormRef"
        :model="addWordForm"
        :rules="addWordFormRules"
      >
        <a-form-model-item
          :label="$t('weight')"
          prop="keyword"
        >
          <a-select
            v-model="addWordForm.keyword"
            :options="keywordOptions"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('key')"
          prop="key"
        >
          <a-input v-model="addWordForm.key" />
        </a-form-model-item>
      </a-form-model>

      <template #footer>
        <a-button @click="onCloseAddModal">
          {{ $t("cancel") }}
        </a-button>

        <a-button
          type="primary"
          :disabled="!addWordForm.key"
          @click="addNewKeyword"
        >
          {{ $t("apply") }}
        </a-button>
      </template>
    </a-modal>

    <div class="drawer-footer">
      <a-button
        :disabled="isSubmitting"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isSubmitting"
        @click="onSubmit()"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, computed } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import {
  updateSearchKeywords,
  checkProductKeywordScore
} from "@/modules/MPAdmin/services/searchKeywordsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import getFormatedSum from "@/utils/getFormatedSum"

const props = defineProps({
  chosenSearch: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["close", "submit"])

const initialKeywords = {
  keywords_all: [],
  keywords_6: [],
  keywords_8: [],
  keywords_12: [],
  keywords_18: []
}
let coloredKeywords = ref([])
let keyColors = ref({})

const keywords = reactive({ ...initialKeywords })
const showAddKeywordModal = ref(false)
const isSubmitting = ref(false)
const isCalculating = ref(false)
const calculatedQuery = ref("")
const productSearchResults = ref()

const addWordFormRef = ref()
const addWordForm = reactive({
  keyword: "keyword_all",
  key: ""
})
const addWordFormRules = computed(() => ({
  key: [{ validator: keyValidator, trigger: "blur" }]
}))

const keyValidator = (_, value, callback) => {
  if (!value.trim()) {
    callback(new Error(i18n.t("thisFieldMustBeFilled")))
  } else if (keywords[addWordForm.keyword].some((item) => item.key === value.trim())) {
    callback(new Error(i18n.t("alreadyAdded")))
  } else {
    callback()
  }
}

const keywordOptions = computed(() => [
  {
    label: i18n.t(`searchWeights.keywords_all`),
    value: "keywords_all"
  },
  {
    label: i18n.t(`searchWeights.keywords_6`),
    value: "keywords_6"
  },
  {
    label: i18n.t(`searchWeights.keywords_8`),
    value: "keywords_8"
  },
  {
    label: i18n.t(`searchWeights.keywords_12`),
    value: "keywords_12"
  },
  {
    label: i18n.t(`searchWeights.keywords_18`),
    value: "keywords_18"
  }
])

const assignColor = (key) => {
  if (keyColors.value[key]) {
    return keyColors.value[key]
  }

  const color = `hsl(${((coloredKeywords.value.length + 1) % 12) * 30}, 100%, 95%)`
  keyColors.value[key] = color
  return color
}

const hasDuplicate = (key, currentKey) => {
  return Object.keys(keywords).some((searchKey) => {
    if (searchKey === currentKey) return false
    return keywords[searchKey].some((item) => item.key === key)
  })
}

const colorizeDuplicates = () => {
  for (const searchKey of Object.keys(keywords)) {
    if (!keywords[searchKey].length) continue

    const keys = keywords[searchKey]

    keywords[searchKey] = keys.map(({ key }) => {
      let color = "white"

      if (hasDuplicate(key, searchKey)) {
        color = assignColor(key)
        coloredKeywords.value.push({ key, color })
      }

      return { key, color }
    })
  }
}

const setupModal = () => {
  for (const key of Object.keys(initialKeywords)) {
    keywords[key] = props.chosenSearch[key]
      .split(" ")
      .filter(Boolean)
      .map((key) => ({ key }))
  }

  colorizeDuplicates()
}

const onShowAddModal = ({ keyword }) => {
  showAddKeywordModal.value = true
  addWordForm.keyword = keyword
}

const onCloseAddModal = () => {
  showAddKeywordModal.value = false
  addWordFormRef.value.resetFields()
}

const addNewKeyword = async () => {
  try {
    await addWordFormRef.value.validate()
  } catch {
    return
  }

  keywords[addWordForm.keyword].push({ key: addWordForm.key, color: "none" })
  showAddKeywordModal.value = false
  addWordFormRef.value.resetFields()

  colorizeDuplicates()
}

const onRemoveSearchWord = ({ word, keyword }) => {
  keywords[keyword] = keywords[keyword].filter((item) => word.key !== item.key)

  colorizeDuplicates()
}

const handleCalculateSearchQuery = async () => {
  if (isCalculating.value || !calculatedQuery.value) return

  try {
    isCalculating.value = true

    const { data } = await checkProductKeywordScore({
      queryParams: { q: calculatedQuery.value },
      code: props.chosenSearch.product.p_code
    })

    productSearchResults.value = data
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isCalculating.value = false
  }
}

const onSubmit = async () => {
  try {
    isSubmitting.value = true

    const payload = {
      keywords_all: keywords.keywords_all.map(({ key }) => key).join(" "),
      keywords_6: keywords.keywords_6.map(({ key }) => key).join(" "),
      keywords_8: keywords.keywords_8.map(({ key }) => key).join(" "),
      keywords_12: keywords.keywords_12.map(({ key }) => key).join(" "),
      keywords_18: keywords.keywords_18.map(({ key }) => key).join(" ")
    }

    const { data } = await updateSearchKeywords({ uuid: props.chosenSearch.uuid, payload })
    notification.success({ message: i18n.t("updateIsStarting") })
    emit("submit", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmitting.value = false
  }
}

onMounted(setupModal)
</script>

<style lang="scss" scoped>
.search-modal-list {
  padding-bottom: 200px;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  height: 100%;

  &__item {
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    gap: 12px;

    font-size: 14px;

    &__title {
      width: fit-content;

      font-weight: 500;
      line-height: 22px;

      display: flex;
      align-items: center;
      gap: 16px;

      &__prefix {
        width: 20px;
        min-width: unset;
        margin: 0;
      }

      &__text {
        width: 50px;
      }
    }

    &__tags {
      display: flex;
      gap: 12px;
      flex-flow: row wrap;

      &--empty {
        color: $grey-font;
      }
    }
  }
}

.calculate-query {
  display: flex;
  flex-flow: column nowrap;

  position: absolute;
  bottom: 64px;
  padding: 12px 0;
  width: calc(100% - 48px);
  background: $background-color;

  &__input {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;

    width: 60%;
  }

  &__result {
    display: flex;
    height: 40px;
    align-items: center;
  }
}
</style>

<style lang="scss">
.search-modal-list__item__tags .ant-tag {
  color: $font-default;
  font-size: 14px;
  line-height: 22px;

  & * {
    color: $grey-font;
  }
}
</style>
