<template>
  <div>
    <div
      v-for="val in value"
      :key="`${val}-${rowIndex}`"
    >
      {{ val }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rowIndex: Number,
    value: Array
  }
}
</script>
