import { API_ADS } from "@/utils/HttpUtils.js"
import { transformQuery } from "./servicesUtils.js"

export const fetchCPAOrders = ({ queryParams, requestParams, signal }) => {
  return API_ADS.get(
    `/v1/admin/orders/${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const editOrderCpa = ({ uuid, data }) => {
  return API_ADS.patch(`v1/admin/orders/${uuid}/cpa/`, data)
}

export const editOrderRef = ({ uuid, data }) => {
  return API_ADS.patch(`v1/admin/orders/${uuid}/ref/`, data)
}

export const searchSellerByName = ({ params, signal }) => {
  return API_ADS.get(`/v1/admin/sellers/seller_search?term=${params.search}`, {
    signal
  })
}
