import Messenger from "./index.vue"

export default {
  path: "/messenger",
  meta: {
    permissionType: "application",
    permissionCode: "00MS01",
    code: "00MS01",
    title: "Messenger",
    layout: "ant-layout"
  },
  component: Messenger
}
