import { API } from "./HttpUtils"

const GLOBAL_CHAT_SEARCH_URL = `${process.env.VUE_APP_MESSENGER_URL_XHR}/api/admin/global-search/`
const GET_CHAT_MESSAGES_URL = (chatUuid) =>
  `${process.env.VUE_APP_MESSENGER_URL_XHR}/api/admin/all-messages/${chatUuid}/`
const GET_MESSAGE_HISTORY_URL = (messageUuid) =>
  `${process.env.VUE_APP_MESSENGER_URL_XHR}/api/admin/messages/${messageUuid}/history/`

export const globalChatSearch = ({ value, limit, offset, auth }) => {
  return API.get(GLOBAL_CHAT_SEARCH_URL, {
    params: { sender: "moderator", q: value, limit, offset },
    headers: { Authorization: `Bearer ${auth}` }
  })
}

export const getChatMessages = ({ chatUuid, limit, offset, auth }) => {
  return API.get(GET_CHAT_MESSAGES_URL(chatUuid), {
    params: { sender: "moderator", limit, offset },
    headers: { Authorization: `Bearer ${auth}` }
  })
}

export const getMessageHistory = ({ messageUuid, limit, offset, auth }) => {
  return API.get(GET_MESSAGE_HISTORY_URL(messageUuid), {
    params: { sender: "moderator", limit, offset },
    headers: { Authorization: `Bearer ${auth}` }
  })
}
