<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="items"
      row-key="id"
      :pagination="false"
    >
      <template #dateRenderer="text">
        <TableRendererDateTime :date="text" />
      </template>

      <template #editAuthorRenderer="{ created_by }">
        <div>{{ created_by.side }}</div>
        <div :style="{ opacity: '0.45' }">{{ created_by.name }}</div>
      </template>

      <template #editObjectRenderer="{ data_after, data_before }">
        <div v-for="item of getNewOfferValues(data_after, data_before)">
          <span :style="{ opacity: '0.45' }">{{ $t(item.key) }}:</span>
          {{ getTranslatedHistoryValue(item.value) }}

          <a-popover
            v-if="item.value !== 'NEW'"
            placement="top"
          >
            <template #content>
              {{ getTranslatedHistoryValue(data_before[item.key]) }}
              <a-icon type="arrow-right" />
              {{ getTranslatedHistoryValue(item.value) }}
            </template>

            <a-button
              type="link"
              icon="info-circle"
              :style="{ color: '#1890FF' }"
            />
          </a-popover>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { computed } from "vue"
import i18n from "@/i18n"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import { useStatuses } from "@/composables/useStatuses.js"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const { orderStatuses } = useStatuses()

const columns = computed(() => [
  {
    key: "index",
    dataIndex: "index",
    title: "№",
    width: 50,
    customRender: (_, __, index) => index + 1
  },
  {
    key: "created_at",
    dataIndex: "created_at",
    title: i18n.t("editDate"),
    width: 120,
    scopedSlots: { customRender: "dateRenderer" }
  },
  {
    key: "created_by",
    title: i18n.t("editAuthor"),
    scopedSlots: {
      customRender: "editAuthorRenderer"
    }
  },
  {
    key: "edit_object",
    title: i18n.t("editObject"),
    scopedSlots: {
      customRender: "editObjectRenderer"
    }
  }
])

const getTranslatedHistoryValue = (value) => {
  if (!value) {
    return ""
  }
  if (orderStatuses.value[value]) {
    return orderStatuses.value[value].title
  }
  return value
}

const getNewOfferValues = (values, oldValues) => {
  return Object.entries(values)
    .filter(([key, value]) => value && value !== oldValues[key])
    .map(([key, value]) => ({ key, value }))
}
</script>
