import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { notification } from "ant-design-vue"

const state = () => ({
  PV_showCreateValueModal: false,
  PV_valuesForNewValue: false
})

const getters = {
  PV_showCreateValueModal: (state) => state.PV_showCreateValueModal,
  PV_valuesForNewValue: (state) => state.PV_valuesForNewValue
}

const mutations = {
  PV_showCreateValueModal(state, value) {
    state.PV_showCreateValueModal = value
  },
  PV_valuesForNewValue(state, value) {
    state.PV_valuesForNewValue = value
  }
}

const actions = {
  PV_createNewValue({ commit, getters, dispatch }, description = "") {
    // show loader
    commit("PC_showLoader", true)

    let values = getters.PV_valuesForNewValue

    let list = {
      description: description.length ? description : getters.PV_selectedList.description,
      values
    }
    // start request
    API_MODERATION.post(`${apiUrls.value}`, list)
      .then((r) => r.message)
      .then((r) => {
        // restart table
        if (!description.length) {
          dispatch("PV_searchValues", {
            selected: getters.PV_selectedList,
            queryParams: getters.PV_valuesQueryParams
          })
          // update number of values in lists table
          commit("PV_selectedListCount", getters.PV_selectedListCount + values.length)
        } else {
          dispatch("ProductCatalog/PA_searchValues", getters.PA_selectedAttribute, { root: true })
        }
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PV_resetCreateValueValues")

        notification.info({ message: "Новые значения добавлены" })
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PV_resetCreateValueValues")

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  },

  PV_resetCreateValueValues({ commit }) {
    commit("PV_valuesForNewValue", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
