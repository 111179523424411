import { API } from "@/utils/HttpUtils"
import { transformQuery } from "./servicesUtils"
import { MPA_PREFIX } from "./commonService"

export const fetchOrders = ({ queryParams, requestParams, signal }) => {
  queryParams.in_cart = false

  return API.get(
    `${MPA_PREFIX}/orders${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    {
      signal
    }
  )
}

export const fetchOrdersMeta = () => {
  return API.get(`${MPA_PREFIX}/orders/meta`)
}

export const fetchOrderById = (orderCode) => {
  return API.get(`${MPA_PREFIX}/orders/${orderCode}`)
}

export const updateOrder = ({ code, payload }) => {
  return API.patch(`${MPA_PREFIX}/orders/${code}`, payload)
}

export const fetchOrderHistory = (orderCode) => {
  return API.get(`${MPA_PREFIX}/orders/${orderCode}/history`)
}

export const fetchOrderNotes = (orderCode) => {
  return API.get(`${MPA_PREFIX}/orders/${orderCode}/notes`)
}

export const fetchOrderReturns = (orderCode) => {
  return API.get(`${MPA_PREFIX}/orders/${orderCode}/returns`)
}

export const fetchOrderTransactions = (orderCode) => {
  return API.get(`${MPA_PREFIX}/orders/${orderCode}/transactions`)
}
