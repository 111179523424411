<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PU_showCreateUnitModal"
      :title="'Добавление новой единицы измерения'"
    >
      <div>
        <div>
          <label
            ><span
              >Список возможный синонимов (через запятую)<span class="required-field">*</span></span
            ></label
          >
          <Input
            size="medium"
            :value="synonyms"
            @input="onInputSynonyms"
          />
          <ValidationErrors
            :uniqueError="!PU_checkUniqueUnitSynonymGet"
            :lengthError="synonymsLengthError"
            :quantityError="showNumberOfValuesError"
            :repetativeValuesError="showRepetativeValuesError"
          />
          <p class="example">
            <label><span>Пример: Байт, byte, Byte</span></label>
          </p>
        </div>
        <div class="margin-top-10">
          <label class="sub-heading"
            ><span
              >Значение единицы измерения для
              <!--корректного -->отображения в характеристиках товара</span
            ></label
          >
          <div class="row">
            <div class="col-sm-6">
              <label
                ><span>Значение ед. изм.:<span class="required-field">*</span></span></label
              >
            </div>
            <div class="col-sm-6">
              <Input
                size="medium"
                :value="fullName"
                @input="onInputName"
              />
              <ValidationErrors
                :uniqueError="!PU_checkUniqueUnitNameGet"
                :lengthError="!validNameLength"
              />
              <p class="example">
                <label><span>Пример: ГБ</span></label>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label
                ><span>Короткое описание:<span class="required-field">*</span></span></label
              >
            </div>
            <div class="col-sm-6">
              <Input
                size="medium"
                :value="description"
                @input="onInputDescription"
              />
              <ValidationErrors :lengthError="!validDescriptionLength" />
              <p class="example">
                <label><span>Пример: Хранение цифровой информации</span></label>
              </p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewUnit"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import Autocomplete from "components/Autocomplete"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import validFloat from "utils/validations/validFloat"
import validArrayItemsCount from "utils/validations/validArrayItemsCount"
import validArrayItemsMaxLength from "utils/validations/validArrayItemsMaxLength"
import validArrayRepetativeItems from "utils/validations/validArrayRepetativeItems"

export default {
  name: "CreateUnitModal",
  components: {
    Button,
    Modal,
    Input,
    Autocomplete,
    ValidationErrors
  },
  created() {},
  data() {
    return {
      timeoutName: null,
      timeoutSynonyms: null,
      timeoutBase: null,
      validNameLength: true,
      validDescriptionLength: true,
      validCoefLength: true,
      validCoefNumeric: true,
      synonymsLengthError: false,
      showNumberOfValuesError: false,
      showRepetativeValuesError: false
    }
  },
  methods: {
    onInputName(value) {
      // validate length
      this.validNameLength = validMaxLength(value, 10)
      this.checkUniqueUnitName(value)
      // set value
      this.PU_nameForNewUnit(value)
    },
    checkUniqueUnitName(value) {
      // validate unique value
      clearTimeout(this.timeoutName)
      this.timeoutName = setTimeout(() => {
        this.PU_checkUniqueUnitName(value)
      }, 500)
    },
    onInputSynonyms(value) {
      // build array
      let synonymsArray = value.split(",").map((item) => {
        return item.trim()
      })

      this.showNumberOfValuesError = !validArrayItemsCount(synonymsArray, 50)
      this.synonymsLengthError = !validArrayItemsMaxLength(synonymsArray, 10)
      this.showRepetativeValuesError = !validArrayRepetativeItems(synonymsArray)

      this.checkUniqueUnitSynonym(value)
    },
    checkUniqueUnitSynonym(value) {
      clearTimeout(this.timeoutSynonyms)
      this.timeoutSynonyms = setTimeout(() => {
        this.PU_checkUniqueUnitSynonym({ value })
      }, 500)
      // set value
      this.PU_synonymsForNewUnit(value)
    },
    onInputDescription(value) {
      // validate length
      this.validDescriptionLength = validMaxLength(value, 50)
      // set value
      this.PU_descriptionForNewUnit(value)
    },
    fetchUnits(value) {
      if (value === "") {
        // reset
        this.PU_unitsForBaseSelectMute([])
        this.PU_unitsForBaseSelectAutocompleteMute([])
        this.PU_selectedBaseUnitMute(false)
        return
      }
      clearTimeout(this.timeoutBase)
      this.timeoutBase = setTimeout(() => {
        this.PU_fetchUnitsForBaseSelect(value)
      }, 500)
    },
    onInputCoef(value) {
      // validate length
      this.validCoefLength = validMaxLength(value, 50)
      // validate numeric value
      this.validCoefNumeric = validFloat(value)
      // set value
      this.PU_coefForNewUnit(value)
    },
    createNewUnit() {
      this.PU_createNewUnit()
      this.closeModal()
    },
    onClear() {
      // update selected base
      this.PU_selectedBaseUnitMute(false)
      // update base field
      this.PU_baseForNewUnit(" ")
      setTimeout(() => {
        this.PU_baseForNewUnit(false)
      }, 0)
      // update coef field
      this.PU_coefForNewUnit(false)
      // reset errors if any
      this.validCoefLength = true
      this.validCoefNumeric = true
    },
    closeModal() {
      this.PU_showCreateUnitModalSet(false)
      this.resetValues()
    },
    resetValues() {
      this.timeoutName = null
      this.timeoutSynonyms = null
      this.timeoutBase = null
      this.validNameLength = true
      this.validDescriptionLength = true
      this.validCoefLength = true
      this.validCoefNumeric = true
      this.synonymsLengthError = false
      this.showNumberOfValuesError = false
      this.showRepetativeValuesError = false
    },
    ...mapMutations("ProductCatalog", {
      PU_nameForNewUnit: "PU_nameForNewUnit",
      PU_synonymsForNewUnit: "PU_synonymsForNewUnit",
      PU_descriptionForNewUnit: "PU_descriptionForNewUnit",
      PU_baseForNewUnit: "PU_baseForNewUnit",
      PU_coefForNewUnit: "PU_coefForNewUnit",
      PU_showCreateUnitModalSet: "PU_showCreateUnitModal",
      PU_checkUniqueUnitNameMute: "PU_checkUniqueUnitName",
      PU_checkUniqueUnitNameSynonym: "PU_checkUniqueUnitSynonym",
      PU_unitsForBaseSelectMute: "PU_unitsForBaseSelect",
      PU_unitsForBaseSelectAutocompleteMute: "PU_unitsForBaseSelectAutocomplete",
      PU_selectedBaseUnitMute: "PU_selectedBaseUnit"
    }),
    ...mapActions("ProductCatalog", {
      PU_createNewUnit: "PU_createNewUnit",
      PU_checkUniqueUnitName: "PU_checkUniqueUnitName",
      PU_checkUniqueUnitSynonym: "PU_checkUniqueUnitSynonym",
      PU_searchUnits: "PU_searchUnits",
      PU_fetchUnitsForBaseSelect: "PU_fetchUnitsForBaseSelect",
      PU_processSelectedBaseUnit: "PU_processSelectedBaseUnit"
    })
  },
  computed: {
    description() {
      return this.PU_descriptionForNewUnitGet === false ? "" : this.PU_descriptionForNewUnitGet
    },
    base() {
      return this.PU_baseForNewUnitGet === false ? "" : this.PU_baseForNewUnitGet
    },
    coef() {
      return this.PU_coefForNewUnitGet === false ? "" : this.PU_coefForNewUnitGet
    },
    fullName() {
      return this.PU_nameForNewUnitGet === false ? "" : this.PU_nameForNewUnitGet
    },
    synonyms() {
      return this.PU_synonymsForNewUnitGet === false ? "" : this.PU_synonymsForNewUnitGet
    },
    enableCreateButton() {
      let show = false

      if (
        this.PU_nameForNewUnitGet &&
        this.PU_descriptionForNewUnitGet &&
        this.PU_synonymsForNewUnitGet &&
        this.validNameLength &&
        this.validDescriptionLength &&
        this.validCoefLength &&
        this.validCoefNumeric &&
        this.PU_checkUniqueUnitNameGet &&
        this.PU_checkUniqueUnitSynonymGet &&
        !this.synonymsLengthError &&
        !this.showNumberOfValuesError &&
        !this.showRepetativeValuesError
      ) {
        show = true
      }

      if (this.PU_selectedBaseUnit && !this.PU_coefForNewUnitGet) {
        show = false
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PU_nameForNewUnitGet: "PU_nameForNewUnit",
      PU_synonymsForNewUnitGet: "PU_synonymsForNewUnit",
      PU_descriptionForNewUnitGet: "PU_descriptionForNewUnit",
      PU_baseForNewUnitGet: "PU_baseForNewUnit",
      PU_coefForNewUnitGet: "PU_coefForNewUnit",
      PU_selectedBaseUnit: "PU_selectedBaseUnit",
      PU_showCreateUnitModal: "PU_showCreateUnitModal",
      PU_checkUniqueUnitNameGet: "PU_checkUniqueUnitName",
      PU_checkUniqueUnitSynonymGet: "PU_checkUniqueUnitSynonym",
      PU_unitsTableQueryParams: "PU_unitsTableQueryParams",
      PU_unitsForBaseSelectAutocomplete: "PU_unitsForBaseSelectAutocomplete"
    })
  }
}
</script>
<style lang="scss" scoped>
.example {
  font-size: $medium-font-size;
  line-height: 1.5em;
  margin-bottom: -5px;
  margin-top: 0px;
  position: relative;
  top: -5px;
  font-weight: 300;
}

hr {
  border: none;
  border-top: 1px solid rgba(125, 125, 125, 0.2);
}

.sub-heading {
  margin-bottom: 10px;
  font-weight: 500;
}
</style>
