import i18n from "@/i18n"

export const MODERATION_STATUS_FILTERS = [
  {
    label: "TEMP",
    value: "TEMP"
  },
  {
    label: "NEW",
    value: "NEW"
  },
  {
    label: "EDITED",
    value: "EDITED"
  },
  {
    label: "VERIFIED",
    value: "VERIFIED"
  },
  {
    label: "NOT_VERIFIED",
    value: "NOT_VERIFIED"
  },
  {
    label: "TRANSFERRED",
    value: "TRANSFERRED"
  },
  {
    label: "MR",
    value: "MODERATION_REQUIRED"
  }
]

export const AVAILABLE_FOR_EXPORT_STATUSES = ["TEMP", "NEW", "VERIFIED", "TRANSFERRED"]
export const REJECTED_EXPORT_STATUSES = ["EDITED", "NOT_VERIFIED", "MODERATION_REQUIRED"]
