<template>
  <div
    class="massive-change-file-modal"
    ref="massiveFileChangeModalRef"
  >
    <a-form-item
      :label="$t('chooseForMassiveChange')"
      class="massive-change-file-modal__form-item"
      showIcon
      :colon="false"
    >
      <a-select
        v-model="type"
        :options="typeOptions"
        style="width: 100%"
      />
    </a-form-item>

    <template v-if="type">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :pagination="false"
        rowKey="code"
      >
        <template #title>
          <span class="table__title">
            {{ $t("fileFormatForMassiveChange") }}
          </span>
        </template>

        <template #dynamicValueRenderer="record">
          {{ record[type] }}
        </template>
      </a-table>

      <a-alert
        showIcon
        class="massive-change-file-modal__alert"
      >
        <template #message>
          <div
            v-for="(part, index) in dynamicMessage"
            :key="index"
          >
            {{ part }}<br />
          </div>
        </template>
      </a-alert>

      <a-upload-dragger
        :beforeUpload="onChooseFile"
        accept="text/csv"
        :fileList="currentFiles"
        :remove="() => (currentFiles = [])"
      >
        <div class="upload-field">
          <a-icon
            type="inbox"
            class="upload-field__icon"
          />

          {{ $t("chooseOrDragForUpload") }}
        </div>
      </a-upload-dragger>
    </template>

    <div class="drawer-footer">
      <a-button @click="handleClose()">{{ $t("toReturn") }}</a-button>

      <a-button
        type="primary"
        :disabled="!currentFiles.length"
        :loading="loading"
        @click="handleUploadFile()"
      >
        {{ $t("uploadFile") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import {
  uploadGoogleLabelsFile,
  uploadFacebookLabelsFile,
  uploadEkatalogActivityFile,
  uploadFacebookActivityFile,
  checkFileChangeTask
} from "@/modules/ADS/services/merchantServices.js"

const tableTitles = {
  ga_default_label: "Custom-label",
  fb_default_label: "Custom-label",
  facebook_marks: "FB",
  ekatalog_marks: "EK"
}

const dataSource = [
  {
    code: "CS0020764",
    ga_default_label: "merch0onesmart 1",
    fb_default_label: "merch0onesmart 1",
    facebook_marks: "True",
    ekatalog_marks: "True"
  },
  {
    code: "CS0020765",
    ga_default_label: "merch0onesmart 2",
    fb_default_label: "merch0onesmart 2",
    facebook_marks: "False",
    ekatalog_marks: "False"
  }
]

const emit = defineEmits(["close"])

const massiveFileChangeModalRef = ref()

const type = ref()
const loading = ref(false)
const currentFiles = ref([])

const typeOptions = computed(() => [
  {
    label: `${i18n.t("customLabelChange")}, Google`,
    value: "ga_default_label"
  },
  {
    label: `${i18n.t("customLabelChange")}, Facebook`,
    value: "fb_default_label"
  },
  {
    label: i18n.t("activityChangeFacebook"),
    value: "facebook_marks"
  },
  {
    label: i18n.t("activityChangeEkatalog"),
    value: "ekatalog_marks"
  }
])

const tableDynamicTitle = computed(() => tableTitles[type.value])

const dynamicMessage = computed(() => i18n.t(`merchantFileChangeAlerts.${type.value}`))

const columns = computed(() => [
  {
    key: "code",
    dataIndex: "code",
    title: "CS-code",
    width: 200
  },
  {
    key: "value",
    title: tableDynamicTitle.value,
    scopedSlots: {
      customRender: "dynamicValueRenderer"
    },
    width: 400
  }
])

const handleClose = () => {
  emit("close")
}

const onChooseFile = (file) => {
  if (file.name.split(".")[1] !== "csv") {
    notification.error({ message: `${i18n.t("formatError")}: .csv` })
    return false
  }

  currentFiles.value = [file]
  return false
}

const getRequestFromType = (formData) => {
  const routes = {
    ga_default_label: uploadGoogleLabelsFile,
    fb_default_label: uploadFacebookLabelsFile,
    facebook_marks: uploadFacebookActivityFile,
    ekatalog_marks: uploadEkatalogActivityFile
  }

  return routes[type.value](formData)
}

const handleUploadFile = async () => {
  const formData = new FormData()
  const [file] = currentFiles.value

  formData.append("update_file", file, "update_file.csv")

  try {
    loading.value = true

    const { data } = await getRequestFromType(formData)

    checkFileChangeTask(data.task_id)

    notification.info({ message: i18n.t("massiveChangeWithFileStarted") })
    currentFiles.value = []
    handleClose()
  } catch (e) {
    const detail = e?.response?.data?.detail ? e.response.data.detail : ""
    notification.error({
      message: i18n.t("massiveFileChangeError"),
      description: Array.isArray(detail) ? detail.join(", ") : detail,
      duration: 0
    })
  } finally {
    loading.value = false
  }
}
</script>

<style scoped lang="scss">
.massive-change-file-modal {
  padding-bottom: 50px;

  &__form-item {
    margin: 0;
  }

  &__alert {
    margin: 24px 0 48px 0;
  }
}

.table__title {
  color: $font-default;
}

.upload-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;

  font-size: 16px;

  &__icon {
    color: $primary-color;
    font-size: 36px;
  }
}
</style>
