<template>
  <div>
    <template v-if="PA_searchedAtlsOfAttributeGet.length">
      <SynDataTable
        :data="tableData"
        :queryAction="() => false"
        :queryParams="queryParams"
        rowKey="code"
        :columns="dataTableColumns"
        tableId="ProductAttributesAtlsTable"
        :headerButtons="headerButtons"
        class="margin-top"
        :limitValues="limitValues"
        theme="odd"
      ></SynDataTable>
    </template>
    <template v-else>
      <Button
        color="primary"
        @click="PA_showAddTypeModal(true)"
        class="lonely-button"
        >Привязать</Button
      >
    </template>
    <AddTypeModal />
  </div>
</template>
<script>
import Button from "components/Button"
import Checkboxes from "./RendererComponents/Checkboxes"

import { mapMutations, mapActions, mapGetters } from "vuex"
import AddTypeModal from "../AddTypeModal/AddTypeModal"

export default {
  name: "AtlsTable",
  components: {
    AddTypeModal,
    Button
  },
  created() {},
  data() {
    return {
      dataTableColumns: [
        {
          key: "product_type_name",
          title: "Тип (полный)"
        },
        {
          key: "is_main",
          title: "M",
          width: 40,
          renderer: Checkboxes
        },
        {
          key: "is_required",
          title: "R",
          width: 40,
          renderer: Checkboxes
        }
      ],
      headerButtons: [
        {
          label: "Привязать",
          action: () => {
            this.PA_showAddTypeModal(true)
          },
          theme: "primary"
        }
      ],
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 }
      ]
    }
  },
  methods: {
    onSort(oldIndex, newIndex, items) {
      this.PA_updateTypesOrder(items)
    },
    ...mapMutations("ProductCatalog", {
      PA_showAddTypeModal: "PA_showAddTypeModal"
    }),
    ...mapActions("ProductCatalog", {
      PA_updateTypesOrder: "PA_updateTypesOrder"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PA_searchedAtlsOfAttributeGet: "PA_searchedAtlsOfAttribute",
      tableData: "PA_searchedAtlsTable",
      queryParams: "PA_searchedAtlsQueryParams"
    })
  }
}
</script>
<style lang="scss" scoped>
.lonely-button {
  margin-top: 3px;
}
</style>
