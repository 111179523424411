<template>
  <div>
    <Select
      v-if="row.new_value_type == 'NUMERIC' && value"
      :values="possibleUnits"
      :selected="selected"
      :disabled="true"
    />
  </div>
</template>
<script>
import Select from "components/Select/Select"
import possibleUnitsMixin from "../../../mixins/possibleUnitsMixin"

export default {
  mixins: [possibleUnitsMixin],
  components: {
    Select
  },
  props: ["row", "value"],
  computed: {
    selected() {
      if (!this.possibleUnits.length) return null
      return this.value || null
    }
  }
}
</script>
<style lang="scss"></style>
