const state = () => ({
  PA_showEditAttributeModal: false,
  PA_editableAttrValue: false,
  PA_editableAttrRowIndex: false
})

const getters = {
  PA_showEditAttributeModal: (state) => state.PA_showEditAttributeModal,
  PA_editableAttrValue: (state) => state.PA_editableAttrValue,
  PA_editableAttrRowIndex: (state) => state.PA_editableAttrRowIndex
}

const mutations = {
  PA_showEditAttributeModal(state, value) {
    state.PA_showEditAttributeModal = value
  },
  PA_editableAttrValue(state, value) {
    state.PA_editableAttrValue = value
  },
  PA_editableAttrRowIndex(state, value) {
    state.PA_editableAttrRowIndex = value
  }
}

export default {
  state,
  getters,
  mutations
}
