import Moderation from "./Moderation.vue"
import ModeartionProducts from "./ModerationProducts"
import Export from "./Export"
import ExportHistory from "./ExportHistory"
import i18n from "@/i18n"

const applicationCode = "PRODCAT1"
const productsComponentCode = "PRODCAT02"
const exportComponentCode = "PM:COMP:EX"
const exportHistoryComponentCode = "PM:COMP:EH"

export default {
  path: "/moderation",
  component: Moderation,
  meta: {
    permissionType: "application",
    permissionCode: applicationCode,
    code: "PRODCAT1",
    withoutChecking: true,
    layout: "ant-layout"
  },
  children: [
    {
      path: "products",
      component: ModeartionProducts,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${productsComponentCode}`,
        code: productsComponentCode,
        layout: "ant-layout",
        title: i18n.t("moderationOfProducts")
      }
    },
    {
      path: "export",
      component: Export,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${exportComponentCode}`,
        code: exportComponentCode,
        layout: "ant-layout",
        title: i18n.t("exportPageTitle")
      }
    },
    {
      path: "export-history",
      component: ExportHistory,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${exportHistoryComponentCode}`,
        code: exportHistoryComponentCode,
        layout: "ant-layout",
        title: i18n.t("exportHistoryTitle")
      }
    }
  ]
}
