<template>
  <div class="buyer-avatar">
    <div class="buyer-avatar__img">
      <a-avatar
        :src="avatarSrc"
        :size="36"
        @click="onPhotoClick"
      />
    </div>

    <div
      v-if="isAvatarBlocked"
      class="buyer-avatar--blocked"
    >
      <a-icon type="eye-invisible" />
    </div>

    <TableRendererDateTime :date="row.avatar_uploaded_at" />
  </div>
</template>

<script setup>
import { computed, inject } from "vue"

import getImagePath from "utils/getImagePath"

import {
  IMAGES_CDN,
  IMAGE_FEMALE,
  IMAGE_MALE,
  AVATAR_MODERATION_STATUS_EXPECTS
} from "@/constants/common"

import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"

const props = defineProps({
  row: {
    type: Object,
    default: () => ({})
  }
})

const openModal = inject("openModal")

const avatarSrc = computed(() => {
  if (!props.row.avatar) {
    return props.row.gender && props.row.gender === "F" ? IMAGE_FEMALE : IMAGE_MALE
  }

  return `${IMAGES_CDN}/media/assets/images/${getImagePath(props.row.avatar, "100x100")}`
})

const isAvatarBlocked = computed(() => {
  return props.row.avatar && props.row.avatar_moderation_status !== AVATAR_MODERATION_STATUS_EXPECTS
})

const onPhotoClick = () => {
  openModal(props.row)
}
</script>

<style scoped lang="scss">
.buyer-avatar {
  position: relative;

  display: flex;
  align-items: center;

  gap: 8px;

  &__img {
    cursor: pointer;
    border-radius: 50%;

    transition: 0.1s;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 2.5px $primary-color;
    }
  }

  &--blocked {
    position: absolute;
    top: -4px;
    left: -4px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
    font-size: 12px;
    color: #ffffff;

    background: #ff0000;
    border-radius: 50%;
  }
}
</style>
