import { API, API_V2 } from "../../utils/HttpUtils"
import Cookies from "js-cookie"

const auth = {
  namespaced: true,

  state: {
    profile: {},
    permissions: {
      applications: []
    },
    isLogged: false,
    loginRequest: false,
    logoutRequest: false,
    profileRequest: false,
    permissionsRequest: false
  },

  mutations: {
    loginRequest(state) {
      state.loginRequest = true
    },

    loginSuccess(state, payload) {
      state.loginRequest = false
      state.isLogged = true
      state.profile = payload
    },

    loginFailure(state) {
      state.loginRequest = false
      state.isLogged = false
    },

    logoutRequest(state) {
      state.logoutRequest = true
    },

    logoutSuccess(state) {
      state.logoutRequest = false
      state.profile = {}
      state.isLogged = false
    },

    setLogout(state) {
      state.logoutRequest = false
      state.profile = {}
      state.isLogged = false
    },

    logoutFailure(state) {
      state.logoutRequest = false
    },

    getProfileRequest(state) {
      state.profileRequest = true
    },

    getProfileSuccess(state, payload) {
      state.profileRequest = false
      state.profile = payload
      state.isLogged = true
    },

    getProfileFailure(state) {
      state.profileRequest = false
      state.isLogged = false
    },

    getPermissionsRequest(state) {
      state.permissionsRequest = true
    },

    getPermissionsSuccess(state, applications) {
      state.permissionsRequest = false
      state.permissions.applications = applications
    },

    getPermissionsFailure(state) {
      state.permissionsRequest = false
      state.permissions.applications = {}
    }
  },

  actions: {
    async login({ commit, dispatch }, authParams) {
      commit("loginRequest")

      try {
        const { data } = await API_V2.post("admin/auth/login/", {
          login: authParams.username,
          password: authParams.password
        })

        await dispatch("setTokens", data)

        API_V2.defaults.headers.common["Authorization"] = "Bearer " + data.access_token
      } catch (e) {
        console.log(e)
      }

      try {
        const response = await API.post("/auth/login", authParams)

        commit("loginSuccess", response.data)
        dispatch("getPermissions")
      } catch {
        commit("loginFailure")
      }
    },

    async logout({ commit, dispatch }) {
      commit("logoutRequest")

      try {
        await API_V2.post("admin/auth/logout/")
      } catch (e) {
        console.log(e)
      }

      API.post("/auth/logout")
        .then((response) => {
          dispatch("clearTokens")
          commit("logoutSuccess", response.data)
        })
        .catch((error) => {
          commit("logoutFailure", error)
        })
    },

    setLogout({ commit }) {
      commit("setLogout")
    },

    async getProfile({ commit, dispatch }) {
      commit("getProfileRequest")

      let access = Cookies.get("access_token_mpa")
      if (!access) {
        try {
          const { access_token } = await dispatch("sendRefreshToken")
          access = access_token
        } catch {
          commit("getProfileFailure")
        }
      }

      API_V2.defaults.headers.common["Authorization"] = "Bearer " + access

      API.get("/users-management/users/profile")
        .then((response) => {
          commit("getProfileSuccess", response.data)

          dispatch("getPermissions")
        })
        .catch((error) => {
          commit("getProfileFailure")
        })
    },

    async getPermissions({ commit }) {
      commit("getPermissionsRequest")

      API.get("/users-management/permissions/applications?components_only=true")
        .then((response) => {
          commit("getPermissionsSuccess", response.data.results)
        })
        .catch((error) => {
          commit("getPermissionsFailure", error.response.data)
        })
    },

    async sendRefreshToken({ dispatch }) {
      return new Promise(async (res, rej) => {
        try {
          const refresh = Cookies.get("refresh_token_mpa")
          if (!refresh) rej("Not authorized")

          const { data } = await API_V2.post(
            "admin/auth/refresh/",
            {},
            {
              headers: {
                Authorization: "Bearer " + refresh
              }
            }
          )

          await dispatch("setTokens", data)
          res(data)
        } catch (e) {
          rej(e)
        }
      })
    },

    setTokens({}, data) {
      return new Promise((res, rej) => {
        try {
          const { access_token, refresh_token } = data

          if (!access_token) {
            rej(new Error("Access token is invalid"))
            return
          }

          Cookies.set("access_token_mpa", access_token, { expires: 1 / 24 })
          Cookies.set("refresh_token_mpa", refresh_token, { expires: 8 / 24 })

          res(data)
        } catch (e) {
          rej(e)
        }
      })
    },

    clearTokens({}) {
      Cookies.set("access_token_mpa", "", { expires: 0 })
      Cookies.set("refresh_token_mpa", "", { expires: 0 })
    }
  },

  getters: {},

  modules: {}
}

export default auth
