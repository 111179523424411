<template>
  <div :class="status.toLowerCase()">
    {{ translations[status.toLowerCase()] }}
  </div>
</template>

<script setup>
import { computed } from "vue"
import i18n from "@/i18n"

const props = defineProps({
  status: String,
  default: "new"
})

const translations = computed(() => ({
  new: i18n.t("new"),
  paid: i18n.t("paid"),
  canceled: i18n.t("canceledStatus"),
  removed: i18n.t("deleted")
}))
</script>

<style scoped>
.new {
  color: #1890ff;
}
.paid {
  color: #3cb600;
}
.canceled {
  color: #f14141;
}
.removed {
  color: #bfbfbf;
}
</style>
