<template>
  <div
    class="i-wrapper"
    :class="{ [state]: state, display: display }"
  >
    <label :class="{ inline: width == 'xs' }">
      <span v-if="isLabel">{{ label }} {{ required ? "*" : "" }}</span>
      <input
        ref="input"
        type="text"
        :class="{ [size]: size, [width]: width }"
        :value="value"
        @input="updateSelf($event.target.value, $event.target, $event)"
        @keydown.enter="onEnter($event.target.value)"
        @blur="onBlur($event.target.value, $event.target, $event)"
        @focus="$emit('focus')"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    autoFocus: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ""
    },
    size: {
      type: String,
      default: "medium"
    },
    status: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    display: {
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    }
  },
  data() {
    return {
      state: this.status
    }
  },
  mounted() {
    if (this.autoFocus) {
      this.$refs.input.focus()
    }
  },
  watch: {
    status(value) {
      this.state = value
    }
  },
  methods: {
    updateSelf(value, target, e) {
      this.$emit("input", value)
    },
    onEnter(value) {
      this.$emit("enter", value)
    },
    onBlur(value, target, e) {
      this.$emit("blur", value)
    }
  },
  computed: {
    isLabel() {
      return this.label !== ""
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  box-shadow: $light-shadow;
  &:disabled {
    background-color: #f0f0f0;
  }
}
.inline {
  display: inline-block;
}
.small {
  height: 24px;
  line-height: 24px;
}
.medium {
  height: 30px;
  line-height: 30px;
}
.large {
  height: 38px;
  line-height: 38px;
}
.xs {
  max-width: 80px;
}
.i-wrapper {
  width: 100%;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.error {
  span {
    color: $danger-color;
    border: 0 !important;
  }
  input {
    border: 1px solid $danger-color;
  }
}
.success {
  span {
    color: $success-color;
    border: 0 !important;
  }
  input {
    border: 1px solid $success-color;
  }
}
.display {
  display: block !important;
  width: 100% !important;
}
</style>
