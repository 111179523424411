import { computed, ref } from "vue"
import router from "@/router/index.js"
import buildTableQueryParams from "@/utils/buildTableQueryParams"
import notifyResponseError from "@/utils/notifyResponseError"
import useCancellableRequest from "./useCancellableRequest"

export default ({ queryFunction, requestParams = {} }) => {
  const { makeRequest, cancelRequest } = useCancellableRequest(queryFunction)

  const tableData = ref([])
  const isFetching = ref(false)

  const filteredInfo = ref({})
  const paginationInfo = ref({
    current: 1,
    total: 0,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: false,
    pageSizeOptions: ["10", "25", "50", "100"]
  })
  const sortedInfo = ref({})

  const dataSource = computed(() => tableData.value)

  const setFilteredInfo = (value) => {
    const filters = {}

    Object.keys(value).forEach((key) => {
      if (value[key] === undefined) return
      filters[key] = value[key]
    })

    filteredInfo.value = filters
  }

  const setSortedInfo = (value) => {
    sortedInfo.value = value
  }

  const setPaginationInfo = (value) => {
    paginationInfo.value = value

    const tableHeadEl = document.getElementsByClassName("ant-table-thead")[0]

    if (tableHeadEl) {
      const tableHeadPosition = tableHeadEl.getBoundingClientRect()

      if (tableHeadPosition.top < 0) {
        tableHeadEl.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  const setupTable = (defaultParams = {}) => {
    const { ordering, order_by, page, pageSize, ...filters } = router.currentRoute.query
    const { defaultPagination = {}, defaultFilters = {}, defaultSorter = {} } = defaultParams
    // Set initial sorter START
    const newSortedInfo = { ...defaultSorter }

    if (ordering) {
      newSortedInfo.columnKey = ordering.indexOf("-") === 0 ? ordering.replace("-", "") : ordering
      newSortedInfo.order = ordering.indexOf("-") === 0 ? "ascend" : "descend"
    } else if (order_by) {
      newSortedInfo.columnKey = order_by.indexOf("-") === 0 ? order_by.replace("-", "") : order_by
      newSortedInfo.order = order_by.indexOf("-") === 0 ? "ascend" : "descend"
    }

    setSortedInfo(newSortedInfo)
    // Set initial sorter END

    // Set initial filters START
    const correctFilters = { ...defaultFilters }

    Object.keys(filters).forEach((filterKey) => {
      if (filterKey.endsWith("_at_min")) {
        correctFilters[filterKey.replace("_min", "")] = [
          filters[filterKey],
          filters[filterKey.replace("_min", "_max")]
        ]
      } else if (filterKey.endsWith("_at_max")) {
        // ignore _at_max key
      } else if (filterKey.endsWith("_from")) {
        correctFilters[filterKey.replace("_from", "_range")] = [
          filters[filterKey],
          filters[filterKey.replace("_from", "_to")]
        ]
      } else if (filterKey.endsWith("_to")) {
        // ignore _at_max key
      } else if (filterKey.endsWith("_at__min")) {
        correctFilters[filterKey.replace("__min", "__interval")] = [
          filters[filterKey],
          filters[filterKey.replace("__min", "__max")]
        ]
      } else if (filterKey.endsWith("_at__max")) {
        // ignore _at_max key
      } else if (filters[filterKey].includes(",")) {
        correctFilters[filterKey] = filters[filterKey].split(",")
      } else {
        correctFilters[filterKey] = filters[filterKey]
      }
    })

    setFilteredInfo(correctFilters)
    // Set initial filters END

    // Set initial pagination START
    setPaginationInfo({
      ...paginationInfo.value,
      ...defaultPagination,
      current: page ? +page : 1
    })

    if (pageSize) {
      setPaginationInfo({
        ...paginationInfo.value,
        pageSize: +pageSize
      })
    }
    // Set initial pagination END
  }

  // Change isFetching state
  const onStartFetching = () => {
    isFetching.value = true
  }

  const onEndFetching = () => {
    isFetching.value = false
  }

  // set new list of records to tableData
  const setTableData = (newData) => {
    tableData.value = newData
    onEndFetching()
  }

  // update single record by identifier
  const updateTableDataRecord = ({ payload, identifier }) => {
    tableData.value = tableData.value.map((item) => {
      if (item[identifier] !== payload[identifier]) return item
      return { ...item, ...payload }
    })
  }

  const fetchTableInfo = async ({
    pagination = paginationInfo.value,
    filters = filteredInfo.value,
    sorter = sortedInfo.value
  }) => {
    try {
      onStartFetching()

      const queryParams = buildTableQueryParams(pagination, filters, sorter)

      const { data } = await makeRequest({ queryParams, requestParams })

      setTableData(data.results)
      setPaginationInfo({ ...pagination, total: data.count })
      setSortedInfo(sorter)
    } catch (error) {
      if (error.message === "canceled") return // ignore axios abortController

      notifyResponseError({ error })
      onEndFetching()
    }
  }

  const handleTableFilterChange = () => {
    setPaginationInfo({ ...paginationInfo.value, current: 1 })
    fetchTableInfo({})
  }

  const handleTableFiltersReset = (resetTableData = false) => {
    setFilteredInfo({})

    if (!resetTableData) {
      setPaginationInfo({ ...paginationInfo.value, current: 1 })
      return fetchTableInfo({})
    }

    setPaginationInfo({ ...paginationInfo.value, current: 1, total: 0 })
    cancelRequest()
    setTableData([])

    if (!Object.keys(router.currentRoute.query).length) return
    router.push({ query: {} })
  }

  return {
    dataSource,
    isFetching,
    paginationInfo,
    filteredInfo,
    sortedInfo,

    setPaginationInfo,
    setFilteredInfo,
    setSortedInfo,
    setupTable,
    setTableData,
    fetchTableInfo,
    handleTableFilterChange,
    handleTableFiltersReset,
    updateTableDataRecord,
    notifyResponseError
  }
}
