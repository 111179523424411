<template>
  <div>
    <PageHeader :title="$t('exportHistoryTitle')">
      <template #filters>
        <a-form-item>
          <a-select
            :placeholder="$t('exportStatusFilter')"
            style="width: 100%"
            v-model="filteredInfo.status"
            allowClear
            @change="handleTableFilterChange"
          >
            <a-select-option
              v-for="item in exportStatuses"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <!-- <a-form-item :help="$t('creatingDate')" style="margin-bottom: 0;">
            <a-range-picker
              v-model="filteredInfo.start_timestamp"
              format="DD.MM.YYYY"
              valueFormat="YYYY-MM-DD"
              @change="handleTableFilterChange"
            />
          </a-form-item>

          <a-form-item :help="$t('exportDateEnd')" style="margin-bottom: 0;">
            <a-range-picker
              v-model="filteredInfo.finish_timestamp"
              format="DD.MM.YYYY"
              valueFormat="YYYY-MM-DD"
              @change="handleTableFilterChange"
            />
          </a-form-item> -->
      </template>

      <template #actions>
        <a-form-item>
          <a-button
            icon="undo"
            :loading="isFetching"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>
        </a-form-item>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="job_id"
      @change="onTableChange"
    >
      <template #dateRenderer="text">
        <template v-if="text">
          <TableRendererDateTime :date="text" />
        </template>
      </template>

      <template #statusRenderer="status">
        {{ exportStatuses[status].label }}
      </template>

      <template #actions="record">
        <a-button
          type="danger"
          shape="circle"
          icon="download"
          :style="{ marginRight: '8px' }"
          :disabled="!record.errors_products"
          :loading="reportFetchingIds.indexOf(record.oid) !== -1"
          @click="handleDownloadReport(record)"
        />

        <a-button
          type="dashed"
          shape="circle"
          icon="info"
          :style="{ marginRight: '8px' }"
          @click="handleOpenErrors(record.oid)"
        />

        <a-button
          type="dashed"
          shape="circle"
          icon="stop"
          :loading="stopExportIds.indexOf(record.oid) !== -1"
          :disabled="record.status !== 'STARTED' && record.status !== 'PENDING'"
          @click="handleStopExport(record.oid)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="isOpenedErrorsDrawer"
      :title="$t('exportErrors')"
      placement="right"
      width="800"
      destroyOnClose
      @close="handleCloseErrors"
    >
      <ExportErrors
        :jobOid="jobOid"
        @onClose="handleCloseErrors"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import i18n from "@/i18n"
import moment from "moment"
import ExportErrors from "./components/ExportErrors"
import PageHeader from "@/ant-components/PageHeader"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import { useExportStatuses } from "@/composables/useExportStatuses"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import {
  fetchExportHistory,
  fetchExportReport,
  stopExportingJob
} from "../services/exportHistoryService"
import useExportHistoryColumns from "./useExportHistoryColumns"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchExportHistory,
  requestParams: { changeRouterQuery: true }
})
const { exportStatuses } = useExportStatuses()
const { columns } = useExportHistoryColumns({ sortedInfo: computed(() => sortedInfo.value) })

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const reportFetchingIds = ref([])
const stopExportIds = ref([])
const isOpenedErrorsDrawer = ref(false)
const jobOid = ref(null)

const handleStopExport = async (oid) => {
  try {
    stopExportIds.value = [...stopExportIds.value, oid]

    await stopExportingJob({
      requestParams: {
        job_oid: oid
      }
    })

    notification.success({
      message: i18n.t("exportSuccessStoped")
    })

    onTableChange()
  } catch (error) {
    notifyResponseError({
      message: i18n.t("exportFailedStoped"),
      error
    })
  } finally {
    stopExportIds.value = stopExportIds.value.filter((item) => item !== oid)
  }
}

const handleOpenErrors = (oid) => {
  jobOid.value = oid
  isOpenedErrorsDrawer.value = true
}

const handleCloseErrors = () => {
  jobOid.value = null
  isOpenedErrorsDrawer.value = false
}

const handleDownloadReport = async (record) => {
  try {
    reportFetchingIds.value = [...reportFetchingIds.value, record.oid]

    const { data } = await fetchExportReport(record.oid)

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8, ${data}`)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute(
      "download",
      `export-errors_${moment().format("DD-MM-YYYY_HH-mm-ss")}_${record.meta.xml_slug}.csv`
    )
    document.body.appendChild(link) // Required for FF

    link.click()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    reportFetchingIds.value = reportFetchingIds.value.filter((item) => item !== record.oid)
  }
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>
