import buildTableQueryParams from "@/utils/buildTableQueryParams.js"
import { transformQuery } from "./servicesUtils.js"
import { simpleBuildQuery, API_V2 } from "@/utils/HttpUtils.js"

const moderationPrefix = "/proxy/pm/api/v3/admin"

export const fetchProductsForExport = ({ queryParams, requestParams, signal }) => {
  const query = transformQuery(queryParams, requestParams?.changeRouterQuery).replace(
    "ordering",
    "order_by"
  )

  return API_V2.get(`${moderationPrefix}/products/${query}`, {
    signal
  })
}

export const exportProducts = ({ paginationInfo, filteredInfo, sortedInfo }) => {
  const tableQueryParams = buildTableQueryParams(paginationInfo, filteredInfo, sortedInfo)

  const { page, pageSize, ...otherParams } = tableQueryParams
  const queryParamsForRequest = {
    ...otherParams
  }

  return API_V2.post(
    `${moderationPrefix}/products/export/${simpleBuildQuery(queryParamsForRequest)}`
  )
}
