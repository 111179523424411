import { API_V2 } from "@/utils/HttpUtils.js"
import { moderationPrefix, transformQuery } from "./servicesUtils"

const ruLangHeaders = {
  headers: {
    "Accept-language": "ru"
  }
}

export const fetchExportHistory = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/export-jobs/${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    ).replace("ordering", "order_by")}`,
    {
      signal,
      ...ruLangHeaders
    }
  )
}

export const stopExportingJob = ({ requestParams }) => {
  return API_V2.post(
    `${moderationPrefix}/export-jobs/${requestParams.job_oid}/stop/`,
    {
      job_oid: requestParams.job_oid
    },
    ruLangHeaders
  )
}

export const fetchExportReport = (oid) => {
  return API_V2.get(`${moderationPrefix}/export-jobs/${oid}/report/`, ruLangHeaders)
}

export const fetchExportErrors = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/export-jobs/${requestParams.oid}/errors/${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    {
      signal,
      ...ruLangHeaders
    }
  )
}
