<template>
  <span ref="mainInput">
    <input
      ref="editableCell"
      type="text"
      v-model="myVal"
      @keyup.enter="(e) => submitEditCell(field, e.target.value, rowIndex)"
      @keyup.esc="cancelEditCell"
      @input="onInput"
    />
    <ValidationErrors
      :maxLengthError="{ flag: maxLengthError, length: 200 }"
      :emptyError="emptyError"
      :uniqueError="!PT_checkUniqueTypeGet && myVal !== initialValue && myVal !== ''"
    />
  </span>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import validEmpty from "utils/validations/validEmpty"
import validMaxLength from "utils/validations/validMaxLength"

export default {
  name: "InputEditName",
  components: {
    ValidationErrors
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    close: Function,
    onUpdate: Function
  },
  data() {
    return {
      emptyError: false,
      maxLengthError: false,
      sendObject: {},
      myVal: this.row[this.field],
      initialValue: this.row[this.field],
      timeout: null
    }
  },
  mounted() {
    this.$refs.mainInput.querySelector("input").focus()
    if (this.PC_selectedLanguage === this.PC_baseLanguage) {
      this.validateUniqueOnMount()
    }
    this.closeOnBlurEvent()
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PT_checkUniqueTypeGet: "PT_checkUniqueTypeName",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage"
    })
  },
  methods: {
    validateUniqueOnMount() {
      if (this.myVal !== this.initialValue) {
        this.PT_checkUniqueTypeName(this.$refs.editableCell.value)
      }
    },
    onInput(event) {
      let value = event.target.value
      this.maxLengthError = !validMaxLength(value, 200)
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        this.emptyError = !validEmpty(value)
        this.validateUnique(value)
      }
    },
    validateUnique(value) {
      if (this.myVal !== this.initialValue) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.PT_checkUniqueTypeName(value)
        }, 500)
      }
    },
    submitEditCell(field, value, rowIndex) {
      if (this.maxLengthError || !this.PT_checkUniqueTypeGet || this.emptyError) return

      this.submitName(field, value, rowIndex)

      this.onUpdate(this.sendObject)
      this.PT_checkUniqueTypeMute(true)
      this.close()
    },
    submitName(field, value, rowIndex) {
      this.sendObject = {
        columnKey: field,
        newValue: value,
        rowIndex
      }
    },
    cancelEditCell() {
      this.PT_checkUniqueTypeMute(true)
      this.close()
    },
    closeOnBlurEvent() {
      let context = this
      this.$refs.editableCell.addEventListener("blur", context.close, false)
    },
    unsubscribeOnBlur() {
      this.PT_checkUniqueTypeMute(true)
      let context = this
      this.$refs.editableCell.removeEventListener("blur", context.close, false)
    },
    ...mapMutations("ProductCatalog", {
      PT_checkUniqueTypeMute: "PT_checkUniqueTypeName"
    }),
    ...mapActions("ProductCatalog", {
      PT_checkUniqueTypeName: "PT_checkUniqueTypeName"
    })
  },
  beforeDestroy() {
    this.unsubscribeOnBlur()
  }
}
</script>
