<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PT_showCopyTypeModalGet"
      :title="'Копирование типа'"
    >
      <div>
        <div>
          <label
            ><span>Выберите тип для копирования<span class="required-field">*</span></span></label
          >
          <Autocomplete
            :data="PT_typesToCopyAutocompleteGet"
            event="input"
            @event="fetchTypes"
            @select="PT_selectedTypeToCopy"
            :size="'small'"
            :defaultValue="defaultType"
          />
        </div>
        <div class="margin-top-10">
          <label
            ><span>Название типа (полное)<span class="required-field">*</span></span></label
          >
          <Input
            size="medium"
            :value="fullName"
            @input="onInputName"
          />
          <ValidationErrors
            :lengthError="!validNameLength"
            :uniqueError="!PT_checkUniqueTypeNameGet"
          />
        </div>
        <div class="margin-top-10">
          <label><span>Название типа (короткая форма)</span></label>
          <Input
            size="medium"
            :value="shortNameRu"
            @input="onInputShortName"
          />
          <ValidationErrors :lengthError="!validShortNameLength" />
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewType"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Input from "components/Input"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { mapActions, mapMutations, mapGetters } from "vuex"
import validMaxLength from "utils/validations/validMaxLength"
import Autocomplete from "components/Autocomplete"

export default {
  name: "CreateTypeModal",
  components: {
    Button,
    Modal,
    Input,
    ValidationErrors,
    Autocomplete
  },
  data() {
    return {
      timeoutName: null,
      validNameLength: true,
      validShortNameLength: true
    }
  },
  methods: {
    fetchTypes(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PT_typesToCopyAutocomplete(value)
      }, 500)
    },
    onInputName(value) {
      // validate length
      this.validNameLength = validMaxLength(value, 200)
      this.checkUniqueTypeName(value)
      // set value
      this.PT_nameForNewType(value)
    },
    checkUniqueTypeName(value) {
      clearTimeout(this.timeoutName)
      this.timeoutName = setTimeout(() => {
        this.PT_checkUniqueTypeName(value)
      }, 500)
    },
    onInputShortName(value) {
      // validate length
      this.validShortNameLength = validMaxLength(value, 50)
      // set value
      this.PT_nameShortForNewType(value)
    },
    checkUniqueTypeSynonym(value) {
      clearTimeout(this.timeoutSynonyms)
      this.timeoutSynonyms = setTimeout(() => {
        this.PT_checkUniqueTypeSynonym({ value })
      }, 500)
    },
    createNewType() {
      this.PT_copyNewType()
    },
    closeModal() {
      this.PT_showCopyTypeModal(false)
      this.resetValues()
    },
    resetValues() {
      this.PT_checkUniqueTypeNameMute(true)
      this.validNameLength = true
      this.validShortNameLength = true
      this.PT_nameForNewType(false)
      this.PT_nameShortForNewTypeRu(false)
      this.PT_nameShortForNewTypeUk(false)
      this.PT_nameShortForNewTypeEn(false)
      this.PT_selectedTypeToCopyMute(false)
    },
    ...mapMutations("ProductCatalog", {
      PT_nameForNewType: "PT_nameForNewType",
      PT_nameShortForNewType: "PT_nameShortForNewType",
      PT_showCopyTypeModal: "PT_showCopyTypeModal",
      PT_checkUniqueTypeNameMute: "PT_checkUniqueTypeName",
      PT_selectedTypeToCopyMute: "PT_selectedTypeToCopy"
    }),
    ...mapActions("ProductCatalog", {
      PT_checkUniqueTypeName: "PT_checkUniqueTypeName",
      PT_typesToCopyAutocomplete: "PT_typesToCopyAutocomplete",
      PT_selectedTypeToCopy: "PT_selectedTypeToCopy",
      PT_copyNewType: "PT_copyNewType"
    })
  },
  computed: {
    defaultType() {
      return this.PT_selectedTypeToCopyGet === false ? "" : this.PT_selectedTypeToCopyGet.full_name
    },
    shortNameRu() {
      return this.PT_nameShortForNewTypeGetRu === false ? "" : this.PT_nameShortForNewTypeGetRu
    },
    shortNameUk() {
      return this.PT_nameShortForNewTypeGetUk === false ? "" : this.PT_nameShortForNewTypeGetUk
    },
    shortNameEn() {
      return this.PT_nameShortForNewTypeGetEn === false ? "" : this.PT_nameShortForNewTypeGetEn
    },
    fullName() {
      return this.PT_nameForNewTypeGet === false ? "" : this.PT_nameForNewTypeGet
    },
    enableCreateButton() {
      let show = false

      if (
        this.PT_nameForNewTypeGet &&
        this.validNameLength &&
        this.validShortNameLength &&
        this.PT_checkUniqueTypeNameGet &&
        this.PT_selectedTypeToCopyGet
      ) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PT_nameForNewTypeGet: "PT_nameForNewType",
      PT_nameShortForNewTypeGetRu: "PT_nameShortForNewTypeRu",
      PT_nameShortForNewTypeGetUk: "PT_nameShortForNewTypeUk",
      PT_nameShortForNewTypeGetEn: "PT_nameShortForNewTypeEn",
      PT_showCopyTypeModalGet: "PT_showCopyTypeModal",
      PT_checkUniqueTypeNameGet: "PT_checkUniqueTypeName",
      PT_selectedTypeToCopyGet: "PT_selectedTypeToCopy",
      PT_typesToCopyAutocompleteGet: "PT_typesToCopyAutocomplete"
    })
  }
}
</script>
<style lang="scss" scoped></style>
