<template>
  <div>
    <Modal
      @close="PB_openSearchTypesModalSet(false)"
      :showModal="PB_openSearchTypesModal"
      :title="'Поиск типов товаров по бренду'"
    >
      <div>
        <template v-if="PB_typesWithGivenBrand.length === 0"> Ничего не найдено </template>
        <template v-else>
          <div>
            Выбранный бренд: <strong>{{ PB_selectedBrand.name }}</strong>
          </div>
          <div>Найден в типах товаров:</div>
          <ul>
            <li v-for="item in PB_typesWithGivenBrand">
              - {{ item.full_name }}
              <span v-if="item.name && item.name !== item.full_name">({{ item.name }})</span>
            </li>
          </ul>
        </template>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "components/Modals/Modal"

import { mapMutations, mapGetters } from "vuex"

export default {
  name: "EditProductModal",
  components: {
    Modal
  },
  methods: {
    ...mapMutations("ProductCatalog", {
      PB_openSearchTypesModalSet: "PB_openSearchTypesModal"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PB_openSearchTypesModal: "PB_openSearchTypesModal",
      PB_typesWithGivenBrand: "PB_typesWithGivenBrand",
      PB_selectedBrand: "PB_selectedBrand"
    })
  }
}
</script>
<style lang="scss" scoped>
ul {
  margin: 0;
  padding-left: 0;
  li {
    list-style-type: none;
  }
}
</style>
