<template>
  <a-table
    :columns="columns"
    :data-source="items"
    row-key="id"
    :pagination="false"
  >
    <template #dateRenderer="text">
      <TableRendererDateTime :date="text" />
    </template>

    <template #codeRenderer="text">
      <div
        :style="{ color: '#1890FF', cursor: 'pointer' }"
        @click="handleCopy(text)"
      >
        {{ text }}
        <a-icon type="copy" />
      </div>
    </template>
  </a-table>
</template>

<script>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import getFormatedSum from "@/utils/getFormatedSum"

export default {
  name: "OrderItems",
  components: {
    TableRendererDateTime
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    columns() {
      return [
        {
          key: "index",
          dataIndex: "index",
          title: "№",
          width: 30,
          customRender: (text, record, index) => index + 1
        },
        {
          key: "created_at",
          dataIndex: "created_at",
          title: this.$t("date"),
          width: 120,
          scopedSlots: { customRender: "dateRenderer" }
        },
        {
          key: "code",
          dataIndex: "code",
          title: this.$t("returnNumber"),
          width: 140,
          scopedSlots: {
            customRender: "codeRenderer"
          }
        },
        {
          key: "aim",
          dataIndex: "aim",
          title: this.$t("aimReasonReturn"),
          customRender: (data) => this.getReturnAim(data)
        },
        {
          key: "price_total",
          dataIndex: "price_total",
          title: this.$t("sum"),
          customRender: (data, record) => {
            return this.$tc("returnProductCountWithSum", record.goods_quantity, {
              count: record.goods_quantity,
              sum: getFormatedSum(record.price_total, true)
            })
          }
        },
        {
          key: "status",
          dataIndex: "status",
          title: this.$t("returnStatus"),
          customRender: (data) => this.getReturnStatus(data)
        }
      ]
    }
  },
  methods: {
    getReturnAim(value) {
      switch (value) {
        case "RETURN_PRODUCT_REFUNDS": {
          return this.$t("productRefunds")
        }
        case "EXCHANGE_PRODUCT": {
          return this.$t("exchangeProduct")
        }
        case "REPAIR_PRODUCT": {
          return this.$t("repair")
        }
      }
    },
    getReturnStatus(status) {
      switch (status) {
        case "NEW": {
          return this.$t("new")
        }
        case "SELLER_APPROVED": {
          return this.$t("orderStatuses.approved")
        }
        case "CANCELED_BY_SELLER": {
          return this.$t("orderStatuses.canceledBy.seller")
        }
        case "CANCELED_BY_CUSTOMER": {
          return this.$t("orderStatuses.canceledBy.customer")
        }
        case "CLOSED": {
          return this.$t("orderStatuses.closed")
        }
      }
    },
    handleCopy(text) {
      navigator.clipboard.writeText(text)

      this.$message.info(this.$t("copied"))
    }
  }
}
</script>
