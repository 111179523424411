<template>
  <div
    :class="[{ [status]: status }]"
    ref="wrap"
  >
    <span v-if="isLabel">{{ label }}</span>

    <div
      class="search-wrapper"
      ref="autocomplete"
      :class="[{ focus: isFocused }, sizeClass]"
    >
      <input
        type="text"
        @focus="focused"
        @blur="blur"
        v-model="input"
        @input="handleInput(input)"
        class="border-reset"
        :class="[{ 'full-size': event == '' || event == 'input' }, sizeClass]"
        :placeholder="placeholder"
        ref="autocompleteInput"
        @keyup.esc="$emit('esc')"
      />
      <span
        :class="{ ['move-loader']: event !== 'click' }"
        class="loader-content"
      >
        <Loader
          size="small"
          :show="load"
        ></Loader>
      </span>
      <input
        v-if="event === 'click'"
        type="submit"
        class="search-btn"
        @click="handleClick(input)"
        value=":)"
      />

      <portal to="autocomplete">
        <transition name="slide-fade">
          <div
            @mousedown.prevent
            v-if="isShow"
            class="autocomplete-content"
            :style="{
              left: `${offset.left}px`,
              top: `${offset.top + elementHeight + elementMargin}px`,
              width: `${elementWidth + 2}px`
            }"
          >
            <span
              v-if="!data.length && event == ''"
              class="description noborder"
              >{{ $t("noData") }}</span
            >
            <span
              v-else-if="input.trim() != '' && !getCount && !load && event != 'click'"
              class="description noborder"
              :class="{ noborder: !getCount }"
              >{{ $t("noMatchesFound") }}</span
            >
            <span
              v-else-if="input.trim() == '' && !getCount && !load && event != 'click'"
              class="description noborder"
              >{{ $t("startPrint") }}</span
            >
            <span
              v-else-if="!getCount && load"
              class="description noborder"
              >{{ $t("loading") }}</span
            >
            <span
              v-else
              class="description"
              :class="{ noborder: !getCount }"
              >{{ $t("matches") }}
              <Badge>{{ getCount }}</Badge>
            </span>
            <ul ref="ul">
              <li
                v-for="(item, index) in targeted"
                :key="`${item.name}_${index}`"
                :class="{ active: index == activeItem, disabledCategory: item.disabledCategory }"
                @click="handleChange(item)"
              >
                <a
                  href
                  @click.prevent
                  >{{ typeof item == "string" ? item : item.name }}</a
                >
              </li>
              <li
                v-if="isNext"
                class="center"
              >
                <span class="description center noborder">
                  <Button
                    :disabled="disabled"
                    @click.stop="emitNext()"
                    size="small"
                    >{{ $t("showMore") }}</Button
                  >
                </span>
              </li>
            </ul>
          </div>
        </transition>
      </portal>
    </div>
  </div>
</template>

<script>
import { getElementOffset } from "../utils/DOM"
import Loader from "./Loader"
import Badge from "./Badge"
import Button from "./Button"
export default {
  props: {
    event: {
      type: String,
      default: ""
    },
    data: {
      default: function () {
        return []
      }
    },
    count: {
      type: Number,
      default: 0
    },
    default: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ""
    },
    defaultValue: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "medium"
    },
    placeholder: {
      type: String,
      default: ""
    },
    reset: {},
    needToLowerCase: {
      type: Boolean,
      default: () => true
    },
    toTrim: {
      type: Boolean,
      default: () => true
    }
  },
  components: {
    Loader,
    Badge,
    Button
  },
  name: "Autocomplete",
  data() {
    return {
      isFocused: false,
      load: false,
      targeted: this.data,
      input: this.defaultValue,
      isShow: false,
      disabled: false,
      offset: {},
      elementHeight: 0,
      elementWidth: 0,
      elementMargin: 5,
      wrap: null,
      activeItem: null
    }
  },
  methods: {
    search() {
      if (this.$refs.search.value != "") this.$emit("search", this.$refs.search.value)
      else return
    },
    focused() {
      if (this.event == "") {
        this.show()
        this.handleInput(this.input)
      }
      if (this.event == "input" && this.input != "") {
        this.isFocused = true
        this.isShow = true
      }
    },
    blur() {
      this.isFocused = false
    },
    handleChange(el) {
      this.hide()
      if (!el.disabledCategory) {
        this.input = typeof el == "string" ? el : el.name
      } else {
        this.input = this.defaultValue
        this.$notification.error({ message: this.$t("categoryNotLast") })
      }
      this.$emit("select", el)
      this.$refs.autocompleteInput.blur()
    },
    handleInput(value) {
      if (this.event == "click") return
      const targetValue = this.needToLowerCase && this.toTrim ? value.toLowerCase().trim() : value
      if (this.event == "input") {
        if (targetValue) {
          this.load = true
          this.$emit("event", targetValue)
          if (targetValue) this.show()
          return
        }
      }
      if (targetValue) this.show()
      this.targeted = this.data.filter((obj) => {
        let item
        if (typeof obj == "string")
          item = this.needToLowerCase && this.toTrim ? obj.toLowerCase().trim() : obj
        else item = this.needToLowerCase && this.toTrim ? obj.name.toLowerCase().trim() : obj.name
        return ~item.indexOf(targetValue)
      })
    },
    handleClick(value) {
      const targetValue = this.needToLowerCase && this.toTrim ? value.toLowerCase().trim() : value
      if (this.event == "click") {
        if (targetValue) {
          this.load = true
          this.$emit("event", targetValue)
          this.show()
        }
        return
      }
      this.targeted = this.data.filter((obj) => {
        const item = this.needToLowerCase && this.toTrim ? obj.name.toLowerCase().trim() : obj.name
        return ~item.indexOf(targetValue)
      })
      if (targetValue) this.show()
    },
    hide(e) {
      if (e && e.target.closest(".autocomplete-content")) return
      this.isShow = false
    },
    show() {
      const el = this.$refs.autocomplete
      if (el) {
        this.offset = getElementOffset(el)
        this.elementHeight = el.clientHeight
        this.elementWidth = el.clientWidth
      }
      this.isShow = true
    },
    scroll() {
      const el = this.$refs.autocomplete
      if (el) {
        this.offset = getElementOffset(el)
        this.elementHeight = el.clientHeight
        this.elementWidth = el.clientWidth
      }
    },
    getSizeClass() {
      let sizeClass = ""
      if (this.size === "small") {
        sizeClass = "small"
      }
      if (this.size === "medium") {
        sizeClass = "medium"
      }
      return sizeClass
    },
    emitNext() {
      this.$emit("nextPage", {
        offset: this.targeted.length,
        value: this.needToLowerCase && this.toTrim ? this.input.toLowerCase().trim() : this.input
      })
      this.load = true
      this.disabled = true
    },
    setScroll() {
      const ref = this.$refs.ul
      ref.scrollTop = this.liHeight * this.activeItem
    },
    handleKeyDown(event) {
      if (this.isShow) {
        if (event.keyCode == 38) {
          event.preventDefault()
          if (this.activeItem == null || this.activeItem == 0) {
            this.activeItem = this.data.length - 1
            this.setScroll()
          } else {
            this.activeItem -= 1
            this.setScroll()
          }
        } else if (event.keyCode == 40) {
          event.preventDefault()
          if (this.activeItem == null || this.activeItem == this.data.length - 1) {
            this.activeItem = 0
            this.setScroll()
          } else {
            this.activeItem += 1
            this.setScroll()
          }
        } else if (event.keyCode == 13) {
          this.handleChange(this.data[this.activeItem])
        }
      }
    }
  },
  computed: {
    isLabel() {
      return this.label !== ""
    },
    sizeClass() {
      return {
        [this.getSizeClass()]: true
      }
    },
    getCount() {
      if (this.count != 0) return this.count
      else return this.targeted.length
    },
    isNext() {
      if (this.count == 0) return false
      else if (this.targeted.length == this.count) return false
      else return true
    },
    liHeight() {
      switch (this.size) {
        case "medium":
          return 41
          break
        case "small":
          return 30
          break
      }
    }
  },
  watch: {
    data(newData) {
      this.load = false
      this.targeted = newData
      this.disabled = false
    },
    default(value) {
      if (this.default) {
        this.data.forEach((obj) => {
          if (obj.id === this.default) {
            this.input = obj.name
            this.$emit("select", obj)
          }
        })
      }
    },
    reset() {
      this.input = ""
    },
    defaultValue(value) {
      this.input = value
    }
  },
  created() {
    document.addEventListener("mousedown", this.hide)
    window.addEventListener("keydown", this.handleKeyDown)
  },
  mounted() {
    const el = this.$refs.wrap.closest(".modal-mask")
    this.wrap = el
    if (this.wrap) {
      ;[this.wrap, window].forEach((node) => node.addEventListener("scroll", this.scroll))
    }
  },
  destroyed() {
    document.removeEventListener("mousedown", this.hide)
    if (this.wrap) {
      ;[this.wrap, window].forEach((node) => node.removeEventListener("scroll", this.scroll))
    }
    window.removeEventListener("keydown", this.handleKeyDown)
  }
}
</script>

<style lang="scss" scoped>
.noborder {
  border-bottom: 0 !important;
}
span {
  display: block;
  margin-bottom: 5px;
  cursor: default;
  color: $black;
}
.search-wrapper {
  display: block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  position: relative;
  margin: 0;
  border: 1px solid $light-border;
  padding: 0;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 0;
}
.error {
  & > span {
    color: $danger-color;
  }
  & > .search-wrapper {
    border: 1px solid $danger-color !important;
  }
}
.success {
  & > span {
    color: $success-color;
  }
  & > .search-wrapper {
    border: 1px solid $success-color !important;
  }
}
.search-wrapper {
  position: relative;
  span {
    display: inline-block;
    margin-bottom: 0;
  }
}
.medium {
  height: 38px;
  line-height: 38px;
}
.small {
  height: 30px;
  line-height: 30px;
}
.border-reset {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  line-height: 36px;
  width: calc(100% - 36px);
  border: 0;
}
.border-reset.medium {
  height: 36px;
  line-height: 36px;
}
.border-reset.small {
  height: 30px;
  line-height: 30px;
}
.focus {
  border-color: $info-color;
}
input {
  box-shadow: none;
}
.full-size {
  width: 100% !important;
}
input[type="submit"].search-btn {
  cursor: pointer;
  width: 34px;
  position: absolute;
  top: 1px;
  right: 1px;
  border: 0;
  background-color: $primary-color;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  height: 34px;
  line-height: 34px;
}
.loader-content {
  position: absolute;
  top: 0;
  right: 38px;
  display: inline-block !important;
}
.move-loader {
  right: 0 !important;
}
.small .autocomplete-content {
  top: 32px;
  & .description {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
}
.autocomplete-content {
  width: calc(100% + 2px);
  box-sizing: border-box;
  height: auto;
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: 40px;
  font-size: 0;
  line-height: 0;
  left: -1px;
  box-shadow: $element-shadow;
  border: 1px solid $light-border;
  background-color: $page-color;
  z-index: 999;
  .active {
    background-color: #f4f4f4;
  }
  & .description {
    height: 40px;
    margin-bottom: 0;
    line-height: 40px;
    width: 100%;
    display: inline-block;
    font-size: $small-font-size;
    font-weight: 700;
    text-transform: uppercase;
    color: $dark;
    padding: 0 20px;
    border-bottom: 1px solid $light-border;
    & span {
      display: inline-block !important;
    }
  }
  & .info {
    height: 40px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: $small-font-size;
    font-weight: 700;
    color: $dark;
    padding: 0 20px;
  }
  & ul {
    padding: 0;
    margin: 0;
    max-height: 245px;
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
      border-radius: 0;
      background-color: $light-border;
    }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: $light-border;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: $dark;
    }
    &::-webkit-scrollbar-thumb:active {
      border-radius: 0;
      background-color: $black;
    }
    li {
      height: auto;
      min-height: 40px;
      border-bottom: 1px solid $light-border;
      &:last-child {
        border: 0;
      }
      a {
        height: auto;
        line-height: 20px;
        padding: 10px 20px;
        display: block;
        word-break: break-word;
        font-size: $small-font-size;
        &:hover {
          background-color: $default-shadow-color;
        }
      }
    }
  }
}
.disabledCategory {
  opacity: 0.5;
}
</style>
