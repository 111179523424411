<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="padding-bottom: 70px"
  >
    <div class="form-items">
      <a-form-model-item
        :label="$t('arrange')"
        prop="locations"
      >
        <a-select
          mode="multiple"
          style="width: 100%"
          v-model="form.locations"
          allowClear
          :options="locationChoices"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('category')"
        prop="category"
      >
        <a-select
          v-model="form.category"
          :options="categories"
          allowClear
          style="width: 100%"
        />
      </a-form-model-item>

      <a-form-model-item
        label="Slug (url)"
        prop="slug"
      >
        <a-input
          v-model="form.slug"
          allowClear
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('sorting')"
        prop="ordering"
        class="form-items__thin-item"
      >
        <a-input-number v-model="form.ordering" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('active')"
        prop="is_active"
        class="form-items__thin-item"
      >
        <a-switch
          v-model="form.is_active"
          :checkedChildren="$t('yes')"
          :unCheckedChildren="$t('no')"
        />
      </a-form-model-item>
    </div>

    <a-tabs v-model="activeLanguage">
      <a-tab-pane
        v-for="lang in allowedLanguages"
        :key="lang"
        :tab="lang.toUpperCase()"
        forceRender
      >
        <a-form-model-item
          :label="`${$t('urlName')} (title)`"
          :prop="`label.${lang}`"
        >
          <a-input v-model="form.label[lang]" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('description')"
          :prop="`description.${lang}`"
        >
          <a-textarea
            v-model="form.description[lang]"
            :rows="4"
          />
        </a-form-model-item>

        <a-form-model-item
          label="Keywords"
          :prop="`keywords.${lang}`"
        >
          <a-textarea
            v-model="form.keywords[lang]"
            :rows="4"
          />
        </a-form-model-item>

        <a-form-model-item
          label="Body"
          :prop="`body.${lang}`"
        >
          <EditorHtml
            v-model="form.body[lang]"
            :useCustomImageHandler="true"
            :id="`page-constructor-${lang}`"
            link
            @image-added="handleImageAdded"
          />
        </a-form-model-item>
      </a-tab-pane>
    </a-tabs>

    <span class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </span>
  </a-form-model>
</template>

<script setup>
import { reactive, ref, computed } from "vue"
import { notification, message } from "ant-design-vue"
import i18n from "@/i18n"
import { IMAGES_CDN } from "@/constants/common.js"
import getImagePath from "utils/getImagePath.js"
import EditorHtml from "@/components/Editor/EditorHtml.vue"

import {
  createPage,
  updatePage,
  uploadPageImages
} from "@/modules/MPAdmin/services/pageBuilderService.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  },
  categories: {
    type: Array,
    default: () => []
  },

  locationChoices: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["updatePage"])

const initialLocalisedObject = {
  uk: "",
  ru: "",
  en: ""
}

const isFetching = ref(false)

const activeLanguage = ref("uk")
const allowedLanguages = ["uk", "ru", "en"]

const formRef = ref()
const form = reactive({
  locations: [...new Set(props.prototype?.locations || [])],
  category: props.prototype?.category?.id || null,
  is_active: props.prototype?.is_active || false,
  slug: props.prototype?.slug || "",
  ordering: props.prototype?.ordering || null,
  label: { ...initialLocalisedObject, ...props.prototype?.label },
  description: { ...initialLocalisedObject, ...props.prototype?.description },
  keywords: { ...initialLocalisedObject, ...props.prototype?.keywords },
  body: { ...initialLocalisedObject, ...props.prototype?.body }
})
const formRules = computed(() => ({
  slug: [requiredRule.value],
  ordering: [requiredRule.value],
  label: {
    uk: [requiredRule.value],
    ru: [requiredRule.value],
    en: [requiredRule.value]
  }
}))

const handleImageAdded = (file, Editor, cursorLocation, resetUploader) => {
  const formData = new FormData()

  formData.append("images", file)
  uploadPageImages({ fd: formData }).then(({ results }) => {
    const url = results[0].images
    const parseUrl = `${IMAGES_CDN}/media/assets/images/${getImagePath(url, "full")}`

    Editor.insertEmbed(cursorLocation, "image", parseUrl)
    resetUploader()
  })
}

const onSave = async () => {
  try {
    await formRef.value.validate()
  } catch {
    message.error(i18n.t("fillFields"))
    return
  }
  isFetching.value = true

  const payload = {
    ...form,
    category: form.category || null,
    type: "GENERAL"
  }

  try {
    if (props.prototype.id) {
      const { data } = await updatePage({ payload, id: props.prototype.id })
      emit("updatePage", data)
    } else {
      await createPage({ payload })
      emit("createPage")
    }

    notification.success({
      message: props.prototype.id ? i18n.t("updated") : i18n.t("created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const requiredRule = computed(() => ({ required: true, message: i18n.t("emptyError") }))
</script>

<style lang="scss" scoped>
.form-items {
  display: flex;
  gap: 16px;

  & > * {
    width: 200px;
  }

  &__thin-item {
    width: fit-content;
  }
}
</style>
