<template>
  <div class="main-wrap">
    <a-icon
      type="info-circle"
      class="info-icon"
      :title="row.description"
      v-if="row.description"
    />
    <a-button
      class="btn-wrap"
      type="primary"
      size="small"
      @click="onEdit"
    >
      <a-icon type="edit" />
    </a-button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex"

export default {
  props: {
    rowIndex: Number,
    field: String,
    row: Object
  },
  data() {
    return {}
  },
  methods: {
    onEdit() {
      this.PA_editableAttrValue(this.$attrs.value)
      this.PA_editableAttrRowIndex(this.rowIndex)

      this.PA_showEditAttributeModal(true)
    },
    ...mapMutations("ProductCatalog", {
      PA_showEditAttributeModal: "PA_showEditAttributeModal",
      PA_editableAttrValue: "PA_editableAttrValue",
      PA_editableAttrRowIndex: "PA_editableAttrRowIndex"
    }),
    ...mapActions("ProductCatalog", {})
  }
}
</script>

<style scoped lang="scss">
.main-wrap {
  width: 100%;
  height: 100%;
  padding: 7px;
  position: absolute;
}
.main-wrap:hover .btn-wrap {
  display: block;
}
.btn-wrap {
  display: none;
  position: absolute;
  top: 5px;
  right: 7px;
  padding: 0 3px 0 4px;
  border-radius: 3px;
  line-height: 19px;
  cursor: pointer;
  span {
    font-size: 12px;
  }
}
.info-icon {
  font-size: 22px;
  line-height: 22px;
  color: $default-border;
  cursor: pointer;
  position: absolute;
  top: 4px;
}
</style>
