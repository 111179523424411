var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"width":720,"body-style":{ paddingBottom: '80px' },"visible":_vm.isOpened,"title":_vm.$t('sellerCreating')},on:{"close":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":_vm.$t('companyName'),"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: _vm.$t('fieldRequire') }]
          }
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: $t('fieldRequire') }]\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('owner')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'owner',
          {
            rules: [{ required: true, message: _vm.$t('fieldRequire') }]
          }
        ]),expression:"[\n          'owner',\n          {\n            rules: [{ required: true, message: $t('fieldRequire') }]\n          }\n        ]"}],staticStyle:{"width":"200px"},attrs:{"show-search":"","placeholder":_vm.$t('enterPhoneNumber'),"optionLabelProp":"","default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":_vm.customersFetching ? undefined : null},on:{"search":_vm.handleSearchCustomers}},[(_vm.customersFetching)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.customers),function(item){return _c('a-select-option',{key:item.uuid},[_vm._v(" "+_vm._s(item.mobile_phone)+" ("+_vm._s(item.username)+" / "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+") ")])})],2)],1),_c('a-form-item',{attrs:{"label":_vm.$t('sellerLogo'),"help":_vm.$t('recommendedSize', { size: '100x100' }),"required":""}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'small_logo',
          {
            rules: [{ required: true, message: _vm.$t('fieldRequire') }]
          }
        ]),expression:"[\n          'small_logo',\n          {\n            rules: [{ required: true, message: $t('fieldRequire') }]\n          }\n        ]"}],attrs:{"name":"small_logo","list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUpload}},[(_vm.logoPreviewUrl.length > 0)?_c('img',{staticStyle:{"width":"100px"},attrs:{"src":_vm.logoPreviewUrl,"alt":"avatar"}}):_c('div',{staticStyle:{"width":"100px","height":"100px","display":"flex","justify-content":"center","align-items":"center"}},[_c('div',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t("upload"))+" ")])])])],1)],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1
    })},[_c('a-button',{key:"back",style:({ marginRight: '8px' }),on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.processing},on:{"click":_vm.handleCreateSeller}},[_vm._v(" "+_vm._s(_vm.$t("create"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }