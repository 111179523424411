import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  AE_attrsAutocomplete: false,
  AE_fetchedAttributes: false,
  AE_attrAutocompleteDefault: "",
  AE_selectedAttr: null,
  AE_selectedAttrSearchConflict: false,
  userName: null,

  AE_wrongStatusModal: false,
  AE_wrongStatusAttr: false,
  AE_conversionInProgressModal: false,

  possibleUnits: [],
  blockSearch: false
})

const mutations = {
  AE_attrsAutocomplete(state, value) {
    state.AE_attrsAutocomplete = value
  },
  AE_fetchedAttributes(state, value) {
    state.AE_fetchedAttributes = value
  },
  AE_attrAutocompleteDefault(state, value) {
    state.AE_attrAutocompleteDefault = value
  },
  AE_selectedAttr(state, value) {
    state.AE_selectedAttr = value
  },
  AE_selectedAttrSearchConflict(state, value) {
    state.AE_selectedAttrSearchConflict = value
  },
  userName(state, value) {
    state.userName = value
  },
  AE_resetSearchValues(state) {
    state.AE_attrsAutocomplete = []
    state.AE_fetchedAttributes = false
    state.AE_attrAutocompleteDefault = " "
    setTimeout(() => {
      state.AE_attrAutocompleteDefault = ""
    }, 0)
    state.AE_selectedAttr = false
    state.AE_selectedAttrSearchConflict = false
    state.userName = null
    state.AE_wrongStatusAttr = false
    state.possibleUnits = []
    state.blockSearch = false
  },
  AE_resetSearchWithoutAutocomplete(state) {
    state.AE_attrsAutocomplete = []
    state.AE_fetchedAttributes = false
    state.AE_selectedAttr = false
    state.AE_selectedAttrSearchConflict = false
    state.userName = null
    state.AE_wrongStatusAttr = false
    state.possibleUnits = []
    state.blockSearch = false
  },

  AE_wrongStatusModal(state, value) {
    state.AE_wrongStatusModal = value
  },
  AE_wrongStatusAttr(state, value) {
    state.AE_wrongStatusAttr = value
  },
  AE_conversionInProgressModal(state, value) {
    state.AE_conversionInProgressModal = value
  },
  possibleUnits(state, value) {
    state.possibleUnits = value
  },
  blockSearch(state, value) {
    state.blockSearch = value
  }
}

const actions = {
  async AE_fetchAttributes({ commit, dispatch }, value) {
    try {
      let searchData = buildMagicSearch({
        resource: "AND",
        field: "name",
        value,
        sort: null,
        limit: 20,
        offset: 0
      })
      return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
        .then((r) => r.data.message.data)
        .then((attrs) => {
          commit("AE_fetchedAttributes", attrs)
          let attrsArray = attrs.map((item) => {
            return item.name
          })
          commit("AE_attrsAutocomplete", attrsArray)
          return attrs
        })
        .catch((e) => {
          e.$_actionName = "AE_fetchAttributes"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    }
  },
  async AE_setSelectedAttr({ commit, state, dispatch }, value) {
    let selected = state.AE_fetchedAttributes.find((item) => {
      return item.name === value
    })

    commit("AE_resetSearchWithoutAutocomplete")
    commit("resetTableValues")
    commit("resetConversionValues")

    // check status
    if (selected.status == "IDLE" || selected.type === "URL") {
      commit("AE_wrongStatusAttr", selected)
      // open modal
      commit("AE_wrongStatusModal", true)
      //commit('AE_resetSearchValues');
      return
    }
    // set
    commit("AE_selectedAttr", selected)
    //commit('AE_selectedValueType', selected.value_type);
    commit("blockSearch", false)
  },
  async AE_processSelectedAttr({ commit, state }) {
    let sendData = {
      attribute: state.AE_selectedAttr.code
    }
    return API_MODERATION.post(`${apiUrls.attributeEdit}`, sendData)
      .then((r) => r.data.message.task_id)
      .then((r) => {
        return r
      })
      .catch((e) => {
        if (e.response && e.response.status && e.response.status == 409) {
          commit("AE_selectedAttrSearchConflict", e.response.data.message)
          return 409
        } else if (
          e.response &&
          e.response.status &&
          e.response.status == 404 &&
          (e.response.data.message === "Attribute not used in products" ||
            e.response.data.message === "Attribute not found")
        ) {
          return e.response.data.message
        }
        e.$_actionName = "AE_processSelectedAttr"
        return Promise.reject(e)
      })
  },
  async unitsByAttr({ commit, state, dispatch }, selectedAttr) {
    try {
      commit("ProductCatalog/PC_showLoader", true, { root: true })
      let params = {
        code: selectedAttr.code,
        complex: true
      }
      return API_MODERATION.get(`${apiUrls.attribute}`, { params })
        .then((r) => r.data.message.records[0].possible_units)
        .then((r) => {
          commit("possibleUnits", r)
          return r
        })
        .catch((e) => {
          e.$_actionName = "AE_fetchPossibleUnits"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    } finally {
      commit("ProductCatalog/PC_showLoader", false, { root: true })
    }
  },
  async userNameById({ commit, state, dispatch }) {
    let id = state.AE_selectedAttrSearchConflict.user_id
    return API_MODERATION.get(`${apiUrls.userName}/${id}`)
      .then((r) => r.data.username)
      .then((r) => {
        commit("userName", r)
        return r
      })
  },
  async EA_removeTempTable({ state }, selectedAttrCode) {
    let params = {
      attribute: selectedAttrCode
    }
    return API_MODERATION.delete(`${apiUrls.attributeEdit}`, { params })
      .then((r) => r.data)
      .then((r) => {
        return r
      })
      .catch((e) => {
        e.$_actionName = "EA_removeTempTable"
        return Promise.reject(e)
      })
  },
  async fetchPossibleUnits({ commit, state, dispatch }, item) {
    if (item.new_value_type == "NUMERIC") {
      //search possible units by new_default_unit
      let unitObject = await dispatch("searchUnit", item.new_default_unit)
      await dispatch("unitsByBase", unitObject)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
