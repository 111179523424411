import { computed } from "vue"
import i18n from "@/i18n"

const useRRColumns = () =>
  computed(() => [
    {
      key: "name_uk",
      title: "UA",
      dataIndex: "name.uk",
      fixed: "left",
      width: 300
    },
    {
      key: "name_ru",
      dataIndex: "name.ru",
      title: "RU",
      width: 300
    },
    {
      key: "name_en",
      dataIndex: "name.en",
      title: "EN",
      width: 300
    },
    {
      key: "ordering",
      dataIndex: "ordering",
      title: i18n.t("outputSortOrder"),
      width: 230
    },
    {
      key: "help",
      dataIndex: "help",
      title: i18n.t("help"),
      width: 300
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 100
    }
  ])

export default useRRColumns
