export const ATTRIBUTE_VALUE_REQUEST_FORMATTERS = {
  MULTILIST: (value) => (value.length ? value?.map((item) => item.code) : null),
  LIST: (value) => value?.code || null,
  NUMERIC: (value) => {
    if (value.value === null || value.value === undefined) return null
    if (typeof value.value === "number") return value.value
    if (typeof value.value === "string") {
      const parsedValue = parseFloat(value.value)
      return isNaN(parsedValue) ? null : parsedValue
    }

    return null
  },
  BOOLEAN: (value) => (typeof value.value === "boolean" ? value.value : null),
  default: (value) => (value?.value ? value?.value.toString() : null)
}

export const formatValuesForRequest = (attributes) => {
  const formatedAttributes = attributes.map(({ code, unit, value, value_type }) => {
    const getValue =
      ATTRIBUTE_VALUE_REQUEST_FORMATTERS[value_type] || ATTRIBUTE_VALUE_REQUEST_FORMATTERS.default

    return { code, unit: unit?.code || null, value: getValue(value) }
  })

  return formatedAttributes
}
