<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "ADS",

  metaInfo: {
    title: "ADS"
  },

  methods: {
    ...mapActions("Auth", ["logout"])
  },

  provide() {
    return {
      logout: this.logout
    }
  }
}
</script>
