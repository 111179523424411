<template>
  <div>
    <Modal
      :title="`Конвертация ${AE_selectedAttr.value_type} атрибута в тип значений ${newValueType}`"
      :showCloseButton="false"
      :showModal="AE_conversionBooleanModalGet"
      @close="closeModal"
    >
      <p class="convert-attr__explain">Сопоставление значения:</p>
      <div class="convert-attr">
        <div class="convert-attr__item">
          <span class="convert-attr__label">TRUE</span>
          <input
            class="convert-attr__input"
            :placeholder="
              newValueType === 'BOOLEAN'
                ? 'Введите значения через запятую'
                : 'Введите одно значение'
            "
            v-model="valueTrue"
          />
        </div>
        <div class="convert-attr__item">
          <span class="convert-attr__label">FALSE</span>
          <input
            class="convert-attr__input"
            :placeholder="
              newValueType === 'BOOLEAN'
                ? 'Введите значения через запятую'
                : 'Введите одно значение'
            "
            v-model="valueFalse"
          />
        </div>
        <ValidationErrors
          :repetativeValuesError="repetativeValuesError"
          :emptyError="!$v.valueTrue.required || !$v.valueFalse.required"
        />
      </div>

      <div
        slot="footer"
        class="footer"
      >
        <Button
          class="footer_btn"
          @click="closeModal"
          theme="secondary"
        >
          Закрыть
        </Button>
        <Button
          :disabled="disabledConvertButton"
          @click="startConverting"
          class="footer_btn"
          >Подтвердить</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapState } from "vuex"
import ValidationErrors from "components/ValidationErrors/ValidationErrors"
import { required } from "vuelidate/lib/validators"

export default {
  components: {
    Button,
    Modal,
    ValidationErrors
  },
  data() {
    return {
      valueTrue: "",
      valueFalse: ""
    }
  },
  computed: {
    ...mapState("ProductCatalog", {
      celeryInterval: (state) => state.celeryInterval
    }),
    ...mapState("ProductCatalog/attributesEdit", {
      AE_conversionBooleanModalGet: (state) => state.conversion.AE_conversionBooleanModal,
      AE_selectedAttr: (state) => state.search.AE_selectedAttr
    }),

    disabledConvertButton() {
      return (
        this.repetativeValuesError || !this.$v.valueTrue.required || !this.$v.valueFalse.required
      )
    },
    newValueType() {
      return this.AE_selectedAttr.value_type !== "BOOLEAN" ? "BOOLEAN" : "STRING"
    },
    repetativeValuesError() {
      const arrayTrue = this.valueTrue.split(",")
      const arrayFalse = this.valueFalse.split(",")

      const repeat = arrayTrue.find((val) => {
        return arrayFalse.find((v) => {
          return v.trim() == val.trim()
        })
      })

      return this.valueTrue || this.valueFalse
        ? this.valueFalse === this.valueTrue || repeat
        : false
    }
  },
  methods: {
    ...mapMutations("ProductCatalog/attributesEdit", {
      AE_conversionBooleanModal: "AE_conversionBooleanModal"
    }),
    ...mapActions("ProductCatalog/attributesEdit", {
      AE_CSB_DoConvertation: "AE_CSL_DoConvertation",
      AE_tableSearch: "AE_tableSearch"
    }),
    ...mapMutations("ProductCatalog", {
      PC_showLoader: "PC_showLoader",
      handleError: "handleError"
    }),
    ...mapActions("ProductCatalog", {
      SH_checkTask: "SH_checkTask"
    }),
    closeModal() {
      this.AE_conversionBooleanModal(false)
      this.resetValues()
    },
    resetValues() {
      this.valueTrue = ""
      this.valueFalse = ""
    },
    async startConverting() {
      const payload = {
        attribute: this.AE_selectedAttr.code,
        bool_true: this.valueTrue,
        bool_false: this.valueFalse,
        new_value_type: this.newValueType
      }

      try {
        let response = await this.AE_CSB_DoConvertation(payload)
        let id = response.data.message.task_id
        this.checkWithInterval(id, this.AE_selectedAttr.code)
      } catch (e) {
        // commit('ProductCatalog/handleError', e, {root: true});
      } finally {
        this.closeModal()
      }
    },
    async checkWithInterval(id, selectedAttrCode) {
      this.interval = setInterval(async () => {
        let response = await this.SH_checkTask(id)
        response = response[0]

        if (response.task_state === "SUCCESS" || response.task_state === "FAILURE") {
          this.PC_showLoader(false)
          clearInterval(this.interval)
        }
        if (response.task_state === "SUCCESS") {
          await this.AE_tableSearch({ selectedAttrCode })
          this.$notification.info({
            message:
              'Конвертация успешна. Изменения будут добавлены после исправления всех ошибок и нажатия кнопки "Применить"'
          })
          this.closeModal()
        }
        if (response.task_state === "FAILURE") {
          this.$notification.error({ message: `Ошибка соединения: ${response.error_msg}` })
          this.closeModal()
        }
      }, this.celeryInterval)
    }
  },
  validations: {
    valueTrue: {
      required
    },
    valueFalse: {
      required
    }
  }
}
</script>
<style lang="scss" scoped>
.convert-attr {
  display: block;
  &__label {
    width: 50px;
  }

  &__explain {
    color: rgba(0, 0, 0, 0.54);
  }

  &__input {
    margin-left: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &_btn {
    margin-left: 20px;
  }
}
</style>
