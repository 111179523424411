import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import regeneratorRuntime from "regenerator-runtime"
import { notification } from "ant-design-vue"

const state = () => ({
  PT_showCreateTypeModal: false,
  PT_nameForNewType: false,
  PT_nameShortForNewTypeRu: false,
  PT_nameShortForNewTypeUk: false,
  PT_nameShortForNewTypeEn: false,
  PT_synonymsForNewType: false
})

const getters = {
  PT_showCreateTypeModal: (state) => state.PT_showCreateTypeModal,
  PT_nameForNewType: (state) => state.PT_nameForNewType,
  PT_nameShortForNewTypeRu: (state) => state.PT_nameShortForNewTypeRu,
  PT_nameShortForNewTypeUk: (state) => state.PT_nameShortForNewTypeUk,
  PT_nameShortForNewTypeEn: (state) => state.PT_nameShortForNewTypeEn,
  PT_synonymsForNewType: (state) => state.PT_synonymsForNewType
}

const mutations = {
  PT_showCreateTypeModal(state, value) {
    state.PT_showCreateTypeModal = value
  },
  PT_nameForNewType(state, value) {
    state.PT_nameForNewType = value
  },
  PT_nameShortForNewTypeRu(state, value) {
    state.PT_nameShortForNewTypeRu = value
  },
  PT_nameShortForNewTypeUk(state, value) {
    state.PT_nameShortForNewTypeUk = value
  },
  PT_nameShortForNewTypeEn(state, value) {
    state.PT_nameShortForNewTypeEn = value
  },
  PT_synonymsForNewType(state, value) {
    state.PT_synonymsForNewType = value
  }
}

const actions = {
  async PT_createNewType({ commit, getters, dispatch }) {
    // if it's not processing already
    if (getters.PC_showLoader) return
    // show loader
    commit("PC_showLoader", true)

    let synonyms = []
    if (getters.PT_synonymsForNewType !== false) {
      synonyms = getters.PT_synonymsForNewType.split(",").map((item) => {
        return item.trim()
      })
    }

    let type = {
      full_name: getters.PT_nameForNewType
    }
    if (getters.PT_nameShortForNewTypeRu !== false) type.name = getters.PT_nameShortForNewTypeRu
    if (getters.PT_nameShortForNewTypeUk !== false) type.name_uk = getters.PT_nameShortForNewTypeUk
    if (getters.PT_nameShortForNewTypeEn !== false) type.name_en = getters.PT_nameShortForNewTypeEn
    if (synonyms.length && synonyms[0] !== false) type.synonyms = synonyms
    // start request
    return API_MODERATION.post(`${apiUrls.type}`, type)
      .then((r) => r.data.message)
      .then((type) => {
        restartTableSearch({ getters, dispatch })

        // hide loader
        commit("PC_showLoader", false)
        // reset values
        dispatch("PT_resetCreateTypeValues")

        notification.info({ message: "Новый тип добавлен" })
        return type
      })
      .catch((e) => {
        // hide loader
        commit("PC_showLoader", false)

        if (e.response.status === 409) {
          console.log(e.message)
          notification.error({ message: "Значение уже есть в базе" })
        } else {
          commit("handleError", e)
        }
      })
  },
  PT_resetCreateTypeValues({ commit }) {
    commit("PT_nameForNewType", false)
    commit("PT_nameShortForNewTypeRu", false)
    commit("PT_nameShortForNewTypeUk", false)
    commit("PT_nameShortForNewTypeEn", false)
    commit("PT_synonymsForNewType", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

function restartTableSearch({ getters, dispatch }) {
  dispatch("PC_searchTypes", getters.PT_typesTableQueryParams)
}
