<template>
  <div>
    <PageHeader :title="$t('catalogModeration')" />

    <div class="categories-container">
      <CategoriesList :parent="null" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/ant-components/PageHeader/index.js"

import CategoriesList from "./CategoriesList.vue"

export default {
  metaInfo: {
    title: "Модерація каталогу"
  },

  components: {
    PageHeader,
    CategoriesList
  }
}
</script>

<style lang="scss" scoped>
.categories-container {
  border: 1px solid $light-border;
  border-radius: 4px;
  box-shadow: $light-shadow;

  position: relative;
}
</style>
