<template>
  <span class="select-wrapper">
    <!-- <template v-if="row.value_type !== 'LIST' && row.value_type !== 'MULTILIST' && row.value_type !== 'BOOLEAN'">
      <Select
        @change="onChange"
        :values="selectValues"
        :selected="row.value_type"
        :disabled="disabled"
      />
       v-if="row.value_type === 'MULTILIST' || row.value_type === 'BOOLEAN'"
    </template> -->
    <!-- <template> -->
    <Select
      :values="selectDisabled"
      :selected="row.value_type"
      :disabled="true"
    />
    <!-- </template> -->
    <!-- <template v-if="row.value_type === 'LIST'">
      <Select
        @change="onChange"
        :values="selectList"
        :selected="row.value_type"
        :disabled="disabled"
      />
    </template> -->
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Select from "components/Select/Select"

export default {
  components: {
    Select
  },
  props: {
    rowIndex: Number,
    field: String,
    row: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // selectValues: [
      //   {
      //     label: 'NUMERIC',
      //     value: 'NUMERIC'
      //   },
      //   {
      //     label: 'STRING',
      //     value: 'STRING'
      //   },
      //   {
      //     label: 'BOOLEAN',
      //     value: 'BOOLEAN'
      //   },
      //   {
      //     label: 'URL',
      //     value: 'URL'
      //   },
      // ],
      selectDisabled: [
        {
          label: "MULTILIST",
          value: "MULTILIST"
        },
        {
          label: "BOOLEAN",
          value: "BOOLEAN"
        },
        {
          label: "NUMERIC",
          value: "NUMERIC"
        },
        {
          label: "STRING",
          value: "STRING"
        },
        {
          label: "BOOLEAN",
          value: "BOOLEAN"
        },
        {
          label: "URL",
          value: "URL"
        },
        {
          label: "LIST",
          value: "LIST"
        },
        {
          label: "TEMP",
          value: "TEMP"
        }
      ]
      // selectList: [
      //   {
      //     label: 'LIST',
      //     value: 'LIST'
      //   },
      //   {
      //     label: 'MULTILIST',
      //     value: 'MULTILIST'
      //   }
      // ]
    }
  },
  methods: {
    onChange(value) {
      // update on server
      this.PA_editAttributesType({ row: this.row, newValue: value, rowIndex: this.rowIndex })
    },
    ...mapActions("ProductCatalog", {
      PA_editAttributesType: "PA_editAttributesType"
    })
  }
}
</script>
<style>
span.select-wrapper {
  display: inline-block;
  width: 100%;

  /*margin-bottom: -10px;*/
}
span.select-wrapper div[class*="select"] {
  margin-bottom: 0 !important;
}
</style>
