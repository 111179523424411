<template>
  <a-drawer
    :visible="isOpened"
    :title="$t('editInvoiceModalTitle', { number: invoiceData.code })"
    :width="640"
    @close="handleCancel"
    :body-style="{ paddingBottom: '80px' }"
  >
    <!-- Seller name START -->
    <a-row>
      <a-col :span="12">
        {{ $t("seller") }}
      </a-col>
      <a-col :span="12">
        <img
          :src="sellerLogo"
          style="width: 32px; margin-right: 8px"
        />
        {{ invoiceData.seller }}
      </a-col>
    </a-row>
    <!-- Seller name END -->

    <a-divider>
      {{ $t("invoiceEditInvoiceDataTitle") }}
    </a-divider>

    <!-- Invoice data START -->
    <div
      style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr; grid-row-gap: 15px"
    >
      <a-row :gutter="24">
        <a-col :span="12"> {{ $t("payer") }}: </a-col>
        <a-col :span="12">
          {{ invoiceData.payer }}
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="12"> {{ $t("sellerTypePlaceholder") }}: </a-col>
        <a-col :span="12">
          <span v-if="invoiceData.seller_type === 'FN'">{{ $t("sellerTypePib") }}</span>
          <span v-if="invoiceData.seller_type === 'IE'">{{ $t("sellerTypeFop") }}</span>
          <span v-if="invoiceData.seller_type === 'PLC'">{{ $t("sellerTypeTov") }}</span>
        </a-col>
      </a-row>

      <a-row
        v-if="!isPib"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("isVatPayerFull") }}: </a-col>
        <a-col :span="12">
          {{ invoiceData.is_vat_payer ? $t("yes") : $t("no") }}
        </a-col>
      </a-row>

      <a-row
        v-if="!isPib"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("edrpou_ipn") }}: </a-col>
        <a-col :span="12">
          {{ invoiceData.edrpou }}
          <span v-if="invoiceData.ipn && invoiceData.ipn.length > 0">
            / {{ invoiceData.ipn }}
          </span>
        </a-col>
      </a-row>

      <a-row
        v-if="invoiceData.amount_with_vat"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("invoiceSumFull") }}: </a-col>
        <a-col :span="12">
          {{ getFormatedSum(invoiceData.amount_with_vat.toFixed(2)) }}
          / {{ getFormatedSum(invoiceData.amount_without_vat.toFixed(2)) }} /
          {{ getFormatedSum(invoiceData.vat.toFixed(2)) }}
        </a-col>
      </a-row>

      <a-row
        v-if="showAddress"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("invoiceAdressFull") }}: </a-col>
        <a-col :span="12">
          <span v-if="invoiceData.postal_code">{{ invoiceData.postal_code }}</span>
          <span v-if="invoiceData.region_name">, {{ invoiceData.region_name }}&nbsp;</span>
          <span v-if="invoiceData.city_name">, {{ invoiceData.city_name }}</span>
          <span v-if="invoiceData.street_name">, {{ invoiceData.street_name }}</span>
          <span v-if="invoiceData.house_number">, {{ invoiceData.house_number }}</span>
          <span v-if="invoiceData.cabinet">, {{ invoiceData.cabinet }}</span>
        </a-col>
      </a-row>
    </div>
    <!-- Invoice data END -->

    <a-divider>
      {{ $t("invoiceStatus") }}
    </a-divider>

    <!-- Paid invoice data START -->
    <div v-if="invoiceData.status === 'PAID'">
      <a-row>
        <a-col :span="12"> {{ $t("invoiceStatus") }}: </a-col>
        <a-col
          :span="12"
          style="color: #52c41a"
        >
          {{ $t("paid") }}
        </a-col>
      </a-row>

      <a-divider>
        {{ $t("invoiceManagerDataDivider") }}
      </a-divider>

      <div
        style="
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          grid-row-gap: 15px;
          margin-bottom: 15px;
        "
      >
        <a-row>
          <a-col :span="12"> {{ $t("invoicePaymentDateTime") }}: </a-col>
          <a-col :span="12">
            {{ getFormatedDateTime(invoiceData.paid_at) }}
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12"> {{ $t("invoicePaidSum") }}: </a-col>
          <a-col :span="12"> {{ getFormatedSum(invoiceData.amount_with_vat) }} грн </a-col>
        </a-row>

        <a-row>
          <a-col :span="12"> {{ $t("invoiceNumber") }}: </a-col>
          <a-col :span="12">
            {{ invoiceData.code }}
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12"> {{ $t("payerFactual") }}: </a-col>
          <a-col :span="12">
            {{ invoiceData.payer_factual }}
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- Paid invoice data END -->

    <!-- Invoice form START -->
    <a-form
      :form="form"
      :label-col="{ span: 12 }"
      :wrapper-col="{ span: 12 }"
      labelAlign="left"
    >
      <!-- Show when invoice status 'PAID' after change in select, not when default invoice status is equal to 'PAID' -->
      <div v-if="invoiceData.status !== 'PAID'">
        <a-form-item :label="$t('invoiceStatus')">
          <a-select
            v-decorator="['status']"
            autocomplete="off"
          >
            <a-select-option key="NEW">
              <span style="color: #1890ff">{{ $t("new") }}</span>
            </a-select-option>
            <a-select-option key="CANCELED">
              <span style="color: #ff4d4f">{{ $t("canceledStatus") }}</span>
            </a-select-option>
            <a-select-option key="REMOVED">
              <span style="opacity: 0.45">{{ $t("deleted") }}</span>
            </a-select-option>
            <a-select-option key="PAID">
              <span style="color: #52c41a">{{ $t("paid") }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>

      <!-- Show when invoice status 'PAID' after change in select, not when default invoice status is equal to 'PAID' -->
      <div v-if="invoiceStatus === 'PAID' && invoiceData.status !== 'PAID'">
        <!-- Paid datetime START -->
        <a-form-item :label="$t('invoicePaymentDateTime')">
          <a-input
            v-decorator="[
              'paid_at',
              {
                rules: [
                  { required: true, message: $t('fieldRequire') },
                  {
                    pattern:
                      /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(20)\d{2}[ ]{1}([0-1]?[0-9]|[2][0-3]):([0-5][0-9])$/g,
                    message: $t('errorInvoiceDateTime')
                  }
                ]
              }
            ]"
            autocomplete="off"
            placeholder="DD.MM.YYYY HH:mm"
            @paste="onPaste"
          />
        </a-form-item>
        <!-- Paid datetime END -->

        <!-- Payment sum START -->
        <a-form-item :label="$t('invoicePaidSum')">
          <a-input
            v-decorator="[
              'payment_amount',
              {
                rules: [{ required: true, message: $t('fieldRequire') }]
              }
            ]"
            autocomplete="off"
            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
            :precision="2"
            decimal-separator=","
            style="width: 100%"
            @paste="onPaste"
          />
        </a-form-item>
        <!-- Payment sum END -->

        <!-- Payer START -->
        <a-form-item :label="$t('payerFactual')">
          <a-input
            v-decorator="[
              'payer_factual',
              {
                rules: [{ required: true, message: $t('fieldRequire') }]
              }
            ]"
            autocomplete="off"
            @paste="onPaste"
          />
        </a-form-item>
        <!-- Payer END -->

        <!-- Invoice number START -->
        <a-form-item :label="$t('invoiceNumber')">
          <a-input
            v-decorator="[
              'code',
              {
                rules: [{ required: true, message: $t('fieldRequire') }]
              }
            ]"
            autocomplete="off"
            @paste="onPaste"
          />
        </a-form-item>
        <!-- Invoice number END -->
      </div>

      <a-form-item
        :label="$t('comment')"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
      >
        <a-textarea
          v-decorator="[
            'comment',
            {
              rules: [{ max: 500, message: $t('maxLengthError', { length: 500 }) }]
            }
          ]"
          autocomplete="off"
        />
      </a-form-item>
    </a-form>
    <!-- Invoice form END -->

    <div class="drawer-footer">
      <a-button
        key="back"
        @click="handleCancel"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleEditInvoice"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment"
import getImagePath from "@/utils/getImagePath"
import getFormatedSum from "@/utils/getFormatedSum"
import { IMAGES_CDN } from "@/constants/common.js"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { updateInvoice } from "@/modules/MPAdmin/services/invoicesService.js"

export default {
  name: "EditInvoiceModal",
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    invoiceData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      invoiceStatus: "",
      processing: false,
      form: this.$form.createForm(this, {
        name: "editInvoice",
        onValuesChange: (_, values) => {
          if (values.status) {
            this.invoiceStatus = values.status

            const expire = moment(this.invoiceData.expire_at)
            const now = moment(new Date())

            if (values.status === "PAID" && now.diff(expire, "seconds") > 0) {
              this.$notification.error({
                message: this.$t("invoiceOverdue")
              })
            }
          }
        }
      })
    }
  },
  computed: {
    issuedDateDiff() {
      const expire = moment(this.invoiceData.expire_at)
      const now = moment(new Date())

      return expire.diff(now, "seconds")
    },
    sellerLogo() {
      return (
        IMAGES_CDN +
        "/media/assets/images/" +
        getImagePath(this.invoiceData.small_logo || "", "full")
      )
    },
    isPib() {
      return this.invoiceData.seller_type === "FN"
    },
    isVatPayer() {
      return this.invoiceData.is_vat_payer
    },
    showAddress() {
      return this.invoiceData.seller_type === "IE" || this.invoiceData.seller_type === "PLC"
    }
  },
  watch: {
    isOpened(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          if (this.invoiceData.status === "PAID") {
            this.form.setFieldsValue({
              comment: this.invoiceData.comment
            })
          } else {
            this.form.setFieldsValue({
              status: this.invoiceData.status,
              comment: this.invoiceData.comment
            })
          }

          this.invoiceStatus = this.invoiceData.status
        })
      } else {
        this.invoiceStatus = ""
      }
    }
  },
  methods: {
    getFormatedDateTime,
    getFormatedSum,
    async handleEditInvoice() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.processing = true

          let payload = {}

          if (values.status === "PAID") {
            payload = {
              ...values,
              status: values.status,
              paid_at: moment(values.paid_at, "DD.MM.YYYY HH:mm").utc(),
              comment: values.comment ? values.comment : ""
            }
          } else if (this.invoiceData.status === "PAID") {
            payload = {
              status: "PAID",
              comment: values.comment ? values.comment : ""
            }
          } else {
            payload = {
              ...values,
              status: values.status,
              comment: values.comment ? values.comment : ""
            }
          }

          try {
            const { data } = await updateInvoice({ uuid: this.invoiceData.uuid, payload })

            this.$notification.success({
              message: this.$t("invoiceUpdateSuccess")
            })

            this.$emit("onUpdate", {
              uuid: this.invoiceData.uuid,
              ...data
            })

            this.$emit("close")
          } catch (error) {
            notifyResponseError({ error, message: this.$t("invoiceUpdateError") })
          } finally {
            this.processing = false
          }
        }
      })
    },

    handleCancel() {
      this.$emit("close")

      this.selectedSellerLogo = ""
    },

    onPaste(event) {
      const badPayers = ["АКЦIОНЕРНЕ ТОВАРИСТВО", "Транз.рахунок платежi_ DN, DG, DZ"]
      const paste = (event.clipboardData || window.clipboardData).getData("text")

      const date = paste.match(
        /((0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(20)\d{2}[ ]{1}([0-1]?[0-9]|[2][0-3]):([0-5][0-9]))/gm
      )
      const amount = paste.match(/[ ][\d\s]{1,90}(:?\.\d{2})/gm)
      const payerFactual = paste.match(/(?<=UA\d{27}\n)(\D{1,100})(?=\n)/gm)
      const orderNumber = paste.match(/\d{3}-\d{5,11}/gm)

      this.$nextTick(() => {
        if (date && date.length > 0) {
          event.preventDefault() // prevent double insert
          this.form.setFieldsValue({ paid_at: date[0] })
        }

        if (amount && amount.length > 0) {
          event.preventDefault() // prevent double insert
          this.form.setFieldsValue({ payment_amount: amount[0].replace(/\s/g, "") })
        }

        if (payerFactual && payerFactual.length > 0) {
          // Check if payer include badPayer string
          if (badPayers.findIndex((item) => payerFactual[0].search(item) !== -1) === -1) {
            event.preventDefault() // prevent double insert
            this.form.setFieldsValue({ payer_factual: payerFactual[0] })
          }
        }

        if (orderNumber && orderNumber.length > 0) {
          event.preventDefault() // prevent double insert
          this.form.setFieldsValue({ code: orderNumber[0] })
        }
      })
    }
  }
}
</script>
