<template>
  <div>
    <p v-if="lengthError">
      {{ $t("lengthError") }}
    </p>
    <p v-if="maxLengthError.flag">
      {{ $t("maxLengthError", { length: maxLengthError.length }) }}
    </p>
    <p v-if="minLengthError.flag">
      {{ $t("minLengthError", { length: minLengthError.length }) }}
    </p>
    <p v-if="wantedLengthError.flag">
      {{ $t("wantedLengthError", { length: wantedLengthError.length }) }}
    </p>
    <p v-if="wrongLengthError.flag">
      {{ $t("mustBe") }}
      <strong
        v-for="(length, index) in wrongLengthError.lengthArray"
        :key="length"
      >
        {{ length }}
        <span v-if="index === wrongLengthError.lengthArray.length - 2"
          >{{ $t("orLowerCased") }}
        </span>
        <span v-else-if="index < wrongLengthError.lengthArray.length - 1">, </span> </strong
      >{{ $t("symbolsLowerCased") }}
    </p>
    <p v-if="emptyError">
      {{ $t("emptyError") }}
    </p>
    <p v-if="uniqueError">
      {{ $t("alreadyInDataBaseError") }}
    </p>
    <p v-if="quantityError">
      {{ $t("valueQuantityError") }}
    </p>
    <p v-if="repetativeValuesError">
      {{ $t("repetativeValuesError") }}
    </p>
    <p v-if="urlError">
      {{ $t("urlError") }}
    </p>
    <p v-if="floatError">
      {{ $t("floatError") }}
    </p>
    <p v-if="numberError">
      {{ $t("numberError") }}
    </p>
    <p v-if="cyrillicError">
      {{ $t("cyrillicError") }}
    </p>
    <p v-if="formatEan13Error">
      {{ $t("formatEan13Error") }}
    </p>
    <p v-if="phoneError">
      {{ $t("phoneFormatError") }}
    </p>
    <p v-if="wrongGtinError">
      {{ $t("wrongGtinError") }}<strong>98, 99, 02, 04 {{ $t("orLowerCased") }} 2</strong>
    </p>
    <p v-if="mustBeSame">
      {{ $t("mustBeSameError") }}
    </p>
    <p v-if="mustBePositiv">
      {{ $t("mustBePositivError") }}
    </p>
    <p v-if="nameOrModelRequired">
      {{ $t("nameOrModelRequiredError") }}
    </p>
    <p v-if="dateError">Invalid date{{ $t("dateError") }}</p>
    <p v-if="timeError">
      {{ $t("timeError") }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    lengthError: {
      type: Boolean,
      default: false
    },
    maxLengthError: {
      type: Object,
      default: () => {
        return {
          flag: false,
          length: 0
        }
      }
    },
    minLengthError: {
      type: Object,
      default: () => {
        return {
          flag: false,
          length: 0
        }
      }
    },
    wantedLengthError: {
      type: Object,
      default: () => {
        return {
          flag: false,
          length: 0
        }
      }
    },
    wrongLengthError: {
      type: Object,
      default: () => {
        return {
          flag: false,
          lengthArray: []
        }
      }
    },
    wrongGtinError: {
      type: Boolean,
      default: false
    },
    emptyError: {
      type: Boolean,
      default: false
    },
    nameOrModelRequired: {
      type: Boolean,
      default: false
    },
    uniqueError: {
      type: Boolean,
      default: false
    },
    quantityError: {
      type: Boolean,
      default: false
    },
    repetativeValuesError: {
      type: Boolean,
      default: false
    },
    urlError: {
      type: Boolean,
      default: false
    },
    floatError: {
      type: Boolean,
      default: false
    },
    numberError: {
      type: Boolean,
      default: false
    },
    cyrillicError: {
      type: Boolean,
      default: false
    },
    formatEan13Error: {
      type: Boolean,
      default: false
    },
    phoneError: {
      type: Boolean,
      default: false
    },
    mustBeSame: {
      type: Boolean,
      default: false
    },
    mustBePositiv: {
      type: Boolean,
      default: false
    },
    dateError: {
      type: Boolean,
      default: false
    },
    timeError: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped="true">
p {
  color: $danger-color;
  font-size: $medium-font-size;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-top: 0;
  position: static;
}
</style>
