import TypesTable from "./typesTable/typesTable"
import CreateType from "./createType/createType"
import CopyType from "./copyType/copyType"
import CheckUniqueType from "../validations/checkUniqueType"
import AttributesTable from "./attributesTable/attributesTable"
import CreateAttribute from "./createAttribute/createAttribute"
import EditAttributes from "./editAttributes/editAttributes"
import ShowProducts from "./showProducts/showProducts"

const modules = {
  TypesTable,
  CreateType,
  CheckUniqueType,
  AttributesTable,
  CreateAttribute,
  EditAttributes,
  ShowProducts,
  CopyType
}

export default {
  modules
}
