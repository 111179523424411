import { computed } from "vue"
import i18n from "@/i18n"
import getCurrentLocale from "@/utils/getCurrentLocale"

export default function useSynonymsColumns() {
  const searchesListColumns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("date"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      fixed: "left",
      width: 120
    },
    {
      key: "p_code",
      dataIndex: "product.p_code",
      title: "SPC (p-code)",
      fixed: "left",
      width: 120
    },
    {
      key: "name",
      dataIndex: "product.name",
      title: i18n.t("offerName"),
      scopedSlots: { customRender: "nameRenderer" },
      width: 260
    },
    {
      key: "category",
      dataIndex: "product.category.name",
      title: i18n.t("category"),
      customRender: (localeObject) => (localeObject ? localeObject[getCurrentLocale()] : ""),
      width: 200
    },
    {
      key: "keywords_all",
      dataIndex: "keywords_all",
      title: i18n.t(`searchWeights.keywords_all`),
      scopedSlots: { customRender: "keywordsRenderer" },
      width: 210
    },
    {
      key: "keywords_6",
      dataIndex: "keywords_6",
      title: i18n.t(`searchWeights.keywords_6`),
      scopedSlots: { customRender: "keywordsRenderer" },
      width: 210
    },
    {
      key: "keywords_8",
      dataIndex: "keywords_8",
      title: i18n.t(`searchWeights.keywords_8`),
      scopedSlots: { customRender: "keywordsRenderer" },
      width: 210
    },
    {
      key: "keywords_12",
      dataIndex: "keywords_12",
      title: i18n.t(`searchWeights.keywords_12`),
      scopedSlots: { customRender: "keywordsRenderer" },
      width: 210
    },
    {
      key: "keywords_18",
      dataIndex: "keywords_18",
      title: i18n.t(`searchWeights.keywords_18`),
      scopedSlots: { customRender: "keywordsRenderer" },
      width: 210
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      fixed: "right",
      width: 64
    }
  ])

  return {
    searchesListColumns
  }
}
