<template>
  <div class="attribute-edit__table-cell--invalid">
    {{ row.invalid }}
  </div>
</template>
<script>
export default {
  props: ["row"]
}
</script>

<style lang="scss">
.attribute-edit__table-cell--invalid {
  color: $danger-color;
}
</style>
