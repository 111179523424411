<template>
  <a-drawer
    :visible="showModal"
    :title="`${$t('shop')} ${prototype?.name || ''}`"
    :width="1400"
    style="padding-top: 0"
    @close="$emit('close')"
  >
    <div
      v-if="prototype"
      class="billing-seller"
    >
      <div class="left">
        <div class="section">
          <a-card
            :title="$t('currentTariff')"
            :bordered="false"
            size="small"
          >
            <div v-if="prototype.tariff_config">
              {{ prototype.tariff_config.tariff.title }}/{{
                prototype.tariff_config.expire_in_days + `${$t("dayShorted")}`
              }}
              <span>{{ `(${prototype.tariff_config.price} ${$t("uah")})` }}</span>
            </div>
            <div v-else>{{ $t("noCurrentTariff") }}</div>
          </a-card>

          <a-card
            v-if="prototype.tariff_config"
            :title="$t('licenseExpireDate')"
            :bordered="false"
            size="small"
          >
            <div v-if="!isTariffExpired && tarrifDateDifference >= 0">
              {{ tariffDate }}
            </div>
            <div
              v-else
              class="alert"
            >
              {{ $t("isOver") }} {{ tariffDate }}
            </div>
          </a-card>

          <a-card
            :title="$t('freeOrdersLimit')"
            :bordered="false"
            size="small"
          >
            {{ prototype.amount_free_orders }}
            {{ $tc("ordersLeft", prototype.amount_free_orders) }}
          </a-card>
          <a-card
            :title="`${$t('sellerOrders')} (${prototype.orders_count})`"
            :bordered="false"
            size="small"
          >
            <div>{{ $t("success") }}: {{ ordersInfoInModal.success_closed_orders }}</div>
            <div>{{ $t("canceledStatus") }}: {{ ordersInfoInModal.unsuccess_closed_orders }}</div>
            <div>{{ $t("lastWeek") }}: {{ ordersInfoInModal.orders_count_per_week }}</div>
            <div>
              {{ $t("activeOrSelling") }}: {{ ordersInfoInModal.active_offers_count }} /
              {{ ordersInfoInModal.sells_offers_count }}
            </div>
          </a-card>
          <a-card
            :title="$t('shopBalance')"
            :bordered="false"
            size="small"
          >
            <div>{{ prototype?.bill?.balance || 0 }} {{ $t("uah") }}</div>
          </a-card>
          <a-card
            :title="$t('tariffPlanBalance')"
            :bordered="false"
            size="small"
          >
            <div>{{ prototype?.tariff_rest || 0 }} {{ $t("uah") }}</div>
          </a-card>
        </div>
      </div>
      <a-tabs class="right">
        <a-tab-pane
          key="history"
          :tab="$t('balanceHistory')"
        >
          <BillingHistoryTable
            :sellerUuid="prototype.uuid"
            @handleUpdateBillingInfo="handleUpdateBillingInfo"
          />
        </a-tab-pane>
        <a-tab-pane
          key="edit"
          :tab="$t('changingTariffPlan')"
        >
          <TariffChange
            :sellerUuid="prototype.uuid"
            :bill="prototype.bill"
            :tariff_rest="prototype.tariff_rest"
            :tariff_expire_at="prototype.tariff_expire_at"
            :tariff_config="prototype.tariff_config"
            @handleUpdateBillingInfo="handleUpdateBillingInfo"
          />
        </a-tab-pane>
        <a-tab-pane
          key="topUp"
          :tab="$t('balanceRefill')"
        >
          <TopUpBalance
            :sellerUuid="prototype.uuid"
            @handleUpdateBillingInfo="handleUpdateBillingInfo"
          />
        </a-tab-pane>
        <a-tab-pane
          key="subtract"
          :tab="$t('writeOffingCommission')"
        >
          <SubtractComission
            :prototype="prototype"
            @handleUpdateBillingInfo="handleUpdateBillingInfo"
          />
        </a-tab-pane>

        <a-tab-pane
          v-if="!prototype.tariff_config"
          key="limit"
          :tab="$t('editLimit')"
        >
          <FreeOrdersLimit
            :amountFreeOrders="prototype.amount_free_orders"
            :uuid="prototype.uuid"
            :slug="prototype.slug"
            @handleUpdateBillingInfo="handleUpdateBillingInfo"
          />
        </a-tab-pane>

        <template #tabBarExtraContent>
          <a-spin
            class="billing-seller--loading"
            v-if="fetching"
          />
          <a-button
            v-if="prototype.tariff_config"
            type="danger"
            @click="handleCancelTariff"
            style="margin-left: 10px"
          >
            {{ $t("cancelTariff") }}
          </a-button>
        </template>
      </a-tabs>
    </div>
    <a-spin v-else />
  </a-drawer>
</template>

<script>
import TopUpBalance from "./components/TopUpBalance.vue"
import SubtractComission from "./components/SubtractComission.vue"
import TariffChange from "./components/TariffChange.vue"
import BillingHistoryTable from "./components/BillingHistoryTable.vue"
import FreeOrdersLimit from "./components/FreeOrdersLimit.vue"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"

import {
  fetchBillingSellerById,
  sellerCancelTariff,
  fetchBillingSellerOrders
} from "@/modules/MPAdmin/services/billingService.js"

export default {
  name: "SellerBillingModal",

  components: {
    TopUpBalance,
    SubtractComission,
    TariffChange,
    BillingHistoryTable,
    FreeOrdersLimit
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    sellerUuid: {
      type: String,
      required: true
    }
  },

  emits: ["handleUpdateBillingInfo"],

  mounted() {
    this.fetchSeller()
  },

  data() {
    return {
      fetching: true,
      prototype: null,
      ordersInfoInModal: {}
    }
  },

  computed: {
    isTariffExpired() {
      const today = new Date().getTime()
      const tariffExpire = new Date(this.prototype.tariff_expire_at).getTime()

      return today > tariffExpire
    },

    tariffDate() {
      return getFormatedDateTime(this.prototype.tariff_expire_at)
    },

    tarrifDateDifference() {
      const today = new Date()
      const date = new Date(this.prototype.tariff_expire_at)

      const Diff_In_Time = date.getTime() - today.getTime()
      const diff_in_days = Math.floor(Diff_In_Time / (1000 * 3600 * 24))

      return diff_in_days
    },

    selectTariffs() {
      const arr = this.tariffs.filter((item) => {
        return item.configs.length > 0
      })
      return arr.map((item) => {
        return {
          ...item,
          titleRu: item.title.uk || item.title.en || item.title.ru
        }
      })
    }
  },

  methods: {
    fetchSeller() {
      this.fetching = true

      fetchBillingSellerById(this.sellerUuid)
        .then((res) => {
          this.prototype = res.data
        })
        .finally(() => {
          this.fetching = false
        })
    },

    async onCancelTariff() {
      try {
        sellerCancelTariff(this.prototype.uuid)

        this.$notification.success({ message: this.$t("tariffCanceled") })
        this.handleUpdateBillingInfo()
      } catch {
        this.$notification.error({ message: "error" })
      }
    },

    handleCancelTariff() {
      this.$confirm({
        content: this.$t("questionToCancelTariff"),
        okText: this.$t("yes"),
        cancelText: this.$t("no"),
        onOk: () => this.onCancelTariff()
      })
    },

    handleUpdateBillingInfo() {
      this.fetchSeller()
      this.$emit("handleUpdateBillingInfo")
    }
  },

  watch: {
    showModal: {
      async handler(val) {
        if (val) {
          try {
            const response = await fetchBillingSellerOrders(this.sellerUuid)

            this.ordersInfoInModal = response.data[0]
          } catch {}
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  color: red;
  font-weight: 500;
}
.billing-seller {
  display: flex;

  &--loading {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffffaa;
    z-index: 6;
  }
}
.left {
  width: 20%;
}
.right {
  width: 80%;
  position: relative;
}
.section {
  padding-top: 6px;
}
.label {
  color: $primary-color;
}
</style>
