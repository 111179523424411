<template>
  <transition name="gallery-mask">
    <div
      class="gallery-wrapper"
      v-if="showGallery"
      @mousedown.prevent
      @click="closeGallery"
    >
      <span
        class="close-button"
        @click.stop="closeGallery"
      >
        ×
      </span>

      <a-icon
        class="navigation-btn next"
        type="arrow-right"
        @click.stop="next"
        v-if="getCurrentImage"
        @mousedown.prevent
      >
      </a-icon>

      <a-icon
        class="navigation-btn prev"
        type="arrow-left"
        @click.stop="prev"
        v-if="getCurrentImage && getPreviewList.length"
        @mousedown.prevent
      >
      </a-icon>

      <span
        class="status-bar"
        v-if="getCurrentImage"
        @click.stop
      >
        {{ currentIndex + 1 }} / {{ getFullCount }}
      </span>

      <div class="gallery-relative">
        <a-icon
          class="preview-navigation prev"
          type="arrow-left"
          @click.stop="prevPage"
          v-if="getPreviewList.length > 5"
        >
        </a-icon>

        <a-icon
          class="preview-navigation next"
          type="arrow-right"
          @click.stop="nextPage"
          v-if="getPreviewList.length > 5"
        >
        </a-icon>

        <div class="cover-wrapper">
          <div
            class="cover"
            @click.stop
            v-show="getCurrentImage"
          >
            <img
              :src="getCurrentImage"
              @error.once="changeImageSize"
              :key="currentIndex"
            />
          </div>

          <div
            class="error"
            v-show="!getCurrentImage"
          >
            No images
          </div>
        </div>

        <div
          class="previews-wrapper"
          v-if="getCurrentImage"
        >
          <div class="box">
            <ul
              class="list"
              :style="{
                left: `-${offset}px`
              }"
            >
              <li
                v-for="image in getPreviewList"
                :key="image.url + image.index"
              >
                <span
                  @click.stop="setCurrentIndex(image.index)"
                  class="preview"
                  :class="{
                    active: image.index == currentIndex
                  }"
                >
                  <img :src="image.url" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import getImagePath from "utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"

export default {
  name: "GlobalGallery",

  data() {
    return {
      previewWidth: 84,
      perPage: 5,
      showGallery: false,
      images: [],
      previews: [],
      currentIndex: null,
      offset: 0,
      currentPage: 0,
      imagesStrings: []
    }
  },

  computed: {
    getCurrentImage() {
      if (this.currentIndex != null && this.images.length) {
        return this.images[this.currentIndex]
      } else {
        return ""
      }
    },

    getFullCount() {
      return this.images.length
    },

    getPreviewList() {
      return this.previews.map((image, i) => {
        return {
          url: image,
          index: i
        }
      })
    }
  },

  methods: {
    changeImageSize(e) {
      e.target.src = this.getFullSizeCdnUrl(this.imagesStrings[this.currentIndex])
    },
    handleEvent(obj) {
      let images = []
      let previews = []
      let currentIndex = 0

      if (typeof obj === "array") {
        images = obj
      } else if (typeof obj === "object") {
        if ("images" in obj) {
          images = obj.images
        }

        if ("startIndex" in obj) {
          currentIndex = obj.startIndex
        }
      }

      this.imagesStrings = images

      previews = [...images]
      previews = this.getCdnUrls(images, "300x300")

      images = images.filter((str) => typeof str === "string")
      images = this.getCdnUrls(images, "full")

      this.images = images
      this.previews = previews
      this.currentIndex = currentIndex

      this.showGallery = true

      this.setCurrentIndex(this.currentIndex)

      this.listenKeyDown()

      this.setActualOffset()
    },

    getCdnUrls(array, size) {
      return array.map((str) => {
        if (~str.indexOf(IMAGES_CDN) || str.indexOf("http") == 0) return str
        else return `${IMAGES_CDN}/media/assets/images/${getImagePath(str, size)}`
      })
    },
    getFullSizeCdnUrl(str) {
      if (~str.indexOf(IMAGES_CDN) || str.indexOf("http") == 0) return str
      else return `${IMAGES_CDN}/media/assets/images/${getImagePath(str, "full")}`
    },

    setActualOffset() {
      const steps = Math.floor(this.currentIndex / this.perPage).toFixed(0)
      const stepSize = this.perPage * this.previewWidth
      const offset = stepSize * steps

      this.currentPage = steps
      this.offset = offset
    },

    listenKeyDown() {
      document.addEventListener("keydown", this.keyDownListener)
    },

    removeKeyDownListener() {
      document.removeEventListener("keydown", this.keyDownListener)
    },

    keyDownListener(e) {
      if (e.keyCode == 37) {
        this.prev()
      } else if (e.keyCode == 39) {
        this.next()
      }
    },

    closeGallery() {
      this.showGallery = false

      this.removeKeyDownListener()
    },

    setCurrentIndex(index) {
      if (typeof index !== "number") {
        console.error("index is not a Number")
        return
      } else if (index > this.images.length - 1 || index < 0) {
        console.error("index out of bounds array")
        return
      }

      this.currentIndex = index

      this.setActualOffset()
    },

    next() {
      if (this.currentIndex + 1 < this.images.length) {
        this.setCurrentIndex(this.currentIndex + 1)
      } else {
        this.setCurrentIndex(0)
      }
    },

    prev() {
      if (this.currentIndex - 1 >= 0) {
        this.setCurrentIndex(this.currentIndex - 1)
      } else {
        this.setCurrentIndex(this.images.length - 1)
      }
    },

    nextPage() {
      let nextPage = +this.currentPage + 1 //2

      if ((nextPage + 1) * this.perPage - this.perPage < this.images.length) {
        this.offset = nextPage * this.perPage * this.previewWidth
        this.currentPage = nextPage
      } else {
        this.offset = 0
        this.currentPage = 0
      }
    },

    prevPage() {
      let prevPage = +this.currentPage - 1 //2
      let stepSize = this.perPage * this.previewWidth

      if (prevPage >= 0) {
        this.offset -= stepSize
        this.currentPage = prevPage
      } else {
        let pagesCount = Math.ceil(this.images.length / this.perPage) - 1
        this.offset = pagesCount * stepSize
        this.currentPage = pagesCount
      }
    }
  }

  // created() {
  //   this.$root.$on("showGallery", this.handleEvent)
  // },

  // destroyed() {
  //   this.$root.$off("showGallery", this.handleEvent)
  // }
}
</script>

<style lang="scss">
.gallery-wrapper {
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  content: "";
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  display: inline-block;

  & .navigation-btn {
    z-index: 99999;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    height: 120px;
    width: 60px;
    line-height: 120px;
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.next {
      top: calc(50% - 60px);
      right: 10px;
    }

    &.prev {
      top: calc(50% - 60px);
      left: 10px;
    }
  }

  & .close-button {
    z-index: 99999;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  & .status-bar {
    cursor: default;
    z-index: 999;
    position: absolute;
    text-align: center;
    height: 20px;
    line-height: 20px;
    padding: 0 12px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 11px;
    font-weight: 700;
  }

  & .gallery-relative {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    content: "";

    & .preview-navigation {
      position: absolute;
      height: 60px;
      width: 60px;
      content: "";
      display: inline-block;
      z-index: 9999;
      cursor: pointer;
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      font-weight: 700;
      color: #fff;

      &.next {
        left: calc(50% + 220px);
        bottom: 15px;
      }

      &.prev {
        right: calc(50% + 220px);
        bottom: 15px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    & .cover-wrapper {
      position: absolute;
      width: calc(100% - 160px);
      height: calc(100% - 100px);
      left: 80px;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      & .cover {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        line-height: 0;

        & img {
          display: inline-block !important;
          max-width: 100%;
          max-height: calc(100vh - 100px);
        }
      }

      & .error {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        line-height: 100%;
        text-align: center;
        font-size: 12px;
        color: #fff;
      }
    }

    & .previews-wrapper {
      position: absolute;
      width: calc(100% - 160px);
      padding: 0;
      content: "";
      height: 90px;
      bottom: 0;
      left: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      & .box {
        width: calc(84px * 5);
        height: 80px;
        position: relative;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
      }

      & ul.list {
        position: absolute;
        top: 0;
        transition: left ease-in-out 0.2s;
        left: 0;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 10000px;
        height: 80px;

        li {
          padding: 0;
          margin: 0;
          display: inline;
          margin-right: 2px;
          margin-left: 2px;

          & .preview {
            border: 1px solid rgba(0, 0, 0, 0);
            background-color: transparent;
            overflow: hidden;
            width: 80px;
            height: 80px;
            display: inline-block;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            transition: opacity ease-in-out 0.2s;
            opacity: 0.8;

            &.active {
              border: 1px solid #357cf1;
              opacity: 1;
            }

            &:hover {
              opacity: 1;
            }

            & img {
              max-width: 80px;
              max-height: 80px;
              position: absolute;
              top: calc(50%);
              left: calc(50%);
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

.gallery-mask-enter {
  opacity: 0;
}

.gallery-mask-leave-active {
  opacity: 0;
}
</style>
