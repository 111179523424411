import { render, staticRenderFns } from "./MessageHistoryModal.vue?vue&type=template&id=6857a4ed&scoped=true"
import script from "./MessageHistoryModal.vue?vue&type=script&lang=js"
export * from "./MessageHistoryModal.vue?vue&type=script&lang=js"
import style0 from "./MessageHistoryModal.vue?vue&type=style&index=0&id=6857a4ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6857a4ed",
  null
  
)

export default component.exports