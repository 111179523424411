import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import regeneratorRuntime from "regenerator-runtime"

const state = () => ({
  PT_showCopyTypeModal: false,
  PT_typesToCopyAutocomplete: false,
  PT_typesToCopy: false,
  PT_selectedTypeToCopy: false
})

const getters = {
  PT_showCopyTypeModal: (state) => state.PT_showCopyTypeModal,
  PT_typesToCopyAutocomplete: (state) => state.PT_typesToCopyAutocomplete,
  PT_typesToCopy: (state) => state.PT_typesToCopy,
  PT_selectedTypeToCopy: (state) => state.PT_selectedTypeToCopy
}

const mutations = {
  PT_showCopyTypeModal(state, value) {
    state.PT_showCopyTypeModal = value
  },
  PT_typesToCopyAutocomplete(state, value) {
    state.PT_typesToCopyAutocomplete = value
  },
  PT_typesToCopy(state, value) {
    state.PT_typesToCopy = value
  },
  PT_selectedTypeToCopy(state, value) {
    state.PT_selectedTypeToCopy = value
  }
}

const actions = {
  async PT_copyNewType({ commit, getters, dispatch }) {
    try {
      let newType = await dispatch("PT_createNewType")
      commit("PC_showLoader", true)
      let type = getters.PT_selectedTypeToCopy
      await dispatch("PC_fetchAllAtlsOfType", type)
      await dispatch("PT_copyAtls", newType)
      await dispatch("PT_searchAttributes", getters.PT_selectedType) // to update attributes table after new atls added
    } catch (e) {
      commit("handleError", e)
    } finally {
      commit("PC_showLoader", false)
      dispatch("PT_resetCopyTypeValues")
      commit("PT_showCopyTypeModal", false)
    }
  },
  PT_typesToCopyAutocomplete({ commit, getters }, value) {
    let searchData = buildMagicSearch({
      resource: "PTD",
      field: "full_name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })
    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((types) => {
        commit("PT_typesToCopy", types)
        let typeArray = types.map((item) => {
          return item.full_name
        })
        commit("PT_typesToCopyAutocomplete", typeArray)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PT_selectedTypeToCopy({ commit, getters }, value) {
    let selectedValue = getters.PT_typesToCopy.find((item) => {
      return item.full_name === value
    })

    // set
    commit("PT_selectedTypeToCopy", selectedValue)
  },
  async PT_copyAtls({ commit, getters }, type) {
    let atls = getters.PC_allAtlsOfType
    let promiseArray = []
    let sendData
    for (let i = 0; i < atls.length; i++) {
      atls[i].order += 1
      atls[i].product_type = type.code
      sendData = atls[i]
      promiseArray.push(API_MODERATION.post(`${apiUrls.link}`, sendData))
    }
    return Promise.all(promiseArray).then((r) => r.data)
  },
  PT_resetCopyTypeValues({ commit }) {
    commit("PT_typesToCopyAutocomplete", false)
    commit("PT_typesToCopy", false)
    commit("PT_selectedTypeToCopy", false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
