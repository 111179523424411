import { API } from "@/utils/HttpUtils"
import { transformQuery } from "./servicesUtils"

export const getTransactions = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/bonuses/transactions${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const getSuperBill = () => {
  return API.get("/mp-admin/bonuses/bills/meta/")
}

export const getBonusRate = () => {
  return API.get("/mp-admin/bonuses/currencies/rates/current")
}

export const changeBonusRate = (payload) => {
  return API.post("/mp-admin/bonuses/currencies/rates/", payload)
}
export const createBonus = (payload) => {
  return API.post("/mp-admin/bonuses/transactions/super-user-bill/", payload)
}

export const getBonusHistoryRate = ({ queryParams, signal }) => {
  return API.get(`/mp-admin/bonuses/currencies/rates/${transformQuery(queryParams)}`, { signal })
}

export const transactBonus = (payload) => {
  return API.post("/mp-admin/bonuses/transactions", payload)
}

export const searchCustomers = ({ params, signal }) => {
  return API.get(
    `/mp-admin/customers?is_active=true&is_confirmed_mobile_phone=true&receiver=${params.receiver}`,
    {
      signal
    }
  )
}
