<template>
  <div>
    <a-table
      :dataSource="data ? [data] : []"
      :columns="columns"
      rowKey="id"
      size="middle"
      :scroll="{ y: 300 }"
      :pagination="false"
    >
      <template #offerImages="record">
        <TableRendererPhotoGallery
          v-if="record?.images?.length"
          :images="record.images"
        />
      </template>

      <template #condition="value">
        {{ $t(`condition_${value}`) }}
      </template>

      <template #clampedText="value">
        <a-popover placement="topLeft">
          <template #content>
            <div style="max-width: 450px">
              {{ value }}
            </div>
          </template>

          <div class="clamped-comment">{{ value }}</div>
        </a-popover>
      </template>
    </a-table>

    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item :label="$t('chooseStatus')">
        <a-select
          v-model="form.moderation_status"
          :options="moderationStatuses"
        />
      </a-form-model-item>

      <a-form-item :label="$t('label')">
        <a-select
          :placeholder="$t('notSet')"
          v-model="form.label"
          allowClear
        >
          <a-select-option
            v-for="label in labels"
            :key="label.id"
            :value="label.id"
          >
            {{ label.name[getCurrentLocale()] }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-model-item :label="$t('priceOld')">
        <a-input-number
          v-model="form.price_old"
          :min="0"
          :max="999999999"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('moderComment')">
        <a-textarea
          v-model="form.moderation_comment"
          :rows="4"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"
import usePropositionsPageColumns from "@/modules/MPAdmin/pages/ModerationPropositions/usePropositionsPageColumns.js"
import { updateProposition } from "@/modules/MPAdmin/services/propositionsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import getCurrentLocale from "@/utils/getCurrentLocale.js"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  labels: {
    type: Array,
    default: () => []
  },
  moderationStatuses: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["close"])

const { editPropositionColumns: columns } = usePropositionsPageColumns()

const formRef = ref()
const form = reactive({
  label: props.data.label?.id || undefined,
  moderation_status: props.data.moderation_status,
  price_old: props.data.price_old,
  moderation_comment: props.data.moderation_comment
})
const formRules = computed(() => ({
  moderation_status: [{ required: true, message: i18n.t("emptyError") }],
  moderation_comment: [{ max: 1000, message: i18n.t("maxLengthError", { length: 1000 }) }]
}))

const isSubmitting = ref(false)

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    isSubmitting.value = true

    const payload = {
      moderation_status: form.moderation_status,
      label: form.label || null,
      moderation_comment: form.moderation_comment,
      price_old: form.price_old || null
    }

    const { data } = await updateProposition(payload, props.data.id)

    notification.success({ message: i18n.t("updated") })
    emit("updateProposition", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmitting.value = false
  }
}
</script>
