import Login from "./pages/Login"

export default {
  path: "/login",
  component: Login,
  meta: {
    withoutChecking: true,
    layout: "ant-layout"
  }
}
