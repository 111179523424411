export default function ({ resource, field, value, sort, limit, offset }) {
  let search = {}
  search = {}
  search.resource = resource

  if (field instanceof Array && value instanceof Array) {
    search.filters = []
    for (let i = 0; i < field.length; i++) {
      if (value[i] && field[i]) {
        search.filters.push({
          field: field[i],
          value: value[i]
        })
      }
    }
  } else {
    search.filters = [
      {
        field,
        value: value
      }
    ]
  }

  if (sort !== null) search.sorting = sort

  if (limit !== null) search.limit = limit
  if (offset !== null) search.offset = offset

  return search
}
