<template>
  <a-checkbox
    :disabled="disabled"
    @change="(e) => onChange(e.target.checked)"
    :data="data"
    :checked="checked"
  />
</template>

<script>
export default {
  name: "CheckRow",

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
