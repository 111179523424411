import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import { API_MODERATION } from "utils/HttpUtils"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

// CONVERTING TO LIST/MULTILIST MODAL DATA
// СКОРОЧЕННЯ CSL - CONVERT STRING TO LIST

const state = {
  AE_CSL_newListTitle: "",
  AE_CSL_lists: [],
  AE_CSL_selectedList: {},
  AE_CSL_selectedListValues: [],
  AE_CSL_selectedListAttrs: []
}

const mutations = {
  AE_CSL_newListTitle(state, value) {
    state.AE_CSL_newListTitle = value
  },
  AE_CSL_lists(state, value) {
    state.AE_CSL_lists = value
  },
  AE_CSL_selectedList(state, value) {
    state.AE_CSL_selectedList = value
  },
  AE_CSL_selectedListValues(state, value) {
    state.AE_CSL_selectedListValues = value
  },
  AE_CSL_selectedListAttrs(state, value) {
    state.AE_CSL_selectedListAttrs
  }
}

const actions = {
  async fetchLists({ commit }) {
    // for existing lists
    return API_MODERATION.get(`${apiUrls.lists}`)
      .then((r) => {
        commit("AE_CSL_lists", r.data.message)
      })
      .catch((e) => {
        return Promise.reject(e.response)
      })
  },

  async setListForConverting({ state, commit, dispatch }, inputValue) {
    const selectedList = state.AE_CSL_lists.find((list) => {
      return inputValue.name === list.description
    })

    commit("AE_CSL_selectedList", selectedList)
    try {
      await dispatch("getAttrsOfList")
      await dispatch("getValuesOfList")
    } catch (e) {
      commit("ProductCatalog/handleError", e, { root: true })
    }
  },
  getAttrsOfList({ state, commit }) {
    const params = {
      description: state.AE_CSL_selectedList.description
    }
    return API_MODERATION.get(`${apiUrls.searchAttributesOfList}`, { params })
      .then((r) => {
        commit("AE_CSL_selectedListAttrs", r.data.message)
      })
      .catch((e) => {
        return Promise.reject(e.response)
      })
  },
  getValuesOfList({ state, commit }) {
    let searchData = buildMagicSearch({
      resource: "VLD",
      field: "description",
      value: state.AE_CSL_selectedList.description,
      sort: null,
      limit: null,
      offset: null
    })
    return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData).then((r) => {
      commit("AE_CSL_selectedListValues", r.data.message.data)
    })
  },

  AE_CSL_DoConvertation({ commit }, payload) {
    commit("ProductCatalog/PC_showLoader", true, { root: true })
    return API_MODERATION.patch(`${apiUrls.attributeEdit}`, payload)
      .then((r) => {
        return r
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  AE_CSL_resetModalData({ commit }) {
    commit("AE_CSL_newListTitle", "")
    commit("AE_CSL_selectedList", false)
    commit("AE_CSL_selectedListValues", false)
    commit("AE_CSL_selectedListAttrs", false)
  }
}

export default {
  state,
  mutations,
  actions
}
