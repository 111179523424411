<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PA_showAddTypeModalGet"
      :title="'Связать атрибут с типом'"
    >
      <div>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col col-first">
                <label><span>Выберите тип товара (полный)</span></label>
                <Autocomplete
                  :data="PA_typesForNewAtlAutocompleteGet"
                  event="input"
                  @event="fetchTypes"
                  @select="PA_processSelectedTypeForNewAtl"
                  :size="'small'"
                  :defaultValue="type"
                />
              </div>
            </div>
            <div
              class="row margin-top-10"
              v-if="PA_selectedProductTypeForNewAtlGet && PA_selectedProductTypeForNewAtlGet.name"
            >
              <div class="col col-first">
                <label
                  ><span
                    >Тип товара (короткий): {{ PA_selectedProductTypeForNewAtlGet.name }}</span
                  ></label
                >
              </div>
            </div>
            <div class="row margin-top-10">
              <div class="col">
                <Checkbox
                  @change="checkboxMain"
                  :checked="PA_isMainForNewAtlGet"
                >
                  Основной
                </Checkbox>
              </div>
            </div>
            <div class="row margin-top-10">
              <div class="col">
                <Checkbox
                  @change="checkboxRequired"
                  :checked="PA_isRequiredForNewAtlGet"
                >
                  Обязательный
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <Button
          :disabled="!enableCreateButton"
          color="primary"
          @click="createNewAttribute"
          >Создать</Button
        >
      </span>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import Autocomplete from "components/Autocomplete"
import Checkbox from "components/Checkbox"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "AddTypeModal",
  components: {
    Button,
    Modal,
    Autocomplete,
    Checkbox
  },
  data() {
    return {
      timeoutType: null
    }
  },
  methods: {
    fetchTypes(value) {
      if (value === "") {
        // reset
        this.PA_typesForNewAtlMute([])
        this.PA_selectedProductTypeForNewAtlMute(false)
        this.PA_typesForNewAtlAutocompleteMute([])
        return
      }
      clearTimeout(this.timeoutType)
      this.timeoutType = setTimeout(() => {
        this.PA_fetchTypesForNewAtl(value)
      }, 500)
    },
    checkboxMain(val) {
      this.PA_isMainForNewAtlMute(val)
    },
    checkboxRequired(val) {
      this.PA_isRequiredForNewAtlMute(val)
    },
    createNewAttribute() {
      this.PA_createNewAtl()
      this.closeModal()
    },
    closeModal() {
      this.PA_showAddTypeModalMute(false)
      this.resetValues()
    },
    resetValues() {
      this.timeoutType = null
    },
    ...mapMutations("ProductCatalog", {
      PA_showAddTypeModalMute: "PA_showAddTypeModal",
      PA_typesForNewAtlMute: "PA_typesForNewAtl",
      PA_selectedProductTypeForNewAtlMute: "PA_selectedProductTypeForNewAtl",
      PA_typesForNewAtlAutocompleteMute: "PA_typesForNewAtlAutocomplete",
      PA_isMainForNewAtlMute: "PA_isMainForNewAtl",
      PA_isRequiredForNewAtlMute: "PA_isRequiredForNewAtl"
    }),
    ...mapActions("ProductCatalog", {
      PA_createNewAtl: "PA_createNewAtl",
      PA_fetchTypesForNewAtl: "PA_fetchTypesForNewAtl",
      PA_processSelectedTypeForNewAtl: "PA_processSelectedTypeForNewAtl"
    })
  },
  computed: {
    type() {
      return this.PA_selectedProductTypeForNewAtlGet === false
        ? ""
        : this.PA_selectedProductTypeForNewAtlGet.full_name
    },
    enableCreateButton() {
      let show = false

      if (this.PA_selectedProductTypeForNewAtlGet) {
        show = true
      }

      return show
    },
    ...mapGetters("ProductCatalog", {
      PA_showAddTypeModalGet: "PA_showAddTypeModal",
      PA_selectedProductTypeForNewAtlGet: "PA_selectedProductTypeForNewAtl",
      PA_typesForNewAtlAutocompleteGet: "PA_typesForNewAtlAutocomplete",
      PA_isMainForNewAtlGet: "PA_isMainForNewAtl",
      PA_isRequiredForNewAtlGet: "PA_isRequiredForNewAtl"
    })
  }
}
</script>
<style lang="scss" scoped></style>
