<template>
  <div class="roles-container">
    <div
      v-for="item in allRoles"
      :key="item.id"
    >
      <a-checkbox
        :checked="isChecked(item.id)"
        @change="(e) => handleChangeSelected(e.target.checked, item.id)"
      >
        {{ item.name }}
      </a-checkbox>
    </div>

    <div class="drawer-footer">
      <a-button
        :loading="isFetching"
        type="primary"
        @click="handleUpdate"
      >
        {{ $t("update") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import { updateUser } from "@/modules/UsersManagement/services/usersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  userId: {
    type: Number,
    required: true
  },
  selectedRoles: {
    type: Array,
    default: () => []
  },
  allRoles: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["onUpdate"])

const isFetching = ref(false)
const selectedValues = ref(props.selectedRoles.map((item) => item.id))

const isChecked = (itemValue) => selectedValues.value.includes(itemValue)

const handleChangeSelected = (checked, value) => {
  if (checked) {
    selectedValues.value = [...selectedValues.value, value]
  } else {
    selectedValues.value = selectedValues.value.filter((item) => item !== value)
  }
}

const handleUpdate = async () => {
  try {
    isFetching.value = true

    const { data } = await updateUser({
      userId: props.userId,
      userParams: { roles: selectedValues.value }
    })

    notification.success({ message: i18n.t("updated") })
    emit("onUpdate", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.roles-container {
  font-size: 16px;

  padding-bottom: 50px;
}
</style>
