import { API } from "@/utils/HttpUtils"
import { transformQuery } from "@/modules/MPAdmin/services/servicesUtils"

export const getRoles = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/users-management/roles${transformQuery(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchRoleByID = ({ id }) => {
  return API.get(`/users-management/roles/${id}`)
}

export const updateRole = ({ id, params }) => {
  return API.put(`/users-management/roles/${id}`, params)
}

export const createRole = (payload) => {
  return API.post("/users-management/roles", payload)
}

export const fetchPermissionModels = () => {
  return API.get("/users-management/permissions/models")
}

export const fetchPermissionApps = () => {
  return API.get("/users-management/permissions/applications")
}
