import regeneratorRuntime from "regenerator-runtime"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_newAttribute: {},
  PC_showNewAttributeSecondStep: false,
  PC_newAttributeUnitsSelect: [{ name: "", id: 1 }],
  PC_newAttributeValuesSelect: [],
  PC_selectedNewAttributeUnit: "",
  PC_SearchedAttributes: [],
  PC_newAttributeValue: "",
  PC_newAttributeValueTypeList: "",
  PC_newAttributeOrder: 0,
  PC_defaultUnitsValueId: 1
})

const getters = {
  PC_newAttribute: (state) => state.PC_newAttribute,
  PC_showNewAttributeSecondStep: (state) => state.PC_showNewAttributeSecondStep,
  PC_newAttributeUnitsSelect: (state) => state.PC_newAttributeUnitsSelect,
  PC_newAttributeValuesSelect: (state) => state.PC_newAttributeValuesSelect,
  PC_selectedNewAttributeUnit: (state) => state.PC_selectedNewAttributeUnit,
  PC_SearchedAttributes: (state) => state.PC_SearchedAttributes,
  PC_newAttributeValue: (state) => state.PC_newAttributeValue,
  PC_newAttributeValueTypeList: (state) => state.PC_newAttributeValueTypeList,
  PC_newAttributeOrder: (state) => state.PC_newAttributeOrder,
  PC_defaultUnitsValueId: (state) => state.PC_defaultUnitsValueId
}

const mutations = {
  PC_setSearchedAttributes: (state, attributes) => {
    state.PC_SearchedAttributes = attributes
  },
  PC_newAttribute: (state, { items }) => {
    let attr = items[0]
    state.PC_newAttribute = attr

    if (attr.possible_units) {
      let units = []
      for (let i = 0; i < attr.possible_units.length; i++) {
        let unit = attr.possible_units[i]
        unit.id = i + 1
        units.push(unit)
      }
      state.PC_newAttributeUnitsSelect = units
      try {
        // set default unit as first in select
        if (attr.default_unit && attr.default_unit.code) {
          // find it
          let defaultUnit = units.find((item) => {
            return item.code === attr.default_unit.code
          })
          // set as first
          state.PC_defaultUnitsValueId = defaultUnit.id
        }
      } catch (e) {
        console.error(e, "item.code === attr.default_unit.code")
      }
    }

    if (attr.possible_values) {
      let values = []
      for (let i = 0; i < attr.possible_values.length; i++) {
        let item = attr.possible_values[i]
        let newItem = {}
        newItem.value = item.code
        if (item.value) {
          newItem.label = item.value
        } else {
          // skip value with empty 'value' fields
          continue
        }
        values.push(newItem)
      }
      values = values.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        } else if (a.label > b.label) {
          return 1
        }
        return 0
      })
      if (attr.value_type === "LIST") {
        // add default value for 'LIST', but not for 'MULTILIST'
        values.unshift({ label: "Выберите значение", value: 1 })
      }
      state.PC_newAttributeValuesSelect = values
    }

    state.PC_showNewAttributeSecondStep = true
  },
  PC_selectedNewAttributeUnit(state, value) {
    state.PC_selectedNewAttributeUnit = value
    // state.PC_newAttribute.default_unit = value;
  },
  PC_newAttributeValue(state, value) {
    state.PC_newAttributeValue = value
  },
  PC_newAttributeValueTypeList(state, value) {
    state.PC_newAttributeValueTypeList = value
  },
  PC_newAttributeOrder(state, { sortOrder }) {
    state.PC_newAttributeOrder = sortOrder
  },
  PC_resetSearchNewAttributeValues(state) {
    state.PC_newAttribute = {}
    state.PC_showNewAttributeSecondStep = false
    state.PC_newAttributeUnitsSelect = []
    state.PC_newAttributeValuesSelect = []
    state.PC_selectedNewAttributeUnit = ""
    state.PC_SearchedAttributes = []
    state.PC_newAttributeValue = ""
    state.PC_newAttributeValueTypeList = ""
    state.PC_newAttributeOrder = 0
    state.PC_defaultUnitsValueId = 1
  }
}

const actions = {
  PC_searchAttributes({ commit }, value) {
    // reset values
    commit("PC_resetSearchNewAttributeValues")

    let searchData = buildMagicSearch({
      resource: "AND",
      field: "name",
      value,
      sort: null,
      limit: 20,
      offset: 0
    })

    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((attributes) => {
        commit("PC_setSearchedAttributes", attributes)
      })
      .catch((e) => {
        commit("handleError", e)
      })
  },
  PC_newAttribute({ commit, getters, dispatch }, value) {
    // extract selected attribute from all attributes stored in 'searched attributes'
    let attrIndex
    for (let i = 0; i < getters.PC_SearchedAttributes.length; i++) {
      if (getters.PC_SearchedAttributes[i].name === value) {
        attrIndex = i
        break
      }
    }

    let attribute = [getters.PC_SearchedAttributes[attrIndex]]

    if (attribute[0].status === "IDLE") {
      notification.error({ message: 'Выбранный атрибут заблокирован (статус "IDLE")' })
      return
    }

    if (attribute[0].status === "LOCKED") {
      notification.error({ message: 'Выбранный атрибут заблокирован (статус "LOCKED")' })
      return
    }

    let sendData = {
      product: getters.PC_EditableProduct,
      attributes: attribute,
      mutationName: "PC_newAttribute",
      newAttribute: true,
      checkAttributes: false
    }
    dispatch("PC_fetchProductAttributes", sendData)
  },
  PC_newAttributeOrder({ commit, getters }, value) {
    let sortOrder = Number(value) - 1
    if (sortOrder > getters.PC_editableProductAttributes.items.length) {
      sortOrder = getters.PC_editableProductAttributes.items.length
    }
    commit("PC_newAttributeOrder", { sortOrder })
  },
  async PC_submitNewAttribute({ commit, getters, dispatch }) {
    // show loader
    commit("PC_showLoader", true)

    let attr = getters.PC_newAttribute
    attr._id = "attributesTableRow-" + attr.code

    if (getters.PC_selectedNewAttributeUnit !== "") {
      attr.selectedUnit = getters.PC_selectedNewAttributeUnit

      // add it to change log
      let attrCode = attr.code
      let newUnitCode = attr.selectedUnit.code
      commit("PC_setEditedAttributeDefaultUnits", { attrCode, newUnitCode })
    } else {
      attr.selectedUnit = attr.default_unit
    }
    // if value type is list and PC_newAttributeValueTypeList is empty, because user did not choose any option
    if (attr.value_type === "LIST" && !getters.PC_newAttributeValueTypeList) {
      // set default value as first among PC_newAttributeValuesSelect
      commit("PC_newAttributeValueTypeList", getters.PC_newAttributeValuesSelect[1].value)
    } else if (attr.value_type === "MULTILIST" && !getters.PC_newAttributeValueTypeList) {
      commit("PC_newAttributeValueTypeList", [getters.PC_newAttributeValuesSelect[0].value])
    }

    let commitData = {
      attr,
      order: getters.PC_newAttributeOrder
    }

    // if value type is not 'list or multilist'
    if (getters.PC_newAttributeValuesSelect.length === 0) {
      attr.unitValue = getters.PC_newAttributeValue
      commitData.value = getters.PC_newAttributeValue
    } else {
      // if value type is 'list' or 'multilist'
      attr.unitValue = getters.PC_newAttributeValuesSelect
      commitData.value = getters.PC_newAttributeValueTypeList
    }

    commit("PC_setNewAttribute", commitData)

    await dispatch("PC_updateAttributesChanges")

    // hide loader and modal
    commit("PC_showLoader", false)
    commit("PC_showAddAttributeModal", false)

    let attrs = await dispatch("PC_restartFetchAttributes")
    if (!getters.PC_EditableProduct.type) return
    let missing = await dispatch("PC_checkRequiredAttributeOfType")
    if (!missing) return
    dispatch("PC_missingRequiredAttributes")
    dispatch("PC_missingMainAttributes")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
