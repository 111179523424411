<template>
  <div>
    <Modal
      @close="closeModal"
      :showModal="PV_searchValueInListModalGet"
      :title="`Результат поиска значения по всем добавленным спискам`"
    >
      <div>
        <template v-if="PV_searchedListsWithValue && PV_searchedListsWithValue.length">
          <h3>Значение "{{ PV_valueToSearchInLists }}" содержится в списках:</h3>
          <div
            v-for="(list, index) in PV_searchedListsWithValue"
            :key="index"
            @click="selectList(list)"
            class="search-list-item"
          >
            - {{ list.description }} <a-icon type="search" />
          </div>
        </template>
        <template v-else
          ><label><span>Ничего не найдено</span></label></template
        >
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>
<script>
import Button from "components/Button"
import Modal from "components/Modals/Modal"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "DeleteAttributeModal",
  components: {
    Button,
    Modal
  },
  data() {
    return {}
  },
  methods: {
    selectList(selected) {
      let selectedInTable = this.findSelectedInTable(selected)
      if (!selectedInTable) return
      this.PV_reloadDependentTablesForSelectedList(selectedInTable)
      this.closeModal()
    },
    findSelectedInTable(selected) {
      let tableItems = this.PV_valuesDescriptionData.items
      return tableItems.find((item) => {
        return item.description === selected.description
      })
    },
    closeModal() {
      this.PV_searchValueInListModal(false)
      this.onReset()
    },
    onReset() {
      this.PV_searchedListsWithValueSet(false)
    },
    ...mapMutations("ProductCatalog", {
      PV_searchValueInListModal: "PV_searchValueInListModal",
      PV_searchedListsWithValueSet: "PV_searchedListsWithValue"
    }),
    ...mapActions("ProductCatalog", {
      PV_reloadDependentTablesForSelectedList: "PV_reloadDependentTablesForSelectedList"
    })
  },
  computed: {
    ...mapGetters("ProductCatalog", {
      PV_searchValueInListModalGet: "PV_searchValueInListModal",
      PV_valueToSearchInLists: "PV_valueToSearchInLists",
      PV_searchedListsWithValue: "PV_searchedListsWithValue",
      PV_valuesDescriptionData: "PV_valuesDescriptionData"
    })
  }
}
</script>
<style lang="scss" scoped>
.search-list-item {
  cursor: pointer;
  line-height: 30px;

  &:hover {
    filter: contrast(70%);
  }
}
</style>
