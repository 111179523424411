var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticStyle:{"margin-bottom":"40px"},attrs:{"form":_vm.deliveryForm}},[_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("name"))+", UA ("+_vm._s(_vm.ukNameLength)+"/"+_vm._s(_vm.nameMaxLength)+")")]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name.uk',
        {
          rules: _vm.nameRules,
          initialValue: ''
        }
      ]),expression:"[\n        'name.uk',\n        {\n          rules: nameRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.ukNameLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("name"))+", EN ("+_vm._s(_vm.enNameLength)+"/"+_vm._s(_vm.nameMaxLength)+") ")]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name.en',
        {
          rules: _vm.nameRules,
          initialValue: ''
        }
      ]),expression:"[\n        'name.en',\n        {\n          rules: nameRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.enNameLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("name"))+", RU ("+_vm._s(_vm.ruNameLength)+"/"+_vm._s(_vm.nameMaxLength)+") ")]},proxy:true}])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name.ru',
        {
          rules: _vm.nameRules,
          initialValue: ''
        }
      ]),expression:"[\n        'name.ru',\n        {\n          rules: nameRules,\n          initialValue: ''\n        }\n      ]"}],on:{"change":(e) => (_vm.ruNameLength = e.target.value.length || 0)}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('deliveryType'),"colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type', { rules: [{ required: true, message: _vm.$t('emptyError') }] }]),expression:"['type', { rules: [{ required: true, message: $t('emptyError') }] }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('chooseType'),"options":_vm.deliveryTypes}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('deliveryService'),"colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['service', { rules: [{ required: true, message: _vm.$t('emptyError') }] }]),expression:"['service', { rules: [{ required: true, message: $t('emptyError') }] }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('chooseDeliveryService'),"options":_vm.deliveryServices}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('outputSortOrder'),"colon":false}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'ordering',
        {
          initialValue: 0,
          rules: [{ required: true, message: _vm.$t('emptyError') }]
        }
      ]),expression:"[\n        'ordering',\n        {\n          initialValue: 0,\n          rules: [{ required: true, message: $t('emptyError') }]\n        }\n      ]"}],attrs:{"min":0,"max":999,"precision":0}})],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"colon":false}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_seller_filter', { valuePropName: 'checked' }]),expression:"['is_seller_filter', { valuePropName: 'checked' }]"}]},[_vm._v(" "+_vm._s(_vm.$t("takeFilteringBySellers"))+" ")])],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('supportedPayments'),"colon":false}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'payment_methods',
        { rules: [{ required: true, message: _vm.$t('emptyError') }] }
      ]),expression:"[\n        'payment_methods',\n        { rules: [{ required: true, message: $t('emptyError') }] }\n      ]"}],staticClass:"margin-top",staticStyle:{"display":"flex","flex-flow":"column nowrap","gap":"8px"}},_vm._l((_vm.paymentMethods),function(paymentMethod){return _c('a-checkbox',{key:paymentMethod.value,staticStyle:{"margin":"0","height":"20px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},attrs:{"value":paymentMethod.value}},[_vm._v(" "+_vm._s(paymentMethod.label)+" ")])}),1)],1),_c('a-form-item',{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('deliveryMethodActivity'),"colon":false}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'is_active',
        {
          valuePropName: 'checked',
          initialValue: false
        }
      ]),expression:"[\n        'is_active',\n        {\n          valuePropName: 'checked',\n          initialValue: false\n        }\n      ]"}],attrs:{"checkedChildren":_vm.$t('yes'),"unCheckedChildren":_vm.$t('no')}})],1),_c('div',{staticClass:"drawer-footer"},[_c('a-button',{staticClass:"form-button-padding",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('a-button',{staticClass:"form-button-padding",attrs:{"type":"primary","loading":_vm.fetching},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("apply"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }