<template>
  <transition name="gallery-mask">
    <div
      class="messenger-gallery-wrapper"
      v-if="showGallery"
      @mousedown.prevent
      @click="closeGallery"
    >
      <span
        class="close-button"
        @click.stop="closeGallery"
      >
        ×
      </span>

      <a-icon
        class="navigation-btn next"
        type="arrow-right"
        @click.stop="next"
        v-if="getCurrentImage"
        @mousedown.prevent
      >
      </a-icon>

      <a-icon
        class="navigation-btn prev"
        type="arrow-left"
        @click.stop="prev"
        v-if="getCurrentImage && getPreviewList.length"
        @mousedown.prevent
      >
      </a-icon>

      <span
        class="status-bar"
        v-if="getCurrentImage"
        @click.stop
      >
        {{ currentIndex + 1 }} / {{ getFullCount }}
      </span>

      <div class="gallery-relative">
        <a-icon
          class="preview-navigation prev"
          type="arrow-left"
          @click.stop="prevPage"
          v-if="getPreviewList.length > 5"
        >
        </a-icon>

        <a-icon
          class="preview-navigation next"
          type="arrow-right"
          @click.stop="nextPage"
          v-if="getPreviewList.length > 5"
        >
        </a-icon>

        <div class="cover-wrapper">
          <div
            class="cover"
            @click.stop
            v-if="getCurrentImage"
          >
            <img :src="getCurrentImage" />
          </div>

          <div
            class="error"
            v-else
          >
            {{ $t("noImages") }}
          </div>
        </div>

        <div
          class="previews-wrapper"
          v-if="getCurrentImage"
        >
          <div class="box">
            <ul
              class="list"
              :style="{
                left: `-${offset}px`
              }"
            >
              <li
                v-for="image in getPreviewList"
                :key="image.url"
              >
                <span
                  @click.stop="setCurrentIndex(image.index)"
                  class="preview"
                  :class="{
                    active: image.index == currentIndex
                  }"
                >
                  <img :src="image.url" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import imagePathMixin from "../../mixins/imagePathMixin"

export default {
  name: "GlobalGallery",
  mixins: [imagePathMixin],
  data() {
    return {
      previewWidth: 84,
      perPage: 5,
      showGallery: false,
      images: [],
      previews: [],
      currentIndex: null,
      offset: 0,
      currentPage: 0
    }
  },

  computed: {
    getCurrentImage() {
      if (this.currentIndex != null && this.images.length) {
        return this.images[this.currentIndex]
      } else {
        return ""
      }
    },

    getFullCount() {
      return this.images.length
    },

    getPreviewList() {
      return this.previews.map((image, i) => {
        return {
          url: image,
          index: i
        }
      })
    }
  },

  methods: {
    handleEvent(obj) {
      let images = obj
      let previews = []
      let currentIndex = 0

      if (Array.isArray(obj)) {
        images = obj
      } else if (typeof obj === "object") {
        if ("images" in obj) {
          images = obj.images
        }
        if ("startIndex" in obj) {
          currentIndex = obj.startIndex
        }
      }

      previews = [...images]
      previews = this.getCdnUrls(images, "100x100")

      images = images.filter((str) => typeof str === "string")
      images = this.getCdnUrls(images, "full")

      this.images = images
      this.previews = previews
      this.currentIndex = currentIndex

      this.showGallery = true

      this.setCurrentIndex(this.currentIndex)

      this.listenKeyDown()

      this.setActualOffset()
    },

    setActualOffset() {
      const steps = Math.floor(this.currentIndex / this.perPage).toFixed(0)
      const stepSize = this.perPage * this.previewWidth
      const offset = stepSize * steps

      this.currentPage = steps
      this.offset = offset
    },

    listenKeyDown() {
      document.addEventListener("keydown", this.keyDownListener)
    },

    removeKeyDownListener() {
      document.removeEventListener("keydown", this.keyDownListener)
    },

    keyDownListener(e) {
      switch (e.keyCode) {
        case 37:
          this.prev()
          break
        case 39:
          this.next()
          break
        case 27:
          this.closeGallery()
          break
      }
    },

    closeGallery() {
      this.showGallery = false

      this.removeKeyDownListener()
    },

    setCurrentIndex(index) {
      this.currentIndex = index
      this.setActualOffset()
    },

    next() {
      if (this.currentIndex + 1 < this.images.length) {
        this.setCurrentIndex(this.currentIndex + 1)
      } else {
        this.setCurrentIndex(0)
      }
    },

    prev() {
      if (this.currentIndex - 1 >= 0) {
        this.setCurrentIndex(this.currentIndex - 1)
      } else {
        this.setCurrentIndex(this.images.length - 1)
      }
    },

    nextPage() {
      let nextPage = +this.currentPage + 1 //2

      if ((nextPage + 1) * this.perPage - this.perPage < this.images.length) {
        this.offset = nextPage * this.perPage * this.previewWidth
        this.currentPage = nextPage
      } else {
        this.offset = 0
        this.currentPage = 0
      }
    },

    prevPage() {
      let prevPage = +this.currentPage - 1 //2
      let stepSize = this.perPage * this.previewWidth

      if (prevPage >= 0) {
        this.offset -= stepSize
        this.currentPage = prevPage
      } else {
        let pagesCount = Math.ceil(this.images.length / this.perPage) - 1
        this.offset = pagesCount * stepSize
        this.currentPage = pagesCount
      }
    }
  },

  created() {
    this.$root.$on("showMessengerGallery", this.handleEvent)
  },

  destroyed() {
    this.$root.$off("showMessengerGallery", this.handleEvent)
  }
}
</script>
