<template>
  <DatePicker
    v-model="date"
    :first-day-of-week="1"
    lang="en"
    width="100%"
    :range="true"
    :confirm="true"
    :clearable="true"
    rangeSeparator="一"
    :appendToBody="true"
    :placeholder="placeholder"
    @confirm="handleConfirm"
    @clear="handleClear"
    format="DD.MM.YYYY"
    value-type="timestamp"
  />
</template>

<script>
import moment from "moment"
import DatePicker from "vue2-datepicker"

export default {
  name: "DateRangeFilter",

  components: {
    DatePicker
  },

  props: {
    tableQueryAction: Function,
    tableQueryParams: Object,
    filters: Array,
    updateFilters: Function,
    columnKey: String,
    dateFormat: {
      type: String,
      default: "YYYY-MM-DD"
    },
    valueTypeTimestamp: {
      type: Boolean,
      default: false
    },
    placeholder: String
  },

  data() {
    return {
      date: ""
    }
  },

  methods: {
    handleConfirm(value) {
      let secondsInDay = 86400
      // let milisecondsInDay = 86400000;

      if (value[0] != null && value[1] != null) {
        const date = [
          moment(value[0]).format(this.dateFormat),
          moment(value[1]).format(this.dateFormat)
        ]

        const filters = [
          ...this.filters.map((filter) => {
            return filter.name === this.columnKey
              ? {
                  name: filter.name,
                  // відправляється стрінг бо для POST запита на експорт треба стрінг
                  value: this.valueTypeTimestamp
                    ? [(value[0] / 1000).toString(), (value[1] / 1000 + secondsInDay).toString()]
                    : date
                }
              : filter
          })
        ]

        this.tableQueryAction({
          ...this.tableQueryParams,
          offset: 0,
          filters
        })

        this.updateFilters(filters)
      }
    },

    handleClear() {
      const filters = this.filters.map((filter) => {
        let newFilter = {}

        if (filter.name === this.columnKey) {
          newFilter = {
            name: filter.name,
            value: null
          }
        } else {
          newFilter = filter
        }

        return newFilter
      })

      this.tableQueryAction({
        ...this.tableQueryParams,
        filters
      })

      this.updateFilters(filters)
    }
  }
}
</script>

<style scoped></style>
