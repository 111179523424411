import { transformQuery } from "./servicesUtils"
import { API } from "@/utils/HttpUtils"

export const fetchTags = ({ queryParams, requestParams, signal }) => {
  return API.get(`/mp-admin/tags${transformQuery(queryParams, requestParams?.changeRouterQuery)}`, {
    signal
  })
}

export const createTag = (data) => {
  return API.post("/mp-admin/tags", data)
}

export const fetchSingleTag = (uuid) => {
  return API.get(`/mp-admin/tags/${uuid}`)
}

export const updateTag = (data, uuid) => {
  return API.patch(`/mp-admin/tags/${uuid}`, data)
}

export const fetchTagOffers = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/tags/${requestParams.uuid}/offers${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addOffersToTag = (uuid, csCodes) => {
  return API.post(`/mp-admin/tags/${uuid}/offers/set`, csCodes)
}

export const deleteOffersFromTag = ({ uuid, csCodes }) => {
  return API.post(`/mp-admin/tags/${uuid}/offers/clean`, csCodes)
}

export const editTagOffers = (uuid, csCodes) => {
  return API.post(`/mp-admin/tags/${uuid}/offers/update`, csCodes)
}

export const editTagActivity = (data, uuid) => {
  return API.put(`/mp-admin/tags/${uuid}`, data)
}

export const getCategoriesTree = () => {
  return API.get("mp-admin/categories/tree")
}
