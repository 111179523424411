import { computed } from "vue"
import i18n from "@/i18n"

const useCRColumns = () =>
  computed(() => [
    {
      key: "name_uk",
      dataIndex: "reason.uk",
      title: "UA",
      fixed: "left",
      width: 350
    },
    {
      key: "name_ru",
      dataIndex: "reason.ru",
      title: "RU",
      width: 350
    },
    {
      key: "name_en",
      dataIndex: "reason.en",
      title: "EN",
      width: 350
    },
    {
      key: "allowed_for",
      dataIndex: "allowed_for",
      title: i18n.t("avaliableFor"),
      scopedSlots: { customRender: "allowedFor" },
      width: 350
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("note"),
      width: 340
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      fixed: "right",
      width: 48
    }
  ])

export default useCRColumns
