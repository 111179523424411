const state = () => ({
  PC_showEditAttrModal: false,
  PC_editedAttrValue: false,
  PC_editedAttrRow: false,
  PC_editedAttrRowIndex: false
})

const getters = {
  PC_showEditAttrModal: (state) => state.PC_showEditAttrModal,
  PC_editedAttrValue: (state) => state.PC_editedAttrValue,
  PC_editedAttrRow: (state) => state.PC_editedAttrRow,
  PC_editedAttrRowIndex: (state) => state.PC_editedAttrRowIndex
}

const mutations = {
  PC_showEditAttrModal(state, value) {
    state.PC_showEditAttrModal = value
  },
  PC_editedAttrValue(state, value) {
    state.PC_editedAttrValue = value
  },
  PC_editedAttrRow(state, value) {
    state.PC_editedAttrRow = value
  },
  PC_editedAttrRowIndex(state, value) {
    state.PC_editedAttrRowIndex = value
  },
  PC_resetEditedAttrValues(state) {
    state.PC_showEditAttrModal = false
    state.PC_editedAttrValue = false
    state.PC_editedAttrRow = false
    state.PC_editedAttrRowIndex = false
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
