<template>
  <a-drawer
    width="730px"
    :title="`${$t('add')} ${activeKind} ${$t('toBlackList')}`"
    placement="right"
    :closable="true"
    :visible="addBlackListVisible"
    @close="handleClose"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('note')"
        prop="comment"
      >
        <a-input v-model="form.comment" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('blackList')"
        prop="blackList"
      >
        <a-textarea
          v-model="form.blackList"
          allowClear
          :autoSize="{ minRows: 8, maxRows: 35 }"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="handleClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        @click="handleConfirm"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { reactive, ref, computed } from "vue"
import i18n from "@/i18n"

const props = defineProps({
  activeKind: {
    type: String,
    default: "p_code"
  },
  addBlackListVisible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["submit", "close"])

const formRef = ref()
const form = reactive({
  comment: "",
  blackList: ""
})
const formRules = computed(() => ({
  comment: [{ max: 200, message: i18n.t("maxLengthError", { length: 200 }) }]
}))

const handleConfirm = () => {
  const arrValues = form.blackList.split(/[\r\n\t, ]+/gm).filter(Boolean)

  const data = {
    kind: props.activeKind,
    values: arrValues,
    comment: form.comment
  }

  emit("submit", data)
  clearModal()
}
const handleClose = () => {
  emit("close")
  clearModal()
}

const clearModal = () => {
  formRef.value.resetFields()
}
</script>
