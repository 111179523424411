import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import { notification } from "ant-design-vue"

const state = () => ({
  PC_checkUniqueBarcode: true
})

const getters = {
  PC_checkUniqueBarcode: (state) => state.PC_checkUniqueBarcode
}

const mutations = {
  PC_checkUniqueBarcode: (state, value) => {
    state.PC_checkUniqueBarcode = value
  }
}

const actions = {
  PC_checkUniqueBarcode: ({ commit, getters }, value) => {
    if (value.length !== 13) return

    let searchData = buildMagicSearch({
      resource: "MPC",
      field: "barcode",
      value: value,
      sort: null,
      limit: null,
      offset: null
    })

    API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => {
        if (r.data.message.count === 0) commit("PC_checkUniqueBarcode", true)
        if (r.data.message.count > 0) commit("PC_checkUniqueBarcode", false)
      })
      .catch((e) => {
        console.log(e.message)
        notification.error({ message: "Ошибка проверки штрихкода" })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
