import logger from "../utils/logger"

export default ({ commit, dispatch }, data) => {
  // request answer example: {data.actions = ['chat', 'get_all], body, status}
  if (String(data.status)[0] !== "2") {
    logger.error(`Status code: ${data.status}`)
  } else if (data.actions[0] === "chat" && data.actions[1] === "find") {
    dispatch("ON_CHAT_FIND_NOTICE", data)
  } else if (data.actions[0] === "message" && data.actions[1] === "get_all") {
    dispatch("ON_CHAT_MESSAGES_NOTICE", data)
  } else if (data.actions[0] === "message" && data.actions[1] === "read_all") {
    commit("CHANGE_MESSAGES_STATUS", data.body.chat_uuid)
  } else if (data.actions[0] === "message" && data.actions[1] === "new") {
    dispatch("ON_MESSAGE_NEW_NOTICE", data)
  } else if (data.actions[0] === "message" && data.actions[1] === "edit") {
    dispatch("ON_MESSAGE_EDIT_NOTICE", data)
  } else if (data.actions[0] === "message" && data.actions[1] === "remove") {
    dispatch("ON_MESSAGE_REMOVE_NOTICE", data)
  } else if (data.actions[0] === "chat" && data.actions[1] === "get") {
    dispatch("ON_CHAT_GET_NOTICE", data)
  } else if (data.actions[0] === "chat" && data.actions[1] === "init") {
    dispatch("ON_CHAT_SELLER_NOTICE", data)
  } else if (data.actions[0] === "chat" && data.actions[1] === "with_moderator") {
    dispatch("ON_CHAT_MODERATOR_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "offline") {
    dispatch("ON_USER_OFFLINE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "online") {
    dispatch("ON_USER_ONLINE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "get_sellers_online") {
    dispatch("ON_SELLERS_ONLINE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "seller_online") {
    dispatch("ON_SELLER_ONLINE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "seller_offline") {
    dispatch("ON_SELLER_OFFLINE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "is_typing") {
    dispatch("ON_INFORM_TYPING_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "my_info") {
    dispatch("ON_PROFILE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "set_sessionid") {
    dispatch("ON_SESSION_ID_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "update_profile") {
    dispatch("ON_UPDATE_PROFILE_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "captcha_verify") {
    dispatch("ON_CAPTCHA_NOTICE", data)
  } else if (data.actions[0] === "user" && data.actions[1] === "auth") {
    commit("ON_AUTHORIZATION_SUCCESS", data)
  }
}
