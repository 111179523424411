<template>
  <div>
    <SynDataTable
      :data="tableData"
      :queryAction="querySearch"
      :queryParams="queryParams"
      :fetching="fetchingStatus"
      rowKey="_id"
      :columns="dataTableColumns"
      tableId="ProductCatalogTypesTable"
      class="margin-top"
      defaultOrder="-timestamp"
      :rowActions="rowActions"
      :headerButtons="headerButtons"
      :selectedRow="PT_selectedTypeGet || {}"
      :limitValues="limitValues"
    ></SynDataTable>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import InputEditName from "./EditComponents/InputEditName"
import InputEditShortName from "./EditComponents/InputEditShortName"
import InputEditSynonyms from "./EditComponents/InputEditSynonyms"
import HighlightCellCyrillic from "@/modules/ProductCatalog/components/common/HighlightCellCyrillic"

export default {
  name: "TypesTable",
  data() {
    return {
      rowActions: [
        {
          label: "Поиск атрибутов",
          action: this.buildAttrTable
        }
      ],
      timeout: null,
      headerButtons: [
        {
          label: "Добавить",
          action: () => {
            this.PT_showCreateTypeModal(true)
          },
          theme: "primary"
        },
        {
          label: "Скопировать",
          action: () => {
            this.PT_showCopyTypeModal(true)
          },
          theme: "primary"
        }
      ],
      limitValues: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ]
    }
  },
  methods: {
    buildAttrTable(row) {
      // scroll to top
      window.scrollTo(0, 0)
      // assign selected
      this.PT_selectedType(row)
      // build secondary table
      this.PT_searchAttributes(row)
    },
    querySearch(queryParams) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.PC_searchTypes(queryParams)
      }, 500)
    },
    ...mapMutations("ProductCatalog", {
      changeQueryParams: "PT_typesTableQueryParams",
      PT_showCreateTypeModal: "PT_showCreateTypeModal",
      PT_selectedType: "PT_selectedType",
      PT_showCopyTypeModal: "PT_showCopyTypeModal"
    }),
    ...mapActions("ProductCatalog", {
      PC_searchTypes: "PC_searchTypes",
      updateNameCell: "PT_editTypesName",
      updateSynonymCell: "PT_editTypesSynonym",
      PT_searchAttributes: "PT_searchAttributes"
    })
  },
  computed: {
    dataTableColumns() {
      return [
        {
          key: "code",
          title: "Код",
          width: 104,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          }
        },
        {
          key: `full_name_${this.PC_selectedLanguage}`,
          title: `Тип товаров (полный) (${this.PC_selectedLanguage})`,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditName,
          editRendererProps: {
            onUpdate: this.updateNameCell
          },
          filtering: this.fullNameFilter,
          sorting: true
        },
        {
          key: `name_${this.PC_selectedLanguage}`,
          title: `Тип товаров (короткий) (${this.PC_selectedLanguage})`,
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditShortName,
          editRendererProps: {
            onUpdate: this.updateNameCell
          },
          filtering: this.nameFilter,
          sorting: true
        },
        {
          key: "synonymsNames",
          title: "Синонимы",
          renderer: HighlightCellCyrillic,
          editRenderer: InputEditSynonyms,
          editRendererProps: {
            onUpdate: this.updateSynonymCell
          },
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        },
        {
          key: "time",
          title: "Дата создания",
          width: 145,
          filtering: {
            type: "dateRange",
            placeholder: "Поиск",
            valueTypeTimestamp: true
          },
          sorting: true
        },
        {
          key: "status",
          title: "Статус",
          width: 104,
          filtering: {
            type: "search",
            placeholder: "Поиск"
          },
          sorting: true
        }
      ]
    },
    fullNameFilter() {
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        return {
          type: "search",
          placeholder: "Поиск"
        }
      }
      return false
    },
    nameFilter() {
      if (this.PC_selectedLanguage === this.PC_baseLanguage) {
        return {
          type: "search",
          placeholder: "Поиск"
        }
      }
      return false
    },
    ...mapGetters("ProductCatalog", {
      queryParams: "PT_typesTableQueryParams",
      tableData: "PT_typesTableData",
      fetchingStatus: "PT_typesTableFetchingStatus",
      PT_selectedTypeGet: "PT_selectedType",
      PC_selectedLanguage: "PC_selectedLanguage",
      PC_baseLanguage: "PC_baseLanguage"
    })
  }
}
</script>
<style lang="scss" scoped></style>
