import getImagePath from "utils/getImagePath"
import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearchComplex from "../../utils/buildMagicSearchComplex"
import { IMAGES_CDN, IMAGES_API_CDN } from "@/constants/common"
import { notification } from "ant-design-vue"
import { getFormatedDateTimeByTimestamp } from "@/utils/getFormatedDateTime"

const state = () => ({
  PC_productTableQueryParams: {
    limit: 10,
    ordering: ["-timestamp"],
    offset: 0
  },
  PC_productTableData: { count: 0, items: [] },
  PC_productTableDataCloneBeforeChanges: { count: 0, items: [] },
  PC_productTableFetchingStatus: false,
  PC_currentEditableProduct: {}
})

const getters = {
  PC_productTableQueryParams: (state) => state.PC_productTableQueryParams,
  PC_productTableData: (state) => state.PC_productTableData,
  PC_productTableDataCloneBeforeChanges: (state) => state.PC_productTableDataCloneBeforeChanges,
  PC_productTableFetchingStatus: (state) => state.PC_productTableFetchingStatus,
  PC_currentEditableProduct: (state) => state.PC_currentEditableProduct
}

const mutations = {
  PC_productTableSetQueryParams(state, value) {
    state.PC_productTableQueryParams = value
  },
  PC_productTableFireFetchingStatus(state) {
    state.PC_productTableFetchingStatus = true
  },
  PC_productTableCancellFetchingStatus(state) {
    state.PC_productTableFetchingStatus = false
  },
  PC_setProductTableData(state, value) {
    state.PC_productTableData = value
  },
  PC_productTableDataCloneBeforeChanges: (state, value) => {
    state.PC_productTableDataCloneBeforeChanges = JSON.parse(JSON.stringify(value)) // clone
  },
  PC_productTableSetNewValue: (state, { columnKey, newValue, rowIndex }) => {
    state.PC_productTableData.items[rowIndex][columnKey] = newValue
    let clone = JSON.parse(JSON.stringify(state.PC_productTableData))
    state.PC_productTableData = clone
  },
  PC_setProductTableDataImgCount: (state, { newCount, code }) => {
    let current = state.PC_productTableData.items.find((item) => {
      return item.code === code
    })
    if (current) {
      current.imagesMsg = newCount
    }
  },
  PC_productTableUpdateImagesUrls(state, { newImagesUrls, index }) {
    state.PC_productTableData.items[index].imagesUrls = newImagesUrls
  },
  PC_currentEditableProduct: (state, value) => {
    state.PC_currentEditableProduct = JSON.parse(JSON.stringify(value)) // clone
  },
  PC_resetProductTableValues(state) {
    state.PC_productTableQueryParams = {
      limit: 10,
      ordering: ["-timestamp"],
      offset: 0
    }
    state.PC_productTableData = { count: 0, items: [] }
  }
}

const actions = {
  async PC_searchProducts({ commit, getters, dispatch }, queryParams) {
    try {
      if (!queryParams.filters || !queryParams.filters.length) return
      commit("PC_productTableFireFetchingStatus")
      commit("PC_setProductTableData", { count: 0, items: [] })
      await dispatch("PC_searchProductsRequest", queryParams)
      await dispatch("VE_checkCount", queryParams)
    } catch (e) {
      commit("handleError", e)
    } finally {
      commit("PC_productTableCancellFetchingStatus")
    }
  },
  async PC_searchProductsRequest({ commit, getters }, queryParams) {
    // let fields = ['code', 'p_code', 'type_name', 'brand_name', 'name', 'model', 'gtin', 'description', 'timestamp', 'status'];
    let fields = queryParams.filters.map((filter) => filter.name)
    let resource = "MPC"
    let sort = queryParams.ordering[0]
    queryParams.filters = queryParams.filters.map((filter) => {
      if (filter.value !== null && (filter.name === "code" || filter.name === "p_code")) {
        // видалити всі з пробели cs_code p_code  snt 5286
        filter.value = filter.value.replace(/\s+/g, "")
      }
      return filter.name === "gtin" && filter.value === "false"
        ? { name: "gtin", value: false }
        : filter
    })

    let sendData = buildMagicSearchComplex(queryParams, fields, resource, sort)

    let productsMsg
    let products = []
    return API_MODERATION.post(
      // search products
      `${apiUrls.searchMagic}`,
      sendData,
      {
        headers: {
          "Accept-language": getters.PC_selectedLanguage
        }
      }
    )
      .then((r) => r.data.message)
      .then((message) => {
        productsMsg = message
        products = message.data

        let items = buildProductTableItems(products, "full")

        let tableObj = {
          count: productsMsg.count,
          items: items
        }

        commit("PC_setProductTableData", tableObj)

        // update table query params
        commit("PC_productTableSetQueryParams", queryParams)
        return message
      })
      .catch((e) => {
        e.$_actionName = "PC_searchProductsRequest"
        return Promise.reject(e)
      })
  },
  PC_editProductTable({ getters, commit }, { columnKey, newValue, rowIndex }) {
    try {
      let oldValue = getters.PC_productTableData.items[rowIndex][columnKey]
      if (newValue === oldValue) return

      let sendData = {
        code: getters.PC_productTableData.items[rowIndex].code,
        product: {
          [columnKey]: newValue
        }
      }

      let params = {}
      if (columnKey === "name") {
        params = {
          headers: {
            "Accept-language": getters.PC_selectedLanguage
          }
        }
      }

      // show loader
      commit("PC_showLoader", true)

      return API_MODERATION.put(`${apiUrls.product}`, sendData, params)
        .then((r) => {
          commit("PC_productTableSetNewValue", { columnKey, newValue, rowIndex })

          notification.info({ message: "Изменения продукта добавлены" })
          return r
        })
        .catch((e) => {
          e.$_actionName = "PC_editProductTable"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("handleError", e)
    } finally {
      // hide loader
      commit("PC_showLoader", false)
    }
  },
  async PC_openEditProductModal({ commit, dispatch, getters }, product) {
    try {
      commit("PC_showEditProductModal", true)
      // reset values:
      commit("PC_resetProductValues")
      commit("PC_resetBrandAutocompleteValues")
      commit("PC_resetTypeAutocompleteValues")
      commit("PC_resetSearchNewAttributeValues")
      commit("PC_submitMsg", false)
      commit("PC_emptyEditableProductAttributes")
      commit("PC_resetCheckRequiredAttributes")
      commit("PC_resetCheckMainAttributes")
      // fetch product to obtain most recent data
      let response = await dispatch("PC_fetchSelectedProductData", { product, size: "full" })
      if (response) {
        product = response
      } else {
        // else use less recent data
        product = JSON.parse(JSON.stringify(product)) //clone
        commit("PC_setEditableProduct", product)
      }
      // set values:
      commit("PC_currentEditableProduct", product)
      commit("PC_productTableDataCloneBeforeChanges", getters.PC_productTableData) //

      dispatch("PC_setProductTypePlaceholder", (product.type && product.type.full_name) || "noType")
      dispatch("PC_setProductBrandPlaceholder", (product.brand && product.brand.name) || "noBrand")

      let sendObject = {
        product,
        attributes: product.attributes,
        mutationName: "PC_setEditableProductAttributes",
        newAttribute: false,
        checkAttributes: true
      }
      await dispatch("PC_fetchProductAttributes", sendObject)
      // await dispatch('PC_fetchProductAttributes', sendObject);
      if (!product.type) return
      // check required attributes:
      let required = await dispatch("PC_checkRequiredAttributeOfType")
      let main = await dispatch("PC_checkMainAttributeOfType")
      if (required) {
        dispatch("PC_missingRequiredAttributes")
      }
      if (main) {
        dispatch("PC_missingMainAttributes")
      }
    } catch (e) {
      e.$_actionName = "PC_openEditProductModal"
      commit("handleError", e)
    }
  },
  async PC_fetchSelectedProductData({ commit, dispatch, getters }, { product, size }) {
    let reservedAtributes = product.attributes ? product.attributes : []
    try {
      // show loader
      commit("PC_showLoader", true)

      let params = {
        code: product.code
      }

      return API_MODERATION.get(`${apiUrls.product}`, { params })
        .then((r) => r.data.message.records[0])
        .then((product) => {
          product = buildProductTableItems([product], size)[0]
          product.attributes = reservedAtributes

          commit("PC_setEditableProduct", product)
          return product
        })
        .catch((e) => {
          e.$_actionName = "PC_editProductTable"
          return Promise.reject(e)
        })
    } catch (e) {
      commit("handleError", e)
    } finally {
      // hide loader
      commit("PC_showLoader", false)
    }
  },

  async PC_duplicateProduct({ commit, getters, dispatch }, { mpcCode }) {
    try {
      // show loader
      commit("PC_showLoader", true)

      await API_MODERATION.post(apiUrls.duplicateProduct(mpcCode))

      dispatch("PC_searchProducts", getters.PC_productTableQueryParams)
    } catch (e) {
      commit("handleError", e)
    } finally {
      // hide loader
      commit("PC_showLoader", false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

// helpers:

export function buildProductTableItems(products, size) {
  return products.map((item, index) => {
    if (item.barcode === null) item.barcode = ""
    // restructure images urls
    if (item.images && item.images.length) {
      item.imagesUrls = item.images.map((item) => {
        let url
        let urlApi
        if (!item.on_cdn) {
          url = item.filename
        } else {
          // build url
          url = IMAGES_CDN + "/media/assets/images/" + getImagePath(item.filename, size || "full")
          urlApi =
            IMAGES_API_CDN + "/media/assets/images/" + getImagePath(item.filename, size || "full")
        }
        return {
          item: url,
          itemApi: urlApi,
          filename: item.filename,
          on_cdn: item.on_cdn
        }
      })
    } else {
      item.imagesUrls = []
    }
    item.typeName =
      (products[index].type && products[index].type[0] && products[index].type[0].full_name) || ""
    item.brandName =
      (products[index].brand && products[index].brand[0] && products[index].brand[0].name) || ""
    // time
    item.timestamp = getFormatedDateTimeByTimestamp(item.timestamp)
    return item
  })
}
